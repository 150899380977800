import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { FileDropZone } from "./FileDropZone";
import { useNavigate } from "react-router";

interface PImportFile {
  onChange?: (x: JSON) => void;
}

export const ImportFile = ({ onChange }: PImportFile) => {
  const [_file, setFile] = useState<File>();
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const onFile = useCallback(
    async (file: File | null | undefined) => {
      if (!file) return;
      const text = JSON.parse(await file.text());
      setFile(text);
      onChange?.(text);
      if (!onChange) {
        localStorage.setItem("portfolio", JSON.stringify(text));
        navigate(0);
      }
    },
    [navigate, onChange],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onFile(e.currentTarget.files?.item(0));
    },
    [onFile],
  );

  const handleDrop = useCallback(
    (files: File[]) => {
      onFile(files[0]);
    },
    [onFile],
  );

  return (
    <div className="flex items-center justify-center w-full ">
      <label
        htmlFor="dropzone-file"
        className={clsx(
          "flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer",
          "bg-gray-50  hover:bg-gray-100",
          "bg-orange-100 text-orange-800 hover:bg-orange-200",
          "dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 dark:text-gray-300",
        )}
      >
        <FileDropZone onFilesDrop={handleDrop}>
          <div className="flex flex-col items-center justify-center p-2">
            <CloudArrowUpIcon className="w-6 h-6" />
            <p className="mb-2 text-sm text-center">
              <span className="font-semibold">Click to upload</span> <br /> or
              drag and drop a JSON file
            </p>
          </div>
        </FileDropZone>
        <input
          ref={ref}
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={handleChange}
        />
      </label>
    </div>
  );
};
