import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import * as d3 from "d3";

import {
  TTrades,
  normalizeHoldingsWithNav,
  replaceTradeAssets,
  tradesToFifoAdjustedOps,
} from "src/portfolio/holdings";
import { MFData, getMFData } from "src/instruments/mf";
import allTrades, { isDummy } from "src/data/all_trades";
import { sum, uniq } from "lodash";
import { nifty50TRINav } from "src/data/index-tri";
import { MFChart } from "../d3/MFChart";
import { useDimensions } from "src/lib/utils/useDimensions";
import { PortfolioMFCardGroup } from "./PortfolioMFCardGroup";
import { PortfolioMFCard } from "./PortfolioMFCard";
import clsx from "clsx";
import { InfoPair } from "./InfoPair";
import { ShareAnonymous } from "../ShareAnonymous";
import { toINRStr } from "src/lib/utils/toINRStr";
import { xirrForTrades } from "src/utils/xirr";
import { ImportFile } from "../ImportFile";
import { DemoInfo } from "./DemoInfo";

const refMFData: MFData = nifty50TRINav();
const msInDay = 3600 * 24 * 1000;
const daysBetween = (x: number, y: number, every = 1) =>
  Math.floor(Math.abs(x - y) / (every * msInDay));

export const PortfolioChart = () => {
  const [portfolioNumbers, setPortfolioNumbers] = useState<{
    nav: number[];
    total: number;
    assetWeights?: Map<string, { total: number; weight: number }>;
    xirr: number;
  }>({
    nav: [],
    total: 0,
    xirr: 0,
  });
  const [startDate, setStartDate] = useState<number>();
  const [assetNavs, setAssetNavs] = useState<MFData[]>();
  const lastDate = useMemo(() => +new Date(), []);
  const [filteredISINs, setFilteredISINs] = useState<string[]>([]);
  const portfolioDateExtent = useMemo<
    [number, number] | [undefined, undefined]
  >(() => {
    const [start, end] = d3.extent(
      allTrades.allTrades.map((x) => +new Date(x.trade_date)),
    );
    return [start!, lastDate || end!];
  }, [lastDate]);

  const sourceTrades = useMemo(
    () =>
      allTrades.allTrades.filter((x) =>
        filteredISINs.length ? filteredISINs.includes(x.isin) : true,
      ),
    [filteredISINs],
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(containerRef);

  const onToggleISIN = useCallback((isin: string) => {
    setFilteredISINs((cs) => {
      if (cs.includes(isin)) return cs.filter((x) => x !== isin);
      return [...cs, isin];
    });
  }, []);
  const onSetISINs = useCallback((isins?: string[]) => {
    setFilteredISINs(isins || []);
  }, []);

  useEffect(() => {
    console.timeEnd("fetching_asset_navs");
    console.time("fetching_asset_navs");
    const codes = uniq((allTrades.allTrades || []).map((x) => x.code));
    getMFData({ codes })
      .then((res) => setAssetNavs(res))
      .finally(() => console.timeEnd("fetching_asset_navs"));
  }, []);

  const ref = useMemo(() => {
    const trades = sourceTrades.map((x) => {
      const dt = +new Date(x.trade_date);
      return [x.isin, x.trade_type as TTrades[1], x.quantity, x.price, dt];
    }) as TTrades[];

    const assets = { [refMFData.isin]: refMFData };
    const refTrades = replaceTradeAssets(trades, refMFData);
    const hops = tradesToFifoAdjustedOps(refTrades);
    const refNumbers = normalizeHoldingsWithNav(
      hops,
      assets,
      refMFData.last_nav_date,
    );

    return refNumbers;
  }, [sourceTrades]);

  const dots = useMemo(() => {
    if (!startDate || !portfolioNumbers.nav.length) return;
    return {
      dates: sourceTrades.map((x) => new Date(x.trade_date)),
      values: sourceTrades.map((x) => {
        return daysBetween(startDate, +new Date(x.trade_date));
      }),
      types: sourceTrades.map((x) => x.trade_type as TTrades[1]),
      meta: sourceTrades,
    };
  }, [portfolioNumbers, sourceTrades, startDate]);

  useEffect(() => {
    const calculateNavs = async () => {
      if (!assetNavs || !lastDate) return;
      const trades = sourceTrades.map((x) => [
        x.isin,
        x.trade_type as TTrades[1],
        x.quantity,
        x.price,
        +new Date(x.trade_date),
      ]) as TTrades[];

      const assets = Object.fromEntries(assetNavs.map((x) => [x.isin, x]));
      const hops = tradesToFifoAdjustedOps(trades);
      const ld = Math.min(...Object.values(assets).map((a) => a.last_nav_date));
      const { navs, total, assetWeights } = normalizeHoldingsWithNav(
        hops,
        assets,
        ld,
      );
      const xirr = xirrForTrades(
        sourceTrades,
        -(total * (1 + navs[navs.length - 1])),
        new Date(),
      );
      setStartDate(hops.ops[0][0]);
      setPortfolioNumbers({ nav: navs, total, assetWeights, xirr });
    };
    calculateNavs();
  }, [assetNavs, lastDate, sourceTrades]);

  const [checked, setChecked] = useState<"absolute" | "relative">("absolute");

  const domain = useMemo(() => [-0.2, 1] as [number, number], []);

  const mfGroups = useMemo(() => {
    const tradesByISIN = (
      allTrades.allTrades
        ? [...d3.group(allTrades.allTrades, (d) => d.isin)]
        : []
    )
      .map(([isin, trades]) => {
        const buys = trades
          .filter((x) => x.trade_type === "buy")
          .reduce((acc, x) => acc + x.quantity * 1000, 0);
        const sells = trades
          .filter((x) => x.trade_type === "sell")
          .reduce((acc, x) => acc + x.quantity * 1000, 0);

        return {
          isin,
          code: trades[0]?.code,
          trades,
          quantity: buys - sells,
          category: trades[0]?.category,
        };
      })
      .sort((a, b) => {
        if (a.quantity && b.quantity) {
          return b.quantity - a.quantity;
        }
        if (a.quantity) return -1;
        if (b.quantity) return 1;
        return 0;
      });

    const pastTrades = tradesByISIN.filter((x) => x.quantity <= 0.01);

    const debtTrades = tradesByISIN.filter(
      (x) => x.quantity > 0.01 && x.category?.includes("Debt Scheme"),
    );
    const equityTrades = tradesByISIN.filter(
      (x) =>
        x.quantity > 0.01 &&
        (x.category?.includes("Equity Scheme") ||
          x.category?.includes("Other Scheme")),
    );

    const hybridTrades = tradesByISIN.filter(
      (x) => x.quantity > 0.01 && x.category?.includes("Hybrid Scheme"),
    );

    return [
      {
        name: "Equity MFs",
        trades: equityTrades,
      },
      {
        name: "Hybrid MFs",
        trades: hybridTrades,
      },
      {
        name: "Debt MFs",
        trades: debtTrades,
      },
      {
        name: "Past MFs",
        trades: pastTrades,
        expandable: true,
      },
    ].map((x) => ({
      ...x,
      weight: sum(
        x.trades.map((t) => portfolioNumbers.assetWeights?.get(t.isin)?.weight),
      ),
    }));
  }, [portfolioNumbers.assetWeights]);

  const toggleISINCb = useCallback(
    (isin: string) => onToggleISIN(isin),
    [onToggleISIN],
  );

  if (allTrades.allTrades.length === 0) {
    return (
      <div className="w-full h-screen grid content-center">
        <div className="m-auto mb-2">
          <ImportFile />
        </div>
      </div>
    );
  }

  if (!portfolioNumbers.nav.length || !startDate || !dots || !assetNavs)
    return <div>loading</div>;

  const invested = Math.round(portfolioNumbers.total ?? 0);
  const value = Math.round(
    (portfolioNumbers.total ?? 0) *
      (1 + portfolioNumbers.nav[portfolioNumbers.nav.length - 1]),
  );
  const currentPortfolio = {
    invested,
    value,
    unrealized: value - invested,
  };

  return (
    <div className="flex flex-col space-y-2 p-4">
      <div ref={containerRef}>
        <div className="container py-4 m-auto">
          {isDummy && <DemoInfo />}
          <div className="flex flex-row justify-between">
            <div>
              <div className="flex flex-row space-x-6 items-center justify-between">
                <InfoPair
                  className="text-xl"
                  label="Total"
                  value={toINRStr(currentPortfolio.value)}
                />
                <ShareAnonymous trades={allTrades.allTrades} />
              </div>
              <div className="flex flex-row pt-2 space-x-6">
                <InfoPair
                  label="Invested"
                  value={toINRStr(currentPortfolio.invested)}
                />
                <InfoPair
                  label="Unrealized"
                  value={`${toINRStr(currentPortfolio.unrealized)}`}
                  secondary={`${(
                    (currentPortfolio.unrealized / currentPortfolio.invested) *
                    100
                  ).toFixed(2)}%`}
                />
                <InfoPair
                  label="XIRR"
                  value={`${(portfolioNumbers.xirr * 100).toFixed(2)}`}
                  secondary={`while Nifty 50 gave TODO`}
                />
              </div>
              <div className="flex flex-row space-x-8 pt-4 pb-2">
                <div className="flex flex-col space-y-2">
                  <div className="space-x-2">
                    <input
                      id="absolute"
                      className="pl-2"
                      name="absolute"
                      checked={"absolute" === checked}
                      onChange={() => setChecked("absolute")}
                      type="checkbox"
                    />
                    <label htmlFor="absolute">Absolute</label>
                  </div>
                  <div className="space-x-2">
                    <input
                      id="relative"
                      className="pl-2"
                      name="relative"
                      checked={"relative" === checked}
                      onChange={() => setChecked("relative")}
                      type="checkbox"
                    />
                    <label htmlFor="relative">Relative</label>
                  </div>
                </div>
                <div>
                  {filteredISINs.length !== 0 && (
                    <button onClick={() => setFilteredISINs([])}>
                      Reset selection
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-2">
              <ImportFile />
            </div>
          </div>
        </div>

        <MFChart
          className=" py-4"
          width={width}
          height={420}
          dots={dots}
          data={
            checked === "absolute"
              ? [
                  {
                    values: portfolioNumbers.nav,
                    type: "main",
                    startDate,
                    endDate: lastDate,
                  },
                  {
                    values: ref.navs,
                    type: "benchmark",
                    startDate,
                    endDate: lastDate,
                  },
                ]
              : [
                  {
                    values: portfolioNumbers.nav.map((n, i) => n - ref.navs[i]),
                    type: "main",
                    startDate,
                    endDate: lastDate,
                  },
                  {
                    values: [...ref.navs].fill(0),
                    type: "benchmark",
                    startDate,
                    endDate: lastDate,
                  },
                ]
          }
        />
        <div className="container m-auto max-w-fit mt-4 space-y-4">
          {mfGroups.map(({ trades, name, expandable, weight }) => {
            return (
              <PortfolioMFCardGroup
                key={name}
                name={name}
                group={trades}
                startDate={startDate}
                showCompareMFLink
                onSelectGroup={onSetISINs}
                expandable={expandable}
                collapsed={!!expandable}
                total={weight}
              >
                {trades.map(({ isin }) => (
                  <PortfolioMFCard
                    key={isin}
                    className={clsx(
                      "place-self-center border",
                      filteredISINs.includes(isin)
                        ? "border-orange-400 dark:border-gray-300 shadow-lg"
                        : "border-orange-200 dark:border-gray-500 shadow",
                    )}
                    weight={portfolioNumbers.assetWeights?.get(isin)?.weight}
                    total={portfolioNumbers.assetWeights?.get(isin)?.total}
                    mfData={assetNavs.find((x) => x.isin === isin)!}
                    isin={isin}
                    onClick={toggleISINCb}
                    domain={domain}
                    portfolioDateExtent={portfolioDateExtent}
                  />
                ))}
              </PortfolioMFCardGroup>
            );
          })}
        </div>
      </div>
    </div>
  );
};
