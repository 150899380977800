import clsx from "clsx";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";

type Data = {
  values: number[];
  startDate: number;
  endDate: number;
  type: "main" | "benchmark";
}[];

type Props = {
  width: number | string;
  height: number | string;
  className?: string;
  dots?: {
    dates: Date[];
    values: number[];
    types: ("buy" | "sell")[];
    meta: any;
  };
  data: Data;
  forCard?: boolean;
  dateExtent?: [number, number] | [undefined, undefined];
  domain?: [number, number];
};

type PChart = {
  width: number | string;
  height: number | string;
  svg: SVGSVGElement;
  showAxis?: boolean;
  showGrid?: boolean;
  yearLines?: boolean;
  dateExtent?: Props["dateExtent"];
  domain?: Props["domain"];
  margin?: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
};

export const chart = ({
  svg: svgEle,
  width: fWidth,
  height: fHeight,
  showAxis = true,
  showGrid = true,
  yearLines = false,
  margin: pMargin,
  dateExtent: pDateExtent,
  domain,
}: PChart) => {
  const margin = {
    top: pMargin?.top ?? 10,
    right: pMargin?.right ?? 40,
    bottom: pMargin?.bottom ?? 20,
    left: pMargin?.left ?? 56,
  };

  const width = +fWidth - margin.left - margin.right;
  const height = +fHeight - margin.top - margin.bottom;

  const canvas = d3.select(svgEle).attr("viewBox", [0, 0, fWidth, fHeight]);
  const svg = canvas
    .selectAll("g")
    .data([null])
    .join("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const axiss = svg.append("g").attr("class", "axiess");
  const lines = svg.append("g").attr("class", "lines");
  const dotsEle = svg.append("g").attr("class", "dots");

  // Scales
  const xScale = d3.scaleTime().nice(d3.timeMonth).range([0, width]);
  const xAxis = svg
    .append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0,${height})`);

  const yScale = d3.scaleLinear().range([height, 0]).nice();

  function update(data: Data, dots: Props["dots"]) {
    /* TODO: these should be precalculated */
    const dataDateExtent = d3.extent(
      data
        .map((d) => [d.startDate, d.endDate])
        .flat()
        .sort(),
    );
    const dateExtent =
      pDateExtent && pDateExtent[0] && pDateExtent[1]
        ? pDateExtent
        : dataDateExtent;

    const valueExtent = domain ?? d3.extent(data.map((x) => x.values).flat());

    if (dateExtent[0] === undefined || dateExtent[1] === undefined)
      throw Error(`date extent is undefined`);
    if (valueExtent[0] === undefined || valueExtent[1] === undefined)
      throw Error(`value extent is undefined`);

    xScale.domain(dateExtent);
    yScale.domain(valueExtent);

    // Update x-axis
    if (showAxis) {
      xAxis
        .selectAll<SVGGElement, d3.NumberValue>(".x-axis")
        .data([null])
        .join("g")
        .attr("class", "x-axis text-black dark:text-white")
        .call(d3.axisBottom(xScale).ticks(16));
    }

    const line = ({ values }: Data[number]) => {
      const res = d3
        .line<number>()
        .x((_, i) => xScale(data[0].startDate + i * 3600 * 24 * 1000))
        .y((v) => yScale(v))
        .defined((v) => !Number.isNaN(v))
        .curve(d3.curveBasis)(values);
      return res;
    };

    if (showGrid) {
      /* show horizontal grid lines */
      svg
        .selectAll<SVGGElement, d3.NumberValue>(".y-axis-grid")
        .data([null])
        .join("g")
        .attr("class", "y-axis-grid text-slate-600 dark:text-slate-400")
        .selectAll("line")
        .data(
          yScale.ticks(5),
        ) /* TODO: ticks should be based on height and data domain? */
        .join("line")
        .attr("class", (d) =>
          clsx(
            "text-slate-300 dark:text-slate-500",
            d === 0 && "text-slate-800 dark:text-slate-200",
          ),
        )
        .attr("x1", 0)
        .attr("y1", yScale)
        .attr("x2", width)
        .attr("y2", yScale)
        .attr("opacity", 0)
        .transition()
        .ease(d3.easeQuadInOut)
        .attr("opacity", 1)
        .attr("stroke", "currentColor");
    }

    if (showAxis) {
      /* y axis */
      axiss
        .selectAll<SVGGElement, d3.NumberValue>(".y-axis")
        .data([null])
        .join("g")
        .attr("class", "y-axis text-black dark:text-white")
        .attr("transform", `translate(${width},0)`)
        .transition()
        .ease(d3.easeQuadInOut)
        .call(
          d3
            .axisRight<number>(yScale)
            .tickFormat((d) => `${(d * 100).toFixed(0)}%`)
            .ticks(5),
        );
    }

    if (yearLines) {
      lines
        .selectAll("year-line")
        .data(xScale.ticks(5))
        .join("line")
        .attr("class", "year-line")
        .attr("stroke", "lightgray")
        .attr("x1", (d) => xScale(d))
        .attr("y1", height)
        .attr("x2", xScale)
        .attr("y2", 0);
    }

    if (dots) {
      /* Draw dots */
      dotsEle
        .selectAll<SVGCircleElement, NonNullable<Props["dots"]>["dates"]>(
          "circle",
        )
        .data(dots.dates)
        .join("circle")
        .attr("cx", (d) => xScale(d))
        .attr("class", "text-slate-200 dark:text-slate-500")
        .attr("cy", (_d, i) => yScale(data[0].values[dots.values[i]]))
        .transition()
        .delay(50)
        .ease(d3.easeQuadInOut)
        .attr("r", 3)
        .attr("fill", (_d, i) => (dots.types[i] === "buy" ? "blue" : "red"))
        .attr("stroke", "currentColor")
        .attr("stroke-width", 2);
    }

    // Draw lines
    lines
      .selectAll<SVGPathElement, Data[number]>("path")
      .data(data, (d) => d.type)
      .join("path")
      .attr("stroke", (d) => (d.type === "main" ? "green" : "gray"))
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", (d) => (d.type === "main" ? 1.75 : 1))
      .attr("stroke-dasharray", (d) => (d.type === "main" ? null : "2,2"))
      .attr("fill", "none")
      .transition()
      .delay(50)
      .ease(d3.easeQuadInOut)
      .attr("d", line);
  }
  return update;
};

export const MFChart = ({
  width,
  height,
  data,
  className,
  dots,
  dateExtent,
  domain,
  forCard = false,
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [updateViz, setUpdateViz] =
    useState<(d: Data, dots: Props["dots"]) => void>();
  useEffect(() => {
    if (!svgRef.current) return;
    const update = chart({
      width,
      height,
      svg: svgRef.current,
      showGrid: !forCard,
      showAxis: !forCard,
      yearLines: forCard,
      margin: forCard ? { left: 10, right: 10, top: 5, bottom: 5 } : undefined,
      domain,
      dateExtent,
    });
    setUpdateViz(() => update);
  }, [width, height, forCard, domain, dateExtent]);

  useEffect(() => {
    updateViz && data && updateViz(data, dots);
  }, [data, updateViz, dots]);

  return (
    <svg className={className} ref={svgRef} width={width} height={height} />
  );
};
