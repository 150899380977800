import * as Plot from "@observablehq/plot";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MFSchemeSearch } from "src/components/MFSchemeSearch";
import trades from "../../data/all_trades";
import { MFData, getMFData } from "src/instruments/mf/mfAPINavData";
import { normalizeNav, toPlotData } from "src/instruments/mf";
import { format, sub } from "date-fns";
import { NavComparisonType } from "src/types";
import { uniq } from "lodash";

interface Props {
  comparison: NavComparisonType;
}

const msInDay = 3600 * 24 * 1000;

export const MFComparisonPlot = ({ comparison }: Props) => {
  const [params, setParams] = useSearchParams();
  const [folio, setFolio] = useState<MFData[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const startDate = useMemo(() => {
    const startParam = params.get("start");
    const adjustment =
      comparison.type === "rolling" ? msInDay * comparison.days[0] : 0;
    return startParam ? new Date(+startParam - adjustment) : undefined;
  }, [comparison.type, params]);

  console.log("start", startDate);
  const codes = useMemo(() => {
    const codesParam = params.get("codes");
    const folioCodes = uniq((trades.allTrades || []).map((x) => x.code));
    return codesParam?.length
      ? decodeURIComponent(codesParam)
          ?.split(",")
          .map((x) => parseInt(x, 10))
      : folioCodes;
  }, [params]);

  const bg = "#FBF5EB"; /* "#FFEEDF" */

  useEffect(() => {
    getMFData({ codes, startDate }).then((res) => {
      if (!res) return;
      setFolio(res);
    });
  }, [codes, startDate]);

  useEffect(() => {
    const width = containerRef.current?.offsetWidth;

    const minDate = startDate ?? folio.map((x) => x.first_nav_date).sort()[0];

    const data = toPlotData(normalizeNav({ mfs: folio, comparison }));
    if (!data) return;

    const plot = Plot.plot({
      marginRight: 100,
      marginLeft: 100,
      style: {
        backgroundColor: bg,
        overflow: "visible",
      },
      width,
      height: 800,
      y: {
        grid: true,
        axis: "both",
      },
      x: {
        domain: [minDate, new Date()],
        grid: true,
      },
      marks: [
        Plot.ruleX([0]),
        Plot.ruleX(
          [
            sub(new Date(), { years: 1 }),
            sub(new Date(), { years: 2 }),
            sub(new Date(), { years: 3 }),
            sub(new Date(), { years: 5 }),
          ],
          {
            stroke: "darkblue",
          },
        ),
        Plot.line(data, {
          x: "day",
          y: "nav",
          z: "name",
          stroke: "name",
          title: (d) =>
            [
              d.name,
              `Date: ${format(d.day, "yyyy-MM-dd")}`,
              `Return: ${(1 + d.nav).toFixed(2)}x`,
              `CAGR: ${(d.cagr * 100).toFixed(2)}`,
            ].join("\n"),
          tip: true,
        }),
        Plot.text(
          data,
          Plot.selectFirst({
            x: "day",
            y: "nav",
            z: "name",
            text: "name",
            textAnchor: "start",
          }),
        ),
      ],
    });
    containerRef.current?.append(plot);
    return () => plot.remove();
  }, [folio, startDate, comparison]);

  return (
    <div>
      <MFSchemeSearch
        onChange={(mfs) => {
          params.set("codes", mfs.join(","));
          setParams(params);
        }}
        defaultValues={codes.map((x) => +x)}
      />
      <div
        style={{
          backgroundColor: bg,
        }}
        className="w-full px-2 py-8 shadow"
        ref={containerRef}
      />
    </div>
  );
};
