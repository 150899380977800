import dummyTrades from "src/components/dummy_trades";
import amfiSchemes from "./amfi-schemes";
import { notEmpty } from "src/utils/notEmpty";
import { Trades, TTrade } from "src/types";

/* curl "https://mutualfund.purifiedbytes.com?default_format=JSON&query=select%20code%2C%20isin%2C%20name%2C%20type%2C%20category%20from%20scheme%20where%20isin%20in%20('INF179K01WM1'%2C%20'INF082J01036'%2C%20'INF179K01WN9'%2C%20'INF205K01LE4'%2C%20'INF090I01FH9'%2C%20'INF789F01XA0'%2C%20'INF879O01027'%2C%20'INF090I01JV2'%2C%20'INF204K01K15'%2C%20'INF846K01K43'%2C%20'INF209KB19C3'%2C%20'INF209KB18C5'%2C%20'INF247L01718'%2C%20'INF200K01UT4'%2C%20'INF179KB1HP9'%2C%20'INF109K01Q49'%2C%20'INF740K01151')%3B" -H "Authorization: Basic cGxheTo=" -H "Content-Type: text/plain" | jq ".data" */

interface ImportTrade {
  name: string;
  isin: string;
  price: number;
  quant: number;
  date: string;
  type: "buy" | "sell";
}

// const old = () => {
//   return ciju
//     .map((x) => {
//       const { code, category } =
//         amfiSchemes.find((s) => s.isin === x.isin) ?? {};
//       if (!code) {
//         throw Error(`No code found for ${x.isin} - ${x.tradingsymbol}`);
//       }

//       return {
//         ...x,
//         code: parseInt(code, 10),
//         category,
//       };
//     })
//     .sort(
//       (a, b) => +new Date(a.trade_date) - +new Date(b.trade_date),
//     ) as Trades[];
// };

const dummyData = () => {
  return dummyTrades
    .map(({ tradingsymbol, isin, price, quantity, trade_date, trade_type }) => {
      const { code, category } = amfiSchemes.find((s) => s.isin === isin) ?? {};
      if (!code) {
        throw Error(`No code found for ${isin} - ${tradingsymbol}`);
      }

      return {
        tradingsymbol: tradingsymbol,
        trade_date: trade_date,
        trade_id: tradingsymbol,
        trade_type: trade_type === "buy" ? ("buy" as const) : ("sell" as const),
        price,
        quantity: quantity,
        isin,
        code: parseInt(code, 10),
        category,
      };
    })
    .filter((x) => x)
    .sort((a, b) => +new Date(a.trade_date) - +new Date(b.trade_date));
};

/** Find biggest trade. Find the multiple which would normalize it to 10,000.
 * And use that to normalize rest of the trades.
 */
export const normalizeTrades = (trades: Trades[]) => {
  const max = Math.max(...trades.map((t) => t.quantity * t.price));
  const scalingFactor = 10000 / max;
  return trades.map((t) => ({ ...t, quantity: t.quantity * scalingFactor }));
};

const isAppTradeFormat = (trade: Trades[] | ImportTrade[]): trade is Trades[] =>
  [
    "tradingsymbol",
    "trade_date",
    "trade_id",
    "trade_type",
    "price",
    "quantity",
    "isin",
    "code",
    "category",
  ].every((x) => Object.keys(trade[0]).includes(x));

export const getTrades = (): TTrade[] => {
  if (process.env.REACT_APP_DUMMY_DATA) {
    return dummyData().map((x) => [
      x.tradingsymbol,
      x.isin,
      x.price,
      x.quantity,
      +new Date(x.trade_date),
      x.trade_type,
    ]);
  }

  const ps = localStorage.getItem("portfolio");
  if (!ps) {
    console.warn("no portfolio");
    return dummyData().map((x) => [
      x.tradingsymbol,
      x.isin,
      x.price,
      x.quantity,
      +new Date(x.trade_date),
      x.trade_type,
    ]);
    // return [];
  }

  const portfolio = JSON.parse(ps);

  const trades = portfolio.trades as ImportTrade[] | Trades[];
  if (isAppTradeFormat(trades)) {
    return trades
      .map((x) =>
        x
          ? ([
              x.tradingsymbol,
              x.isin,
              x.price,
              x.quantity,
              +new Date(x.trade_date),
              x.trade_type,
            ] as TTrade)
          : null,
      )
      .filter(notEmpty)
      .sort((a, b) => a[4] - b[4]);
  }

  return trades
    .map((x) =>
      x
        ? ([
            x.name,
            x.isin,
            x.price,
            x.quant,
            +new Date(x.date),
            x.type,
          ] as TTrade)
        : null,
    )
    .filter(notEmpty)
    .sort((a, b) => a[4] - b[4]);
};

export const allTrades = getTrades();

export var isDummy = false;

export default {
  get allTrades(): Trades[] {
    // const o = old();
    // console.log("tradse", o);
    // return o;
    if (process.env.REACT_APP_DUMMY_DATA) {
      return dummyData();
    }

    // portfolio_id from query? Return from examples of local portfolios based on id.

    const qps = new URLSearchParams(window.location.search).get("portfolio");
    if (qps) {
      const pf = JSON.parse(qps);
      console.assert(pf.length && isAppTradeFormat(pf[0]));
      return pf;
    }

    const ps = localStorage.getItem("portfolio");
    if (!ps) {
      isDummy = true;
      return dummyData();
      console.warn("no portfolio");
      return [];
    }
    isDummy = false;

    const portfolio = JSON.parse(ps);

    const trades = portfolio.trades as ImportTrade[] | Trades[];
    if (isAppTradeFormat(trades)) {
      return trades
        .filter(notEmpty)
        .sort((a, b) => +new Date(a.trade_date) - +new Date(b.trade_date));
    }

    return trades
      .map(({ name, isin, price, quant, date, type }) => {
        const { code, category } =
          amfiSchemes.find((s) => s.isin === isin) ?? {};
        if (!code) {
          console.warn(`No code found for ${isin} - ${name}`);
          return null;
        }

        return {
          tradingsymbol: name,
          trade_date: date,
          trade_id: name,
          trade_type: type,
          price,
          quantity: quant,
          isin,
          code: parseInt(code, 10),
          category,
        };
      })
      .filter(notEmpty)
      .sort(
        (a, b) => +new Date(a.trade_date) - +new Date(b.trade_date),
      ) as Trades[];
  },
};
