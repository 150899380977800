import { nextDay } from "./nextDay";

export const daysBetween = (
  start: Date,
  end: Date,
  opts = {
    inclusive: false,
  },
) => {
  const arr = [];
  let currDate = start;
  while (opts.inclusive ? currDate <= end : currDate < end) {
    arr.push(new Date(currDate));
    currDate = nextDay(currDate);
  }
  return arr;
};
