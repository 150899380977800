import { MFData } from "src/instruments/mf";
import nifty50tri from "./historical_nifty50_tri";
import ntri50D from "./niftyTRI50.json";
import ntri500D from "./niftyTRI500.json";

const dtToStr = (dt: Date) => {
  const d = `0${dt.getDate()}`.slice(-2);
  const m = `0${dt.getMonth() + 1}`.slice(-2);
  const y = dt.getFullYear();
  return `${d}-${m}-${y}`;
};

interface TRINav {
  Date: string;
  TotalReturnsIndex: string;
  "Index Name": string;
}

const nifty50TRI = JSON.parse(ntri50D.d) as TRINav[];
const nifty500TRI = JSON.parse(ntri500D.d) as TRINav[];

export const getNifty50TRI = () => ({
  meta: {
    scheme_type: "Nifty 50 TRI",
    scheme_category: "reference",
    scheme_code: 1000000000,
    scheme_name: `Nifty 50 TRI`,
    fund_house: "some",
  },
  data: nifty50TRI.map((x) => ({
    date: dtToStr(new Date(Date.parse(x.Date))),
    nav: `${x.TotalReturnsIndex}`,
  })),
});

const msInDay = 3600 * 24 * 1000;

const fillMissingDays = (lsRaw: typeof nifty50TRI) => {
  const ls = lsRaw
    .map((x) => [Date.parse(x.Date), +x.TotalReturnsIndex])
    .reverse();

  const maxDate = ls[ls.length - 1][0];
  const minDate = ls[0][0];
  const days = (maxDate - minDate) / msInDay;
  const nav = [];
  const missingDays = [];
  let lastNav = ls[0][1];
  let lsIdx = 0;

  for (let i = 0; i <= days; i++) {
    const day = minDate + i * msInDay;
    if (day === ls[lsIdx][0]) {
      lastNav = ls[lsIdx][1];
      lsIdx++;
    } else {
      missingDays.push(day);
    }
    nav.push(lastNav);
  }

  return {
    nav,
    missing_days: missingDays,
    first_nav_date: minDate,
    last_nav_date: maxDate,
  };
};

export const nifty50TRINav = (): MFData => ({
  type: "mf",
  cagr: [],
  amc: "Nifty 50 TRI",
  name: "Nifty 50 TRI",
  code: 1000000000,
  isin: "1000000000",
  category: "Benchmark",
  ...fillMissingDays(nifty50TRI),
});

export const getNifty50TRIOld = () => ({
  meta: {
    scheme_type: "Nifty 50 TRI",
    scheme_category: "reference",
    scheme_code: 1000000000,
    scheme_name: `Nifty 50 TRI`,
    fund_house: "some",
  },
  data: nifty50tri.map((x) => ({
    date: dtToStr(new Date(Date.parse(x.Date))),
    nav: `${x["Total Returns Index"]}`,
  })),
});
