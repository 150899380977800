import { Outlet } from "react-router-dom";

import { useDarkWatch } from "./hooks/useDarkWatch";

function App() {
  useDarkWatch();
  return (
    <div className="min-h-screen dark:bg-slate-800 modern-gradient bg-orange-50">
      <Outlet />
    </div>
  );
}

export default App;
