import clsx from "clsx";

export const InfoPair = ({
  label,
  value,
  secondary,
  className,
}: {
  label: string;
  value: string;
  secondary?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={clsx("space-x-2", className)}>
      <span className="text-sm uppercase text-gray-500 font-medium tracking-wider dark:text-gray-400">
        {label}:
      </span>
      <span className="font-mono font-semibold dark:text-gray-300">
        {value}
      </span>
      {secondary && (
        <span className="font-mono font-semibold text-sm text-gray-500 dark:text-gray-300">
          ({secondary})
        </span>
      )}
    </div>
  );
};
