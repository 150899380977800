import { MFData } from "src/instruments/mf";

type TTradeTypes = "buy" | "sell"; /* TODO: 'switch' */

export type TTrades = [
  /** Account instrument id.

        This is a unique id common across all transactions related to a single
        financial investment, from a single account. Each demat account is a separate account.
        This is important for doing FIFO on sale. If you hold same asset (a mutual
        fund scheme) in two different accounts, selling in one, doesn't affect the
        other account. */
  act_fi_id: string,
  type: TTradeTypes,
  quantity: number,
  /** Different assets types have different name for price. E.g. nav for MF. And stock price, for stocks.  */
  price: number,
  /** unix timestamp: UTC offset */
  date: number,
];

// https://www.sebi.gov.in/sebi_data/commondocs/cirmfd112002_h.html

// https://support.zerodha.com/category/console/portfolio/holdings/articles/how-is-the-buy-average-calculated-in-q

const QUANTITY_EPSILON = 0.1;

type QuantityHoldings = number[];

const isAppoxZero = (n: number) => Math.abs(n) <= QUANTITY_EPSILON;

// TODO: What happens to cases where the asset is completely sold and then bought again?
// TODO: send warnings list
// TODO: return list of sold quantity details
export const fifoAdjustments = (
  holdings: QuantityHoldings,
  assetIdx: number[],
  soldQuantity: number,
) => {
  let remaining = soldQuantity;
  const len = assetIdx.length;
  for (let i = 0; i < len; i++) {
    const quantity = holdings[assetIdx[i]];
    const rem = quantity - remaining;
    if (!isAppoxZero(rem) && remaining > quantity) {
      remaining -= quantity;
      continue;
    }

    // Part of the quantity is sold. So, holding needs to be adjusted.
    const updatedQuantity = isAppoxZero(rem) ? 0 : rem;
    return { update: i, quantity: updatedQuantity };
  }

  // Should not reach this, unless there is still remaning after adjustments. In
  // which case, there is more withdrawn than what was bought. This should not
  // happen in real life, but could happen in simulations. Send meta-data to
  // show this on fron-end?
  console.warn("Exhausted", remaining);
  return {
    update: null,
    quantity: 0,
  };
};

const BUY_OP = 1;
const SELL_OP = 2;

type HOLDING_OPS =
  | [
      date: number,
      buy: typeof BUY_OP,
      id: string,
      quantity: number,
      price: number,
    ]
  | [
      date: number,
      sell: typeof SELL_OP,
      id: string,
      quantity: number,
      idx: number | null,
    ];

export const tradesToFifoAdjustedOps = (trades: TTrades[]) => {
  return trades.reduce<{
    ops: HOLDING_OPS[];
    holdings: number[]; // quantities held
    assets: { [k: string]: number[] };
    idx: number;
  }>(
    ({ ops, assets, idx, holdings }, [id, type, quantity, price, date]) => {
      if (type === "buy") {
        holdings.push(quantity);

        assets[id] ??= [];
        assets[id] = assets[id].concat([idx]);

        const op: HOLDING_OPS = [date, BUY_OP, id, quantity, price];

        return { ops: [...ops, op], idx: idx + 1, assets, holdings };
      }

      if (type !== "sell") throw Error(`Unknown type: '${type}'`);
      // NOTE: Special case for when you sell something, but here is no buy for
      // it. E.g Sundaram 100 buys missing from one of the portfolio trades.
      // This happens because the original units were bought in another fund,
      // which was later merged into Sundaram.
      if (!assets[id]) {
        console.warn(
          `Ignoring sell of asset ${id}. There is no asset in holding to sell.`,
        );
        return { ops, idx, assets, holdings };
      }
      const { update: updateIdx, quantity: updateQuantity } = fifoAdjustments(
        holdings,
        assets[id],
        quantity,
      );

      assets[id]
        .slice(0, updateIdx ?? assets[id].length)
        .forEach((idx) => (holdings[idx] = 0));
      if (updateIdx !== null) {
        holdings[assets[id][updateIdx]] = updateQuantity;
      }
      const op: HOLDING_OPS = [date, SELL_OP, id, updateQuantity, updateIdx];

      return {
        ops: [...ops, op],
        idx,
        assets,
        holdings,
      };
    },
    {
      ops: [],
      holdings: [],
      assets: {},
      idx: 0,
    },
  );
};

const dayMs = 3600 * 24 * 1000;
const daysBetween = (x: number, y: number, every = 1) =>
  Math.floor(Math.abs(x - y) / (every * dayMs));

/**
 * Nomalizes all holdings at any given time to add up to 1.
 * record perf changes
 *
 * TODO: Support *every*. Should return nav skipping every number of days.
 */
export const normalizeHoldingsWithNav = (
  holdingOps: ReturnType<typeof tradesToFifoAdjustedOps>,
  assets: { [k: string]: { first_nav_date: number; nav: number[] } },
  /** date till which to generate nav for, as uniq timestamp  */
  till: number,
  every = 1,
) => {
  let total = 0;
  let holdings: {
    [k: string]: [weight: number, nav: number, quantity: number][];
  } = {};
  if (!holdingOps.ops[0]) {
    return { holdings: [], total: 0, navs: [], assetWeights: new Map() };
  }

  const firstDay = holdingOps.ops[0][0];

  // let navs: number[] = [];
  let navs: number[] = new Array(daysBetween(till, firstDay)).fill(0);
  let lastNavIdx = 0;

  const assetWeights = new Map<string, { total: number; weight: number }>();
  // TODO: till should also check last date for asset navs

  // Find starting index in navs, for the asset. for each holding period (days),
  // calculate holding and for each day update with nav.
  holdingOps.ops.forEach(function calc(op, hidx) {
    const [date, typ, id, quantity] = op;

    if (typ === BUY_OP) {
      const paid = quantity * op[4];
      const ntot = total + paid;
      const assetOldTotal = assetWeights.get(id)?.total ?? 0;
      const assetTotal = assetOldTotal + paid;
      holdings[id] ??= [];
      holdings[id].forEach((h) => {
        h[0] = (h[0] * assetOldTotal) / assetTotal;
      });
      holdings[id].push([paid / assetTotal, op[4], quantity]);
      for (let [k, v] of assetWeights) {
        assetWeights.set(k, { ...v, weight: (v.weight * total) / ntot });
      }
      const aw = assetWeights.get(id) ?? { weight: 0, total: 0 };
      assetWeights.set(id, {
        weight: aw.weight + paid / ntot,
        total: assetTotal,
      });
      total = ntot;
    } else if (typ === SELL_OP) {
      // Price at the time of purchase, not sell.
      const idx = op[4];
      let sold = 0;
      if (idx === null) {
        for (let i = 0; i < holdings[id].length; i++) {
          const [_w, n, q] = holdings[id][i];
          sold += q * n;
          holdings[id][i] = [0, n, 0];
        }
        const ntot = total - sold;
        for (let [k, v] of assetWeights) {
          assetWeights.set(k, {
            ...v,
            weight: ntot === 0 ? 0 : (v.weight * total) / ntot,
          });
        }
        assetWeights.set(id, { total: 0, weight: 0 });
        console.warn("what the");
        total = ntot;
        return;
      }

      for (let i = 0; i < idx; i++) {
        const [_w, n, q] = holdings[id][i];
        sold += q * n;
        holdings[id][i] = [0, n, 0];
      }
      const [_w, n, oldQ] = holdings[id][idx];

      sold += (oldQ - quantity) * n;
      const assetOldTotal = assetWeights.get(id)?.total ?? 0;
      const assetTotal = assetOldTotal - sold;
      const ntot = total - sold;
      if (assetTotal === 0) {
        const [_w, n, _q] = holdings[id][idx];
        holdings[id][idx] = [0, n, 0];
        // for (let i = idx + 1; i < holdings[id].length; i++) {
        //   const [_w, n, _q] = holdings[id][i];
        //   holdings[id][i] = [0, n, 0];
        // }
        for (let [k, v] of assetWeights) {
          assetWeights.set(k, {
            ...v,
            weight: ntot === 0 ? 0 : (v.weight * total) / ntot,
          });
        }
        assetWeights.set(id, { total: 0, weight: 0 });
        total = ntot;
      } else {
        const nw = (quantity * n) / assetTotal;
        holdings[id][idx] = [nw, n, quantity];

        for (let i = idx + 1; i < holdings[id].length; i++) {
          holdings[id][i][0] =
            (holdings[id][i][0] * assetOldTotal) / assetTotal;
        }
        for (let [k, v] of assetWeights) {
          assetWeights.set(k, { ...v, weight: (v.weight * total) / ntot });
        }
        const aw = assetWeights.get(id) ?? { weight: 0, total: 0 };
        assetWeights.set(id, {
          weight: aw.weight - sold / ntot,
          total: assetTotal,
        });

        total = ntot;
      }
    } else {
      throw Error(`Uknown trade type: ${typ}`);
    }

    const [nextDate] = holdingOps.ops[hidx + 1] || [till];

    const holdingDays = daysBetween(nextDate, date, every);
    if (holdingDays === 0) return;
    for (let assetId in holdings) {
      const offset = daysBetween(assets[assetId].first_nav_date, date);
      const anavs = assets[assetId].nav;
      for (let [weight, nav] of holdings[assetId]) {
        const w = weight * (assetWeights.get(assetId)?.weight ?? 0);
        for (let i = 0; i < holdingDays; i++) {
          navs[lastNavIdx + i] += ((anavs[offset + i] - nav) * w) / nav;
        }
      }
    }
    lastNavIdx += holdingDays;
  });

  return { holdings, total, navs, assetWeights };
};

// TODO: compare with excel or something?
export const replaceTradeAssets = (trades: TTrades[], asset: MFData) => {
  const refTrades = trades.reduce(
    (
      {
        refTrades,
        origQuantity,
        refQuantity,
      }: {
        refTrades: TTrades[];
        origQuantity: number;
        refQuantity: number;
      },
      t,
    ) => {
      const [_id, typ, quantity, price, date] = t;
      const isBuy = typ === "buy";
      const qSign = isBuy ? 1 : -1;
      const offset = daysBetween(date, asset.first_nav_date);
      const nav = asset.nav[offset];

      let q: number;
      if (isBuy) {
        q = (quantity * price) / nav;
      } else {
        q = Math.min((quantity * price) / nav, refQuantity);
      }

      return {
        refTrades: refTrades.concat([[asset.isin, typ, q, nav, date]]),
        origQuantity: origQuantity + qSign * quantity,
        refQuantity: refQuantity + qSign * q,
      };
    },
    {
      refTrades: [],
      origQuantity: 0,
      refQuantity: 0,
    },
  ).refTrades;

  return Object.values(
    refTrades.reduce(
      (acc, [id, typ, q, n, d]) => {
        if (acc[d]) {
          return {
            ...acc,
            [d]: [id, typ, acc[d][2] + q, n, d],
          };
        }

        return { ...acc, [d]: [id, typ, q, n, d] };
      },
      {} as { [k: string]: TTrades },
    ),
    // TODO: check sorting
  ).sort((a, b) => a[4] - b[4]);
};
