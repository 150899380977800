const dummyTrades = [
  {
    trade_date: "2017-11-22",
    trade_type: "buy",
    price: 93.4304,
    quantity: 107.03154433674692,
    tradingsymbol: "HDFC Index Nifty 50 Plan - Direct Plan",
    isin: "INF179K01WM1",
  },
  {
    trade_date: "2017-11-22",
    trade_type: "buy",
    price: 53.03,
    quantity: 15.085992154318575,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2017-11-29",
    trade_type: "buy",
    price: 53.09,
    quantity: 75.34356081652638,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2017-12-07",
    trade_type: "buy",
    price: 52.21,
    quantity: 99.06154848165484,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-01-01",
    trade_type: "buy",
    price: 53.54,
    quantity: 74.71036114583073,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-02-01",
    trade_type: "buy",
    price: 55.08,
    quantity: 217.86468669641957,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-03-01",
    trade_type: "buy",
    price: 52.77,
    quantity: 227.40188173646774,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-03-05",
    trade_type: "buy",
    price: 299.046,
    quantity: 40.127579131090684,
    tradingsymbol: "HDFC Index Sensex Plan - Direct Plan",
    isin: "INF179K01WN9",
  },
  {
    trade_date: "2018-03-27",
    trade_type: "buy",
    price: 294.1872,
    quantity: 135.9679292879748,
    tradingsymbol: "HDFC Index Sensex Plan - Direct Plan",
    isin: "INF179K01WN9",
  },
  {
    trade_date: "2018-03-27",
    trade_type: "buy",
    price: 50.93,
    quantity: 785.3911915463153,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-05-02",
    trade_type: "buy",
    price: 53.16,
    quantity: 225.73348260414204,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-05-23",
    trade_type: "buy",
    price: 304.3788,
    quantity: 26.15158639950137,
    tradingsymbol: "HDFC Index Sensex Plan - Direct Plan",
    isin: "INF179K01WN9",
  },
  {
    trade_date: "2018-06-01",
    trade_type: "buy",
    price: 52.31,
    quantity: 229.40148069654833,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-06-18",
    trade_type: "buy",
    price: 50.8,
    quantity: 550.3933137602515,
    tradingsymbol: "Invesco India Contra Fund - Direct Plan",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2018-08-07",
    trade_type: "buy",
    price: 54.1,
    quantity: 369.2566079629314,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-08-27",
    trade_type: "buy",
    price: 55.11,
    quantity: 362.91021126346385,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-09-10",
    trade_type: "buy",
    price: 53.07,
    quantity: 753.7212080167336,
    tradingsymbol: "Invesco India Contra Fund - Direct Plan",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2018-09-14",
    trade_type: "buy",
    price: 55.67,
    quantity: 718.5196263238089,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2018-09-25",
    trade_type: "buy",
    price: 50.33,
    quantity: 794.7543866768546,
    tradingsymbol: "Invesco India Contra Fund - Direct Plan",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2018-10-05",
    trade_type: "buy",
    price: 51.64,
    quantity: 387.2965985809817,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-01-31",
    trade_type: "buy",
    price: 52.77,
    quantity: 758.0060057883645,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-01-31",
    trade_type: "buy",
    price: 48.86,
    quantity: 818.6651742417147,
    tradingsymbol: "Invesco India Contra Fund - Direct Plan",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2019-04-22",
    trade_type: "buy",
    price: 56.02,
    quantity: 357.01501432934356,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-05-07",
    trade_type: "buy",
    price: 54.86,
    quantity: 364.5642104032784,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-05-09",
    trade_type: "buy",
    price: 54.37,
    quantity: 367.84980869455705,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-05-17",
    trade_type: "buy",
    price: 970.8278,
    quantity: 41.201978572334205,
    tradingsymbol: "Franklin India Prima Fund - Direct Plan",
    isin: "INF090I01FH9",
  },
  {
    trade_date: "2019-06-03",
    trade_type: "buy",
    price: 79.3845,
    quantity: 251.93826897597606,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-06-20",
    trade_type: "buy",
    price: 77.8627,
    quantity: 513.7245328303644,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-07-12",
    trade_type: "buy",
    price: 76.0634,
    quantity: 525.8769265103421,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-07-22",
    trade_type: "buy",
    price: 74.7954,
    quantity: 267.39586093703895,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-07-25",
    trade_type: "buy",
    price: 74.2279,
    quantity: 538.8805197476413,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-08-22",
    trade_type: "buy",
    price: 71.0167,
    quantity: 563.2473070753524,
    tradingsymbol: "UTI Nifty Index Fund - Direct Plan",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2019-10-29",
    trade_type: "buy",
    price: 52.35,
    quantity: 764.087602625545,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-12-02",
    trade_type: "buy",
    price: 53.28,
    quantity: 750.7504095617389,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2019-12-09",
    trade_type: "buy",
    price: 52.45,
    quantity: 762.6308033831738,
    tradingsymbol: "Quantum Long Term Equity Value Fund - Direct Plan",
    isin: "INF082J01036",
  },
  {
    trade_date: "2020-03-03",
    trade_type: "buy",
    price: 49.12,
    quantity: 814.3319764952548,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2020-03-09",
    trade_type: "buy",
    price: 69.2763,
    quantity: 577.3976997162425,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2020-03-11",
    trade_type: "buy",
    price: 69.3218,
    quantity: 577.0188999132428,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2020-03-16",
    trade_type: "buy",
    price: 60.8903,
    quantity: 656.9188583601508,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2020-03-16",
    trade_type: "buy",
    price: 40.08,
    quantity: 998.0034809743177,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2020-03-19",
    trade_type: "buy",
    price: 54.6744,
    quantity: 731.6036195192951,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2020-04-03",
    trade_type: "buy",
    price: 53.5131,
    quantity: 747.4800112625553,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2020-04-03",
    trade_type: "buy",
    price: 35.25,
    quantity: 1134.7510098568507,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2020-07-07",
    trade_type: "buy",
    price: 45.91,
    quantity: 871.225946906749,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2020-08-31",
    trade_type: "buy",
    price: 32.3832,
    quantity: 2470.291915290105,
    tradingsymbol: "PARAG PARIKH LONG TERM EQUITY FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2020-09-15",
    trade_type: "buy",
    price: 32.5242,
    quantity: 1229.7913604297858,
    tradingsymbol: "PARAG PARIKH LONG TERM EQUITY FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2020-09-16",
    trade_type: "buy",
    price: 32.7755,
    quantity: 1220.3621653335708,
    tradingsymbol: "PARAG PARIKH LONG TERM EQUITY FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2020-10-26",
    trade_type: "buy",
    price: 3050.855,
    quantity: 131.10433181735678,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2020-10-28",
    trade_type: "buy",
    price: 3051.3519,
    quantity: 131.08273182859014,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2020-11-02",
    trade_type: "buy",
    price: 3052.621,
    quantity: 131.0283318568816,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2020-11-24",
    trade_type: "buy",
    price: 3058.4179,
    quantity: 130.77993198606544,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2020-12-01",
    trade_type: "buy",
    price: 35.5085,
    quantity: 1126.4338141823246,
    tradingsymbol: "PARAG PARIKH LONG TERM EQUITY FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-02-22",
    trade_type: "buy",
    price: 39.3981,
    quantity: 2030.4521440349358,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-03-01",
    trade_type: "sell",
    price: 3083.289,
    quantity: 27.99998543821557,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-03-02",
    trade_type: "buy",
    price: 39.8112,
    quantity: 2009.3833549920546,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-03-03",
    trade_type: "buy",
    price: 39.7114,
    quantity: 2014.4329523659408,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-03-10",
    trade_type: "sell",
    price: 3085.6816,
    quantity: 71.99996255541146,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-03-12",
    trade_type: "buy",
    price: 39.9518,
    quantity: 5706.588632208689,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-03-16",
    trade_type: "sell",
    price: 3086.9407,
    quantity: 133.1999307275112,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-03-18",
    trade_type: "buy",
    price: 40.0461,
    quantity: 5283.643252167355,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-03-18",
    trade_type: "buy",
    price: 97.1067,
    quantity: 2059.4861289354053,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2021-03-24",
    trade_type: "sell",
    price: 3089.6051,
    quantity: 129.99993239171513,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-03-26",
    trade_type: "buy",
    price: 96.7892,
    quantity: 2066.241725422063,
    tradingsymbol: "UTI NIFTY INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2021-03-26",
    trade_type: "buy",
    price: 65.79,
    quantity: 3039.822019098001,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2021-03-31",
    trade_type: "sell",
    price: 3092.8978,
    quantity: 67.99996463566639,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-04-05",
    trade_type: "buy",
    price: 70.2,
    quantity: 2991.302044331493,
    tradingsymbol: "INVESCO INDIA CONTRA FUND - DIRECT PLAN",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2021-08-10",
    trade_type: "sell",
    price: 3128.776,
    quantity: 39.99997919745081,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-08-13",
    trade_type: "buy",
    price: 48.8012,
    quantity: 2612.9594410938607,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2021-08-17",
    trade_type: "sell",
    price: 3130.9751,
    quantity: 52.79557254292335,
    tradingsymbol: "FRANKLIN INDIA LIQUID FUND - DIRECT PLAN",
    isin: "INF090I01JV2",
  },
  {
    trade_date: "2021-08-20",
    trade_type: "buy",
    price: 48.2933,
    quantity: 3422.7034199751683,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2022-05-16",
    trade_type: "sell",
    price: 71.93,
    quantity: 3077.99839924384,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2022-05-24",
    trade_type: "buy",
    price: 85.2233,
    quantity: 1407.9944677527649,
    tradingsymbol: "NIPPON INDIA SMALL CAP FUND - DIRECT PLAN",
    isin: "INF204K01K15",
  },
  {
    trade_date: "2022-05-24",
    trade_type: "buy",
    price: 36.35,
    quantity: 2783.9033521920865,
    tradingsymbol: "AXIS SMALL CAP FUND - DIRECT PLAN",
    isin: "INF846K01K43",
  },
  {
    trade_date: "2022-08-08",
    trade_type: "sell",
    price: 78.45,
    quantity: 2919.9984814139093,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2022-09-26",
    trade_type: "buy",
    price: 50.1478,
    quantity: 1595.20357039205,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2022-09-29",
    trade_type: "buy",
    price: 49.7331,
    quantity: 804.2527817370812,
    tradingsymbol: "PARAG PARIKH FLEXI CAP FUND - DIRECT PLAN",
    isin: "INF879O01027",
  },
  {
    trade_date: "2022-10-03",
    trade_type: "sell",
    price: 159.5891,
    quantity: 107.03154433674692,
    tradingsymbol: "HDFC INDEX NIFTY 50 PLAN - DIRECT PLAN",
    isin: "INF179K01WM1",
  },
  {
    trade_date: "2022-10-03",
    trade_type: "sell",
    price: 523.4954,
    quantity: 202.24709481856686,
    tradingsymbol: "HDFC INDEX S&P BSE SENSEX FUND - DIRECT PLAN",
    isin: "INF179K01WN9",
  },
  {
    trade_date: "2022-10-04",
    trade_type: "sell",
    price: 1670.9947,
    quantity: 41.201978572334205,
    tradingsymbol: "FRANKLIN INDIA PRIMA FUND - DIRECT PLAN",
    isin: "INF090I01FH9",
  },
  {
    trade_date: "2022-10-04",
    trade_type: "sell",
    price: 78.18,
    quantity: 8773.018637468835,
    tradingsymbol: "QUANTUM LONG TERM EQUITY VALUE FUND - DIRECT PLAN",
    isin: "INF082J01036",
  },
  {
    trade_date: "2022-10-04",
    trade_type: "sell",
    price: 117.2595,
    quantity: 3999.997919745081,
    tradingsymbol: "UTI NIFTY 50 INDEX FUND - DIRECT PLAN",
    isin: "INF789F01XA0",
  },
  {
    trade_date: "2022-10-13",
    trade_type: "buy",
    price: 7.4138,
    quantity: 26975.356371088263,
    tradingsymbol: "ADITYA BIRLA SUN LIFE NASDAQ 100 FOF - DIRECT PLAN",
    isin: "INF209KB19C3",
  },
  {
    trade_date: "2022-10-17",
    trade_type: "sell",
    price: 7.5252,
    quantity: 26975.356371088263,
    tradingsymbol: "ADITYA BIRLA SUN LIFE NASDAQ 100 FOF - DIRECT PLAN",
    isin: "INF209KB19C3",
  },
  {
    trade_date: "2022-10-18",
    trade_type: "buy",
    price: 7.5556,
    quantity: 26839.66244165779,
    tradingsymbol: "ADITYA BIRLA SUN LIFE NASDAQ 100 FOF - DIRECT PLAN",
    isin: "INF209KB18C5",
  },
  {
    trade_date: "2022-10-21",
    trade_type: "sell",
    price: 40.54,
    quantity: 2783.9033521920865,
    tradingsymbol: "AXIS SMALL CAP FUND - DIRECT PLAN",
    isin: "INF846K01K43",
  },
  {
    trade_date: "2022-10-21",
    trade_type: "buy",
    price: 89.5,
    quantity: 2234.5240379044903,
    tradingsymbol: "INVESCO INDIA CONTRA FUND - DIRECT PLAN",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2022-10-25",
    trade_type: "sell",
    price: 90.32,
    quantity: 5908.796927047434,
    tradingsymbol: "INVESCO INDIA CONTRA FUND - DIRECT PLAN",
    isin: "INF205K01LE4",
  },
  {
    trade_date: "2022-11-07",
    trade_type: "buy",
    price: 102.1297,
    quantity: 1958.1953816130767,
    tradingsymbol: "NIPPON INDIA SMALL CAP FUND - DIRECT PLAN",
    isin: "INF204K01K15",
  },
  {
    trade_date: "2022-11-15",
    trade_type: "buy",
    price: 101.2859,
    quantity: 1974.5085731291733,
    tradingsymbol: "NIPPON INDIA SMALL CAP FUND - DIRECT PLAN",
    isin: "INF204K01K15",
  },
  {
    trade_date: "2023-01-05",
    trade_type: "buy",
    price: 18.996,
    quantity: 2105.6001049531865,
    tradingsymbol: "MOTILAL OSWAL NASDAQ 100 FUND OF FUND - DIRECT PLAN",
    isin: "INF247L01718",
  },
  {
    trade_date: "2023-04-11",
    trade_type: "buy",
    price: 3531.0069,
    quantity: 113.27634108905292,
    tradingsymbol: "SBI LIQUID FUND - DIRECT PLAN",
    isin: "INF200K01UT4",
  },
  {
    trade_date: "2023-04-12",
    trade_type: "buy",
    price: 4433.8073,
    quantity: 90.21155308421885,
    tradingsymbol: "HDFC LIQUID FUND - DIRECT PLAN",
    isin: "INF179KB1HP9",
  },
  {
    trade_date: "2023-04-12",
    trade_type: "buy",
    price: 333.9776,
    quantity: 1197.6245771585718,
    tradingsymbol: "ICICI PRUDENTIAL LIQUID FUND - DIRECT PLAN",
    isin: "INF109K01Q49",
  },
  {
    trade_date: "2023-04-13",
    trade_type: "buy",
    price: 3532.5607,
    quantity: 56.61317055752806,
    tradingsymbol: "SBI LIQUID FUND - DIRECT PLAN",
    isin: "INF200K01UT4",
  },
  {
    trade_date: "2023-05-04",
    trade_type: "sell",
    price: 3547.9961,
    quantity: 5.999996879617622,
    tradingsymbol: "SBI LIQUID FUND - DIRECT PLAN",
    isin: "INF200K01UT4",
  },
  {
    trade_date: "2023-06-02",
    trade_type: "sell",
    price: 3567.2812,
    quantity: 9.999994799362703,
    tradingsymbol: "SBI LIQUID FUND - DIRECT PLAN",
    isin: "INF200K01UT4",
  },
  {
    trade_date: "2023-06-07",
    trade_type: "sell",
    price: 94.59,
    quantity: 2234.563237884104,
    tradingsymbol: "Invesco India Contra Fund - Direct Plan",
    isin: "INF205K01LE4",
  },
];
export default dummyTrades;
