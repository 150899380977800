import { add } from "date-fns";
import { MFData } from "./mfAPINavData";
import { NavComparisonType } from "src/types";

export type * from "./mfAPINavData";

export * from "./mfAPINavData";

type PNormalizeTrailing = {
  mfs: MFData[];
  comparison: NavComparisonType;
};

const dayMs = 3600 * 24 * 1000;

const notImplemented = (err: string, val: never): never => {
  throw new Error(`${err}: ${val}`);
};

export const normalizeNav = ({ mfs, comparison }: PNormalizeTrailing) => {
  switch (comparison.type) {
    case "trailing":
      return mfs.map(({ nav: onav, ...rest }) => {
        const sell = onav[onav.length - 1];
        const nav = onav.map((buy) => (sell - buy) / buy);
        const len = nav.length;
        return {
          ...rest,
          nav,
          cagr: nav.map((nav, i) =>
            len - i > 360 ? Math.pow(1 + nav, 360 / (len - i)) - 1 : nav,
          ),
        };
      });

    case "leading":
      return mfs.map(({ nav: onav, ...rest }) => {
        const buy = onav[0];
        const nav = onav.map((sell) => (sell - buy) / buy);
        return {
          ...rest,
          nav,
          cagr: nav.map((nav, i) =>
            i > 360 ? Math.pow(1 + nav, 360 / i) - 1 : nav,
          ),
        };
      });

    case "rolling":
      return mfs.map(({ nav: onav, ...rest }) => {
        let nav = [];
        const period = comparison.days[0];
        for (let i = onav.length - 1; i > period; i--) {
          nav.push((onav[i] - onav[i - period]) / onav[i - period]);
        }
        nav = nav.reverse();

        return {
          ...rest,
          first_nav_date: +rest.first_nav_date + period * dayMs,
          nav,
          cagr: nav.map((n) =>
            period > 360 ? Math.pow(1 + n, 360 / period) - 1 : n,
          ),
        };
      });
  }
  notImplemented(`Type not implemented`, comparison);
};

export const toPlotData = (mfs: MFData[]) => {
  return mfs
    .map(({ nav, name, first_nav_date, cagr }) => {
      return nav.map((nav, i) => ({
        day: +first_nav_date + i * dayMs,
        nav,
        name,
        cagr: cagr[i],
      }));
    })
    .flatMap((x) => x);
};
