import MiniSearch from "minisearch";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";

import data from "../../data/consolidated.json";

interface ZERODHA {
  tradingsymbol: string;
  amc: string;
  name: string;
  purchase_allowed: string;
  redemption_allowed: string;
  minimum_purchase_amount: string;
  purchase_amount_multiplier: string;
  minimum_additional_purchase_amount: string;
  minimum_redemption_quantity: string;
  redemption_quantity_multiplier: string;
  dividend_type: string;
  scheme_type: string;
  plan: string;
  settlement_type: string;
  last_price: string;
  last_price_date: string;
}

type MFMetaData = {
  code: number;
  mfapi: {
    schemeCode: number;
    schemeName: string;
  };
  amfi: {
    amc: string;
    code: string;
    name: string;
    category: string;
    navName: string;
    /** number as string  */
    minAmount: string;
    /** 1994-01-01  */
    launchDate: string;
    closeData: string;
    isin: string | null;
    isinRe: string | null;
  };
};

const mfList = (data as MFMetaData[])
  ?.filter((d) => d.amfi)
  .map((d) => {
    return {
      label: d.amfi.navName,
      value: +d.amfi.code,
      /* schemePlan:
       *   d.zerodha?.plan ||
       *   (d.amfi.navName.toLowerCase().includes("regular")
       *     ? "regular"
       *     : "direct"),
       * schemeType:
       *   d.zerodha?.scheme_type?.toLowerCase() ||
       *   (d.amfi.navName.toLowerCase().includes("equity") ? "equity" : "debt"),
       * dividendType:
       *   d.zerodha?.dividend_type?.toLowerCase() ||
       *   (d.amfi.navName.toLowerCase().includes("idcw") ? "idcw" : "growth"), */
      /* name: d.amfi.navName,
       * id: +d.amfi.code, */
    };
  });

const options = new MiniSearch({
  idField: "value",
  fields: ["label"],
  storeFields: ["label", "value"],
});

options.addAll(mfList);

interface Props {
  onChange: (mfs: any[]) => void;
  defaultValues: Array<number | string>;
}

export function MFSchemeSearch({ onChange, defaultValues }: Props) {
  const handleQuery = debounce((query, cb) => {
    if (query.length < 3) {
      console.warn("less than 3 letters");
      return;
    }
    console.log("searching: ", query);
    console.time("query");

    const res = options.search(query, { prefix: true });
    console.timeEnd("query");
    cb(res);
    console.log("set mfs");
  }, 300);

  return (
    <AsyncSelect
      defaultValue={mfList.filter((d) => defaultValues.includes(d.value))}
      defaultOptions
      loadOptions={handleQuery}
      isMulti
      onChange={(ls) => onChange(ls.map((s) => s.value))}
    />
  );
}
