import { ShareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useCallback } from "react";
import { normalizeTrades } from "src/data/all_trades";
import { Trades } from "src/types";
import { downloadBase64File } from "src/utils/downloadStrAsFile";

type PShareAnanymous = {
  trades: Trades[];
};

export const ShareAnonymous = ({ trades }: PShareAnanymous) => {
  const copyAnonTrades = useCallback(() => {
    downloadBase64File(
      btoa(JSON.stringify({ trades: normalizeTrades(trades) })),
      "anonymized-portfolio.json",
    );
  }, [trades]);

  return (
    <div
      onClick={copyAnonTrades}
      className={clsx(
        "flex flex-row items-center space-x-2 p-1 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer",
        "bg-gray-50  hover:bg-gray-100",
        "bg-orange-100 text-orange-800 hover:bg-orange-200",
        "dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 dark:text-gray-300",
      )}
    >
      <span>Share Anonymized Portfolio</span>
      <ShareIcon className="w-4 h-4" />
    </div>
  );
};
