export const DemoInfo = ({}) => {
  return (
    <div className="py-6 px-4 border bg-orange-200 mb-4 space-y-2">
      <div>
        This is a demo.{" "}
        <b>
          The intent of the product is to understand how onces portfolio
          performance fairs, based on the decisions they have made.
        </b>{" "}
        E.g Am I making better decisions that putting everything in index fund?
        Which decisions are bad? Which schemes are non performing? How is my
        market timing? Etc.
      </div>
      <div>
        The portfolio you see below is my portfolio, but with anonymised
        absolute amounts. E.g the dates of investment, the proportions of
        investments, the current split across investments, and the current
        return, are all same. But the absolute numbers are not.
      </div>
      <div>
        The main chart below shows comparison of my portfolio return, with the
        same trades done on nifty 50. E.g. replace all the schemes with nifty 50
        benchmark scheme. <b>Relative</b> checkbox makes the nifty 50 index
        return as basline, for easier comparison. Boxes below the main chart are
        clickable, and show performance of individual investments. And click on
        'Select All' shows the scheme categories performance.
      </div>
      <div className="text-gray-500">
        Please ignore the `Click to upload` button. I built an extension to
        extract data from zerodha/kuvera and save in a specific file format.
        Without the extension, you won't be able to generate it.
      </div>
    </div>
  );
};
