import { RateInterval, convertRate, xirr } from "node-irr";
import { Trades } from "src/types";

export const yearXirrPerc = (cashflows: { amount: number; date: Date }[]) => {
  const { rate } = xirr(cashflows);
  return convertRate(rate, RateInterval.Year);
};

export const xirrForTrades = (trades: Trades[], value: number, date: Date) => {
  return yearXirrPerc(
    trades
      .map((x) => ({
        amount: x.quantity * x.price * (x.trade_type === "buy" ? 1 : -1),
        date: new Date(x.trade_date),
      }))
      .concat([{ amount: value, date }]),
  );
};
