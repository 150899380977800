import amfiSchemes from "src/data/amfi-schemes";
import { daysBetween } from "src/utils/daysBetween";

type MFResp = {
  meta: {
    fund_house: string;
    scheme_type: string;
    scheme_category: string;
    scheme_code: number;
    scheme_name: string;
  };
  /** Sorted descending. From now to past. 5 digit precision */
  data: { nav: string; date: string }[];
};

export type MFData = {
  first_nav_date: number;
  last_nav_date: number;
  missing_days: number[];
  nav: number[];
  cagr: number[];
  type: string;
  category: string;
  code: number;
  name: string;
  amc: string;
  isin: string;
};

const codeToISIN = (code: number | string) => {
  const scheme = amfiSchemes.find((x) => `${x.code}` === `${code}`);
  if (code === 1000000000) {
    return `${code}`;
  }
  return scheme?.isin;
};

const parseDate = (s = "29-08-2011") => {
  const [sd, sm, y] = s.split("-").map((x) => parseInt(x, 10));
  const d = `0${sd}`.slice(-2);
  const m = `0${sm}`.slice(-2);
  return `${y}-${m}-${d}`;
};

const fetchJSON = async (url: string) => {
  const { status, data, meta } = await fetch(url).then((x) => x.json());
  if (status !== "SUCCESS") {
    throw Error(
      `Expected ${url} api response to have status SUCCESS ${JSON.stringify(
        { status, data },
        null,
        2,
      )}`,
    );
  }
  return { data, meta, status } as MFResp;
};

type PTransform = {
  mfs: MFResp[];
  startDate?: Date;
  endDate?: Date;
};

// Ignore IDCW (can't do normal nav calculations for return)
// get min and max dates. Generate all dates between. Fill nav for all dates (from the date before).
const transform = ({ mfs, startDate, endDate }: PTransform) => {
  const endDates = mfs.map((f) => +new Date(parseDate(f.data[0]?.date)));
  const lastEndDate = new Date(Math.max(...endDates));

  if (endDate) {
    console.warn("endDate is nor supported yet");
  }
  const sorted = mfs.map(({ data, meta }) => {
    const od = data
      .map(({ date, nav }) => ({
        date: +new Date(parseDate(date)),
        nav: +nav,
      }))
      .filter((x) => (startDate ? x.date >= +startDate : true));

    const count = od.length - 1;
    // TODO: unix timestamp and simpler calculation for days (+ 3600*24*1000)
    const maxDate = new Date(od[0].date);
    const minDate = new Date(od[count].date);
    const days = daysBetween(minDate, lastEndDate, { inclusive: true }).map(
      (d) => +d,
    );
    let oidx = count;
    let lastNav = +od[count].nav;
    const missingDays: number[] = [];

    const nd = days.map((day) => {
      if (day === od[oidx]?.date) {
        lastNav = od[oidx].nav;
        oidx -= 1;
        return lastNav;
      }
      missingDays.push(day);
      return lastNav;
    });

    return {
      name: meta.scheme_name,
      amc: meta.fund_house,
      isin: codeToISIN(meta.scheme_code),
      category: meta.scheme_category,
      code: meta.scheme_code,
      type: meta.scheme_type,
      nav: nd,
      first_nav_date: +minDate,
      last_nav_date: +maxDate,
      missing_days: missingDays,
    } as MFData;
  });

  return sorted;
};

export const fetchMFs = (schemeCodes: number[]) =>
  Promise.all(
    schemeCodes.map((s) => fetchJSON(`https://api.mfapi.in/mf/${s}`)),
  ) as Promise<MFResp[]>;

type PGetData = {
  codes?: number[];
  isins?: string[];
  startDate?: Date;
  endDate?: Date;
};

export const getMFData = async ({
  codes,
  isins,
  startDate,
  endDate,
}: PGetData) => {
  if (isins) {
    console.error("not implemented");
    return;
  }

  if (!codes || !codes.length) {
    console.error("Missing `codes` params");
    return;
  }

  const mfs = await fetchMFs(codes);

  return transform({ mfs, startDate, endDate });
};
