import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode, useCallback, useState } from "react";
import { Trades } from "src/types";

type TPortfolioMFCardGroup = {
  group: {
    isin: string;
    code: number;
    trades: Trades[];
    quantity: number;
    category: any;
  }[];
  name: string;
  onSelectGroup: (isins: string[]) => void;
  startDate: string | number;
  expandable?: boolean;
  collapsed?: boolean;
  showCompareMFLink?: boolean;
  children: ReactNode;
  total: number;
};

export const PortfolioMFCardGroup = ({
  group,
  name,
  startDate,
  expandable,
  collapsed,
  showCompareMFLink,
  onSelectGroup,
  children,
  total,
}: TPortfolioMFCardGroup) => {
  const [isCollapsed, setIsCollapsed] = useState(
    expandable ? collapsed : false,
  );
  const onToggleCollapse = useCallback(
    () => setIsCollapsed((collapsed) => !collapsed),
    [],
  );

  const selectGroup = useCallback(
    () => onSelectGroup(group.map((x) => x.isin)),
    [group, onSelectGroup],
  );

  if (!group.length) return null;

  return (
    <div>
      <div className="flex flex-row space-x-2 items-center">
        <span className="font-bold text-lg pb-2 underline dark:text-gray-200">
          {name}
        </span>
        <button
          className="pb-2 underline text-blue-500 hover:text-blue-700"
          onClick={selectGroup}
        >
          Select all
        </button>
        {showCompareMFLink && (
          <a
            className="pb-2 underline text-blue-500 hover:text-blue-700"
            href={`compare/trailing?codes=${encodeURIComponent(
              group.map((x) => x.code).join(","),
            )}&start=${startDate}`}
          >
            Compare
          </a>
        )}
        {expandable && (
          <span className="pb-2" onClick={onToggleCollapse}>
            {isCollapsed ? (
              <ChevronDownIcon className="w-4 h-4" />
            ) : (
              <ChevronRightIcon className="w-4 h-4" />
            )}
          </span>
        )}

        {total && (
          <span className="px-3 pb-2 font-bold text-slate-600 dark:text-slate-300">
            {(total * 100)?.toFixed(2)}%
          </span>
        )}
      </div>
      <div
        className={clsx(
          "grid grid-cols-3 lg:grid-cols-4 gap-4",
          isCollapsed && "hidden",
        )}
      >
        {children}
      </div>
    </div>
  );
};
