export default [
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100033",
    "name": "Aditya Birla Sun Life Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Equity Advantage Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "1995-02-24",
    "closeData": "1995-02-24",
    "isin": "INF209K01165",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100034",
    "name": "Aditya Birla Sun Life Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Equity Advantage Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1995-02-24",
    "closeData": "1995-02-24",
    "isin": "INF209K01157",
    "isinRe": "INF209K01CE5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119433",
    "name": "Aditya Birla Sun Life Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Equity Advantage Fund -Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "1995-02-24",
    "closeData": "1995-02-24",
    "isin": "INF209K01VG0",
    "isinRe": "INF209K01VI6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119436",
    "name": "Aditya Birla Sun Life Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Equity Advantage Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1995-02-24",
    "closeData": "1995-02-24",
    "isin": "INF209K01VH8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100037",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Regular - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01587",
    "isinRe": "INF209K01CY3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100038",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01579",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100039",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Birla Income Plus-Institutional (Growth)",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100040",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Birla Income Plus-Institutional (Dividend)",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111777",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund (Discipline Advantage Plan)",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01LD8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119655",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Direct - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01WZ8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119657",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01WY1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133445",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209KA1WJ6",
    "isinRe": "INF209KA1WK4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133446",
    "name": "Aditya Birla Sun Life Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Aditya Birla Sun Life Income Fund - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209KA1WL2",
    "isinRe": "INF209KA1WM0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100041",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Institutional - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01KN9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100042",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund-Retail (Growth)",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01ME4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100043",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund-Institutional (Growth)",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01RT1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100044",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Retail - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01KP4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100046",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Daily IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01KQ2",
    "isinRe": "INF209K01SQ5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100047",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund - Growth",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01RU9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "112014",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund- Discipline Advantage Plan",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01KZ3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119568",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01VA3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119569",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Direct - daily IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01UZ2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119570",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Direct - weekly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01VC9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119571",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01VD7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100048",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Institutional - weekly  IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209KA1LK7",
    "isinRe": "INF209K01KY6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100050",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Birla Cash Plus-Birla Cash Plus (Institutional Premium Weekly Dividend)",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100051",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -weekly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209K01KU4",
    "isinRe": "INF209KA1LJ9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103885",
    "name": "Aditya Birla Sun Life Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Aditya Birla Sun Life Liquid Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-06-04",
    "closeData": "1997-06-07",
    "isin": "INF209KA1LI1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100057",
    "name": "Aditya Birla Sun Life Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Aditya Birla Sun Life Govenment Securities Fund -Regular - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1999-10-12",
    "closeData": "1999-10-12",
    "isin": "INF209K01AF6",
    "isinRe": "INF209K01DP9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100058",
    "name": "Aditya Birla Sun Life Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Aditya Birla Sun Life Government Securities Fund  - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1999-10-12",
    "closeData": "1999-10-12",
    "isin": "INF209K01AC3",
    "isinRe": "INF209K01AD1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119605",
    "name": "Aditya Birla Sun Life Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Aditya Birla Sun Life Government Securities Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1999-10-12",
    "closeData": "1999-10-12",
    "isin": "INF209K01XP7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119606",
    "name": "Aditya Birla Sun Life Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Aditya Birla Sun Life Govenment Securities Fund -DIRECT - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1999-10-12",
    "closeData": "1999-10-12",
    "isin": "INF209KA1LC4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100063",
    "name": "Aditya Birla Sun Life MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life MNC Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1998-11-16",
    "closeData": "1998-11-16",
    "isin": "INF209K01314",
    "isinRe": "INF209K01CL0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "100064",
    "name": "Aditya Birla Sun Life MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life MNC Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1998-11-16",
    "closeData": "1998-11-16",
    "isin": "INF209K01322",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119646",
    "name": "Aditya Birla Sun Life MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life MNC Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1998-11-16",
    "closeData": "1998-11-16",
    "isin": "INF209K01VT3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119647",
    "name": "Aditya Birla Sun Life MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life MNC Fund - DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1998-11-16",
    "closeData": "1998-11-16",
    "isin": "INF209K01Q06",
    "isinRe": "INF209K01VU1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100077",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01565",
    "isinRe": "INF740K01995"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100078",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01557",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100079",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01573",
    "isinRe": "INF740K01AA1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118921",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01NA4",
    "isinRe": "INF740K01NC0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118922",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01NB2",
    "isinRe": "INF740K01ND8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118924",
    "name": "DSP Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "DSP Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-07",
    "closeData": "1997-04-15",
    "isin": "INF740K01MZ3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100080",
    "name": "DSP Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "DSP Flexi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-04",
    "closeData": "1997-04-15",
    "isin": "INF740K01011",
    "isinRe": "INF740K01029"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105875",
    "name": "DSP Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "DSP Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-04",
    "closeData": "1997-04-15",
    "isin": "INF740K01037",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119076",
    "name": "DSP Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "DSP Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-04",
    "closeData": "1997-04-15",
    "isin": "INF740K01PI2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119077",
    "name": "DSP Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "DSP Flexi Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1997-04-04",
    "closeData": "1997-04-15",
    "isin": "INF740K01PJ0",
    "isinRe": "INF740K01PK8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119019",
    "name": "DSP Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "DSP Equity & Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-05-03",
    "closeData": "1999-05-14",
    "isin": "INF740K01NY4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119020",
    "name": "DSP Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "DSP Equity & Bond Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-05-03",
    "closeData": "1999-05-14",
    "isin": "INF740K01NZ1",
    "isinRe": "INF740K01OA2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100081",
    "name": "DSP Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "DSP Equity & Bond Fund- Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-05-03",
    "closeData": "1999-05-14",
    "isin": "INF740K01318",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100082",
    "name": "DSP Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "DSP Equity & Bond Fund- Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-05-03",
    "closeData": "1999-05-14",
    "isin": "INF740K01326",
    "isinRe": "INF740K01334"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100084",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01615",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100085",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01607",
    "isinRe": "INF740K01AD5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100086",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01623",
    "isinRe": "INF740K01AE3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119099",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NF3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119100",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NE6",
    "isinRe": "INF740K01NH9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119101",
    "name": "DSP Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DSP Government Securities Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NG1",
    "isinRe": "INF740K01NI7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100087",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01714",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100088",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01706",
    "isinRe": "INF740K01AJ2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100089",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01722",
    "isinRe": "INF740K01AK0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119106",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NU2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119107",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NT4",
    "isinRe": "INF740K01NW8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119108",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740K01NV0",
    "isinRe": "INF740K01NX6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "123287",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740KA1PC1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "123288",
    "name": "DSP Savings Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "DSP Savings Fund - Direct Plan -  IDCW - Daily",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "1999-09-20",
    "closeData": "1999-09-23",
    "isin": "INF740KA1PB3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "103819",
    "name": "DSP Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "DSP Equity Opportunities Fund-Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2000-03-11",
    "closeData": "2000-04-18",
    "isin": "INF740K01094",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "103820",
    "name": "DSP Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "DSP Equity Opportunities Fund-Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2000-03-11",
    "closeData": "2000-04-18",
    "isin": "INF740K01078",
    "isinRe": "INF740K01086"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119218",
    "name": "DSP Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "DSP Equity Opportunities Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2000-03-11",
    "closeData": "2000-04-18",
    "isin": "INF740K01PL6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119219",
    "name": "DSP Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "DSP Equity Opportunities Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2000-03-11",
    "closeData": "2000-04-18",
    "isin": "INF740K01PM4",
    "isinRe": "INF740K01PN2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100119",
    "name": "HDFC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HDFC Balanced Advantage Fund - Growth Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "2000-07-20",
    "closeData": "2000-08-10",
    "isin": "INF179K01830",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100120",
    "name": "HDFC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HDFC Balanced Advantage Fund - IDCW Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "2000-07-20",
    "closeData": "2000-08-10",
    "isin": "INF179K01814",
    "isinRe": "INF179K01822"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118968",
    "name": "HDFC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HDFC Balanced Advantage Fund - Growth Plan - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "2000-07-20",
    "closeData": "2000-08-10",
    "isin": "INF179K01WA6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118969",
    "name": "HDFC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HDFC Balanced Advantage Fund - IDCW Plan - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "2000-07-20",
    "closeData": "2000-08-10",
    "isin": "INF179K01VY8",
    "isinRe": "INF179K01VZ5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100123",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Quarterly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179K01947",
    "isinRe": "INF179K01954"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100124",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Growth Option",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179K01962",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100125",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plan Dividend",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100126",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plus Dividend",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100127",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plus Growth",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100128",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plan Growth",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100129",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plus Growth",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100130",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund-Premium Plus Dividend",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119068",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Quarterly IDCW- Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179K01WJ7",
    "isinRe": "INF179K01WK5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119069",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179K01WL3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133365",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Normal IDCW - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179KA1Q12",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133366",
    "name": "HDFC Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HDFC Income Fund - Normal IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "2000-09-11",
    "closeData": "",
    "isin": "INF179KA1Q38",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100174",
    "name": "Quant Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "quant Tax Plan - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2000-03-08",
    "closeData": "2000-03-31",
    "isin": "INF966L01119",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100175",
    "name": "Quant Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "quant Tax Plan - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2000-03-08",
    "closeData": "2000-03-31",
    "isin": "INF966L01135",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120846",
    "name": "Quant Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "quant Tax Plan - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2000-03-08",
    "closeData": "2000-03-31",
    "isin": "INF966L01960",
    "isinRe": "INF966L01127"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120847",
    "name": "Quant Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "quant Tax Plan - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2000-03-08",
    "closeData": "2000-03-31",
    "isin": "INF966L01986",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100176",
    "name": "Quant Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "quant Small Cap Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1996-09-23",
    "closeData": "1996-10-30",
    "isin": "INF966L01036",
    "isinRe": "INF966L01044"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100177",
    "name": "Quant Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "quant Small Cap Fund - Growth - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1996-09-23",
    "closeData": "1996-10-30",
    "isin": "INF966L01AA0",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120827",
    "name": "Quant Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "quant Small Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1996-09-23",
    "closeData": "1996-10-30",
    "isin": "INF966L01663",
    "isinRe": "INF966L01671"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120828",
    "name": "Quant Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "quant Small Cap Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1996-09-23",
    "closeData": "1996-10-30",
    "isin": "INF966L01689",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100218",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01585",
    "isinRe": "INF192K01593"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100219",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01601",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134960",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JN9",
    "isinRe": "INF192K01JO7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134961",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JS8",
    "isinRe": "INF192K01JT6"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134954",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JL3",
    "isinRe": "INF192K01JM1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134955",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - Half Yearly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JP4",
    "isinRe": "INF192K01JQ2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134956",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - Monthly IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JJ7",
    "isinRe": "INF192K01JK5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134957",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - Half Yearly IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JR0",
    "isinRe": "INF192K01JZ3"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134958",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JU4",
    "isinRe": "INF192K01JV2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134959",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Regular) - Monthly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01JH1",
    "isinRe": "INF192K01JI9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120489",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01BX5",
    "isinRe": "INF192K01BY3"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120490",
    "name": "JM Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "JM Large Cap Fund (Direct) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "",
    "isin": "INF192K01BZ0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100220",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01528",
    "isinRe": "INF192K01536"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100221",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) -Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01544",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133407",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Annual Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01GZ9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133408",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Half Yearly IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HD4",
    "isinRe": "INF192K01HE2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133409",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HV6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133410",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Quarterly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HX2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133411",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Quarterly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HZ7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133412",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Monthly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HN3",
    "isinRe": "INF192K01HO1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133401",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01GP0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133402",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Half Yearly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HH5",
    "isinRe": "INF192K01HI3"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133403",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HT0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133404",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Monthly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HL7",
    "isinRe": "INF192K01HM5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133405",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HP8",
    "isinRe": "INF192K01HQ6"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133406",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HR4",
    "isinRe": "INF192K01HS2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120483",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01BO4",
    "isinRe": "INF192K01BP1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120484",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01BQ9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "130904",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Annual Bonus Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HB8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "131476",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HF9",
    "isinRe": "INF192K01HG7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "131477",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Direct) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01HJ1",
    "isinRe": "INF192K01HK9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133400",
    "name": "JM Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "JM Equity Hybrid Fund (Regular) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1995-04-01",
    "closeData": "1995-04-01",
    "isin": "INF192K01GN5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100222",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Regular) - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01999",
    "isinRe": "INF192K01AA5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100223",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Regular) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01AB3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100224",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Income Fund-Serial Plan 2004 Growth",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100225",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Income Fund-Serial Plan 2003 Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100226",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Income Fund-JM Income Fund - Serial Plan 2004 Growth",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100227",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Income Fund-Serial Plan 2004 Growth",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100228",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Income Fund-Serial Plan 2004 Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120428",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Direct) - Bonus Option - Principal Units",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01CX3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120429",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Direct)-Bonus Option - Bonus Units",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120430",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Direct) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01CW5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120431",
    "name": "JM Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Direct) - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01CU9",
    "isinRe": "INF192K01CV7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100233",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Regular) - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01841",
    "isinRe": "INF192K01858"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100234",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01882",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100235",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM High Liquidity Fund-Serial Plan 01",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100236",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM High Liquidity Fund-Serial Plan 02",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100239",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM High Liquidity Fund-Monthly Dividend",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100240",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM High Liquidity Fund-Institutional Plan - Monthly Dividend",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148413",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund - Unclaimed Brokerage I.E.F. (Direct) - Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148414",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund - Unclaimed Application Refund Amount I.E.F. (Direct) - Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148415",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund - Withheld Brokerage I.E.F. (Direct) - Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120410",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Direct) - Bonus Option - Bonus Option - Principal units",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01CN4",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120411",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Direct) - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01CH6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "139257",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund Unclaimed Redemption (Direct) Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01LA2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "139258",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund Unclaimed Dividend (Direct) Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01LB0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "139259",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund Unclaimed Redemption IEF (Direct) Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01LC8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "139260",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund Unclaimed Dividend IEF (Direct) Growth Plan",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01LD6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100242",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM High Liquidity Fund-Montly Dividend",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100244",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Regular) - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01866",
    "isinRe": "INF192K01874"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100247",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Regular) - Super Institutional Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01981",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120406",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Direct) - Growth Option",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01CM6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120407",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Direct) - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01CI4",
    "isinRe": "INF192K01CJ2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120408",
    "name": "JM Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Direct) - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01CK0",
    "isinRe": "INF192K01CL8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100248",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund-Short Term Plan",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100249",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Quarterly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01783",
    "isinRe": "INF192K01791"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100250",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01809",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100253",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Bonus Option - Principal Units",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01817",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134659",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Annual Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01KG1",
    "isinRe": "INF192K01KH9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134660",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Monthly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01JW0",
    "isinRe": "INF192K01JX8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134661",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) Half Yearly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01KE6",
    "isinRe": "INF192K01KF3"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134662",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) Annual Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01KI7",
    "isinRe": "INF192K01KJ5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134663",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - Half Yearly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01KC0",
    "isinRe": "INF192K01KD8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "134664",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) Monthly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01JY6",
    "isinRe": "INF192K01KB2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120418",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) - Bonus Option - Principal Units",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120419",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) Bonus Option - Principal Units",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01CT1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120420",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) - Quarterly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01CP9",
    "isinRe": "INF192K01CQ7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120421",
    "name": "JM G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "JM G-Sec Fund - (Direct) - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "1992-09-29",
    "closeData": "",
    "isin": "INF192K01CR5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "100254",
    "name": "JM Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "JM Value Fund (Regular) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1997-03-25",
    "closeData": "1997-04-24",
    "isin": "INF137A01037",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "106168",
    "name": "JM Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "JM Value Fund (Regular) - IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1997-03-25",
    "closeData": "1997-04-24",
    "isin": "INF137A01011",
    "isinRe": "INF137A01029"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120485",
    "name": "JM Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "JM Value Fund (Direct) - IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1997-03-25",
    "closeData": "1997-04-24",
    "isin": "INF192K01BR7",
    "isinRe": "INF192K01BS5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120486",
    "name": "JM Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "JM Value Fund (Direct) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "1997-03-25",
    "closeData": "1997-04-24",
    "isin": "INF192K01BT3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100264",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment Regular-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FK1",
    "isinRe": "INF174K01FJ3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100265",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment Regular-Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FI5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100266",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Serial 2001-Dividend",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100267",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Serial 2001-Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100268",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Serial 2003-Dividend",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100269",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Serial 2003-Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119760",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment  Provident Fund and Trust-Dividend - Direct",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KN5",
    "isinRe": "INF174K01KO3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100274",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Serial 2011-Dividend",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100280",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment  Provident Fund and Trust-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FM7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100281",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment  Provident Fund and Trust-Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FL9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119757",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment  Provident Fund and Trust-Growth - Direct",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KM7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119758",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment Regular-Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KF1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119759",
    "name": "Kotak Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Kotak Gilt-Investment Regular-Growth - Direct",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KE4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100286",
    "name": "Kotak Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Kotak Equity Hybrid - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.1000",
    "launchDate": "1999-11-29",
    "closeData": "1999-11-29",
    "isin": "INF174K01450",
    "isinRe": "INF174K01443"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119767",
    "name": "Kotak Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Kotak Equity Hybrid - Payout of Income Distribution cum  capital withdrawal option - Direct",
    "minAmount": "Rs.1000",
    "launchDate": "1999-11-29",
    "closeData": "1999-11-29",
    "isin": "INF174K01LL7",
    "isinRe": "INF174K01LM5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133035",
    "name": "Kotak Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Kotak Equity Hybrid - Growth - Direct",
    "minAmount": "Rs.1000",
    "launchDate": "1999-11-29",
    "closeData": "1999-11-29",
    "isin": "INF174K01F00",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133036",
    "name": "Kotak Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Kotak Equity Hybrid - Growth",
    "minAmount": "Rs.1000",
    "launchDate": "1999-11-29",
    "closeData": "1999-11-29",
    "isin": "INF174K01E92",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100291",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01EK4",
    "isinRe": "INF174K01EL2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100292",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01EJ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100293",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100294",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100295",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100296",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119735",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01JC0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100305",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2002 A-Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100306",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2005  A-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100307",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2005  A-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100308",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2005 A-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100309",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2005-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119734",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond Fund - Direct Plan - Standard IDCW option",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01JD8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100297",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Deposit-Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100299",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond Fund - Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01EM0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100300",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond Fund - Regular Plan - Standard IDCW Option",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": "INF174K01EQ1",
    "isinRe": "INF174K01EP3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100302",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2001 B-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100303",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2001 B-Growth",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100304",
    "name": "Kotak Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Kotak Bond-Serial 2002 A-Dividend",
    "minAmount": "5000",
    "launchDate": "1999-11-25",
    "closeData": "1999-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100312",
    "name": "LIC MF Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "LIC MF Flexi Cap Fund-Regular Plan-IDCW",
    "minAmount": "2000",
    "launchDate": "1998-04-16",
    "closeData": "1998-04-30",
    "isin": "INF767K01063",
    "isinRe": "INF767K01055"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100313",
    "name": "LIC MF Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "LIC MF Flexi Cap Fund-Regular Plan-Growth",
    "minAmount": "2000",
    "launchDate": "1998-04-16",
    "closeData": "1998-04-30",
    "isin": "INF767K01071",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120263",
    "name": "LIC MF Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "LIC MF Flexi Cap Fund-Direct Plan-IDCW",
    "minAmount": "2000",
    "launchDate": "1998-04-16",
    "closeData": "1998-04-30",
    "isin": "INF767K01EF5",
    "isinRe": "INF767K01EH1"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120264",
    "name": "LIC MF Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "LIC MF Flexi Cap Fund-Direct Plan-Growth",
    "minAmount": "2000",
    "launchDate": "1998-04-16",
    "closeData": "1998-04-30",
    "isin": "INF767K01EG3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100314",
    "name": "LIC MF Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "LIC MF Bond Fund-Regular Plan-IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-03-26",
    "closeData": "1999-04-16",
    "isin": "INF767K01907",
    "isinRe": "INF767K01915"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100315",
    "name": "LIC MF Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "LIC MF Bond Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "1999-03-26",
    "closeData": "1999-04-16",
    "isin": "INF767K01923",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115199",
    "name": "LIC MF Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "LIC NOMURA MF BOND  FUND",
    "minAmount": "5000/-",
    "launchDate": "1999-03-26",
    "closeData": "1999-04-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120278",
    "name": "LIC MF Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "LIC MF Bond Fund-Direct Plan-IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-03-26",
    "closeData": "1999-04-16",
    "isin": "INF767K01EV2",
    "isinRe": "INF767K01EX8"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120279",
    "name": "LIC MF Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "LIC MF Bond Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "1999-03-26",
    "closeData": "1999-04-16",
    "isin": "INF767K01EW0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100316",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-Regular Plan-IDCW",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01683",
    "isinRe": "INF767K01691"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100317",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-Regular Plan-Growth",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01709",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100318",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-PF Plan-IDCW",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01717",
    "isinRe": "INF767K01725"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100319",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-PF Plan-Growth",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01733",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100320",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Govt Securities Fund-PF Plan",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115223",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC NOMURA MF Govt Securities Fund",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120282",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-Direct Plan-Growth",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01EZ3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120283",
    "name": "LIC MF Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "LIC MF Government Securities Fund-Direct Plan-IDCW",
    "minAmount": "10000",
    "launchDate": "1999-11-15",
    "closeData": "1999-11-29",
    "isin": "INF767K01EY6",
    "isinRe": "INF767K01FA3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100321",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LIC MF Equity Hybrid Fund-Regular Plan-IDCW",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": "INF767K01022",
    "isinRe": "INF767K01014"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100322",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LICMF Balanced Fund-Growth",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100323",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LIC MF Equity Hybrid Fund-Regular Plan-Growth",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": "INF767K01030",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115198",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LIC NOMURA MF Balanced Fund-Dividend",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120260",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LIC MF Equity Hybrid Fund-Direct Plan-IDCW",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": "INF767K01EC2",
    "isinRe": "INF767K01EE8"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120261",
    "name": "LIC MF Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "LIC MF Equity Hybrid Fund-Direct Plan-Growth",
    "minAmount": "Rs.1000/-",
    "launchDate": "1991-01-01",
    "closeData": "1991-01-01",
    "isin": "INF767K01ED0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100325",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (5 Yrs. Single Premium)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01964",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120259",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (5 Yrs. Single Premium)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DO9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134846",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Single Premium)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01972",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134847",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Monthly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01980",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134863",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Monthly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AB2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135983",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Quarterly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01PA2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135977",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Quarterly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01PB0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135978",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Quarterly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OX7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135979",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Quarterly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OV1",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135980",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Quarterly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OZ2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135981",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Quarterly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OU3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135982",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Quarterly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OW9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134883",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DY8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134884",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Monthly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DW2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134885",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Half-Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01EA6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134886",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01EB4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134887",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Monthly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DZ5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135976",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Quarterly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01OY5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134877",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DS0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134878",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Half-Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DR2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134879",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Half-Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DU6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134880",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DV4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134881",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Monthly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DT8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134882",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Half-Yearly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DX0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134871",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AG1",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134872",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Monthly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AH9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134873",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Uniform Cover Half-Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AI7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134874",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15Yrs. Regular Premium Uniform Cover Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AJ5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134875",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Single Premium)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DP6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134876",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Monthly)-Direct Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01DQ4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134864",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AA4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134865",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Reducing Cover Half-Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01998",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134867",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Half-Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AC0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134868",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (10 Yrs. Regular Premium Uniform Cover Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AD8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134869",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Monthly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AE6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134870",
    "name": "LIC MF Unit Linked Insurance Scheme (ULIS)",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF ULIS (15 Yrs. Regular Premium Reducing Cover Half-Yearly)-Regular Plan-IDCW Reinvestment",
    "minAmount": "Reg contr.-10000-10yr15000-15yrSingle Cont-10000",
    "launchDate": "1989-06-19",
    "closeData": "1989-06-19",
    "isin": "INF767K01AF3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100332",
    "name": "LIC MF Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "LIC MF Large Cap Fund-Regular Plan-IDCW",
    "minAmount": "Rs. 2000",
    "launchDate": "1994-09-01",
    "closeData": "1994-09-15",
    "isin": "INF767K01097",
    "isinRe": "INF767K01089"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "106871",
    "name": "LIC MF Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "LIC MF Large Cap Fund-Regular Plan-Growth",
    "minAmount": "Rs. 2000",
    "launchDate": "1994-09-01",
    "closeData": "1994-09-15",
    "isin": "INF767K01105",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115201",
    "name": "LIC MF Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "LIC NOMURA  MF Growth Fund",
    "minAmount": "Rs. 2000",
    "launchDate": "1994-09-01",
    "closeData": "1994-09-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120267",
    "name": "LIC MF Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "LIC MF Large Cap Fund-Direct Plan-Growth",
    "minAmount": "Rs. 2000",
    "launchDate": "1994-09-01",
    "closeData": "1994-09-15",
    "isin": "INF767K01EJ7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120268",
    "name": "LIC MF Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "LIC MF Large Cap Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 2000",
    "launchDate": "1994-09-01",
    "closeData": "1994-09-15",
    "isin": "INF767K01EI9",
    "isinRe": "INF767K01EK5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100348",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Large & Mid Cap Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": "INF109K01EQ7",
    "isinRe": "INF109K01449"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100349",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Large & Mid Cap Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": "INF109K01431",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120286",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Blended Plan A - Direct Plan -  Dividend",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120287",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Blended Plan A - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120596",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Large & Mid Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": "INF109K011O5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120597",
    "name": "ICICI Prudential Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "ICICI Prudential Large & Mid Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1998-06-01",
    "closeData": "",
    "isin": "INF109K019N0",
    "isinRe": "INF109K010O7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100351",
    "name": "ICICI Prudential FMCG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential FMCG Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01EO2",
    "isinRe": "INF109K01423"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100352",
    "name": "ICICI Prudential FMCG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential FMCG Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01415",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120587",
    "name": "ICICI Prudential FMCG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential FMCG Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01Z14",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120588",
    "name": "ICICI Prudential FMCG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential FMCG Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01Y98",
    "isinRe": "INF109K01Z06"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100353",
    "name": "ICICI Prudential Long Term Equity Fund (Tax Saving)",
    "category": "Equity Scheme - ELSS",
    "navName": "ICICI Prudential Long Term Equity Fund (Tax Saving)- IDCW",
    "minAmount": "Rs. 500/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01FN1",
    "isinRe": "INF109K01472"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100354",
    "name": "ICICI Prudential Long Term Equity Fund (Tax Saving)",
    "category": "Equity Scheme - ELSS",
    "navName": "ICICI Prudential Long Term Equity Fund (Tax Saving) - Growth",
    "minAmount": "Rs. 500/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01464",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120592",
    "name": "ICICI Prudential Long Term Equity Fund (Tax Saving)",
    "category": "Equity Scheme - ELSS",
    "navName": "ICICI Prudential Long Term Equity Fund (Tax Saving) - Direct Plan -  Growth",
    "minAmount": "Rs. 500/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01Y31",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120593",
    "name": "ICICI Prudential Long Term Equity Fund (Tax Saving)",
    "category": "Equity Scheme - ELSS",
    "navName": "ICICI Prudential Long Term Equity Fund (Tax Saving) - Direct Plan -  IDCW",
    "minAmount": "Rs. 500/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01Y15",
    "isinRe": "INF109K01Y23"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100355",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01DX5",
    "isinRe": "INF109K01498"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100356",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01480",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120251",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01Y07",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120252",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01X81",
    "isinRe": "INF109K01X99"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120422",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Floating Rate - Direct Plan -  Daily Dividend",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131452",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1X93",
    "isinRe": "INF109KA1X85"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131453",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1Y19",
    "isinRe": "INF109KA1Y01"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136113",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KB1QK6",
    "isinRe": "INF109KB1QJ8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136114",
    "name": "ICICI Prudential Equity & Debt Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "ICICI Prudential Equity & Debt Fund - Direct Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KB1QM2",
    "isinRe": "INF109KB1QL4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100357",
    "name": "ICICI Prudential Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund -Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-01-01",
    "closeData": "",
    "isin": "INF109K01UU5",
    "isinRe": "INF109K01UT7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100359",
    "name": "ICICI Prudential Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Institutional Plus Fund - Div - Daily",
    "minAmount": "5000",
    "launchDate": "2012-01-01",
    "closeData": "",
    "isin": "INF109K01VJ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100363",
    "name": "ICICI Prudential Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Technology Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-02-03",
    "closeData": "",
    "isin": "INF109K01506",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115294",
    "name": "ICICI Prudential Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Technology Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-02-03",
    "closeData": "",
    "isin": "INF109K01FO9",
    "isinRe": "INF109K01514"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120594",
    "name": "ICICI Prudential Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Technology Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-02-03",
    "closeData": "",
    "isin": "INF109K01Z48",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120595",
    "name": "ICICI Prudential Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Technology Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-02-03",
    "closeData": "",
    "isin": "INF109K01Z22",
    "isinRe": "INF109K01Z30"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100364",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01EX3",
    "isinRe": "INF109K01357"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100365",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01365",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100366",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund-Institutional Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01EY1",
    "isinRe": "INF109K01381"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100367",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01EZ8",
    "isinRe": "INF109K01399"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120743",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K017L8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120747",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K010M1",
    "isinRe": "INF109K011M9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120748",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K018L6",
    "isinRe": "INF109K019L4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122673",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Bonus",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA1335",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131622",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund Direct Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA13C0",
    "isinRe": "INF109KA12C2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131623",
    "name": "ICICI Prudential Long Term Bond Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA11C4",
    "isinRe": "INF109KA10C6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100368",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - IDCW",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01JQ6",
    "isinRe": "INF109K01JP8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100369",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - Growth",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01JR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100370",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Dividend",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01JW4",
    "isinRe": "INF109K01JV6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100371",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Growth",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01JX2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100372",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Half Yearly Dividend",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K01JZ7",
    "isinRe": "INF109K01JY0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120589",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - Direct Plan - IDCW",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K016C9",
    "isinRe": "INF109K017C7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130955",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - Direct Plan Bonus",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109KA1H02",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130956",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Direct Plan Bonus",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109KA1I50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120590",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - Direct Plan - Growth",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K018C5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120607",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Direct Plan -  Dividend",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K012D6",
    "isinRe": "INF109K013D4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120608",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Direct Plan -  Growth",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K014D2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120609",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Direct Plan -  Half Yearly Dividend",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109K015D9",
    "isinRe": "INF109K016D7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130902",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Short Term Gilt Fund - Bonus",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109KA1I68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130903",
    "name": "ICICI Prudential Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "ICICI Prudential Gilt Fund - Bonus",
    "minAmount": "5",
    "launchDate": "2012-12-04",
    "closeData": "",
    "isin": "INF109KA1H10",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100373",
    "name": "ICICI Prudential Premier",
    "category": "Balanced",
    "navName": "ICICI Prudential Premier",
    "minAmount": "",
    "launchDate": "",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100375",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "NIPPON INDIA GROWTH FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01299",
    "isinRe": "INF204K01307"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100376",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Nippon India Growth Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01315",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100377",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Nippon India Growth Fund-Growth Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01323",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106260",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "NIPPON INDIA GROWTH FUND - INSTITUTIONAL Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01257",
    "isinRe": "INF204K01265"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118665",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Nippon India Growth Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01E21",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118666",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "NIPPON INDIA GROWTH FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01E39",
    "isinRe": "INF204K01E47"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118668",
    "name": "Nippon India Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Nippon India Growth Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01E54",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100378",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "NIPPON INDIA VISION FUND - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01372",
    "isinRe": "INF204K01380"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100379",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Nippon India Vision Fund-GROWTH PLAN-Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01398",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100380",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Nippon India Vision Fund-GROWTH PLAN-Growth Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01406",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106255",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Nippon India Vision Fund Institutional Dividend Plan",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01331",
    "isinRe": "INF204K01349"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118675",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Nippon India Vision Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01E96",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118676",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "NIPPON INDIA VISION FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01F04",
    "isinRe": "INF204K01F12"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118678",
    "name": "Nippon India Vision Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Nippon India Vision Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "1995-09-25",
    "closeData": "",
    "isin": "INF204K01F20",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100383",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CN7",
    "isinRe": "INF204K01CO5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100384",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CP2",
    "isinRe": "INF204K01CQ0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100385",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - HALF YEARLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CR8",
    "isinRe": "INF204K01CS6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100386",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - ANNUAL IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CT4",
    "isinRe": "INF204K01CU2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100387",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Nippon India Income Fund - Growth Plan Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CL1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100388",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Nippon India Income Fund - Growth Plan Bonus Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01CM9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118687",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Nippon India Income Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01XS2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100395",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Reliance Income Fund-Institutional Quarterly Dividend",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118679",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Nippon India Income Fund - Direct Plan Growth Plan-Bonus Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01F38",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118680",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - Direct Plan - ANNUAL IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01YT8",
    "isinRe": "INF204K01YU6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118681",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - Direct Plan - HALF YEARLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01YR2",
    "isinRe": "INF204K01YS0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118683",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01YN1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118684",
    "name": "Nippon India Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "NIPPON INDIA INCOME FUND - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re. 1 thereafter",
    "launchDate": "1997-12-20",
    "closeData": "1997-12-20",
    "isin": "INF204K01YP6",
    "isinRe": "INF204K01YQ4"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "100476",
    "name": "Taurus Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Taurus Flexi Cap Fund - Regular Plan - Growth ",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "1994-01-29",
    "closeData": "",
    "isin": "INF044D01989",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111640",
    "name": "Taurus Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Taurus Flexi Cap Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "1994-01-29",
    "closeData": "",
    "isin": "INF044D01955",
    "isinRe": "INF044D01963"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118883",
    "name": "Taurus Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Taurus Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "1994-01-29",
    "closeData": "",
    "isin": "INF044D01BU9",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118884",
    "name": "Taurus Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Taurus Flexi Cap Fund - Direct Plan -  Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "1994-01-29",
    "closeData": "",
    "isin": "INF044D01BV7",
    "isinRe": "INF044D01BW5"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "100477",
    "name": "Taurus Discovery (Midcap) Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Taurus Discovery (Midcap) Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 therafter",
    "launchDate": "1994-09-05",
    "closeData": "",
    "isin": "INF044D01815",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111642",
    "name": "Taurus Discovery (Midcap) Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Taurus Discovery (Midcap) Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 therafter",
    "launchDate": "1994-09-05",
    "closeData": "",
    "isin": "INF044D01781",
    "isinRe": "INF044D01799"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118872",
    "name": "Taurus Discovery (Midcap) Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Taurus Discovery (Midcap) Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 therafter",
    "launchDate": "1994-09-05",
    "closeData": "",
    "isin": "INF044D01CA9",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118873",
    "name": "Taurus Discovery (Midcap) Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Taurus Discovery (Midcap) Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 therafter",
    "launchDate": "1994-09-05",
    "closeData": "",
    "isin": "INF044D01CB7",
    "isinRe": "INF044D01CC5"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "100480",
    "name": "Taurus Tax Shield",
    "category": "Equity Scheme - ELSS",
    "navName": "Taurus Taxshield - Regular Plan - Growth",
    "minAmount": "Rs 500 and in multiples of Rs 500 thereafter",
    "launchDate": "2007-03-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01AC9",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "108402",
    "name": "Taurus Tax Shield",
    "category": "Equity Scheme - ELSS",
    "navName": "Taurus Taxshield - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 500 and in multiples of Rs 500 thereafter",
    "launchDate": "2007-03-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01997",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118866",
    "name": "Taurus Tax Shield",
    "category": "Equity Scheme - ELSS",
    "navName": "Taurus Taxshield - Direct Plan - Growth",
    "minAmount": "Rs 500 and in multiples of Rs 500 thereafter",
    "launchDate": "2007-03-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01CG6",
    "isinRe": "INF044D01AA3"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118867",
    "name": "Taurus Tax Shield",
    "category": "Equity Scheme - ELSS",
    "navName": "Taurus Taxshield - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 500 and in multiples of Rs 500 thereafter",
    "launchDate": "2007-03-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01CH4",
    "isinRe": "INF044D01CI2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100484",
    "name": "Franklin India NSE Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Franklin India Index Fund- NSE Nifty 50 Index Fund - Growth",
    "minAmount": "Rs. 2000",
    "launchDate": "2000-08-04",
    "closeData": "",
    "isin": "INF090I01890",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105067",
    "name": "Franklin India NSE Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Franklin India INDEX FUND- NSE NIFTY 50 INDEX FUND - IDCW",
    "minAmount": "Rs. 2000",
    "launchDate": "2000-08-04",
    "closeData": "",
    "isin": "INF090I01874",
    "isinRe": "INF090I01882"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118580",
    "name": "Franklin India NSE Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Franklin India INDEX FUND- NSE Nifty 50 Index Fund - Direct - IDCW",
    "minAmount": "Rs. 2000",
    "launchDate": "2000-08-04",
    "closeData": "",
    "isin": "INF090I01GQ8",
    "isinRe": "INF090I01GR6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118581",
    "name": "Franklin India NSE Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Franklin India INDEX FUND- NSE NIFTY 50 INDEX FUND- Direct - Growth",
    "minAmount": "Rs. 2000",
    "launchDate": "2000-08-04",
    "closeData": "",
    "isin": "INF090I01GS4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100488",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Templeton India Govt.Sec. Fund-Treasury Plan",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100492",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Templeton India Govt.Sec. Fund-Long Term Plan",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100493",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Franklin India Government Securities Fund - Growth",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": "INF090I01CU9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100494",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Franklin India Government Securities Fund - IDCW ",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": "INF090I01CS3",
    "isinRe": "INF090I01CT1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118497",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Franklin India Government Securities Fund - Direct - IDCW ",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": "INF090I01HQ6",
    "isinRe": "INF090I01HR4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118498",
    "name": "Franklin India Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Franklin India Government Securities Fund - Direct - Growth",
    "minAmount": "Growth -10000 and Diviedend - 25000",
    "launchDate": "1999-06-21",
    "closeData": "",
    "isin": "INF090I01HS2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100496",
    "name": "Templeton India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Templeton India Value Fund - Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "1996-09-10",
    "closeData": "",
    "isin": "INF090I01296",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100497",
    "name": "Templeton India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Templeton India Value Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1996-09-10",
    "closeData": "",
    "isin": "INF090I01270",
    "isinRe": "INF090I01288"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118493",
    "name": "Templeton India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Templeton India Value Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1996-09-10",
    "closeData": "",
    "isin": "INF090I01GW6",
    "isinRe": "INF090I01GX4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118494",
    "name": "Templeton India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Templeton India Value Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1996-09-10",
    "closeData": "",
    "isin": "INF090I01GY2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100498",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund - IDCW ",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01BN6",
    "isinRe": "INF090I01BO4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100499",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund-Growth",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01BP1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118495",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund - Direct - Growth",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01HB8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118496",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund - Direct - IDCW ",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01GZ9",
    "isinRe": "INF090I01HA0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148309",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - IDCW",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01WA9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148310",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct IDCW",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01WC5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147985",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - IDCW",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TU3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147986",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct IDCW",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TW9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147987",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TV1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147988",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Direct-Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TX7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148307",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01WB7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148308",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01WD3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147981",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TR9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147982",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Dividend Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TS7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147983",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Dividend Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TQ1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147984",
    "name": "Franklin India Dynamic Accrual Fund (No. of segregated portfolios- 3)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Franklin India Dynamic Accrual Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Growth Plan",
    "minAmount": "10000",
    "launchDate": "1997-03-05",
    "closeData": "",
    "isin": "INF090I01TT5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100503",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Monthly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01BQ9",
    "isinRe": "INF090I01BR7"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100504",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Quarterly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01BS5",
    "isinRe": "INF090I01BT3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "113135",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Growth Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01BU1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118528",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Direct - Monthly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01HC6",
    "isinRe": "INF090I01HD4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118529",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Direct - Quarterly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01HE2",
    "isinRe": "INF090I01HF9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148000",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UG0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147994",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UA3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147995",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Monthly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UE5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147996",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund-Direct- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct Monthly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UH8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147997",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Quarterly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UF2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147998",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund-Direct- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct Quarterly - IDCW",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UI6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147999",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Direct- Growth Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UJ4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118530",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund - Direct - Growth",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01HG7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147989",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Growth Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UD7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147990",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Monthly Dividend Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01TY5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147991",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Monthly Dividend Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UB1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147992",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Quarterly Dividend Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01TZ2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147993",
    "name": "Franklin India Low Duration Fund (No. of Segregated Portfolios-2)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Franklin India Low Duration Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct- Quarterly Dividend Plan",
    "minAmount": "Rs. 10000 (Growth Plan) Rs.25000 (Dividend Plans)",
    "launchDate": "2000-02-07",
    "closeData": "",
    "isin": "INF090I01UC9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100470",
    "name": "Franklin India Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Franklin India BLUECHIP FUND - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01155",
    "isinRe": "INF090I01163"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100471",
    "name": "Franklin India Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Franklin India Bluechip Fund-Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01171",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118462",
    "name": "Franklin India Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Franklin India BLUECHIP FUND - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01FO5",
    "isinRe": "INF090I01FP2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118531",
    "name": "Franklin India Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Franklin India Bluechip Fund- Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01FN7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100472",
    "name": "Franklin India Prima Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Franklin India PRIMA FUND - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01726",
    "isinRe": "INF090I01734"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100473",
    "name": "Franklin India Prima Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Franklin India Prima Fund-Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01809",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118532",
    "name": "Franklin India Prima Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Franklin India PRIMA FUND - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01FI7",
    "isinRe": "INF090I01FJ5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118533",
    "name": "Franklin India Prima Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Franklin India Prima Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1993-12-01",
    "closeData": "",
    "isin": "INF090I01FH9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100519",
    "name": "Franklin India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Franklin India Flexi Cap Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1994-09-29",
    "closeData": "",
    "isin": "INF090I01213",
    "isinRe": "INF090I01221"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100520",
    "name": "Franklin India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Franklin India Flexi Cap Fund - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1994-09-29",
    "closeData": "",
    "isin": "INF090I01239",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118534",
    "name": "Franklin India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Franklin India Flexi Cap Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1994-09-29",
    "closeData": "",
    "isin": "INF090I01FL1",
    "isinRe": "INF090I01FM9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118535",
    "name": "Franklin India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Franklin India Flexi Cap Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1994-09-29",
    "closeData": "",
    "isin": "INF090I01FK3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100521",
    "name": "Franklin India Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Technology Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1998-08-22",
    "closeData": "",
    "isin": "INF090I01759",
    "isinRe": "INF090I01767"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100522",
    "name": "Franklin India Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Technology Fund-Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1998-08-22",
    "closeData": "",
    "isin": "INF090I01742",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118536",
    "name": "Franklin India Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Technology Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1998-08-22",
    "closeData": "",
    "isin": "INF090I01FF3",
    "isinRe": "INF090I01FG1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118537",
    "name": "Franklin India Technology Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Technology Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1998-08-22",
    "closeData": "",
    "isin": "INF090I01FE6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100524",
    "name": "Franklin India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin INDIA OPPORTUNITIES FUND - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": "INF090I01858",
    "isinRe": "INF090I01866"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103151",
    "name": "Franklin India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Opportunities Fund - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": "INF090I01841",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118538",
    "name": "Franklin India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin INDIA OPPORTUNITIES FUND - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": "INF090I01GD6",
    "isinRe": "INF090I01GE4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118539",
    "name": "Franklin India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin India Opportunities Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": "INF090I01GC8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100525",
    "name": "Franklin India Taxshield",
    "category": "Equity Scheme - ELSS",
    "navName": "Franklin India TAXSHIELD - IDCW ",
    "minAmount": "Rs.500",
    "launchDate": "1994-04-10",
    "closeData": "",
    "isin": "INF090I01783",
    "isinRe": "INF090I01791"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100526",
    "name": "Franklin India Taxshield",
    "category": "Equity Scheme - ELSS",
    "navName": "Franklin India Taxshield-Growth",
    "minAmount": "Rs.500",
    "launchDate": "1994-04-10",
    "closeData": "",
    "isin": "INF090I01775",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118540",
    "name": "Franklin India Taxshield",
    "category": "Equity Scheme - ELSS",
    "navName": "Franklin India TAXSHIELD - Direct - Growth",
    "minAmount": "Rs.500",
    "launchDate": "1994-04-10",
    "closeData": "",
    "isin": "INF090I01JS8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118541",
    "name": "Franklin India Taxshield",
    "category": "Equity Scheme - ELSS",
    "navName": "Franklin India TAXSHIELD - Direct - IDCW ",
    "minAmount": "Rs.500",
    "launchDate": "1994-04-10",
    "closeData": "",
    "isin": "INF090I01JT6",
    "isinRe": "INF090I01JU4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100527",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan A - Annual - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DH4",
    "isinRe": "INF090I01DI2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100528",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Growth",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DG6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100529",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan B - Monthly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DJ0",
    "isinRe": "INF090I01DK8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100530",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan B - Quarterly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DL6",
    "isinRe": "INF090I01DM4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100531",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan B - Half yearly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DN2",
    "isinRe": "INF090I01DO0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100532",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Monthly Bonus",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01DC5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118573",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan A - Direct - Half yearly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01FU2",
    "isinRe": "INF090I01FV0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "119289",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Direct - Bonus",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01KC0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118569",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Direct - GROWTH",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01FW8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118570",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan A - Direct - Annual - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01FX6",
    "isinRe": "INF090I01FY4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118571",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan A - Direct - Monthly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01FQ0",
    "isinRe": "INF090I01FR8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118572",
    "name": "Franklin India Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Franklin India Corporate Debt Fund - Plan A - Direct - Quarterly - IDCW",
    "minAmount": "PlanA Rs.50 lakhs; PlanB Rs.25000 (MD&QD)",
    "launchDate": "1997-06-24",
    "closeData": "",
    "isin": "INF090I01FS6",
    "isinRe": "INF090I01FT4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100535",
    "name": "Franklin India Pension Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Franklin India Pension Plan - IDCW ",
    "minAmount": "Rs.500",
    "launchDate": "1997-03-31",
    "closeData": "",
    "isin": "INF090I01528",
    "isinRe": "INF090I01536"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100536",
    "name": "Franklin India Pension Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Franklin India Pension Plan-Growth",
    "minAmount": "Rs.500",
    "launchDate": "1997-03-31",
    "closeData": "",
    "isin": "INF090I01510",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118548",
    "name": "Franklin India Pension Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Franklin India  PENSION PLAN - Direct - Growth",
    "minAmount": "Rs.500",
    "launchDate": "1997-03-31",
    "closeData": "",
    "isin": "INF090I01JZ3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118549",
    "name": "Franklin India Pension Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Franklin India Pension Plan - Direct - IDCW ",
    "minAmount": "Rs.500",
    "launchDate": "1997-03-31",
    "closeData": "",
    "isin": "INF090I01KA4",
    "isinRe": "INF090I01KB2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100537",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Regular Plan - Weekly - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BL0",
    "isinRe": "INF090I01BI6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100538",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Regular Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BB1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100541",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Regular Plan - Daily - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BA3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100545",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Templeton India Treasury Management Account-Super Institutional Plan",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100546",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Institutional Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BG0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100547",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Institutional Plan - Daily - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BF2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "139891",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Unclaimed Redemption Investor Education Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01LQ8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "139892",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Unclaimed IDCW Investor Education Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01LR6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100548",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Institutional Plan - Weekly - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01BM8",
    "isinRe": "INF090I01BH8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118577",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Instittutional - Direct - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01JV2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118578",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Institutional Plan - Direct - Daily - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01JW0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118579",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Super Institutional Plan - Direct - Weekly - IDCW",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01JX8",
    "isinRe": "INF090I01JY6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "139889",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Unclaimed Redemption Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01LO3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "139890",
    "name": "Franklin India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Unclaimed IDCW Plan - Growth",
    "minAmount": "Rs.1 Lakh (RP)",
    "launchDate": "1998-04-29",
    "closeData": "",
    "isin": "INF090I01LP0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100549",
    "name": "Franklin India Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Franklin India Equity Hybrid Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1999-12-10",
    "closeData": "",
    "isin": "INF090I01825",
    "isinRe": "INF090I01833"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100550",
    "name": "Franklin India Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Franklin India Equity Hybrid Fund - Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "1999-12-10",
    "closeData": "",
    "isin": "INF090I01817",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118546",
    "name": "Franklin India Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Franklin India Equity Hybrid Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "1999-12-10",
    "closeData": "",
    "isin": "INF090I01FZ1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118547",
    "name": "Franklin India Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Franklin India Equity Hybrid Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "1999-12-10",
    "closeData": "",
    "isin": "INF090I01GA2",
    "isinRe": "INF090I01GB0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100593",
    "name": "Canara Robeco Equity Taxsaver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "CANARA ROBECO EQUITY TAX SAVER FUND - REGULAR PLAN -IDCW (Payout)",
    "minAmount": "Rs.500",
    "launchDate": "1993-02-25",
    "closeData": "",
    "isin": "INF760K01084",
    "isinRe": "INF760K01092"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "111722",
    "name": "Canara Robeco Equity Taxsaver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "CANARA ROBECO EQUITY TAX SAVER FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs.500",
    "launchDate": "1993-02-25",
    "closeData": "",
    "isin": "INF760K01100",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118285",
    "name": "Canara Robeco Equity Taxsaver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "CANARA ROBECO EQUITY TAX SAVER FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs.500",
    "launchDate": "1993-02-25",
    "closeData": "",
    "isin": "INF760K01EL8",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118286",
    "name": "Canara Robeco Equity Taxsaver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "CANARA ROBECO EQUITY TAX SAVER FUND - DIRECT PLAN - IDCW (Payout)",
    "minAmount": "Rs.500",
    "launchDate": "1993-02-25",
    "closeData": "",
    "isin": "INF760K01EJ2",
    "isinRe": "INF760K01EK0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100596",
    "name": "Canara Robeco Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "CANARA ROBECO GILT FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.10000",
    "launchDate": "1999-12-22",
    "closeData": "",
    "isin": "INF760K01AB7",
    "isinRe": "INF760K01AC5"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100597",
    "name": "Canara Robeco Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "CANARA ROBECO GILT FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs.10000",
    "launchDate": "1999-12-22",
    "closeData": "",
    "isin": "INF760K01AE1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118298",
    "name": "Canara Robeco Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "CANARA ROBECO GILT FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.10000",
    "launchDate": "1999-12-22",
    "closeData": "",
    "isin": "INF760K01FA8",
    "isinRe": "INF760K01FB6"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118299",
    "name": "Canara Robeco Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "CANARA ROBECO GILT FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs.10000",
    "launchDate": "1999-12-22",
    "closeData": "",
    "isin": "INF760K01FC4",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100598",
    "name": "Canara Robeco Gilt 1988",
    "category": "Gilt",
    "navName": "Canara Robeco Gilt 1988",
    "minAmount": "Rs.100000",
    "launchDate": "1988-10-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100600",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01308",
    "isinRe": "INF760K01316"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "100601",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01282",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "112378",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - REGULAR PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01AP7",
    "isinRe": "INF760K01AQ5"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118309",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01GB4",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118310",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - DIRECT PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01GC2",
    "isinRe": "INF760K01GD0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118311",
    "name": "Canara Robeco Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "CANARA ROBECO CONSERVATIVE HYBRID FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "1988-04-24",
    "closeData": "",
    "isin": "INF760K01FZ5",
    "isinRe": "INF760K01GA6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100602",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Regular Plan- Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FR0",
    "isinRe": "INF903J01FU4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100603",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Regular Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FQ2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100604",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Regular Plan - Bonus",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FX8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100608",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Institutional Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FY6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100609",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Regular Plan- Half Yearly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FS8",
    "isinRe": "INF903J01FV2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100610",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Regular Plan - Annual Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01FT6",
    "isinRe": "INF903J01FW0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119672",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Direct Plan - Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01PK4",
    "isinRe": "INF903J01PL2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119675",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Direct Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01PO6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119676",
    "name": "Sundaram Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Sundaram Medium Term Bond Fund Direct Plan - Annual Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1997-10-16",
    "closeData": "",
    "isin": "INF903J01PH0",
    "isinRe": "INF903J01PM0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100614",
    "name": "Sundaram Diversified Equity",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Diversified Equity (ELSS) Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.500/-",
    "launchDate": "1999-11-22",
    "closeData": "",
    "isin": "INF903J01512",
    "isinRe": "INF903J01520"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101853",
    "name": "Sundaram Diversified Equity",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Diversified Equity (ELSS) Regular Plan - Growth",
    "minAmount": "Rs.500/-",
    "launchDate": "1999-11-22",
    "closeData": "",
    "isin": "INF903J01538",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119548",
    "name": "Sundaram Diversified Equity",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Diversified Equity (ELSS) Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.500/-",
    "launchDate": "1999-11-22",
    "closeData": "",
    "isin": "INF903J01NL7",
    "isinRe": "INF903J01NM5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119549",
    "name": "Sundaram Diversified Equity",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Diversified Equity (ELSS) Direct Plan - Growth",
    "minAmount": "Rs.500/-",
    "launchDate": "1999-11-22",
    "closeData": "",
    "isin": "INF903J01NN3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "102700",
    "name": "Sundaram Money Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Money Fund-Institutional Plan-Daily Diiv. Reinvestment",
    "minAmount": "1000",
    "launchDate": "2000-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100636",
    "name": "Z-REDEEMED-SBI MOEF 95",
    "category": "Balanced",
    "navName": "SBI MOEF 95",
    "minAmount": "1000",
    "launchDate": "1995-01-01",
    "closeData": "1995-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100637",
    "name": "Z-OLD-SBI MMPS 93",
    "category": "Growth",
    "navName": "SBI MMPS 93",
    "minAmount": "10",
    "launchDate": "1995-01-01",
    "closeData": "1995-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100638",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund - Regular Plan - Half Yearly - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01610",
    "isinRe": "INF200K01628"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100639",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund-REGULAR PLAN-Growth",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01594",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100640",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund-REGULAR PLAN-Bonus",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01602",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119712",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund-DIRECT PLAN - Bonus",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01SO9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119713",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund - Direct Plan - Half Yearly - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01SP6",
    "isinRe": "INF200K01SQ4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119714",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund-DIRECT PLAN -Growth",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01SR2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "121940",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01YS8",
    "isinRe": "INF200K01YT6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "121944",
    "name": "SBI MAGNUM INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "SBI Magnum Income Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-12-31",
    "closeData": "2010-12-31",
    "isin": "INF200K01YQ2",
    "isinRe": "INF200K01YR0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100643",
    "name": "SBI TECHNOLOGY OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Technology Opportunities Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01420",
    "isinRe": "INF200K01438"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119731",
    "name": "SBI TECHNOLOGY OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Technology Opportunities Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01RT0",
    "isinRe": "INF200K01RU8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120577",
    "name": "SBI TECHNOLOGY OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI TECHNOLOGY OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01VS4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120578",
    "name": "SBI TECHNOLOGY OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI TECHNOLOGY OPPORTUNITIES FUND - DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01RV6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100645",
    "name": "SBI CONSUMPTION OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Consumption Opportunities Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01404",
    "isinRe": "INF200K01412"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119730",
    "name": "SBI CONSUMPTION OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Consumption Opportunities Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01RK9",
    "isinRe": "INF200K01RL7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120575",
    "name": "SBI CONSUMPTION OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI CONSUMPTION OPPORTUNITIES FUND - DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01RM5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120576",
    "name": "SBI CONSUMPTION OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI CONSUMPTION OPPORTUNITIES FUND - REGULAR - GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01VR6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100646",
    "name": "UTI - Unit Linked Insurance Plan",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "UTI - Unit Linked Insurance Plan",
    "minAmount": "15000.00",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120784",
    "name": "UTI - Unit Linked Insurance Plan",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "UTI - Unit Linked Insurance Plan- Direct",
    "minAmount": "15000.00",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100650",
    "name": "UTI - Mastershare Unit Scheme",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "UTI Mastershare Unit Scheme - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF189A01038",
    "isinRe": "INF789F01950"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100651",
    "name": "UTI - Mastershare Unit Scheme",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "UTI - Master Share-Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01976",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120656",
    "name": "UTI - Mastershare Unit Scheme",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "UTI - Master Share-Growth Option - Direct",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01US8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120657",
    "name": "UTI - Mastershare Unit Scheme",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "UTI Mastershare Unit Scheme - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01UQ2",
    "isinRe": "INF789F01UR0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100668",
    "name": "UTI - Flexi Cap Fund.",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "UTI Flexi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-04-20",
    "closeData": "",
    "isin": "INF189A01053",
    "isinRe": "INF789F01505"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100669",
    "name": "UTI - Flexi Cap Fund.",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "UTI - Flexi Cap Fund-Growth Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-04-20",
    "closeData": "",
    "isin": "INF789F01513",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120662",
    "name": "UTI - Flexi Cap Fund.",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "UTI - Flexi Cap Fund-Growth Option - Direct",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-04-20",
    "closeData": "",
    "isin": "INF789F01TC4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120663",
    "name": "UTI - Flexi Cap Fund.",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "UTI Flexi Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-04-20",
    "closeData": "",
    "isin": "INF789F01TA8",
    "isinRe": "INF789F01TB6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100663",
    "name": "UTI - Core Equity Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "UTI Core Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-05-20",
    "closeData": "2009-05-25",
    "isin": "INF189A01046",
    "isinRe": "INF789F01851"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100664",
    "name": "UTI - Core Equity Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "UTI - Core Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-05-20",
    "closeData": "2009-05-25",
    "isin": "INF789F01869",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120664",
    "name": "UTI - Core Equity Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "UTI Core Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-05-20",
    "closeData": "2009-05-25",
    "isin": "INF789F01UE8",
    "isinRe": "INF789F01UF5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120665",
    "name": "UTI - Core Equity Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "UTI - Core Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-05-20",
    "closeData": "2009-05-25",
    "isin": "INF789F01UG3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100678",
    "name": "UTI - CCF - Saving Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Saving Plan - Regular Plan",
    "minAmount": "Rs. 2000/-",
    "launchDate": "1993-07-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120771",
    "name": "UTI - CCF - Saving Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Saving Plan - Direct Plan",
    "minAmount": "Rs. 2000/-",
    "launchDate": "1993-07-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100682",
    "name": "UTI - Retirement Benefit Pension Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "UTI - Retirement Benefit Pension Fund - Regular Plan",
    "minAmount": "Rs.10000/",
    "launchDate": "1994-12-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120766",
    "name": "UTI - Retirement Benefit Pension Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "UTI - Retirement Benefit Pension Fund- Direct",
    "minAmount": "Rs.10000/",
    "launchDate": "1994-12-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100684",
    "name": "UTI - Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "UTI - Hybrid Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs.10000",
    "launchDate": "1995-01-02",
    "closeData": "",
    "isin": "INF789F01323",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100685",
    "name": "UTI - Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "UTI Hybrid Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "1995-01-02",
    "closeData": "",
    "isin": "INF789F01307",
    "isinRe": "INF789F01315"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120673",
    "name": "UTI - Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "UTI Hybrid Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "1995-01-02",
    "closeData": "",
    "isin": "INF789F01SI3",
    "isinRe": "INF789F01SJ1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120674",
    "name": "UTI - Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "UTI - Hybrid Equity Fund -Direct Plan - Growth",
    "minAmount": "Rs.10000",
    "launchDate": "1995-01-02",
    "closeData": "",
    "isin": "INF789F01SK9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100721",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF-Growth",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100722",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF-Income",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100723",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF-Discontinued - Regular Plan -Growth",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01BF5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100724",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF- Discontinued - Regular Plan - Flexi Dividend Option",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01BD0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100725",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF- Discontinued - Regular Plan - Periodic Dividend Option",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01BE8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "112076",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01PW0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140023",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1M87",
    "isinRe": "INF789FA1M95"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142249",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1M61",
    "isinRe": "INF789FA1M79"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143125",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1N29",
    "isinRe": "INF789FA1N37"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134972",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1L88",
    "isinRe": "INF789FA1L96"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134973",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1N03",
    "isinRe": "INF789FA1N11"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135371",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Fortnightly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1L05",
    "isinRe": "INF789FA1L13"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135606",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1M20",
    "isinRe": "INF789FA1M38"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139236",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF - Regular Plan - Flexi Dividend Option",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1M04",
    "isinRe": "INF789FA1M12"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139646",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1L47",
    "isinRe": "INF789FA1L54"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120299",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01XV6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120772",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01XU8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120793",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Weekly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01XW4",
    "isinRe": "INF789F01XX2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133387",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Direct Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1M46",
    "isinRe": "INF789FA1M53"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133484",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1L21",
    "isinRe": "INF789FA1L39"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134763",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789FA1L62",
    "isinRe": "INF789FA1L70"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "112077",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI MMF - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01PX8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "112635",
    "name": "UTI - Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "UTI Money Market Fund - Regular Plan - Weekly IDCW",
    "minAmount": "10000",
    "launchDate": "1997-04-23",
    "closeData": "",
    "isin": "INF789F01PY6",
    "isinRe": "INF789F01PZ3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100739",
    "name": "UTI - MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI MNC Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01828",
    "isinRe": "INF789F01836"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100740",
    "name": "UTI - MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI - MNC Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01844",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120681",
    "name": "UTI - MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI MNC Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01UB4",
    "isinRe": "INF789F01UC2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120682",
    "name": "UTI - MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI - MNC Fund - Growth Option - Direct",
    "minAmount": "5000",
    "launchDate": "2003-02-01",
    "closeData": "",
    "isin": "INF789F01UD0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100741",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund- Regular Plan - Growth",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789F01406",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100742",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789F01380",
    "isinRe": "INF789F01398"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100743",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund-(for rep. After 6 months- No Load)",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120689",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund-Growth - Direct",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789F01SQ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120690",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789F01SO1",
    "isinRe": "INF789F01SP8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133280",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1U04",
    "isinRe": "INF789FA1U12"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133869",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1T23",
    "isinRe": "INF789FA1T31"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133872",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1T49",
    "isinRe": "INF789FA1T56"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134998",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1T64",
    "isinRe": "INF789FA1T72"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135581",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1T07",
    "isinRe": "INF789FA1T15"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139339",
    "name": "UTI - Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "UTI Bond Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000/",
    "launchDate": "1998-05-04",
    "closeData": "",
    "isin": "INF789FA1T80",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100806",
    "name": "UTI - Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Healthcare Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1999-05-27",
    "closeData": "",
    "isin": "INF789F01679",
    "isinRe": "INF789F01687"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100807",
    "name": "UTI - Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Healthcare Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1999-05-27",
    "closeData": "",
    "isin": "INF789F01695",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120782",
    "name": "UTI - Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Healthcare Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1999-05-27",
    "closeData": "",
    "isin": "INF789F01TO9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120783",
    "name": "UTI - Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Healthcare Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "1999-05-27",
    "closeData": "",
    "isin": "INF789F01TM3",
    "isinRe": "INF789F01TN1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100810",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI - G-Sec Fund-Growth",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100813",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI Overnight Fund - Regular Plan - Periodic IDCW",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789F01588",
    "isinRe": "INF789F01596"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100814",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI - Overnight  Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789F01604",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120785",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI - Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789FB1S71",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120786",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI Overnight Fund - Direct Plan - Periodic IDCW",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789FB1T05",
    "isinRe": "INF789FB1S97"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "124265",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI Overnight Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789FB1T13",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "124266",
    "name": "UTI - Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "UTI Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs.10000/",
    "launchDate": "1999-08-23",
    "closeData": "",
    "isin": "INF789FB1S89",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100820",
    "name": "UTI - Long Term Equity Fund ( Tax Saving )",
    "category": "Equity Scheme - ELSS",
    "navName": "UTI Long Term Equity Fund ( Tax Saving ) - Regular Plan - IDCW",
    "minAmount": "Rs 500/-",
    "launchDate": "1999-11-15",
    "closeData": "",
    "isin": "INF789F01521",
    "isinRe": "INF789F01539"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100821",
    "name": "UTI - Long Term Equity Fund ( Tax Saving )",
    "category": "Equity Scheme - ELSS",
    "navName": "UTI - Long Term Equity Fund (Tax Saving) - Regular Plan - Growth Option",
    "minAmount": "Rs 500/-",
    "launchDate": "1999-11-15",
    "closeData": "",
    "isin": "INF789F01547",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120714",
    "name": "UTI - Long Term Equity Fund ( Tax Saving )",
    "category": "Equity Scheme - ELSS",
    "navName": "UTI Long Term Equity Fund ( Tax Saving ) - Direct Plan - IDCW",
    "minAmount": "Rs 500/-",
    "launchDate": "1999-11-15",
    "closeData": "",
    "isin": "INF789F01TD2",
    "isinRe": "INF789F01TE0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120715",
    "name": "UTI - Long Term Equity Fund ( Tax Saving )",
    "category": "Equity Scheme - ELSS",
    "navName": "UTI - Long Term Equity Fund (Tax Saving) - Direct Plan - Growth Option",
    "minAmount": "Rs 500/-",
    "launchDate": "1999-11-15",
    "closeData": "",
    "isin": "INF789F01TF7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100822",
    "name": "UTI Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "5000.00",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF789F01JN2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "100823",
    "name": "UTI Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "5000.00",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF789F01JL6",
    "isinRe": "INF789F01JM4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120716",
    "name": "UTI Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 50 Index Fund - Growth Option- Direct",
    "minAmount": "5000.00",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF789F01XA0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120717",
    "name": "UTI Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "5000.00",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF789F01WY2",
    "isinRe": "INF789F01WZ9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100828",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid-Regular (Dividend)",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01FY2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100829",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid-Regular (Growth)",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NM1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100830",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid-Institutional Plan  (Growth)",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01FZ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100832",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid-Institutional Plan (Weekly Dividend)",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01GB8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100833",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid Regular Plan Daily Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NL3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100834",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid Regular Plan Weekly Reinvestment of Income Distribution cum capital withdrawal option Dividend",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NK5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "100835",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NI9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119764",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid - Daily Reinvestment of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NH1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119766",
    "name": "Kotak Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Kotak Liquid - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2000-10-04",
    "closeData": "2000-10-05",
    "isin": "INF174K01NE8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100837",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund - Retail Option - Growth Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UL3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100838",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - RETAIL Plan - WEEKLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UK5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100839",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund-Treasury Plan-Retail Option - Growth Option Bonus Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100840",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund-Treasury Plan-Bonus Plan (Growth)",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100841",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund-Treasury Plan-Growth Option - Growth Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100842",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - RETAIL Plan - DAILY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UJ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118700",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - DIRECT Plan - WEEKLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01ZM0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118701",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01ZH0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112340",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - QUARTERLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UQ2",
    "isinRe": "INF204K01UR0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "114251",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Monthly Dividend Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "114252",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Quarterly Dividend Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118695",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - DIRECT Plan - DAILY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01ZG2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118696",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - DIRECT Plan - MONTHLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01ZI8",
    "isinRe": "INF204K01ZJ6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118698",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - DIRECT Plan - QUARTERLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01ZK4",
    "isinRe": "INF204K01ZL2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100850",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Super Cash Plan-Growth Option",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100851",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund -Growth Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UN9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100852",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - DAILY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UM1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100853",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - WEEKLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01US8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100854",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Nippon India Liquid Fund-Institutional Plan-Bonus",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100855",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - MONTHLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UO7",
    "isinRe": "INF204K01UP4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100843",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - RETAIL plan - MONTHLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UT6",
    "isinRe": "INF204K01UU4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100844",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "NIPPON INDIA LIQUID FUND - RETAIL Plan - QUARTERLY IDCW OPTION",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": "INF204K01UV2",
    "isinRe": "INF204K01UW0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100845",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Growth Plan",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100846",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Weekly Dividend",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100848",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Weekly Dividend Reinvestment Option",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100849",
    "name": "Nippon India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Reliance Liquid Fund-Cash Plan-Daily Dividend Reinvestment Option",
    "minAmount": "100 for TP & 5000 for CP",
    "launchDate": "1998-03-16",
    "closeData": "1998-03-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100856",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Nippon India Corporate Bond Fund - Growth Plan - Growth Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01EF9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100857",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01EJ1",
    "isinRe": "INF204K01EK9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100858",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01EL7",
    "isinRe": "INF204K01EM5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "100859",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Nippon India Corporate Bond Fund - Growth Plan - Bonus Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204KA12N8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109472",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DAILY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01EG7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109473",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - WEEKLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01EH5",
    "isinRe": "INF204K01EI3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125266",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204KA1EO9",
    "isinRe": "INF204KA1EP6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125267",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204KA1EM3",
    "isinRe": "INF204KA1EN1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118807",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Nippon India Corporate Bond Fund - Direct Plan Growth Plan - Bonus",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01D63",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118808",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01C23",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118809",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01C56",
    "isinRe": "INF204K01C64"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118811",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01C72",
    "isinRe": "INF204K01C80"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118812",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "NIPPON INDIA CORPORATE BOND FUND - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01C31",
    "isinRe": "INF204K01C49"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118814",
    "name": "Nippon India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Nippon India Corporate Bond Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 1000 & Rs. 5000",
    "launchDate": "2000-08-16",
    "closeData": "2000-09-14",
    "isin": "INF204K01C15",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100864",
    "name": "LIC MF Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF Tax Plan-Regular Plan-IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "1997-01-11",
    "closeData": "1997-01-11",
    "isin": "INF767K01931",
    "isinRe": "INF767K01949"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "100865",
    "name": "LIC MF Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF Tax Plan-Regular Plan-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "1997-01-11",
    "closeData": "1997-01-11",
    "isin": "INF767K01956",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115209",
    "name": "LIC MF Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC  NOMURA MF Taxplan",
    "minAmount": "Rs. 500/-",
    "launchDate": "1997-01-11",
    "closeData": "1997-01-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120269",
    "name": "LIC MF Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF Tax Plan-Direct Plan-IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "1997-01-11",
    "closeData": "1997-01-11",
    "isin": "INF767K01EL3",
    "isinRe": "INF767K01EN9"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120270",
    "name": "LIC MF Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "LIC MF Tax Plan-Direct Plan-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "1997-01-11",
    "closeData": "1997-01-11",
    "isin": "INF767K01EM1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100868",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HK0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100869",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-DIVIDEND",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100870",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-PREMIUM- Dividend",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100871",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-PREMIUM PLUS- Dividend",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100872",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-PREMIUM PLUS- Growth",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179K01KQ7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100873",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-PREMIUM- Growth",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HN4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119091",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HP9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100880",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plus Plan - Dividend-Daily",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100881",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plus Plan - Dividend-Weekly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179K01KS3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100882",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plus Plan - Dividend-Monthly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119088",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Weekly - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119089",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Daily - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HV7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119090",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Monthly - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HO2",
    "isinRe": "INF179KB1HQ7"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100874",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Plan Weekly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1ID3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100875",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Daily",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1IC5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100876",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund - IDCW Monthly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179KB1HM6",
    "isinRe": "INF179KB1HL8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100877",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plan - Dividend-Daily",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179K01KN4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100878",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plan - Dividend-Weekly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": "INF179K01KP9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100879",
    "name": "HDFC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HDFC Liquid Fund-Premium Plan - Dividend-Monthly",
    "minAmount": "REFER SID",
    "launchDate": "2000-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100922",
    "name": "Z-REDEEMED SCHEME-SBI MMIS 97",
    "category": "Income",
    "navName": "SBI MMIS 97",
    "minAmount": "1000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100930",
    "name": "Z-REDEEMED-SBI MTP 94",
    "category": "ELSS",
    "navName": "SBI MTP 94",
    "minAmount": "1000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100934",
    "name": "Z-OLD-SBI MELS 95",
    "category": "ELSS",
    "navName": "SBI MELS 95",
    "minAmount": "1000",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100935",
    "name": "Z-OLD-MELS 96",
    "category": "ELSS",
    "navName": "SBI MELS 96",
    "minAmount": "1000",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100936",
    "name": "Z-OLD-SBI MGF 99",
    "category": "Growth",
    "navName": "SBI MGF 99",
    "minAmount": "1000",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "100942",
    "name": "DSP Liquidity Fund - Regular Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Merril Lynch Liquidity Fund - Regular Plan-Daily Dividend",
    "minAmount": "Discontinued Plan",
    "launchDate": "1998-03-06",
    "closeData": "1998-03-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100948",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Growth",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01EA7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100949",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Plan A - Monthly - IDCW",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01EB5",
    "isinRe": "INF090I01EC3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "100950",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Plan A - Quarterly - IDCW",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01ED1",
    "isinRe": "INF090I01EE9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118574",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Direct - GROWTH",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01GF1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118575",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Plan A - Direct - Monthly - IDCW",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01GG9",
    "isinRe": "INF090I01GH7"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118576",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Plan A - Direct - Quarterly - IDCW",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01GI5",
    "isinRe": "INF090I01GJ3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148299",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes Bank Ltd CO 05Mar20) - Monthly Dividend Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WG6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148300",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes Bank Ltd CO 05Mar20) - Quarterly Dividend Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WF8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148301",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes Bank Ltd CO05Mar20) - Direct Monthly Dividend Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WJ0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148302",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes Bank ltd CO 05Mar20) - Direct Growth Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WH4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148297",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes bank Ltd CO 05Mar20) - Growth Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WE1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148298",
    "name": "Franklin India Debt Hybrid Fund (No. of segregated portfolios - 1)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Franklin India Debt Hybrid Fund - Segregated Portfolio 1 (10.25% Yes Bank Ltd CO 05Mar20) - Direct Quarterly Dividend Plan",
    "minAmount": "10000",
    "launchDate": "2000-09-25",
    "closeData": "",
    "isin": "INF090I01WI2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100952",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Growth",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01555",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100953",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Dividend Monthly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01FH3",
    "isinRe": "INF109K01522"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100954",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Dividend Quarterly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01FI1",
    "isinRe": "INF109K01530"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "100955",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Dividend Half Yearly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01FG5",
    "isinRe": "INF109K01548"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120666",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Direct Plan -  Dividend Quarterly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01U19",
    "isinRe": "INF109K01U27"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120667",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Direct Plan -  Dividend Monthly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01T95",
    "isinRe": "INF109K01U01"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120668",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Direct Plan -  Growth",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01U35",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120669",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Direct Plan -  Dividend Half Yearly",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109K01T79",
    "isinRe": "INF109K01T87"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130940",
    "name": "ICICI Prudential MIP",
    "category": "Income",
    "navName": "ICICI Prudential MIP - Direct Plan Bonus",
    "minAmount": "3",
    "launchDate": "2000-09-28",
    "closeData": "",
    "isin": "INF109KA1H44",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100997",
    "name": "HDFC Long Term Advantage Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Long Term Advantage Plan - IDCW Plan",
    "minAmount": "For new & existing investors  Rs.500 and in multip",
    "launchDate": "2000-12-26",
    "closeData": "2000-12-27",
    "isin": "INF179K01970",
    "isinRe": "INF179K01988"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100998",
    "name": "HDFC Long Term Advantage Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Long Term Advantage Plan - Growth Plan",
    "minAmount": "For new & existing investors  Rs.500 and in multip",
    "launchDate": "2000-12-26",
    "closeData": "2000-12-27",
    "isin": "INF179K01996",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118928",
    "name": "HDFC Long Term Advantage Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Long Term Advantage Plan - IDCW Option - Direct Plan",
    "minAmount": "For new & existing investors  Rs.500 and in multip",
    "launchDate": "2000-12-26",
    "closeData": "2000-12-27",
    "isin": "INF179K01WV2",
    "isinRe": "INF179K01WW0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118929",
    "name": "HDFC Long Term Advantage Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Long Term Advantage Plan - Growth Option - Direct Plan",
    "minAmount": "For new & existing investors  Rs.500 and in multip",
    "launchDate": "2000-12-26",
    "closeData": "2000-12-27",
    "isin": "INF179K01WX8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101009",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Long",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101010",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Medium",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101011",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Dividend - Short",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101012",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Short",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101013",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Dividend - Medium",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101014",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series V A Short Term - 14/3/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101027",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series VIII A Short Term - 24/9/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101028",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series VIII A Medium Term - 2/4/03",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101029",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series XI A Short Term - 23/9/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101030",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series I A Basic (D) - 4/04/04",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101031",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series I A Long Term (G) - 25/4/05",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101032",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-A Medium Term - 19.11.04",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101021",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Long Term (A)",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101022",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Long Term (C)",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101023",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Growth - Long Term (C)",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101024",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series V A Short Term 14/09/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101025",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series VI A Short Term - 24/4/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101026",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Strategic Investment Plan - Series V A Short Term - 14/9/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101015",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series V A Short Term - 14/9/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101016",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series I A Short Term - 23/11/01",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101017",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series II A Short Term - 27/11/01",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101018",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series II A Short Term - 4/1/02",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101019",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Series III A Short Term - 26/12/01",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101020",
    "name": "Franklin India Strategic Investment Plan",
    "category": "Income",
    "navName": "Franklin India Strategic Investment Plan-Dividend - Medium Term (D)",
    "minAmount": "1",
    "launchDate": "2000-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101062",
    "name": "Franklin India Maxima Fund",
    "category": "Income",
    "navName": "Franklin India Maxima Fund-Growth Plan",
    "minAmount": "1",
    "launchDate": "1999-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100337",
    "name": "SaharaTax Gain",
    "category": "ELSS",
    "navName": "SaharaTax Gain-Dividend",
    "minAmount": "500",
    "launchDate": "1997-12-31",
    "closeData": "",
    "isin": "INF515L01189",
    "isinRe": "INF515L01197"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100338",
    "name": "SaharaTax Gain",
    "category": "ELSS",
    "navName": "SaharaTax Gain-Growth",
    "minAmount": "500",
    "launchDate": "1997-12-31",
    "closeData": "",
    "isin": "INF515L01205",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120290",
    "name": "SaharaTax Gain",
    "category": "ELSS",
    "navName": "SaharaTax Gain-Dividend- Direct",
    "minAmount": "500",
    "launchDate": "1997-12-31",
    "closeData": "",
    "isin": "INF515L01783",
    "isinRe": "INF515L01791"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120291",
    "name": "SaharaTax Gain",
    "category": "ELSS",
    "navName": "SaharaTax Gain-Growth- direct",
    "minAmount": "500",
    "launchDate": "1997-12-31",
    "closeData": "",
    "isin": "INF515L01809",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100899",
    "name": "HDFC Childrens Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "HDFC Children Gift Fund-Savings",
    "minAmount": "REFER SID",
    "launchDate": "2001-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "100900",
    "name": "HDFC Childrens Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "HDFC Childrens Gift Fund - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119066",
    "name": "HDFC Childrens Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "HDFC Childrens Gift Fund - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119067",
    "name": "HDFC Childrens Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "HDFC Childrens Gift Fund Savings Plan-Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101065",
    "name": "Quant Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "quant Mid Cap Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-12",
    "closeData": "2001-02-26",
    "isin": "INF966L01176",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101066",
    "name": "Quant Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "quant Mid Cap Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-12",
    "closeData": "2001-02-26",
    "isin": "INF966L01150",
    "isinRe": "INF966L01168"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120840",
    "name": "Quant Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "quant Mid Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-12",
    "closeData": "2001-02-26",
    "isin": "INF966L01861",
    "isinRe": "INF966L01879"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120841",
    "name": "Quant Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "quant Mid Cap Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-12",
    "closeData": "2001-02-26",
    "isin": "INF966L01887",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101069",
    "name": "Quant Absolute Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "quant Absolute Fund-IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01242",
    "isinRe": "INF966L01259"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101070",
    "name": "Quant Absolute Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "quant Absolute Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01267",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120818",
    "name": "Quant Absolute Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "quant Absolute Fund-IDCW Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01531",
    "isinRe": "INF966L01549"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120819",
    "name": "Quant Absolute Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "quant Absolute Fund-Growth Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01556",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100630",
    "name": "Quant Active Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "quant Active Fund-IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01218",
    "isinRe": "INF966L01226"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "100631",
    "name": "Quant Active Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "quant Active Fund-GROWTH OPTION - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01234",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120822",
    "name": "Quant Active Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "quant Active Fund-IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01598",
    "isinRe": "INF966L01606"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120823",
    "name": "Quant Active Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "quant Active Fund-GROWTH OPTION-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01614",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101071",
    "name": "Quant Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "quant Multi Asset Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01184",
    "isinRe": "INF966L01192"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "101072",
    "name": "Quant Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "quant Multi Asset Fund-GROWTH OPTION - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01200",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120820",
    "name": "Quant Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "quant Multi Asset Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01564",
    "isinRe": "INF966L01572"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120821",
    "name": "Quant Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "quant Multi Asset Fund-GROWTH OPTION-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2001-02-19",
    "closeData": "2001-03-20",
    "isin": "INF966L01580",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101044",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Franklin India Floating Rate Fund - IDCW ",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": "INF090I01LN5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101045",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Templeton India Liquid Plus-Growth Plan",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101046",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Templeton India Liquid Plus-Growth Plan",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101047",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Templeton India Liquid Plus-Growth Plan",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101048",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Franklin India Floating Rate Fund - Growth Plan",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": "INF090I01LM7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118507",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Franklin India Floating Rate Fund - Direct - IDCW ",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": "INF090I01LL9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118508",
    "name": "Franklin India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Franklin India Floating Rate Fund - Direct - Growth",
    "minAmount": "Rs. 1000",
    "launchDate": "2001-04-23",
    "closeData": "",
    "isin": "INF090I01LK1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100414",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund- Regular Plan - Growth Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01303",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101222",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund- Regular Plan - Periodic Payout of IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01DB6",
    "isinRe": "INF277K01295"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "113134",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund- Regular Plan - Monthly Payout of IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01CS2",
    "isinRe": "INF277K01CW4"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119053",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund -Direct Plan- Growth Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01MN2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119057",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund- Direct Plan - Monthly Payout of IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01MO0",
    "isinRe": "INF277K01MP7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119058",
    "name": "Tata Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Tata Hybrid Equity Fund- Direct Plan - Periodic Payout of IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "1995-08-30",
    "closeData": "",
    "isin": "INF277K01ML6",
    "isinRe": "INF277K01MM4"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100474",
    "name": "Tata India Tax Savings Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Tata India Tax Savings Fund- Regular Plan - Payout of IDCW Option",
    "minAmount": "Rs.500",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF277K01I60",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119281",
    "name": "Tata India Tax Savings Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Tata India Tax Savings Fund- Direct Plan - Payout of IDCW Option",
    "minAmount": "Rs.500",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF277K01I94",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "132756",
    "name": "Tata India Tax Savings Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Tata India Tax Savings Fund-Growth-Direct Plan",
    "minAmount": "Rs.500",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF277K01I86",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "132757",
    "name": "Tata India Tax Savings Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Tata India Tax Savings Fund-Growth-Regular Plan",
    "minAmount": "Rs.500",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF277K01I52",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101078",
    "name": "JM Liquid Fund - Daily Dividend Option",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Regular) - Daily IDCW",
    "minAmount": "1000",
    "launchDate": "1997-12-23",
    "closeData": "",
    "isin": "INF192K01833",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101083",
    "name": "HDFC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HDFC Gilt Fund - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-07-16",
    "closeData": "",
    "isin": "INF179K01756",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101084",
    "name": "HDFC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HDFC Gilt Fund - IDCW Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-07-16",
    "closeData": "",
    "isin": "INF179K01772",
    "isinRe": "INF179K01764"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119115",
    "name": "HDFC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HDFC Gilt Fund - IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-07-16",
    "closeData": "",
    "isin": "INF179K01VR2",
    "isinRe": "INF179K01VT8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119116",
    "name": "HDFC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HDFC Gilt Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2001-07-16",
    "closeData": "",
    "isin": "INF179K01VS0",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "101115",
    "name": "Taurus Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Libra Bond Fund-Fixed Maturity Plan - II",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2001-07-23",
    "closeData": "2001-08-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101098",
    "name": "ICICI Prudential Child Care -Study Plan",
    "category": "Income",
    "navName": "ICICI Prudential Child Care Plan Study - Cumulative",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2001-08-31",
    "closeData": "",
    "isin": "INF109K01597",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120691",
    "name": "ICICI Prudential Child Care -Study Plan",
    "category": "Income",
    "navName": "ICICI Prudential Child Care Plan Study - Direct Plan- Cumulative",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2001-08-31",
    "closeData": "",
    "isin": "INF109K01Y56",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101127",
    "name": "ICICI Prudential Child Care Fund (Gift Plan)",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ICICI Prudential Child Care Fund (Gift Plan) - Cumulative",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2001-08-31",
    "closeData": "",
    "isin": "INF109K01605",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120579",
    "name": "ICICI Prudential Child Care Fund (Gift Plan)",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ICICI Prudential Child Care Fund (Gift Plan) - Direct Plan - Cumulative",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2001-08-31",
    "closeData": "",
    "isin": "INF109K01Y49",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101228",
    "name": "ICICI Prudential Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential Multicap Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1994-09-01",
    "closeData": "",
    "isin": "INF109K01613",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101706",
    "name": "ICICI Prudential Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential Multicap Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1994-09-01",
    "closeData": "",
    "isin": "INF109K01FK7",
    "isinRe": "INF109K01621"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120598",
    "name": "ICICI Prudential Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential Multicap Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1994-09-01",
    "closeData": "",
    "isin": "INF109K012O3",
    "isinRe": "INF109K013O1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120599",
    "name": "ICICI Prudential Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential Multicap Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "1994-09-01",
    "closeData": "",
    "isin": "INF109K014O9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101229",
    "name": "ICICI Prudential Short Term Fund-",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Institutional Plan - Dividend Monthly",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01WK2",
    "isinRe": "INF109K01670"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101230",
    "name": "ICICI Prudential Short Term Fund-",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund-Institutional Plan-Dividend Fortnightly",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01688",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101231",
    "name": "ICICI Prudential Short Term Fund-",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund-Institutional Growth",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01696",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101271",
    "name": "LIC MF Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "LIC MF Children's Gift Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2001-09-26",
    "closeData": "2001-10-05",
    "isin": "INF767K01048",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120285",
    "name": "LIC MF Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "LIC MF Children's Gift Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2001-09-26",
    "closeData": "2001-10-05",
    "isin": "INF767K01FB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101165",
    "name": "ICICI Prudential Short Term Fund - Div. Option",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Monthly IDCW",
    "minAmount": "5",
    "launchDate": "2001-11-30",
    "closeData": "",
    "isin": "INF109K01WL0",
    "isinRe": "INF109K01647"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101316",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-Retail Growth",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01MC8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101317",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01LZ1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "105881",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-Weekly - retail IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01MD6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "105887",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-Regular - weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01MA2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "105888",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-Regular - DAILY IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01LY4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "109108",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-Retail - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01MB0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "112016",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund - Discipline Advantage Plan",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01JT8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119499",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-DIRECT - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01US7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119500",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund-DIRECT - DAILY IDCW",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01UQ1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119501",
    "name": "Aditya Birla Sun Life Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Aditya Birla Sun Life Savings Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2001-11-19",
    "closeData": "",
    "isin": "INF209K01UR9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101330",
    "name": "FT India Gilt Fund",
    "category": "Gilt",
    "navName": "FT India Gilt Fund-Investment Plan",
    "minAmount": "Growth Option - 10000 & Dividend Payout - 25000",
    "launchDate": "2001-12-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101331",
    "name": "FT India Gilt Fund",
    "category": "Gilt",
    "navName": "FT India Gilt Fund-Liquid Plan",
    "minAmount": "Growth Option - 10000 & Dividend Payout - 25000",
    "launchDate": "2001-12-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101356",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Quarterly - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01BZ0",
    "isinRe": "INF090I01BY3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101357",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund - Growth Option",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01CA1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101358",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund - Institutional Option - Growth",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01BX5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101359",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund - Institutional Option - Dividend",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01BW7",
    "isinRe": "INF090I01BV9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118505",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Direct - Quarterly - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01GT2",
    "isinRe": "INF090I01GU0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118506",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund - Direct - Growth",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01GV8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "122443",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Daily - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01KM9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "122444",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Direct - Daily - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01KN7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129436",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Monthly - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01LA2",
    "isinRe": "INF090I01LB0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129437",
    "name": "Franklin India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Franklin India Money Market Fund Retail Option - Direct - Monthly - IDCW",
    "minAmount": "Rs.10000 (Long Term Plan)",
    "launchDate": "2002-01-21",
    "closeData": "",
    "isin": "INF090I01LC8",
    "isinRe": "INF090I01LD6"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109349",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - REGULAR PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01CU3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109350",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - REGULAR PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01BZ4",
    "isinRe": "INF760K01CZ2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109351",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01CX7",
    "isinRe": "INF760K01CY5"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109353",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01CW9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109358",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Canara Robeco Liquid-Super Institutional-Monthly Div Payout",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118304",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01FT8",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118305",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01FU6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118306",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01FV4",
    "isinRe": "INF760K01FW2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118307",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - DIRECT PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01FS0",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118308",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "CANARA ROBECO LIQUID FUND - DIRECT PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01FY8",
    "isinRe": "INF760K01FX0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "139235",
    "name": "Canara Robeco Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Canara Robeco Liquid Fund -Unclaimed Redemption and Dividend Plan -Direct Growth Option",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2002-01-09",
    "closeData": "2002-01-11",
    "isin": "INF760K01IC8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101345",
    "name": "Z-OLD-Magnum Index Fund",
    "category": "Growth",
    "navName": "Magnum Index Fund",
    "minAmount": "5000",
    "launchDate": "2001-12-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101232",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-Term Income Plan-Growth",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01304",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101233",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Monthly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101234",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Growth Plan",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101235",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Monthly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101236",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01338",
    "isinRe": "INF090I01346"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101237",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-Term Income Plan-Weekly Bonus",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01DY9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148319",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan- Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VP9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148313",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Tem Income Plan - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct Retail Plan Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148314",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Institutional Plan Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VR5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148315",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-term Income Plan- Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VQ7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148316",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-term Income Plan-Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VV7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148317",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VO2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148318",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Retail Plan Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VN4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148015",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Direct-Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UY3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148016",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VB9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148017",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VA1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148018",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UZ0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148311",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VT1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148312",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01VU9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148009",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct- Quarterly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UQ9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148010",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UT3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148011",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UV9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148012",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UU1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148013",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Institutional Plan- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UX5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148014",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UW7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148003",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Monthly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UM8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148004",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct- Monthly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UR7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148005",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Weekly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UN6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148006",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-term Income Plan-Retail Plan- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Weekly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01US5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148007",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Quarterly Dividend Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UL0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148008",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short term Income Plan-Institutional Plan- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UO4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148001",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-Term Income Plan- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UK2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148002",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-term Income Plan- Retail Plan - Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct- Growth Option",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01UP1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101244",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-Term Income Plan-Institutional Plan - Growth",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01361",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101246",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short-Term Income Plan-Institutional Plan - Monthly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01387",
    "isinRe": "INF090I01395"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118565",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India SHORT TERM INCOME PLAN - Direct - GROWTH",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01GK1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118566",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Direct - Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01GL9",
    "isinRe": "INF090I01GM7"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118567",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Direct - Monthly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01GN5",
    "isinRe": "INF090I01GO3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118568",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Direct - Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01GP0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101238",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Quarterly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01312",
    "isinRe": "INF090I01320"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101239",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Weekly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101240",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Quarterly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101241",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Weekly Dividend",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101242",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Franklin India Short Term Income Plan -  Retail Plan - Weekly - IDCW",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": "INF090I01353",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101243",
    "name": "Franklin India Short-Term Income Plan (no. of segregated portfolios- 3)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Templeton India Short-Term Income Plan-Institutional Plan",
    "minAmount": "Rs.5000 (Retail) Rs.5 Crs (Institutional)",
    "launchDate": "2002-01-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101395",
    "name": "Sahara Liquid Fund",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Daily Dividend option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101396",
    "name": "Sahara Liquid Fund",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Growth option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101346",
    "name": "Z-OLD-SBI  Magnum Childrens Benefit Plan",
    "category": "Growth",
    "navName": "SBI  Magnum Childrens Benefit Plan",
    "minAmount": "1500",
    "launchDate": "2001-12-28",
    "closeData": "2001-12-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101169",
    "name": "SBI MAGNUM CHILDREN'S BENEFIT FUND - SAVINGS PLAN",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "SBI Magnum Children's Benefit Fund - Savings Plan - REGULAR PLAN - GROWTH",
    "minAmount": "1500",
    "launchDate": "2001-12-28",
    "closeData": "2001-12-28",
    "isin": "INF200K01776",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119719",
    "name": "SBI MAGNUM CHILDREN'S BENEFIT FUND - SAVINGS PLAN",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "SBI Magnum Children's Benefit Fund - Savings Plan - DIRECT PLAN - GROWTH",
    "minAmount": "1500",
    "launchDate": "2001-12-28",
    "closeData": "2001-12-28",
    "isin": "INF200K01QR6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101407",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND-Defunct Plan- IDCW Option",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01559",
    "isinRe": "INF955L01567"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101408",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND- Defunct Plan -Growth Option",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01575",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101409",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND-Defunct Plan-Daily IDCW Option",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01518",
    "isinRe": "INF955L01526"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "111704",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - Regular Plan - GROWTH OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01484",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "111705",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - Regular Plan - WEEKLY IDCW OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01492",
    "isinRe": "INF955L01500"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "139344",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas Liquid Fund - Plan C",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01GR4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "142245",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BARODA BNP PARIBAS LIQUID FUND PLAN C REDEMPTION ABOVE 3 YRS",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "142246",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BARODA BNP PARIBAS LIQUID FUND PLAN C IDCW UPTO 3YRS",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "142247",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BARODA BNP PARIBAS LIQUID FUND PLAN C REDEMPTION UPTO 3YRS",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "142248",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BARODA BNP PARIBAS LIQUID FUND PLAN C IDCW ABOVE 3 YRS",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "111707",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - Regular Plan - DAILY IDCW OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01468",
    "isinRe": "INF955L01476"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "116803",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas Liquid Fund - Regular - Weekly IDCW Option",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01534",
    "isinRe": "INF955L01542"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119414",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - DIRECT PLAN - DAILY IDCW OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01AK2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119415",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01AL0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119416",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - DIRECT PLAN - WEEKLY IDCW OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01AN6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "120495",
    "name": "BARODA BNP PARIBAS LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Baroda BNP Paribas LIQUID FUND - PLAN B (Direct) - MONTHLY IDCW OPTION",
    "minAmount": "Rs. 2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101349",
    "name": "ICICI Prudential Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 50 Index Fund - Cumulative Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-02-15",
    "closeData": "",
    "isin": "INF109K01PI0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120620",
    "name": "ICICI Prudential Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 50 Index Fund - Direct Plan Cumulative Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-02-15",
    "closeData": "",
    "isin": "INF109K012M7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135390",
    "name": "ICICI Prudential Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 50 Index Fund - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-02-15",
    "closeData": "",
    "isin": "INF109KB1NN7",
    "isinRe": "INF109KB1NO5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135391",
    "name": "ICICI Prudential Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 50 Index Fund - Direct Plan IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-02-15",
    "closeData": "",
    "isin": "INF109KB1NL1",
    "isinRe": "INF109KB1NM9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101221",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Retail Growth (erstwhile Cash Option)",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01TW3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115328",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Dividend",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01I72",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115329",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Sweep Plan- Dividend",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120209",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R22",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120210",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120211",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  Growth",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R14",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120212",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R89",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120213",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R63",
    "isinRe": "INF109K01R71"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120216",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Direct Plan -  IDCW Others",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109K01R30",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130945",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund -Bonus",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109KA1H93",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130946",
    "name": "ICICI Prudential Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund - Direct Plan Bonus",
    "minAmount": "5000",
    "launchDate": "2002-02-27",
    "closeData": "",
    "isin": "INF109KA1H85",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101181",
    "name": "JM Medium to Long Duration Fund Growth - Bonus Option",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Regular) - Bonus Option- Principal Units",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01AC1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148886",
    "name": "JM Medium to Long Duration Fund Growth - Bonus Option",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "JM Medium to Long Duration Fund (Regular) - Bonus Option - Bonus Units",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF192K01AD9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101184",
    "name": "LIC MF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "LIC MF Liquid Fund-Regular Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2002-03-11",
    "closeData": "",
    "isin": "INF767K01IQ3",
    "isinRe": "INF767K01IR1"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101185",
    "name": "LIC MF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "LIC MF Liquid Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2002-03-11",
    "closeData": "",
    "isin": "INF767K01IS9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120248",
    "name": "LIC MF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "LIC MF Liquid Fund-Direct Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2002-03-11",
    "closeData": "",
    "isin": "INF767K01DL5",
    "isinRe": "INF767K01DM3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120249",
    "name": "LIC MF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "LIC MF Liquid Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2002-03-11",
    "closeData": "",
    "isin": "INF767K01DN1",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101158",
    "name": "BARODA INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BOB INCOME FUND-STP",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101159",
    "name": "BARODA INCOME FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BOB INCOME FUND-STP",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101187",
    "name": "BARODA BNP PARIBAS GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Baroda BNP Paribas GILT FUND - Regular Plan - Growth Option",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01450",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101188",
    "name": "BARODA BNP PARIBAS GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Baroda BNP Paribas GILT FUND - Regular Plan - IDCW Option",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01435",
    "isinRe": "INF955L01443"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "101189",
    "name": "BARODA BNP PARIBAS GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BOB GILT FUND-PF",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119340",
    "name": "BARODA BNP PARIBAS GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Baroda BNP Paribas GILT FUND - Direct Plan - IDCW Option",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01AB1",
    "isinRe": "INF955L01AC9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119341",
    "name": "BARODA BNP PARIBAS GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Baroda BNP Paribas GILT FUND - Direct Plan - Growth Option",
    "minAmount": "2000",
    "launchDate": "2002-02-14",
    "closeData": "",
    "isin": "INF955L01AD7",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "101209",
    "name": "Taurus Largecap Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Taurus Largecap Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2008-05-07",
    "closeData": "",
    "isin": "INF044D01773",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "108403",
    "name": "Taurus Largecap Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Taurus Largecap Equity Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2008-05-07",
    "closeData": "",
    "isin": "INF044D01740",
    "isinRe": "INF044D01757"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118870",
    "name": "Taurus Largecap Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Taurus Largecap Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2008-05-07",
    "closeData": "",
    "isin": "INF044D01BX3",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118871",
    "name": "Taurus Largecap Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Taurus Largecap Equity Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2008-05-07",
    "closeData": "",
    "isin": "INF044D01BY1",
    "isinRe": "INF044D01BZ8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101350",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund Retail - Cumulative",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01712",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101351",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Weekly IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01FD2",
    "isinRe": "INF109K01704"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112094",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Premium Growth Option",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GM1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112096",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GN9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112097",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GK5",
    "isinRe": "INF109K01GL3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113218",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GO7",
    "isinRe": "INF109K01GP4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130938",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan Bonus",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109KA1H28",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120603",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K016E5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120604",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan - Weekly IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K019E9",
    "isinRe": "INF109K010F5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130937",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Bonus",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109KA1H36",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113220",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund Retail - Annual Dividend",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GI9",
    "isinRe": "INF109K01GJ7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113403",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Quarterly IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GE8",
    "isinRe": "INF109K01GF5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113417",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund Retail - Quarterly Dividend",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K01GG3",
    "isinRe": "INF109K01GH1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120600",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K014E0",
    "isinRe": "INF109K015E7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120601",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K012E4",
    "isinRe": "INF109K013E2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120602",
    "name": "ICICI Prudential All Seasons Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ICICI Prudential All Seasons Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "1000",
    "launchDate": "2008-03-12",
    "closeData": "",
    "isin": "INF109K017E3",
    "isinRe": "INF109K018E1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101372",
    "name": "Kotak Bond Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Kotak Bond Short Term Plan-(Payout of Income Distribution cum capital withdrawal option)",
    "minAmount": "Rs.1000",
    "launchDate": "2002-05-03",
    "closeData": "2002-05-03",
    "isin": "INF174K01EU3",
    "isinRe": "INF174K01ET5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101373",
    "name": "Kotak Bond Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Kotak Bond Short Term Plan-(Growth)",
    "minAmount": "Rs.1000",
    "launchDate": "2002-05-03",
    "closeData": "2002-05-03",
    "isin": "INF174K01ES7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119739",
    "name": "Kotak Bond Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Kotak Bond Short Term Plan-(Growth) - Direct",
    "minAmount": "Rs.1000",
    "launchDate": "2002-05-03",
    "closeData": "2002-05-03",
    "isin": "INF174K01JI7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135500",
    "name": "Kotak Bond Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Kotak Bond Short Term Plan - Direct - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.1000",
    "launchDate": "2002-05-03",
    "closeData": "2002-05-03",
    "isin": "INF174K01Z14",
    "isinRe": "INF174K01Z22"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135501",
    "name": "Kotak Bond Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Kotak Bond Short Term Plan - Regular Plan - Normal Dividend",
    "minAmount": "Rs.1000",
    "launchDate": "2002-05-03",
    "closeData": "2002-05-03",
    "isin": "INF174K01Y98",
    "isinRe": "INF174K01Z06"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101509",
    "name": "JM Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund",
    "minAmount": "100000",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120469",
    "name": "JM Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund (Direct) - Daily Dividend Option",
    "minAmount": "100000",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01DZ6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120470",
    "name": "JM Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund (Direct) - Dividend Option",
    "minAmount": "100000",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01DX1",
    "isinRe": "INF192K01DY9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120471",
    "name": "JM Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund (Direct) - Growth Option",
    "minAmount": "100000",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01EA7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101519",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01BA3",
    "isinRe": "INF192K01BB1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101520",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01BC9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101521",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01BG0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101522",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund..-Monthly Dividend",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101523",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund..-Institutional Plan - Monthly Dividend",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101524",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01BE5",
    "isinRe": "INF192K01BF2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "115090",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Regular Plan - Daily Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01AZ2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "115103",
    "name": "JM Short Term Fund..",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Term Fund - Daily Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2002-06-18",
    "closeData": "",
    "isin": "INF192K01BD7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101281",
    "name": "HDFC Index Fund-S&P BSE SENSEX Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Index Fund-S&P BSE Sensex Plan - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-07-03",
    "closeData": "",
    "isin": "INF179K01LA9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119065",
    "name": "HDFC Index Fund-S&P BSE SENSEX Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Index Fund-S&P BSE Sensex Plan - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-07-03",
    "closeData": "",
    "isin": "INF179K01WN9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101525",
    "name": "HDFC Index Fund-Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Index Fund-NIFTY 50 Plan - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-07-03",
    "closeData": "",
    "isin": "INF179K01KZ8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119063",
    "name": "HDFC Index Fund-Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Index Fund-NIFTY 50 Plan - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-07-03",
    "closeData": "",
    "isin": "INF179K01WM1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101538",
    "name": "Sundaram Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Sundaram Mid Cap Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": "INF903J01181",
    "isinRe": "INF903J01199"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101539",
    "name": "Sundaram Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Sundaram Mid Cap Fund Regular Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": "INF903J01173",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119581",
    "name": "Sundaram Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Sundaram Mid Cap Fund Direct Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": "INF903J01MJ3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119582",
    "name": "Sundaram Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Sundaram Mid Cap Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": "INF903J01MH7",
    "isinRe": "INF903J01MI5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101543",
    "name": "Sundaram Short Term Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Sundaram  Income Plus-Appreciation",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101546",
    "name": "Sundaram Short Term Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Sundaram  Income Plus-Short Term Plan - Institutional Monthly  Dividend",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "104110",
    "name": "Sundaram Short Term Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Sundaram BNP Paribas  Income Plus-Appreciation",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101549",
    "name": "JM Liquid Fund - Bonus Option",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "JM Liquid Fund (Regular) - Bonus Option - Principal Units",
    "minAmount": "1000",
    "launchDate": "2002-08-17",
    "closeData": "",
    "isin": "INF192K01890",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101528",
    "name": "Sahara Growth Fund",
    "category": "Growth",
    "navName": "Sahara Growth Fund-Growth",
    "minAmount": "3000",
    "launchDate": "2002-07-22",
    "closeData": "",
    "isin": "INF515L01171",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101529",
    "name": "Sahara Growth Fund",
    "category": "Growth",
    "navName": "Sahara Growth Fund-Dividend",
    "minAmount": "3000",
    "launchDate": "2002-07-22",
    "closeData": "",
    "isin": "INF515L01155",
    "isinRe": "INF515L01163"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120288",
    "name": "Sahara Growth Fund",
    "category": "Growth",
    "navName": "Sahara Growth Fund-Dividend- Direct",
    "minAmount": "3000",
    "launchDate": "2002-07-22",
    "closeData": "",
    "isin": "INF515L01759",
    "isinRe": "INF515L01767"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120289",
    "name": "Sahara Growth Fund",
    "category": "Growth",
    "navName": "Sahara Growth Fund-Growth- Direct",
    "minAmount": "3000",
    "launchDate": "2002-07-22",
    "closeData": "",
    "isin": "INF515L01775",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101552",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-3-Year Asset Plan-Quarterly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101553",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-3-Year Asset Plan-Half-Yearly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101554",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-3-Year Asset Plan-Annual Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101555",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-3-Year Asset Plan-Appreciation Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101556",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-Long-Term Asset Plan-Quarterly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101557",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-Long-Term Asset Plan-Half-Yearly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101571",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Select  Debt-Dynamic Asset Plan-Quarterly Dividend",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101573",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Select  Debt-Dynamic Asset Plan-Annual Divided",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101574",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Select  Debt-Dynamic Asset Plan-Appreciation",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101558",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-Long-Term Asset Plan-Annual Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101559",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-Long-Term Asset Plan-Appreciation Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101567",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-5-Year Asset Plan-Quarterly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101568",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-5-Year Asset Plan-Half-Yearly Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101569",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-5-Year Asset Plan-Annual Div",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "101570",
    "name": "Sundaram Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram BNP Paribas Select  Debt-5-Year Asset Plan-Appreciation Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101302",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Merrill Lynch Short Term Fund--",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101303",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Regular Plan - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740KA1PF4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101304",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01656",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101305",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01649",
    "isinRe": "INF740K01AF0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101306",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01664",
    "isinRe": "INF740K01AG8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119222",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01NK3",
    "isinRe": "INF740K01NN7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119223",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740KA1PD9",
    "isinRe": "INF740KA1PE7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119224",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01NL1",
    "isinRe": "INF740K01NM9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119226",
    "name": "DSP Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DSP Short Term Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2002-08-30",
    "closeData": "2002-09-04",
    "isin": "INF740K01NJ5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101295",
    "name": "SBI MAGNUM EQUITY ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Equity ESG Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "1000",
    "launchDate": "1993-10-29",
    "closeData": "",
    "isin": "INF200K01198",
    "isinRe": "INF200K01206"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104523",
    "name": "SBI MAGNUM EQUITY ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Equity ESG Fund- REGULAR PLAN - Growth",
    "minAmount": "1000",
    "launchDate": "1993-10-29",
    "closeData": "",
    "isin": "INF200K01214",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119708",
    "name": "SBI MAGNUM EQUITY ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Equity ESG Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "1000",
    "launchDate": "1993-10-29",
    "closeData": "",
    "isin": "INF200K01SC4",
    "isinRe": "INF200K01SD2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119709",
    "name": "SBI MAGNUM EQUITY ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Equity ESG Fund - DIRECT PLAN -GROWTH",
    "minAmount": "1000",
    "launchDate": "1993-10-29",
    "closeData": "",
    "isin": "INF200K01SE0",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "101587",
    "name": "Canara Robeco Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "CANARA ROBECO INCOME FUND - REGULAR PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/",
    "launchDate": "2002-08-20",
    "closeData": "2002-09-07",
    "isin": "INF760K01340",
    "isinRe": "INF760K01357"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "101588",
    "name": "Canara Robeco Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "CANARA ROBECO INCOME FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/",
    "launchDate": "2002-08-20",
    "closeData": "2002-09-07",
    "isin": "INF760K01324",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "101590",
    "name": "Canara Robeco Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "CANINCOME-AUTOMATIC REPURCHASE PLAN",
    "minAmount": "Rs.5000/",
    "launchDate": "2002-08-20",
    "closeData": "2002-09-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118281",
    "name": "Canara Robeco Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "CANARA ROBECO INCOME FUND - DIRECT PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/",
    "launchDate": "2002-08-20",
    "closeData": "2002-09-07",
    "isin": "INF760K01FG5",
    "isinRe": "INF760K01FH3"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118282",
    "name": "Canara Robeco Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "CANARA ROBECO INCOME FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/",
    "launchDate": "2002-08-20",
    "closeData": "2002-09-07",
    "isin": "INF760K01FI1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101313",
    "name": "Aditya Birla Sun Life Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Index Fund -REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-10",
    "closeData": "2002-09-11",
    "isin": "INF209K01LA4",
    "isinRe": "INF209K01LC0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101314",
    "name": "Aditya Birla Sun Life Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Index Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2002-09-10",
    "closeData": "2002-09-11",
    "isin": "INF209K01LB2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119648",
    "name": "Aditya Birla Sun Life Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Index Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2002-09-10",
    "closeData": "2002-09-11",
    "isin": "INF209K01VY3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119649",
    "name": "Aditya Birla Sun Life Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Index Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-10",
    "closeData": "2002-09-11",
    "isin": "INF209K01VX5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101617",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01JO1",
    "isinRe": "INF109K01753"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101618",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01738",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101619",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01746",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111793",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund Retail Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01EJ2",
    "isinRe": "INF109K01BY7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111794",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund Retail Growth",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01BX9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111795",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund Retail Daily Dividend",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01EI4",
    "isinRe": "INF109K01BW1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130952",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan Bonus",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109KA1I01",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122651",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K018Y9",
    "isinRe": "INF109K017Y1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122748",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K012Z9",
    "isinRe": "INF109K011Z1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122904",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K016Y3",
    "isinRe": "INF109K015Y5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122982",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K010Z3",
    "isinRe": "INF109K019Y7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122995",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K016Z0",
    "isinRe": "INF109K015Z2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130900",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Bonus",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109KA1I92",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115511",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - IDCW Others",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01WO4",
    "isinRe": "INF109K01WN6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120396",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01P08",
    "isinRe": "INF109K01P16"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120397",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01O66",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120398",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01O82",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120399",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Direct Plan - IDCW Others",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K01O90",
    "isinRe": "INF109K01O74"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122531",
    "name": "ICICI Prudential Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "ICICI Prudential Savings Fund - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-08-01",
    "closeData": "",
    "isin": "INF109K014Z5",
    "isinRe": "INF109K013Z7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101591",
    "name": "Aditya Birla Sun Life Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Midcap Fund -REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-09",
    "closeData": "2002-09-09",
    "isin": "INF209K01355",
    "isinRe": "INF209K01CN6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101592",
    "name": "Aditya Birla Sun Life Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Aditya Birla Sun Life MIDCAP Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2002-09-09",
    "closeData": "2002-09-09",
    "isin": "INF209K01363",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119619",
    "name": "Aditya Birla Sun Life Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Midcap Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-09",
    "closeData": "2002-09-09",
    "isin": "INF209K01Q22",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119620",
    "name": "Aditya Birla Sun Life Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Aditya Birla Sun Life Midcap Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2002-09-09",
    "closeData": "2002-09-09",
    "isin": "INF209K01Q30",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101143",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K01ED5",
    "isinRe": "INF109K01779"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101144",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K01761",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104898",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund-Institutional Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K01787",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115269",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund- Institutional Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K01TN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120292",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan -  Daily Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120334",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K015K4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120335",
    "name": "ICICI Prudential Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "ICICI Prudential Multi-Asset Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2002-10-07",
    "closeData": "",
    "isin": "INF109K013K9",
    "isinRe": "INF109K014K7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101593",
    "name": "HSBC Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HSBC Large Cap Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01024",
    "isinRe": "INF336L01032"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101594",
    "name": "HSBC Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HSBC Large Cap Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01016",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120029",
    "name": "HSBC Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HSBC Large Cap Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01CK1",
    "isinRe": "INF336L01CL9"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120030",
    "name": "HSBC Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HSBC Large Cap Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01CM7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101683",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Income Fund - Investment Plan-Dividend-Payout",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101684",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Income Fund - Investment Plan-Dividend-Reinvestment",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101685",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Medium to Long Duration Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01776",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101686",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Medium to Long Duration Fund - Regular Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01784",
    "isinRe": "INF336L01792"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120059",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Medium to Long Duration Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01DE2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120101",
    "name": "HSBC Medium to Long Duration Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "HSBC Medium to Long Duration Fund - Direct Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": "INF336L01EO9",
    "isinRe": "INF336L01EP6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101595",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Income Fund - Short Term Plan-Dividend-Payout",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101596",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Income Fund - Short Term Plan-Dividend-Reinvestment",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101689",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Cash Fund-Growth-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101690",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Cash Fund-Growth-Dividend Option - Daily",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "101695",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Cash Fund-Dividend-Reinvestment",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118901",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Regular Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BM9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118902",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BN7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118903",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BP2",
    "isinRe": "INF336L01BO5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120038",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund -  Growth Direct",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01CC8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120039",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BZ1",
    "isinRe": "INF336L01CA2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120040",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01LK2",
    "isinRe": "INF336L01CD6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118904",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01LJ4",
    "isinRe": "INF336L01BQ0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118906",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BK3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118907",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BL1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118908",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BR8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "118909",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Institutional Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01BS6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120037",
    "name": "HSBC Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "HSBC Liquid Fund - Direct Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2002-11-14",
    "closeData": "2002-12-11",
    "isin": "INF336L01CB0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115203",
    "name": "LIC MF Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC NOMURA MF Index Fund",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101609",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "TATA Equity Savings Fund- Regular Plan -Monthly Payout of IDCW option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01EE8",
    "isinRe": "INF277K01899"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101906",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Tata Equity Savings Fund -Regular Plan-Growth Option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01907",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102118",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Tata Equity Savings Fund- Regular Plan - Periodic Payout of IDCW Option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01EF5",
    "isinRe": "INF277K01915"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119958",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Tata Equity Savings Fund-Direct Plan - Monthly Payout of IDCW Option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01QI3",
    "isinRe": "INF277K01QJ1"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119959",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Tata Equity Savings Fund-Direct Plan - Periodic Payout of IDCWS Option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01QK9",
    "isinRe": "INF277K01QL7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119960",
    "name": "Tata Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Tata Equity Savings Fund- Direct Plan- Growth Option",
    "minAmount": "Rs.5000",
    "launchDate": "2000-04-27",
    "closeData": "",
    "isin": "INF277K01QH5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101665",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Nippon India Short Term Fund-Growth Plan",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01FL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101666",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Reliance Short Term Fund-Bonus Plan",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101667",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - MONTHLY IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01FM2",
    "isinRe": "INF204K01FN0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118800",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - Direct Plan - QUARTERLY IDCW OPTION",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01B65",
    "isinRe": "INF204K01B73"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125268",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204KA1EQ4",
    "isinRe": "INF204KA1ER2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125269",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204KA1ES0",
    "isinRe": "INF204KA1ET8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101668",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Reliance Short Term Fund-Institutional Plan",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101669",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - QUARTERLY IDCW OPTION",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01FO8",
    "isinRe": "INF204K01FP5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118796",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Nippon India Short Term Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01B32",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118797",
    "name": "Nippon India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "NIPPON INDIA SHORT TERM FUND - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2002-12-09",
    "closeData": "",
    "isin": "INF204K01B40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101705",
    "name": "ICICI Prudential S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential S&P BSE Sensex ETF",
    "minAmount": "5000",
    "launchDate": "2003-01-06",
    "closeData": "",
    "isin": "INF346A01034",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101737",
    "name": "Aditya Birla Sun Life Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Aditya Birla Sun Life Dividend Yield Fund -REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-23",
    "closeData": "2003-01-27",
    "isin": "INF209K01397",
    "isinRe": "INF209K01CP1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101738",
    "name": "Aditya Birla Sun Life Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Aditya Birla Sun Life Dividend Yield Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2003-01-23",
    "closeData": "2003-01-27",
    "isin": "INF209K01405",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119506",
    "name": "Aditya Birla Sun Life Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Aditya Birla Sun Life Dividend Yield Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-23",
    "closeData": "2003-01-27",
    "isin": "INF209K01Q55",
    "isinRe": "INF209K01WB9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119507",
    "name": "Aditya Birla Sun Life Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Aditya Birla Sun Life Dividend Yield Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2003-01-23",
    "closeData": "2003-01-27",
    "isin": "INF209K01WA1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101659",
    "name": "Tata Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty 50 Index Fund -Regular Plan",
    "minAmount": "5000/-",
    "launchDate": "2003-02-20",
    "closeData": "",
    "isin": "INF277K01741",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119288",
    "name": "Tata Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty 50 Index Fund -Direct Plan",
    "minAmount": "5000/-",
    "launchDate": "2003-02-20",
    "closeData": "",
    "isin": "INF277K01PJ3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101635",
    "name": "DSP Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "DSP Top 100 Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2003-02-10",
    "closeData": "2003-02-21",
    "isin": "INF740K01243",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101636",
    "name": "DSP Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "DSP Top 100 Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2003-02-10",
    "closeData": "2003-02-21",
    "isin": "INF740K01227",
    "isinRe": "INF740K01235"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119249",
    "name": "DSP Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "DSP Top 100 Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2003-02-10",
    "closeData": "2003-02-21",
    "isin": "INF740K01PS1",
    "isinRe": "INF740K01PT9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119250",
    "name": "DSP Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "DSP Top 100 Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2003-02-10",
    "closeData": "2003-02-21",
    "isin": "INF740K01PR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101642",
    "name": "ICICI Prudential Long Term Bond Fund-",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund - Institutional Dividend Halfyearly",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01EW5",
    "isinRe": "INF109K01373"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101643",
    "name": "ICICI Prudential Long Term Bond Fund-",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "ICICI Prudential Long Term Bond Fund-Institutional Option-Growth",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01407",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101748",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Institutional - Daily - Div",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01VE7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101750",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund Retail Growth",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01UQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101751",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Institutional Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01VF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101752",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund Retail Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01UV3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101753",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Institutional - Weekly - Div",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01VI8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101754",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund Retail Daily Dividend",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01UR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101755",
    "name": "ICICI Prudential Liquid Fund-",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund Retail Monthly Dividend",
    "minAmount": "5000",
    "launchDate": "2003-01-20",
    "closeData": "",
    "isin": "INF109K01US9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101758",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K01654",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101759",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Reinvestment of IDCW Fortnightly",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KB12M9",
    "isinRe": "INF109K01662"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120753",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Direct Plan - Reinvestment of IDCW Fortnightly",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K012N5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120754",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K013N3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120755",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109K014N1",
    "isinRe": "INF109K015N8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122674",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Bonus",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA1376",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122909",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Direct Plan - Bonus",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA1368",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131478",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA1X36",
    "isinRe": "INF109KA1X28"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131479",
    "name": "ICICI Prudential Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "ICICI Prudential Short Term Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-11",
    "closeData": "",
    "isin": "INF109KA1X10",
    "isinRe": "INF109KA1X02"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101785",
    "name": "ICICI Prudential  FMP-Growth Yearly 12",
    "category": "Income",
    "navName": "ICICI Prudential  FMP-Growth Yearly 12-Institutional Plan - Growth",
    "minAmount": "*",
    "launchDate": "2001-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101786",
    "name": "ICICI Prudential  FMP-Growth Yearly 12",
    "category": "Income",
    "navName": "ICICI Prudential  FMP-Growth Yearly 12-Institutional Plan - Growth",
    "minAmount": "*",
    "launchDate": "2001-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101787",
    "name": "ICICI Prudential  FMP-Growth Yearly 12",
    "category": "Income",
    "navName": "ICICI Prudential  FMP-Growth Yearly 12-Growth",
    "minAmount": "*",
    "launchDate": "2001-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101788",
    "name": "ICICI Prudential  FMP-Growth Yearly 12",
    "category": "Income",
    "navName": "ICICI Prudential  FMP-Growth Yearly 12-Growth",
    "minAmount": "*",
    "launchDate": "2001-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101792",
    "name": "Kotak FMP (6)",
    "category": "Income",
    "navName": "Kotak FMP (6)-(Growth)",
    "minAmount": "",
    "launchDate": "2003-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101793",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan B - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01977",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101794",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan B - Fortnightly Dividend",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01969",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101795",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan A - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01936",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101796",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan A - Fortnightly Dividend",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01910",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101797",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Savings Fund Plan C - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01AB7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101798",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan C - Fortnightly Dividend",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01993",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130899",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Bonus",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1I43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120426",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - IDCW Others",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01P65",
    "isinRe": "INF109K01P32"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120427",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01P40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122606",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Monthly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1038",
    "isinRe": "INF109KA1020"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122607",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K018Z6",
    "isinRe": "INF109K017Z8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122891",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Quarterly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1053",
    "isinRe": "INF109KA1046"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125216",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109KA1012",
    "isinRe": "INF109K019Z4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115510",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - IDCW Others",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01WQ9",
    "isinRe": "INF109K01WP1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120423",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01P24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120424",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01P73",
    "isinRe": "INF109K01P81"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120425",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01P57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111543",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan B - Weekly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01EL8",
    "isinRe": "INF109K01985"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111544",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan A - Weekly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01EK0",
    "isinRe": "INF109K01944"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111545",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Weekly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01EN4",
    "isinRe": "INF109K01AY9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111546",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Savings Fund Plan C - Weekly Dividend Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01EM6",
    "isinRe": "INF109K01AC5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101799",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan B - Dividend Daily",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01951",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101800",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan A - Dividend Daily",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01928",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101801",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund Plan C - Dividend Daily",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01AA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101802",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01AX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101803",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Fortnightly IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01AZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "101804",
    "name": "ICICI Prudential Floating Interest Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "ICICI Prudential Floating Interest Fund - Daily IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2000-01-01",
    "closeData": "",
    "isin": "INF109K01AW3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101826",
    "name": "Kotak FMP (7)",
    "category": "Income",
    "navName": "Kotak FMP (7)-(Growth)",
    "minAmount": "",
    "launchDate": "2003-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101827",
    "name": "Kotak FMP (7)",
    "category": "Income",
    "navName": "Kotak FMP (7)-(Dividend)",
    "minAmount": "",
    "launchDate": "2003-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "101834",
    "name": "UTI - Master Equity Plan Unit Scheme",
    "category": "ELSS",
    "navName": "UTI - Master Equity Plan Unit Scheme",
    "minAmount": "",
    "launchDate": "2003-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101837",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan -Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01599",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101838",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01FR4",
    "isinRe": "INF740K01FQ6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101839",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01581",
    "isinRe": "INF740K01AB9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101840",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740KA1OA8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "101841",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Merrill Lynch Floating Rate Fund - Regular Plan-Institutional Plan",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117061",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01JW6",
    "isinRe": "INF740K01JV8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119087",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740KA1NX2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117062",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Regular Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01JY2",
    "isinRe": "INF740K01JX4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119082",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01OS4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119083",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01OT2",
    "isinRe": "INF740K01OU0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119084",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan -  IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01OV8",
    "isinRe": "INF740K01OW6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119085",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740K01OX4",
    "isinRe": "INF740K01OY2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119086",
    "name": "DSP Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "DSP Credit Risk Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2003-05-12",
    "closeData": "2003-05-12",
    "isin": "INF740KA1NY0",
    "isinRe": "INF740KA1NZ7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101843",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund - REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209K01975",
    "isinRe": "INF209K01DK0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101844",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209K01942",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "112356",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund - REGULAR - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209K01BU3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119496",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund - DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209KA1KQ6",
    "isinRe": "INF209K01XL6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119497",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund-Quarterly-IDCW-Direct",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209K01R96",
    "isinRe": "INF209KA1KR4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119498",
    "name": "Aditya Birla Sun Life Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Aditya Birla Sun Life Short Term Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2002-04-22",
    "closeData": "2003-04-24",
    "isin": "INF209K01XK8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101850",
    "name": "Z-OLD-SBI Magnum Insta Cash Fund - Short Term Plan - Growth Option",
    "category": "Liquid",
    "navName": "SBI Magnum Insta Cash Fund - Short Term Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2001-09-16",
    "closeData": "2001-09-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101967",
    "name": "Z-OLD-SBI Magnum Insta Cash Fund - Short Term Plan - Dividend",
    "category": "Liquid",
    "navName": "SBI Magnum Insta Cash Fund - Short Term Plan - Dividend",
    "minAmount": "1000",
    "launchDate": "2003-05-17",
    "closeData": "2003-05-17",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101862",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund-Growth Plan-Growth Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01927",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101863",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01919",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101864",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund - IDCW Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01893",
    "isinRe": "INF204K01901"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101865",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Reliance Banking Fund-Dividend Plan-Divi Reinvestment Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118588",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01D22",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118589",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01XO1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118591",
    "name": "Nippon India Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Banking & Financial Services Fund- DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 (Retail)Rs 5 Crore (Institutional)",
    "launchDate": "2003-05-08",
    "closeData": "2003-05-26",
    "isin": "INF204K01XM5",
    "isinRe": "INF204K01XN3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101866",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Regular Plan-Monthly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01816",
    "isinRe": "INF767K01824"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101867",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Regular Plan-Quarterly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01832",
    "isinRe": "INF767K01840"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101868",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Regular Plan-Yearly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01857",
    "isinRe": "INF767K01865"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101869",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Regular Plan-Growth",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01808",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120274",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01EQ2",
    "isinRe": "INF767K01ET6"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120275",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Direct Plan-Yearly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01ER0",
    "isinRe": "INF767K01EU4"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120276",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Direct Plan-Growth",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01EO7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120277",
    "name": "LIC MF Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "LIC MF Debt Hybrid Fund-Direct Plan-Monthly IDCW",
    "minAmount": "1000/-",
    "launchDate": "2003-06-01",
    "closeData": "",
    "isin": "INF767K01EP4",
    "isinRe": "INF767K01ES8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101970",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - RETAIL - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01JY8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101971",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Retail Growth",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01MJ3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101972",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Retail - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01JZ5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101973",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01SZ6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101974",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01SY9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101976",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01RV7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119513",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Direct - daily IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UT5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119511",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UU3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119512",
    "name": "Aditya Birla Sun Life Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Aditya Birla Sun Life Money Manager Fund - Direct - weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UV1",
    "isinRe": "INF209KA1LM3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101829",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Regular Plan-Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01AM9",
    "isinRe": "INF767K01AN7"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101830",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01AO5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "111675",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Regular Plan-Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01AP2",
    "isinRe": "INF767K01AQ0"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "111676",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Regular Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01AK3",
    "isinRe": "INF767K01AL1"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120315",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01FM8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120316",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Direct Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01FL0",
    "isinRe": "INF767K01FN6"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120317",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Direct Plan-Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01FP1",
    "isinRe": "INF767K01FO4"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120318",
    "name": "LIC MF Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "LIC MF Savings Fund-Direct Plan-Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-05-29",
    "closeData": "",
    "isin": "INF767K01JJ6",
    "isinRe": "INF767K01JK4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101762",
    "name": "HDFC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HDFC Flexi Cap Fund - Growth Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1994-12-08",
    "closeData": "1994-12-24",
    "isin": "INF179K01608",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101763",
    "name": "HDFC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HDFC Flexi Cap Fund - IDCW Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1994-12-08",
    "closeData": "1994-12-24",
    "isin": "INF179K01582",
    "isinRe": "INF179K01590"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118954",
    "name": "HDFC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HDFC Flexi Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1994-12-08",
    "closeData": "1994-12-24",
    "isin": "INF179K01VL5",
    "isinRe": "INF179K01VM3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118955",
    "name": "HDFC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HDFC Flexi Cap Fund - Growth Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1994-12-08",
    "closeData": "1994-12-24",
    "isin": "INF179K01UT0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101764",
    "name": "HDFC Capital Builder Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HDFC Capital Builder Value Fund - Growth Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1993-12-16",
    "closeData": "1993-12-31",
    "isin": "INF179K01426",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101765",
    "name": "HDFC Capital Builder Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HDFC Capital Builder Value Fund - IDCW Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1993-12-16",
    "closeData": "1993-12-31",
    "isin": "INF179K01400",
    "isinRe": "INF179K01418"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118934",
    "name": "HDFC Capital Builder Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HDFC Capital Builder Value Fund - IDCW Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1993-12-16",
    "closeData": "1993-12-31",
    "isin": "INF179K01VA8",
    "isinRe": "INF179K01VB6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118935",
    "name": "HDFC Capital Builder Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HDFC Capital Builder Value Fund - Growth Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1993-12-16",
    "closeData": "1993-12-31",
    "isin": "INF179K01VC4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119059",
    "name": "HDFC TaxSaver",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Taxsaver - IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF179K01YQ8",
    "isinRe": "INF179K01YR6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119060",
    "name": "HDFC TaxSaver",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Taxsaver - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF179K01YS4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101979",
    "name": "HDFC TaxSaver",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Taxsaver - Growth Plan",
    "minAmount": "REFER SID",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF179K01BB8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101980",
    "name": "HDFC TaxSaver",
    "category": "Equity Scheme - ELSS",
    "navName": "HDFC Taxsaver - IDCW Plan",
    "minAmount": "REFER SID",
    "launchDate": "1996-03-31",
    "closeData": "",
    "isin": "INF179K01AZ9",
    "isinRe": "INF179K01BA0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101872",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Growth Option",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01848",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101873",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Quarterly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01871",
    "isinRe": "INF179K01889"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101874",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Half Yearly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01855",
    "isinRe": "INF179K01863"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101875",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Yearly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01921",
    "isinRe": "INF179K01939"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133369",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Normal IDCW - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179KA1Q95",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133370",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Normal IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179KA1R11",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119072",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Half Yearly IDCW - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01WC2",
    "isinRe": "INF179K01WD0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119073",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Quarterly IDCW - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01WE8",
    "isinRe": "INF179K01WF5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119074",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Yearly IDCW - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01WG3",
    "isinRe": "INF179K01WH1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119075",
    "name": "HDFC Dynamic Debt Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HDFC Dynamic Debt Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1997-04-28",
    "closeData": "",
    "isin": "INF179K01WB4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101989",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - Growth Option",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179K01913",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101990",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - Fornightly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179K01897",
    "isinRe": "INF179K01905"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "132852",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KA1E16",
    "isinRe": "INF179KA1E08"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "132853",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KA1D90",
    "isinRe": "INF179KA1D82"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119080",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - Fortnightly IDCW Opt - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KA1NP8",
    "isinRe": "INF179KA1NO1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119081",
    "name": "HDFC Medium Term Debt Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HDFC Medium Term Debt Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179K01WI9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101993",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Growth Option",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1HR5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101994",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Daily IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1IG6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101995",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Weekly IDCW Option",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1IH4",
    "isinRe": "INF179KB1II2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119092",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1HU9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119093",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Daily IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1HZ8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119094",
    "name": "HDFC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HDFC Money Market Fund - Weekly IDCW Option -Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "1999-11-18",
    "closeData": "",
    "isin": "INF179KB1IA9",
    "isinRe": "INF179KB1IB7"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101996",
    "name": "HDFC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HDFC Overnight Fund - Growth Option",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KB1HS3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101997",
    "name": "HDFC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HDFC Overnight Fund - IDCW Option (Daily)",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KB1IF8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119109",
    "name": "HDFC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HDFC Overnight Fund - Daily IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KB1HY1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119110",
    "name": "HDFC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HDFC Overnight Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF179KB1HT1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102000",
    "name": "HDFC Top 100 Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HDFC Top 100 Fund - Growth Option - Regular Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1996-08-19",
    "closeData": "2000-08-09",
    "isin": "INF179K01BE2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102001",
    "name": "HDFC Top 100 Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HDFC Top 100 Fund - IDCW Option - Regular Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1996-08-19",
    "closeData": "2000-08-09",
    "isin": "INF179K01BC6",
    "isinRe": "INF179K01BD4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119017",
    "name": "HDFC Top 100 Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HDFC Top 100 Fund - IDCW Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1996-08-19",
    "closeData": "2000-08-09",
    "isin": "INF179K01YT2",
    "isinRe": "INF179K01YU0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119018",
    "name": "HDFC Top 100 Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "HDFC Top 100 Fund - Growth Option - Direct Plan",
    "minAmount": "For new investors  Rs.5000 For existing Rs. 1000",
    "launchDate": "1996-08-19",
    "closeData": "2000-08-09",
    "isin": "INF179K01YV8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101805",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01684",
    "isinRe": "INF192K01692"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101806",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Growth Option",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01700",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101807",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular)- Premium Plan IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01734",
    "isinRe": "INF192K01742"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "101808",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund - Premium Plan - Growth Option",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01759",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "105637",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Weekly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01676",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "105638",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund - Premium Plan - Daily Dividend Option",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01718",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133389",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01IN1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133390",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Monthly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01IJ9",
    "isinRe": "INF192K01IK7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133414",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Direct) - Monthly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01IL5",
    "isinRe": "INF192K01IM3"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120433",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Direct) - Weekly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01DA9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120434",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Direct) - IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01DB7",
    "isinRe": "INF192K01DC5"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120435",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund - (Direct) - Growth Option",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01DD3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "122315",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund - (Direct) - Bonus Option-Principal Units",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01EZ4",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "122323",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Bonus Option - Principal Units",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01EX9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133388",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund - (Direct) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01IP6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "105639",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Regular) - Daily IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01668",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120432",
    "name": "JM Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "JM Dynamic Bond Fund (Direct) - Daily IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF192K01CZ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102007",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI-  Liquid Cash Plan- Discontinued -Regular Plan Periodic Dividend Option",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01AZ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102008",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI-  Liquid Cash Plan- Discontinued - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01BC2",
    "isinRe": "INF789F01BA6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102009",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI-  Liquid Cash Plan- Discontinued - Regular Plan -Growth",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01BB4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102010",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01PG3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102011",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01PI9",
    "isinRe": "INF789F01PJ7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102012",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI-  Liquid Cash Plan - Regular Plan - Growth Option",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01PH1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140913",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Fortnightly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1K06",
    "isinRe": "INF789FA1K14"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141768",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1K89",
    "isinRe": "INF789FA1K97"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144475",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1J41",
    "isinRe": "INF789FA1J58"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134427",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Annual IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1K63",
    "isinRe": "INF789FA1K71"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135721",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1J33",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139838",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Fortnightly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1J09",
    "isinRe": "INF789FA1J17"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140024",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1K22",
    "isinRe": "INF789FA1K30"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140504",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1K48",
    "isinRe": "INF789FA1K55"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140517",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1J82",
    "isinRe": "INF789FA1J90"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102013",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Weekly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01PK5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120304",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI-  Liquid Cash Plan - Direct Plan - Growth Option",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01XQ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120558",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01XP8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120777",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Weekly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01XT0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120794",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789F01XR4",
    "isinRe": "INF789F01XS2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133973",
    "name": "UTI - Liquid Cash Plan",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "UTI Liquid Cash Plan - Regular Plan - Annual IDCW",
    "minAmount": "Rs 10000 & in  multiple of Rs 1 in dividend option",
    "launchDate": "2003-06-23",
    "closeData": "",
    "isin": "INF789FA1J66",
    "isinRe": "INF789FA1J74"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101892",
    "name": "Kotak Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Kotak Money market Scheme - (Monthly Payout of Income Distribution cum capital withdrawal option)",
    "minAmount": "1000",
    "launchDate": "2007-01-01",
    "closeData": "",
    "isin": "INF174K018O0",
    "isinRe": "INF174K01NB4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "101893",
    "name": "Kotak Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Kotak Money Market Scheme - (Growth)",
    "minAmount": "1000",
    "launchDate": "2007-01-01",
    "closeData": "",
    "isin": "INF174K01NA6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119746",
    "name": "Kotak Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Kotak Money Market Scheme - (Growth) - Direct",
    "minAmount": "1000",
    "launchDate": "2007-01-01",
    "closeData": "",
    "isin": "INF174K01MW2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119747",
    "name": "Kotak Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Kotak Money Market Scheme - (Monthly Payout of Income Distribution cum capital withdrawal option) - Direct",
    "minAmount": "1000",
    "launchDate": "2007-01-01",
    "closeData": "",
    "isin": "INF174K019O8",
    "isinRe": "INF174K01MX0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "102020",
    "name": "Baroda BNP Paribas Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Baroda BNP Paribas MULTI CAP FUND - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-08-14",
    "closeData": "",
    "isin": "INF955L01674",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "102021",
    "name": "Baroda BNP Paribas Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Baroda BNP Paribas MULTI CAP FUND - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-08-14",
    "closeData": "",
    "isin": "INF955L01658",
    "isinRe": "INF955L01666"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119353",
    "name": "Baroda BNP Paribas Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Baroda BNP Paribas MULTI CAP FUND - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-08-14",
    "closeData": "",
    "isin": "INF955L01AE5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119354",
    "name": "Baroda BNP Paribas Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Baroda BNP Paribas MULTI CAP FUND - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-08-14",
    "closeData": "",
    "isin": "INF955L01AG0",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "101922",
    "name": "Canara Robeco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "CANARA ROBECO FLEXICAP FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/-",
    "launchDate": "2003-08-29",
    "closeData": "2003-09-12",
    "isin": "INF760K01019",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "101923",
    "name": "Canara Robeco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "CANARA ROBECO FLEXICAP FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2003-08-29",
    "closeData": "2003-09-12",
    "isin": "INF760K01027",
    "isinRe": "INF760K01035"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118275",
    "name": "Canara Robeco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "CANARA ROBECO FLEXICAP FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/-",
    "launchDate": "2003-08-29",
    "closeData": "2003-09-12",
    "isin": "INF760K01EF0",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118276",
    "name": "Canara Robeco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "CANARA ROBECO FLEXICAP FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2003-08-29",
    "closeData": "2003-09-12",
    "isin": "INF760K01ED5",
    "isinRe": "INF760K01EE3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101656",
    "name": "Franklin India Dynamic Asset Allocation Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Dynamic Asset Allocation Fund of Funds-Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2003-10-31",
    "closeData": "",
    "isin": "INF090I01262",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101657",
    "name": "Franklin India Dynamic Asset Allocation Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Dynamic Asset Allocation Fund of funds - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2003-10-31",
    "closeData": "",
    "isin": "INF090I01247",
    "isinRe": "INF090I01254"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "101658",
    "name": "Franklin India Dynamic Asset Allocation Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "FT India Dynamic PE Ratio Fund of Funds-FT INDIA LIFE STAGE 20 (D)",
    "minAmount": "Rs.5000",
    "launchDate": "2003-10-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118542",
    "name": "Franklin India Dynamic Asset Allocation Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Dynamic Asset Allocation Fund of funds - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2003-10-31",
    "closeData": "",
    "isin": "INF090I01HT0",
    "isinRe": "INF090I01HU8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118543",
    "name": "Franklin India Dynamic Asset Allocation Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Dynamic Asset Allocation Fund Of Funds - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2003-10-31",
    "closeData": "",
    "isin": "INF090I01HV6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115839",
    "name": "Tata Ethical Fund Regular Plan - Dividend",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Ethical Fund - Dividend Deleted",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2003-11-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102055",
    "name": "Z-BEFORE MERGER-SBI Magnum Income Plus Fund - Savings Plan (G)",
    "category": "Income",
    "navName": "SBI Magnum Income Plus Fund - Savings Plan (G)",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01743",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102056",
    "name": "Z-BEFORE MERGER-SBI Magnum Income Plus Fund - Savings Plan (D)",
    "category": "Income",
    "navName": "SBI Magnum Income Plus Fund - Savings Plan (D)",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01750",
    "isinRe": "INF200K01768"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101928",
    "name": "Z-OLD-SBI Magnum Institutional Income Fund - Investment - Growth",
    "category": "Liquid",
    "navName": "SBI Magnum Institutional Income Fund - Investment - Growth",
    "minAmount": "5000000",
    "launchDate": "2003-11-17",
    "closeData": "2003-11-17",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102057",
    "name": "Z-OLD-SBI Magnum Institutional Income Fund - Investment - Dividend",
    "category": "Income",
    "navName": "SBI Magnum Institutional Income Fund - Investment - Dividend",
    "minAmount": "5000000",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101929",
    "name": "Z-OLD-SBI Magnum Institutional Income Fund - Savings - Growth",
    "category": "Liquid",
    "navName": "SBI Magnum Institutional Income Fund - Savings - Growth(Upto 22/03/07) Renamed as SBI Premier Liquid Fund",
    "minAmount": "5000000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102058",
    "name": "Z-OLD-SBI Magnum Institutional Income Fund - Savings - Dividend(Upto 22/03/07)",
    "category": "Liquid",
    "navName": "SBI Magnum Institutional Income Fund - Savings - Dividend(Upto 22/03/07) Renamed as SBI Premier Liquid Fund",
    "minAmount": "5000000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102060",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Investment Plan PF Option - Growth",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109K01JS2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118019",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Investment Plan PF Option - Half Yearly Dividend",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109K01I15",
    "isinRe": "INF109K01I07"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120605",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Investment Plan PF Option - Direct Plan -Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109K011D8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120606",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Investment Plan PF Option - Direct Plan - Half Yearly Dividend",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109K019C3",
    "isinRe": "INF109K010D0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130901",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Investment PF Option - Bonus",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109KA1C31",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130954",
    "name": "ICICI Prudential Gilt Fund Investment Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Investment PF Option - Direct Plan Bonus",
    "minAmount": "5000",
    "launchDate": "2003-11-19",
    "closeData": "",
    "isin": "INF109KA1C23",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102133",
    "name": "ICICI Prudential Passive Strategy Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Strategy Fund (FOF) - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01795",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120242",
    "name": "ICICI Prudential Passive Strategy Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Strategy Fund (FOF) - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01W58",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120243",
    "name": "ICICI Prudential Passive Strategy Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Strategy Fund (FOF) - Direct Plan -  IDCW",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01W33",
    "isinRe": "INF109K01W41"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102134",
    "name": "ICICI Prudential Passive Strategy Fund (FOF )-Dividend Option",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Strategy Fund (FOF) - IDCW",
    "minAmount": "$",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01DW7",
    "isinRe": "INF109K01803"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102135",
    "name": "ICICI Prudential Thematic Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Thematic Advantage Fund (FOF) - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01852",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120700",
    "name": "ICICI Prudential Thematic Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Thematic Advantage Fund (FOF) - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01X40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120701",
    "name": "ICICI Prudential Thematic Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Very Aggressive - Direct Plan -  Dividend",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01X24",
    "isinRe": "INF109K01X32"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102136",
    "name": "ICICI Prudential Thematic Advantage Fund (FOF) - Dividend Option",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Thematic Advantage Fund (FOF) - IDCW",
    "minAmount": "#$",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01FR2",
    "isinRe": "INF109K01860"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102137",
    "name": "ICICI Prudential Asset Allocator Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Asset Allocator Fund (FOF) - Growth",
    "minAmount": "#@",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01837",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120679",
    "name": "ICICI Prudential Asset Allocator Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Asset Allocator Fund (FOF) - Direct Plan - Growth",
    "minAmount": "#@",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01X16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120680",
    "name": "ICICI Prudential Asset Allocator Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Asset Allocator Fund (FOF) - Direct Plan - IDCW",
    "minAmount": "#@",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01W90",
    "isinRe": "INF109K01X08"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102138",
    "name": "ICICI Prudential Asset Allocator Fund (FOF) - Dividend",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Asset Allocator Fund (FOF) - IDCW",
    "minAmount": "#@",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01FJ9",
    "isinRe": "INF109K01845"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102139",
    "name": "ICICI Prudential Income Optimizer Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Income Optimizer Fund (FOF) - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01811",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120313",
    "name": "ICICI Prudential Income Optimizer Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Income Optimizer Fund (FOF) - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01W82",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120314",
    "name": "ICICI Prudential Income Optimizer Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Income Optimizer Fund (FOF) - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01W66",
    "isinRe": "INF109K01W74"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102140",
    "name": "ICICI Prudential Income Optimizer Fund (FOF) - Dividend Option",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Income Optimizer Fund (FOF) - IDCW",
    "minAmount": "#@",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01EB9",
    "isinRe": "INF109K01829"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102141",
    "name": "ICICI Prudential Debt Management Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Debt Management Fund (FOF) - Growth",
    "minAmount": "s",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01878",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120702",
    "name": "ICICI Prudential Debt Management Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Debt Management Fund (FOF) - Direct Plan - Growth",
    "minAmount": "s",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01X73",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120703",
    "name": "ICICI Prudential Debt Management Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Debt Management Fund (FOF) - Direct Plan -  IDCW",
    "minAmount": "s",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01X57",
    "isinRe": "INF109K01X65"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102143",
    "name": "ICICI Prudential Debt Management Fund (FOF) - Dividend option",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Debt Management Fund (FOF ) - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-12-19",
    "closeData": "",
    "isin": "INF109K01FS0",
    "isinRe": "INF109K01886"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102147",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01AE4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102148",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - IDCW Monthly",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01AA2",
    "isinRe": "INF179K01AB0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102149",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - IDCW Quarterly",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01AC8",
    "isinRe": "INF179K01AD6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119118",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01XE6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119119",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - IDCW Monthly - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01XF3",
    "isinRe": "INF179K01XG1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119120",
    "name": "HDFC Hybrid Debt Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HDFC Hybrid Debt Fund - IDCW Quarterly - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2003-11-17",
    "closeData": "",
    "isin": "INF179K01XH9",
    "isinRe": "INF179K01XI7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102172",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01FD1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102173",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND - MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01FE9",
    "isinRe": "INF204K01FF6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102174",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND - QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01FG4",
    "isinRe": "INF204K01FH2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118726",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01YV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118727",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01YW2",
    "isinRe": "INF204K01YX0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148291",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB15O7",
    "isinRe": "INF204KB16O5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148292",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB17O3",
    "isinRe": "INF204KB18O1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148293",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 2 - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB19O9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148294",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND -  SEGREGATED PORTFOLIO 2 - MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB10P5",
    "isinRe": "INF204KB11P3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148295",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB12P1",
    "isinRe": "INF204KB13P9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148296",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 2 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB14O0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148139",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Direct Plan Monthly Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB14K8",
    "isinRe": "INF204KB13K0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148140",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Direct Plan Quarterly Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB15K5",
    "isinRe": "INF204KB16K3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148141",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB17K1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148142",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Monthly Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB18K9",
    "isinRe": "INF204KB19K7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148143",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB12K2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148144",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Nippon India Hybrid Bond Fund - Segregated Portfolio 1 - Quarterly Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204KB10L4",
    "isinRe": "INF204KB11L2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118729",
    "name": "Nippon India Hybrid Bond Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "NIPPON INDIA HYBRID BOND FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2003-12-10",
    "closeData": "",
    "isin": "INF204K01YY8",
    "isinRe": "INF204K01YZ5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102207",
    "name": "Z-BEFORE MERGER-SBI MAGNUM NRI FLEXIASSET PLAN-GROWTH",
    "category": "Growth",
    "navName": "SBI MAGNUM NRI FLEXIASSET PLAN-GROWTH",
    "minAmount": "50000",
    "launchDate": "2003-12-15",
    "closeData": "2003-12-15",
    "isin": "INF200K01503",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102208",
    "name": "Z-BEFORE MERGER-SBI MAGNUM NRI FLEXIASSET PLAN-DIVIDEND",
    "category": "Growth",
    "navName": "SBI MAGNUM NRI FLEXIASSET PLAN-DIVIDEND",
    "minAmount": "50000",
    "launchDate": "2003-12-15",
    "closeData": "2003-12-15",
    "isin": "INF200K01511",
    "isinRe": "INF200K01529"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102209",
    "name": "Reliance Fixed Term Scheme-V",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-V-Growth Plan-Growth Option",
    "minAmount": "",
    "launchDate": "2004-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102210",
    "name": "Reliance Fixed Term Scheme-V",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-V-Dividend Plan-Dividend",
    "minAmount": "",
    "launchDate": "2004-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102248",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Growth",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K01KA8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118022",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K01I49",
    "isinRe": "INF109K01I23"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118023",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Half Yearly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K01I56",
    "isinRe": "INF109K01I31"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120610",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Direct Plan - Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K019D1",
    "isinRe": "INF109K010E8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120611",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Direct Plan -Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K011E6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120612",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Fund Treasury Plan PF Option - Direct Plan - Half Yearly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109K017D5",
    "isinRe": "INF109K018D3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130905",
    "name": "ICICI Prudential Gilt Fund Treasury Plan PF Option",
    "category": "Gilt",
    "navName": "ICICI Prudential Gilt Treasury PF Option - Bonus",
    "minAmount": "5000",
    "launchDate": "2004-02-20",
    "closeData": "",
    "isin": "INF109KA1C56",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102251",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC Flexi Cap Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01AE8",
    "isinRe": "INF336L01AD0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102252",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC Flexi Cap Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01AF5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102253",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC India Opportunities Fund--",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102254",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC India Opportunities Fund--",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102255",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC India Opportunities Fund--",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102256",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC India Opportunities Fund--",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120045",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC Flexi Cap Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01DF9",
    "isinRe": "INF336L01DG7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120046",
    "name": "HSBC Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "HSBC Flexi Cap Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01DH5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102260",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01107",
    "isinRe": "INF336L01115"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102261",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Regular Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01123",
    "isinRe": "INF336L01131"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "102262",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01099",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120073",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01DW4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120074",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01DX2",
    "isinRe": "INF336L01DY0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120075",
    "name": "HSBC Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "HSBC Conservative Hybrid Fund - Direct Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-01-22",
    "closeData": "",
    "isin": "INF336L01DZ7",
    "isinRe": "INF336L01EA8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102266",
    "name": "UTI - CCF Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Investment Plan - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-01-30",
    "closeData": "2008-01-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102267",
    "name": "UTI - CCF Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Investment Plan - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-01-30",
    "closeData": "2008-01-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120724",
    "name": "UTI - CCF Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Investment Plan - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-01-30",
    "closeData": "2008-01-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120725",
    "name": "UTI - CCF Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "UTI CCF Investment Plan - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-01-30",
    "closeData": "2008-01-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102276",
    "name": "Reliance Fixed Term Scheme",
    "category": "Gilt",
    "navName": "Reliance Fixed Term Scheme-Monthly Plan-Series-VI-Growth",
    "minAmount": "",
    "launchDate": "2004-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102277",
    "name": "Reliance Fixed Term Scheme",
    "category": "Gilt",
    "navName": "Reliance Fixed Term Scheme-Monthly Plan-Series-VI-Dividend",
    "minAmount": "",
    "launchDate": "2004-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102278",
    "name": "Reliance Fixed Term Scheme",
    "category": "Gilt",
    "navName": "Reliance Fixed Term Scheme-Annual Plan-Series-III-Growth",
    "minAmount": "",
    "launchDate": "2004-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102279",
    "name": "Reliance Fixed Term Scheme",
    "category": "Gilt",
    "navName": "Reliance Fixed Term Scheme-Annual Plan-Series-III-Dividend",
    "minAmount": "",
    "launchDate": "2004-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102330",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01902",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102331",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01ER5",
    "isinRe": "INF109K01894"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113097",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01IX4",
    "isinRe": "INF109K01WY3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113098",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01IW6",
    "isinRe": "INF109K01WX5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120613",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01S47",
    "isinRe": "INF109K01S54"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120614",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01S88",
    "isinRe": "INF109K01S96"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123183",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Direct Plan - Bonus",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109KA1301",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128110",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Plan - Bonus",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109KA1319",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120615",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01S62",
    "isinRe": "INF109K01S70"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120616",
    "name": "ICICI Prudential Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ICICI Prudential Regular Savings Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2004-03-30",
    "closeData": "",
    "isin": "INF109K01S39",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102393",
    "name": "UTI - Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "UTI Mid Cap Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01794",
    "isinRe": "INF789F01802"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102394",
    "name": "UTI - Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "UTI Mid Cap Fund-Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01810",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120726",
    "name": "UTI - Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "UTI Mid Cap Fund-Growth Option- Direct",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01UA6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120727",
    "name": "UTI - Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "UTI Mid Cap Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01TY8",
    "isinRe": "INF789F01TZ5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102395",
    "name": "UTI - Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Infrastructure Fund-Growth Option",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01752",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102396",
    "name": "UTI - Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Infrastructure Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01737",
    "isinRe": "INF789F01745"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120728",
    "name": "UTI - Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Infrastructure Fund-Growth Option- Direct",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01TU6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120729",
    "name": "UTI - Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Infrastructure Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01TS0",
    "isinRe": "INF789F01TT8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102397",
    "name": "UTI - Transportation and Logistics Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Transportation and Logistics Fund - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-04-11",
    "closeData": "2008-04-11",
    "isin": "INF789F01273",
    "isinRe": "INF789F01281"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102398",
    "name": "UTI - Transportation and Logistics Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI-Transpotation and Logistics  Fund-Growth Option",
    "minAmount": "10000",
    "launchDate": "2008-04-11",
    "closeData": "2008-04-11",
    "isin": "INF789F01299",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120730",
    "name": "UTI - Transportation and Logistics Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Transportation and Logistics Fund - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-04-11",
    "closeData": "2008-04-11",
    "isin": "INF789F01SF9",
    "isinRe": "INF789F01SG7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120731",
    "name": "UTI - Transportation and Logistics Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI-Transpotation and Logistics  Fund-Growth Option- Direct",
    "minAmount": "10000",
    "launchDate": "2008-04-11",
    "closeData": "2008-04-11",
    "isin": "INF789F01SH5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102401",
    "name": "UTI - Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Banking and Financial Services Fund - Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01372",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102402",
    "name": "UTI - Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Banking and Financial Services Fund - Regular Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01356",
    "isinRe": "INF789F01364"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120732",
    "name": "UTI - Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Banking and Financial Services Fund - Direct Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01SL7",
    "isinRe": "INF789F01SM5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120733",
    "name": "UTI - Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI Banking and Financial Services Fund - Direct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2003-01-02",
    "closeData": "",
    "isin": "INF789F01SN3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102407",
    "name": "Reliance Fixed Term Scheme-Series-VII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-VII-Monthly Plan-Growth",
    "minAmount": "",
    "launchDate": "2004-04-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102408",
    "name": "Reliance Fixed Term Scheme-Series-VII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-VII-Monthly Plan-Dividend",
    "minAmount": "",
    "launchDate": "2004-04-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101262",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Power & Infra Fund-Growth Plan -Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01AE0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101263",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Power & Infra Fund-Growth Plan-Bonus Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01AD2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101264",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA POWER & INFRA FUND - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01AB6",
    "isinRe": "INF204K01AC4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118760",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA POWER & INFRA FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01I76",
    "isinRe": "INF204K01I84"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118762",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Power & Infra Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01I68",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118763",
    "name": "Nippon India Power & Infra Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Power & Infra Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-03-29",
    "closeData": "",
    "isin": "INF204K01I92",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101816",
    "name": "Aditya Birla Sun Life Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Aditya Birla Sun Life Regular Savings Fund - REGULAR - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2004-04-12",
    "closeData": "2004-04-12",
    "isin": "INF209K01769",
    "isinRe": "INF209K01DF0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "101818",
    "name": "Aditya Birla Sun Life Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Aditya Birla Sun Life Regular Savings Fund - Growth / Payment - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2004-04-12",
    "closeData": "2004-04-12",
    "isin": "INF209K01751",
    "isinRe": "INF209K01777"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120550",
    "name": "Aditya Birla Sun Life Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Aditya Birla Sun Life Regular Savings Fund - DIRECT - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2004-04-12",
    "closeData": "2004-04-12",
    "isin": "INF209K01R47",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120705",
    "name": "Aditya Birla Sun Life Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Aditya Birla Sun Life Regular Savings Fund - Growth / Payment - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2004-04-12",
    "closeData": "2004-04-12",
    "isin": "INF209K01XH4",
    "isinRe": "INF209K01XJ0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102412",
    "name": "Reliance Fixed Term Scheme-Series-VIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-VIII-Monthly Plan-Growth",
    "minAmount": "",
    "launchDate": "2004-05-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102413",
    "name": "Reliance Fixed Term Scheme-Series-VIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-VIII-Monthly Plan-Dividend",
    "minAmount": "",
    "launchDate": "2004-05-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102429",
    "name": "Reliance Short Term Fund-Institutional",
    "category": "Growth",
    "navName": "Reliance Short Term Fund-Institutional-Institutional Plan-Growth",
    "minAmount": "",
    "launchDate": "",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102430",
    "name": "Reliance Short Term Fund-Institutional",
    "category": "Growth",
    "navName": "Reliance Short Term Fund-Institutional-Institutional Plan-Dividend",
    "minAmount": "",
    "launchDate": "",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102431",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Pharma Fund-Growth Plan-Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01968",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102432",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Pharma Fund-Growth Plan-Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01950",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102433",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA PHARMA FUND - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01935",
    "isinRe": "INF204K01943"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118756",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA PHARMA FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01I35",
    "isinRe": "INF204K01I43"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118758",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Pharma Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01I27",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118759",
    "name": "Nippon India Pharma Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Pharma Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-05-10",
    "closeData": "",
    "isin": "INF204K01I50",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102434",
    "name": "DSP India T.I.G.E.R. Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP India T.I.G.E.R. Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01151",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102435",
    "name": "DSP India T.I.G.E.R. Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP India T.I.G.E.R. Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01136",
    "isinRe": "INF740K01144"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119247",
    "name": "DSP India T.I.G.E.R. Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP India T.I.G.E.R. Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01PU7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119248",
    "name": "DSP India T.I.G.E.R. Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP India T.I.G.E.R. Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01PV5",
    "isinRe": "INF740K01PW3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102444",
    "name": "DSP Savings Manager Fund - Conservative",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Merrill Lynch Savings Plus Fund-Conservative-Growth",
    "minAmount": "5000.00",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102448",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund- Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01441",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102449",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Merrill Lynch Savings Plus Fund - Aggressive-Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102450",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01458",
    "isinRe": "INF740K01466"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "102451",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund - Regular Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01474",
    "isinRe": "INF740K01482"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118992",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01NP2",
    "isinRe": "INF740K01NR8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118993",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund - Direct Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01NQ0",
    "isinRe": "INF740K01NS6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "118994",
    "name": "DSP Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "DSP Regular Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2004-04-27",
    "closeData": "2004-05-25",
    "isin": "INF740K01NO5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102436",
    "name": "Reliance Fixed Term Scheme-Series-IX",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-IX-Growth Plan-Growth",
    "minAmount": "100000/-",
    "launchDate": "2004-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102437",
    "name": "Reliance Fixed Term Scheme-Series-IX",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-IX-Dividend Plan-Dividend",
    "minAmount": "100000/-",
    "launchDate": "2004-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102452",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Growth",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01442",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102453",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Weekly IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01475",
    "isinRe": "INF179K01483"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105543",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Monthly IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01459",
    "isinRe": "INF179K01467"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105544",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration Fund- Wholesale- Growth",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01491",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105545",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration Fund- Wholesale Weekly Dividend",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01525",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118942",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Direct Plan - Growth",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01VF7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118943",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01VG5",
    "isinRe": "INF179K01VH3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118944",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118945",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Direct Plan - Daily IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105546",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration Fund- Wholesale Monthly Dividend",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01509",
    "isinRe": "INF179K01517"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105547",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration Fund- Wholesale Daily Dividend",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01541",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105548",
    "name": "HDFC Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HDFC Low Duration  Fund - Daily IDCW",
    "minAmount": "Retail Plan 5000",
    "launchDate": "2007-04-24",
    "closeData": "",
    "isin": "INF179K01434",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102441",
    "name": "Franklin India Liquid Fund- Institution",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Institution-Growth",
    "minAmount": "x",
    "launchDate": "2004-06-22",
    "closeData": "",
    "isin": "INF090I01BD7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102442",
    "name": "Franklin India Liquid Fund- Institution",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Institutional Plan - Weekly - IDCW",
    "minAmount": "x",
    "launchDate": "2004-06-22",
    "closeData": "",
    "isin": "INF090I01BJ4",
    "isinRe": "INF090I01BE5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102443",
    "name": "Franklin India Liquid Fund- Institution",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Franklin India Liquid Fund - Institutional Plan - Daily - IDCW",
    "minAmount": "x",
    "launchDate": "2004-06-22",
    "closeData": "",
    "isin": "INF090I01BC9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102486",
    "name": "UTI - Liquid Plus FUND",
    "category": "Liquid",
    "navName": "UTI - BOND ADVANTAGE FUND-ANNUAL DIVIDEND",
    "minAmount": "5000/-",
    "launchDate": "1999-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102487",
    "name": "UTI - Liquid Plus FUND",
    "category": "Liquid",
    "navName": "UTI - BOND ADVANTAGE FUND-BONUS",
    "minAmount": "5000/-",
    "launchDate": "1999-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102488",
    "name": "UTI - Liquid Plus FUND",
    "category": "Liquid",
    "navName": "UTI - BOND ADVANTAGE FUND-GROWTH",
    "minAmount": "5000/-",
    "launchDate": "1999-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102489",
    "name": "UTI - Liquid Plus FUND",
    "category": "Liquid",
    "navName": "UTI - BOND ADVANTAGE FUND-INSTITUTIONAL",
    "minAmount": "5000/-",
    "launchDate": "1999-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102490",
    "name": "UTI - Liquid Plus FUND",
    "category": "Liquid",
    "navName": "UTI - BOND ADVANTAGE FUND-QUARTERLY DIVIDEND",
    "minAmount": "5000/-",
    "launchDate": "1999-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102507",
    "name": "Z-OLD-SBI Magnum Income Fund - F R P - Long Term - Inst. (G)",
    "category": "Floating Rate",
    "navName": "OLD-SBI Magnum Income Fund - F R P - Long Term - Inst. (G)",
    "minAmount": "5000000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102509",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI Gilt Fund - Regular Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01646",
    "isinRe": "INF789F01653"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102510",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI - GILT FUND - Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01661",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102511",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI - GILT FUND -Discontinued PF Plan -Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01PR0",
    "isinRe": "INF789F01PS8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102512",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI - GILT FUND - Discontinued PF Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01PT6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102514",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI - GILT FUND - Discontinued PF Plan Prescribed Date Auto Redemption Option",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01PU4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120791",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI Gilt Fund - Direct Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01TJ9",
    "isinRe": "INF789F01TK7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120792",
    "name": "UTI - Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "UTI - GILT FUND - Direct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2001-01-14",
    "closeData": "",
    "isin": "INF789F01TL5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102531",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Weekly IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01562",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102532",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI - Ultra Short Term Fund - Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01570",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109501",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01554",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "112082",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI - Ultra Short Term Fund - Discontinued - INSTN PLAN - PERIODIC DIVIDEND OPTION",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01PL3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "112083",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI - Ultra Short Term Fund - Discontinued - INSTN GROWTH OPTION",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01PM1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139568",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Annual IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1Q42",
    "isinRe": "INF789FA1Q59"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147128",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1Q26",
    "isinRe": "INF789FA1Q34"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131511",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Fortnightly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1O69",
    "isinRe": "INF789FA1O77"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131512",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1P68",
    "isinRe": "INF789FA1P76"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133059",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1Q00",
    "isinRe": "INF789FA1Q18"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133873",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1P01",
    "isinRe": "INF789FA1P19"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134484",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Annual IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1P43",
    "isinRe": "INF789FA1P50"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134799",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1P27",
    "isinRe": "INF789FA1P35"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131444",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1P84",
    "isinRe": "INF789FA1P92"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131445",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FA1O85",
    "isinRe": "INF789FA1O93"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121891",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Regular Plan - Flexi IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FB1KS6",
    "isinRe": "INF789FB1KT4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121892",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Flexi IDCW",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FB1KQ0",
    "isinRe": "INF789FB1KR8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120744",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01TG5",
    "isinRe": "INF789F01TH3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120745",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI Ultra Short Term Fund - Direct Plan - Weekly IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789FB1S63",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120746",
    "name": "UTI - Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "UTI - Ultra Short Term Fund - Direct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2003-08-29",
    "closeData": "",
    "isin": "INF789F01TI1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102533",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Savings Fund - Regular Plan - Monthly Payment Option",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01927",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102534",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Savings Fund - Regular Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01877",
    "isinRe": "INF789F01885"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102535",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Savings Fund - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01893",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102536",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Savings Fund - Regular Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01901",
    "isinRe": "INF789F01919"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120778",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Savings Fund - Direct Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01UK5",
    "isinRe": "INF789F01UL3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120779",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Savings Fund - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01UJ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120789",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Savings Fund - Direct Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01UH1",
    "isinRe": "INF789F01UI9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120790",
    "name": "UTI - Regular Savings Fund.",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Savings Fund - Direct Plan - Monthly Payment Option",
    "minAmount": "10000",
    "launchDate": "2003-12-01",
    "closeData": "",
    "isin": "INF789F01UM1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102538",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued - Annaul Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OK8",
    "isinRe": "INF789F01OL6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102539",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102540",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued Growth Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01331",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102541",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued - Quarterly Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OO0",
    "isinRe": "INF789F01OP7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102542",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - BOND ADVANTAGE-INSTITUTIONAL-ANNUAL DIVIDEND",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133733",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Fortnightly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1N45",
    "isinRe": "INF789FA1N52"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135191",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1O02",
    "isinRe": "INF789FA1O10"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136345",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1N60",
    "isinRe": "INF789FA1N78"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141958",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Annual IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XF9",
    "isinRe": "INF789F01XG7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120737",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XE2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120738",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Direct Plan - Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XH5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120739",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XN3",
    "isinRe": "INF789F01XO1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133127",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Flexi IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1O44",
    "isinRe": "INF789FA1O51"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133130",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Flexi IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1N86",
    "isinRe": "INF789FA1N94"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133469",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789FA1O28",
    "isinRe": "INF789FA1O36"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105659",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued Flexi Dividend Plan",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01PA6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105670",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued Monthly Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OY9",
    "isinRe": "INF789F01OZ6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "115483",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OW3",
    "isinRe": "INF789F01OX1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120734",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XL7",
    "isinRe": "INF789F01XM5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120735",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XI3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120736",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01XJ1",
    "isinRe": "INF789F01XK9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102544",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OT9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "102545",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Regular Plan - Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OS1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105578",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI - Treasury Advantage Fund - Discontinued - Periodic Dividend Plan",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01349",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105605",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01ON2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105627",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01PB4",
    "isinRe": "INF789F01PC2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105658",
    "name": "UTI - Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "UTI Treasury Advantage Fund - Regular Plan - Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "1999-05-05",
    "closeData": "",
    "isin": "INF789F01OU7",
    "isinRe": "INF789F01OV5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102548",
    "name": "Reliance Fixed Term Scheme-Series-X",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-X-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-07-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102549",
    "name": "Reliance Fixed Term Scheme-Series-X",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-X-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-07-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102550",
    "name": "Reliance Fixed Term Scheme-Series-V",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-V-Quarterly Plan-Growth Option",
    "minAmount": "100000/-",
    "launchDate": "2004-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102551",
    "name": "Reliance Fixed Term Scheme-Series-V",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-V-Quarterly Plan-Dividend Option",
    "minAmount": "100000/-",
    "launchDate": "2004-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102552",
    "name": "Reliance Fixed Term Scheme-Series-IV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-IV-Annual Plan-Growth Option",
    "minAmount": "100000/-",
    "launchDate": "2004-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102553",
    "name": "Reliance Fixed Term Scheme-Series-IV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-IV-Annual Plan-Dividend Option",
    "minAmount": "100000/-",
    "launchDate": "2004-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102573",
    "name": "Kotak Multi Asset Allocator Fund of Fund - Dynamic",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Multi Asset Allocator Fund of Fund - Dynamic - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-10",
    "closeData": "",
    "isin": "INF174K01484",
    "isinRe": "INF174K01476"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102574",
    "name": "Kotak Multi Asset Allocator Fund of Fund - Dynamic",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Multi Asset Allocator Fund of Fund - Dynamic - Growth",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-10",
    "closeData": "",
    "isin": "INF174K01468",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119776",
    "name": "Kotak Multi Asset Allocator Fund of Fund - Dynamic",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Multi Asset Allocator Fund of Fund - Dynamic - Direct Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-10",
    "closeData": "",
    "isin": "INF174K01LO1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119777",
    "name": "Kotak Multi Asset Allocator Fund of Fund - Dynamic",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Multi Asset Allocator Fund of Fund - Dynamic - Direct Growth - Direct",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-10",
    "closeData": "",
    "isin": "INF174K01LN3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102591",
    "name": "Kotak Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Savings Fund -Growth",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01FD6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102592",
    "name": "Kotak Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Savings Fund -Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01FH7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102593",
    "name": "Kotak Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Floater Long-Term-Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119750",
    "name": "Kotak Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Savings Fund-Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01JP2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119751",
    "name": "Kotak Savings Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Savings Fund-Monthly Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01JQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102594",
    "name": "ICICI Prudential Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ICICI Prudential Value Discovery Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-07-09",
    "closeData": "",
    "isin": "INF109K01AF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102595",
    "name": "ICICI Prudential Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ICICI Prudential Value Discovery Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-07-09",
    "closeData": "",
    "isin": "INF109K01EC7",
    "isinRe": "INF109K01AD3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120322",
    "name": "ICICI Prudential Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ICICI Prudential Value Discovery Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-07-09",
    "closeData": "",
    "isin": "INF109K011K3",
    "isinRe": "INF109K010K5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120323",
    "name": "ICICI Prudential Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ICICI Prudential Value Discovery Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-07-09",
    "closeData": "",
    "isin": "INF109K012K1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102657",
    "name": "Reliance Fixed Term Scheme-Monthly Plan Series-XI",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Monthly Plan Series-XI-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102658",
    "name": "Reliance Fixed Term Scheme-Monthly Plan Series-XI",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Monthly Plan Series-XI-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "102671",
    "name": "BARODA PIONEER CHILDREN FUND",
    "category": "Income",
    "navName": "BARODA PIONEER CHILDREN FUND-STUDY PLAN",
    "minAmount": "5000",
    "launchDate": "2004-07-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102673",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Nippon India Floating Rate Fund - Growth Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01CG1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102674",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Reliance Floating Rate Fund - Growth-Bonus Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102675",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - MONTHLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01CH9",
    "isinRe": "INF204K01CK3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102676",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DAILY IDCW REINVESTMENT Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01CI7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102677",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - WEEKLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01OQ5",
    "isinRe": "INF204K01CJ5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112942",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01HK2",
    "isinRe": "INF204K01HL0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124590",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204KA1DU8",
    "isinRe": "INF204KA1DV6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118654",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01D71",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118655",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01D89",
    "isinRe": "INF204K01D97"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118656",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Nippon India Floating Rate Fund  - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01E05",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118660",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01D14",
    "isinRe": "INF204K01E13"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118661",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204K01C98",
    "isinRe": "INF204K01D06"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124589",
    "name": "Nippon India Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "NIPPON INDIA FLOATING RATE FUND - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2004-08-23",
    "closeData": "",
    "isin": "INF204KA1DS2",
    "isinRe": "INF204KA1DT0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102741",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01AH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102743",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund-Plan B-Growth",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01AJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113080",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund Plan A - Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01UD1",
    "isinRe": "INF109K01IP0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113136",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund Plan C - Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01UO8",
    "isinRe": "INF109K01UN0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113137",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01UF6",
    "isinRe": "INF109K01IO3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113138",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund Plan B - Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01UI0",
    "isinRe": "INF109K01IQ8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130936",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Direct Plan Bonus",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109KA1B81",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132981",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109KA12G3",
    "isinRe": "INF109KA13G1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132982",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund -Direct Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109KA10G7",
    "isinRe": "INF109KA11G5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117330",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01B53",
    "isinRe": "INF109K01B61"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117706",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund Plan B - Half Yearly Dividend",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K01B87",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120670",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K015A5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120671",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K018A9",
    "isinRe": "INF109K019A7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120672",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109K016A3",
    "isinRe": "INF109K017A1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130935",
    "name": "ICICI Prudential Medium Term Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "ICICI Prudential Medium Term Bond Fund - Bonus",
    "minAmount": "5000",
    "launchDate": "2004-08-16",
    "closeData": "",
    "isin": "INF109KA1B99",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102749",
    "name": "Reliance Fixed Term Scheme-Series-XII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XII-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102750",
    "name": "Reliance Fixed Term Scheme-Series-XII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XII-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102751",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Consumption Fund-Growth Plan-Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01AQ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102752",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Consumption Fund-Growth Plan-Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01AP6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102753",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA CONSUMPTION FUND - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01AN1",
    "isinRe": "INF204K01AO9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118722",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Consumption Fund - Direct Plan Growth Plan - Bonus",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01G29",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118724",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Consumption Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01G52",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118725",
    "name": "Nippon India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA CONSUMPTION FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-09-16",
    "closeData": "",
    "isin": "INF204K01G37",
    "isinRe": "INF204K01G45"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101585",
    "name": "HDFC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HDFC Equity Savings Fund - GROWTH PLAN",
    "minAmount": "REFER SID",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01AM7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "101586",
    "name": "HDFC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HDFC Equity Savings Fund - IDCW PLAN",
    "minAmount": "REFER SID",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01AK1",
    "isinRe": "INF179K01AL9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119128",
    "name": "HDFC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HDFC Equity Savings Fund - Growth Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01XT4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119129",
    "name": "HDFC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HDFC Equity Savings Fund - IDCW Option - Direct Plan",
    "minAmount": "REFER SID",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01XR8",
    "isinRe": "INF179K01XS6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102760",
    "name": "HDFC Focused 30 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HDFC Focused 30 Fund - GROWTH PLAN",
    "minAmount": "For new Rs.5000 For existing Rs. 1000",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01574",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102761",
    "name": "HDFC Focused 30 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HDFC Focused 30 Fund - IDCW PLAN",
    "minAmount": "For new Rs.5000 For existing Rs. 1000",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01558",
    "isinRe": "INF179K01566"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118949",
    "name": "HDFC Focused 30 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HDFC Focused 30 Fund - IDCW Option - Direct Plan",
    "minAmount": "For new Rs.5000 For existing Rs. 1000",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01VI1",
    "isinRe": "INF179K01VJ9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118950",
    "name": "HDFC Focused 30 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HDFC Focused 30 Fund - Growth Option - Direct Plan",
    "minAmount": "For new Rs.5000 For existing Rs. 1000",
    "launchDate": "2004-08-20",
    "closeData": "",
    "isin": "INF179K01VK7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "102764",
    "name": "JM Fixed Maturity Plan. - QSA4",
    "category": "Income",
    "navName": "JM Fixed Maturity Plan. - QSA4-Growth",
    "minAmount": "",
    "launchDate": "",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119725",
    "name": "SBI FOCUSED EQUITY FUND - GROWTH",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI MSFU EMERGING BUSINESSES FUND - DIRECT PLAN -DIVIDEND -Deleted",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119726",
    "name": "SBI FOCUSED EQUITY FUND - GROWTH",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI MSFU CONTRA-DIRECT PLAN -GROWTH -Deleted",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119728",
    "name": "SBI FOCUSED EQUITY FUND - GROWTH",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI MSFU EMERGING BUSINESSES FUND - DIRECT PLAN - DIVIDEND -Deleted",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119729",
    "name": "SBI FOCUSED EQUITY FUND - GROWTH",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI MSFU CONTRA-DIRECT  PLAN -GROWTH -Deleted",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "102766",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -Regular - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01819",
    "isinRe": "INF209K01DH6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "102767",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01793",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111521",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -REGULAR - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01801",
    "isinRe": "INF209K01DG8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111848",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund-Discipline Advantage Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01JW2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119502",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -Direct - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01R88",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119503",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -DIRECT - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01R62",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132918",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209KA1TX3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119505",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209K01N82",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132917",
    "name": "Aditya Birla Sun Life Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Aditya Birla Sun Life Dynamic Bond Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF209KA1TV7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102757",
    "name": "Reliance Liquid Cash Plan",
    "category": "Liquid",
    "navName": "Reliance Liquid Cash Plan-Growth Plan-Bonus",
    "minAmount": "5000/-",
    "launchDate": "2001-12-07",
    "closeData": "2001-12-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102809",
    "name": "Reliance Fixed Term Scheme--Series VI",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme--Series VI-QuarterlyPlan-Growth Option",
    "minAmount": "Rs100000/-",
    "launchDate": "2004-10-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102810",
    "name": "Reliance Fixed Term Scheme--Series VI",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme--Series VI-QuarterlyPlan-Dividend Option",
    "minAmount": "Rs100000/-",
    "launchDate": "2004-10-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102811",
    "name": "Reliance Fixed Term Scheme-XIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-XIII-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000-",
    "launchDate": "2004-10-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102812",
    "name": "Reliance Fixed Term Scheme-XIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-XIII-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000-",
    "launchDate": "2004-10-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102528",
    "name": "ICICI Prudential MidCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential MidCap Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-09-06",
    "closeData": "2004-09-06",
    "isin": "INF109K01AN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102529",
    "name": "ICICI Prudential MidCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential MidCap Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-09-06",
    "closeData": "2004-09-06",
    "isin": "INF109K01EH6",
    "isinRe": "INF109K01AL6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120380",
    "name": "ICICI Prudential MidCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential MidCap Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-09-06",
    "closeData": "2004-09-06",
    "isin": "INF109K019M2",
    "isinRe": "INF109K010N9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120381",
    "name": "ICICI Prudential MidCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ICICI Prudential MidCap Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2004-09-06",
    "closeData": "2004-09-06",
    "isin": "INF109K011N7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102846",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "Nippon India Balanced Advantage Fund-Growth Plan-Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01604",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102847",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "Nippon India Balanced Advantage Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01596",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102848",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "NIPPON INDIA BALANCED ADVANTAGE FUND - IDCW Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01570",
    "isinRe": "INF204K01588"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118736",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "Nippon India Balanced Advantage Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01G94",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118737",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "Nippon India Balanced Advantage Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01G60",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118738",
    "name": "Nippon India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Balanced Hybrid Fund",
    "navName": "NIPPON INDIA BALANCED ADVANTAGE FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01G78",
    "isinRe": "INF204K01G86"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102849",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Nippon India Dynamic Bond Fund-Growth Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01FI0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102850",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Nippon India Dynamic Bond Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102851",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "NIPPON INDIA DYNAMIC BOND FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01FJ8",
    "isinRe": "INF204K01FK6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117974",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "NIPPON INDIA DYNAMIC BOND FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01XB8",
    "isinRe": "INF204K01XC6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118747",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Nippon India Dynamic Bond Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01A25",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118749",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "NIPPON INDIA DYNAMIC BOND FUND - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01A58",
    "isinRe": "INF204K01A66"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118750",
    "name": "Nippon India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "NIPPON INDIA DYNAMIC BOND FUND - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2004-10-16",
    "closeData": "",
    "isin": "INF204K01A33",
    "isinRe": "INF204K01A41"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102852",
    "name": "Reliance Fixed Term Scheme- Series-XIV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme- Series-XIV-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102853",
    "name": "Reliance Fixed Term Scheme- Series-XIV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme- Series-XIV-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "114347",
    "name": "SBI  SAVINGS FUND - WEEKLY DIVIDEND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Magnum Income Fund - F R P - Saving Plus Bond Plan - Daily (D)",
    "minAmount": "2000",
    "launchDate": "2004-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102866",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 180 DAYS - 1 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 180 DAYS - 1 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2004-11-29",
    "closeData": "2004-11-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100916",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 1 - GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 1 - GROWTH",
    "minAmount": "10000",
    "launchDate": "2004-12-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100674",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 1 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 1 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2004-12-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102416",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-Retail",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102417",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-Institutional",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102418",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-RETAIL",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102419",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-DIVIDEND",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102420",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-INSTITUTIONAL",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "102421",
    "name": "ICICI Prudential Fixed Maturity Plan - 1 Year",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - 1 Year-Series 12-Institutional",
    "minAmount": "25000",
    "launchDate": "2004-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102870",
    "name": "Z-OLD-SBI Magnum Inst. Income Fund -  Savings - Wkly(D)",
    "category": "Liquid",
    "navName": "Z-OLD-SBI Magnum Inst. Income Fund -  Savings - Wkly(D)(Upto 22/03/07) Renamed as SBI Premier Liquid Fund",
    "minAmount": "5000000",
    "launchDate": "2004-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101769",
    "name": "Reliance Fixed Term Scheme-Series-XV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XV-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101770",
    "name": "Reliance Fixed Term Scheme-Series-XV",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XV-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100785",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Regular Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01HX4",
    "isinRe": "INF903J01IB8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100786",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Regular Plan - Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01HY2",
    "isinRe": "INF903J01IC6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100787",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Regular Plan - Half yearly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01HZ9",
    "isinRe": "INF903J01ID4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100788",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Regular Plan - Annual Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01IA0",
    "isinRe": "INF903J01IE2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100789",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Regular Plan- Growth",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01HW6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119628",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Direct Plan - Weekly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01PD9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119630",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Direct Plan - Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01NU8",
    "isinRe": "INF903J01NV6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119631",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Direct Plan - Annual Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01OX0",
    "isinRe": "INF903J01OZ5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119621",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01NR4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119622",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Direct Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01NS2",
    "isinRe": "INF903J01NT0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119624",
    "name": "Sundaram Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Sundaram Corporate Bond Fund Direct Plan - Half yearly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "1000",
    "launchDate": "2004-12-20",
    "closeData": "",
    "isin": "INF903J01OW2",
    "isinRe": "INF903J01OY8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100917",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 180 DAYS - 2 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 180 DAYS - 2 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2004-12-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100794",
    "name": "Sundaram Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Sundaram Small Cap Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-01-03",
    "closeData": "2005-01-03",
    "isin": "INF903J01454",
    "isinRe": "INF903J01462"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100795",
    "name": "Sundaram Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Sundaram Small Cap Fund Regular Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-01-03",
    "closeData": "2005-01-03",
    "isin": "INF903J01470",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119588",
    "name": "Sundaram Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Sundaram Small Cap Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-01-03",
    "closeData": "2005-01-03",
    "isin": "INF903J01NI3",
    "isinRe": "INF903J01NJ1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119589",
    "name": "Sundaram Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Sundaram Small Cap Fund Direct Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-01-03",
    "closeData": "2005-01-03",
    "isin": "INF903J01NK9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102874",
    "name": "Kotak Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Kotak-Small Cap Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2004-12-30",
    "closeData": "",
    "isin": "INF174K01237",
    "isinRe": "INF174K01229"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102875",
    "name": "Kotak Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Kotak-Small Cap Fund - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2004-12-30",
    "closeData": "",
    "isin": "INF174K01211",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120163",
    "name": "Kotak Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Kotak-Small Cap Fund - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "Rs.5000/-",
    "launchDate": "2004-12-30",
    "closeData": "",
    "isin": "INF174K01KU0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120164",
    "name": "Kotak Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Kotak-Small Cap Fund - Growth - Direct",
    "minAmount": "Rs.5000/-",
    "launchDate": "2004-12-30",
    "closeData": "",
    "isin": "INF174K01KT2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102883",
    "name": "Franklin India Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Franklin India Equity Advantage Fund - Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "2005-01-17",
    "closeData": "",
    "isin": "INF090I01205",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102884",
    "name": "Franklin India Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Franklin India Equity Advantage Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2005-01-17",
    "closeData": "",
    "isin": "INF090I01189",
    "isinRe": "INF090I01197"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118509",
    "name": "Franklin India Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Franklin India Equity Advantage Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2005-01-17",
    "closeData": "",
    "isin": "INF090I01IL5",
    "isinRe": "INF090I01IM3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118510",
    "name": "Franklin India Equity Advantage Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Franklin India Equity Advantage Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2005-01-17",
    "closeData": "",
    "isin": "INF090I01IN1",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100796",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Growth Plan",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01239",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100797",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Dividend Plan",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01213",
    "isinRe": "INF515L01221"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100798",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Auto Payout",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01247",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "100799",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Bonus",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01254",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120295",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Auto Payout- Direct",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01841",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120296",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Bonus- Direct",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01858",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120297",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Growth Option - Direct",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01833",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120298",
    "name": "Sahara Midcap Fund",
    "category": "Growth",
    "navName": "Sahara Midcap Fund-Dividend -Direct",
    "minAmount": "1000/-",
    "launchDate": "2004-11-29",
    "closeData": "",
    "isin": "INF515L01817",
    "isinRe": "INF515L01825"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102888",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 15 MONTHS-1- GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 15 MONTHS-1- GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-01-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102889",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 15 MONTHS-1- DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 15 MONTHS-1- DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2005-01-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102890",
    "name": "Reliance Fixed Term Scheme-VII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-VII-Quarterly Plan-Growth Option",
    "minAmount": "100000/-",
    "launchDate": "2005-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102891",
    "name": "Reliance Fixed Term Scheme-VII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-VII-Quarterly Plan-Dividend Option",
    "minAmount": "100000/-",
    "launchDate": "2005-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102892",
    "name": "Reliance Fixed Term Scheme- Series-XVI",
    "category": "Assured Return",
    "navName": "Reliance Fixed Term Scheme- Series-XVI-Monthly Plan-Growth Option",
    "minAmount": "",
    "launchDate": "2005-01-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102893",
    "name": "Reliance Fixed Term Scheme- Series-XVI",
    "category": "Assured Return",
    "navName": "Reliance Fixed Term Scheme- Series-XVI-Monthly Plan-Dividend Option",
    "minAmount": "",
    "launchDate": "2005-01-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102894",
    "name": "Z-OLD-SBI Magnum G I F T Savings Scheme 1992 - Option A",
    "category": "Income",
    "navName": "SBI Magnum G I F T Savings Scheme 1992 - Option A",
    "minAmount": "1",
    "launchDate": "1992-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102895",
    "name": "Z-OLD-SBI Magnum G I F T Savings Scheme 1992 - Option B",
    "category": "Income",
    "navName": "SBI Magnum G I F T Savings Scheme 1992 - Option B",
    "minAmount": "10",
    "launchDate": "1992-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118294",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - DIRECT PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01ER5",
    "isinRe": "INF760K01ES3"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118295",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01EN4",
    "isinRe": "INF760K01EO2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102917",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANFLOATING RATE-LONG TERM PLAN-DIVIDEND OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "104559",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - REGULAR PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01803",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "113144",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01AM4",
    "isinRe": "INF760K01AN2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118291",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01ET1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118292",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01EP9",
    "isinRe": "INF760K01EQ7"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118293",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - DIRECT PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01EM6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102911",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANFLOATING RATE-SHORT TERM PLAN-GROWTH OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102912",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANFLOATING RATE-SHORT TERM PLAN-DIVIDEND OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102913",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01795",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102914",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01779",
    "isinRe": "INF760K01787"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102915",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANARA ROBECO SAVINGS FUND - REGULAR PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": "INF760K01CB3",
    "isinRe": "INF760K01811"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102916",
    "name": "Canara Robeco Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "CANFLOATING RATE-LONG TERM PLAN-GROWTH OPTION",
    "minAmount": "RS.5000/-",
    "launchDate": "2005-02-08",
    "closeData": "2005-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102920",
    "name": "Canara Robeco Emerging Equities",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "CANARA ROBECO EMERGING EQUITIES - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-02-11",
    "closeData": "2005-02-23",
    "isin": "INF760K01167",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "102921",
    "name": "Canara Robeco Emerging Equities",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "CANARA ROBECO EMERGING EQUITIES - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-02-11",
    "closeData": "2005-02-23",
    "isin": "INF760K01175",
    "isinRe": "INF760K01183"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118277",
    "name": "Canara Robeco Emerging Equities",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "CANARA ROBECO EMERGING EQUITIES - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-02-11",
    "closeData": "2005-02-23",
    "isin": "INF760K01EG8",
    "isinRe": "INF760K01EH6"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118278",
    "name": "Canara Robeco Emerging Equities",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "CANARA ROBECO EMERGING EQUITIES - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-02-11",
    "closeData": "2005-02-23",
    "isin": "INF760K01EI4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101160",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 2 - GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 2 - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100717",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 2 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 2 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2005-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101161",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund-Growth Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01489",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101162",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01471",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "101163",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "NIPPON INDIA MULTI CAP FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01455",
    "isinRe": "INF204K01463"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106252",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund Institutional Dividend Plan",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01414",
    "isinRe": "INF204K01422"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106253",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund Institutional Plan Growth Plan Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01448",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118650",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01XF9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118651",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Nippon India Multi Cap Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01D55",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118652",
    "name": "Nippon India Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "NIPPON INDIA MULTI CAP FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2005-02-14",
    "closeData": "",
    "isin": "INF204K01XD4",
    "isinRe": "INF204K01XE2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102758",
    "name": "Reliance Fixed Term Scheme-Series-XVII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XVII-Monthly Plan-Growth Option",
    "minAmount": "",
    "launchDate": "2005-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102759",
    "name": "Reliance Fixed Term Scheme-Series-XVII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Series-XVII-Monthly Plan-Dividend Option",
    "minAmount": "",
    "launchDate": "2005-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "102938",
    "name": "Kotak FMP - Series",
    "category": "Income",
    "navName": "Kotak FMP - Series-VIII-XVII",
    "minAmount": "",
    "launchDate": "",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102947",
    "name": "HDFC Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HDFC Hybrid Equity Fund - IDCW Plan",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-02-28",
    "closeData": "",
    "isin": "INF179K01AQ8",
    "isinRe": "INF179K01AR6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102948",
    "name": "HDFC Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HDFC Hybrid Equity Fund - Growth Plan",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-02-28",
    "closeData": "",
    "isin": "INF179K01AS4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "102949",
    "name": "HDFC Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HDFC Premier Multi-Cap Fund-Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119062",
    "name": "HDFC Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HDFC Hybrid Equity Fund - Growth Option - Direct Plan",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-02-28",
    "closeData": "",
    "isin": "INF179K01XZ1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119061",
    "name": "HDFC Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HDFC Hybrid Equity Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs.5000/-",
    "launchDate": "2005-02-28",
    "closeData": "",
    "isin": "INF179K01XX6",
    "isinRe": "INF179K01XY4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102955",
    "name": "Reliance Fixed Term Scheme-Quarterly Plan-Series-VIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Quarterly Plan-Series-VIII-Quarterly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102956",
    "name": "Reliance Fixed Term Scheme-Quarterly Plan-Series-VIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme-Quarterly Plan-Series-VIII-Quarterly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102960",
    "name": "Z-OLD-SBI Magnum Inst. Income Fund -  Savings - Fortnightly(D)",
    "category": "Liquid",
    "navName": "Z-OLD-SBI Magnum Inst. Income Fund -  Savings - Fortnightly(D)(Upto 22/03/07) Renamed as SBI Premier Liquid Fund",
    "minAmount": "5000000",
    "launchDate": "2004-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102974",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 13 MONTHS-1 - GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 13 MONTHS-1 - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102976",
    "name": "Reliance Fixed Term Scheme Series-XVIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme Series-XVIII-Monthly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "102977",
    "name": "Reliance Fixed Term Scheme Series-XVIII",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme Series-XVIII-Monthly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103008",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 3 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 3 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103009",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 3 - GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 60 DAYS - 3 - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103020",
    "name": "Reliance Fixed Term Scheme--Series-IX",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme--Series-IX-Quarterly Plan-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-04-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103021",
    "name": "Reliance Fixed Term Scheme--Series-IX",
    "category": "Growth",
    "navName": "Reliance Fixed Term Scheme--Series-IX-Quarterly Plan-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-04-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103022",
    "name": "Reliance Fixed Maturity Fund-Series-2-",
    "category": "Growth",
    "navName": "Reliance Fixed Maturity Fund-Series-2--Monthly Plan-1-Growth Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-04-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103023",
    "name": "Reliance Fixed Maturity Fund-Series-2-",
    "category": "Growth",
    "navName": "Reliance Fixed Maturity Fund-Series-2--Monthly Plan-1-Dividend Option",
    "minAmount": "Rs 100000/",
    "launchDate": "2005-04-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120310",
    "name": "ICICI Prudential Blended Plan A",
    "category": "Growth",
    "navName": "ICICI Prudential Blended Plan A-Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K015C1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120311",
    "name": "ICICI Prudential Blended Plan A",
    "category": "Growth",
    "navName": "ICICI Prudential Blended Plan A-Direct Plan -  Dividend",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K013C6",
    "isinRe": "INF109K014C4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130949",
    "name": "ICICI Prudential Blended Plan A",
    "category": "Growth",
    "navName": "ICICI Prudential Blended Plan A - Direct Plan Bonus",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109KA1B65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103027",
    "name": "ICICI Prudential Blended Plan A",
    "category": "Growth",
    "navName": "ICICI Prudential Blended Plan A - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01AP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103028",
    "name": "ICICI Prudential Blended Plan A",
    "category": "Growth",
    "navName": "ICICI Prudential Blended Plan A -  Dividend",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01DZ0",
    "isinRe": "INF109K01AO0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103061",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B -  Growth Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01AR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103062",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B -  Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01EA1",
    "isinRe": "INF109K01AQ5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114238",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Daily Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01VW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114376",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B -  Monthly Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K01VV1",
    "isinRe": "INF109K01ID6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120300",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan -  Growth Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K017J2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120301",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan -  Monthly Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K018J0",
    "isinRe": "INF109K019J8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120302",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan -  Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K015J6",
    "isinRe": "INF109K016J4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120303",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B-Direct Plan -  Daily Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109K014J9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123542",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan - Quarterly Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109KA1CK7",
    "isinRe": "INF109KA1CJ9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131149",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Half Yearly Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109KA1M39",
    "isinRe": "INF109KA1M21"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131150",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - Direct Plan - Half Yearly Dividend Option - I",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109KA1M54",
    "isinRe": "INF109KA1M47"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132979",
    "name": "ICICI Prudential Blended Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Blended Plan B - option I-Bonus",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-04-28",
    "closeData": "",
    "isin": "INF109KA1392",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103025",
    "name": "UTI - Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "UTI Dividend Yield Fund - Regular Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": "INF789F01448",
    "isinRe": "INF789F01455"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103026",
    "name": "UTI - Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "UTI-Dividend Yield Fund.-Growth",
    "minAmount": "5000/-",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": "INF789F01463",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120749",
    "name": "UTI - Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "UTI-Dividend Yield Fund.-Growth-Direct",
    "minAmount": "5000/-",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": "INF789F01SW4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120750",
    "name": "UTI - Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "UTI Dividend Yield Fund - Direct Plan - IDCW",
    "minAmount": "5000/-",
    "launchDate": "2005-04-11",
    "closeData": "",
    "isin": "INF789F01SU8",
    "isinRe": "INF789F01SV6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103039",
    "name": "Kotak India EQ Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Kotak India EQ Contra Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.1000",
    "launchDate": "2005-06-02",
    "closeData": "2005-06-02",
    "isin": "INF174K01260",
    "isinRe": "INF174K01252"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103040",
    "name": "Kotak India EQ Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Kotak India EQ Contra Fund - Growth",
    "minAmount": "Rs.1000",
    "launchDate": "2005-06-02",
    "closeData": "2005-06-02",
    "isin": "INF174K01245",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119768",
    "name": "Kotak India EQ Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Kotak India EQ Contra Fund - Payout of Income Distribution cum capital withdrawal option- Direct",
    "minAmount": "Rs.1000",
    "launchDate": "2005-06-02",
    "closeData": "2005-06-02",
    "isin": "INF174K01LA0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119769",
    "name": "Kotak India EQ Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Kotak India EQ Contra Fund - Growth - Direct",
    "minAmount": "Rs.1000",
    "launchDate": "2005-06-02",
    "closeData": "2005-06-02",
    "isin": "INF174K01KZ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103085",
    "name": "Nippon India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Nippon India Value Fund- Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01GB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103086",
    "name": "Nippon India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "NIPPON INDIA VALUE FUND - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01GC1",
    "isinRe": "INF204K01GD9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118782",
    "name": "Nippon India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "NIPPON INDIA VALUE FUND - DIRECT Plan - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01K23",
    "isinRe": "INF204K01K31"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118784",
    "name": "Nippon India Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Nippon India Value Fund - Direct Plan Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01K49",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "103031",
    "name": "Escorts Mutual Fund Unclaimed Dundee Sovereign Trust",
    "category": "Money Market",
    "navName": "Escorts Mutual Fund Unclaimed Dundee Sovereign Trust-Quarterly Dividend",
    "minAmount": "0",
    "launchDate": "2017-01-31",
    "closeData": "2017-02-06",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103048",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Nippon India Money Market Fund-Growth Plan-Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01VA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103049",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Nippon India Money Market Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01UX8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103050",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - DAILY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01UY6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103051",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - WEEKLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01UZ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103052",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01VB2",
    "isinRe": "INF204K01VC0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112341",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01VD8",
    "isinRe": "INF204K01VE6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118720",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01ZQ1",
    "isinRe": "INF204K01ZR9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118715",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Nippon India Money Market Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01G11",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118716",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01ZS7",
    "isinRe": "INF204K01ZT5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118717",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01ZO6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118718",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "NIPPON INDIA MONEY MARKET FUND - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01ZN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118719",
    "name": "Nippon India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Nippon India Money Market Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2005-06-15",
    "closeData": "2005-06-16",
    "isin": "INF204K01ZP3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "103106",
    "name": "JM Emerging Leaders Fund",
    "category": "Growth",
    "navName": "JM Emerging Leaders Fund-Growth Plan",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2005-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "103107",
    "name": "JM Emerging Leaders Fund",
    "category": "Growth",
    "navName": "JM Emerging Leaders Fund-Dividend Plan",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2005-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103110",
    "name": "Aditya Birla Sun Life India GenNext Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life India Gennext Fund -Regular - IDCW",
    "minAmount": "Rs. 5000 per application",
    "launchDate": "2005-06-14",
    "closeData": "2005-07-12",
    "isin": "INF209K01439",
    "isinRe": "INF209K01CR7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103111",
    "name": "Aditya Birla Sun Life India GenNext Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life India Gennext Fund-Growth Option",
    "minAmount": "Rs. 5000 per application",
    "launchDate": "2005-06-14",
    "closeData": "2005-07-12",
    "isin": "INF209K01447",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119590",
    "name": "Aditya Birla Sun Life India GenNext Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life India Gennext Fund -DIRECT - IDCW",
    "minAmount": "Rs. 5000 per application",
    "launchDate": "2005-06-14",
    "closeData": "2005-07-12",
    "isin": "INF209K01Q63",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119591",
    "name": "Aditya Birla Sun Life India GenNext Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life India Gennext Fund - Growth - Direct Plan",
    "minAmount": "Rs. 5000 per application",
    "launchDate": "2005-06-14",
    "closeData": "2005-07-12",
    "isin": "INF209K01WC7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103096",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Opportunities Fund-Growth Option",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103097",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Value Opportunities Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": "INF789F01AE0",
    "isinRe": "INF789F01AF7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103098",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Value Opportunities Fund- Regular Plan - Growth Option",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": "INF789F01AG5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103099",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Opportunities Fund-Dividend Option",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120751",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Value Opportunities Fund- Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": "INF789F01VB2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120752",
    "name": "UTI - Value Opportunities Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "UTI Value Opportunities Fund - Direct Plan - IDCW",
    "minAmount": "Rs.5000/- subsequent amt Rs.1000/-",
    "launchDate": "2005-07-16",
    "closeData": "",
    "isin": "INF789F01UZ3",
    "isinRe": "INF789F01VA4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "103130",
    "name": "HDFC Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "HDFC Multi-Asset Fund - IDCW Option",
    "minAmount": "Rs 5000",
    "launchDate": "2005-07-11",
    "closeData": "",
    "isin": "INF179K01AN5",
    "isinRe": "INF179K01AO3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "103131",
    "name": "HDFC Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "HDFC Multi-Asset Fund - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2005-07-11",
    "closeData": "",
    "isin": "INF179K01AP0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119130",
    "name": "HDFC Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "HDFC Multi-Asset Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs 5000",
    "launchDate": "2005-07-11",
    "closeData": "",
    "isin": "INF179K01XU2",
    "isinRe": "INF179K01XV0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119131",
    "name": "HDFC Multi-Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "HDFC Multi-Asset Fund - Growth Option - Direct Plan",
    "minAmount": "Rs 5000",
    "launchDate": "2005-07-11",
    "closeData": "",
    "isin": "INF179K01XW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103149",
    "name": "ICICI Prudential Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Infrastructure Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-07-18",
    "closeData": "",
    "isin": "INF109K01AV5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103150",
    "name": "ICICI Prudential Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Infrastructure Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-07-18",
    "closeData": "",
    "isin": "INF109K01FB6",
    "isinRe": "INF109K01AT9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120621",
    "name": "ICICI Prudential Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Infrastructure Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-07-18",
    "closeData": "",
    "isin": "INF109K018M4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120622",
    "name": "ICICI Prudential Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Infrastructure Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-07-18",
    "closeData": "",
    "isin": "INF109K016M8",
    "isinRe": "INF109K017M6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103154",
    "name": "Aditya Birla Sun Life Equity Hybrid '95 Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Aditya Birla Sun Life Equity Hybrid'95 Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1995-01-19",
    "closeData": "",
    "isin": "INF209K01BS7",
    "isinRe": "INF209K01EE1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103155",
    "name": "Aditya Birla Sun Life Equity Hybrid '95 Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Aditya Birla Sun Life Equity Hybrid'95 Fund - Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "1995-01-19",
    "closeData": "",
    "isin": "INF209K01BT5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120517",
    "name": "Aditya Birla Sun Life Equity Hybrid '95 Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Aditya Birla Sun Life Equity Hybrid'95 Fund - Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "1995-01-19",
    "closeData": "",
    "isin": "INF209K01ZC0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120518",
    "name": "Aditya Birla Sun Life Equity Hybrid '95 Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Aditya Birla Sun Life Equity Hybrid'95 Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1995-01-19",
    "closeData": "",
    "isin": "INF209KA1LH3",
    "isinRe": "INF209K01ZB2"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103164",
    "name": "Aditya Birla Sun Life ELSS Tax Relief '96",
    "category": "Equity Scheme - ELSS",
    "navName": "Aditya Birla Sun Life ELSS Tax Relief '96 -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01090",
    "isinRe": "INF209K01CB1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "107745",
    "name": "Aditya Birla Sun Life ELSS Tax Relief '96",
    "category": "Equity Scheme - ELSS",
    "navName": "Aditya Birla Sun Life ELSS Tax Relief '96 - Growth Option",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01108",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119543",
    "name": "Aditya Birla Sun Life ELSS Tax Relief '96",
    "category": "Equity Scheme - ELSS",
    "navName": "Aditya Birla Sun Life ELSS Tax Relief '96 -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01P23",
    "isinRe": "INF209K01UM0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119544",
    "name": "Aditya Birla Sun Life ELSS Tax Relief '96",
    "category": "Equity Scheme - ELSS",
    "navName": "Aditya Birla Sun Life ELSS Tax Relief '96 - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1994-01-01",
    "closeData": "1994-01-01",
    "isin": "INF209K01UN8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103165",
    "name": "Aditya Birla Sun Life Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Aditya Birla Sun Life Flexi Cap Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1998-08-17",
    "closeData": "",
    "isin": "INF209K01AQ3",
    "isinRe": "INF209K01DT1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103166",
    "name": "Aditya Birla Sun Life Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Aditya Birla Sun Life Flexi Cap Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1998-08-17",
    "closeData": "",
    "isin": "INF209K01AJ8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120564",
    "name": "Aditya Birla Sun Life Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Aditya Birla Sun Life Flexi Cap Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1998-08-17",
    "closeData": "",
    "isin": "INF209K01XX1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120565",
    "name": "Aditya Birla Sun Life Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Aditya Birla Sun Life Flexi Cap Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1998-08-17",
    "closeData": "",
    "isin": "INF209KA1LE0",
    "isinRe": "INF209K01XY9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103167",
    "name": "Aditya Birla Sun Life Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Digital India Fund -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "1999-12-15",
    "closeData": "",
    "isin": "INF209K01132",
    "isinRe": "INF209K01CD7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103168",
    "name": "Aditya Birla Sun Life Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Digital India Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1999-12-15",
    "closeData": "",
    "isin": "INF209K01140",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120538",
    "name": "Aditya Birla Sun Life Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Digital India Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1999-12-15",
    "closeData": "",
    "isin": "INF209K01P49",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120539",
    "name": "Aditya Birla Sun Life Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Digital India Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1999-12-15",
    "closeData": "",
    "isin": "INF209K01VF2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103173",
    "name": "Aditya Birla Sun Life Frontline Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Aditya Birla Sun Life Frontline Equity Fund-Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-23",
    "closeData": "",
    "isin": "INF209K01BO6",
    "isinRe": "INF209K01EC5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103174",
    "name": "Aditya Birla Sun Life Frontline Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Aditya Birla Sun Life Frontline Equity Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2002-09-23",
    "closeData": "",
    "isin": "INF209K01BR9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119527",
    "name": "Aditya Birla Sun Life Frontline Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Aditya Birla Sun Life Frontline Equity Fund-DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2002-09-23",
    "closeData": "",
    "isin": "INF209K01YX9",
    "isinRe": "INF209K01YW1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119528",
    "name": "Aditya Birla Sun Life Frontline Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Aditya Birla Sun Life Frontline Equity Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2002-09-23",
    "closeData": "",
    "isin": "INF209K01YY7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103176",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund - Retail Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LT4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106157",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - retail - quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LS6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "108272",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LX6",
    "isinRe": "INF209KA11Z3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "108273",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund - Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LV0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119553",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - Direct - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01YO8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "108274",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - REGULAR - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LN7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "110282",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - REGULAR - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LU2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "110490",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - retail - monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01LR8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119550",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund- Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01YN0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119551",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209KA12Z1",
    "isinRe": "INF209KA13Z9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119552",
    "name": "Aditya Birla Sun Life Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Aditya Birla Sun Life Banking & PSU Debt Fund  - DIRECT - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "2000-01-01",
    "closeData": "2000-01-02",
    "isin": "INF209K01YM2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103177",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Income Fund-Plan A(Dividend)",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103178",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209K01785",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103179",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Income Fund-Plan D(54EA Dividend)",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209K01DI4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103180",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Income Fund-Plan E(54EA Growth)",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103181",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Income Fund-PLan F(54EB Dividend)",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103182",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Income Fund-Plan G(54EB Growth)",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103183",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund -REGULAR - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209K01983",
    "isinRe": "INF209K01DL8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119532",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund -DIRECT - MONTHLY IDCW",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209K01N90",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119533",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209K01S38",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "124832",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund -DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209KA1KT0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "124833",
    "name": "Aditya Birla Sun Life Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Aditya Birla Sun Life Corporate Bond Fund -REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "1997-03-20",
    "closeData": "1997-03-20",
    "isin": "INF209KA1KS2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103191",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -INSTITUTIONAL - DAILY IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01KD0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103192",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund - Growth Plan",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01LQ0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103193",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -REGULAR - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209KA1LQ4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103194",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -Institutional - weekly  IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01KG3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103195",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund - Institutional Plan - Growth",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01KE8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "113307",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -Regular - DAILY IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209KA1LP6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119522",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -Direct - weekly IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01XV5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119523",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low duration Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01XU7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119524",
    "name": "Aditya Birla Sun Life Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Aditya Birla Sun Life Low Duration Fund -Direct - daily IDCW",
    "minAmount": "5000",
    "launchDate": "1998-07-07",
    "closeData": "",
    "isin": "INF209K01XW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103196",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Nippon India Tax Saver (ELSS) Fund-Growth Plan-Growth Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204K01GK4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103197",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "NIPPON INDIA TAX SAVER (ELSS) FUND - IDCW Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204K01GL2",
    "isinRe": "INF204K01GM0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118801",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "NIPPON INDIA TAX SAVER (ELSS) FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204K01L30",
    "isinRe": "INF204K01L48"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118803",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Nippon India Tax Saver Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204K01L55",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133865",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "NIPPON INDIA TAX SAVER (ELSS) FUND - DIRECT Plan - ANNUAL IDCW Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204KA1E12",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133866",
    "name": "Nippon India Tax Saver (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "NIPPON INDIA TAX SAVER (ELSS) FUND - ANNUAL IDCW Option",
    "minAmount": "Rs 500/-",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF204KA1E04",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103198",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)-Growth Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103199",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)-Annual Dividend Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103200",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)-Growth Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103201",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesII (60MonthsPlan)-Annual Dividend Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103219",
    "name": "Z-OLD-SBI Magnum Multicap Fund",
    "category": "Growth",
    "navName": "SBI Magnum Multicap Fund",
    "minAmount": "5000",
    "launchDate": "2005-08-22",
    "closeData": "2005-08-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "103225",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01317",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "103226",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Daily IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01275",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "103227",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Weekly IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01499",
    "isinRe": "INF966L01283"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "103228",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Monthly IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01291",
    "isinRe": "INF966L01309"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148510",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Unclaimed Dividend-Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148511",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Unclaimed Redemption Investor Education Plan-Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148512",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Unclaimed Redemption Plan-Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148513",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Unclaimed Dividend Investor Education Plan-Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120836",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund-Daily IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01770",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120837",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01820",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120838",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Monthly IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01796",
    "isinRe": "INF966L01812"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120839",
    "name": "Quant Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "quant Liquid Fund - Weekly IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2005-09-27",
    "closeData": "2005-09-29",
    "isin": "INF966L01788",
    "isinRe": "INF966L01804"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103233",
    "name": "Kotak Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Kotak Equity Opportunities Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF174K01203",
    "isinRe": "INF174K01195"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103234",
    "name": "Kotak Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Kotak Equity Opportunities Fund - Growth",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF174K01187",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120157",
    "name": "Kotak Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Kotak Equity Opportunities Fund - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF174K01LG7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120158",
    "name": "Kotak Equity Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Kotak Equity Opportunities Fund - Growth - Direct",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2004-09-10",
    "closeData": "",
    "isin": "INF174K01LF9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "103271",
    "name": "Canara Robeco Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "CANBALANCE-II",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-12-01",
    "closeData": "1993-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "106166",
    "name": "Canara Robeco Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "CANARA ROBECO EQUITY HYBRID FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-12-01",
    "closeData": "1993-01-01",
    "isin": "INF760K01050",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "106167",
    "name": "Canara Robeco Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "CANARA ROBECO EQUITY HYBRID FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-12-01",
    "closeData": "1993-01-01",
    "isin": "INF760K01068",
    "isinRe": "INF760K01076"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118271",
    "name": "Canara Robeco Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "CANARA ROBECO EQUITY HYBRID FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-12-01",
    "closeData": "1993-01-01",
    "isin": "INF760K01EX3",
    "isinRe": "INF760K01EY1"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118272",
    "name": "Canara Robeco Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "CANARA ROBECO EQUITY HYBRID FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs.5000/-",
    "launchDate": "1992-12-01",
    "closeData": "1993-01-01",
    "isin": "INF760K01EZ8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103308",
    "name": "Aditya Birla Sun Life Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Aditya Birla Sun Life Focused Equity Fund -Regular - IDCW",
    "minAmount": "Minimum of Rs. 5000/- and in multiplesof Re. 1/-",
    "launchDate": "2005-08-30",
    "closeData": "",
    "isin": "INF209K01454",
    "isinRe": "INF209K01CS5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103309",
    "name": "Aditya Birla Sun Life Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Aditya Birla Sun Life Focused Equity Fund -Growth Option",
    "minAmount": "Minimum of Rs. 5000/- and in multiplesof Re. 1/-",
    "launchDate": "2005-08-30",
    "closeData": "",
    "isin": "INF209K01462",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119563",
    "name": "Aditya Birla Sun Life Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Aditya Birla Sun Life Focused Equity Fund -Direct - IDCW",
    "minAmount": "Minimum of Rs. 5000/- and in multiplesof Re. 1/-",
    "launchDate": "2005-08-30",
    "closeData": "",
    "isin": "INF209K01Q71",
    "isinRe": "INF209K01WF0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119564",
    "name": "Aditya Birla Sun Life Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Aditya Birla Sun Life Focused Equity Fund - Growth - Direct Plan",
    "minAmount": "Minimum of Rs. 5000/- and in multiplesof Re. 1/-",
    "launchDate": "2005-08-30",
    "closeData": "",
    "isin": "INF209K01WE3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102030",
    "name": "Z-OLD-SBI MAGNUM DEBT FUND SERIES - 13 MONTHS - 2-GROWTH",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 13 MONTHS - 2-GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-10-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102415",
    "name": "Z-OLD-OLD-SBI MAGNUM DEBT FUND SERIES - 13 MONTHS - 2 - DIVIDEND",
    "category": "Income",
    "navName": "SBI MAGNUM DEBT FUND SERIES - 13 MONTHS - 2 - DIVIDEND",
    "minAmount": "10000",
    "launchDate": "2005-10-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103338",
    "name": "Kotak Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Kotak Tax Saver-Scheme-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.500",
    "launchDate": "2005-09-29",
    "closeData": "",
    "isin": "INF174K01385",
    "isinRe": "INF174K01377"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "103339",
    "name": "Kotak Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Kotak Tax Saver-Scheme-Growth",
    "minAmount": "Rs.500",
    "launchDate": "2005-09-29",
    "closeData": "",
    "isin": "INF174K01369",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119772",
    "name": "Kotak Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Kotak Tax Saver-Scheme-Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "Rs.500",
    "launchDate": "2005-09-29",
    "closeData": "",
    "isin": "INF174K01LJ1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119773",
    "name": "Kotak Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Kotak Tax Saver-Scheme-Growth - Direct",
    "minAmount": "Rs.500",
    "launchDate": "2005-09-29",
    "closeData": "",
    "isin": "INF174K01LI3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103340",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Growth",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103341",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund -Monthly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103342",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Weekly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VS7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103343",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Daily IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103344",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Institutional Option - I",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VH0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104308",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Half Yearly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01UX9",
    "isinRe": "INF109K01UW1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139431",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Unclaimed Redemption Investor Education",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KB1WT5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139432",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Unclaimed IDCW Investor Education",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KB1WS7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139433",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Unclaimed Redemption",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KB1WQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139434",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Unclaimed IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KB1WR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130933",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Bonus",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KA1G94",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130934",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan Bonus",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109KA1G86",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120191",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan -  IDCW Others",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q31",
    "isinRe": "INF109K01Q23"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120196",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q72",
    "isinRe": "INF109K01Q80"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120197",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan - Growth",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q49",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120198",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan -  Annual IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01P99",
    "isinRe": "INF109K01Q07"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112119",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund-Annual IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VD9",
    "isinRe": "INF109K01VC1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115505",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - IDCW Others",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01VU3",
    "isinRe": "INF109K01VT5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120187",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan -  Half Yearly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q56",
    "isinRe": "INF109K01Q64"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120188",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan - Daily IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120189",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan - Monthly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01R06",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120190",
    "name": "ICICI Prudential Liquid Fund -",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ICICI Prudential Liquid Fund - Direct Plan - Weekly IDCW",
    "minAmount": "1)Growth-Rs.15000 2)Dividend-Rs. 100000/-",
    "launchDate": "1999-01-01",
    "closeData": "",
    "isin": "INF109K01Q98",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "103347",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740K01FK9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "103348",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740K01FM5",
    "isinRe": "INF740K01FL7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "103349",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund- Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740KA1OE0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119123",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740KA1OC4",
    "isinRe": "INF740KA1OD2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119124",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740KA1OB6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119125",
    "name": "DSP Liquidity Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DSP Liquidity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2005-11-23",
    "closeData": "2005-11-23",
    "isin": "INF740K01QL4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103360",
    "name": "Franklin India Smaller Companies Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Franklin India Smaller Companies Fund-Growth",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2005-11-16",
    "closeData": "",
    "isin": "INF090I01569",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103361",
    "name": "Franklin India Smaller Companies Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Franklin India Smaller Companies Fund - IDCW ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2005-11-16",
    "closeData": "",
    "isin": "INF090I01544",
    "isinRe": "INF090I01551"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118524",
    "name": "Franklin India Smaller Companies Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Franklin India Smaller Companies Fund - Direct - IDCW ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2005-11-16",
    "closeData": "",
    "isin": "INF090I01IO9",
    "isinRe": "INF090I01IP6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118525",
    "name": "Franklin India Smaller Companies Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Franklin India Smaller Companies Fund - Direct - Growth",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2005-11-16",
    "closeData": "",
    "isin": "INF090I01IQ4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103366",
    "name": "Z-OLD-SBI Magnum Debt Fund Series-180 days-3-Growth",
    "category": "Income",
    "navName": "SBI Magnum Debt Fund Series-180 days-3-Growth",
    "minAmount": "10",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103354",
    "name": "Z-OLD-SBI Magnum Debt Fund Series-180 days-3-Dividend",
    "category": "Income",
    "navName": "SBI Magnum Debt Fund Series-180 days-3-Dividend",
    "minAmount": "10",
    "launchDate": "1990-01-01",
    "closeData": "1990-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103312",
    "name": "ICICI Prudential Exports & Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Exports & Services Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-10-13",
    "closeData": "",
    "isin": "INF109K01BB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103313",
    "name": "ICICI Prudential Exports & Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Exports & Services Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-10-13",
    "closeData": "",
    "isin": "INF109K01FL5",
    "isinRe": "INF109K01BA7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120687",
    "name": "ICICI Prudential Exports & Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Exports & Services Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-10-13",
    "closeData": "",
    "isin": "INF109K01W09",
    "isinRe": "INF109K01W17"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120688",
    "name": "ICICI Prudential Exports & Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Exports & Services Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2005-10-13",
    "closeData": "",
    "isin": "INF109K01W25",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "103389",
    "name": "Canara Robeco Infrastructure",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO INFRASTRUCTURE FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-10-11",
    "closeData": "2005-10-28",
    "isin": "INF760K01258",
    "isinRe": "INF760K01266"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "103390",
    "name": "Canara Robeco Infrastructure",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO INFRASTRUCTURE FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-10-11",
    "closeData": "2005-10-28",
    "isin": "INF760K01274",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118267",
    "name": "Canara Robeco Infrastructure",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO INFRASTRUCTURE FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-10-11",
    "closeData": "2005-10-28",
    "isin": "INF760K01FO9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118268",
    "name": "Canara Robeco Infrastructure",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO INFRASTRUCTURE FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "RS. 5000/-",
    "launchDate": "2005-10-11",
    "closeData": "2005-10-28",
    "isin": "INF760K01FM3",
    "isinRe": "INF760K01FN1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103456",
    "name": "UTI Bluechip Flexicap Fund",
    "category": "Growth",
    "navName": "UTI Leadership Equity Fund-Dividend-Dividend reinvestment Option",
    "minAmount": "5000",
    "launchDate": "2006-01-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103475",
    "name": "Aditya Birla Sun Life Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Infrastructure Fund -  Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-01-31",
    "closeData": "",
    "isin": "INF209K01470",
    "isinRe": "INF209K01CT3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "103476",
    "name": "Aditya Birla Sun Life Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Infrastructure Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2006-01-31",
    "closeData": "",
    "isin": "INF209K01504",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119514",
    "name": "Aditya Birla Sun Life Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Infrastructure Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2006-01-31",
    "closeData": "",
    "isin": "INF209K01WH6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119515",
    "name": "Aditya Birla Sun Life Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Infrastructure Fund -  Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-01-31",
    "closeData": "",
    "isin": "INF209K01WG8",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "103490",
    "name": "Quantum Long Term Equity Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quantum Long Term Equity Value Fund - Direct Plan Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2006-02-08",
    "closeData": "",
    "isin": "INF082J01036",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "103491",
    "name": "Quantum Long Term Equity Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quantum Long Term Equity Value Fund - Direct Plan IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2006-02-08",
    "closeData": "",
    "isin": "INF082J01044",
    "isinRe": "INF082J01051"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141068",
    "name": "Quantum Long Term Equity Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quantum Long Term Equity Value Fund - Regular Plan Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2006-02-08",
    "closeData": "",
    "isin": "INF082J01242",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141069",
    "name": "Quantum Long Term Equity Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quantum Long Term Equity Value Fund - Regular Plan IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2006-02-08",
    "closeData": "",
    "isin": "INF082J01259",
    "isinRe": "INF082J01267"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "103533",
    "name": "UTI Contra Fund",
    "category": "Growth",
    "navName": "UTI Contra Fund-Income-Dividend reinvestment Option",
    "minAmount": "5000",
    "launchDate": "2006-02-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103546",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesIV(60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesIV(60MonthsPlan)Growth Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2006-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103547",
    "name": "Franklin Templeton Fixed Tenure Fund-SeriesIV(60MonthsPlan)",
    "category": "Income",
    "navName": "Franklin Templeton Fixed Tenure Fund-SeriesIV(60MonthsPlan)Annual Dividend Plan",
    "minAmount": "Rs.10000",
    "launchDate": "2006-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103633",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Growth",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01TX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103634",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Daily IDCW",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01TY9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103635",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Weekly IDCW",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01TZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103636",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option -Fortnightly IDCW",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01UA7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "103637",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - Monthly IDCW",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01UC3",
    "isinRe": "INF109K01UB5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130013",
    "name": "ICICI Prudential Money Market Fund - Cash Option",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "ICICI Prudential Money Market Fund Option - IDCW Others",
    "minAmount": "1000",
    "launchDate": "2006-03-08",
    "closeData": "",
    "isin": "INF109K01I64",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "103652",
    "name": "JM FMF SERIES II - YEARLY",
    "category": "Income",
    "navName": "JM FMF SERIES II - YEARLY \"A\"-Dividend Plan",
    "minAmount": "Rs.5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2006-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103678",
    "name": "Templeton India Equity Income Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Templeton India Equity Income Fund-Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "2006-03-22",
    "closeData": "",
    "isin": "INF090I01957",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "103679",
    "name": "Templeton India Equity Income Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Templeton India EQUITY INCOME FUND - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2006-03-22",
    "closeData": "",
    "isin": "INF090I01932",
    "isinRe": "INF090I01940"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118526",
    "name": "Templeton India Equity Income Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Templeton India EQUITY INCOME FUND - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2006-03-22",
    "closeData": "",
    "isin": "INF090I01IR2",
    "isinRe": "INF090I01IS0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118527",
    "name": "Templeton India Equity Income Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Templeton India Equity Income Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2006-03-22",
    "closeData": "",
    "isin": "INF090I01IT8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "103601",
    "name": "BARODA PIONEER GLOBAL FUND",
    "category": "Growth",
    "navName": "BARODA PIONEER GLOBAL FUND-Growth",
    "minAmount": "5000",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103747",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund-Growth Plan-Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01687",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103748",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund-Growth Plan-Bonus Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01679",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "103749",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund-Dividend Plan",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01653",
    "isinRe": "INF204K01661"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118640",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund - Direct Plan Dividend Plan",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01XJ1",
    "isinRe": "INF204K01XK9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118641",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01D48",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118643",
    "name": "Reliance Focused Large Cap Fund",
    "category": "Growth",
    "navName": "Reliance Focused Large Cap Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2006-02-06",
    "closeData": "",
    "isin": "INF204K01XL7",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "103734",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Direct Plan Growth Option",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01127",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "103735",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Direct Plan Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01143",
    "isinRe": "INF082J01200"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "103736",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Direct Plan Daily IDCW",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01135",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141065",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Regular Plan Daily IDCW",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01317",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141066",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Regular Plan Growth Option",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01309",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141067",
    "name": "Quantum Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Quantum Liquid Fund - Regular Plan Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2006-04-03",
    "closeData": "",
    "isin": "INF082J01325",
    "isinRe": "INF082J01333"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "102142",
    "name": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Consumption Fund(Formerly Known as Sundaram Rural and Consumption Fund Regular Plan - Growth)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-03-20",
    "closeData": "",
    "isin": "INF903J01561",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "103743",
    "name": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-03-20",
    "closeData": "",
    "isin": "INF903J01546",
    "isinRe": "INF903J01553"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "113373",
    "name": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund Institutional Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-03-20",
    "closeData": "",
    "isin": "INF903J01579",
    "isinRe": "INF903J01587"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119594",
    "name": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Consumption Fund (Formerly Known asSundaram Rural and Consumption Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-03-20",
    "closeData": "",
    "isin": "INF903J01NF9",
    "isinRe": "INF903J01NG7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119595",
    "name": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Consumption Fund (Formerly Known as Sundaram Rural and Consumption Fund Direct Plan - Growth)",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-03-20",
    "closeData": "",
    "isin": "INF903J01NH5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103764",
    "name": "SBI Debt Fund Series-90 Days-1-Dividend",
    "category": "Income",
    "navName": "SBI Debt Fund Series-90 Days-1-Dividend",
    "minAmount": "1000",
    "launchDate": "2006-05-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103800",
    "name": "SBI Debt Fund Series-90 Days-1-Growth",
    "category": "Income",
    "navName": "SBI Debt Fund Series-90 Days-1-Growth",
    "minAmount": "1000",
    "launchDate": "2006-05-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "103780",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01510",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "103781",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - IDCW ",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01494",
    "isinRe": "INF192K01502"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120481",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct)- IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01BL0",
    "isinRe": "INF192K01BM8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120482",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01BN6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "130603",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01GJ3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "130604",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01GL9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148854",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Bonus Option - Bonus Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01GK1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135172",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Monthly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KM9",
    "isinRe": "INF192K01KN7"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135173",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KY4",
    "isinRe": "INF192K01KZ1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135174",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KQ0",
    "isinRe": "INF192K01KR8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133399",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01IB6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135167",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Annual IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KW8",
    "isinRe": "INF192K01KX6"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135168",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Monthly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KK3",
    "isinRe": "INF192K01KL1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135169",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Quarterly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KO5",
    "isinRe": "INF192K01KP2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135170",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Half Yearly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KS6",
    "isinRe": "INF192K01KT4"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "135171",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Half Yearly IDCW",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01KU2",
    "isinRe": "INF192K01KV0"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133215",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Advantage Fund (Direct) - Half Yearly Bonus Option",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133394",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Annual Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01GV8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133395",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Annual Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01GX4",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133396",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Regular) - Quarterly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01IF7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133397",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Half Yearly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01ID2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "133398",
    "name": "JM Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "JM Arbitrage Fund (Direct) - Quarterly Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of any amount thereafter",
    "launchDate": "2006-06-01",
    "closeData": "",
    "isin": "INF192K01IH3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103997",
    "name": "SBI Debt Fund Series-60 Days-1",
    "category": "Income",
    "navName": "SBI Debt Fund Series-60 Days-1-Growth",
    "minAmount": "10000",
    "launchDate": "2006-06-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103998",
    "name": "SBI Debt Fund Series-60 Days-1",
    "category": "Income",
    "navName": "SBI Debt Fund Series-60 Days-1-Dividend",
    "minAmount": "10000",
    "launchDate": "2006-06-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "104032",
    "name": "Kotak FMP 6M Series 1",
    "category": "Income",
    "navName": "Kotak FMP 6M Series 1",
    "minAmount": "5000",
    "launchDate": "2006-06-12",
    "closeData": "2006-06-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "104033",
    "name": "Kotak FMP 6M Series 1",
    "category": "Income",
    "navName": "KOtak FMP 6M Series 1 - Dividend",
    "minAmount": "5000",
    "launchDate": "2006-06-12",
    "closeData": "2006-06-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "104034",
    "name": "Kotak FMP 6M Series 1",
    "category": "Income",
    "navName": "Kotak FMP 6M Series 1 - Growth",
    "minAmount": "5000",
    "launchDate": "2006-06-12",
    "closeData": "2006-06-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104050",
    "name": "SBI Debt Fund Series-13months-1-Growth",
    "category": "Income",
    "navName": "SBI Debt Fund Series-13months-1-Growth",
    "minAmount": "50000",
    "launchDate": "2006-06-19",
    "closeData": "2006-06-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104051",
    "name": "SBI Debt Fund Series-13months-1-Dividend",
    "category": "Income",
    "navName": "SBI Debt Fund Series-13months-1-Dividend",
    "minAmount": "50000",
    "launchDate": "2006-06-19",
    "closeData": "2006-06-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "104052",
    "name": "Tata Fixed Horizon Fund Series 5 Scheme C - Div",
    "category": "Income",
    "navName": "Scheme C - Div",
    "minAmount": "Rs.10000/-",
    "launchDate": "2006-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "104074",
    "name": "UTI - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "UTI Arbitrage Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-06-09",
    "closeData": "2006-06-22",
    "isin": "INF789FB1RH4",
    "isinRe": "INF789FB1RI2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "104075",
    "name": "UTI - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "UTI Arbitrage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-06-09",
    "closeData": "2006-06-22",
    "isin": "INF789FB1RJ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120795",
    "name": "UTI - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "UTI Arbitrage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-06-09",
    "closeData": "2006-06-22",
    "isin": "INF789FB1RG6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120796",
    "name": "UTI - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "UTI Arbitrage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-06-09",
    "closeData": "2006-06-22",
    "isin": "INF789FB1RE1",
    "isinRe": "INF789FB1RF8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104138",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01QQ3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104139",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - IDCW - Weekly Reinvest",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104140",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - IDCW - Daily Reinvest",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740KA1NW4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117063",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01QS9",
    "isinRe": "INF740K01QR1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117994",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - IDCW - Reinvest",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01ML3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117995",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Regular Plan - IDCW - Payout",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01MK5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119203",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740KA1NT0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119204",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01OO3",
    "isinRe": "INF740K01OP0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119205",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01ON5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119206",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740K01OQ8",
    "isinRe": "INF740K01OR6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119207",
    "name": "DSP Ultra Short Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DSP Ultra Short Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-07-24",
    "closeData": "2006-07-31",
    "isin": "INF740KA1NU8",
    "isinRe": "INF740KA1NV6"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "104240",
    "name": "Taurus Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Taurus Liquid Fund-Dividend",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2006-08-28",
    "closeData": "",
    "isin": "INF044D01542",
    "isinRe": "INF044D01559"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "104241",
    "name": "Taurus Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Taurus Liquid Fund-Growth",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2006-08-28",
    "closeData": "",
    "isin": "INF044D01575",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "104271",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - Growth option",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AQ1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "104272",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - Daily Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AN8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "106101",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - Fortnightly Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AP3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "106104",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - Weekly Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AO6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120454",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - (Direct) -Daily Dividend Option",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DL6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120455",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - (Direct) -Weekly Dividend Option",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DM4",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120457",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund (Direct) - Fortnightly Dividend Option",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DN2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120458",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - (Direct) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DO0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "122316",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund (Direct) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FD8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "122317",
    "name": "JM Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "JM Ultra Short Duration Fund - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re. 1/ thereafter",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FB2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104481",
    "name": "DSP Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "DSP Midcap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-09-29",
    "closeData": "2006-10-18",
    "isin": "INF740K01128",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104482",
    "name": "DSP Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "DSP Midcap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-09-29",
    "closeData": "2006-10-18",
    "isin": "INF740K01102",
    "isinRe": "INF740K01110"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119070",
    "name": "DSP Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "DSP Midcap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-09-29",
    "closeData": "2006-10-18",
    "isin": "INF740K01PY9",
    "isinRe": "INF740K01PZ6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119071",
    "name": "DSP Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "DSP Midcap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2006-09-29",
    "closeData": "2006-10-18",
    "isin": "INF740K01PX1",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104484",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HN3",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104485",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HO1",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104486",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HM5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104488",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Regular - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HG7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104489",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Regular - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HH5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104492",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01HQ6",
    "isinRe": "INF205K01HP8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "139388",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Unclaimed Redemption Plan - Above 3 Years",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "139389",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Unclaimed Dividend Plan - Below 3 Years",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "139390",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Unclaimed Dividend Plan - Above 3 Years",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120534",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Direct Plan- Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01ME2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120535",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Direct Plan - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01MI3",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120536",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01MG7",
    "isinRe": "INF205K01MH5"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120537",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01MF9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "122786",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Bonus Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": "INF205K01UX5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "139387",
    "name": "Invesco India Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Invesco India Liquid Fund - Unclaimed Redemption Plan - Below 3 years",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2006-11-13",
    "closeData": "2006-11-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "104513",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01341",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "104514",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01325",
    "isinRe": "INF966L01333"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "104515",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - Bonus Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01424",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120824",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - Bonus Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01655",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120825",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01622",
    "isinRe": "INF966L01630"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120826",
    "name": "Quant Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "quant Large & Mid Cap Fund - Growth Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-11",
    "isin": "INF966L01648",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104635",
    "name": "Invesco India Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "Invesco India Tax Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "",
    "isin": "INF205K01296",
    "isinRe": "INF205K01288"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104636",
    "name": "Invesco India Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "Invesco India Tax Plan - Growth",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "",
    "isin": "INF205K01270",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120416",
    "name": "Invesco India Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "Invesco India Tax Plan - Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "",
    "isin": "INF205K01NT8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120417",
    "name": "Invesco India Tax Plan",
    "category": "Equity Scheme - ELSS",
    "navName": "Invesco India Tax Plan - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "",
    "isin": "INF205K01NR2",
    "isinRe": "INF205K01NS0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "104637",
    "name": "Nippon India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Nippon India Focused Equity Fund -Growth Plan -Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2006-11-14",
    "closeData": "2006-11-28",
    "isin": "INF204K01GE7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "104638",
    "name": "Nippon India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "NIPPON INDIA FOCUSED EQUITY FUND - IDCW OPTION",
    "minAmount": "5000/-",
    "launchDate": "2006-11-14",
    "closeData": "2006-11-28",
    "isin": "INF204K01GF4",
    "isinRe": "INF204K01GG2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118692",
    "name": "Nippon India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Nippon India Focused Equity Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2006-11-14",
    "closeData": "2006-11-28",
    "isin": "INF204K01F95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118693",
    "name": "Nippon India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "NIPPON INDIA FOCUSED EQUITY FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2006-11-14",
    "closeData": "2006-11-28",
    "isin": "INF204K01F79",
    "isinRe": "INF204K01F87"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104681",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K01EF0",
    "isinRe": "INF109K01BE9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104683",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K01BF6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104684",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity - Arbitrage Fund-Institutional Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K01BD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120364",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K016O4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120365",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K017O2",
    "isinRe": "INF109K015O6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130898",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - Bonus",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109KA1C15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130951",
    "name": "ICICI Prudential Equity - Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ICICI Prudential Equity Arbitrage Fund - Direct Plan Bonus",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109KA1C07",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104685",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K01BH2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "104686",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K01EG8",
    "isinRe": "INF109K01BG4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120376",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Direct Plan -  IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K010B4",
    "isinRe": "INF109K011B2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120377",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Direct Plan -  Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K012B0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122168",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Monthly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K018W3",
    "isinRe": "INF109K017W5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122236",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109K016W7",
    "isinRe": "INF109K015W9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131450",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Quarterly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109KA1Y35",
    "isinRe": "INF109KA1Y27"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131451",
    "name": "ICICI Prudential Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ICICI Prudential Balanced Advantage Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-11-08",
    "closeData": "",
    "isin": "INF109KA1Y50",
    "isinRe": "INF109KA1Y43"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "104706",
    "name": "HSBC Tax Saver Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC Tax Saver Equity Fund - IDCW",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-19",
    "isin": "INF336L01AZ3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "104707",
    "name": "HSBC Tax Saver Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC Tax Saver Equity Fund - Growth",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-19",
    "isin": "INF336L01BA4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120078",
    "name": "HSBC Tax Saver Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC Tax Saver Equity Fund - IDCW Direct Plan",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-19",
    "isin": "INF336L01ER2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120079",
    "name": "HSBC Tax Saver Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC Tax Saver Equity Fund - Growth Direct",
    "minAmount": "500",
    "launchDate": "2006-11-20",
    "closeData": "2006-12-19",
    "isin": "INF336L01EE0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104722",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Regular - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HV6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104723",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Regular - Monthly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HW4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104725",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Weekly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01IB6",
    "isinRe": "INF205K01IA8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104726",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HY0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104728",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Regular - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HT0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120568",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan - Weekly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01OB4",
    "isinRe": "INF205K01OC2"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120569",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01NU6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120570",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01NY8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120571",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01NZ5",
    "isinRe": "INF205K01OA6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "104729",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01ID2",
    "isinRe": "INF205K01IC4"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105024",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Regular Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HU8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105025",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01HZ7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "115458",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01IG5",
    "isinRe": "INF205K01IF7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120566",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan -Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01NV4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120567",
    "name": "Invesco India Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Invesco India Treasury Advantage Fund - Direct Plan - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/",
    "launchDate": "2007-01-15",
    "closeData": "2007-01-17",
    "isin": "INF205K01NW2",
    "isinRe": "INF205K01NX0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104762",
    "name": "Z-BEFORE MERGER-SBI ONE INDIA FUND - GROWTH (PREVIOUSLY CLOSE ENDED UPTO 14/01/2010)",
    "category": "Growth",
    "navName": "SBI ONE INDIA FUND - GROWTH (PREVIOUSLY CLOSE ENDED UPTO 14/01/2010)",
    "minAmount": "5000",
    "launchDate": "2006-11-24",
    "closeData": "",
    "isin": "INF200K01CW6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104763",
    "name": "Z-BEFORE MERGER-SBI ONE INDIA FUND - DIVIDEND (PREVIOUSLY CLOSE ENDED UPTO 14/01/2010)",
    "category": "Growth",
    "navName": "SBI ONE INDIA FUND - DIVIDEND (PREVIOUSLY CLOSE ENDED UPTO 14/01/2010)",
    "minAmount": "5000.00",
    "launchDate": "2006-11-24",
    "closeData": "",
    "isin": "INF200K01CX4",
    "isinRe": "INF200K01CY2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104772",
    "name": "DSP Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "DSP Tax Saver Fund - Regular Plan - Growth",
    "minAmount": "500/- and any amount thereafter",
    "launchDate": "2006-11-27",
    "closeData": "2006-12-26",
    "isin": "INF740K01185",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "104773",
    "name": "DSP Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "DSP Tax Saver Fund - Regular Plan - IDCW",
    "minAmount": "500/- and any amount thereafter",
    "launchDate": "2006-11-27",
    "closeData": "2006-12-26",
    "isin": "INF740K01169",
    "isinRe": "INF740K01177"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119241",
    "name": "DSP Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "DSP Tax Saver Fund - Direct Plan - IDCW",
    "minAmount": "500/- and any amount thereafter",
    "launchDate": "2006-11-27",
    "closeData": "2006-12-26",
    "isin": "INF740K01OL9",
    "isinRe": "INF740K01OM7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119242",
    "name": "DSP Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "DSP Tax Saver Fund - Direct Plan - Growth",
    "minAmount": "500/- and any amount thereafter",
    "launchDate": "2006-11-27",
    "closeData": "2006-12-26",
    "isin": "INF740K01OK1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "104788",
    "name": "Templeton Fixed Horizon Fund - Series I - 15 Months Plan",
    "category": "Income",
    "navName": "Templeton Fixed Horizon Fund - Series I - Institutional Plan - Dividend",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Cr",
    "launchDate": "2007-01-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "104907",
    "name": "Kotak Emerging Equity Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Kotak Emerging Equity Scheme - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2007-02-12",
    "closeData": "2007-03-12",
    "isin": "INF174K01DU5",
    "isinRe": "INF174K01DT7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "104908",
    "name": "Kotak Emerging Equity Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Kotak Emerging Equity Scheme - Growth",
    "minAmount": "5000",
    "launchDate": "2007-02-12",
    "closeData": "2007-03-12",
    "isin": "INF174K01DS9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119774",
    "name": "Kotak Emerging Equity Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Kotak Emerging Equity Scheme - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2007-02-12",
    "closeData": "2007-03-12",
    "isin": "INF174K01LU8",
    "isinRe": "INF174K01LV6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119775",
    "name": "Kotak Emerging Equity Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Kotak Emerging Equity Scheme - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2007-02-12",
    "closeData": "2007-03-12",
    "isin": "INF174K01LT0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "105000",
    "name": "Sundaram Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Sundaram Large and Midcap Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-01-10",
    "closeData": "2007-01-31",
    "isin": "INF903J01DU9",
    "isinRe": "INF903J01DV7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "105001",
    "name": "Sundaram Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Sundaram Large and Midcap Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2007-01-10",
    "closeData": "2007-01-31",
    "isin": "INF903J01DT1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119565",
    "name": "Sundaram Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Sundaram Large and Midcap Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-01-10",
    "closeData": "2007-01-31",
    "isin": "INF903J01PP3",
    "isinRe": "INF903J01PQ1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119566",
    "name": "Sundaram Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Sundaram Large and Midcap Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2007-01-10",
    "closeData": "2007-01-31",
    "isin": "INF903J01PR9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "105004",
    "name": "Kotak FMP 14M Series 1",
    "category": "Income",
    "navName": "Kotak FMP 14M Series 1 Institutional Plan - Dividend",
    "minAmount": "10000000",
    "launchDate": "2007-02-09",
    "closeData": "2007-02-19",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105030",
    "name": "Templeton Fixed Horizon Fund - Series I - 13 Months Plan",
    "category": "Income",
    "navName": "Templeton Fixed Horizon Fund - Series I - 13 Months - Institutional Plan - Dividend",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2007-03-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119830",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND - DAILY DIVIDEND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI MAGNUM INSTA CASH FUND - DIRECT PLAN - DAILY DIVIDEND -Deleted",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105185",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UN6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105187",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Plan B - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01US5",
    "isinRe": "INF205K01UT3"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105188",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Lotus India Short Term Plan - Institutional - Monthly Dividend Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105189",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Plan B - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UR7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105190",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UV9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105191",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UO4",
    "isinRe": "INF205K01UP1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120562",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Direct Plan -Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UK2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120563",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Direct Plan -Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UF2",
    "isinRe": "INF205K01UG0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105192",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Plan B - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UU1",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116110",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund- Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UL0",
    "isinRe": "INF205K01UM8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "117973",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UD7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120559",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UE5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120560",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UH8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120561",
    "name": "Invesco India Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Invesco India Short Term Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1",
    "launchDate": "2007-03-21",
    "closeData": "2007-03-22",
    "isin": "INF205K01UI6",
    "isinRe": "INF205K01UJ4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105229",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-I",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Monthly Interval Fund-Series-I- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-14",
    "closeData": "2007-03-15",
    "isin": "INF204K01CV0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105230",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-I",
    "category": "Income",
    "navName": "NIPPON INDIA MONTHLY INTERVAL FUND - SERIES I - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-14",
    "closeData": "2007-03-15",
    "isin": "INF204K01CW8",
    "isinRe": "INF204K01CX6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105232",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-I",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Monthly Interval Fund-Series-I-Institutional Plan-Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-14",
    "closeData": "2007-03-15",
    "isin": "INF204K01CZ1",
    "isinRe": "INF204K01DA2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118596",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-I",
    "category": "Income",
    "navName": "NIPPON INDIA MONTHLY INTERVAL FUND - SERIES I - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-14",
    "closeData": "2007-03-15",
    "isin": "INF204K01ZB3",
    "isinRe": "INF204K01ZC1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118598",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-I",
    "category": "Income",
    "navName": "Nippon India Monthly Interval Fund - Series I - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-03-14",
    "closeData": "2007-03-15",
    "isin": "INF204K01ZA5-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105400",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Monthly Interval Fund-Series-II -Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-28",
    "closeData": "2007-03-29",
    "isin": "INF204K01DB0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105401",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-",
    "category": "Income",
    "navName": "NIPPON INDIA MONTHLY INTERVAL FUND - SERIES II - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-28",
    "closeData": "2007-03-29",
    "isin": "INF204K01DC8",
    "isinRe": "INF204K01DD6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105402",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-Institutional Plan-Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-28",
    "closeData": "2007-03-29",
    "isin": "INF204K01DF1",
    "isinRe": "INF204K01DG9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118594",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-",
    "category": "Income",
    "navName": "NIPPON INDIA MONTHLY INTERVAL FUND - SERIES II - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-03-28",
    "closeData": "2007-03-29",
    "isin": "INF204K01ZE7",
    "isinRe": "INF204K01ZF4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118595",
    "name": "Nippon India Interval Fund-Monthly Interval Fund-Series-II-",
    "category": "Income",
    "navName": "Nippon India Monthly Interval Fund - Series II - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-03-28",
    "closeData": "2007-03-29",
    "isin": "INF204K01ZD9-",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "105417",
    "name": "Taurus Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Infrastructure Fund - Regular Plan - Growth",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2007-02-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01906",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "105418",
    "name": "Taurus Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Infrastructure Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2007-02-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01872",
    "isinRe": "INF044D01880"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118879",
    "name": "Taurus Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Infrastructure Fund - Direct Plan - Growth ",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2007-02-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01CD3",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118880",
    "name": "Taurus Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Infrastructure Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2007-02-05",
    "closeData": "2007-03-05",
    "isin": "INF044D01CE1",
    "isinRe": "INF044D01CF8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105433",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I-",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-03",
    "closeData": "2007-04-03",
    "isin": "INF204K01DH7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105434",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I-",
    "category": "Income",
    "navName": "NIPPON INDIA INTERVAL FUND - QUARTERLY PLAN - SERIES I - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-03",
    "closeData": "2007-04-03",
    "isin": "INF204K01DI5",
    "isinRe": "INF204K01DJ3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105436",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I-",
    "category": "Income",
    "navName": "NIPPON INDIA INTERVAL FUND - QUARTERLY PLAN - SERIES I - INSTITUTIONAL IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-03",
    "closeData": "2007-04-03",
    "isin": "INF204K01DL9",
    "isinRe": "INF204K01DM7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118621",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I-",
    "category": "Income",
    "navName": "NIPPON INDIA INTERVAL FUND - QUARTERLY PLAN - SERIES I - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-03",
    "closeData": "2007-04-03",
    "isin": "INF204K01XU8",
    "isinRe": "INF204K01XV6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118623",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-I-",
    "category": "Income",
    "navName": "Nippon India Interval Fund - Quarterly Plan - Series I - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-04-03",
    "closeData": "2007-04-03",
    "isin": "INF204K01XT0-",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105459",
    "name": "Invesco India Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Invesco India Contra Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01205",
    "isinRe": "INF205K01197"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105460",
    "name": "Invesco India Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Invesco India Contra Fund - Growth",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01189",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120348",
    "name": "Invesco India Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Invesco India Contra Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01LE4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120349",
    "name": "Invesco India Contra Fund",
    "category": "Equity Scheme - Contra Fund",
    "navName": "Invesco India Contra Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01LC8",
    "isinRe": "INF205K01LD6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "105463",
    "name": "UTI - Gold Exchange Traded Fund",
    "category": "Other Scheme - Gold ETF",
    "navName": "UTI GOLD Exchange Traded Fund",
    "minAmount": "20000",
    "launchDate": "2007-03-01",
    "closeData": "",
    "isin": "INF789F1AUX7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105503",
    "name": "Invesco India Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Invesco India Midcap Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01BC9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105504",
    "name": "Invesco India Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Invesco India Midcap Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01BD7",
    "isinRe": "INF205K01BE5"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120402",
    "name": "Invesco India Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Invesco India Midcap Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01MT0",
    "isinRe": "INF205K01MU8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120403",
    "name": "Invesco India Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Invesco India Midcap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-02-15",
    "closeData": "2007-03-15",
    "isin": "INF205K01MV6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105603",
    "name": "Invesco India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Invesco India Arbitrage Fund - Growth Option",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2007-04-10",
    "closeData": "2007-04-20",
    "isin": "INF205K01122",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "105604",
    "name": "Invesco India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Invesco India Arbitrage Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2007-04-10",
    "closeData": "2007-04-20",
    "isin": "INF205K01148",
    "isinRe": "INF205K01130"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120400",
    "name": "Invesco India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Invesco India Arbitrage Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2007-04-10",
    "closeData": "2007-04-20",
    "isin": "INF205K01KP2",
    "isinRe": "INF205K01KQ0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120401",
    "name": "Invesco India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Invesco India Arbitrage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2007-04-10",
    "closeData": "2007-04-20",
    "isin": "INF205K01KR8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130787",
    "name": "Invesco India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Invesco India Arbitrage Fund - Direct Plan - Annual Bonus",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2007-04-10",
    "closeData": "2007-04-20",
    "isin": "INF205K01P68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105650",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Monthly Interval Plan I - Retail Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01ON3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105651",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Monthly Interval Plan I - Retail Growth",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01OM5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109679",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Monthly Interval Plan I - Growth",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01OJ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109680",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Monthly Interval Plan I - Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01OL7",
    "isinRe": "INF109K01OK9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120635",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Monthly Interval Plan I - Direct Plan -  Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01L36",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120636",
    "name": "ICICI Prudential Interval Fund - Monthly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Monthly Interval Plan I - Direct Plan -  Growth",
    "minAmount": "Retail Opt Rs.5000/-; Inst Opt  Rs 2 Crores",
    "launchDate": "2007-05-08",
    "closeData": "",
    "isin": "INF109K01L28",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105660",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "NIPPON INDIA ANNUAL INTERVAL FUND - SERIES I - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01ED4",
    "isinRe": "INF204K01EE2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105661",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "Nippon India Interval Fund Annual Interval Fund Series-I-Retail Plan Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01DZ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105662",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "NIPPON INDIA ANNUAL INTERVAL FUND - SERIES I - RETAIL Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01EA0",
    "isinRe": "INF204K01EB8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105663",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "Nippon India Interval Fund Annual Interval Fund Series-I- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01EC6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118804",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "Nippon India Annual Interval Fund - Series I - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01B81-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118805",
    "name": "Nippon India Interval Fund Annual Interval Fund Series-I",
    "category": "Income",
    "navName": "NIPPON INDIA ANNUAL INTERVAL FUND - SERIES I - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-04-30",
    "closeData": "2007-05-03",
    "isin": "INF204K01B99",
    "isinRe": "INF204K01C07"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105667",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01GM3",
    "isinRe": "INF740K01GL5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105668",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01GO9",
    "isinRe": "INF740K01GN1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105669",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01GK7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105878",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01GQ4",
    "isinRe": "INF740K01GP6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "111786",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740KA1PA5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119236",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01QH2",
    "isinRe": "INF740K01QJ8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119237",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740KA1OY8",
    "isinRe": "INF740KA1OZ5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119238",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01QI0",
    "isinRe": "INF740K01QK6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119239",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740K01QG4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119240",
    "name": "DSP Strategic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "DSP Strategic Bond Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-04-23",
    "closeData": "2007-05-03",
    "isin": "INF740KA1OX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105689",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Quarterly Interval Fund Series-II-Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-07",
    "isin": "INF204K01DN5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105691",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II-Institutional Plan -Dividend Option",
    "minAmount": "5000/-",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-07",
    "isin": "INF204K01DR6",
    "isinRe": "INF204K01DS4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105692",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II",
    "category": "Income",
    "navName": "NIPPON INDIA QUARTERLY INTERVAL FUND - SERIES II - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-07",
    "isin": "INF204K01DO3",
    "isinRe": "INF204K01DP0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118689",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II",
    "category": "Income",
    "navName": "NIPPON INDIA QUARTERLY INTERVAL FUND - SERIES II - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-07",
    "isin": "INF204K01F46",
    "isinRe": "INF204K01F53"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118691",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund Serie-II",
    "category": "Income",
    "navName": "Nippon India Quarterly Interval Fund - Series II - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-07",
    "isin": "INF204K01F61-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105710",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan I - Retail Growth",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01LT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105711",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan I - Retail Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01LU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111405",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan 1 - Growth",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01LP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111406",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan 1 -Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01LR0",
    "isinRe": "INF109K01LQ2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115763",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan - 1 Retail Quarterly Dividend Payout",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01LW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120637",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan 1 - Direct Plan - Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01L77",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120638",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan 1 - Direct Plan -  Growth",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01L69",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124214",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan 1 Plan - Direct Plan - Quarterly Dividend Payout",
    "minAmount": "Retail Rs. 5000/-; Insti  Rs 25 Lacs",
    "launchDate": "2007-05-10",
    "closeData": "",
    "isin": "INF109K01N75",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "105749",
    "name": "Kotak FMP 15M Series 3",
    "category": "Income",
    "navName": "Kotak FMP 15M Series 3 Institutional Plan - Dividend",
    "minAmount": "10000000",
    "launchDate": "2007-05-07",
    "closeData": "2007-05-17",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105757",
    "name": "HDFC Mid-Cap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HDFC Mid-Cap Opportunities Fund - IDCW Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2007-05-07",
    "closeData": "2007-06-08",
    "isin": "INF179K01CS0",
    "isinRe": "INF179K01CT8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "105758",
    "name": "HDFC Mid-Cap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HDFC Mid-Cap Opportunities Fund - Growth Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2007-05-07",
    "closeData": "2007-06-08",
    "isin": "INF179K01CR2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118988",
    "name": "HDFC Mid-Cap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HDFC Mid-Cap Opportunities Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2007-05-07",
    "closeData": "2007-06-08",
    "isin": "INF179K01XO5",
    "isinRe": "INF179K01XP2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118989",
    "name": "HDFC Mid-Cap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HDFC Mid-Cap Opportunities Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2007-05-07",
    "closeData": "2007-06-08",
    "isin": "INF179K01XQ0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105773",
    "name": "Templeton Fixed Horizon Fund - Series II - Plan A",
    "category": "Income",
    "navName": "Templeton Fixed Horizon Fund - Series II - Plan A - Institutional - Dividend",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2007-05-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "105804",
    "name": "Aditya Birla Sun Life Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Aditya Birla Sun Life Small Cap Fund - GROWTH",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-04-09",
    "closeData": "2007-04-15",
    "isin": "INF209K01EN2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "105805",
    "name": "Aditya Birla Sun Life Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Aditya Birla Sun Life Small Cap Fund -Regular - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-04-09",
    "closeData": "2007-04-15",
    "isin": "INF209K01EO0",
    "isinRe": "INF209K01EP7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119556",
    "name": "Aditya Birla Sun Life Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Aditya Birla Sun Life Small Cap Fund - Growth - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-04-09",
    "closeData": "2007-04-15",
    "isin": "INF209K01WN4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119557",
    "name": "Aditya Birla Sun Life Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Aditya Birla Sun Life Small Cap Fund -Direct - IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-04-09",
    "closeData": "2007-04-15",
    "isin": "INF209K01WM6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105816",
    "name": "Franklin India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Franklin India Focused Equity Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2007-05-31",
    "closeData": "",
    "isin": "INF090I01965",
    "isinRe": "INF090I01973"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105817",
    "name": "Franklin India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Franklin India Focused Equity Fund - Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "2007-05-31",
    "closeData": "",
    "isin": "INF090I01981",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118563",
    "name": "Franklin India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Franklin India Focused Equity Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2007-05-31",
    "closeData": "",
    "isin": "INF090I01IU6",
    "isinRe": "INF090I01IV4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118564",
    "name": "Franklin India Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Franklin India Focused Equity Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2007-05-31",
    "closeData": "",
    "isin": "INF090I01IW2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "105822",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Regular Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01519",
    "isinRe": "INF767K01527"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "105823",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01535",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "105856",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Regular Plan-Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01543",
    "isinRe": "INF767K01550"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "105857",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Regular Plan-Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01568",
    "isinRe": "INF767K01576"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "115202",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC NOMURA  MF INCOME PLUS FUND",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120336",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Direct Plan-Weekly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01GL8",
    "isinRe": "INF767K01GO2"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120337",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Direct Plan-Daily IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01GJ2",
    "isinRe": "INF767K01GM6"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120338",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01GI4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120339",
    "name": "LIC MF Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "LIC MF Banking & PSU Debt Fund-Direct Plan-Monthly IDCW",
    "minAmount": "5000/-",
    "launchDate": "2007-05-28",
    "closeData": "2007-05-30",
    "isin": "INF767K01GK0",
    "isinRe": "INF767K01GN4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105882",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III-Institutional Plan-Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01DW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105883",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "NIPPON INDIA QUARTERLY INTERVAL FUND - SERIES III - INSTITUTIONAL IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01DX4",
    "isinRe": "INF204K01DY2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105884",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01DT2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "105885",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "NIPPON INDIA QUARTERLY INTERVAL FUND - SERIES III - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01DU0",
    "isinRe": "INF204K01DV8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118583",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "Nippon India Quarterly Interval Fund - Series III - Direct Plan Growth Plan - Growth",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01XW4-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118584",
    "name": "Nippon India Interval Fund-Quarterly Interval Fund-Series-III",
    "category": "Income",
    "navName": "NIPPON INDIA QUARTERLY INTERVAL FUND - SERIES III - Direct Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-06",
    "closeData": "2007-06-06",
    "isin": "INF204K01XX2",
    "isinRe": "INF204K01XY0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105907",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan II - Retail Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01MC0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "105908",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan II - Retail Growth",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01MB2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110203",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval - Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01LZ3",
    "isinRe": "INF109K01LY6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110204",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval - Growth",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01LX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120639",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval - Direct Plan -  Growth",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01L85",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120640",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval - Direct Plan -  Dividend",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2007-06-12",
    "closeData": "",
    "isin": "INF109K01L93",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "105964",
    "name": "Templeton Fixed Horizon Fund - Series II - Plan B",
    "category": "Income",
    "navName": "Templeton Fixed Horizon Fund - Series II - Plan B - Institutional - Dividend",
    "minAmount": "Retail  Rs.5000; Institutional   Rs.1 Crore",
    "launchDate": "2007-06-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "105967",
    "name": "Kotak Equity Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Kotak Equity Arbitrage Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2005-09-12",
    "closeData": "",
    "isin": "INF174K01328",
    "isinRe": "INF174K01310"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "105968",
    "name": "Kotak Equity Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Kotak Equity Arbitrage Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2005-09-12",
    "closeData": "",
    "isin": "INF174K01302",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119770",
    "name": "Kotak Equity Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Kotak Equity Arbitrage Fund - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2005-09-12",
    "closeData": "",
    "isin": "INF174K01LE2",
    "isinRe": "INF174K01LD4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119771",
    "name": "Kotak Equity Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Kotak Equity Arbitrage Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2005-09-12",
    "closeData": "",
    "isin": "INF174K01LC6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "105989",
    "name": "DSP Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "DSP Small Cap Fund - Regular - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-25",
    "isin": "INF740K01797",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "113153",
    "name": "DSP Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "DSP Small Cap Fund - Regular - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-25",
    "isin": "INF740K01805",
    "isinRe": "INF740K01AM6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119212",
    "name": "DSP Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "DSP Small Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-25",
    "isin": "INF740K01QD1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119213",
    "name": "DSP Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "DSP Small Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2007-05-04",
    "closeData": "2007-05-25",
    "isin": "INF740K01QE9",
    "isinRe": "INF740K01QF6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106057",
    "name": "UTI - FIIF Quarterly Interval Plan I",
    "category": "Income",
    "navName": "UTI FIIF Quarterly Interval Plan I - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-06-14",
    "closeData": "",
    "isin": "INF789F01GC1",
    "isinRe": "INF789F01GD9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106060",
    "name": "UTI - FIIF Quarterly Interval Plan I",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Quarterly Interval Plan - I) - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-06-14",
    "closeData": "",
    "isin": "INF789F01GE7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "122152",
    "name": "UTI - FIIF Quarterly Interval Plan I",
    "category": "Income",
    "navName": "UTI FIIF Quarterly Interval Plan I - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-06-14",
    "closeData": "",
    "isin": "INF789F01WG9",
    "isinRe": "INF789F01WH7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "122165",
    "name": "UTI - FIIF Quarterly Interval Plan I",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Quarterly Interval Plan - I) -Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-06-14",
    "closeData": "",
    "isin": "INF789F01WI5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106141",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan III - Retail Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01MK3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106142",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan III - Retail Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01MJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120654",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund III Quarterly Interval - Direct Plan -  Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01M19",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120655",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund III Quarterly Interval - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01M01",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127399",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Quarterly Interval Plan III - Direct Quarterly Dividend Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01N91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110555",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund III Quarterly Interval - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01MF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110556",
    "name": "ICICI Prudential Interval Fund - Quarterly Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund III Quarterly Interval - Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-12",
    "closeData": "",
    "isin": "INF109K01MH9",
    "isinRe": "INF109K01MG1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106143",
    "name": "Invesco India Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Invesco India Growth Opportunities Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2007-07-09",
    "closeData": "2007-07-19",
    "isin": "INF205K01262",
    "isinRe": "INF205K01254"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106144",
    "name": "Invesco India Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Invesco India Growth Opportunities Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2007-07-09",
    "closeData": "2007-07-19",
    "isin": "INF205K01247",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120356",
    "name": "Invesco India Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Invesco India Growth Opportunities Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2007-07-09",
    "closeData": "2007-07-19",
    "isin": "INF205K01LY2",
    "isinRe": "INF205K01LZ9"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120357",
    "name": "Invesco India Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Invesco India Growth Opportunities Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2007-07-09",
    "closeData": "2007-07-19",
    "isin": "INF205K01MA0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106145",
    "name": "UTI - FIIF Monthly Interval Plan I",
    "category": "Income",
    "navName": "UTI - Fixed Income Interval Fund - Monthly Interval Plan  - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-06-20",
    "closeData": "",
    "isin": "INF789F01EU8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106146",
    "name": "UTI - FIIF Monthly Interval Plan I",
    "category": "Income",
    "navName": "UTI FIIF Monthly Interval Plan I - Regular Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2007-06-20",
    "closeData": "",
    "isin": "INF789F01ES2",
    "isinRe": "INF789F01ET0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106549",
    "name": "UTI - FIIF Monthly Interval Plan I",
    "category": "Income",
    "navName": "UTI - Fixed Income Interval Fund - Monthly Interval Plan - Institutional Dividend Option",
    "minAmount": "10000",
    "launchDate": "2007-06-20",
    "closeData": "",
    "isin": "INF789F01EV6",
    "isinRe": "INF789F01EW4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120970",
    "name": "UTI - FIIF Monthly Interval Plan I",
    "category": "Income",
    "navName": "UTI - Fixed Income Interval Fund - Monthly Interval Plan  - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-06-20",
    "closeData": "",
    "isin": "INF789F01VQ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120975",
    "name": "UTI - FIIF Monthly Interval Plan I",
    "category": "Income",
    "navName": "UTI FIIF Monthly Interval Plan I - Direct Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2007-06-20",
    "closeData": "",
    "isin": "INF789F01VO5",
    "isinRe": "INF789F01VP2"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "106169",
    "name": "Quant Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Infrastructure Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-26",
    "closeData": "2007-08-24",
    "isin": "INF966L01507",
    "isinRe": "INF966L01515"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "106170",
    "name": "Quant Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Infrastructure Fund - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-26",
    "closeData": "2007-08-24",
    "isin": "INF966L01523",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120832",
    "name": "Quant Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Infrastructure Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-26",
    "closeData": "2007-08-24",
    "isin": "INF966L01705",
    "isinRe": "INF966L01713"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120833",
    "name": "Quant Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Infrastructure Fund - Growth Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-07-26",
    "closeData": "2007-08-24",
    "isin": "INF966L01721",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106171",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RN2",
    "isinRe": "INF205K01RO0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106172",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RP7",
    "isinRe": "INF205K01RQ5"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106177",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Growth",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RM4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106179",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01QX3",
    "isinRe": "INF205K01QY1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116109",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RK8",
    "isinRe": "INF205K01RL6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120496",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RG6",
    "isinRe": "INF205K01RH4"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120497",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Direct Plan - Growth",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RF8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120499",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Direct Plan - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RD3",
    "isinRe": "INF205K01RE1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120500",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Direct Plan - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RA9",
    "isinRe": "INF205K01RB7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120501",
    "name": "Invesco India Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Invesco India Corporate Bond Fund - Direct Plan - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- and in multiples of Re.1/-",
    "launchDate": "2007-07-27",
    "closeData": "2007-07-30",
    "isin": "INF205K01RI2",
    "isinRe": "INF205K01RJ0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106183",
    "name": "UTI - FIIF Annual Intervl Plan I",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Annual Interval Plan Series - I - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-07-11",
    "closeData": "",
    "isin": "INF789F01FG4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106185",
    "name": "UTI - FIIF Annual Intervl Plan I",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Annual Interval Plan Series - I ( Instn Growth Option )",
    "minAmount": "10000",
    "launchDate": "2007-07-11",
    "closeData": "",
    "isin": "INF789F01FJ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106186",
    "name": "UTI - FIIF Annual Intervl Plan I",
    "category": "Income",
    "navName": "UTI FIIF Annual Intervl Plan I - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-07-11",
    "closeData": "",
    "isin": "INF789F01FE9",
    "isinRe": "INF789F01FF6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "123646",
    "name": "UTI - FIIF Annual Intervl Plan I",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Annual Interval Plan Series - I - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-07-11",
    "closeData": "",
    "isin": "INF789F01VW8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "123663",
    "name": "UTI - FIIF Annual Intervl Plan I",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Annual Interval Plan Series - I - Direct Plan - Dividend Option",
    "minAmount": "10000",
    "launchDate": "2007-07-11",
    "closeData": "",
    "isin": "INF789F01VU2",
    "isinRe": "INF789F01VV0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "106193",
    "name": "KOTAK GOLD ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "KOTAK GOLD ETF",
    "minAmount": "5000",
    "launchDate": "2007-06-20",
    "closeData": "2007-07-04",
    "isin": "INF174KA1HJ",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106208",
    "name": "SBI SHORT TERM DEBT FUND - GROWTH",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN INSTTUTIONAL  GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106209",
    "name": "SBI SHORT TERM DEBT FUND - WEEKLY DIVIDEND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN INSTITUTIONAL  DIVIDEND WEEKLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106211",
    "name": "SBI SHORT TERM DEBT FUND - MONTHLY DIVIDEND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN INSTITUTIONAL  DIVIDEND MONTHLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119814",
    "name": "SBI SHORT TERM DEBT FUND - MONTHLY DIVIDEND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Ultra Short Term Fund - Institutional Plan - DIRECT PLAN - Monthly Dividend -Deleted",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106235",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Nippon India Large Cap  Fund- Growth Plan -Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01562",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106236",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "NIPPON INDIA LARGE CAP FUND - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01539",
    "isinRe": "INF204K01547"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106238",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Nippon India Large Cap Fund-Institutional Plan Growth Plan Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01513",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "106240",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Nippon India Large Cap Fund- Growth Plan Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01554",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118632",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Nippon India Large Cap Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01XI3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118633",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Nippon India Large Cap Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01D30",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118634",
    "name": "Nippon India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "NIPPON INDIA LARGE CAP FUND - DIRECT Plan - IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2007-06-12",
    "closeData": "2007-07-10",
    "isin": "INF204K01XG7",
    "isinRe": "INF204K01XH5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106290",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan I - Retail Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109K01KM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106291",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan I -  Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109K01KN1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106292",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan I - Retail Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109K01KL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106293",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan I - Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109K01KO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120625",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan I - Direct Plan -  Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109K01K45",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130958",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - I",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan - I - Direct Plan Bonus",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF109KA1C80",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106316",
    "name": "Invesco India Dynamic Equity Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Invesco India Dynamic Equity Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- Application & in multiples of Re. 1/-",
    "launchDate": "2007-08-16",
    "closeData": "2007-09-07",
    "isin": "INF205K01239",
    "isinRe": "INF205K01221"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106317",
    "name": "Invesco India Dynamic Equity Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Invesco India Dynamic Equity Fund - Growth",
    "minAmount": "Rs. 5000/- Application & in multiples of Re. 1/-",
    "launchDate": "2007-08-16",
    "closeData": "2007-09-07",
    "isin": "INF205K01213",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120332",
    "name": "Invesco India Dynamic Equity Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Invesco India Dynamic Equity Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- Application & in multiples of Re. 1/-",
    "launchDate": "2007-08-16",
    "closeData": "2007-09-07",
    "isin": "INF205K01LL9",
    "isinRe": "INF205K01LM7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120333",
    "name": "Invesco India Dynamic Equity Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Invesco India Dynamic Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- Application & in multiples of Re. 1/-",
    "launchDate": "2007-08-16",
    "closeData": "2007-09-07",
    "isin": "INF205K01LN5",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "106328",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Canliquid Plus- Institutional Plan- Weekly Dividend payout option",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109363",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Canara Robeco Liquid Plus- Institutional Plan- Monthly Div Payout",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109364",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Canara Robeco Liquid Plus- Institutional Plan- Quarterly Div Payout",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109366",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - REGULAR PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01DE5",
    "isinRe": "INF760K01DF2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118317",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01GM1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118318",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01GP4",
    "isinRe": "INF760K01GO7"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118319",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01GN9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "140530",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND- REGULAR PLAN - IDCW (Payout)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01IL9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109368",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Canara Robeco Liquid Plus- Super Institutional Plan- Monthly Div Payout",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109370",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01CA5",
    "isinRe": "INF760K01DD7"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109371",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01DC9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "109372",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - REGULAR PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01DA3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118315",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - DIRECT PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01GL3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118316",
    "name": "Canara Robeco Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "CANARA ROBECO ULTRA SHORT TERM FUND - DIRECT PLAN - WEEKLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2003-08-29",
    "closeData": "2003-08-29",
    "isin": "INF760K01GQ2",
    "isinRe": "INF760K01GR0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106337",
    "name": "UTI - FIIF Annual Interval Plan II",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan II - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-08-06",
    "closeData": "",
    "isin": "INF789F01FK6",
    "isinRe": "INF789F01FL4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106338",
    "name": "UTI - FIIF Annual Interval Plan II",
    "category": "Income",
    "navName": "UTI F I I F-Annual Interval Plan Series - II - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-08-06",
    "closeData": "",
    "isin": "INF789F01FM2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "124595",
    "name": "UTI - FIIF Annual Interval Plan II",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan II - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-08-06",
    "closeData": "",
    "isin": "INF789F01VX6",
    "isinRe": "INF789F01VY4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "124596",
    "name": "UTI - FIIF Annual Interval Plan II",
    "category": "Income",
    "navName": "UTI F I I F-Annual Interval Plan Series - II - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-08-06",
    "closeData": "",
    "isin": "INF789F01VZ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106365",
    "name": "UTI - FIIF Quarterly Interval Plan III",
    "category": "Income",
    "navName": "UTI FIIF Quarterly Interval Plan III - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF789F01GI8",
    "isinRe": "INF789F01GJ6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106366",
    "name": "UTI - FIIF Quarterly Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Quarterly Interval Plan - III) -Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF789F01GK4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106367",
    "name": "UTI - FIIF Quarterly Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Quarterly Interval Plan -III) - Inst Dividend Plan",
    "minAmount": "10000",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF789F01GL2",
    "isinRe": "INF789F01GM0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121412",
    "name": "UTI - FIIF Quarterly Interval Plan III",
    "category": "Income",
    "navName": "UTI FIIF Quarterly Interval Plan III - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF789F01WJ3",
    "isinRe": "INF789F01WK1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121413",
    "name": "UTI - FIIF Quarterly Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Quarterly Interval Plan - III) - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-08-14",
    "closeData": "",
    "isin": "INF789F01WL9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "106369",
    "name": "Sundaram Global Brand Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Sundaram Global Brand Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-07-16",
    "closeData": "2007-07-31",
    "isin": "INF903J01EY9",
    "isinRe": "INF903J01EZ6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "106370",
    "name": "Sundaram Global Brand Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Sundaram Global Brand Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2007-07-16",
    "closeData": "2007-07-31",
    "isin": "INF903J01EX1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119601",
    "name": "Sundaram Global Brand Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Sundaram Global Brand Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-07-16",
    "closeData": "2007-07-31",
    "isin": "INF903J01NZ7",
    "isinRe": "INF903J01OA8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119602",
    "name": "Sundaram Global Brand Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Sundaram Global Brand Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2007-07-16",
    "closeData": "2007-07-31",
    "isin": "INF903J01OB6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106383",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI - Short Term Income Fund - Discontinued Regular Option - Dividend Sub Option",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01AH3",
    "isinRe": "INF789F01AI1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106384",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI - Short Term Income Fund - Discontinued Regular Option -Growth Sub Option",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01AJ9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106624",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI - Short Term Income Fund - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01QA4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106980",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FC1GD5",
    "isinRe": "INF789FA1R58"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120718",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI - Short Term Income Fund - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01XY0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120719",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FA1R66",
    "isinRe": "INF789FA1R74"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134517",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Regular Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FA1Q91",
    "isinRe": "INF789FA1R09"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135497",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Direct Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FA1R33",
    "isinRe": "INF789FA1R41"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "124962",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Direct Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FB1T70",
    "isinRe": "INF789FB1T62"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "125045",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Regular Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FB1T54",
    "isinRe": "INF789FB1T47"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131424",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FA1Q75",
    "isinRe": "INF789FA1Q83"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131425",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI - Short Term Income Fund - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789FA1R17",
    "isinRe": "INF789FA1R25"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131547",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Regular Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01QB2",
    "isinRe": "INF789F01QC0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131548",
    "name": "UTI - Short Term Income Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "UTI Short Term Income Fund - Direct Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2003-06-25",
    "closeData": "",
    "isin": "INF789F01XZ7",
    "isinRe": "INF789F01YA8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106411",
    "name": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series 1 - Regular - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2007-08-17",
    "closeData": "2007-08-28",
    "isin": "INF209K01HO3",
    "isinRe": "INF209K01HP0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106412",
    "name": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series 1 - Growth - Regular Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2007-08-17",
    "closeData": "2007-08-28",
    "isin": "INF209K01HN5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106413",
    "name": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series 1 - institutional - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2007-08-17",
    "closeData": "2007-08-28",
    "isin": "INF209K01HR6",
    "isinRe": "INF209K01HS4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119534",
    "name": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series 1 - Direct - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2007-08-17",
    "closeData": "2007-08-28",
    "isin": "INF209K01YP5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119535",
    "name": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Interval Income Fund - Quarterly Series I - Growth - Direct Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2007-08-17",
    "closeData": "2007-08-28",
    "isin": "INF209K01YQ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106425",
    "name": "UTI - India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI India Consumer Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-07-02",
    "closeData": "",
    "isin": "INF789F01CE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106426",
    "name": "UTI - India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI India Consumer Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2007-07-02",
    "closeData": "",
    "isin": "INF789F01CF3",
    "isinRe": "INF789F01CG1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120780",
    "name": "UTI - India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI India Consumer Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-07-02",
    "closeData": "",
    "isin": "INF789F01VF3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120781",
    "name": "UTI - India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "UTI India Consumer Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2007-07-02",
    "closeData": "",
    "isin": "INF789F01VG1",
    "isinRe": "INF789F01VH9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "106441",
    "name": "Kotak Global Emerging Market Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Emerging Market Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-24",
    "isin": "INF174K01DV3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "106442",
    "name": "Kotak Global Emerging Market Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Emerging Market Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-24",
    "isin": "INF174K01DX9",
    "isinRe": "INF174K01DW1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119778",
    "name": "Kotak Global Emerging Market Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Emerging Market Fund - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-24",
    "isin": "INF174K01LX2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119779",
    "name": "Kotak Global Emerging Market Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Emerging Market Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-24",
    "isin": "INF174K01LW4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "106596",
    "name": "DSP World Gold Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Gold Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-23",
    "isin": "INF740K01268",
    "isinRe": "INF740K01276"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "106597",
    "name": "DSP World Gold Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Gold Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-23",
    "isin": "INF740K01250",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119277",
    "name": "DSP World Gold Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Gold Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-23",
    "isin": "INF740K01OZ9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119278",
    "name": "DSP World Gold Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Gold Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2007-07-25",
    "closeData": "2007-08-23",
    "isin": "INF740K01PA9",
    "isinRe": "INF740K01PB7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106653",
    "name": "Invesco India Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Infrastructure Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs.5000/- per application + in multiples of Re.1",
    "launchDate": "2007-09-25",
    "closeData": "2007-10-24",
    "isin": "INF205K01CE3",
    "isinRe": "INF205K01CF0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "106654",
    "name": "Invesco India Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Infrastructure Fund - Growth Option",
    "minAmount": "Rs.5000/- per application + in multiples of Re.1",
    "launchDate": "2007-09-25",
    "closeData": "2007-10-24",
    "isin": "INF205K01CD5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120404",
    "name": "Invesco India Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Infrastructure Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs.5000/- per application + in multiples of Re.1",
    "launchDate": "2007-09-25",
    "closeData": "2007-10-24",
    "isin": "INF205K01MB8",
    "isinRe": "INF205K01MC6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120405",
    "name": "Invesco India Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Infrastructure Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- per application + in multiples of Re.1",
    "launchDate": "2007-09-25",
    "closeData": "2007-10-24",
    "isin": "INF205K01MD4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106706",
    "name": "UTI - FIIF Annual Interval Plan III",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan III - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-09-14",
    "closeData": "",
    "isin": "INF789F01FQ3",
    "isinRe": "INF789F01FR1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106707",
    "name": "UTI - FIIF Annual Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed Interval Income Fund (Annual Interval Fund)  Series III - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-09-14",
    "closeData": "",
    "isin": "INF789F01FS9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "106709",
    "name": "UTI - FIIF Annual Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed Interval Income Fund (Annual Interval Fund)  Series III - Instn Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-09-14",
    "closeData": "",
    "isin": "INF789F01FV3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "125077",
    "name": "UTI - FIIF Annual Interval Plan III",
    "category": "Income",
    "navName": "UTI Fixed Interval Income Fund (Annual Interval Fund)  Series III - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-09-14",
    "closeData": "",
    "isin": "INF789F01WC8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "125078",
    "name": "UTI - FIIF Annual Interval Plan III",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan III - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-09-14",
    "closeData": "",
    "isin": "INF789F01WA2",
    "isinRe": "INF789F01WB0"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "106744",
    "name": "Sahara R.E.A.L Fund",
    "category": "Growth",
    "navName": "Sahara R.E.A.L Fund - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-10-05",
    "closeData": "2007-11-02",
    "isin": "INF515L01387",
    "isinRe": "INF515L01395"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "106745",
    "name": "Sahara R.E.A.L Fund",
    "category": "Growth",
    "navName": "Sahara R.E.A.L Fund - Growth  Fund",
    "minAmount": "5000",
    "launchDate": "2007-10-05",
    "closeData": "2007-11-02",
    "isin": "INF515L01403",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120360",
    "name": "Sahara R.E.A.L Fund",
    "category": "Growth",
    "navName": "Sahara R.E.A.L Fund - Dividend Option- Direct",
    "minAmount": "5000",
    "launchDate": "2007-10-05",
    "closeData": "2007-11-02",
    "isin": "INF515L01981",
    "isinRe": "INF515L01999"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120361",
    "name": "Sahara R.E.A.L Fund",
    "category": "Growth",
    "navName": "Sahara R.E.A.L Fund - Growth  Fund- Direct",
    "minAmount": "5000",
    "launchDate": "2007-10-05",
    "closeData": "2007-11-02",
    "isin": "INF515L01AA5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106760",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan II - Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01KS0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106761",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan II - Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01KR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106762",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan II - Retail Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01KQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106763",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan II - Retail Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01KP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120627",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan II - Direct Plan -  Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01K60",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120628",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan II - Direct Plan -  Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-01",
    "closeData": "",
    "isin": "INF109K01K78",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106777",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan III - Retail Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106778",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan III - Retail Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106779",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan III - Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106780",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan III - Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120629",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan III - Direct Plan -  Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01K86",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120630",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - III",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan III - Direct Plan -  Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01K94",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106793",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale Growth Option",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01343",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106795",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale IDCW Option",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179KA1KM1",
    "isinRe": "INF179KA1KL3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106796",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Retail Growth Option",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01319",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106797",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Retail Quarterly IDCW Option",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01327",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106798",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND WHOLESALE PLAN MONTHLY DIVIDEND REINVESTMENT OPTION",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "129051",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale Monthly IDCW Option  ",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179KA1KO7",
    "isinRe": "INF179KA1KN9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "129052",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale Growth Option - Direct Plan",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179KA1KT6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "129053",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale IDCW Option - Direct Plan",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179KA1KQ2",
    "isinRe": "INF179KA1KP4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "129054",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Wholesale Monthly IDCW - Direct Plan",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179KA1KS8",
    "isinRe": "INF179KA1KR0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106799",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Retail IDCW Option",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106800",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND RETAIL PLAN MONTHLY DIVIDEND REINVESTMENT OPTION",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106801",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND WHOLESALE PLAN QUARTERLY DIVIDEND REINVESTMENT OPTION",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01368",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106802",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND RETAIL PLAN QUARTERLY DIVIDEND REINVESTMENT OPTION",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01335",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118930",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Quarterly IDCW Option - Direct Plan",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01UV6",
    "isinRe": "INF179K01UW4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118931",
    "name": "HDFC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HDFC ARBITRAGE FUND - Growth Option - Direct Plan",
    "minAmount": "Ws Gr/Div Rs 1Cr Ret-Gr/QtrDiv Rs 5k/- MnDiv 25k",
    "launchDate": "2007-09-28",
    "closeData": "2007-10-15",
    "isin": "INF179K01UU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106821",
    "name": "ICICI Prudential Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Smallcap Fund - Institutional Growth",
    "minAmount": "Rs. 5000 (plus in multiple of Re. 1/-)",
    "launchDate": "2007-08-23",
    "closeData": "",
    "isin": "INF109K01BK6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106822",
    "name": "ICICI Prudential Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Smallcap Fund - IDCW",
    "minAmount": "Rs. 5000 (plus in multiple of Re. 1/-)",
    "launchDate": "2007-08-23",
    "closeData": "",
    "isin": "INF109K01FA8",
    "isinRe": "INF109K01BJ8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106823",
    "name": "ICICI Prudential Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Smallcap Fund - Growth",
    "minAmount": "Rs. 5000 (plus in multiple of Re. 1/-)",
    "launchDate": "2007-08-23",
    "closeData": "",
    "isin": "INF109K01BI0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120591",
    "name": "ICICI Prudential Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Smallcap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 (plus in multiple of Re. 1/-)",
    "launchDate": "2007-08-23",
    "closeData": "",
    "isin": "INF109K015M0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120870",
    "name": "ICICI Prudential Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Smallcap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 (plus in multiple of Re. 1/-)",
    "launchDate": "2007-08-23",
    "closeData": "",
    "isin": "INF109K013M5",
    "isinRe": "INF109K014M3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106828",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan A - Retail Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106829",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan A - Retail Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MN7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110663",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MR8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110664",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A - Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MT4",
    "isinRe": "INF109K01MS6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117368",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A - Retail Quarterly Dividend Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120641",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A - Direct Plan -  Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01M50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120642",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01M43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123597",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan A - Direct Plan - Quarterly Dividend Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01O17",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123645",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan A -  Quarterly Dividend Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-22",
    "closeData": "",
    "isin": "INF109K01MU2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106830",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan IV -  Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106831",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan IV - Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106832",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan IV - Retail Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106833",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Annual Interval Plan IV - Retail Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01KH3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120631",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan IV - Direct Plan -  Growth",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01L02",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120632",
    "name": "ICICI Prudential Interval Fund - Annual Interval Plan - IV",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Annual Interval Plan IV - Direct Plan -  Dividend",
    "minAmount": "Retail Rs.5000/-&Institutional 2 Crores",
    "launchDate": "2007-10-10",
    "closeData": "",
    "isin": "INF109K01L10",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106838",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Daily IDCW Option",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01699",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106839",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Monthly IDCW Option",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01715",
    "isinRe": "INF179K01723"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106840",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Weekly IDCW Option",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01731",
    "isinRe": "INF179K01749"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "106841",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Growth Option",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01707",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118959",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Direct Plan - Daily IDCW",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118960",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01VO9",
    "isinRe": "INF179K01VP6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118961",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Direct Plan - Growth Option",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": "INF179K01VQ4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118962",
    "name": "HDFC Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "HDFC Floating Rate Debt Fund - Direct Plan - Weekly IDCW",
    "minAmount": "10000000",
    "launchDate": "2007-10-23",
    "closeData": "2007-10-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106852",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Half Yearly Interval Plan II - Retail Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01LM1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106853",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Half Yearly Interval Plan II - Retail Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01LL3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112290",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Half Yearly Interval Plan II - Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01LJ7",
    "isinRe": "INF109K01LI9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112291",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Half Yearly Interval Plan II - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01LH1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120633",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Half Yearly Interval Plan II - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01M27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120634",
    "name": "ICICI Prudential Interval Fund - Half Yearly Interval Plan - II",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Half Yearly Interval Plan II - Direct Plan -  Dividend",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2007-10-12",
    "closeData": "",
    "isin": "INF109K01M35",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106872",
    "name": "Aditya Birla Sun Life International Equity Fund Plan A",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan A - Regular - IDCW",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01512",
    "isinRe": "INF209K01CV9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106873",
    "name": "Aditya Birla Sun Life International Equity Fund Plan A",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan A - Growth - Regular Plan",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01520",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119516",
    "name": "Aditya Birla Sun Life International Equity Fund Plan A",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan A - Direct - IDCW",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01Q89",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119517",
    "name": "Aditya Birla Sun Life International Equity Fund Plan A",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan A - Growth - Direct Plan",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01WS3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106875",
    "name": "Aditya Birla Sun Life International Equity Fund Plan B",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan B - Regular - IDCW",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01538",
    "isinRe": "INF209K01CW7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "106876",
    "name": "Aditya Birla Sun Life International Equity Fund Plan B",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan B - Growth - Regular Plan",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01546",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119518",
    "name": "Aditya Birla Sun Life International Equity Fund Plan B",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan B - Growth - Direct Plan",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01WU9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119519",
    "name": "Aditya Birla Sun Life International Equity Fund Plan B",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life International Equity Fund - Plan B - Direct - IDCW",
    "minAmount": "1000",
    "launchDate": "2007-09-17",
    "closeData": "2007-10-16",
    "isin": "INF209K01Q97",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106885",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan B - Retail Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01MW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106886",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan B - Retail Growth",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01MV0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111316",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Growth",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01MZ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111317",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01NB0",
    "isinRe": "INF109K01NA2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118037",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Quarterly Dividend Payout",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01NC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120643",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Direct Plan -  Growth",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01M68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120644",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Direct Plan -  Dividend",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01M76",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120645",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan B - Direct Plan -  Quarterly Dividend Payout",
    "minAmount": "Retail Rs. 5000/-; Insti Option  Rs 25 Lacs",
    "launchDate": "2007-11-05",
    "closeData": "2007-11-06",
    "isin": "INF109K01O25",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "106929",
    "name": "Kotak Nifty PSU Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty PSU Bank ETF",
    "minAmount": "10000",
    "launchDate": "2007-10-29",
    "closeData": "2007-11-02",
    "isin": "INF373I01023",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106976",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan C - Retail Growth",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01NH7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "106977",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan C - Retail Dividend",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01NI5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109854",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C - Growth",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01ND6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109855",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C - Dividend",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01NF1",
    "isinRe": "INF109K01NE4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118235",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C- Institutional Quarterly Dividend Payout",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01NG9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120646",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C - Direct Plan -  Growth",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01L44",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120647",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C - Direct Plan -  Dividend",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01L51",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120648",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan C - Direct Plan -  Quarterly Dividend Payout",
    "minAmount": "Retail  Rs. 5000/- Inst Opt  Rs 25 Lacs",
    "launchDate": "2007-11-21",
    "closeData": "",
    "isin": "INF109K01N67",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "106978",
    "name": "Franklin Asian Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Asian Equity Fund - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF090I01999",
    "isinRe": "INF090I01AA5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "106979",
    "name": "Franklin Asian Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Asian Equity Fund - Growth Plan",
    "minAmount": "Rs.5000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF090I01AB3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118558",
    "name": "Franklin Asian Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Asian Equity Fund - Direct - IDCW ",
    "minAmount": "Rs.5000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF090I01IX0",
    "isinRe": "INF090I01IY8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118559",
    "name": "Franklin Asian Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Asian Equity Fund - Direct - Growth",
    "minAmount": "Rs.5000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF090I01IZ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107070",
    "name": "UTI - FIIF Annual Interval Plan IV",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan IV - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01FW1",
    "isinRe": "INF789F01FX9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107071",
    "name": "UTI - FIIF Annual Interval Plan IV",
    "category": "Income",
    "navName": "UTI Fixed Interval Income Fund (Annual Interval Plan) Series IV - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01FY7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126031",
    "name": "UTI - FIIF Annual Interval Plan IV",
    "category": "Income",
    "navName": "UTI Fixed Interval Income Fund (Annual Interval Plan) Series IV - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01WF1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126030",
    "name": "UTI - FIIF Annual Interval Plan IV",
    "category": "Income",
    "navName": "UTI FIIF Annual Interval Plan IV - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01WD6",
    "isinRe": "INF789F01WE4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107088",
    "name": "UTI - FIIF Half Yearly Plan I",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Half Yearly Plan - I) - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01FA7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107089",
    "name": "UTI - FIIF Half Yearly Plan I",
    "category": "Income",
    "navName": "UTI FIIF Half Yearly Plan I - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01EY0",
    "isinRe": "INF789F01EZ7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "122641",
    "name": "UTI - FIIF Half Yearly Plan I",
    "category": "Income",
    "navName": "UTI FIIF Half Yearly Plan I - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01VR8",
    "isinRe": "INF789F01VS6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "122642",
    "name": "UTI - FIIF Half Yearly Plan I",
    "category": "Income",
    "navName": "UTI Fixed  Income Interval Fund ( Half Yearly Plan - I) - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-15",
    "closeData": "",
    "isin": "INF789F01VT4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "107138",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan D - Retail Dividend",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01NQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "107139",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan D - Retail Growth",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01NP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110013",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Growth",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01NL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110014",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Dividend",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01NN5",
    "isinRe": "INF109K01NM7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114929",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Quarterly Dividend Payout",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01NO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120649",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Direct Plan - Growth",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01M84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120650",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Direct Plan -  Quarterly Dividend Payout",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01O33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120651",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan D - Direct Plan -  Dividend",
    "minAmount": "Retail Rs.5000 Insti Rs. 25 lacs",
    "launchDate": "2007-12-03",
    "closeData": "",
    "isin": "INF109K01M92",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107193",
    "name": "UTI - FIIF Monthly Interval Plan II",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Monthly Interval Plan II-Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01EO1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107195",
    "name": "UTI - FIIF Monthly Interval Plan II",
    "category": "Income",
    "navName": "UTI FIIF Monthly Interval Plan II - Regular Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01EM5",
    "isinRe": "INF789F01EN3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120902",
    "name": "UTI - FIIF Monthly Interval Plan II",
    "category": "Income",
    "navName": "UTI FIIF Monthly Interval Plan II - Direct Plan - Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01VL1",
    "isinRe": "INF789F01VM9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120903",
    "name": "UTI - FIIF Monthly Interval Plan II",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund - Monthly Interval Plan II-Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01VN7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107226",
    "name": "UTI - FIIF Half Yearly Plan II",
    "category": "Income",
    "navName": "UTI FIIF Half Yearly Plan II - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01HG0",
    "isinRe": "INF789F01HH8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "107228",
    "name": "UTI - FIIF Half Yearly Plan II",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund ( Half Yearly Plan-II)- Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01HI6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "125952",
    "name": "UTI - FIIF Half Yearly Plan II",
    "category": "Income",
    "navName": "UTI FIIF Half Yearly Plan II - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01WV8",
    "isinRe": "INF789F01WW6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "125953",
    "name": "UTI - FIIF Half Yearly Plan II",
    "category": "Income",
    "navName": "UTI Fixed Income Interval Fund ( Half Yearly Plan-II)- Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2007-11-19",
    "closeData": "",
    "isin": "INF789F01WX4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107247",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Retail - Growth",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CK0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107248",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Institutional - Growth",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CG8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107249",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Super Institutional - Growth",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CN4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107250",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Super Institutional Plan - Daily - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CL8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107251",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Institutional Plan - Daily - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CD5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "107252",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Retail Plan - Daily - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CH6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147976",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VE3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147977",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Weekly Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VI4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147978",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Weekly Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VM6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147979",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VC7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147980",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Retail Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Daily Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VD5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147970",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VG8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147971",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Daily Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VH6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147972",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Weekly Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VJ2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147973",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Growth Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VK0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147974",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Super Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct Daily Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VL8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147975",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund- Institutional Plan-Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Daily Dividend Option",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01VF0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "109574",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Institutional - Weekly Dividend",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CE3",
    "isinRe": "INF090I01CF0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "109575",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Retail Plan - Weekly - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CI4",
    "isinRe": "INF090I01CJ2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "109576",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Super Institutional Plan - Weekly - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01CM6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118560",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund - Super Institutional Plan - Direct - Growth",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01JA6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118561",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund Super Institutional Plan - Direct - Daily - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01JB4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118562",
    "name": "Franklin India Ultra Short Bond Fund (no. of segregated portfolio-1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Franklin India Ultra Short Bond Fund Super Institutional Plan - Direct - Weekly - IDCW",
    "minAmount": "Ret Rs.25000; Insti Rs.1Cr Sup Inst Rs.5 Crs",
    "launchDate": "2007-12-18",
    "closeData": "",
    "isin": "INF090I01JC2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "107287",
    "name": "JM Tax Gain Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "JM Tax Gain Fund (Regular) - IDCW",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter",
    "launchDate": "2007-12-24",
    "closeData": "2008-03-25",
    "isin": "INF192K01643",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "107288",
    "name": "JM Tax Gain Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "JM Tax Gain Fund (Regular) - Growth option",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter",
    "launchDate": "2007-12-24",
    "closeData": "2008-03-25",
    "isin": "INF192K01650",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120493",
    "name": "JM Tax Gain Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "JM Tax Gain Fund (Direct) - IDCW",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter",
    "launchDate": "2007-12-24",
    "closeData": "2008-03-25",
    "isin": "INF192K01CD5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120494",
    "name": "JM Tax Gain Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "JM Tax Gain Fund (Direct) - Growth Option",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter",
    "launchDate": "2007-12-24",
    "closeData": "2008-03-25",
    "isin": "INF192K01CE3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "107309",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan F - Retail Dividend",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01NU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "107310",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II - Quarterly Interval Plan F - Retail Growth",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01NT2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110383",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F - Growth",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01NX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110384",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01NZ9",
    "isinRe": "INF109K01NY2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115927",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F Retail Quarterly Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01NW6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120652",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F - Direct Plan -  Growth",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01N00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120653",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F - Direct Plan -  Dividend",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01N18",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123055",
    "name": "ICICI Prudential Interval Fund II - Quarterly Interval Plan - F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund II Quarterly Interval Plan F - Direct Plan -  Quarterly Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2007-12-24",
    "closeData": "",
    "isin": "INF109K01O41",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107352",
    "name": "Invesco India Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Invesco India Multicap Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs.5000 & in multiples of Re.1",
    "launchDate": "2008-01-07",
    "closeData": "2008-02-19",
    "isin": "INF205K01DO0",
    "isinRe": "INF205K01DP7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107353",
    "name": "Invesco India Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Invesco India Multicap Fund - Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re.1",
    "launchDate": "2008-01-07",
    "closeData": "2008-02-19",
    "isin": "INF205K01DN2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120412",
    "name": "Invesco India Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Invesco India Multicap Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs.5000 & in multiples of Re.1",
    "launchDate": "2008-01-07",
    "closeData": "2008-02-19",
    "isin": "INF205K01MQ6",
    "isinRe": "INF205K01MR4"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120413",
    "name": "Invesco India Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Invesco India Multicap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re.1",
    "launchDate": "2008-01-07",
    "closeData": "2008-02-19",
    "isin": "INF205K01MS2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "107409",
    "name": "JM Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "JM Focused Fund (Regular) - IDCW",
    "minAmount": "NA",
    "launchDate": "2008-01-14",
    "closeData": "2008-02-15",
    "isin": "INF192K01551",
    "isinRe": "INF192K01569"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "107410",
    "name": "JM Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "JM Focused Fund (Regular) - Growth Option",
    "minAmount": "NA",
    "launchDate": "2008-01-14",
    "closeData": "2008-02-15",
    "isin": "INF192K01577",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120487",
    "name": "JM Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "JM Focused Fund (Direct) -  IDCW",
    "minAmount": "NA",
    "launchDate": "2008-01-14",
    "closeData": "2008-02-15",
    "isin": "INF192K01BU1",
    "isinRe": "INF192K01BV9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120488",
    "name": "JM Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "JM Focused Fund (Direct)  - Growth Option",
    "minAmount": "NA",
    "launchDate": "2008-01-14",
    "closeData": "2008-02-15",
    "isin": "INF192K01BW7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107471",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Monthly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SS9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107472",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01ST7",
    "isinRe": "INF205K01SU5"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107476",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SJ8",
    "isinRe": "INF205K01SK6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "107477",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SR1",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120519",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Direct Plan - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SL4",
    "isinRe": "INF205K01SM2"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120520",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Direct  Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SN0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120521",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Direct Plan - Monthly IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SO8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120522",
    "name": "Invesco India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Invesco India Gilt Fund - Direct Plan - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/-",
    "launchDate": "2008-01-30",
    "closeData": "2008-02-06",
    "isin": "INF205K01SP5",
    "isinRe": "INF205K01SQ3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "107524",
    "name": "HDFC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Infrastructure Fund - Growth Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2008-01-08",
    "closeData": "2008-02-21",
    "isin": "INF179K01GF8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "107525",
    "name": "HDFC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Infrastructure Fund - IDCW Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2008-01-08",
    "closeData": "2008-02-21",
    "isin": "INF179K01GE1",
    "isinRe": "INF179K01GG6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118978",
    "name": "HDFC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Infrastructure Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2008-01-08",
    "closeData": "2008-02-21",
    "isin": "INF179K01WP4",
    "isinRe": "INF179K01WR0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118979",
    "name": "HDFC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Infrastructure Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2008-01-08",
    "closeData": "2008-02-21",
    "isin": "INF179K01WQ2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107578",
    "name": "Mirae Asset Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mirae Asset Large Cap Fund - Growth Plan",
    "minAmount": "Regular Rs.5000/- & Institutional Rs. 5 Crores",
    "launchDate": "2008-02-11",
    "closeData": "2008-03-10",
    "isin": "INF769K01010",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107579",
    "name": "Mirae Asset Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mirae Asset Large Cap Fund Regular IDCW",
    "minAmount": "Regular Rs.5000/- & Institutional Rs. 5 Crores",
    "launchDate": "2008-02-11",
    "closeData": "2008-03-10",
    "isin": "INF769K01036",
    "isinRe": "INF769K01028"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118825",
    "name": "Mirae Asset Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mirae Asset Large Cap Fund - Direct Plan - Growth",
    "minAmount": "Regular Rs.5000/- & Institutional Rs. 5 Crores",
    "launchDate": "2008-02-11",
    "closeData": "2008-03-10",
    "isin": "INF769K01AX2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118826",
    "name": "Mirae Asset Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mirae Asset Large Cap Fund Direct IDCW",
    "minAmount": "Regular Rs.5000/- & Institutional Rs. 5 Crores",
    "launchDate": "2008-02-11",
    "closeData": "2008-03-10",
    "isin": "INF769K01AY0",
    "isinRe": "INF769K01AZ7"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "107693",
    "name": "Quantum Gold Fund",
    "category": "Other Scheme - Gold ETF",
    "navName": "Quantum Gold Fund",
    "minAmount": "5000",
    "launchDate": "2008-01-24",
    "closeData": "2008-02-08",
    "isin": "INF082J01408",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107700",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Regular Savings Daily IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01986",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107701",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Regular Savings Weekly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01994",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118841",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Direct Monthly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01BP6",
    "isinRe": "INF769K01BT8"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118842",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Direct Quarterly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01BQ4",
    "isinRe": "INF769K01BU6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118843",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Direct Daily IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01BR2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118844",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Direct Weekly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01BS0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118840",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund - Direct Plan - Growth",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01BO9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107702",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Regular Savings Quarterly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01978",
    "isinRe": "INF769K01AB8"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107704",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund Regular Savings Monthly IDCW",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01960",
    "isinRe": "INF769K01AA0"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "107705",
    "name": "Mirae Asset Savings Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mirae Asset Savings Fund-Regular Savings Plan- Growth",
    "minAmount": "Regular Plan - Rs.5000/- & in multiples of Re.1/",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-03",
    "isin": "INF769K01937",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "107763",
    "name": "LIC MF Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Infrastructure Fund-Regular Plan-Growth",
    "minAmount": "2000/-",
    "launchDate": "2008-01-31",
    "closeData": "2008-02-29",
    "isin": "INF767K01501",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "107764",
    "name": "LIC MF Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Infrastructure Fund-Regular Plan-IDCW",
    "minAmount": "2000/-",
    "launchDate": "2008-01-31",
    "closeData": "2008-02-29",
    "isin": "INF767K01485",
    "isinRe": "INF767K01493"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120350",
    "name": "LIC MF Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Infrastructure Fund-Direct Plan-IDCW",
    "minAmount": "2000/-",
    "launchDate": "2008-01-31",
    "closeData": "2008-02-29",
    "isin": "INF767K01GV7",
    "isinRe": "INF767K01GX3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120351",
    "name": "LIC MF Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Infrastructure Fund-Direct Plan-Growth",
    "minAmount": "2000/-",
    "launchDate": "2008-01-31",
    "closeData": "2008-02-29",
    "isin": "INF767K01GW5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "107900",
    "name": "Reliance Equity Linked Saving Fund - Series I",
    "category": "ELSS",
    "navName": "Reliance Equity Linked Saving Fund - Series I - Dividend Plan",
    "minAmount": "500",
    "launchDate": "2007-12-18",
    "closeData": "2008-03-17",
    "isin": "INF204K01ON2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "107901",
    "name": "Reliance Equity Linked Saving Fund - Series I",
    "category": "ELSS",
    "navName": "Reliance Equity Linked Saving Fund - Series I - Growth Plan",
    "minAmount": "500",
    "launchDate": "2007-12-18",
    "closeData": "2008-03-17",
    "isin": "INF204K01OM4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "107988",
    "name": "HSBC Global Emerging Markets Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Emerging Markets Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2008-01-28",
    "closeData": "2008-02-25",
    "isin": "INF336L01446",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "107989",
    "name": "HSBC Global Emerging Markets Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Emerging Markets Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-01-28",
    "closeData": "2008-02-25",
    "isin": "INF336L01438",
    "isinRe": "INF336L01420"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120043",
    "name": "HSBC Global Emerging Markets Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Emerging Market Fund - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2008-01-28",
    "closeData": "2008-02-25",
    "isin": "INF336L01CJ3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120044",
    "name": "HSBC Global Emerging Markets Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Emerging Markets Fund - IDCW Direct Plan",
    "minAmount": "5000",
    "launchDate": "2008-01-28",
    "closeData": "2008-02-25",
    "isin": "INF336L01CH7",
    "isinRe": "INF336L01CI5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "108008",
    "name": "HDFC FMP 370D March 2008 (2)",
    "category": "Income",
    "navName": "HDFC FMP 370D MARCH 2008 (1) WHOLESALE PLAN - GROWTH OPTION",
    "minAmount": "Wholesale Rs. 1 Cr Retail Rs. 5000",
    "launchDate": "2008-03-25",
    "closeData": "2008-03-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "108166",
    "name": "Aditya Birla Sun Life Pure Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Aditya Birla Sun Life Pure Value Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-01-17",
    "closeData": "2008-03-01",
    "isin": "INF209K01LE6",
    "isinRe": "INF209K01LG1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "108167",
    "name": "Aditya Birla Sun Life Pure Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Aditya Birla Sun Life Pure Value Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-01-17",
    "closeData": "2008-03-01",
    "isin": "INF209K01LF3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119658",
    "name": "Aditya Birla Sun Life Pure Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Aditya Birla Sun Life Pure Value Fund - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-01-17",
    "closeData": "2008-03-01",
    "isin": "INF209K01WP9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119659",
    "name": "Aditya Birla Sun Life Pure Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Aditya Birla Sun Life Pure Value Fund - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2008-01-17",
    "closeData": "2008-03-01",
    "isin": "INF209K01WQ7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "108202",
    "name": "DSP Natural Resources And New Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Natural Resources And New Energy Fund - Regular - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2008-03-03",
    "closeData": "2008-03-27",
    "isin": "INF740K01060",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "108203",
    "name": "DSP Natural Resources And New Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Natural Resources And New Energy Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2008-03-03",
    "closeData": "2008-03-27",
    "isin": "INF740K01045",
    "isinRe": "INF740K01052"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119028",
    "name": "DSP Natural Resources And New Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Natural Resources and New Energy Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2008-03-03",
    "closeData": "2008-03-27",
    "isin": "INF740K01QA7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119029",
    "name": "DSP Natural Resources And New Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Natural Resources and New Energy Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2008-03-03",
    "closeData": "2008-03-27",
    "isin": "INF740K01QB5",
    "isinRe": "INF740K01QC3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "108249",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Quant Fund -Growth Plan - Growth Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01760",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "108252",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA QUANT FUND - IDCW Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01745",
    "isinRe": "INF204K01752"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "108258",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Quant Fund -Growth Plan -Bonus Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01737",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118767",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA QUANT FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01J18",
    "isinRe": "INF204K01J26"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118769",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Quant Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01J34",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118770",
    "name": "Nippon India Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Quant Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs 5000 per plan per option",
    "launchDate": "2008-04-18",
    "closeData": "2008-04-18",
    "isin": "INF204K01J00",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "108320",
    "name": "Sahara Power and Natural Resources Fund",
    "category": "Growth",
    "navName": "Sahara Power & Natural Resources Fund - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2008-04-28",
    "closeData": "2008-05-27",
    "isin": "INF515L01445",
    "isinRe": "INF515L01452"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "108321",
    "name": "Sahara Power and Natural Resources Fund",
    "category": "Growth",
    "navName": "Sahara Power & Natural resources Fund- Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-04-28",
    "closeData": "2008-05-27",
    "isin": "INF515L01460",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120370",
    "name": "Sahara Power and Natural Resources Fund",
    "category": "Growth",
    "navName": "Sahara Power & Natural Resources Fund-Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2008-04-28",
    "closeData": "2008-05-27",
    "isin": "INF515L01AE7",
    "isinRe": "INF515L01AF4"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120371",
    "name": "Sahara Power and Natural Resources Fund",
    "category": "Growth",
    "navName": "Sahara Power & Natural resources Fund- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2008-04-28",
    "closeData": "2008-05-27",
    "isin": "INF515L01AG2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "108377",
    "name": "Invesco India Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Financial Services Fund - Regular Plan -  IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2008-05-19",
    "closeData": "2008-06-17",
    "isin": "INF205K01171",
    "isinRe": "INF205K01163"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "108378",
    "name": "Invesco India Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Financial Services Fund - Retail Growth",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2008-05-19",
    "closeData": "2008-06-17",
    "isin": "INF205K01155",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120384",
    "name": "Invesco India Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Financial Services Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2008-05-19",
    "closeData": "2008-06-17",
    "isin": "INF205K01KW8",
    "isinRe": "INF205K01KX6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120385",
    "name": "Invesco India Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India Financial Services Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- per application",
    "launchDate": "2008-05-19",
    "closeData": "2008-06-17",
    "isin": "INF205K01KY4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "108415",
    "name": "Kotak FMP 12M Series 5",
    "category": "Income",
    "navName": "Kotak FMP 12M Series 5-Institutional-Dividend",
    "minAmount": "5000",
    "launchDate": "2008-04-16",
    "closeData": "2008-05-05",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "108465",
    "name": "ICICI Prudential Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ICICI Prudential Bluechip Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2008-04-08",
    "closeData": "",
    "isin": "INF109K01EP9",
    "isinRe": "INF109K01BM2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "108466",
    "name": "ICICI Prudential Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ICICI Prudential Bluechip Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2008-04-08",
    "closeData": "",
    "isin": "INF109K01BL4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "108467",
    "name": "ICICI Prudential Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ICICI Prudential Bluechip Fund - Institutional Option - I - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2008-04-08",
    "closeData": "",
    "isin": "INF109K01BN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120585",
    "name": "ICICI Prudential Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ICICI Prudential Bluechip Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2008-04-08",
    "closeData": "",
    "isin": "INF109K014L5",
    "isinRe": "INF109K015L2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120586",
    "name": "ICICI Prudential Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ICICI Prudential Bluechip Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2008-04-08",
    "closeData": "",
    "isin": "INF109K016L0",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "108479",
    "name": "Quantum Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Quantum Nifty 50 ETF",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-20",
    "isin": "INF082J01028",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "108511",
    "name": "Kotak Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Kotak Dynamic Bond Fund Regular Plan Growth",
    "minAmount": "1000",
    "launchDate": "2008-05-26",
    "closeData": "2008-05-26",
    "isin": "INF174K01FA2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "117998",
    "name": "Kotak Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Kotak Dynamic Bond Fund Regular Plan - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "1000",
    "launchDate": "2008-05-26",
    "closeData": "2008-05-26",
    "isin": "INF174K01IY6",
    "isinRe": "INF174K01IZ3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119753",
    "name": "Kotak Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Kotak Dynamic Bond Fund - Direct Plan - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "1000",
    "launchDate": "2008-05-26",
    "closeData": "2008-05-26",
    "isin": "INF174K01JX6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119755",
    "name": "Kotak Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Kotak Dynamic Bond Fund - Growth - Direct",
    "minAmount": "1000",
    "launchDate": "2008-05-26",
    "closeData": "2008-05-26",
    "isin": "INF174K01JU2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108543",
    "name": "GSSIF - MT(Bimonthly)Plan B",
    "category": "Income",
    "navName": "GSSIF - MT(Bimonthly)Plan B",
    "minAmount": "50000000.0",
    "launchDate": "2003-06-16",
    "closeData": "2003-06-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108544",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01SK2",
    "isinRe": "INF194K01SJ4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108545",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01SE5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108546",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Half Yearly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01SI6",
    "isinRe": "INF194K01SH8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108547",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01SG0",
    "isinRe": "INF194K01SF2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "115874",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Fortnightly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01SM8",
    "isinRe": "INF194K01SL0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "117867",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01F63",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118413",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01V30",
    "isinRe": "INF194K01V22"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118414",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Fortnightly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01V55",
    "isinRe": "INF194K01V48"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131398",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Periodic IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194KA1TV4",
    "isinRe": "INF194KA1TW2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131399",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Periodic IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194KA1TS0",
    "isinRe": "INF194KA1TT8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "117868",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01F55",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118408",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Annual IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01U98",
    "isinRe": "INF194K01U80"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118409",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Daily IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01V63",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118410",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01U72",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118411",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN All Seasons Bond Fund-Direct Plan-Half Yearly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01V14",
    "isinRe": "INF194K01V06"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118412",
    "name": "BANDHAN All Seasons Bond Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN ALL SEASONS BOND FUND - DIRECT PLAN-WEEKLY IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF194K01V71",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108557",
    "name": "BANDHAN GSF Investment  Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular Plan - Quarterly IDCW",
    "minAmount": "50000000.0",
    "launchDate": "2003-03-24",
    "closeData": "",
    "isin": "INF194K01EH8",
    "isinRe": "INF194K01EG0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108558",
    "name": "BANDHAN GSF Investment    Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular - Plan B - Half Yearly IDCW",
    "minAmount": "50000000",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01EB1",
    "isinRe": "INF194K01EA3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108559",
    "name": "BANDHAN GSF Investment   Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular Plan - Annual IDCW",
    "minAmount": "50000000",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01EE5",
    "isinRe": "INF194K01ED7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108561",
    "name": "BANDHAN Dynamic Bond   Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - Quarterly IDCW",
    "minAmount": "50000000.0",
    "launchDate": "2012-04-06",
    "closeData": "2012-04-06",
    "isin": "INF194K01QI0",
    "isinRe": "INF194K01QH2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108592",
    "name": "BANDHAN Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BANDHAN Focused Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-01-30",
    "closeData": "",
    "isin": "INF194K01466",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108593",
    "name": "BANDHAN Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BANDHAN Focused Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-01-30",
    "closeData": "",
    "isin": "INF194K01474",
    "isinRe": "INF194K01482"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118421",
    "name": "BANDHAN Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BANDHAN Focused Equity Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-01-30",
    "closeData": "",
    "isin": "INF194K01W21",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118422",
    "name": "BANDHAN Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BANDHAN Focused Equity Fund-Direct Plan-IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2006-01-30",
    "closeData": "",
    "isin": "INF194K01W39",
    "isinRe": "INF194K01W47"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108594",
    "name": "BANDHAN Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "BANDHAN Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 10000/-",
    "launchDate": "2005-09-05",
    "closeData": "",
    "isin": "INF194K01391",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108595",
    "name": "BANDHAN Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "BANDHAN Flexi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 10000/-",
    "launchDate": "2005-09-05",
    "closeData": "",
    "isin": "INF194K01409",
    "isinRe": "INF194K01417"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118423",
    "name": "BANDHAN Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "BANDHAN Flexi Cap Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 10000/-",
    "launchDate": "2005-09-05",
    "closeData": "",
    "isin": "INF194K01W70",
    "isinRe": "INF194K01W88"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118424",
    "name": "BANDHAN Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "BANDHAN Flexi Cap Fund-Direct Plan-Growth",
    "minAmount": "Rs. 10000/-",
    "launchDate": "2005-09-05",
    "closeData": "",
    "isin": "INF194K01W62",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108596",
    "name": "BANDHAN CORE EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANDHAN Core Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2005-06-27",
    "closeData": "",
    "isin": "INF194K01524",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108597",
    "name": "BANDHAN CORE EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANDHAN Core Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2005-06-27",
    "closeData": "",
    "isin": "INF194K01532",
    "isinRe": "INF194K01540"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118419",
    "name": "BANDHAN CORE EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANDHAN Core Equity Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2005-06-27",
    "closeData": "",
    "isin": "INF194K01V89",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118420",
    "name": "BANDHAN CORE EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANDHAN Core Equity Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2005-06-27",
    "closeData": "",
    "isin": "INF194K01V97",
    "isinRe": "INF194K01W05"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108616",
    "name": "Grindlays Fixed Maturity 17th Plan",
    "category": "Income",
    "navName": "Grindlays Fixed Maturity 17th Plan-A-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108617",
    "name": "Grindlays Fixed Maturity 17th Plan",
    "category": "Income",
    "navName": "Grindlays Fixed Maturity 17th Plan-A-Dividend",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108632",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Growth",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01FU8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108633",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Daily IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01FW4",
    "isinRe": "INF194K01FV6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108634",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01GC4",
    "isinRe": "INF194K01GB6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108635",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01FZ7",
    "isinRe": "INF194K01FY0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "116020",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Periodic IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01I29",
    "isinRe": "INF194K01I37"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "116413",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01UR3",
    "isinRe": "INF194K01US1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118368",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Daily IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01J85",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118369",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01K58",
    "isinRe": "INF194K01K66"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118370",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Periodic IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01K82",
    "isinRe": "INF194K01K90"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118371",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Growth",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01J77",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118372",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Monthly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01K17",
    "isinRe": "INF194K01K09"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118373",
    "name": "BANDHAN LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BANDHAN Low Duration Fund-Direct Plan-Weekly IDCW",
    "minAmount": "Minimum of Rs 100",
    "launchDate": "2006-01-12",
    "closeData": "",
    "isin": "INF194K01K33",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108644",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan - Regular Plan - Monthly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01FC6",
    "isinRe": "INF194K01FB8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118387",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan -Direct Plan-Growth",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01P38",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118388",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan -Direct Plan-Quarterly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01P87",
    "isinRe": "INF194K01P79"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118389",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan -Direct Plan-Weekly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01Q03",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118390",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan -Direct Plan-Monthly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01P53",
    "isinRe": "INF194K01P46"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131390",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan -Direct Plan-Periodic IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194KA1SL7",
    "isinRe": "INF194KA1SM5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131391",
    "name": "BANDHAN GSF - CONSTANT MATURITY PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan - Regular Plan - Periodic IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194KA1SI3",
    "isinRe": "INF194KA1SJ1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108646",
    "name": "BANDHAN GSF - CONSTANT MATURITY    PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01FF9",
    "isinRe": "INF194K01FE2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108650",
    "name": "BANDHAN Money Manager  Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund - Regular Plan - Daily IDCW",
    "minAmount": "500.00",
    "launchDate": "2003-04-03",
    "closeData": "2003-04-03",
    "isin": "INF194K01LL5",
    "isinRe": "INF194K01LK7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108675",
    "name": "BANDHAN Dynamic Bond  Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - Annual IDCW",
    "minAmount": "50000000.0",
    "launchDate": "2012-04-06",
    "closeData": "2012-04-06",
    "isin": "INF194K01QL4",
    "isinRe": "INF194K01QK6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108690",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Regular Plan - Growth",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01VX9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108691",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01WI8",
    "isinRe": "INF194K01WH0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108692",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01VZ4",
    "isinRe": "INF194K01VY7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108693",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01WF4",
    "isinRe": "INF194K01WE7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108694",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID FUND - Inst Plan B - Periodic Div",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01VP5",
    "isinRe": "INF194K01VO8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108695",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Plan D-Weekly Dividend",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118366",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Direct Plan-Daily IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01I78",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118367",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Direct Plan -Weekly IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01J51",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139582",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Unclaimed IDCW Less than 3 years",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194KA14C0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139583",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Unclaimed IDCW More than 3 years",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194KA15C7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139584",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Unclaimed Redemption Less than 3 years",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194KA12C4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139585",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Unclaimed Redemption More than 3 years",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194KA13C2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108696",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Plan D-Daily Dividend",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "115488",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Plan B - Monthly Dividend",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01VV3",
    "isinRe": "INF194K01VU5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "115489",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund - Regular Plan - Periodic IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01WC1",
    "isinRe": "INF194K01WB3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118363",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Direct Plan-Periodic IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01J02",
    "isinRe": "INF194K01I94"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118364",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Direct Plan-Growth",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01I60",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118365",
    "name": "BANDHAN LIQUID Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANDHAN LIQUID Fund -Direct Plan -Monthly IDCW",
    "minAmount": "Rs.100",
    "launchDate": "1900-01-01",
    "closeData": "1900-01-01",
    "isin": "INF194K01J36",
    "isinRe": "INF194K01J28"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108701",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194K01LR2",
    "isinRe": "INF194K01LQ4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108702",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Plan D-Daily Dividend",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108703",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Plan D-Weekly Dividend",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108704",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Plan D-Monthly Divdend",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118383",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Direct Plan-Daily IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194K01M98",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118384",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Direct Plan-Growth",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194K01M80",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118385",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Direct Plan-Monthly IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194K01N22",
    "isinRe": "INF194K01N14"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118386",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Direct Plan-Weekly IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194K01N48",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131388",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund -Direct Plan-Periodic IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194KA1TJ9",
    "isinRe": "INF194KA1TK7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131389",
    "name": "BANDHAN Money Manager Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund - Regular Plan - Periodic IDCW",
    "minAmount": "Rs. 100/-",
    "launchDate": "2001-01-01",
    "closeData": "2001-01-01",
    "isin": "INF194KA1TG5",
    "isinRe": "INF194KA1TH3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108713",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Fortnightly IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01HK5",
    "isinRe": "INF194K01HJ7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108714",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan C  - Fortnightly Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01HV2",
    "isinRe": "INF194K01HU4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108715",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan D- Fortnightly Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01IC0",
    "isinRe": "INF194K01IB2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108716",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan B - Fortnightly Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01HR0",
    "isinRe": "INF194K01HQ2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108717",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan C  - Growth",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01HT6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108718",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan C  - Monthly Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01HY6",
    "isinRe": "INF194K01HX8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143366",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Quarterly IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA12K7",
    "isinRe": "INF194KA13K5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143367",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Direct Plan - Quarterly IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA18K4",
    "isinRe": "INF194KA19K2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143368",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Annual IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA15K0",
    "isinRe": "INF194KA16K8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143369",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Direct Plan - Annual IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA11L7",
    "isinRe": "INF194KA12L5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131382",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term-Direct Plan-Periodic IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA1SR4",
    "isinRe": "INF194KA1SS2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131383",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Periodic IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA1SO1",
    "isinRe": "INF194KA1SP8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108719",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan D - Growth",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01IA4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108720",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan D - Monthly Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01IF3",
    "isinRe": "INF194K01IE6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "116593",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term Plan - Plan C  -Periodic Dividend",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01WR9",
    "isinRe": "INF194K01WS7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118405",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term-Direct Plan-Fortnightly IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01U56",
    "isinRe": "INF194K01U49"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118406",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term-Direct Plan-Monthly IDCW",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01U23",
    "isinRe": "INF194K01U15"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118407",
    "name": "BANDHAN BOND FUND - SHORT TERM PLAN",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term-Direct Plan-Growth",
    "minAmount": "500",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01U07",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108727",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Monthly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01KC6",
    "isinRe": "INF194K01KB8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108728",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Growth",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01JU0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108729",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Fortnightly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01KI3",
    "isinRe": "INF194K01KH5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108730",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Bi-Monthly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01KF9",
    "isinRe": "INF194K01KE2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108731",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Daily IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01JW6",
    "isinRe": "INF194K01JV8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "113169",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - IDCW Quarterly",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01JZ9",
    "isinRe": "INF194K01JY2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131384",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Periodic IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA1TD2",
    "isinRe": "INF194KA1TE0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131385",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term - Regular Plan - Periodic IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194KA1TA8",
    "isinRe": "INF194KA1TB6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118399",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Bi Monthly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01T59",
    "isinRe": "INF194K01T42"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118400",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Daily IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01S68",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118401",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Growth",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01S50",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118402",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Monthly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01T26",
    "isinRe": "INF194K01T18"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118403",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Quartely IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01S92",
    "isinRe": "INF194K01S84"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118404",
    "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BANDHAN Bond Fund - Medium Term Plan-Direct Plan-Fortnightly IDCW",
    "minAmount": "10",
    "launchDate": "2001-04-01",
    "closeData": "",
    "isin": "INF194K01T83",
    "isinRe": "INF194K01T75"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108753",
    "name": "BANDHAN GSF - CONSTANT MATURITY   PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan - Regular Plan - Growth",
    "minAmount": "Rs10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01FA0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108756",
    "name": "BANDHAN Money Manager   Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund - Regular Plan - Growth",
    "minAmount": "500.00",
    "launchDate": "2003-02-18",
    "closeData": "",
    "isin": "INF194K01LJ9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108757",
    "name": "BANDHAN Money Manager    Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "BANDHAN Money Manager Fund - Regular Plan - Monthly IDCW",
    "minAmount": "500.00",
    "launchDate": "2003-02-18",
    "closeData": "2003-02-18",
    "isin": "INF194K01LO9",
    "isinRe": "INF194K01LN1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108763",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan - Regular Plan - Half-yearly IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01IQ0",
    "isinRe": "INF194K01IP2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108764",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01IT4",
    "isinRe": "INF194K01IS6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108765",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan - Regular Plan - Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01IL1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108766",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01IN7",
    "isinRe": "INF194K01IM9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108767",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01HH1",
    "isinRe": "INF194K01HG3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108768",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Short Term - Regular Plan - Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01HF5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118393",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan-Direct Plan-Annual IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01R77",
    "isinRe": "INF194K01R69"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118394",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan-Direct Plan-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01R51",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118395",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan-Direct Plan-Half Yearly IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01S01",
    "isinRe": "INF194K01R93"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118396",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194K01S35",
    "isinRe": "INF194K01S27"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131386",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan-Direct Plan-Periodic IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194KA1SX2",
    "isinRe": "INF194KA1SY0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131387",
    "name": "BANDHAN BOND FUND - INCOME PLAN",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANDHAN Bond Fund - Income Plan - Regular Plan - Periodic IDCW",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": "INF194KA1SU8",
    "isinRe": "INF194KA1SV6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108799",
    "name": "BANDHAN LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANDHAN Large Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2006-04-19",
    "closeData": "",
    "isin": "INF194K01516",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108800",
    "name": "BANDHAN LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANDHAN Large Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2006-04-19",
    "closeData": "",
    "isin": "INF194K01490",
    "isinRe": "INF194K01508"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118478",
    "name": "BANDHAN LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANDHAN Large Cap Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2006-04-19",
    "closeData": "",
    "isin": "INF194K01Z51",
    "isinRe": "INF194K01Z69"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118479",
    "name": "BANDHAN LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANDHAN Large Cap Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2006-04-19",
    "closeData": "",
    "isin": "INF194K01Z44",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108819",
    "name": "Standard Chartered Fixed Maturity Plan - Quarterly Series 6",
    "category": "Income",
    "navName": "Standard Chartered Fixed Maturity Plan - QS6 PLAN B DIVIDEND",
    "minAmount": "Rs. 500/-.",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108844",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund - Plan B - Dividend",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01623",
    "isinRe": "INF194K01631"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108845",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01649",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108846",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund - Plan B - Growth",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01615",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108847",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01656",
    "isinRe": "INF194K01664"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118474",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund-Direct Plan- Growth",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01Y60",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118475",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund-Direct Plan-Monthly IDCW",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194K01Y78",
    "isinRe": "INF194K01Y86"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "133679",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund-Direct Plan-Annual IDCW",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194KA1YG5",
    "isinRe": "INF194KA1YH3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "133680",
    "name": "BANDHAN Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANDHAN Arbitrage Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 100/- and any amount thereafter",
    "launchDate": "2006-11-14",
    "closeData": "",
    "isin": "INF194KA1YD2",
    "isinRe": "INF194KA1YE0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108908",
    "name": "BANDHAN STERLING VALUE FUND",
    "category": "Equity Scheme - Value Fund",
    "navName": "BANDHAN Sterling Value Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2008-01-09",
    "closeData": "",
    "isin": "INF194K01359",
    "isinRe": "INF194K01367"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108909",
    "name": "BANDHAN STERLING VALUE FUND",
    "category": "Equity Scheme - Value Fund",
    "navName": "BANDHAN Sterling Value Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2008-01-09",
    "closeData": "",
    "isin": "INF194K01342",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118480",
    "name": "BANDHAN STERLING VALUE FUND",
    "category": "Equity Scheme - Value Fund",
    "navName": "BANDHAN Sterling Value Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2008-01-09",
    "closeData": "",
    "isin": "INF194K01Z93",
    "isinRe": "INF194K010A2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118481",
    "name": "BANDHAN STERLING VALUE FUND",
    "category": "Equity Scheme - Value Fund",
    "navName": "BANDHAN Sterling Value Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2008-01-09",
    "closeData": "",
    "isin": "INF194K01Z85",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "108939",
    "name": "Kotak FMP 12M Series 6",
    "category": "Income",
    "navName": "Kotak FMP 12M Series 6 - Institutional Dividend",
    "minAmount": "1000",
    "launchDate": "2008-05-22",
    "closeData": "2008-05-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108992",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund - Regular Plan - Monthly IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01599",
    "isinRe": "INF194K01607"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108993",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund -B-DIVIDEND",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01565",
    "isinRe": "INF194K01573"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108994",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund -B-GROWTH",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01557",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "108995",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01581",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118476",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund-Direct Plan-Monthly IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01Z10",
    "isinRe": "INF194K01Z28"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118477",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund-Direct Plan-Growth",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194K01Z02",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "133681",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund-Direct Plan-Annual IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194KA1YA8",
    "isinRe": "INF194KA1YB6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "133682",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund - Regular Plan -Annual IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194KA1XX2",
    "isinRe": "INF194KA1XY0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143258",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund-Direct Plan -Quarterly IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194KA19J4",
    "isinRe": "INF194KA10K1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "143259",
    "name": "BANDHAN EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "BANDHAN Equity Savings Fund- Regular Plan -Quarterly IDCW",
    "minAmount": "A Rs.10000/-;B Rs. 100000/-",
    "launchDate": "2008-05-05",
    "closeData": "",
    "isin": "INF194KA16J0",
    "isinRe": "INF194KA17J8"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "109010",
    "name": "Kotak S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak S&P BSE Sensex ETF",
    "minAmount": "10000",
    "launchDate": "2008-05-07",
    "closeData": "2008-05-16",
    "isin": "INF174KA1JD7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "109058",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01603",
    "isinRe": "INF903J01611"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "109059",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01629",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "109060",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Institutional Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01637",
    "isinRe": "INF903J01645"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "109061",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Institutional Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01652",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119596",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01MZ9",
    "isinRe": "INF903J01NA0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119597",
    "name": "Sundaram Financial Services Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Financial Services Opportunities Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2008-05-10",
    "closeData": "2008-06-10",
    "isin": "INF903J01NB8",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "109254",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Regular Plan- Growth",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01306",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "109255",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Regular Plan- Weekly IDCW",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CP1",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111971",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Regular Plan- Daily IDCW",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CO4",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119368",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Direct Plan- Daily IDCW",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CL0",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119369",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Direct Plan- Growth",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01785",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119370",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund- Direct Plan- Weekly IDCW",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CM8",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141887",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund  Unclaimed Dividend greater than  3 years",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141888",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund  Unclaimed Redemption greater than  3 years",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141889",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund  Unclaimed Dividend Upto 3 years",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141890",
    "name": "BANK OF INDIA LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "BANK OF INDIA Liquid Fund  Unclaimed Redemption Upto 3 years",
    "minAmount": "10000000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "109264",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Regular Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CX5",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "109269",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01298",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111970",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Regular Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CW7",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119379",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01892",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119380",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Direct Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CT3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119381",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Direct Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CU1",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "126389",
    "name": "BANK OF INDIA ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANK OF INDIA Ultra Short Duration Fund- Direct Plan-Bonus",
    "minAmount": "5000",
    "launchDate": "2008-07-09",
    "closeData": "2008-07-14",
    "isin": "INF761K01CS5",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "109274",
    "name": "Quant Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "quant Focused Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-07-03",
    "closeData": "2008-08-01",
    "isin": "INF966L01358",
    "isinRe": "INF966L01366"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "109275",
    "name": "Quant Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "quant Focused Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-07-03",
    "closeData": "2008-08-01",
    "isin": "INF966L01374",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120834",
    "name": "Quant Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "quant Focused Fund - Growth Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-07-03",
    "closeData": "2008-08-01",
    "isin": "INF966L01853",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120835",
    "name": "Quant Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "quant Focused Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-07-03",
    "closeData": "2008-08-01",
    "isin": "INF966L01838",
    "isinRe": "INF966L01846"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109445",
    "name": "ICICI Prudential Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Banking and Financial Services Fund -  Growth",
    "minAmount": "Retail Option Rs.5000 (plus in multiple of Re.1)",
    "launchDate": "2008-07-09",
    "closeData": "",
    "isin": "INF109K01BU5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109446",
    "name": "ICICI Prudential Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Banking and Financial Services Fund -  IDCW",
    "minAmount": "Retail Option Rs.5000 (plus in multiple of Re.1)",
    "launchDate": "2008-07-09",
    "closeData": "",
    "isin": "INF109K01DY3",
    "isinRe": "INF109K01BV3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120244",
    "name": "ICICI Prudential Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Banking and Financial Services Fund - Direct Plan -  Growth",
    "minAmount": "Retail Option Rs.5000 (plus in multiple of Re.1)",
    "launchDate": "2008-07-09",
    "closeData": "",
    "isin": "INF109K013J1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120245",
    "name": "ICICI Prudential Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Banking and Financial Services Fund - Direct Plan -  IDCW",
    "minAmount": "Retail Option Rs.5000 (plus in multiple of Re.1)",
    "launchDate": "2008-07-09",
    "closeData": "",
    "isin": "INF109K011J5",
    "isinRe": "INF109K012J3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109464",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan IV",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan IV - Regular Plan - IDCW",
    "minAmount": "10000.00",
    "launchDate": "2008-07-17",
    "closeData": "",
    "isin": "INF789F01GO6",
    "isinRe": "INF789F01GP3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109465",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan IV",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - IV -Regular Plan - Growth Option",
    "minAmount": "10000.00",
    "launchDate": "2008-07-17",
    "closeData": "",
    "isin": "INF789F01GQ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109466",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan IV",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - IV -Instn. Growth  Option",
    "minAmount": "10000.00",
    "launchDate": "2008-07-17",
    "closeData": "",
    "isin": "INF789F01GT5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121033",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan IV",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan IV - Direct Plan - IDCW",
    "minAmount": "10000.00",
    "launchDate": "2008-07-17",
    "closeData": "",
    "isin": "INF789F01WM7",
    "isinRe": "INF789F01WN5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121034",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan IV",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - IV -Direct Plan - Growth Option",
    "minAmount": "10000.00",
    "launchDate": "2008-07-17",
    "closeData": "",
    "isin": "INF789F01WO3",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "109493",
    "name": "SAHARA BANKING & FINANCIAL SERVICES FUND",
    "category": "Growth",
    "navName": "SAHARA BANKING & FINANCIAL SERVICES FUND- GROWTH OPTION",
    "minAmount": "5000/-",
    "launchDate": "2008-07-28",
    "closeData": "2008-08-26",
    "isin": "INF515L01494",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "109494",
    "name": "SAHARA BANKING & FINANCIAL SERVICES FUND",
    "category": "Growth",
    "navName": "SAHARA BANKING & FINANCIAL SERVICES FUND-DIVIDEND OPTION",
    "minAmount": "5000/-",
    "launchDate": "2008-07-28",
    "closeData": "2008-08-26",
    "isin": "INF515L01478",
    "isinRe": "INF515L01486"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120373",
    "name": "SAHARA BANKING & FINANCIAL SERVICES FUND",
    "category": "Growth",
    "navName": "SAHARA BANKING & FINANCIAL SERVICES FUND- GROWTH - Direct",
    "minAmount": "5000/-",
    "launchDate": "2008-07-28",
    "closeData": "2008-08-26",
    "isin": "INF515L01AJ6",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120374",
    "name": "SAHARA BANKING & FINANCIAL SERVICES FUND",
    "category": "Growth",
    "navName": "SAHARA BANKING & FINANCIAL SERVICES FUND-DIVIDEND - Direct",
    "minAmount": "5000/-",
    "launchDate": "2008-07-28",
    "closeData": "2008-08-26",
    "isin": "INF515L01AH0",
    "isinRe": "INF515L01AI8"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "109522",
    "name": "JM Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "JM Flexicap Fund (Regular) - Growth option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2008-07-31",
    "closeData": "2008-08-29",
    "isin": "INF192K01635",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "109523",
    "name": "JM Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "JM Flexicap Fund (Regular) - IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2008-07-31",
    "closeData": "2008-08-29",
    "isin": "INF192K01619",
    "isinRe": "INF192K01627"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120491",
    "name": "JM Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "JM Flexicap Fund (Direct) - IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2008-07-31",
    "closeData": "2008-08-29",
    "isin": "INF192K01CA1",
    "isinRe": "INF192K01CB9"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "120492",
    "name": "JM Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "JM Flexicap Fund (Direct) - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2008-07-31",
    "closeData": "2008-08-29",
    "isin": "INF192K01CC7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109617",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan V",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - V - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2008-07-31",
    "closeData": "",
    "isin": "INF789F01GW9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "109618",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan V",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan V - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-07-31",
    "closeData": "",
    "isin": "INF789F01GU3",
    "isinRe": "INF789F01GV1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121181",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan V",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan V - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-07-31",
    "closeData": "",
    "isin": "INF789F01WP0",
    "isinRe": "INF789F01WQ8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121182",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan V",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - V - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2008-07-31",
    "closeData": "",
    "isin": "INF789F01WR6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109716",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund -P F Option-Automatic Annual Reinvest Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01CA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109717",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund - Institutional Plan-Growth Plan -Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01BX8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109718",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund-Institutional Plan-P F Option-Automatic Capital Appreciation Payout Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01CD8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109720",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund -Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01BU4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109721",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund -P F Option-Defined Maturity Date Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01CB2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109723",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "NIPPON INDIA GILT SECURITIES FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01BV2",
    "isinRe": "INF204K01BW0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118673",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund - Direct Plan - P F Option - Defined Maturity Date Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01E88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118674",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund - Direct Plan - P F Option - Automatic Annual Reinvest Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01E62",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133264",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund- Growth Plan- Bonus Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204KA1YU4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133265",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund- Direct Plan- Growth Plan- Bonus Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204KA1YV2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "109724",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund-P F Option-Automatic Capital Appreciation Payout Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01CC0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118669",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund - Direct Plan - P F Option - Automatic Capital Appreciation Payout Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01E70",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118670",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "NIPPON INDIA GILT SECURITIES FUND - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01XQ6",
    "isinRe": "INF204K01XR4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118672",
    "name": "Nippon India Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Nippon India Gilt Securities Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-08-22",
    "closeData": "2008-08-22",
    "isin": "INF204K01XP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109740",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Growth",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01BO8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109741",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - IDCW Monthly",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01EU9",
    "isinRe": "INF109K01BP5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109742",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund- Institutional Growth",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01BQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109743",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - IDCW Quarterly",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01EV7",
    "isinRe": "INF109K01BS9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109744",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund- Institutional Monthly Dividend",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01ES3",
    "isinRe": "INF109K01BR1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "109745",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund- Institutional Quarterly Dividend",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01ET1",
    "isinRe": "INF109K01BT7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120617",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Direct Plan - IDCW Monthly",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01V59",
    "isinRe": "INF109K01V67"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120618",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Direct Plan - IDCW Quarterly",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01V75",
    "isinRe": "INF109K01V91"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120619",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Direct Plan - Growth",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109K01V83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130932",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Bonus",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109KA1C72",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131480",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109KA1X77",
    "isinRe": "INF109KA1X69"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131481",
    "name": "ICICI Prudential Bond Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "ICICI Prudential Bond Fund - Half Yearly IDCW",
    "minAmount": "Retail Option  Rs.5000/-;Insti Option Rs 5 Crores",
    "launchDate": "2008-08-13",
    "closeData": "",
    "isin": "INF109KA1X51",
    "isinRe": "INF109KA1X44"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "109830",
    "name": "Quant Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "quant Flexi Cap Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-08-25",
    "closeData": "2008-09-23",
    "isin": "INF966L01457",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "109831",
    "name": "Quant Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "quant Flexi Cap Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-08-25",
    "closeData": "2008-09-23",
    "isin": "INF966L01432",
    "isinRe": "INF966L01440"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120842",
    "name": "Quant Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "quant Flexi Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-08-25",
    "closeData": "2008-09-23",
    "isin": "INF966L01895",
    "isinRe": "INF966L01903"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "120843",
    "name": "Quant Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "quant Flexi Cap Fund - Growth Option-Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2008-08-25",
    "closeData": "2008-09-23",
    "isin": "INF966L01911",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "110021",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VI",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan VI - Regular Plan - IDCW",
    "minAmount": "10000.00",
    "launchDate": "2008-08-27",
    "closeData": "",
    "isin": "INF789F01HA3",
    "isinRe": "INF789F01HB1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "110024",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VI",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - VI-Regular Plan - Growth Option",
    "minAmount": "10000.00",
    "launchDate": "2008-08-27",
    "closeData": "",
    "isin": "INF789F01HC9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121721",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VI",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan VI - Direct Plan - IDCW",
    "minAmount": "10000.00",
    "launchDate": "2008-08-27",
    "closeData": "",
    "isin": "INF789F01WS4",
    "isinRe": "INF789F01WT2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "121722",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VI",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - VI-Direct Plan - Growth Option",
    "minAmount": "10000.00",
    "launchDate": "2008-08-27",
    "closeData": "",
    "isin": "INF789F01WU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110045",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V Monthly Interval Plan A - Growth",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01OP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110046",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V Monthly Interval Plan A - Dividend",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01OR4",
    "isinRe": "INF109K01OQ6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110047",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V - Monthly Interval Plan A - Retail Growth",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01OS2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110048",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V - Monthly Interval Plan A - Retail Dividend",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01OU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120660",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V Monthly Interval Plan A - Direct Plan -  Dividend",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01N34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120661",
    "name": "ICICI Prudential Interval Fund V - Monthly Interval Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund V Monthly Interval Plan A - Direct Plan - Growth",
    "minAmount": "Retail  Rs.5000/-; Insti  Rs.25 Lacs",
    "launchDate": "2008-09-04",
    "closeData": "",
    "isin": "INF109K01N26",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110345",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B - Retail Dividend",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01OG7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110346",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV Quarterly Interval Plan B - Growth",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01OB8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110347",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV Quarterly Interval Plan B -Dividend",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01OD4",
    "isinRe": "INF109K01OE2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "110348",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B - Retail Growth",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01OF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120623",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV Quarterly Interval Plan B - Direct Plan -  Growth",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01N42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120624",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV Quarterly Interval Plan B - Direct Plan -  Dividend",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01N59",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120811",
    "name": "ICICI Prudential Interval Fund IV - Quarterly Interval Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund IV Quarterly Interval Plan B - Direct Plan -  Quarterly Dividend Payout",
    "minAmount": "Retail - Rs. 5000; Institutional - Rs.2 Crores",
    "launchDate": "2008-09-18",
    "closeData": "",
    "isin": "INF109K01O58",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "110468",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VII",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - VII - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2008-09-26",
    "closeData": "",
    "isin": "INF789F01EI3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "110469",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VII",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan VII - Regular Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-09-26",
    "closeData": "",
    "isin": "INF789F01EG7",
    "isinRe": "INF789F01EH5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120876",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VII",
    "category": "Income",
    "navName": "UTI FIIF Sr-2 Quarterly Interval Plan VII - Direct Plan - IDCW",
    "minAmount": "10000",
    "launchDate": "2008-09-26",
    "closeData": "",
    "isin": "INF789F01VI7",
    "isinRe": "INF789F01VJ5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120877",
    "name": "UTI - FIIF Sr-2 Quarterly Interval Plan VII",
    "category": "Income",
    "navName": "UTI F I I F Series II -Quarterly Interval Plan - VII - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2008-09-26",
    "closeData": "",
    "isin": "INF789F01VK3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "110575",
    "name": "Kotak Treasury Advantage",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Treasury Advantage Fund -Daily Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01FE4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119749",
    "name": "Kotak Treasury Advantage",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Kotak Treasury Advantage Fund -Daily Reinvestment of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-07",
    "isin": "INF174K01JT4",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110598",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap  Equity Fund Regular Plan- Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01116",
    "isinRe": "INF761K01124"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110599",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Eco Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01041",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110601",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Eco Plan-Bonus",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01017",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110603",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01108",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110604",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Eco Plan-Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01058",
    "isinRe": "INF761K01066"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110606",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Regular Plan-Bonus",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01074",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110607",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Regular Plan- Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01082",
    "isinRe": "INF761K01090"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "110608",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Eco Plan-Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01025",
    "isinRe": "INF761K01033"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119300",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Direct Plan- Bonus",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01629",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119346",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Direct Plan-Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01645",
    "isinRe": "INF761K01637"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119349",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Direct  Plan- Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01678",
    "isinRe": "INF761K01660"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119350",
    "name": "BANK OF INDIA LARGE & MID CAP EQUITY FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "BANK OF INDIA Large & Mid Cap Equity Fund Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2008-09-04",
    "closeData": "2008-10-01",
    "isin": "INF761K01652",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111343",
    "name": "BANDHAN GSF - CONSTANT MATURITY  PLAN",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "BANDHAN Government Securities Fund - Constant Maturity Plan - Regular Plan - Weekly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01FI3",
    "isinRe": "INF194K01FH5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111348",
    "name": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-15",
    "closeData": "2008-10-14",
    "isin": "INF209K01199",
    "isinRe": "INF209K01CG0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111349",
    "name": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan - Growth - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2008-09-15",
    "closeData": "2008-10-14",
    "isin": "INF209K01207",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119575",
    "name": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan -Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-09-15",
    "closeData": "2008-10-14",
    "isin": "INF209K01P64",
    "isinRe": "INF209K01VM8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119576",
    "name": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Commodity Equities Fund - Global Agri Plan - Growth - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2008-09-15",
    "closeData": "2008-10-14",
    "isin": "INF209K01VL0",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111436",
    "name": "Taurus Ultra Short Term Bond  Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Taurus Liquid Plus Fund - Retail Dividend",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-11-24",
    "closeData": "2008-11-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111440",
    "name": "Taurus Ultra Short Term Bond  Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Taurus Liquid Plus Fund - Institutional Dividend",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-11-24",
    "closeData": "2008-11-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111444",
    "name": "Taurus Ultra Short Term Bond  Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Taurus Liquid Plus Fund - SI Dividend",
    "minAmount": "Rs 5000 & in multiples of Re 1 thereafter",
    "launchDate": "2008-11-24",
    "closeData": "2008-11-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111522",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDFC Dynamic Bond Fund -PLAN B DIVIDEND QUARTELY",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111523",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01QO8",
    "isinRe": "INF194K01QN0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111524",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01QG4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118415",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-Annual IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01O13",
    "isinRe": "INF194K01O05"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118416",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01N63",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118417",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01O47",
    "isinRe": "INF194K01O39"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118418",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K01N89",
    "isinRe": "INF194K01N71"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "122618",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-Half Yearly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K011P8",
    "isinRe": "INF194K012P6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "122868",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - Half Yearly IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194K018R9",
    "isinRe": "INF194K019R7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131396",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund-Direct Plan-Periodic IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194KA1TP6",
    "isinRe": "INF194KA1TQ4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131397",
    "name": "BANDHAN Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "BANDHAN Dynamic Bond Fund - Regular Plan B - Periodic IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2002-06-25",
    "closeData": "",
    "isin": "INF194KA1TM3",
    "isinRe": "INF194KA1TN1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111525",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular Plan - Growth",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01DZ2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111526",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDFC GSF - Investment Plan -PLAN B ANNUAL",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111527",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular Plan - IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01EK2",
    "isinRe": "INF194K01EJ4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111528",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDFC GSF - Investment Plan -PLAN B DIVIDEND HALF YEARLY",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118463",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01R36",
    "isinRe": "INF194K01R28"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118464",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-Growth",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01Q29",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118465",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-Half Yearly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01Q45",
    "isinRe": "INF194K01Q37"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118466",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01R02",
    "isinRe": "INF194K01Q94"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118467",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-Annual IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194K01Q78",
    "isinRe": "INF194K01Q60"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131394",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund-  Investment Plan-Direct Plan-Periodic IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194KA1RZ9",
    "isinRe": "INF194KA1SA0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131395",
    "name": "BANDHAN GSF Investment Plan",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "BANDHAN Government Securities Fund - IP - Regular -Plan B - Periodic IDCW",
    "minAmount": "Rs.10000",
    "launchDate": "2002-02-21",
    "closeData": "",
    "isin": "INF194KA1RW6",
    "isinRe": "INF194KA1RX4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111530",
    "name": "IDFC Super Saver Income Fund-Investment Plan",
    "category": "Income",
    "navName": "IDFC-SSIF-Investment Plan B DIVIDEND HALF YEARLY",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111531",
    "name": "IDFC Super Saver Income Fund-Investment Plan",
    "category": "Income",
    "navName": "IDFC-SSIF-Investment Plan B DIVIDEND ANNUAL",
    "minAmount": "Rs. 500/-",
    "launchDate": "2005-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "111548",
    "name": "Quantum Tax Saving Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Quantum Tax Saving Fund",
    "minAmount": "500",
    "launchDate": "2008-12-10",
    "closeData": "2008-12-13",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "111549",
    "name": "Quantum Tax Saving Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Quantum Tax Saving Fund - Direct Plan Growth Option",
    "minAmount": "500",
    "launchDate": "2008-12-10",
    "closeData": "2008-12-13",
    "isin": "INF082J01069",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "111550",
    "name": "Quantum Tax Saving Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Quantum Tax Saving Fund - Direct Plan IDCW",
    "minAmount": "500",
    "launchDate": "2008-12-10",
    "closeData": "2008-12-13",
    "isin": "INF082J01077",
    "isinRe": "INF082J01085"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141070",
    "name": "Quantum Tax Saving Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Quantum Tax Saving Fund - Regular Plan Growth Option",
    "minAmount": "500",
    "launchDate": "2008-12-10",
    "closeData": "2008-12-13",
    "isin": "INF082J01366",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141071",
    "name": "Quantum Tax Saving Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Quantum Tax Saving Fund - Regular Plan IDCW",
    "minAmount": "500",
    "launchDate": "2008-12-10",
    "closeData": "2008-12-13",
    "isin": "INF082J01374",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111569",
    "name": "BANDHAN Tax Advantage (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BANDHAN Tax Advantage (ELSS) Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2008-12-01",
    "closeData": "",
    "isin": "INF194K01292",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "111570",
    "name": "BANDHAN Tax Advantage (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BANDHAN Tax Advantage (ELSS) Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2008-12-01",
    "closeData": "",
    "isin": "INF194K01300",
    "isinRe": "INF194K01318"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118472",
    "name": "BANDHAN Tax Advantage (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BANDHAN Tax Advantage (ELSS) Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2008-12-01",
    "closeData": "",
    "isin": "INF194K01Y37",
    "isinRe": "INF194K01Y45"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118473",
    "name": "BANDHAN Tax Advantage (ELSS) Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BANDHAN Tax Advantage (ELSS) Fund-Direct Plan-Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2008-12-01",
    "closeData": "",
    "isin": "INF194K01Y29",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111585",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01579",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111589",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Regular Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01595",
    "isinRe": "INF761K01587"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111590",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Regular Plan- Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01611",
    "isinRe": "INF761K01603"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119382",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01736",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119383",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Direct Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01751",
    "isinRe": "INF761K01744"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119384",
    "name": "BANK OF INDIA SHORT TERM INCOME FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "BANK OF INDIA Short Term Income Fund-Direct Plan- Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-03",
    "closeData": "2008-12-10",
    "isin": "INF761K01777",
    "isinRe": "INF761K01769"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "111599",
    "name": "UTI - Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "UTI - Multi Asset Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-10-21",
    "closeData": "",
    "isin": "INF789F01AP6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "111602",
    "name": "UTI - Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "UTI Multi Asset Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-10-21",
    "closeData": "",
    "isin": "INF789F01AN1",
    "isinRe": "INF789F01AO9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120760",
    "name": "UTI - Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "UTI - Multi Asset Fund- Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-10-21",
    "closeData": "",
    "isin": "INF789F01VE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120761",
    "name": "UTI - Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "UTI Multi Asset Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-10-21",
    "closeData": "",
    "isin": "INF789F01VC0",
    "isinRe": "INF789F01VD8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111638",
    "name": "Edelweiss Long Term Equity Fund (Tax Savings)",
    "category": "Equity Scheme - ELSS",
    "navName": "Edelweiss Long Term Equity Fund (Tax Savings) - Regular Plan - Growth Option",
    "minAmount": "Rs. 500/- & in multiples of Rs. 500 thereafter",
    "launchDate": "2008-12-26",
    "closeData": "2008-12-26",
    "isin": "INF754K01517",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111639",
    "name": "Edelweiss Long Term Equity Fund (Tax Savings)",
    "category": "Equity Scheme - ELSS",
    "navName": "Edelweiss Long Term Equity Savings Fund (Tax Savings) - Regular Plan - IDCW Option",
    "minAmount": "Rs. 500/- & in multiples of Rs. 500 thereafter",
    "launchDate": "2008-12-26",
    "closeData": "2008-12-26",
    "isin": "INF754K01525",
    "isinRe": "INF754K01541"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118619",
    "name": "Edelweiss Long Term Equity Fund (Tax Savings)",
    "category": "Equity Scheme - ELSS",
    "navName": "Edelweiss Long Term Equity Savings Fund (Tax Savings) - Direct Plan - IDCW Option",
    "minAmount": "Rs. 500/- & in multiples of Rs. 500 thereafter",
    "launchDate": "2008-12-26",
    "closeData": "2008-12-26",
    "isin": "INF754K01BX2",
    "isinRe": "INF754K01BY0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118620",
    "name": "Edelweiss Long Term Equity Fund (Tax Savings)",
    "category": "Equity Scheme - ELSS",
    "navName": "Edelweiss Long Term Equity Fund (Tax Savings) - Direct Plan-Growth Option",
    "minAmount": "Rs. 500/- & in multiples of Rs. 500 thereafter",
    "launchDate": "2008-12-26",
    "closeData": "2008-12-26",
    "isin": "INF754K01CA8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "111644",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01812",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "111645",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01838",
    "isinRe": "INF769K01820"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "111646",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01788",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "111647",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund RegularDaily IDCW ",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01804",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118859",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01CM1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118860",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01CN9",
    "isinRe": "INF769K01CQ2"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118861",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund Direct  Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01CO7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118862",
    "name": "MIRAE ASSET CASH MANAGEMENT FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mirae Asset Cash Management Fund Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-01-05",
    "closeData": "2009-01-06",
    "isin": "INF769K01CP4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "113167",
    "name": "IDFC SSIF - MT -Plan B- Dividend Plan",
    "category": "Income",
    "navName": "IDFC SSIF - MT (Plan B) Inst - Quarterly Dividend Reinvestment",
    "minAmount": "Rs 1 00000/-",
    "launchDate": "2009-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "113168",
    "name": "IDFC SSIF - MT -Plan B- Dividend Plan",
    "category": "Income",
    "navName": "IDFC SSIF - MT (Plan B) Inst - Monthly Dividend Reinvestment",
    "minAmount": "Rs 1 00000/-",
    "launchDate": "2009-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111708",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-Regular Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01165",
    "isinRe": "INF761K01173"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111709",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-ECO Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01157",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111710",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01181",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111711",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-ECO Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01132",
    "isinRe": "INF761K01140"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119351",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01884",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119352",
    "name": "BANK OF INDIA TAX ADVANTAGE FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "BANK OF INDIA Tax Advantage Fund-Direct Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2008-12-12",
    "closeData": "2009-02-12",
    "isin": "INF761K01876",
    "isinRe": "INF761K01868"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111712",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Regular Plan-Growth",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01462",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111713",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Regular Plan-Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01504",
    "isinRe": "INF761K01496"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111714",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Regular Plan-Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01454",
    "isinRe": "INF761K01447"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111715",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-ECO Plan-Growth",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01397",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111716",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Regular Plan-Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01488",
    "isinRe": "INF761K01470"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111717",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-ECO Plan-Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01413",
    "isinRe": "INF761K01405"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "111718",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid  Fund-ECO Plan-Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01439",
    "isinRe": "INF761K01421"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119393",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund Fund-Direct Plan-Growth",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01819",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119394",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Direct Plan-Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01835",
    "isinRe": "INF761K01827"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119395",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid  Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01850",
    "isinRe": "INF761K01843"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119396",
    "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "BANK OF INDIA Conservative Hybrid Fund-Direct Plan-Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2009-01-28",
    "closeData": "2009-02-24",
    "isin": "INF761K01793",
    "isinRe": "INF761K01801"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111743",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund - Retail Plan - Growth Plan -Bonus Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EO1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111744",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - RETAIL Plan - DAILY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EP8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111745",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - RETAIL Plan - WEEKLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01OP7",
    "isinRe": "INF204K01EQ6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111746",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund -Growth Plan -Bonus Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EW4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111747",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DAILY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EX2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111748",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund - Retail Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EN3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125264",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204KA1EK7",
    "isinRe": "INF204KA1EL5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125265",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204KA1EI1",
    "isinRe": "INF204KA1EJ9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118702",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01G03",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118703",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ZV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118704",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ZX7",
    "isinRe": "INF204K01ZY5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118705",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ZZ2",
    "isinRe": "INF204K01A09"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118706",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01A17",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118709",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ZU3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111749",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EZ7",
    "isinRe": "INF204K01FA7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111750",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01FB5",
    "isinRe": "INF204K01FC3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111751",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - RETAIL Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ER4",
    "isinRe": "INF204K01ES2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111752",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - RETAIL Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01ET0",
    "isinRe": "INF204K01EU8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111753",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Nippon India Low Duration Fund- Growth Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01EV6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "111754",
    "name": "Nippon India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "NIPPON INDIA LOW DURATION FUND - WEEKLY IDCW Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter.",
    "launchDate": "2007-03-15",
    "closeData": "2007-03-20",
    "isin": "INF204K01OO0",
    "isinRe": "INF204K01EY0"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111787",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01864",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111788",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01831",
    "isinRe": "INF044D01849"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "111903",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund -  Regular Plan - Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01823",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118876",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01CJ0",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118877",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01CK8",
    "isinRe": "INF044D01CL6"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118878",
    "name": "Taurus Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Ethical Fund-Direct Plan-Bonus Option #",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2009-02-19",
    "closeData": "2009-03-20",
    "isin": "INF044D01CM4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111803",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - Growth - Regular Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01603",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111804",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - Institutional - Growth Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01660",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111809",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - REGULAR - Quarterly IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01637",
    "isinRe": "INF209KB1OJ2"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111811",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - REGULAR - HALFYEARLY IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01595",
    "isinRe": "INF209K01CZ0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "111812",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - Regular - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01611",
    "isinRe": "INF209K01DA1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119538",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - Direct - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KA1KO1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147816",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-Growth - Direct Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q15",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147817",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-DIRECT - HALF YEARLY IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q31",
    "isinRe": "INF209KB1R14"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147818",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-Direct - Quarterly IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q72",
    "isinRe": "INF209KB1R06"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147819",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-DIRECT - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1P81",
    "isinRe": "INF209KB1Q80"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147820",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium term Plan- Segregated Portfolio 1- Growth- Regular Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q23",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147821",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-REGULAR - HALFYEARLY IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q49",
    "isinRe": "INF209KB1Q56"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119539",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - Growth - Direct Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209K01XA9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119540",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - DIRECT - HALF YEARLY IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KA1KN3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119541",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan - DIRECT - Quarterly IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KA1KP8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147813",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1- Institutional- Growth Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147814",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-Regular - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1P99",
    "isinRe": "INF209KB1Q07"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147815",
    "name": "Aditya Birla Sun Life Medium Term Plan",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Aditya Birla Sun Life Medium Term Plan- Segregated Portfolio 1-REGULAR - Quarterly IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": "INF209KB1Q64",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "111881",
    "name": "Canara Robeco Short Term Fund",
    "category": "Income",
    "navName": "Canara Robeco Short Term Fund- Institutional Plan - Dividend Reinvestment",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "111884",
    "name": "Canara Robeco Short Term Fund",
    "category": "Income",
    "navName": "Canara Robeco Short Term Fund- Retail Plan - Dividend Reinvestment",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-03-23",
    "closeData": "2009-03-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111935",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund -Plan B - Growth option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01087",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111936",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01012",
    "isinRe": "INF754K01020"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111937",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund -Plan C - Growth option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01129",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111938",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Plan B - IDCW Option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01053",
    "isinRe": "INF754K01061"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111939",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Plan C - IDCW Option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01095",
    "isinRe": "INF754K01103"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "111940",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01046",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118616",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01BT0",
    "isinRe": "INF754K01BU8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118617",
    "name": "Edelweiss Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Edelweiss Large Cap Fund - Direct Plan-Growth option",
    "minAmount": "Rs 1000/- & multiples of Re.1/- thereafter",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-08",
    "isin": "INF754K01BW4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "111954",
    "name": "SBI Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "SBI Gold ETF",
    "minAmount": "5000",
    "launchDate": "2009-03-30",
    "closeData": "",
    "isin": "INF200KA16D8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111957",
    "name": "ICICI Prudential Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ICICI Prudential Focused Equity Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-04-15",
    "closeData": "2009-05-14",
    "isin": "INF109K01BZ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111958",
    "name": "ICICI Prudential Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ICICI Prudential Focused Equity Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-04-15",
    "closeData": "2009-05-14",
    "isin": "INF109K01FM3",
    "isinRe": "INF109K01CA5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111960",
    "name": "ICICI Prudential Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ICICI Prudential Target Returns Fund - Retail Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-04-15",
    "closeData": "2009-05-14",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120722",
    "name": "ICICI Prudential Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ICICI Prudential Focused Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-04-15",
    "closeData": "2009-05-14",
    "isin": "INF109K018N2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120723",
    "name": "ICICI Prudential Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ICICI Prudential Focused Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2009-04-15",
    "closeData": "2009-05-14",
    "isin": "INF109K016N6",
    "isinRe": "INF109K017N4"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "111962",
    "name": "Canara Robeco Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "CANARA ROBECO DYNAMIC BOND FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-20",
    "isin": "INF760K01449",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "111963",
    "name": "Canara Robeco Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "CANARA ROBECO DYNAMIC BOND FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-20",
    "isin": "INF760K01423",
    "isinRe": "INF760K01431"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118283",
    "name": "Canara Robeco Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "CANARA ROBECO DYNAMIC BOND FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-20",
    "isin": "INF760K01EA1",
    "isinRe": "INF760K01EB9"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118284",
    "name": "Canara Robeco Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "CANARA ROBECO DYNAMIC BOND FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Regular Plan-Rs.5000",
    "launchDate": "2009-05-04",
    "closeData": "2009-05-20",
    "isin": "INF760K01EC7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111972",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Growth",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CE7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111976",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Daily Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111977",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Weekly Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SE3",
    "isinRe": "INF109K01CG2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111978",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Fort Nightly Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SF0",
    "isinRe": "INF109K01CH0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111979",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Monthly Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01QM0",
    "isinRe": "INF109K01CI8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111980",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund Retail Quarterly Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01FQ4",
    "isinRe": "INF109K01CJ6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120696",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K014B6",
    "isinRe": "INF109K015B3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120697",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Monthly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K017B9",
    "isinRe": "INF109K018B7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130947",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Bonus",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109KA1I84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131151",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Half Yearly IDCW Option",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109KA1N12",
    "isinRe": "INF109KA1N04"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131152",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Half Yearly IDCW Option",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109KA1N38",
    "isinRe": "INF109KA1N20"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111991",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Monthly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SK0",
    "isinRe": "INF109K01CT5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111992",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund -Quarterly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SN4",
    "isinRe": "INF109K01SO2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120692",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Growth",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K016B1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120693",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Weekly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K010C2",
    "isinRe": "INF109K011C0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120694",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K019B5",
    "isinRe": "INF109K012C8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120695",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Direct Plan - Daily IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K013B8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111982",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Premium Daily Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CL2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111985",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Premium Monthly Dividend",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111987",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Growth",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111988",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Daily IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01CR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111989",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Weekly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SL8",
    "isinRe": "INF109K01SM6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111990",
    "name": "ICICI Prudential Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "ICICI Prudential Corporate Bond Fund - Fortnightly IDCW",
    "minAmount": "15000 Reg 5Cr Premium 200Cr SuperPre",
    "launchDate": "2009-06-10",
    "closeData": "",
    "isin": "INF109K01SJ2",
    "isinRe": "INF109K01CS7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111996",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Growth",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01CB3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111997",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Monthly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01FE0",
    "isinRe": "INF109K01CC1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "111998",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Quarterly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01FF7",
    "isinRe": "INF109K01CD9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112002",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Premium Plus Growth",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01RW7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112005",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Half Yearly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01PK6",
    "isinRe": "INF109K01IY2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122613",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Bonus",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109KA1350",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131199",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan - Bonus Option",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109KA1343",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131620",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan - Annual Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109KA17C1",
    "isinRe": "INF109KA16C3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131621",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Annual Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109KA15C5",
    "isinRe": "INF109KA14C8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113055",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Daily Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K01PJ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120580",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan -  Monthly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K010L3",
    "isinRe": "INF109K011L1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120581",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan -  Half Yearly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K018K8",
    "isinRe": "INF109K019K6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120582",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan -  Growth",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K017K0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120583",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K012L9",
    "isinRe": "INF109K013L7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120584",
    "name": "ICICI Prudential Dynamic Bond Fund",
    "category": "Income",
    "navName": "ICICI Prudential Dynamic Bond Fund - Direct Plan -  Daily Dividend",
    "minAmount": "15000",
    "launchDate": "2009-06-11",
    "closeData": "2009-06-12",
    "isin": "INF109K016K2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112012",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund- Plan B-Growth Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01160",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112013",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund  - Plan B - IDCW Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01137",
    "isinRe": "INF754K01145"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112108",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01202",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112109",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01178",
    "isinRe": "INF754K01186"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118624",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund-Direct Plan-Growth Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01CE0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118625",
    "name": "Edelweiss Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Edelweiss Aggressive Hybrid Fund  - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2009-06-08",
    "closeData": "2009-06-10",
    "isin": "INF754K01CB6",
    "isinRe": "INF754K01CC4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "112030",
    "name": "Baroda Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Baroda Pioneer Treasury Advantage Fund- Regular Plan - Dividend Option",
    "minAmount": "Regular - Rs.5000/- Institutional - Rs. 10000000/-",
    "launchDate": "2009-06-10",
    "closeData": "2009-06-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "112036",
    "name": "Sahara Super 20 Fund",
    "category": "Growth",
    "navName": "Sahara Super 20 Fund - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2009-06-25",
    "closeData": "2009-07-23",
    "isin": "INF515L01551",
    "isinRe": "INF515L01569"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "112037",
    "name": "Sahara Super 20 Fund",
    "category": "Growth",
    "navName": "Sahara Super 20 Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2009-06-25",
    "closeData": "2009-07-23",
    "isin": "INF515L01577",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120382",
    "name": "Sahara Super 20 Fund",
    "category": "Growth",
    "navName": "Sahara Super 20 Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2009-06-25",
    "closeData": "2009-07-23",
    "isin": "INF515L01AR9",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120383",
    "name": "Sahara Super 20 Fund",
    "category": "Growth",
    "navName": "Sahara Super 20 Fund - Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2009-06-25",
    "closeData": "2009-07-23",
    "isin": "INF515L01AP3",
    "isinRe": "INF515L01AQ1"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "112038",
    "name": "Quantum Equity Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Equity Fund Of Funds - Direct Plan IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2009-06-26",
    "closeData": "2009-07-13",
    "isin": "INF082J01101",
    "isinRe": "INF082J01119"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "112039",
    "name": "Quantum Equity Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Equity Fund of Funds - Direct Plan Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2009-06-26",
    "closeData": "2009-07-13",
    "isin": "INF082J01093",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141062",
    "name": "Quantum Equity Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Equity Fund Of Funds - Regular Plan IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2009-06-26",
    "closeData": "2009-07-13",
    "isin": "INF082J01283",
    "isinRe": "INF082J01291"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141063",
    "name": "Quantum Equity Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Equity Fund of Funds - Regular Plan Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2009-06-26",
    "closeData": "2009-07-13",
    "isin": "INF082J01275",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "112087",
    "name": "ADITYA BIRLA SUN LIFE ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Aditya Birla Sun Life Arbitrage Fund-Regular - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-07-14",
    "closeData": "2009-07-20",
    "isin": "INF209K01256",
    "isinRe": "INF209K01CI6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "112088",
    "name": "ADITYA BIRLA SUN LIFE ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Aditya BIRLA SUN LIFE ARBITRAGE FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-07-14",
    "closeData": "2009-07-20",
    "isin": "INF209K01264",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119525",
    "name": "ADITYA BIRLA SUN LIFE ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Aditya Birla Sun Life Arbitrage Fund-DIRECT - IDCW",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-07-14",
    "closeData": "2009-07-20",
    "isin": "INF209K01P80",
    "isinRe": "INF209K01VQ9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "119526",
    "name": "ADITYA BIRLA SUN LIFE ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Aditya Birla Sun Life Arbitrage Fund - Growth - Direct Plan",
    "minAmount": "Minimum 5000/- and in multiples of 1 thereafter",
    "launchDate": "2009-07-14",
    "closeData": "2009-07-20",
    "isin": "INF209K01VP1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "112089",
    "name": "Kotak Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Kotak Flexicap Fund - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2009-07-22",
    "closeData": "2009-08-20",
    "isin": "INF174K01351",
    "isinRe": "INF174K01344"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "112090",
    "name": "Kotak Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Kotak Flexicap Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2009-07-22",
    "closeData": "2009-08-20",
    "isin": "INF174K01336",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120165",
    "name": "Kotak Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Kotak Flexicap Fund - Payout of Income Distribution cum capital withdrawal option- Direct",
    "minAmount": "5000",
    "launchDate": "2009-07-22",
    "closeData": "2009-08-20",
    "isin": "INF174K01LQ6",
    "isinRe": "INF174K01LR4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120166",
    "name": "Kotak Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Kotak Flexicap Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2009-07-22",
    "closeData": "2009-08-20",
    "isin": "INF174K01LS2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "112092",
    "name": "Franklin Build India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Build India Fund Growth Plan",
    "minAmount": "5000",
    "launchDate": "2009-07-10",
    "closeData": "",
    "isin": "INF090I01AE7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "112093",
    "name": "Franklin Build India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Build India Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2009-07-10",
    "closeData": "",
    "isin": "INF090I01AC1",
    "isinRe": "INF090I01AD9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118556",
    "name": "Franklin Build India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Build India Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2009-07-10",
    "closeData": "",
    "isin": "INF090I01JD0",
    "isinRe": "INF090I01JE8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118557",
    "name": "Franklin Build India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Franklin Build India Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2009-07-10",
    "closeData": "",
    "isin": "INF090I01JF5",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112098",
    "name": "Invesco India Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Invesco India Largecap Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF205K01304",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112099",
    "name": "Invesco India Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Invesco India Largecap Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF205K01320",
    "isinRe": "INF205K01312"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120392",
    "name": "Invesco India Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Invesco India Largecap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF205K01LB0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120393",
    "name": "Invesco India Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Invesco India Largecap Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF205K01KZ1",
    "isinRe": "INF205K01LA2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112100",
    "name": "ICICI Prudential R.I.G.H.T (Rewards of Investing & Generation of Healthy Tax-Savings) Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential R.I.G.H.T. Fund Growth",
    "minAmount": "500",
    "launchDate": "2009-06-09",
    "closeData": "",
    "isin": "INF109KA1XP2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112101",
    "name": "ICICI Prudential R.I.G.H.T (Rewards of Investing & Generation of Healthy Tax-Savings) Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential R.I.G.H.T. Fund Dividend",
    "minAmount": "500",
    "launchDate": "2009-06-09",
    "closeData": "",
    "isin": "INF109KA1XQ0",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "112104",
    "name": "Sahara Star Value Fund",
    "category": "Growth",
    "navName": "Sahara Star Value Fund-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2009-07-30",
    "closeData": "2009-08-28",
    "isin": "INF515L01585",
    "isinRe": "INF515L01593"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "112105",
    "name": "Sahara Star Value Fund",
    "category": "Growth",
    "navName": "Sahara Star Value Fund-Growth Option",
    "minAmount": "5000",
    "launchDate": "2009-07-30",
    "closeData": "2009-08-28",
    "isin": "INF515L01601",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120386",
    "name": "Sahara Star Value Fund",
    "category": "Growth",
    "navName": "Sahara Star Value Fund-Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2009-07-30",
    "closeData": "2009-08-28",
    "isin": "INF515L01AU3",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120387",
    "name": "Sahara Star Value Fund",
    "category": "Growth",
    "navName": "Sahara Star Value Fund-Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2009-07-30",
    "closeData": "2009-08-28",
    "isin": "INF515L01AS7",
    "isinRe": "INF515L01AT5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112117",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01285",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "112118",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund - Regular Plan - Quarterly - IDCW Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01251",
    "isinRe": "INF754K01269"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118614",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund - Direct Plan - Quarterly - IDCW Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01BP8",
    "isinRe": "INF754K01BQ6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "118615",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund -Direct Plan-Growth Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01BS2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141767",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund - Direct Plan - Monthly - IDCW Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01IH0",
    "isinRe": "INF754K01II8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141766",
    "name": "Edelweiss Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Edelweiss Balanced Advantage Fund - Regular Plan - Monthly - IDCW Option",
    "minAmount": "Rs.1000/-& in multiples of Re.1/- thereafter",
    "launchDate": "2009-08-03",
    "closeData": "2009-08-07",
    "isin": "INF754K01IK4",
    "isinRe": "INF754K01IL2"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112120",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Regular - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01SG4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112121",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Religare Credit Opportunities Fund - Regular - Dividend Reinvestment",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112122",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Regular - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01SH2",
    "isinRe": "INF205K01SI0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112123",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01SD1",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120507",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01RY9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120508",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Direct Plan - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01RW3",
    "isinRe": "INF205K01RX1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120509",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01RZ6",
    "isinRe": "INF205K01SA7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112124",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Religare Credit Opportunities Fund - Institutional - Dividend Reinvestment",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112125",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01SE9",
    "isinRe": "INF205K01SF6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "115515",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01RU7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116488",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01UW7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116653",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01SB5",
    "isinRe": "INF205K01SC3"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120506",
    "name": "Invesco India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Invesco India Money Market Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2009-08-14",
    "closeData": "",
    "isin": "INF205K01RV5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "112126",
    "name": "DSP World Energy Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Energy Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF740K01284",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "112127",
    "name": "DSP World Energy Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Energy Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF740K01292",
    "isinRe": "INF740K01300"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119275",
    "name": "DSP World Energy Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Energy Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF740K01PC5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119276",
    "name": "DSP World Energy Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Energy Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-07-10",
    "closeData": "2009-07-31",
    "isin": "INF740K01PD3",
    "isinRe": "INF740K01PE1"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "112152",
    "name": "Canara Robeco Consumer Trends Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO CONSUMER TRENDS FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2009-07-20",
    "closeData": "2009-08-18",
    "isin": "INF760K01241",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "112153",
    "name": "Canara Robeco Consumer Trends Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO CONSUMER TRENDS FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2009-07-20",
    "closeData": "2009-08-18",
    "isin": "INF760K01225",
    "isinRe": "INF760K01233"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118273",
    "name": "Canara Robeco Consumer Trends Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO CONSUMER TRENDS FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2009-07-20",
    "closeData": "2009-08-18",
    "isin": "INF760K01DU1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118274",
    "name": "Canara Robeco Consumer Trends Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "CANARA ROBECO CONSUMER TRENDS FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and in multiples",
    "launchDate": "2009-07-20",
    "closeData": "2009-08-18",
    "isin": "INF760K01DS5",
    "isinRe": "INF760K01DT3"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112171",
    "name": "Invesco India PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India PSU Equity Fund - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1/- thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF205K01338",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112172",
    "name": "Invesco India PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Religare PSU Equity Fund - Dividend Payout",
    "minAmount": "Rs. 5000 and in multiples of Re.1/- thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112173",
    "name": "Invesco India PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India PSU Equity Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re.1/- thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF205K01353",
    "isinRe": "INF205K01346"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120394",
    "name": "Invesco India PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India PSU Equity Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re.1/- thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF205K01NE0",
    "isinRe": "INF205K01NF7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120395",
    "name": "Invesco India PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India PSU Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1/- thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF205K01NG5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112210",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Regular Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01412",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112211",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Regular Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01420",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112212",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01438",
    "isinRe": "INF846K01446"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112213",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01453",
    "isinRe": "INF846K01461"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112712",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Retail Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01362",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120391",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01CY2",
    "isinRe": "INF846K01CZ9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128954",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Direct plan - Bonus Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01NH4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128955",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Regular Plan - Bonus Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01NI2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112713",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Retail Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01354",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112714",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Retail Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01396",
    "isinRe": "INF846K01404"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112715",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Retail Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01370",
    "isinRe": "INF846K01388"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120388",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Direct Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01DA0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120389",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Direct Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01CX4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120390",
    "name": "Axis Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Axis Liquid Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs. 1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01DB8",
    "isinRe": "INF846K01DC6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112214",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01537",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112215",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Regular Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01545",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112216",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01552",
    "isinRe": "INF846K01560"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112217",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01578",
    "isinRe": "INF846K01586"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120513",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01EN1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120514",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01ER2",
    "isinRe": "INF846K01ES0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120515",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Direct Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01EQ4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120516",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01EO9",
    "isinRe": "INF846K01EP6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128960",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Bonus option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01NR3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128961",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Direct Plan - Bonus Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01NQ5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112717",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Retail Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01479",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112718",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Retail Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01511",
    "isinRe": "INF846K01529"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112719",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Retail Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01495",
    "isinRe": "INF846K01503"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112720",
    "name": "Axis Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Axis Treasury Advantage Fund - Retail Plan - Daily IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2009-10-08",
    "closeData": "2009-10-08",
    "isin": "INF846K01487",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120465",
    "name": "Axis Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Axis Bluechip Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2009-11-11",
    "closeData": "2009-12-08",
    "isin": "INF846K01DP8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120466",
    "name": "Axis Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Axis Bluechip Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2009-11-11",
    "closeData": "2009-12-08",
    "isin": "INF846K01DN3",
    "isinRe": "INF846K01DO1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112277",
    "name": "Axis Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Axis Bluechip Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2009-11-11",
    "closeData": "2009-12-08",
    "isin": "INF846K01164",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112278",
    "name": "Axis Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Axis Bluechip Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2009-11-11",
    "closeData": "2009-12-08",
    "isin": "INF846K01172",
    "isinRe": "INF846K01180"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "112293",
    "name": "DSP World Mining Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Mining Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-11-23",
    "closeData": "2009-12-18",
    "isin": "INF740K01730",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "112347",
    "name": "DSP World Mining Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Mining Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-11-23",
    "closeData": "2009-12-18",
    "isin": "INF740K01748",
    "isinRe": "INF740K01AL8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119279",
    "name": "DSP World Mining Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Mining Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-11-23",
    "closeData": "2009-12-18",
    "isin": "INF740K01PF8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119280",
    "name": "DSP World Mining Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Mining Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2009-11-23",
    "closeData": "2009-12-18",
    "isin": "INF740K01PG6",
    "isinRe": "INF740K01PH4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "112304",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01445",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "112305",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01452",
    "isinRe": "INF090I01460"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118554",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01JI9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118555",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01JG3",
    "isinRe": "INF090I01JH1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147968",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TF4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147969",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TE7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147962",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TB3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147963",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct Growth Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TD9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147964",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TA5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147965",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TC1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147966",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Direct Growth Plan",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TH0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147967",
    "name": "Franklin India Income Opportunities Fund (no. of segregated portfolios- 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Franklin India Income Opportunities Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-01",
    "closeData": "",
    "isin": "INF090I01TG2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112316",
    "name": "IDFC Fixed Maturity Plan  Seventeen Month Series 1",
    "category": "Income",
    "navName": "IDFC FMP  SMS - 1 Plan A Dividend",
    "minAmount": "Plan A  Rs. 5000/-  Plan B  Rs 1 00 00 000/-",
    "launchDate": "2009-12-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112322",
    "name": "Axis Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Axis Long Term Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Rs. 500",
    "launchDate": "2009-12-17",
    "closeData": "2009-12-21",
    "isin": "INF846K01149",
    "isinRe": "INF846K01156"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112323",
    "name": "Axis Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Axis Long Term Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Rs. 500",
    "launchDate": "2009-12-17",
    "closeData": "2009-12-21",
    "isin": "INF846K01131",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120502",
    "name": "Axis Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Axis Long Term Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Rs. 500",
    "launchDate": "2009-12-17",
    "closeData": "2009-12-21",
    "isin": "INF846K01EX0",
    "isinRe": "INF846K01EY8"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120503",
    "name": "Axis Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Axis Long Term Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Rs. 500",
    "launchDate": "2009-12-17",
    "closeData": "2009-12-21",
    "isin": "INF846K01EW2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112327",
    "name": "BANDHAN Asset Allocation Fund of Fund-Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Conservative Plan - Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01987",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112328",
    "name": "BANDHAN Asset Allocation Fund of Fund-Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Conservative Plan - IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01995",
    "isinRe": "INF194K01AA1"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118486",
    "name": "BANDHAN Asset Allocation Fund of Fund-Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Conservative Plan-Direct Plan-Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K016A9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118487",
    "name": "BANDHAN Asset Allocation Fund of Fund-Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Conservative Plan-Direct Plan-IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K017A7",
    "isinRe": "INF194K018A5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112329",
    "name": "BANDHAN Asset Allocation Fund of Fund-Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Moderate Plan - Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01AB9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112330",
    "name": "BANDHAN Asset Allocation Fund of Fund-Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Moderate Plan - IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01AC7",
    "isinRe": "INF194K01AD5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118488",
    "name": "BANDHAN Asset Allocation Fund of Fund-Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Moderate Plan-Direct Plan-IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K010B0",
    "isinRe": "INF194K011B8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118489",
    "name": "BANDHAN Asset Allocation Fund of Fund-Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Moderate Plan-Direct Plan-Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K019A3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112331",
    "name": "BANDHAN Asset Allocation Fund of Fund-Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Aggressive Plan - IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01961",
    "isinRe": "INF194K01979"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112332",
    "name": "BANDHAN Asset Allocation Fund of Fund-Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund - Regular Plan - Aggressive Plan - Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K01953",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118484",
    "name": "BANDHAN Asset Allocation Fund of Fund-Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Aggressive Plan-Direct Plan-IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K013B4",
    "isinRe": "INF194K014B2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118485",
    "name": "BANDHAN Asset Allocation Fund of Fund-Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BANDHAN Asset Allocation Fund Of Fund-Aggressive Plan-Direct Plan-Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2010-01-04",
    "closeData": "",
    "isin": "INF194K012B6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112342",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01RT3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112343",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund -  Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01WS5",
    "isinRe": "INF109K01RV9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112344",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund -  Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01WR7",
    "isinRe": "INF109K01RU1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113242",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund Retail Growth",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01II5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113243",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund Retail Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01WV9",
    "isinRe": "INF109K01IJ3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113247",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund Retail Daily Dividend",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01WW7",
    "isinRe": "INF109K01JM5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130950",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan Bonus",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109KA1B40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131147",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109KA1M70",
    "isinRe": "INF109KA1M62"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131148",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109KA1M96",
    "isinRe": "INF109KA1M88"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130897",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Bonus",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109KA1B57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "116174",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01YE1",
    "isinRe": "INF109K01YD3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120255",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan -  Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K013A0",
    "isinRe": "INF109K014A8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120256",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan -  Growth",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K010A6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120257",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan -  Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K01Z89",
    "isinRe": "INF109K01Z97"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120258",
    "name": "ICICI Prudential Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ICICI Prudential Banking and PSU Debt Fund - Direct Plan -  Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2009-12-28",
    "closeData": "",
    "isin": "INF109K011A4",
    "isinRe": "INF109K012A2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "112351",
    "name": "Kotak Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty 50 ETF",
    "minAmount": "10000",
    "launchDate": "2010-01-11",
    "closeData": "2010-01-19",
    "isin": "INF174K014P6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112352",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K01RY5",
    "isinRe": "INF194K01RX7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112353",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K01RW9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "113361",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K01SB1",
    "isinRe": "INF194K01SA3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118490",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K017B5",
    "isinRe": "INF194K016B7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118491",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K015B9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118492",
    "name": "BANDHAN REGULAR SAVINGS FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BANDHAN Regular Savings Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-01-11",
    "closeData": "",
    "isin": "INF194K010C8",
    "isinRe": "INF194K019B1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112354",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Regular Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01644",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112355",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01651",
    "isinRe": "INF846K01669"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112369",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01677",
    "isinRe": "INF846K01685"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112721",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Retail Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01594",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112722",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Retail Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01628",
    "isinRe": "INF846K01636"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112723",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Retail Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01602",
    "isinRe": "INF846K01610"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "133895",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Regular Plan - Regular IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01RJ1",
    "isinRe": "INF846K01RK9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120510",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Direct Plan - Growth Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01EI1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120511",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01EJ9",
    "isinRe": "INF846K01EK7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120512",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01EL5",
    "isinRe": "INF846K01EM3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128950",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Regular Plan - Bonus Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01NS1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128951",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Direct plan - Bonus Option",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01NP7",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "133894",
    "name": "Axis Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Axis Short Term Fund - Direct Plan - Regular IDCW",
    "minAmount": "Retail  Rs.5000 Institutional  Rs.1 Crore",
    "launchDate": "2010-01-19",
    "closeData": "",
    "isin": "INF846K01RH5",
    "isinRe": "INF846K01RI3"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "112359",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01199",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "112360",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01231",
    "isinRe": "INF761K01223"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "112361",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01215",
    "isinRe": "INF761K01207"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119363",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Direct Plan-Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01694",
    "isinRe": "INF761K01686"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119364",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01702",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "119365",
    "name": "BANK OF INDIA MANUFACTURING & INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANK OF INDIA Manufacturing & Infrastructure Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-01-20",
    "closeData": "2010-02-15",
    "isin": "INF761K01728",
    "isinRe": "INF761K01710"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112368",
    "name": "Invesco India Gold Exchange Traded Fund",
    "category": "Other Scheme - Gold ETF",
    "navName": "Invesco India Gold Exchange Traded Fund",
    "minAmount": "RI 5000 & Other Rs. 15 Lcas / 1 Kg of Gold",
    "launchDate": "2010-01-28",
    "closeData": "",
    "isin": "INF205K01361",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "112636",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund-Regular Plan-Growth Option",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GL9",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "112645",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Regular Plan Daily IDCW",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GM7",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "112646",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Regular Plan Weekly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GP0",
    "isinRe": "INF959L01GQ8"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "112647",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Regular Plan Monthly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GN5",
    "isinRe": "INF959L01GO3"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119161",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Direct Plan Monthly IDCW - Payout",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GG9",
    "isinRe": "INF959L01GH7"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119162",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Direct Plan Weekly IDCW - Payout",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GI5",
    "isinRe": "INF959L01GJ3"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119163",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund - Direct Plan Daily IDCW - ReInvestment",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GK1",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119164",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund-Direct Plan-Growth Option",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01GF1",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "139386",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund-Unclaimed Redemption and Dividend Plan less than 3 years",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01CN4",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "140437",
    "name": "Navi Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Navi Liquid Fund-Unclaimed Redemption and Dividend Plan greater than 3 years",
    "minAmount": "1000",
    "launchDate": "2010-02-17",
    "closeData": "2010-02-18",
    "isin": "INF959L01CO2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112790",
    "name": "Religare Fixed Maturity Plan - Series II - Plan A to F - Plan C (15 Months)",
    "category": "Income",
    "navName": "Religare Fixed Maturity Plan - Series II - Plan A - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2010-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "112867",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Regular Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01HC8",
    "isinRe": "INF903J01HF1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "112868",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Regular Plan - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01HB0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119635",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Direct Plan - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01OP6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119636",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Direct Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01OQ4",
    "isinRe": "INF903J01OT8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119637",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Direct Plan - Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01OR2",
    "isinRe": "INF903J01OU6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119638",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Direct Plan - Half yearly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01OS0",
    "isinRe": "INF903J01OV4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "112869",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Regular Plan - Half yearly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01HE4",
    "isinRe": "INF903J01HH7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "112870",
    "name": "Sundaram Debt Oriented Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Sundaram Debt Oriented Hybrid Fund Regular Plan - Quarterly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2010-01-21",
    "closeData": "",
    "isin": "INF903J01HD6",
    "isinRe": "INF903J01HG9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118483",
    "name": "BANDHAN Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN Nifty 50 Index Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": "INF194K013A6",
    "isinRe": "INF194K014A4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112877",
    "name": "BANDHAN Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN Nifty 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": "INF194K01920",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "112878",
    "name": "BANDHAN Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN Nifty 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": "INF194K01938",
    "isinRe": "INF194K01946"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118482",
    "name": "BANDHAN Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN Nifty 50 Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": "INF194K012A8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112885",
    "name": "Religare Invesco Monthly Income Plan",
    "category": "Income",
    "navName": "Religare Monthly Income Plan - Monthly Dividend Reinvestment Option",
    "minAmount": "Growth opt.  Rs. 5000. Dividend opt  Rs. 25000",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "112889",
    "name": "Invesco India Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Religare Monthly Income Plan (MIP) Plus - Monthly Dividend Reinvestment",
    "minAmount": "Growth opt  Rs. 5000. Dividend opt  Rs. 25000",
    "launchDate": "2010-04-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "112901",
    "name": "DSP Focus Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "DSP Focus Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2010-04-23",
    "closeData": "",
    "isin": "INF740K01532",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "113032",
    "name": "DSP Focus Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "DSP Focus Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2010-04-23",
    "closeData": "",
    "isin": "INF740K01540",
    "isinRe": "INF740K01AC7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119095",
    "name": "DSP Focus Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "DSP Focus Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2010-04-23",
    "closeData": "",
    "isin": "INF740K01OC8",
    "isinRe": "INF740K01OD6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119096",
    "name": "DSP Focus Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "DSP Focus Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2010-04-23",
    "closeData": "",
    "isin": "INF740K01OB0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "112904",
    "name": "Baroda Large cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Baroda Pioneer Infrastructure Fund",
    "minAmount": "Rs. 5000 and in multiple of Re. 1/- thereafter",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "112915",
    "name": "IDBI NIFTY 50 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty Index Fund",
    "minAmount": "5000",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113062",
    "name": "IDBI NIFTY 50 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty 50  Index Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": "INF397L01083",
    "isinRe": "INF397L01075"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113063",
    "name": "IDBI NIFTY 50 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI NIFTY 50 Index Fund Growth",
    "minAmount": "5000",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": "INF397L01091",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118347",
    "name": "IDBI NIFTY 50 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI NIFTY 50  Index Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": "INF397L01AP6",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118455",
    "name": "IDBI NIFTY 50 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty 50 Index Fund IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-05-03",
    "closeData": "2010-05-31",
    "isin": "INF397L01AN1",
    "isinRe": "INF397L01AO9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "112923",
    "name": "SBI PSU FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI PSU Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-05-17",
    "closeData": "2010-06-14",
    "isin": "INF200K01BD8",
    "isinRe": "INF200K01BE6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "113099",
    "name": "SBI PSU FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI PSU Fund - REGULAR PLAN -Growth",
    "minAmount": "5000",
    "launchDate": "2010-05-17",
    "closeData": "2010-06-14",
    "isin": "INF200K01BC0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119732",
    "name": "SBI PSU FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI PSU Fund - DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2010-05-17",
    "closeData": "2010-06-14",
    "isin": "INF200K01UY4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119733",
    "name": "SBI PSU FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI PSU Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2010-05-17",
    "closeData": "2010-06-14",
    "isin": "INF200K01UW8",
    "isinRe": "INF200K01UX6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112924",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01693",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112925",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01701",
    "isinRe": "INF846K01719"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112926",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Regular Plan - Half Yearly IDCW",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01727",
    "isinRe": "INF846K01735"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112927",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01743",
    "isinRe": "INF846K01750"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120477",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01DY0",
    "isinRe": "INF846K01DZ7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120478",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Direct Plan - Half Yearly",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01EB6",
    "isinRe": "INF846K01EC4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120479",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund - Direct Plan - Quarterly",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01ED2",
    "isinRe": "INF846K01EE0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120480",
    "name": "Axis Regular Saver Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Axis Regular Saver Fund- Direct Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of  Re. 1/- thereafter",
    "launchDate": "2010-05-24",
    "closeData": "",
    "isin": "INF846K01EA8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "112931",
    "name": "MIRAE ASSET EMERGING BLUECHIP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mirae Asset Emerging Bluechip Fund Regular IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2010-05-24",
    "closeData": "2010-06-22",
    "isin": "INF769K01127",
    "isinRe": "INF769K01119"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "112932",
    "name": "MIRAE ASSET EMERGING BLUECHIP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mirae Asset Emerging Bluechip Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/-",
    "launchDate": "2010-05-24",
    "closeData": "2010-06-22",
    "isin": "INF769K01101",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118834",
    "name": "MIRAE ASSET EMERGING BLUECHIP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mirae Asset Emerging Bluechip Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000/-",
    "launchDate": "2010-05-24",
    "closeData": "2010-06-22",
    "isin": "INF769K01BI1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118835",
    "name": "MIRAE ASSET EMERGING BLUECHIP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mirae Asset Emerging Bluechip Fund Direct IDCW",
    "minAmount": "Rs 5000/-",
    "launchDate": "2010-05-24",
    "closeData": "2010-06-22",
    "isin": "INF769K01BJ9",
    "isinRe": "INF769K01BK7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112936",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund - Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01FW1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112937",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01FX9",
    "isinRe": "INF204K01FY7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118793",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - DIRECT Plan - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01B16",
    "isinRe": "INF204K01B24"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118794",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund  - Direct Plan Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01B08",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122765",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K013R8",
    "isinRe": "INF204K014R6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122766",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K011R2",
    "isinRe": "INF204K012R0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148267",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB18T0",
    "isinRe": "INF204KB19T8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148268",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB15U4",
    "isinRe": "INF204KB16U2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148269",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB11U3",
    "isinRe": "INF204KB12U1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148270",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB14T9",
    "isinRe": "INF204KB15T6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148271",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund - Segregated Portfolio 2 - Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB10U5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148272",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB13U9",
    "isinRe": "INF204KB14U7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147687",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB18G7",
    "isinRe": "INF204KB19G5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147688",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB14G6",
    "isinRe": "INF204KB15G3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147689",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund - Segregated Portfolio 1 - Direct Plan - Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB16F3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147690",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - Direct Plan - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB19F7",
    "isinRe": "INF204KB10G4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148265",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund - Segregated Portfolio 2 - Direct Plan - Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB13T1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148266",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB16T4",
    "isinRe": "INF204KB17T2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139679",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB1BD7",
    "isinRe": "INF204KB1BE5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139680",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB1BF2",
    "isinRe": "INF204KB1BG0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147683",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB17F1",
    "isinRe": "INF204KB18F9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147684",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB11G2",
    "isinRe": "INF204KB12G0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147685",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Nippon India Equity Hybrid Fund - Segregated Portfolio 1 - Growth Plan",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB13G8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147686",
    "name": "Nippon India Equity Hybrid Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 1 - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB16G1",
    "isinRe": "INF204KB17G9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112938",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund  - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01FQ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112939",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund  - Institutional Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01FT7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "112941",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01FR1",
    "isinRe": "INF204K01FS9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148260",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB11Q1",
    "isinRe": "INF204KB12Q9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148261",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 2 - Direct Plan - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB14P7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148262",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB17P0",
    "isinRe": "INF204KB18P8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148263",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND -  SEGREGATED PORTFOLIO 2 - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB13Q7",
    "isinRe": "INF204KB14Q5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148264",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB15P4",
    "isinRe": "INF204KB16P2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148097",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - SEGREGATED PORTFOLIO 1 - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB11M0",
    "isinRe": "INF204KB12M8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148098",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB13L8",
    "isinRe": "INF204KB14L6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148100",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 1 - Institutional Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB18L7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148101",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 1 - Direct Plan - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB12L0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148258",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 2 - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB19P6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148259",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 2 - Institutional Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB10Q3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148094",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Segregated Portfolio 1 - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB17L9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148095",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB19L5",
    "isinRe": "INF204KB10M2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148096",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KB15L3",
    "isinRe": "INF204KB16L1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118780",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Nippon India Credit Risk Fund - Direct Plan - Growth Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01A74",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118781",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204K01A82",
    "isinRe": "INF204K01A90"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132871",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KA1WT0",
    "isinRe": "INF204KA1WS2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132873",
    "name": "Nippon India Credit Risk Fund (Number of Segregated Portfolios- 2)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "NIPPON INDIA CREDIT RISK FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Re.1 thereafter",
    "launchDate": "2005-05-10",
    "closeData": "",
    "isin": "INF204KA1WV6",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "112948",
    "name": "Taurus Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Taurus Nifty 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2010-06-04",
    "closeData": "2010-06-10",
    "isin": "INF044D01948",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "112949",
    "name": "Taurus Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Taurus Nifty 50 Index Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2010-06-04",
    "closeData": "2010-06-10",
    "isin": "INF044D01914",
    "isinRe": "INF044D01922"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118881",
    "name": "Taurus Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Taurus Nifty 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2010-06-04",
    "closeData": "2010-06-10",
    "isin": "INF044D01CQ5",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118882",
    "name": "Taurus Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Taurus Nifty 50 Index Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs 5000 and in mulitples of Re 1 thereafter",
    "launchDate": "2010-06-04",
    "closeData": "2010-06-10",
    "isin": "INF044D01CR3",
    "isinRe": "INF044D01CS1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112957",
    "name": "ICICI Prudential Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Next 50 Index Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2010-06-10",
    "closeData": "",
    "isin": "INF109K01IF1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "112958",
    "name": "ICICI Prudential Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Next 50 Index Fund -IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2010-06-10",
    "closeData": "",
    "isin": "INF109K01PR1",
    "isinRe": "INF109K01IE4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120683",
    "name": "ICICI Prudential Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Next 50 Index Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2010-06-10",
    "closeData": "",
    "isin": "INF109K01Y64",
    "isinRe": "INF109K01Y72"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120684",
    "name": "ICICI Prudential Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Next 50 Index Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2010-06-10",
    "closeData": "",
    "isin": "INF109K01Y80",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112980",
    "name": "Axis Fixed Term Plan - Series 3 (3 Months)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 3(3 Months) - Retail Option -  Growth",
    "minAmount": "Rs. 5000 in multiples of Re. 10/- thereafter",
    "launchDate": "2010-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112981",
    "name": "Axis Fixed Term Plan - Series 3 (3 Months)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 3(3 Months) - Retail Option -  Dividend",
    "minAmount": "Rs. 5000 in multiples of Re. 10/- thereafter",
    "launchDate": "2010-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112984",
    "name": "Axis Fixed Term Plan - Series 4 (3 Months)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 4(3 Months) - Retail Option -  Growth",
    "minAmount": "Rs. 5000 in multiples of Re. 10/- thereafter",
    "launchDate": "2010-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "112985",
    "name": "Axis Fixed Term Plan - Series 4 (3 Months)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 4(3 Months) - Retail Option -  Dividend",
    "minAmount": "Rs. 5000 in multiples of Re. 10/- thereafter",
    "launchDate": "2010-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "113016",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Peerless Income plus Fund",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "113141",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund - Monthly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01098",
    "isinRe": "INF959L01106"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "113142",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund-Growth",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01130",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "113143",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund - Quarterly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01114",
    "isinRe": "INF959L01122"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119156",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund-Direct Plan-Growth Option",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01676",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119157",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund - Direct Plan Monthly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01684",
    "isinRe": "INF959L01692"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "119158",
    "name": "Navi Regular Savings Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Navi Regular Savings Fund - Direct Plan Quarterly IDCW",
    "minAmount": "1000",
    "launchDate": "2010-06-09",
    "closeData": "2010-07-08",
    "isin": "INF959L01700",
    "isinRe": "INF959L01718"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "113018",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda Pioneer Short Term Bond Fund",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "113036",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01153",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "113037",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01138",
    "isinRe": "INF955L01146"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119399",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01BB9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119400",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01BD5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "140462",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Regular Plan - Quarterly Dividend Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01GT0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "140463",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01GU8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "140464",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Direct Plan - Quarterly Dividend Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01GV6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "140465",
    "name": "Baroda BNP Paribas Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Baroda BNP Paribas Short Duration Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2010-06-14",
    "closeData": "2010-06-24",
    "isin": "INF955L01GS2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "113047",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": "INF179K01CU6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "113048",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Fortnightly IDCW Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": "INF179K01CV4",
    "isinRe": "INF179K01CW2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119015",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Fortnightly IDCW - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119016",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": "INF179K01YM7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133367",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Normal IDCW - Direct Plan",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": "INF179KA1Q53",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133368",
    "name": "HDFC Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HDFC Short Term  Debt Fund - Normal IDCW Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-21",
    "closeData": "2010-06-24",
    "isin": "INF179KA1Q79",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "113049",
    "name": "HDFC Gold Exchange Traded Fund.",
    "category": "Other Scheme - Gold ETF",
    "navName": "HDFC Gold Exchange Traded Fund. - Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2010-06-25",
    "closeData": "2010-07-23",
    "isin": "INF179KC1981",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "113064",
    "name": "Axis Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Axis Multi Asset Allocation Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2010-06-30",
    "closeData": "",
    "isin": "INF846K01768",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "113065",
    "name": "Axis Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Axis Multi Asset Allocation Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2010-06-30",
    "closeData": "",
    "isin": "INF846K01776",
    "isinRe": "INF846K01784"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120523",
    "name": "Axis Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Axis Multi Asset Allocation Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2010-06-30",
    "closeData": "",
    "isin": "INF846K01ET8",
    "isinRe": "INF846K01EU6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120524",
    "name": "Axis Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Axis Multi Asset Allocation Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2010-06-30",
    "closeData": "",
    "isin": "INF846K01EV4",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "113069",
    "name": "Motilal Oswal Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nifty 50 ETF",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereof",
    "launchDate": "2010-06-30",
    "closeData": "2010-07-19",
    "isin": "INF247L01536",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "113126",
    "name": "Motilal Oswal Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal MOSt Shares M50 ETF (MOSt Shares M50)",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereof",
    "launchDate": "2010-06-30",
    "closeData": "2010-07-19",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "132848",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179KA1D33",
    "isinRe": "INF179KA1D25"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "132849",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179KA1D17",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "113070",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179K01DC2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "113071",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179K01DD0",
    "isinRe": "INF179K01DE8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118986",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179K01XB2",
    "isinRe": "INF179K01XC0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "118987",
    "name": "HDFC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HDFC Corporate Bond Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2010-06-25",
    "closeData": "",
    "isin": "INF179K01XD8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "113076",
    "name": "ICICI Prudential Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "ICICI Prudential Gold ETF",
    "minAmount": "Rs.5000",
    "launchDate": "2010-06-30",
    "closeData": "",
    "isin": "INF109KC1NT3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "113077",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789F01JQ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "113078",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789F01JO0",
    "isinRe": "INF789F01JP7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120762",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789F01XD4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120763",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789F01XB8",
    "isinRe": "INF789F01XC6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133180",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Direct Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1S81",
    "isinRe": "INF789FA1S99"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133852",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Regular Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1S08",
    "isinRe": "INF789FA1S16"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134788",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1S40",
    "isinRe": "INF789FA1S57"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135336",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Regular Plan - Flexi IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1S24",
    "isinRe": "INF789FA1S32"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135536",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Direct Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1S65",
    "isinRe": "INF789FA1S73"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139181",
    "name": "UTI - Dynamic Bond Fund.",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-06-16",
    "closeData": "2010-06-23",
    "isin": "INF789FA1R82",
    "isinRe": "INF789FA1R90"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "126164",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund Bonus Regular",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01FO8",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113104",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01976",
    "isinRe": "INF397L01968"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118345",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01AH3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118449",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund- Monthly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01AK7",
    "isinRe": "INF397L01AJ9"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118450",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Daily IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01AI1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118451",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Weekly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01AM3",
    "isinRe": "INF397L01AL5"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "126163",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund Bonus Direct",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01FP5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113095",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Dividend",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113096",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01984",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113102",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01935",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113103",
    "name": "IDBI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IDBI Liquid Fund-Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-07-07",
    "closeData": "2010-07-08",
    "isin": "INF397L01950",
    "isinRe": "INF397L01943"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "113192",
    "name": "Essel Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Peerless short term plan growth",
    "minAmount": "Rs. 1000",
    "launchDate": "2010-08-17",
    "closeData": "2010-08-17",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113177",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Nippon India Small Cap Fund - Growth Plan - Growth Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01HY3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113178",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Nippon India Small Cap Fund - Growth Plan - Bonus Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01HZ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113179",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "NIPPON INDIA SMALL CAP FUND - IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01IA1",
    "isinRe": "INF204K01IB9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113180",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Reliance Small Cap Fund - Dividend Plan - Dividend Reinvestment Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118775",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "NIPPON INDIA SMALL CAP FUND - Direct Plan - IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01J91",
    "isinRe": "INF204K01K07"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118777",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Nippon India Small Cap Fund - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01J83",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118778",
    "name": "Nippon India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Nippon India Small Cap Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "5000 and in multiples of Re.1/-",
    "launchDate": "2010-08-26",
    "closeData": "",
    "isin": "INF204K01K15",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "113221",
    "name": "Canara Robeco Bluechip Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "CANARA ROBECO BLUE CHIP EQUITY FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00",
    "launchDate": "2010-06-28",
    "closeData": "2010-07-27",
    "isin": "INF760K01AR3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "113222",
    "name": "Canara Robeco Bluechip Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "CANARA ROBECO BLUE CHIP EQUITY FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00",
    "launchDate": "2010-06-28",
    "closeData": "2010-07-27",
    "isin": "INF760K01AS1",
    "isinRe": "INF760K01AT9"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118269",
    "name": "Canara Robeco Bluechip Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "CANARA ROBECO BLUE CHIP EQUITY FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00",
    "launchDate": "2010-06-28",
    "closeData": "2010-07-27",
    "isin": "INF760K01FR2",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118270",
    "name": "Canara Robeco Bluechip Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "CANARA ROBECO BLUE CHIP EQUITY FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00",
    "launchDate": "2010-06-28",
    "closeData": "2010-07-27",
    "isin": "INF760K01FP6",
    "isinRe": "INF760K01FQ4"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113249",
    "name": "IDBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty Next 50 Index Fund Growth",
    "minAmount": "5000",
    "launchDate": "2010-09-02",
    "closeData": "2010-09-15",
    "isin": "INF397L01174",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113250",
    "name": "IDBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty Next 50  Index Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2010-09-02",
    "closeData": "2010-09-15",
    "isin": "INF397L01166",
    "isinRe": "INF397L01182"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118348",
    "name": "IDBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty Next 50 Index Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-02",
    "closeData": "2010-09-15",
    "isin": "INF397L01AW2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118456",
    "name": "IDBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "IDBI Nifty Next 50 Index Fund IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-02",
    "closeData": "2010-09-15",
    "isin": "INF397L01AY8",
    "isinRe": "INF397L01AX0"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113251",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Growth",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01745",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113252",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01695",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113253",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01737",
    "isinRe": "INF397L01729"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "113254",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01711",
    "isinRe": "INF397L01703"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118350",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Growth Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01AV4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118459",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Daily IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01AQ4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118460",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Monthly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01AS0",
    "isinRe": "INF397L01AR2"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118461",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Weekly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01AU6",
    "isinRe": "INF397L01AT8"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "126165",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Bonus Regular",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01FM2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "126166",
    "name": "IDBI Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "IDBI UST Bonus Direct",
    "minAmount": "5000",
    "launchDate": "2010-09-01",
    "closeData": "2010-09-02",
    "isin": "INF397L01FN0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113269",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - S&P BSE Sensex Plan - Growth Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IM6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113270",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - S&P BSE Sensex Plan - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IN4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113271",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - QUARTERLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IO2",
    "isinRe": "INF204K01IR5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113294",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - HALF YEARLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IP9",
    "isinRe": "INF204K01IS3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113295",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - ANNUAL - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IQ7",
    "isinRe": "INF204K01IT1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118785",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - S&P BSE Sensex Plan - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01K56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118786",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - DIRECT Plan - ANNUAL - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01K64",
    "isinRe": "INF204K01K72"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118788",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - DIRECT Plan - HALF YEARLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01K98",
    "isinRe": "INF204K01L06"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118790",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - S&P BSE SENSEX PLAN - DIRECT Plan - QUARTERLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01L14",
    "isinRe": "INF204K01L22"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118791",
    "name": "Nippon India Index Fund - S&P BSE Sensex Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - S&P BSE Sensex Plan - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/-",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01K80",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118745",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - DIRECT Plan - HALF YEARLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01H44",
    "isinRe": "INF204K01H51"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113299",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - HALF YEARLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IH6",
    "isinRe": "INF204K01IK0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113300",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - ANNUAL - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01II4",
    "isinRe": "INF204K01IL8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118740",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - DIRECT Plan - QUARTERLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01H69",
    "isinRe": "INF204K01H77"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118741",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - Nifty 50 Plan - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01H36",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118742",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - Nifty 50 Plan - Direct Plan Growth Plan - Bonus Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01H02",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118743",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - DIRECT Plan - ANNUAL - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01H10",
    "isinRe": "INF204K01H28"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113296",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - Nifty 50 Plan - Growth Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IE3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113297",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Index Fund - Nifty 50 Plan - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IF0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113298",
    "name": "Nippon India Index Fund - Nifty 50 Plan",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - QUARTERLY - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1/- therafter",
    "launchDate": "2010-09-09",
    "closeData": "",
    "isin": "INF204K01IG8",
    "isinRe": "INF204K01IJ2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "113310",
    "name": "IDFC Fixed Maturity Plan Monthly Series 25",
    "category": "Income",
    "navName": "IDFC FMP MS 25   Growth",
    "minAmount": "Rs 10000 and multiples of Re 10/-",
    "launchDate": "2010-09-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "113326",
    "name": "Baroda Mid-cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Baroda Pioneer PSU Equity Fund",
    "minAmount": "5000",
    "launchDate": "2010-09-13",
    "closeData": "2010-09-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113345",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Nippon India Arbitrage Fund - Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204K01IY1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113346",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NIPPON INDIA ARBITRAGE FUND - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204K01IZ8",
    "isinRe": "INF204K01JA9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "113347",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Reliance Arbitrage Advantage Fund - Dividend Plan - Dividend Reinvestment Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118585",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Nippon India Arbitrage Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204K01XZ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118587",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NIPPON INDIA ARBITRAGE FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204K01YA8",
    "isinRe": "INF204K01YB6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128820",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NIPPON INDIA ARBITRAGE FUND - MONTHLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204KA1MT1",
    "isinRe": "INF204KA1MU9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128821",
    "name": "Nippon India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NIPPON INDIA ARBITRAGE FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2010-09-24",
    "closeData": "",
    "isin": "INF204KA1MV7",
    "isinRe": "INF204KA1MW5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "113434",
    "name": "Axis Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "Axis Gold ETF",
    "minAmount": "Rs. 5000/- in case of AP - one kg. of gold",
    "launchDate": "2010-10-20",
    "closeData": "",
    "isin": "INF846K01W80",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "114225",
    "name": "IDFC Fixed Maturity Plan - Half Yearly Series 12",
    "category": "Income",
    "navName": "DFC FMP - HYS - 12 (Plan A) - Dividend Option",
    "minAmount": "Rs. 10000",
    "launchDate": "2010-11-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114239",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Growth",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01GU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114240",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01GV2",
    "isinRe": "INF109K01GW0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "114241",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Half Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01GX8",
    "isinRe": "INF109K01GY6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120709",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01V34",
    "isinRe": "INF109K01V42"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120710",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01V18",
    "isinRe": "INF109K01V26"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120711",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Direct Plan - Growth",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109K01V00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130943",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Bonus",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109KA1I27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130944",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Direct Plan Bonus",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109KA1I19",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134341",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Direct Plan - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109KB1AD5",
    "isinRe": "INF109KB1AE3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134342",
    "name": "ICICI Prudential Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "ICICI Prudential Credit Risk Fund - Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-15",
    "closeData": "",
    "isin": "INF109KB1AF0",
    "isinRe": "INF109KB1AG8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "114359",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TM0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "114360",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TP3",
    "isinRe": "INF205K01TQ1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "114361",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TD9",
    "isinRe": "INF205K01TE7"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "114362",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TN8",
    "isinRe": "INF205K01TO6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120541",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TH0",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120542",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TI8",
    "isinRe": "INF205K01TJ6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120543",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Direct Plan - Annual IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TF4",
    "isinRe": "INF205K01TG2"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120544",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Direct Plan - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K01TK4",
    "isinRe": "INF205K01TL2"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "136111",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K018H7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "136112",
    "name": "Invesco India Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Invesco India Ultra Short Term Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2010-12-13",
    "closeData": "",
    "isin": "INF205K019H5",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "114456",
    "name": "Motilal Oswal Nifty Midcap 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nifty Midcap 100 ETF",
    "minAmount": "10000",
    "launchDate": "2011-01-12",
    "closeData": "2011-01-24",
    "isin": "INF247L01023",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114457",
    "name": "Kotak Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Kotak Bluechip Fund - Payout of Income Distribution cumcapital withdrawal option",
    "minAmount": "5000",
    "launchDate": "1998-12-22",
    "closeData": "1998-12-22",
    "isin": "INF174K01179",
    "isinRe": "INF174K01161"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114458",
    "name": "Kotak Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Kotak Bluechip Fund - Growth",
    "minAmount": "5000",
    "launchDate": "1998-12-22",
    "closeData": "1998-12-22",
    "isin": "INF174K01153",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120152",
    "name": "Kotak Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Kotak Bluechip Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "1998-12-22",
    "closeData": "1998-12-22",
    "isin": "INF174K01KW6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120153",
    "name": "Kotak Bluechip Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Kotak Bluechip Fund - Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "1998-12-22",
    "closeData": "1998-12-22",
    "isin": "INF174K01KY2",
    "isinRe": "INF174K01KX4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "114476",
    "name": "BANDHAN Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN Infrastructure Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- per application",
    "launchDate": "2011-02-07",
    "closeData": "",
    "isin": "INF194K01BY9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "114477",
    "name": "BANDHAN Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN Infrastructure Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- per application",
    "launchDate": "2011-02-07",
    "closeData": "",
    "isin": "INF194K01BZ6",
    "isinRe": "INF194K01CA7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118468",
    "name": "BANDHAN Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN Infrastructure Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 1000/- per application",
    "launchDate": "2011-02-07",
    "closeData": "",
    "isin": "INF194K01X53",
    "isinRe": "INF194K01X61"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "118469",
    "name": "BANDHAN Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN Infrastructure Fund-Direct Plan-Growth",
    "minAmount": "Rs. 1000/- per application",
    "launchDate": "2011-02-07",
    "closeData": "",
    "isin": "INF194K01X46",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "114564",
    "name": "Axis Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Axis Midcap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Re 1/- thereafter",
    "launchDate": "2011-01-31",
    "closeData": "2011-02-14",
    "isin": "INF846K01859",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "114565",
    "name": "Axis Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Axis Midcap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 in multiples of Re 1/- thereafter",
    "launchDate": "2011-01-31",
    "closeData": "2011-02-14",
    "isin": "INF846K01867",
    "isinRe": "INF846K01875"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120504",
    "name": "Axis Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Axis Midcap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 in multiples of Re 1/- thereafter",
    "launchDate": "2011-01-31",
    "closeData": "2011-02-14",
    "isin": "INF846K01EF7",
    "isinRe": "INF846K01EG5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120505",
    "name": "Axis Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Axis Midcap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Re 1/- thereafter",
    "launchDate": "2011-01-31",
    "closeData": "2011-02-14",
    "isin": "INF846K01EH3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "114586",
    "name": "DHFL Pramerica Short Term Income Fund",
    "category": "Income",
    "navName": "Pramerica Short Term Income Fund - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2011-01-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "114616",
    "name": "Nippon India Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Gold Savings Fund-Growth plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter.",
    "launchDate": "2011-02-14",
    "closeData": "2011-02-28",
    "isin": "INF204K01KN0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "114617",
    "name": "Nippon India Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA GOLD SAVINGS FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter.",
    "launchDate": "2011-02-14",
    "closeData": "2011-02-28",
    "isin": "INF204K01KO8",
    "isinRe": "INF204K01KP5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "114618",
    "name": "Nippon India Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Reliance Gold Savings Fund-Dividend Plan-Dividend Reinvestment Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter.",
    "launchDate": "2011-02-14",
    "closeData": "2011-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118662",
    "name": "Nippon India Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA GOLD SAVINGS FUND - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter.",
    "launchDate": "2011-02-14",
    "closeData": "2011-02-28",
    "isin": "INF204K01YD2",
    "isinRe": "INF204K01YE0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118663",
    "name": "Nippon India Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Gold Savings Fund - Direct Plan Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter.",
    "launchDate": "2011-02-14",
    "closeData": "2011-02-28",
    "isin": "INF204K01YC4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "114630",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund IDCW Option",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01257",
    "isinRe": "INF397L01265"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "114982",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01315",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "114983",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01281",
    "isinRe": "INF397L01299"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118452",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund Growth Option Direct",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01AZ5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118453",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund IDCW Option Direct",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01BA6",
    "isinRe": "INF397L01BB4"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118454",
    "name": "IDBI Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "IDBI Equity Savings Fund Option Direct",
    "minAmount": "5000",
    "launchDate": "2011-02-14",
    "closeData": "",
    "isin": "INF397L01BD0",
    "isinRe": "INF397L01BE8"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114757",
    "name": "Kotak Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Gold Fund Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2011-03-04",
    "closeData": "2011-03-18",
    "isin": "INF174K01AU1",
    "isinRe": "INF174K01AV9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114758",
    "name": "Kotak Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Gold Fund Growth",
    "minAmount": "5000",
    "launchDate": "2011-03-04",
    "closeData": "2011-03-18",
    "isin": "INF174K01AT3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119780",
    "name": "Kotak Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Gold Fund Payout of Income Distribution cum capital withdrawal option- Direct",
    "minAmount": "5000",
    "launchDate": "2011-03-04",
    "closeData": "2011-03-18",
    "isin": "INF174K01MQ4",
    "isinRe": "INF174K01MR2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119781",
    "name": "Kotak Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Gold Fund Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2011-03-04",
    "closeData": "2011-03-18",
    "isin": "INF174K01MP6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "114769",
    "name": "Tata Fixed Maturity Plan Series 31- Scheme B",
    "category": "Income",
    "navName": "TFMP Series 31-Scheme B -Growth Option",
    "minAmount": "10000/-",
    "launchDate": "2011-03-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "115102",
    "name": "Navi 3 in 1 Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "PPeerless MF Child Plan-Dividend Option-Monthly Dividendeerless MF Child Plan-Dividend Option-Monthly Dividend",
    "minAmount": "1000",
    "launchDate": "2011-03-11",
    "closeData": "2011-03-25",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114858",
    "name": "Kotak Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Kotak Debt Hybrid - Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2003-12-02",
    "closeData": "2003-12-02",
    "isin": "INF174K01419",
    "isinRe": "INF174K01401"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "114859",
    "name": "Kotak Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Kotak Debt Hybrid - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-02",
    "closeData": "2003-12-02",
    "isin": "INF174K01393",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120154",
    "name": "Kotak Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Kotak Debt Hybrid - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2003-12-02",
    "closeData": "2003-12-02",
    "isin": "INF174K01JZ1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "120155",
    "name": "Kotak Debt Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Kotak Debt Hybrid - Monthly Payout of Income Distribution cum capital withdrawal option - Direct",
    "minAmount": "5000",
    "launchDate": "2003-12-02",
    "closeData": "2003-12-02",
    "isin": "INF174K01KA2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "114909",
    "name": "Pramerica Fixed Duration Fund - Series 1",
    "category": "Income",
    "navName": "Pramerica Fixed Duration Fund - Series 1 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2011-03-08",
    "closeData": "2011-03-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "114930",
    "name": "Mirae Asset Great Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Great Consumer Fund Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2011-03-09",
    "closeData": "2011-03-23",
    "isin": "INF769K01150",
    "isinRe": "INF769K01143"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "114931",
    "name": "Mirae Asset Great Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Great Consumer Fund - Regular Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2011-03-09",
    "closeData": "2011-03-23",
    "isin": "INF769K01135",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118837",
    "name": "Mirae Asset Great Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Great Consumer Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2011-03-09",
    "closeData": "2011-03-23",
    "isin": "INF769K01BL5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "118838",
    "name": "Mirae Asset Great Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Great Consumer Fund Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2011-03-09",
    "closeData": "2011-03-23",
    "isin": "INF769K01BM3",
    "isinRe": "INF769K01BN1"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "114984",
    "name": "Motilal Oswal Nasdaq 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nasdaq 100 ETF (MOFN100)",
    "minAmount": "Rs.10000 and in multiples of Re.1/- thereof",
    "launchDate": "2011-03-16",
    "closeData": "2011-03-23",
    "isin": "INF247L01AP3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "114999",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01406",
    "isinRe": "INF397L01414"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "115000",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01430",
    "isinRe": "INF397L01448"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "115005",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Growth",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01463",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118349",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01BG3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118457",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Monthly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01BH1",
    "isinRe": "INF397L01BI9"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118458",
    "name": "IDBI Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "IDBI Short Term Bond Fund Weekly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2011-03-17",
    "closeData": "",
    "isin": "INF397L01BK5",
    "isinRe": "INF397L01BL3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115022",
    "name": "ICICI Prudential Interval Fund Quarterly Interval Plan - II Quarterly Dividend Payout",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund Quarterly Interval Plan - II Retail Quarterly Dividend Payout",
    "minAmount": "Retail Opt Rs.5000/-; Institutional Opt  Rs25 Lacs",
    "launchDate": "2011-03-14",
    "closeData": "",
    "isin": "INF109K01ME6",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "115025",
    "name": "Taurus Fixed Maturity Plan Series E (91 Days)",
    "category": "Income",
    "navName": "Taurus Fixed Maturity Plan Series E (91 Days) - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs. 10/- thereafter.",
    "launchDate": "2011-03-24",
    "closeData": "2011-03-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "115068",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01917",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "115069",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01925",
    "isinRe": "INF846K01933"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "115131",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Regular Plan - Half Yearly IDCW",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01941",
    "isinRe": "INF846K01958"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120450",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01DJ1",
    "isinRe": "INF846K01DK9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120451",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01DI3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120452",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01DL7",
    "isinRe": "INF846K01DM5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128948",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Bonus Option",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01NM4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128949",
    "name": "Axis Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Axis Dynamic Bond Fund - Direct Plan - Bonus Option",
    "minAmount": "1 crore",
    "launchDate": "2011-04-06",
    "closeData": "2011-04-20",
    "isin": "INF846K01NL6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "115077",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01BM2",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "115078",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - REGULAR PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01BO8",
    "isinRe": "INF760K01BN0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "115079",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - REGULAR PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01BQ3",
    "isinRe": "INF760K01BP5"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118320",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01DX5",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118321",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - DIRECT PLAN - MONTHLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01DV9",
    "isinRe": "INF760K01DW7"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "118322",
    "name": "Canara Robeco Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "CANARA ROBECO SHORT DURATION FUND - DIRECT PLAN - QUARTERLY IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2011-04-01",
    "closeData": "2011-04-15",
    "isin": "INF760K01DY3",
    "isinRe": "INF760K01DZ0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115091",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Quarterly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01TT9",
    "isinRe": "INF109K01TS1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115092",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Growth",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01TP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115093",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Half Yearly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01TV5",
    "isinRe": "INF109K01TU7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115094",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Monthly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01TR3",
    "isinRe": "INF109K01TQ5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120675",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan -  Quarterly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01T53",
    "isinRe": "INF109K01T61"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120676",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan -  Growth",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01T04",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145401",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1NB1",
    "isinRe": "INF109KC1NC9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145402",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Weekly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1NF2",
    "isinRe": "INF109KC1NE5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145403",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Fortnightly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1NH8",
    "isinRe": "INF109KC1NG0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145404",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan - Daily IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1MY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120677",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan -  Monthly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01T38",
    "isinRe": "INF109K01T46"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120678",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan -  Half Yearly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109K01T12",
    "isinRe": "INF109K01T20"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130941",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Bonus",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KA1H77",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130942",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan Bonus",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KA1H69",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145399",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Daily IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1ND7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145400",
    "name": "ICICI Prudential Ultra Short term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ICICI Prudential Ultra Short Term Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Cumulative Rs. 5000Dividend and AEP  Rs. 25000",
    "launchDate": "2011-04-14",
    "closeData": "",
    "isin": "INF109KC1NA3",
    "isinRe": "INF109KC1MZ2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "115116",
    "name": "HSBC Brazil Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Brazil Fund-Growth",
    "minAmount": "5000",
    "launchDate": "2011-04-15",
    "closeData": "2011-04-29",
    "isin": "INF336L01164",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "115117",
    "name": "HSBC Brazil Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Brazil Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-04-15",
    "closeData": "2011-04-29",
    "isin": "INF336L01172",
    "isinRe": "INF336L01180"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120035",
    "name": "HSBC Brazil Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Brazil Fund - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2011-04-15",
    "closeData": "2011-04-29",
    "isin": "INF336L01BY4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "120036",
    "name": "HSBC Brazil Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Brazil Fund - IDCW Direct Plan",
    "minAmount": "5000",
    "launchDate": "2011-04-15",
    "closeData": "2011-04-29",
    "isin": "INF336L01BW8",
    "isinRe": "INF336L01BX6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "115127",
    "name": "Aditya Birla Sun Life Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "Aditya Birla Sun Life Gold ETF",
    "minAmount": "Rs. 6000/- & in multiples of Rs.2000/- thereafter",
    "launchDate": "2011-04-25",
    "closeData": "",
    "isin": "INF209KB18D3",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "115132",
    "name": "Quantum Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Gold Savings Fund - Direct Plan Growth Option",
    "minAmount": "500/- and multiples of Re. 1/- thereafter",
    "launchDate": "2011-04-28",
    "closeData": "2011-05-12",
    "isin": "INF082J01150",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141064",
    "name": "Quantum Gold Savings Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Gold Savings Fund - Regular Plan Growth Option",
    "minAmount": "500/- and multiples of Re. 1/- thereafter",
    "launchDate": "2011-04-28",
    "closeData": "2011-05-12",
    "isin": "INF082J01358",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115153",
    "name": "Tata Fixed Maturity Plan Series 33 Scheme A( 92 days maturity)",
    "category": "Income",
    "navName": "TFMP Series  33 Scheme A- Periodic Dividend Payout Option",
    "minAmount": "10000/-",
    "launchDate": "2011-05-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115270",
    "name": "Union Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Union Flexi Cap Fund - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2011-05-20",
    "closeData": "",
    "isin": "INF582M01104",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115290",
    "name": "Union Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Union Flexi Cap Fund - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2011-05-20",
    "closeData": "",
    "isin": "INF582M01120",
    "isinRe": "INF582M01112"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119292",
    "name": "Union Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Union Flexi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2011-05-20",
    "closeData": "",
    "isin": "INF582M01633",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119293",
    "name": "Union Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Union Flexi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2011-05-20",
    "closeData": "",
    "isin": "INF582M01658",
    "isinRe": "INF582M01641"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115276",
    "name": "Tata Fixed Maturity Plan Series 35 Scheme B (370 days maturity)",
    "category": "Income",
    "navName": "test nav",
    "minAmount": "10000/-",
    "launchDate": "2011-05-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "115284",
    "name": "Invesco India Nifty 50 Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Invesco India Nifty 50 Exchange Traded Fund",
    "minAmount": "Rs10000 per application & in multiples of Rs.1",
    "launchDate": "2011-05-23",
    "closeData": "",
    "isin": "INF205K01DA9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "115338",
    "name": "DHFL Pramerica Treasury Advantage Fund",
    "category": "Income",
    "navName": "Pramerica Treasury Advantage Fund - Fortnightly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2011-06-01",
    "closeData": "2011-06-02",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115398",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01013",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115399",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Fortnightly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01062",
    "isinRe": "INF582M01047"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115400",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Monthly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01070",
    "isinRe": "INF582M01054"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115401",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Daily IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01021",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "115402",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Weekly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01039",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142342",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Unclaimed Amounts Plan - Redemption Upto 3 years",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142343",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Unclaimed Amounts Plan - Redemption Beyond 3 years",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142344",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Unclaimed Amounts Plan - IDCW Upto 3 years",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142345",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Unclaimed Amounts Plan - IDCW Beyond 3 years",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119301",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01682",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119302",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01732",
    "isinRe": "INF582M01716"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119303",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01674",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119304",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01690",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119305",
    "name": "Union Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Union Liquid Fund - Direct Plan - Fortnightly IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2011-06-14",
    "closeData": "",
    "isin": "INF582M01724",
    "isinRe": "INF582M01708"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115473",
    "name": "Tata Fixed Maturity Plan Series 32 ( 736 days maturity)",
    "category": "Income",
    "navName": "Test",
    "minAmount": "10000/-",
    "launchDate": "2011-06-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "115512",
    "name": "Aditya Birla Sun Life Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty ETF",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1000/-",
    "launchDate": "2011-07-04",
    "closeData": "",
    "isin": "INF209KB19D1",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "115526",
    "name": "Baroda Pioneer 90 day Fixed Maturity plan - Series 3",
    "category": "Income",
    "navName": "Baroda Pioneer 90 Day Fixed Maturity Plan - Series 3 - Dividend Option",
    "minAmount": "Rs. 5 000/- and in multiples of Re. 1/- thereafte",
    "launchDate": "2011-06-17",
    "closeData": "2011-06-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "115676",
    "name": "SBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "SBI GOLD FUND REGULAR PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2011-08-22",
    "closeData": "2011-09-05",
    "isin": "INF200K01HA1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "115677",
    "name": "SBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "SBI Gold Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2011-08-22",
    "closeData": "2011-09-05",
    "isin": "INF200K01HB9",
    "isinRe": "INF200K01HC7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119788",
    "name": "SBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "SBI GOLD FUND- DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2011-08-22",
    "closeData": "2011-09-05",
    "isin": "INF200K01RP8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119789",
    "name": "SBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "SBI Gold Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2011-08-22",
    "closeData": "2011-09-05",
    "isin": "INF200K01RN3",
    "isinRe": "INF200K01RO1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115767",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series V - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series V - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2011-09-02",
    "closeData": "",
    "isin": "INF109K01SY1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115768",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series V - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series V - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2011-09-02",
    "closeData": "",
    "isin": "INF109K01SX3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115833",
    "name": "ICICI Prudential Regular Gold Savings Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Regular Gold Savings Fund (FOF) - Growth",
    "minAmount": "5000",
    "launchDate": "2011-09-20",
    "closeData": "",
    "isin": "INF109K01TK8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "115834",
    "name": "ICICI Prudential Regular Gold Savings Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Regular Gold Savings Fund (FOF) - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-09-20",
    "closeData": "",
    "isin": "INF109K01TM4",
    "isinRe": "INF109K01TL6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120685",
    "name": "ICICI Prudential Regular Gold Savings Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Regular Gold Savings Fund (FOF) - Direct Plan -  Growth",
    "minAmount": "5000",
    "launchDate": "2011-09-20",
    "closeData": "",
    "isin": "INF109K01U92",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120686",
    "name": "ICICI Prudential Regular Gold Savings Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Regular Gold Savings Fund (FOF) - Direct Plan -  IDCW",
    "minAmount": "5000",
    "launchDate": "2011-09-20",
    "closeData": "",
    "isin": "INF109K01U76",
    "isinRe": "INF109K01U84"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "115881",
    "name": "DSP World Agriculture Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Agriculture Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2011-09-30",
    "closeData": "",
    "isin": "INF740K01EL0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "115882",
    "name": "DSP World Agriculture Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Agriculture Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2011-09-30",
    "closeData": "",
    "isin": "INF740K01EK2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119271",
    "name": "DSP World Agriculture Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Agriculture Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2011-09-30",
    "closeData": "",
    "isin": "INF740K01OE4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119272",
    "name": "DSP World Agriculture Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP World Agriculture Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2011-09-30",
    "closeData": "",
    "isin": "INF740K01OF1",
    "isinRe": "INF740K01OG9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "115897",
    "name": "Axis Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Gold Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- & in multiples of Rs. 1/- thereafter",
    "launchDate": "2011-09-30",
    "closeData": "2011-10-14",
    "isin": "INF846K01AL3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "115898",
    "name": "Axis Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Gold Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiples of Rs. 1/- thereafter",
    "launchDate": "2011-09-30",
    "closeData": "2011-10-14",
    "isin": "INF846K01AM1",
    "isinRe": "INF846K01AN9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120472",
    "name": "Axis Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Gold Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiples of Rs. 1/- thereafter",
    "launchDate": "2011-09-30",
    "closeData": "2011-10-14",
    "isin": "INF846K01DQ6",
    "isinRe": "INF846K01DR4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120473",
    "name": "Axis Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Gold Fund - Direct Plan - Growth option",
    "minAmount": "Rs. 5000/- & in multiples of Rs. 1/- thereafter",
    "launchDate": "2011-09-30",
    "closeData": "2011-10-14",
    "isin": "INF846K01DS2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "115934",
    "name": "HDFC GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Gold Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-10-07",
    "closeData": "2011-10-21",
    "isin": "INF179K01LC5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "119132",
    "name": "HDFC GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Gold Fund - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2011-10-07",
    "closeData": "2011-10-21",
    "isin": "INF179K01VX0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "115939",
    "name": "IDBI Gold Exchange Traded Fund",
    "category": "Other Scheme - Gold ETF",
    "navName": "IDBI Gold Exchange Traded Fund",
    "minAmount": "10000",
    "launchDate": "2011-10-19",
    "closeData": "",
    "isin": "INF397L01554",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118342",
    "name": "IDBI Gold Exchange Traded Fund",
    "category": "Other Scheme - Gold ETF",
    "navName": "IDBI Gold Exchange Traded Fund Direct",
    "minAmount": "10000",
    "launchDate": "2011-10-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "115991",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund) - Growth Option",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01014",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "115992",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option (Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01022",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "115993",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Regular Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01089",
    "isinRe": "INF666M01030"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "115994",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid) Fund- Regular Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01063",
    "isinRe": "INF666M01048"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "115995",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01071",
    "isinRe": "INF666M01055"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119135",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund ( formerly known as Indiabulls Liquid )Fund - Direct Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01451",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119136",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund) - Direct Plan- - Income Distribution cum capital withdrawal Option ( Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01469",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119137",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Direct Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01527",
    "isinRe": "INF666M01477"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119138",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Direct Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01501",
    "isinRe": "INF666M01485"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119139",
    "name": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Groww Liquid Fund (formerly known as Indiabulls Liquid Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2011-10-24",
    "closeData": "2011-10-24",
    "isin": "INF666M01519",
    "isinRe": "INF666M01493"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119306",
    "name": "Union Tax Saver (ELSS) Fund (Formerly Union Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Union Tax Saver (ELSS) Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.500 and in multiples of Rs.500 thereafter.",
    "launchDate": "2011-11-08",
    "closeData": "",
    "isin": "INF582M01781",
    "isinRe": "INF582M01773"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119307",
    "name": "Union Tax Saver (ELSS) Fund (Formerly Union Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Union Tax Saver (ELSS) Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.500 and in multiples of Rs.500 thereafter.",
    "launchDate": "2011-11-08",
    "closeData": "",
    "isin": "INF582M01765",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "116051",
    "name": "Union Tax Saver (ELSS) Fund (Formerly Union Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Union Tax Saver (ELSS) Fund - Growth Option",
    "minAmount": "Rs.500 and in multiples of Rs.500 thereafter.",
    "launchDate": "2011-11-08",
    "closeData": "",
    "isin": "INF582M01302",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "116052",
    "name": "Union Tax Saver (ELSS) Fund (Formerly Union Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Union Tax Saver (ELSS) Fund - IDCW Option",
    "minAmount": "Rs.500 and in multiples of Rs.500 thereafter.",
    "launchDate": "2011-11-08",
    "closeData": "",
    "isin": "INF582M01328",
    "isinRe": "INF582M01310"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116075",
    "name": "Invesco India Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Invesco India Gold Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-11-15",
    "closeData": "",
    "isin": "INF205K01FB2",
    "isinRe": "INF205K01FC0"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "116077",
    "name": "Invesco India Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Invesco India Gold Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-11-15",
    "closeData": "",
    "isin": "INF205K01FA4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120530",
    "name": "Invesco India Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Invesco India Gold Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-11-15",
    "closeData": "",
    "isin": "INF205K01NH3",
    "isinRe": "INF205K01NI1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120531",
    "name": "Invesco India Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Invesco India Gold Fund - Direct Plan- - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-11-15",
    "closeData": "",
    "isin": "INF205K01NJ9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "116153",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01ET7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "116154",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01ES9",
    "isinRe": "INF090I01ER1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118552",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01JJ7",
    "isinRe": "INF090I01JK5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118553",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01JL3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147954",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TJ6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147961",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TN8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148303",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01VX3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148304",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund - Segregated Portfolio 3 (9.50% Yes bank Ltd CO 23Dec21) - Direct Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01VZ8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148305",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01VW5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "148306",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund-Segregated Portfolio 3 (9.50% Yes Bank Ltd CO 23Dec21) - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01VY1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147955",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TL2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147956",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Direct-Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TK4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147957",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 1- 8.25% Vodafone Idea Ltd-10JUL20-Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TI8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147958",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 2- 10.90% Vodafone Idea Ltd 02Sep2023-Direct Growth Plan",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TP3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147959",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund- Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TM0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147960",
    "name": "Franklin India Credit Risk Fund (No. of segregated portfolios-3)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Franklin India Credit Risk Fund-Segregated Portfolio 2 - 10.90% Vodafone Idea Ltd (02-Sep-2023) - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2011-11-15",
    "closeData": "2011-11-29",
    "isin": "INF090I01TO6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "116352",
    "name": "SBI TAX ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI TAX ADVANTAGE FUND - SERIES II - GROWTH",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2011-12-22",
    "closeData": "2012-03-21",
    "isin": "INF200K01JF6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "116353",
    "name": "SBI TAX ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI Tax Advantage Fund-Series- II - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2011-12-22",
    "closeData": "2012-03-21",
    "isin": "INF200K01JG4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116470",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Regular Plan - Regular IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01CK1",
    "isinRe": "INF846K01CL9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116471",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01AX8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128956",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Bonus Option",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01NK8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128957",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Direct Plan - Bonus Option",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01NJ0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116472",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Regular Plan - Half Yearly IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01AZ3",
    "isinRe": "INF846K01BB2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120447",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01DD4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120448",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01DF9",
    "isinRe": "INF846K01DE2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120449",
    "name": "Axis Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Axis Gilt Fund - Direct Plan - Regular IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1/- thereafter",
    "launchDate": "2012-01-10",
    "closeData": "2012-01-19",
    "isin": "INF846K01DG7",
    "isinRe": "INF846K01DH5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "116483",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Dividend Option - Monthly",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01492",
    "isinRe": "INF663L01500"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "116484",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Dividend Option - Quarterly",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01526",
    "isinRe": "INF663L01534"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "116485",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01484",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "120084",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01AD7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "120085",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Direct Plan-Monthly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01AF2",
    "isinRe": "INF663L01AE5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "120086",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund - Direct Plan-Quarterly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01AI6",
    "isinRe": "INF663L01AH8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "122260",
    "name": "PGIM India Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "PGIM India Dynamic Bond Fund-Bonus Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2012-01-03",
    "closeData": "",
    "isin": "INF663L01BF0",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "116547",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Blue Chip Fund) - Regular Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01162",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "116548",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01188",
    "isinRe": "INF666M01170"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119133",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Blue Chip Fund) - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01600",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "119134",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Direct Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01626",
    "isinRe": "INF666M01618"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140809",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CM5",
    "isinRe": "INF666M01CN3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140810",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CS2",
    "isinRe": "INF666M01CT0"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140811",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Direct Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CO1",
    "isinRe": "INF666M01CP8"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140812",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Regular Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CU8",
    "isinRe": "INF666M01CV6"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140813",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Regular Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CW4",
    "isinRe": "INF666M01CX2"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140814",
    "name": "Groww Large Cap Fund (formerly known as Indiabulls Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Groww Largecap Fund (formerly known as Indiabulls Bluechip Fund)- Direct Plan- Half Yearly -Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2012-01-20",
    "closeData": "2012-02-03",
    "isin": "INF666M01CQ6",
    "isinRe": "INF666M01CR4"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "116555",
    "name": "Union Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Union Dynamic Bond Fund - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2012-01-23",
    "closeData": "",
    "isin": "INF582M01336",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "116556",
    "name": "Union Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Union Dynamic Bond Fund - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2012-01-23",
    "closeData": "",
    "isin": "INF582M01351",
    "isinRe": "INF582M01344"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119310",
    "name": "Union Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Union Dynamic Bond Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2012-01-23",
    "closeData": "",
    "isin": "INF582M01815",
    "isinRe": "INF582M01807"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "119311",
    "name": "Union Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Union Dynamic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2012-01-23",
    "closeData": "",
    "isin": "INF582M01799",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "116582",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Annual IDCW Regular",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01638",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "116583",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Growth",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01661",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "116810",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Quarterly IDCW Regular",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01604",
    "isinRe": "INF397L01612"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "116811",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01BN9",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118428",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Annual IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01BR0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118429",
    "name": "IDBI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "IDBI Dynamic Bond Fund Quarterly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2012-01-31",
    "closeData": "",
    "isin": "INF397L01BO7",
    "isinRe": "INF397L01BP4"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "116632",
    "name": "Franklin India Feeder - Franklin U. S. Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Franklin U S Opportunities Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "",
    "isin": "INF090I01EV3",
    "isinRe": "INF090I01EU5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "116633",
    "name": "Franklin India Feeder - Franklin U. S. Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Franklin U.S. Opportunities Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "",
    "isin": "INF090I01EW1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118550",
    "name": "Franklin India Feeder - Franklin U. S. Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Franklin U S Opportunities Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "",
    "isin": "INF090I01JP4",
    "isinRe": "INF090I01JQ2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118551",
    "name": "Franklin India Feeder - Franklin U. S. Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Franklin US Opportunities Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "",
    "isin": "INF090I01JR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "116670",
    "name": "Reliance Dual Advantage Fixed Tenure Fund - II - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund - II - Plan A - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-02-15",
    "closeData": "",
    "isin": "INF204KA1H50",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "116671",
    "name": "Reliance Dual Advantage Fixed Tenure Fund - II - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund - II - Plan A - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-02-15",
    "closeData": "",
    "isin": "INF204KA1H68",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "116795",
    "name": "Aditya Birla Sun Life Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Gold Fund-REGULAR - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2012-03-01",
    "closeData": "",
    "isin": "INF209K01PG2",
    "isinRe": "INF209K01PH0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "116796",
    "name": "Aditya Birla Sun Life Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Gold Fund-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2012-03-01",
    "closeData": "",
    "isin": "INF209K01PF4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120545",
    "name": "Aditya Birla Sun Life Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Gold Fund-DIRECT - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2012-03-01",
    "closeData": "",
    "isin": "INF209K01YT7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "120546",
    "name": "Aditya Birla Sun Life Gold Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Gold Fund - Growth - Direct Plan",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2012-03-01",
    "closeData": "",
    "isin": "INF209K01YU5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116894",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01BP2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116895",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01BQ0",
    "isinRe": "INF846K01BS6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "116896",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Regular Plan - Half Yearly IDCW",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01BR8",
    "isinRe": "INF846K01BT4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120474",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01DU8",
    "isinRe": "INF846K01DV6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120475",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01DT0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120476",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01DW4",
    "isinRe": "INF846K01DX2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128958",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Regular Plan - Bonus Option",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01NO0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128959",
    "name": "Axis Strategic Bond Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Axis Strategic Bond Fund - Direct Plan - Bonus Option",
    "minAmount": "5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-03-09",
    "closeData": "",
    "isin": "INF846K01NN2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "116932",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantge Fixed Tenure Fund II - Plan B - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-03-16",
    "closeData": "",
    "isin": "INF204K01RR6-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "116933",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan B - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-03-16",
    "closeData": "",
    "isin": "INF204K01RS4-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117007",
    "name": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-03-27",
    "closeData": "",
    "isin": "INF109K01A39",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117008",
    "name": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-03-27",
    "closeData": "",
    "isin": "INF109K01A47",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117056",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan G Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-03-21",
    "closeData": "",
    "isin": "INF109K01ZX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117057",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan G Dividend",
    "minAmount": "5000",
    "launchDate": "2012-03-21",
    "closeData": "",
    "isin": "INF109K01ZY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117205",
    "name": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-04-10",
    "closeData": "",
    "isin": "INF109K01B20",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117206",
    "name": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 2 - Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-04-10",
    "closeData": "",
    "isin": "INF109K01B12",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117214",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan H Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-04-18",
    "closeData": "",
    "isin": "INF109K01B38",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117215",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan H Dividend",
    "minAmount": "5000",
    "launchDate": "2012-04-18",
    "closeData": "",
    "isin": "INF109K01B46",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117222",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan C - Dividend Payout Option",
    "minAmount": "5000/- per option and in multiples of Re. 1 there",
    "launchDate": "2012-04-16",
    "closeData": "",
    "isin": "INF204KA1X68",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117223",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan C - Growth Option",
    "minAmount": "5000/- per option and in multiples of Re. 1 there",
    "launchDate": "2012-04-16",
    "closeData": "",
    "isin": "INF204KA1X50",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "117310",
    "name": "IDBI India Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "IDBI India Top 100 Equity Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2012-04-25",
    "closeData": "",
    "isin": "INF397L01836",
    "isinRe": "INF397L01844"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "117311",
    "name": "IDBI India Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "IDBI India Top 100 Equity Fund Growth",
    "minAmount": "5000",
    "launchDate": "2012-04-25",
    "closeData": "",
    "isin": "INF397L01869",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118344",
    "name": "IDBI India Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "IDBI India Top 100 Equity Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2012-04-25",
    "closeData": "",
    "isin": "INF397L01BV2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118448",
    "name": "IDBI India Top 100 Equity Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "IDBI India Top 100 Equity Fund IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2012-04-25",
    "closeData": "",
    "isin": "INF397L01BW0",
    "isinRe": "INF397L01BX8"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "117312",
    "name": "Taurus Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Banking & Financial Services Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/- and in multiple of Re 1/- thereof",
    "launchDate": "2012-05-02",
    "closeData": "2012-05-16",
    "isin": "INF044D01BI4",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "117313",
    "name": "Taurus Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Banking & Financial Services Fund - Regular Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs.5000/- and in multiple of Re 1/- thereof",
    "launchDate": "2012-05-02",
    "closeData": "2012-05-16",
    "isin": "INF044D01BJ2",
    "isinRe": "INF044D01BK0"
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118868",
    "name": "Taurus Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Banking & Financial Services Fund - Direct Plan - Growth",
    "minAmount": "Rs.5000/- and in multiple of Re 1/- thereof",
    "launchDate": "2012-05-02",
    "closeData": "2012-05-16",
    "isin": "INF044D01CN2",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "118869",
    "name": "Taurus Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Taurus Banking & Financial Services Fund - Direct Plan - Payout of Income Distribution cum Capital Withdrawal option",
    "minAmount": "Rs.5000/- and in multiple of Re 1/- thereof",
    "launchDate": "2012-05-02",
    "closeData": "2012-05-16",
    "isin": "INF044D01CO0",
    "isinRe": "INF044D01CP7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117339",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan G - Growth Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-05-11",
    "closeData": "",
    "isin": "INF204K01SB8-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117340",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan G - Dividend Payout Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-05-11",
    "closeData": "",
    "isin": "INF204K01SC6-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117350",
    "name": "ICICI Prudential Capital Protection Oriented Fund II - Series IX - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund II - Series IX - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-05-14",
    "closeData": "",
    "isin": "INF109K01C78",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117351",
    "name": "ICICI Prudential Capital Protection Oriented Fund II - Series IX - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund II - Series IX - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-05-14",
    "closeData": "",
    "isin": "INF109K01C86",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117366",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan K Dividend",
    "minAmount": "5000",
    "launchDate": "2012-05-21",
    "closeData": "",
    "isin": "INF109K01D28",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117367",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan K Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-05-21",
    "closeData": "",
    "isin": "INF109K01D10",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117446",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Regular Plan - Growth option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CB0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117447",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CC8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117448",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CD6",
    "isinRe": "INF846K01CE4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117449",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CF1",
    "isinRe": "INF846K01CG9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120436",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CV8",
    "isinRe": "INF846K01CW6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120437",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CU0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120438",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CR6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120439",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01CT2",
    "isinRe": "INF846K01CS4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128952",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Direct Plan - Bonus Option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01NF8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "128953",
    "name": "Axis Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Axis Banking & PSU Debt Fund - Regular Plan - Bonus Option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2012-05-30",
    "closeData": "",
    "isin": "INF846K01NG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117471",
    "name": "ICICI Prudential Capital Protection Oriented Fund II - Series X - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund II - Series X - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-06-06",
    "closeData": "",
    "isin": "INF109K01D77",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117472",
    "name": "ICICI Prudential Capital Protection Oriented Fund II - Series X - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund II - Series X - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-06-06",
    "closeData": "",
    "isin": "INF109K01D85",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "117547",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Regular - IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01831",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "117548",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Plan A - Dividend Re-investment Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01849",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "117549",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Regular - Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01856",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119333",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Direct - Growth Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01971",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119334",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Direct - IDCW Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01955",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "119335",
    "name": "Baroda BNP Paribas Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Banking and Financial Services Fund - Plan B (Direct) - Dividend Re-investment Option",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter",
    "launchDate": "2012-06-01",
    "closeData": "",
    "isin": "INF955L01963",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117559",
    "name": "Axis Focused 25 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Axis Focused 25 Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-06-11",
    "closeData": "",
    "isin": "INF846K01CI5",
    "isinRe": "INF846K01CJ3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "117560",
    "name": "Axis Focused 25 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Axis Focused 25 Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-06-11",
    "closeData": "",
    "isin": "INF846K01CH7",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120467",
    "name": "Axis Focused 25 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Axis Focused 25 Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-06-11",
    "closeData": "",
    "isin": "INF846K01CO3",
    "isinRe": "INF846K01CP0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "120468",
    "name": "Axis Focused 25 Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Axis Focused 25 Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1/- thereafter",
    "launchDate": "2012-06-11",
    "closeData": "",
    "isin": "INF846K01CQ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117561",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan D - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-06-15",
    "closeData": "",
    "isin": "INF204K01RV8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117562",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan D - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-06-15",
    "closeData": "",
    "isin": "INF204K01RW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117600",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan H - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-07-03",
    "closeData": "",
    "isin": "INF204K01SD4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117601",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan H - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-07-03",
    "closeData": "",
    "isin": "INF204K01SE2",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "117608",
    "name": "Quantum Multi Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Multi Asset Fund of Funds - Direct Plan Growth Option",
    "minAmount": "500",
    "launchDate": "2012-06-22",
    "closeData": "2012-07-05",
    "isin": "INF082J01168",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141072",
    "name": "Quantum Multi Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Multi Asset Fund of Funds - Regular Plan Growth Option",
    "minAmount": "500",
    "launchDate": "2012-06-22",
    "closeData": "2012-07-05",
    "isin": "INF082J01341",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117619",
    "name": "ICICI Prudential US Bluechip Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential US Bluechip Equity Fund - IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-06-18",
    "closeData": "",
    "isin": "INF109K01E43",
    "isinRe": "INF109K01E50"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117620",
    "name": "ICICI Prudential US Bluechip Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential US Bluechip Equity Fund - Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-06-18",
    "closeData": "",
    "isin": "INF109K01E35",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120185",
    "name": "ICICI Prudential US Bluechip Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential US Bluechip Equity Fund - Direct Plan -  IDCW",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-06-18",
    "closeData": "",
    "isin": "INF109K01Z55",
    "isinRe": "INF109K01Z63"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120186",
    "name": "ICICI Prudential US Bluechip Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential US Bluechip Equity Fund - Direct Plan -  Growth",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1/-)",
    "launchDate": "2012-06-18",
    "closeData": "",
    "isin": "INF109K01Z71",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117621",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan L Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-06-22",
    "closeData": "",
    "isin": "INF109K01E68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117622",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan L Dividend",
    "minAmount": "5000",
    "launchDate": "2012-06-22",
    "closeData": "",
    "isin": "INF109K01E76",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117691",
    "name": "DSP US Flexible Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP US Flexible Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF740K01LP6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "117692",
    "name": "DSP US Flexible Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP US Flexible Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF740K01LQ4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119252",
    "name": "DSP US Flexible Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP US Flexible Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF740K01OH7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119253",
    "name": "DSP US Flexible Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP US Flexible Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF740K01OI5",
    "isinRe": "INF740K01OJ3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "119254",
    "name": "DSP US Flexible Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP US Flexible Equity Fund - Direct Plan - IDCW - Reinvest",
    "minAmount": "Rs. 1000/- & any amount thereafter",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117700",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan M Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-07-18",
    "closeData": "",
    "isin": "INF109K01F00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117701",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 63-3 Year Plan M Dividend",
    "minAmount": "5000",
    "launchDate": "2012-07-18",
    "closeData": "",
    "isin": "INF109K01F18",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117704",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan E - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF204K01RY2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117705",
    "name": "Reliance Dual Advantage Fixed Tenure Fund II - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund II - Plan E - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2012-07-17",
    "closeData": "",
    "isin": "INF204K01RX4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "117714",
    "name": "IDBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "IDBI GOLD FUND",
    "minAmount": "5000",
    "launchDate": "2012-07-25",
    "closeData": "2012-08-08",
    "isin": "INF397L01992",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118343",
    "name": "IDBI GOLD FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "IDBI GOLD FUND Direct",
    "minAmount": "5000",
    "launchDate": "2012-07-25",
    "closeData": "2012-08-08",
    "isin": "INF397L01BU4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "117715",
    "name": "Kotak Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Kotak Credit Risk Fund - Regular Plan - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2010-04-12",
    "closeData": "2010-04-30",
    "isin": "INF174K01EG2",
    "isinRe": "INF174K01EF4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "117716",
    "name": "Kotak Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Kotak Credit Risk Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2010-04-12",
    "closeData": "2010-04-30",
    "isin": "INF174K01DY7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119740",
    "name": "Kotak Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Kotak Credit Risk Fund - Direct Plan - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2010-04-12",
    "closeData": "2010-04-30",
    "isin": "INF174K01MG5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "119741",
    "name": "Kotak Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Kotak Credit Risk Fund - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2010-04-12",
    "closeData": "2010-04-30",
    "isin": "INF174K01LZ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117769",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan H Dividend",
    "minAmount": "5000",
    "launchDate": "2012-08-13",
    "closeData": "",
    "isin": "INF109K01F34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117770",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan H Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-08-13",
    "closeData": "",
    "isin": "INF109K01F26",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117778",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 21 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-10",
    "closeData": "",
    "isin": "INF204KA1TR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117779",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 21 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-10",
    "closeData": "",
    "isin": "INF204KA1TS8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117794",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 22 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-16",
    "closeData": "",
    "isin": "INF204KA1VI5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117795",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 22 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-16",
    "closeData": "",
    "isin": "INF204KA1VJ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117796",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 23 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-16",
    "closeData": "",
    "isin": "INF204K01VL1-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117797",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 23 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-08-16",
    "closeData": "",
    "isin": "INF204K01VM9-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117804",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan I Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-08-22",
    "closeData": "",
    "isin": "INF109K01F67",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117805",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan I Dividend",
    "minAmount": "5000",
    "launchDate": "2012-08-22",
    "closeData": "",
    "isin": "INF109K01F75",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117813",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 24 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2012-08-23",
    "closeData": "",
    "isin": "INF204K01VO5-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117814",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 24 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2012-08-23",
    "closeData": "",
    "isin": "INF204K01VN7-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117848",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 26 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-09-04",
    "closeData": "",
    "isin": "INF204KA1VW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117849",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 26 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-09-04",
    "closeData": "",
    "isin": "INF204KA1VX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117855",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan K Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-09-10",
    "closeData": "",
    "isin": "INF109K01F83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117856",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 64-3 Year Plan K Dividend",
    "minAmount": "5000",
    "launchDate": "2012-09-10",
    "closeData": "",
    "isin": "INF109K01F91",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117964",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 28 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-10-12",
    "closeData": "",
    "isin": "INF204K01VW8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117965",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 28 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-10-12",
    "closeData": "",
    "isin": "INF204K01VV0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117966",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 29 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2012-10-12",
    "closeData": "",
    "isin": "INF204KA1XE0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117967",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 29 - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2012-10-12",
    "closeData": "",
    "isin": "INF204KA1XF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117971",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 65-3 Year Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 65-3 Year Plan A Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-10-16",
    "closeData": "",
    "isin": "INF109K01G25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "117972",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 65-3 Year Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 65-3 Year Plan A Dividend",
    "minAmount": "5000",
    "launchDate": "2012-10-16",
    "closeData": "",
    "isin": "INF109K01G33",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117975",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXII - Series 30 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-10-22",
    "closeData": "",
    "isin": "INF204K01VZ1-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "117976",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXII - Sereis 30 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-10-22",
    "closeData": "",
    "isin": "INF204K01WA2-",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "117981",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund  - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789F01QZ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "117982",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789F01RA2",
    "isinRe": "INF789F01RB0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133344",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FA1V60",
    "isinRe": "INF789FA1V78"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135051",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FA1V86",
    "isinRe": "INF789FA1V94"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135290",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FB1U28",
    "isinRe": "INF789FB1U36"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135418",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FA1W02",
    "isinRe": "INF789FA1W10"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135419",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FA1V45",
    "isinRe": "INF789FA1V52"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136302",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FB1U44",
    "isinRe": "INF789FB1U51"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120764",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789F01YB6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "120765",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789F01YC4",
    "isinRe": "INF789F01YD2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131418",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FB1T88",
    "isinRe": "INF789FB1T96"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131419",
    "name": "UTI - Credit Risk Fund.",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2012-10-25",
    "closeData": "",
    "isin": "INF789FB1U02",
    "isinRe": "INF789FB1U10"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118003",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN A - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN A - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-11-20",
    "closeData": "",
    "isin": "INF109K01G66",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118004",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN A - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN A - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-11-20",
    "closeData": "",
    "isin": "INF109K01G74",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118007",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN B - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN B - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-11-20",
    "closeData": "",
    "isin": "INF109K01G41",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118008",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN B - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN B - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-11-20",
    "closeData": "",
    "isin": "INF109K01G58",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118024",
    "name": "Reliance Fixed Horizon Fund XXII - Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXII - Series 32 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-11-30",
    "closeData": "",
    "isin": "INF204K01WD6-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118025",
    "name": "Reliance Fixed Horizon Fund XXII - Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXII - Series 32 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-11-30",
    "closeData": "",
    "isin": "INF204K01WE4-",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118030",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Growth",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01AD2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118031",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01AE0",
    "isinRe": "INF397L01AF7"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118034",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01AA8",
    "isinRe": "INF397L01AB6"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118341",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01CC0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118446",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Quarterly IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01CD8",
    "isinRe": "INF397L01CE6"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "118447",
    "name": "IDBI Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "IDBI Gilt Fund Annual IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2012-12-05",
    "closeData": "2012-12-17",
    "isin": "INF397L01BZ3",
    "isinRe": "INF397L01CA4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118222",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 34",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII -Series -34 Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-12-13",
    "closeData": "",
    "isin": "INF204K01WH7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "118223",
    "name": "Reliance Fixed Horizon Fund - XXII - Series 34",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXII - Series 34 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2012-12-13",
    "closeData": "",
    "isin": "INF204K01WI5-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118226",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN C - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN C - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2012-12-12",
    "closeData": "",
    "isin": "INF109K01H24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118227",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN C - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN C - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2012-12-12",
    "closeData": "",
    "isin": "INF109K01H32",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "118232",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Growth Option",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01JV2",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "118233",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01JW0",
    "isinRe": "INF205K01JY6"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "118234",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Daily IDCW (Reinvestment)",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01JX8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120443",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01KS6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120444",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Direct Plan -  Growth Option",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01KT4",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "120445",
    "name": "Invesco India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Invesco India Banking & PSU Debt Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "5000/- per application and in multiples of Re. 1/",
    "launchDate": "2012-12-10",
    "closeData": "",
    "isin": "INF205K01KU2",
    "isinRe": "INF205K01KV0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118897",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-07",
    "closeData": "",
    "isin": "INF109K01J14",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118898",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-01-07",
    "closeData": "",
    "isin": "INF109K01I80",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118899",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-07",
    "closeData": "",
    "isin": "INF109K01J06",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "118900",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN D - 36 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-01-07",
    "closeData": "",
    "isin": "INF109K01I98",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101224",
    "name": "Tata Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Tata Dynamic Bond Fund- Regular Plan - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DE0",
    "isinRe": "INF277K01352"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101909",
    "name": "Tata Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Tata Dynamic Bond Fund- Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01360",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119097",
    "name": "Tata Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Tata Dynamic Bond Fund- Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MV5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119098",
    "name": "Tata Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Tata Dynamic Bond- Direct Plan - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MT9",
    "isinRe": "INF277K01MU7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101605",
    "name": "Tata Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Tata Medium Term Fund- Regular Plan - Payout of IDCW option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DW2",
    "isinRe": "INF277K01709"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101703",
    "name": "Tata Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Tata Medium Term Fund -Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01717",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119126",
    "name": "Tata Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Tata Medium Term Fund- Direct Plan - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PG9",
    "isinRe": "INF277K01PH7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119127",
    "name": "Tata Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Tata Medium Term Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PI5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100475",
    "name": "Tata Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Tata Large Cap Fund -Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01931",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102036",
    "name": "Tata Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Tata Large Cap Fund- Regular Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01EG3",
    "isinRe": "INF277K01923"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119159",
    "name": "Tata Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Tata Large Cap Fund- Direct Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01QX2",
    "isinRe": "INF277K01QY0"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119160",
    "name": "Tata Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Tata Large Cap Fund -Direct Plan Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01QZ7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100415",
    "name": "Tata Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Ethical Fund-Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01956",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101833",
    "name": "Tata Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Ethical Fund- Regular Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01EH1",
    "isinRe": "INF277K01949"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119171",
    "name": "Tata Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Ethical Fund- Direct Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01NE9",
    "isinRe": "INF277K01NF6"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119172",
    "name": "Tata Ethical Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Ethical Fund -Direct Plan- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01NG4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102326",
    "name": "Tata Mid Cap Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Tata Mid Cap Growth Fund-Regular Plan - IDCW Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DS0",
    "isinRe": "INF277K01642"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102328",
    "name": "Tata Mid Cap Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Tata Mid Cap Growth Fund Regular Plan- Growth Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01626",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119177",
    "name": "Tata Mid Cap Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Tata Mid Cap Growth Fund - Direct Plan- IDCW Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PW6",
    "isinRe": "INF277K01PX4"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119178",
    "name": "Tata Mid Cap Growth Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Tata Mid Cap Growth Fund - Direct Plan- Growth Option",
    "minAmount": "Rs.5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PY2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101823",
    "name": "Tata Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Tata Large & Mid Cap Fund Regular Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DH3",
    "isinRe": "INF277K01410"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101824",
    "name": "Tata Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Tata Large & Mid Cap Fund- Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01428",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119201",
    "name": "Tata Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Tata Large & Mid Cap Fund Direct Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MI2",
    "isinRe": "INF277K01MJ0"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119202",
    "name": "Tata Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Tata Large & MId Cap Fund -Direct Plan- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MK8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101672",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund - Regular Plan -Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01451",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102428",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund Regular Plan - Payout of IDCW-Option B(10%)",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DJ9",
    "isinRe": "INF277K01444"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "112218",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund - Regular Plan - Payout of IDCW-Option A(5%)",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DI1",
    "isinRe": "INF277K01436"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119231",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund -Direct Plan Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01ND1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119232",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund- Direct Plan -Payout of IDCW-Option A(5%)",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MZ6",
    "isinRe": "INF277K01NA7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119233",
    "name": "Tata Equity P/E Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Tata Equity P/E Fund- Direct Plan - Payout of IDCW -Option B(10%)",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01NB5",
    "isinRe": "INF277K01NC3"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101766",
    "name": "Tata Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Infrastructure Fund-Regular Plan- Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01782",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101852",
    "name": "Tata Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Infrastructure Fund Regular Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DY8",
    "isinRe": "INF277K01774"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119243",
    "name": "Tata Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Infrastructure Fund -Direct Plan -Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PQ8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119244",
    "name": "Tata Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Infrastructure Fund- Direct Plan - Payout of IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PO3",
    "isinRe": "INF277K01PP0"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149202",
    "name": "Tata Retirement Savings Fund Progressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund-Progressive Plan-Direct-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149203",
    "name": "Tata Retirement Savings Fund Progressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund-Progressive Plan-Regular-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115942",
    "name": "Tata Retirement Savings Fund-Progressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund - Progressive Plan -Regular Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01FG0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119251",
    "name": "Tata Retirement Savings Fund-Progressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund- Progressive Plan -Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01QO1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101746",
    "name": "Tata S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata S&P BSE Sensex Index Fund -Regular Plan",
    "minAmount": "5000/-",
    "launchDate": "2003-02-03",
    "closeData": "",
    "isin": "INF277K01758",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119287",
    "name": "Tata S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata S&P BSE Sensex Index Fund - Direct Plan",
    "minAmount": "5000/-",
    "launchDate": "2003-02-03",
    "closeData": "",
    "isin": "INF277K01PK1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101491",
    "name": "Tata Young Citizens Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Tata Young Citizens' Fund -Regular Plan-Growth Option",
    "minAmount": "500/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01AJ5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119312",
    "name": "Tata Young Citizens Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Tata Young Citizens' Fund-Direct Plan-Growth Option",
    "minAmount": "500/-",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01QW4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101847",
    "name": "Tata Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Tata Money Market Fund-Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01LQ7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101986",
    "name": "Tata Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Tata Money Market Fund- Regular Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K014C0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119421",
    "name": "Tata Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Tata Money Market Fund- Direct Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K011C6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119424",
    "name": "Tata Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Tata Money Market Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PR6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101714",
    "name": "Tata Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Tata Liquid Fund- Regular Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K011D4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102672",
    "name": "Tata Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Tata Liquid Fund -Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01YD8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119861",
    "name": "Tata Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Tata Liquid Fund- Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01YE6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119862",
    "name": "Tata Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Tata Liquid Fund- Direct Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K012D2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "103158",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Regular Plan - Weekly Payout of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MD3",
    "isinRe": "INF277K01MB7"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119957",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Direct Plan - Periodic Payout of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01OL2",
    "isinRe": "INF277K01OM0"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "103159",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MA9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "103160",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Regular Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01LZ8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115464",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Regular Plan - Periodic Payout of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01MC5",
    "isinRe": "INF277K01ME1"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119863",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01OK4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119864",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Direct Plan - Daily Reinvestment of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K018B3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119865",
    "name": "Tata Treasury Advantage Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Tata Treasury Advantage Fund Direct Plan - Weekly Payout of IDCW Option",
    "minAmount": "10000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K019B1",
    "isinRe": "INF277K010C8"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100417",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Tata Income Fund-Regular Plan - Half Yearly Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DU6",
    "isinRe": "INF277K01675"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "100418",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Tata Income Fund -Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01659",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101186",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Tata Income Fund- Regular Plan - Periodic Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DT8",
    "isinRe": "INF277K01667"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119875",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "TATA Income Fund Direct Plan - Periodic Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PC8",
    "isinRe": "INF277K01PD6"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119876",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "Tata Income Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01OY5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119877",
    "name": "Tata Income Fund",
    "category": "Debt Scheme - Medium to Long Duration Fund",
    "navName": "TATA Income Fund-Direct Plan - Half Yearly Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01PA2",
    "isinRe": "INF277K01PB0"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101547",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund- Regular Plan - Monthly Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01EI9",
    "isinRe": "INF277K01964"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101548",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund -Regular Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01972",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119949",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01QR4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119950",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund Direct Plan - Monthly Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01QP8",
    "isinRe": "INF277K01QQ6"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "133974",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund- Direct Plan - Periodic Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01P38",
    "isinRe": "INF277K01P46"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "133975",
    "name": "Tata Short Term Bond Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Tata Short Term Bond Fund- Regular Plan - Periodic Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01P12",
    "isinRe": "INF277K01P20"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101042",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Securities Fund -Regular Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01519",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101049",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Securities Fund - Regular Plan - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01DN1",
    "isinRe": "INF277K01535"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "101945",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Retirement Plan- 28/2/25 Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "102046",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Retirement Plan (28/2/25) - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119953",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Securities Fund- Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01OX7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119954",
    "name": "Tata Gilt Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Tata Gilt Securities Fund - Direct Plan - Payout of IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-01-01",
    "closeData": "",
    "isin": "INF277K01OV1",
    "isinRe": "INF277K01OW9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120798",
    "name": "Nippon India Yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Nippon India Yearly Interval Fund - Series 1 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-01-15",
    "closeData": "",
    "isin": "INF204K01M05-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120799",
    "name": "Nippon India Yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Nippon India Yearly Interval Fund - Series 1 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-01-15",
    "closeData": "",
    "isin": "INF204K01M39-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120800",
    "name": "Nippon India Yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Nippon India Yearly Interval Fund - Series 1 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-01-15",
    "closeData": "",
    "isin": "INF204K01M47",
    "isinRe": "INF204K01M54"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120802",
    "name": "Nippon India Yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Nippon India Yearly Interval Fund - Series 1 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-01-15",
    "closeData": "",
    "isin": "INF204K01M13",
    "isinRe": "INF204K01M21"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120812",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-17",
    "closeData": "",
    "isin": "INF109K01K11",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120813",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-01-17",
    "closeData": "",
    "isin": "INF109K01K03",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120814",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-01-17",
    "closeData": "",
    "isin": "INF109K01K29",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "120815",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN E - 60 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-01-17",
    "closeData": "",
    "isin": "INF109K01K37",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120954",
    "name": "Reliance Yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 2 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-01",
    "closeData": "",
    "isin": "INF204K01R83",
    "isinRe": "INF204K01R91"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120955",
    "name": "Reliance Yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 2 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-01",
    "closeData": "",
    "isin": "INF204K01R75-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120956",
    "name": "Reliance Yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 2 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-01",
    "closeData": "",
    "isin": "INF204K01R42-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "120957",
    "name": "Reliance Yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 2 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-01",
    "closeData": "",
    "isin": "INF204K01R59",
    "isinRe": "INF204K01R67"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121087",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-12",
    "closeData": "",
    "isin": "INF109K013F9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121088",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-02-12",
    "closeData": "",
    "isin": "INF109K014F7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121089",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-12",
    "closeData": "",
    "isin": "INF109K011F3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121090",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN F - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-02-12",
    "closeData": "",
    "isin": "INF109K012F1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121095",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF204K01M88-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121096",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF204K01M96-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121097",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF204K01M62-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121098",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan A - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF204K01M70-",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "121109",
    "name": "SBI S&P BSE SENSEX ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI S&P BSE SENSEX ETF",
    "minAmount": "5000 and in multiples of Re.1 thereaft(During NFO)",
    "launchDate": "2013-02-09",
    "closeData": "2013-03-08",
    "isin": "INF200K01VT2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121126",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF109K014G5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121127",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF109K013G7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121128",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF109K016G0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121129",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-14",
    "closeData": "",
    "isin": "INF109K015G2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121143",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF109K018I2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121144",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF109K019I0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121145",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN G - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF109K017I4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121146",
    "name": "Nippon India ETF Nifty 100",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 100",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2013-02-19",
    "closeData": "",
    "isin": "INF204K014N5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121177",
    "name": "Reliance Yearly Interval Fund - Series 3",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 3 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF204K01S33-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121178",
    "name": "Reliance Yearly Interval Fund - Series 3",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 3 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF204K01S17",
    "isinRe": "INF204K01S25"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121179",
    "name": "Reliance Yearly Interval Fund - Series 3",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 3 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF204K01S41",
    "isinRe": "INF204K01S58"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121180",
    "name": "Reliance Yearly Interval Fund - Series 3",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 3 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-02-21",
    "closeData": "",
    "isin": "INF204K01S09-",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121279",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Growth",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K015G8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121280",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Growth",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K01SN6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121281",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K016G6",
    "isinRe": "INF194K017G4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121282",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K01SO4",
    "isinRe": "INF194K012D2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121283",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund-Regular Plan- Default option",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121284",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund-Direct Plan- Default option",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121937",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Quarterly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K014M9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121938",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Quarterly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K018M0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "127470",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Daily IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194KA1JC5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "127471",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Daily IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194KA1JD3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121931",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Fortnightly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K016M4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121932",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Monthly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K017M2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121933",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Regular Annual IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K015M6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121934",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Fortnightly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K010N5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121935",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Monthly IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K011N3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "121936",
    "name": "BANDHAN Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "BANDHAN Banking & PSU Debt Fund - Direct Annual IDCW",
    "minAmount": "Rs 5000 /-",
    "launchDate": "2013-02-26",
    "closeData": "",
    "isin": "INF194K019M8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121285",
    "name": "Reliance Yearly Interval Fund - Serise 4",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 4 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-02-27",
    "closeData": "",
    "isin": "INF204K01T08",
    "isinRe": "INF204K01T16"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121286",
    "name": "Reliance Yearly Interval Fund - Serise 4",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 4 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-02-27",
    "closeData": "",
    "isin": "INF204K01S90-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121287",
    "name": "Reliance Yearly Interval Fund - Serise 4",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 4 - Dividend Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-02-27",
    "closeData": "",
    "isin": "INF204K01S74",
    "isinRe": "INF204K01S82"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121288",
    "name": "Reliance Yearly Interval Fund - Serise 4",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 4- Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-02-27",
    "closeData": "",
    "isin": "INF204K01S66-",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121362",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-04",
    "closeData": "",
    "isin": "INF109K016Q9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121363",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-04",
    "closeData": "",
    "isin": "INF109K017Q7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121364",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-04",
    "closeData": "",
    "isin": "INF109K014Q4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121365",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-04",
    "closeData": "",
    "isin": "INF109K015Q1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121366",
    "name": "ICICI Prudential Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 50 ETF",
    "minAmount": "5000",
    "launchDate": "2013-03-01",
    "closeData": "",
    "isin": "INF109K012R6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121440",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-07",
    "closeData": "",
    "isin": "INF109K010R0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121441",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-07",
    "closeData": "",
    "isin": "INF109K018Q5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121442",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-07",
    "closeData": "",
    "isin": "INF109K011R8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121443",
    "name": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VI - Annual Interval Plan - D - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-07",
    "closeData": "",
    "isin": "INF109K019Q3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121510",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-11",
    "closeData": "",
    "isin": "INF109K019R1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121511",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-11",
    "closeData": "",
    "isin": "INF109K018R3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121512",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-11",
    "closeData": "",
    "isin": "INF109K010S8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121513",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-11",
    "closeData": "",
    "isin": "INF109K017R5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121699",
    "name": "Reliance Yearly Interval Fund - Series 5",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 5 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-03-15",
    "closeData": "",
    "isin": "INF204K01T24-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121700",
    "name": "Reliance Yearly Interval Fund - Series 5",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 5 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-03-15",
    "closeData": "",
    "isin": "INF204K01T32",
    "isinRe": "INF204K01T40"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121701",
    "name": "Reliance Yearly Interval Fund - Series 5",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 5 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-03-15",
    "closeData": "",
    "isin": "INF204K01T57-",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121702",
    "name": "Reliance Yearly Interval Fund - Series 5",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 5 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-03-15",
    "closeData": "",
    "isin": "INF204K01T65",
    "isinRe": "INF204K01T73"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121728",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 6 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121755",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 8 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF204K014A2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121756",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 8 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF204K013A4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121757",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 8 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF204K015A9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "121758",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 8 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF204K012A6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121793",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K016V9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121794",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K015V1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121795",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K013V6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121796",
    "name": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund III - PLAN H - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K014V4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121875",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K011V0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121876",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K019U5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121877",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K010V2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121878",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-3 Year Plan F - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-21",
    "closeData": "",
    "isin": "INF109K012V8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121953",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-28",
    "closeData": "",
    "isin": "INF109K010W0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121954",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-03-28",
    "closeData": "",
    "isin": "INF109K018V5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121955",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-28",
    "closeData": "",
    "isin": "INF109K019V3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "121956",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-740 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-03-28",
    "closeData": "",
    "isin": "INF109K017V7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122078",
    "name": "Reliance Yearly Interval Fund - Series 6",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 6 - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-02",
    "closeData": "",
    "isin": "INF204K01T99",
    "isinRe": "INF204K01U05"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122079",
    "name": "Reliance Yearly Interval Fund - Series 6",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 6 - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-02",
    "closeData": "",
    "isin": "INF204K01U21",
    "isinRe": "INF204K01U39"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122080",
    "name": "Reliance Yearly Interval Fund - Series 6",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 6 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-02",
    "closeData": "",
    "isin": "INF204K01T81",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122081",
    "name": "Reliance Yearly Interval Fund - Series 6",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-02",
    "closeData": "",
    "isin": "INF204K01U13",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122225",
    "name": "Reliance Yearly Interval Fund - Series 7",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 7 - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-15",
    "closeData": "",
    "isin": "INF204K01U88",
    "isinRe": "INF204K01U96"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122226",
    "name": "Reliance Yearly Interval Fund - Series 7",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-15",
    "closeData": "",
    "isin": "INF204K01U47",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122227",
    "name": "Reliance Yearly Interval Fund - Series 7",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-15",
    "closeData": "",
    "isin": "INF204K01U70",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122228",
    "name": "Reliance Yearly Interval Fund - Series 7",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 7- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-15",
    "closeData": "",
    "isin": "INF204K01U54",
    "isinRe": "INF204K01U62"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122237",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF204K01N04",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122238",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF204K01N20",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122239",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF204K01N12",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122240",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF204K01N38",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122249",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF109K015X7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122250",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF109K016X5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122251",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF109K013X2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122252",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan B -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-04-16",
    "closeData": "",
    "isin": "INF109K014X0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122273",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-04-23",
    "closeData": "",
    "isin": "INF109KB1FO1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122274",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-04-23",
    "closeData": "",
    "isin": "INF109KB1FP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122275",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-04-23",
    "closeData": "",
    "isin": "INF109KB1FN3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122276",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 67-745 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-04-23",
    "closeData": "",
    "isin": "INF109KB1FQ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122324",
    "name": "Reliance Fixed Horizon Fund XXIII - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIII - Series 10 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-18",
    "closeData": "",
    "isin": "INF204KA1Z17",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122325",
    "name": "Reliance Fixed Horizon Fund XXIII - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIII - Series 10 -Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-18",
    "closeData": "",
    "isin": "INF204KA1Z25",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122326",
    "name": "Reliance Fixed Horizon Fund XXIII - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIII - Series 10 - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-18",
    "closeData": "",
    "isin": "INF204KA1Z09",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122327",
    "name": "Reliance Fixed Horizon Fund XXIII - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIII - Series 10 -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-04-18",
    "closeData": "",
    "isin": "INF204KA1Y91",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "122387",
    "name": "Motilal Oswal Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Motilal Oswal Focused 25 Fund (MOF25)- Regular Plan Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2013-04-22",
    "closeData": "",
    "isin": "INF247L01155",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "122388",
    "name": "Motilal Oswal Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Motilal Oswal Focused 25 Fund Regular - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2013-04-22",
    "closeData": "",
    "isin": "INF247L01171",
    "isinRe": "INF247L01163"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "122389",
    "name": "Motilal Oswal Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Motilal Oswal Focused 25 Fund (MOF25)- Direct Plan Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2013-04-22",
    "closeData": "",
    "isin": "INF247L01189",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "122390",
    "name": "Motilal Oswal Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Motilal Oswal Focused 25 Fund Direct - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2013-04-22",
    "closeData": "",
    "isin": "INF247L01205",
    "isinRe": "INF247L01197"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122401",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-02",
    "closeData": "",
    "isin": "INF109KA1061",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122402",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-02",
    "closeData": "",
    "isin": "INF109KA1087",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122403",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-02",
    "closeData": "",
    "isin": "INF109KA1079",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122404",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan C -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-02",
    "closeData": "",
    "isin": "INF109KA1095",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "122639",
    "name": "Parag Parikh Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Parag Parikh Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2013-05-13",
    "closeData": "2013-05-21",
    "isin": "INF879O01027",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "122640",
    "name": "Parag Parikh Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Parag Parikh Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2013-05-13",
    "closeData": "2013-05-21",
    "isin": "INF879O01019",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122439",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-08",
    "closeData": "",
    "isin": "INF109KB1WO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122440",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-08",
    "closeData": "",
    "isin": "INF109KB1WP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122441",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-08",
    "closeData": "",
    "isin": "INF109KB1WM0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122442",
    "name": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 3 - Plan D -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-08",
    "closeData": "",
    "isin": "INF109KB1WN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122455",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 11 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-05-09",
    "closeData": "",
    "isin": "INF204K015B7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122456",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 11 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-05-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122457",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 11 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-05-09",
    "closeData": "",
    "isin": "INF204K014B0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122458",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 11 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-05-09",
    "closeData": "",
    "isin": "INF204K016B5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122509",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III -Plan C- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF204K01N61",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122510",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF204K01N79",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122511",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF204K01N46",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122512",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan C - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF204K01N53",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122532",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF109KA1285",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122533",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF109KA1293",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122534",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF109KA1277",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122535",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan A -Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-05-17",
    "closeData": "",
    "isin": "INF109KA1269",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122608",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-03",
    "closeData": "",
    "isin": "INF109KB1IN7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122609",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-03",
    "closeData": "",
    "isin": "INF109KB1IO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122610",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-03",
    "closeData": "",
    "isin": "INF109KB1IM9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122611",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-03",
    "closeData": "",
    "isin": "INF109KB1IL1",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122612",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01183",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122711",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Regular Plan Bonus",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01191",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122712",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Regular Plan Quarterly Dividend",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01217",
    "isinRe": "INF579M01241"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122715",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Direct Plan Growth",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01266",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122717",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Direct Plan Quarterly Dividend",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01290",
    "isinRe": "INF579M01324"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122719",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Regular Plan Monthly Dividend",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01209",
    "isinRe": "INF579M01233"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122720",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Regular Plan Half Yearly Dividend",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01225",
    "isinRe": "INF579M01258"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "122721",
    "name": "360 ONE Dynamic Bond Fund (Formerly known as IIFL Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "360 ONE Dynamic Bond Fund Direct Plan Monthly Dividend",
    "minAmount": "10000/-",
    "launchDate": "2013-06-06",
    "closeData": "2013-06-17",
    "isin": "INF579M01282",
    "isinRe": "INF579M01316"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122614",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 12 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-06-05",
    "closeData": "",
    "isin": "INF204K018B1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122615",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 12 - Direct Plan - Growth  Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-06-05",
    "closeData": "",
    "isin": "INF204K010C6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122616",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 12 - Direct Plan - Dividend Payout  Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-06-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122617",
    "name": "Reliance Fixed Horizon Fund - XXIII - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIII - Series 12 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-06-05",
    "closeData": "",
    "isin": "INF204K019B9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122643",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Regular - DAILY IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209KA1LN1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122644",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01MG9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122645",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Direct - daily IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UW9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122646",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UX7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122647",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-REGULAR - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01MH7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122648",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Direct - weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01UY5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122649",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-RETAIL - WEEKLY IDCW",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01MI5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "122650",
    "name": "Aditya Birla Sun Life Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Aditya Birla Sun Life Floating Rate Fund-Retail Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2003-06-01",
    "closeData": "2003-06-01",
    "isin": "INF209K01MF1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122652",
    "name": "Reliance Yearly Interval Fund - Series 8",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 8 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-11",
    "closeData": "",
    "isin": "INF204K01V04",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122653",
    "name": "Reliance Yearly Interval Fund - Series 8",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 8 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-11",
    "closeData": "",
    "isin": "INF204K01V38",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122654",
    "name": "Reliance Yearly Interval Fund - Series 8",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 8 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-11",
    "closeData": "",
    "isin": "INF204K01V46",
    "isinRe": "INF204K01V53"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122655",
    "name": "Reliance Yearly Interval Fund - Series 8",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 8 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-11",
    "closeData": "",
    "isin": "INF204K01V12",
    "isinRe": "INF204K01V20"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122657",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1517",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122658",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1483",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122659",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1509",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122660",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan B -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1491",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122661",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1467",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122662",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1442",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122663",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1459",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122664",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-13",
    "closeData": "",
    "isin": "INF109KA1475",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122669",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1 - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-13",
    "closeData": "2013-06-13",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122670",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-13",
    "closeData": "2013-06-13",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122671",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1 - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-13",
    "closeData": "2013-06-13",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122672",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 1 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-13",
    "closeData": "2013-06-13",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122699",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-06-17",
    "closeData": "",
    "isin": "INF204K01N95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122700",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-06-17",
    "closeData": "",
    "isin": "INF204K01O11",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122701",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-06-17",
    "closeData": "",
    "isin": "INF204K01N87",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122702",
    "name": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund III - Plan D - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-06-17",
    "closeData": "",
    "isin": "INF204K01O03",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122726",
    "name": "Reliance Yearly Interval Fund - Series 9",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 9 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-19",
    "closeData": "",
    "isin": "INF204K01V95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122727",
    "name": "Reliance Yearly Interval Fund - Series 9",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 9 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-19",
    "closeData": "",
    "isin": "INF204K01V79",
    "isinRe": "INF204K01V87"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122728",
    "name": "Reliance Yearly Interval Fund - Series 9",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 9 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-19",
    "closeData": "",
    "isin": "INF204K01W03",
    "isinRe": "INF204K01W11"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122729",
    "name": "Reliance Yearly Interval Fund - Series 9",
    "category": "Income",
    "navName": "Reliance Yearly Interval Fund - Series 9 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-06-19",
    "closeData": "",
    "isin": "INF204K01V61",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122753",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-24",
    "closeData": "",
    "isin": "INF109KA1590",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122754",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-24",
    "closeData": "",
    "isin": "INF109KA1582",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122755",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-24",
    "closeData": "",
    "isin": "INF109KA1566",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122756",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1825 Days - Plan D -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-24",
    "closeData": "",
    "isin": "INF109KA1574",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122776",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-25",
    "closeData": "",
    "isin": "INF109KB1KH5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122777",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-25",
    "closeData": "",
    "isin": "INF109KB1KI3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122778",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-06-25",
    "closeData": "",
    "isin": "INF109KB1KF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122779",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-06-25",
    "closeData": "",
    "isin": "INF109KB1KG7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122849",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 1 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-05",
    "closeData": "",
    "isin": "INF204K016W1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122850",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-08",
    "closeData": "",
    "isin": "INF109KA1822",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122851",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-08",
    "closeData": "",
    "isin": "INF109KA1814",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122852",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-08",
    "closeData": "",
    "isin": "INF109KA1830",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122853",
    "name": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 4 - 1100 Days - Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-08",
    "closeData": "",
    "isin": "INF109KA1806",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122892",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-368 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-368 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF109KA1ZH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122893",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-368 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-368 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF109KA1ZJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122896",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF109KB1LF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122898",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF109KB1LD2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122900",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 2 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF204K017W9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122901",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 2 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF204K018W7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122902",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF204K019W5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122903",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-15",
    "closeData": "",
    "isin": "INF204K010X2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122910",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF204KA1644",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122911",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A - Direct Plan- Dividend Payout option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF204KA1677",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122912",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A - Dividend Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF204KA1651",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "122913",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan A - Direct Plan -Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF204KA1669",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122954",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-18",
    "closeData": "",
    "isin": "INF109KA1848",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122956",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-18",
    "closeData": "",
    "isin": "INF109KA1863",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122957",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN A - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-18",
    "closeData": "",
    "isin": "INF109KA1871",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122996",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KB1LJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122998",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KB1LH3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "122999",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-745 Days Plan J - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KB1LI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123000",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KA1ZM4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123002",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KA1ZO0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123003",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan I - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-23",
    "closeData": "",
    "isin": "INF109KA1ZP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123004",
    "name": "ICICI Prudential Nifty 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 100 ETF",
    "minAmount": "5000",
    "launchDate": "2013-07-19",
    "closeData": "",
    "isin": "INF109KA1962",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123051",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 3-Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF204KA1SF7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123052",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 3-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF204KA1SD2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123053",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 3-Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF204KA1SE0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123054",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 3-Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF204KA1SG5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123070",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 4-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-01",
    "closeData": "",
    "isin": "INF204KA1TB4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123071",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 4-Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-01",
    "closeData": "",
    "isin": "INF204KA1TC2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123072",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 4-Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-01",
    "closeData": "",
    "isin": "INF204KA1TD0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123073",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 4-Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-01",
    "closeData": "",
    "isin": "INF204KA1TE8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123074",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-30",
    "closeData": "",
    "isin": "INF109KA1ZY9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123076",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-369 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-30",
    "closeData": "",
    "isin": "INF109KA1A09",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123106",
    "name": "Kotak FMP Series 105 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 105 Direct Dividend",
    "minAmount": "Rs. 5 000/- and in multiples of Rs 10",
    "launchDate": "2013-07-25",
    "closeData": "",
    "isin": "INF174K01ZA0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123107",
    "name": "Kotak FMP Series 105 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 105 Growth",
    "minAmount": "Rs. 5 000/- and in multiples of Rs 10",
    "launchDate": "2013-07-25",
    "closeData": "",
    "isin": "INF174K01YX5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123108",
    "name": "Kotak FMP Series 105 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 105 Direct Growth",
    "minAmount": "Rs. 5 000/- and in multiples of Rs 10",
    "launchDate": "2013-07-25",
    "closeData": "",
    "isin": "INF174K01YZ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123109",
    "name": "Kotak FMP Series 105 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 105 Dividend",
    "minAmount": "Rs. 5 000/- and in multiples of Rs 10",
    "launchDate": "2013-07-25",
    "closeData": "",
    "isin": "INF174K01OY4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123110",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF109KB1JT2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123111",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF109KB1JS4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123112",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF109KB1JU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123113",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 68-704 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF109KB1JR6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123150",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 5 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-02",
    "closeData": "",
    "isin": "INF204K019X3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123151",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-02",
    "closeData": "",
    "isin": "INF204K011Y8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123152",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 5 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-02",
    "closeData": "",
    "isin": "INF204K010Y0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123153",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 5 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-02",
    "closeData": "",
    "isin": "INF204K012Y6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123184",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-05",
    "closeData": "",
    "isin": "INF109KA1A25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123186",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-05",
    "closeData": "",
    "isin": "INF109KA1A41",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123188",
    "name": "Kotak FMP Series 106 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 106 Direct Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123189",
    "name": "Kotak FMP Series 106 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 106 Direct Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZD4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123190",
    "name": "Kotak FMP Series 106 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 106 Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZB8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123191",
    "name": "Kotak FMP Series 106 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 106 Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123192",
    "name": "Kotak FMP Series 107 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 107 Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZG7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123193",
    "name": "Kotak FMP Series 107 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 107 Direct Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZH5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123194",
    "name": "Kotak FMP Series 107 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 107 Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZF9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123195",
    "name": "Kotak FMP Series 107 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 107 Direct Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-29",
    "closeData": "",
    "isin": "INF174K01ZI3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123205",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF204KA11B5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123206",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 6 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF204KA10B7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123207",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 6 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF204KA12B3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123208",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 6 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF204KA19A0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123211",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-06",
    "closeData": "",
    "isin": "INF204K019Y1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123212",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 7 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-06",
    "closeData": "",
    "isin": "INF204K010Z7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123213",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 7 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-06",
    "closeData": "",
    "isin": "INF204K017Y5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123214",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 7 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-08-06",
    "closeData": "",
    "isin": "INF204K018Y3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123259",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-05",
    "closeData": "",
    "isin": "INF109KB1JP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123261",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-05",
    "closeData": "",
    "isin": "INF109KB1JN5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123262",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-698 Days Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-05",
    "closeData": "",
    "isin": "INF109KB1JO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123279",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF109KB1KC6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123280",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF109KB1KD4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123281",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF109KB1KE2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123282",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-693 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "",
    "isin": "INF109KB1KB8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123325",
    "name": "Kotak FMP Series 108 (733 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 108 Direct Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123326",
    "name": "Kotak FMP Series 108 (733 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 108 Direct Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF174K01PL8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123327",
    "name": "Kotak FMP Series 108 (733 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 108 Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF174K01PK0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123328",
    "name": "Kotak FMP Series 108 (733 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 108 Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 10 for purchas",
    "launchDate": "2013-07-31",
    "closeData": "",
    "isin": "INF174K01PJ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123341",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 8-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF204K011Z5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123342",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 8-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF204K012Z3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123343",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 8-Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF204K014Z9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123344",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 8-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF204K013Z1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123363",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF109KA18C9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123364",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF109KA10D4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123366",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-433 Days Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-08",
    "closeData": "",
    "isin": "INF109KA11D2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123367",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-12",
    "closeData": "",
    "isin": "INF109KA1AP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123368",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-12",
    "closeData": "",
    "isin": "INF109KA1AQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123370",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1093 Days Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-12",
    "closeData": "",
    "isin": "INF109KA1AN5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123372",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 9-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-13",
    "closeData": "",
    "isin": "INF204KA1TJ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123373",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 9 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-13",
    "closeData": "",
    "isin": "INF204KA1TK5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123374",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 9 -Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-13",
    "closeData": "",
    "isin": "INF204KA1TL3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "123401",
    "name": "HDFC FMP 1846D August 2013 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1846D August 2013 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "2013-08-13",
    "isin": "INF179KB15P8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "123402",
    "name": "HDFC FMP 1846D August 2013 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1846D August 2013 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "2013-08-13",
    "isin": "INF179KB11P7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "123403",
    "name": "HDFC FMP 1846D August 2013 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1846D August 2013 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "2013-08-13",
    "isin": "INF179KB12P5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "123406",
    "name": "HDFC FMP 1846D August 2013 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1846D August 2013 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2013-08-07",
    "closeData": "2013-08-13",
    "isin": "INF179KB16P6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123450",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1719",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123451",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1701",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123452",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1685",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123453",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan B - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1693",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123454",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 10 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204K019Z8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123455",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 10 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1016",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123456",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 10 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1032",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123457",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 10 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1024",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123458",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 11 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1TN9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123459",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 11 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2013-08-16",
    "closeData": "",
    "isin": "INF204KA1TP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123478",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1J42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123480",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1J67",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123481",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-366 Days Plan G - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1J75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123482",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1BL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123484",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1BN3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123485",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan H - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-19",
    "closeData": "",
    "isin": "INF109KA1BO1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123543",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 69 - 1821 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan I - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BT0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123544",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 69 - 1821 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan I - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123545",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 69 - 1821 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan I - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BV6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123546",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 69 - 1821 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1821 Days Plan I - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BW4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123559",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123560",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BQ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123561",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123562",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN C - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF109KA1BS2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123567",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 13 -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-22",
    "closeData": "",
    "isin": "INF204KA1123",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123568",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 13 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-22",
    "closeData": "",
    "isin": "INF204KA1131",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123569",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 13 -Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-22",
    "closeData": "",
    "isin": "INF204KA1149",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123570",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 13 -Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-22",
    "closeData": "",
    "isin": "INF204KA1156",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123626",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF109KA1P51",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123627",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF109KA1P28",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123628",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-369 Days Plan J - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF109KA1P44",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "123635",
    "name": "IDBI Equity Advantage Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "IDBI Equity Advantage Fund - IDCW Direct",
    "minAmount": "500",
    "launchDate": "2013-08-20",
    "closeData": "",
    "isin": "INF397L01ED4",
    "isinRe": "INF397L01EE2"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "123636",
    "name": "IDBI Equity Advantage Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "IDBI Equity Advantage Fund - IDCW Regular",
    "minAmount": "500",
    "launchDate": "2013-08-20",
    "closeData": "",
    "isin": "INF397L01DZ9",
    "isinRe": "INF397L01EA0"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "123637",
    "name": "IDBI Equity Advantage Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "IDBI Equity Advantage Fund - Growth Direct",
    "minAmount": "500",
    "launchDate": "2013-08-20",
    "closeData": "",
    "isin": "INF397L01EC6",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "123638",
    "name": "IDBI Equity Advantage Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "IDBI Equity Advantage Fund - Growth Regular",
    "minAmount": "500",
    "launchDate": "2013-08-20",
    "closeData": "",
    "isin": "INF397L01DY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123651",
    "name": "ICICI Prudential Global Stable Equity Fund (FOF)",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Global Stable Equity Fund (FOF) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF109KA1CB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123652",
    "name": "ICICI Prudential Global Stable Equity Fund (FOF)",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Global Stable Equity Fund (FOF) - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF109KA1CG5",
    "isinRe": "INF109KA1CF7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123653",
    "name": "ICICI Prudential Global Stable Equity Fund (FOF)",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Global Stable Equity Fund (FOF) - IDCW",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF109KA1CD2",
    "isinRe": "INF109KA1CC4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123654",
    "name": "ICICI Prudential Global Stable Equity Fund (FOF)",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Global Stable Equity Fund (FOF) - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF109KA1CE0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123688",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt - Annual Dividend",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FS4",
    "isinRe": "INF174K01FR6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123689",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt Direct - Annual Dividend",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KI5",
    "isinRe": "INF174K01KJ3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123690",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt - Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FO3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123691",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt - Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01FQ8",
    "isinRe": "INF174K01FP0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123692",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt Direct - Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KK1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123693",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt Direct - Growth",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01KH7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123858",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt - Daily Dividend Reinvestment",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01SD9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123859",
    "name": "Kotak Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Kotak Banking and PSU Debt - Direct Daily Dividend Reinvestment",
    "minAmount": "500",
    "launchDate": "2000-01-02",
    "closeData": "2000-01-02",
    "isin": "INF174K01SE7",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123704",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund) - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AC0",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123705",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Direct Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AG1",
    "isinRe": "INF666M01AD8"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123706",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Direct Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AH9",
    "isinRe": "INF666M01AE6"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123707",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AI7",
    "isinRe": "INF666M01AF3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123708",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund )- Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AJ5",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123709",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Regular Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AN7",
    "isinRe": "INF666M01AK3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123710",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Regular Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AO5",
    "isinRe": "INF666M01AL1"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "123711",
    "name": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Groww Short Duration Fund (formerly known as Indiabulls Short Term Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2013-08-22",
    "closeData": "2013-09-05",
    "isin": "INF666M01AP2",
    "isinRe": "INF666M01AM9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123740",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 15-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF204KA1UJ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123741",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 15-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF204KA1UK3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123742",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 15-Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF204KA1UL1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123790",
    "name": "Kotak FMP Series 113 (1094 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 113 (1094 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF174K01QD3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123791",
    "name": "Kotak FMP Series 113 (1094 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 113 (1094 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF174K01QE1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123792",
    "name": "Kotak FMP Series 113 (1094 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 113 (1094 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF174K01QF8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123793",
    "name": "Kotak FMP Series 113 (1094 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 113 (1094 Days)  - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-21",
    "closeData": "",
    "isin": "INF174K01QG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123944",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KA1Q43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123946",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KA1Q68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123947",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-372 Days Plan K - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KA1Q76",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123970",
    "name": "Kotak FMP Series 115 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 115 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF174K01A70",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123971",
    "name": "Kotak FMP Series 115 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 115 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF174K01B04",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123972",
    "name": "Kotak FMP Series 115 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 115 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "123973",
    "name": "Kotak FMP Series 115 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 115 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-27",
    "closeData": "",
    "isin": "INF174K01A96",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123982",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 16 - Direct Plan - Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF204KA1UY4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "123985",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 16 - Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF204KA1UW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123988",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KB1NJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123990",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KB1NI7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123991",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-02",
    "closeData": "",
    "isin": "INF109KB1NH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123992",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1CZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123993",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1DA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123994",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1CX0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "123995",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 69-1092 Days Plan L - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1CY8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124034",
    "name": "Kotak FMP Series 116 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 116 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF174K01QS1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124035",
    "name": "Kotak FMP Series 116 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 116 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF174K01B12",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124036",
    "name": "Kotak FMP Series 116 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 116 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF174K01B38",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124037",
    "name": "Kotak FMP Series 116 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 116 (370 Days)- Dividend",
    "minAmount": "5000",
    "launchDate": "2013-08-28",
    "closeData": "",
    "isin": "INF174K01QQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124079",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1S66",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124081",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1S90",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124082",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-369 Days Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-04",
    "closeData": "",
    "isin": "INF109KA1S82",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124096",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 17-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF204KA1VE4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124097",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 17 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF204KA1VF1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124098",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 17-Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF204KA1VG9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124099",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 17- Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF204KA1VH7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124138",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-05",
    "closeData": "",
    "isin": "INF109KA1DH1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124139",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-05",
    "closeData": "",
    "isin": "INF109KA1DI9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124140",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-05",
    "closeData": "",
    "isin": "INF109KA1DF5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124141",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-05",
    "closeData": "",
    "isin": "INF109KA1DG3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124142",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF109KA1R00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124143",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF109KA1Q84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124145",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-06",
    "closeData": "",
    "isin": "INF109KA1R18",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124182",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - IDCW - Daily Reinvest",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740KA1OF7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124183",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740KA1OG5",
    "isinRe": "INF740KA1OH3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124176",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZX0",
    "isinRe": "INF740K01ZY8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124177",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZZ5",
    "isinRe": "INF740K01A00"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124178",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01A18",
    "isinRe": "INF740K01A26"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124179",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740KA1OJ9",
    "isinRe": "INF740KA1OK7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124180",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZQ4",
    "isinRe": "INF740K01ZR2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124181",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZS0",
    "isinRe": "INF740K01ZT8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124172",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZP6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124173",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - IDCW - Daily Reinvest",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740KA1OI1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124174",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZU6",
    "isinRe": "INF740K01ZV4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "124175",
    "name": "DSP Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "DSP Banking & PSU Debt Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2013-09-10",
    "closeData": "",
    "isin": "INF740K01ZW2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124211",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 18 -Dividend Payout Optioin",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-12",
    "closeData": "",
    "isin": "INF204KA1VL9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124212",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 18 -Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-12",
    "closeData": "",
    "isin": "INF204KA1VN5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124213",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 18 -Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-12",
    "closeData": "",
    "isin": "INF204KA1VM7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124210",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 18-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-12",
    "closeData": "",
    "isin": "INF204KA1VK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124215",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1T08",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124216",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1T16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124217",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1T24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124218",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1T32",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124219",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1DR0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124220",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1DS8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124221",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1DT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124222",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-742 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-13",
    "closeData": "",
    "isin": "INF109KA1DU4",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124233",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund (MOFUSTF)-Regular Plan- Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01213",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124234",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund (MOFUSTF) -Direct Plan- Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01247",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124303",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Regular - IDCW Daily Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01270",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124305",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Regular - IDCW Quarterly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01338",
    "isinRe": "INF247L01312"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124306",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Direct - IDCW Weekly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01353",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124308",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Direct Plan - IDCW Monthly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01395",
    "isinRe": "INF247L01379"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124310",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Regular - IDCW Weekly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01288",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124311",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Direct - IDCW Fortnightly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01361",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124312",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Direct - IDCW Quarterly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01403",
    "isinRe": "INF247L01387"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124313",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Regular - IDCW Fortnightly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01296",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124315",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Regular - IDCW Monthly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01320",
    "isinRe": "INF247L01304"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "124316",
    "name": "Motilal Oswal Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Motilal Oswal Ultra Short Term Fund Direct - IDCW Daily Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-09-04",
    "closeData": "2013-09-05",
    "isin": "INF247L01346",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124249",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 20 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1420",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124250",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 20 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1438",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124251",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 20 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1404",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124252",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 20 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1412",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124261",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 21 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1446",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124262",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 21 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1461",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124263",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 21 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1453",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124264",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 21 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF204KA1479",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124317",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF109KA1EH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124318",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF109KA1EI7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124319",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF109KA1EJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124320",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan G - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-16",
    "closeData": "",
    "isin": "INF109KA1EK3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124333",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Dividend Payout option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204K012O7",
    "isinRe": "INF204K013O5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124334",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204K015O0",
    "isinRe": "INF204K016O8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124335",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Direct Plan -Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204K014O3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124336",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204K011O9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124519",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Direct Plan -Dividend Reinvestment Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124520",
    "name": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund I - Half yearly Interval Fund - Series 2-Dividend Reinvestment Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124337",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204KA1727",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124338",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204KA1750",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124339",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204KA1743",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124340",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan C - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-09-17",
    "closeData": "",
    "isin": "INF204KA1735",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124361",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan J - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-18",
    "closeData": "",
    "isin": "INF109KA1U21",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124362",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan J - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-18",
    "closeData": "",
    "isin": "INF109KA1U47",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124397",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1DV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124398",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1DW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124399",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1DX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124400",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1095 Days Plan H - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1DY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124467",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1ED8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124468",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1EF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124469",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1EE6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124470",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN D - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-19",
    "closeData": "",
    "isin": "INF109KA1EG1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124495",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-23",
    "closeData": "",
    "isin": "INF109KA1U62",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124496",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-23",
    "closeData": "",
    "isin": "INF109KA1U70",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124497",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-366 Days Plan I - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-23",
    "closeData": "",
    "isin": "INF109KA1U88",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124582",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-25",
    "closeData": "",
    "isin": "INF109KA1V04",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124584",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-25",
    "closeData": "",
    "isin": "INF109KA1V20",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124591",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-27",
    "closeData": "",
    "isin": "INF109KA1Z59",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124593",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-27",
    "closeData": "",
    "isin": "INF109KA1Z75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124594",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-372 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-27",
    "closeData": "",
    "isin": "INF109KA1Z83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124647",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF109KB1OL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124649",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF109KB1OJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124650",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-745 Days Plan P - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF109KB1OK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124651",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-30",
    "closeData": "",
    "isin": "INF109KA1Z34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124652",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-09-30",
    "closeData": "",
    "isin": "INF109KA1Z42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124653",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-368 Days Plan M - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-09-30",
    "closeData": "",
    "isin": "INF109KA1Z18",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124678",
    "name": "Reliance Interval Fund - II - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 1 -Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA1AL3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124679",
    "name": "Reliance Interval Fund - II - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 1 -Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA1AI9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124680",
    "name": "Reliance Interval Fund - II - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 1 -Direct Plan -Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA1AG3",
    "isinRe": "INF204KA1AH1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124681",
    "name": "Reliance Interval Fund - II - Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 1 -Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA1AJ7",
    "isinRe": "INF204KA1AK5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124706",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 22 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA18D6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124707",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 22 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA10E1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124708",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 22 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA11E9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124709",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 22 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-03",
    "closeData": "",
    "isin": "INF204KA19D4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124718",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan N - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": "INF109KA1Y84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124720",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-367 Days Plan N - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": "INF109KA1Z91",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124748",
    "name": "Reliance Interval Fund - II - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 2 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124749",
    "name": "Reliance Interval Fund - II - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 2 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AO7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124750",
    "name": "Reliance Interval Fund - II - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 2 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AP4",
    "isinRe": "INF204KA1AQ2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124751",
    "name": "Reliance Interval Fund - II - Series 2",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 2 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AM1",
    "isinRe": "INF204KA1AN9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124752",
    "name": "Reliance Interval Fund - II - Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 3 - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AX8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124753",
    "name": "Reliance Interval Fund - II - Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 3 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AU4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124754",
    "name": "Reliance Interval Fund - II - Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 3 - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AV2",
    "isinRe": "INF204KA1AW0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124755",
    "name": "Reliance Interval Fund - II - Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 3 - Direct Plan - Dividend Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF204KA1AS8",
    "isinRe": "INF204KA1AT6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124794",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF109KA1FQ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124795",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF109KA1FN4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124796",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF109KA1FO2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124797",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN E - 36 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-07",
    "closeData": "",
    "isin": "INF109KA1FP9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124828",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-10-17",
    "closeData": "",
    "isin": "INF204KA1768",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124829",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-10-17",
    "closeData": "",
    "isin": "INF204KA1784",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124830",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-10-17",
    "closeData": "",
    "isin": "INF204KA1776",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124831",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan D - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-10-17",
    "closeData": "",
    "isin": "INF204KA1792",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124834",
    "name": "Kotak FMP Series 123 (630 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 123 (630 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124835",
    "name": "Kotak FMP Series 123 (630 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 123 (630 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124836",
    "name": "Kotak FMP Series 123 (630 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 123 (630 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124837",
    "name": "Kotak FMP Series 123 (630 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 123 (630 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124842",
    "name": "ICICI Prudential Value Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 1 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-18",
    "closeData": "",
    "isin": "INF109KB1J57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124843",
    "name": "ICICI Prudential Value Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 1 -Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-18",
    "closeData": "",
    "isin": "INF109KB1J40",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124870",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 23-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-28",
    "closeData": "",
    "isin": "INF204KA1529",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124871",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 23-Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-28",
    "closeData": "",
    "isin": "INF204KA1552",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124872",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 23-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-28",
    "closeData": "",
    "isin": "INF204KA1537",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124873",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 23-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-28",
    "closeData": "",
    "isin": "INF204KA1545",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124874",
    "name": "Reliance Interval Fund - II - Series 4",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 4-Growth Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-23",
    "closeData": "",
    "isin": "INF204KA1BD8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124875",
    "name": "Reliance Interval Fund - II - Series 4",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 4 -Direct Plan -Dividend Plan -Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-23",
    "closeData": "",
    "isin": "INF204KA1AY6",
    "isinRe": "INF204KA1AZ3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124876",
    "name": "Reliance Interval Fund - II - Series 4",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 4-Direct Plan-Growth Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-23",
    "closeData": "",
    "isin": "INF204KA1BA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "124877",
    "name": "Reliance Interval Fund - II - Series 4",
    "category": "Income",
    "navName": "Reliance Interval Fund - II - Series 4-Dividend Plan -Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-23",
    "closeData": "",
    "isin": "INF204KA1BB2",
    "isinRe": "INF204KA1BC0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124920",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-14",
    "closeData": "",
    "isin": "INF109KB1CB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124921",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-14",
    "closeData": "",
    "isin": "INF109KB1CC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124922",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-14",
    "closeData": "",
    "isin": "INF109KB1BZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "124923",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-540 Days Plan S - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-14",
    "closeData": "",
    "isin": "INF109KB1CA7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124989",
    "name": "Kotak FMP Series 125 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 125 (371 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124990",
    "name": "Kotak FMP Series 125 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 125 (371 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124991",
    "name": "Kotak FMP Series 125 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 125 (371 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "124992",
    "name": "Kotak FMP Series 125 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 125 (371 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125023",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-22",
    "closeData": "",
    "isin": "INF109KA1GF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125024",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-22",
    "closeData": "",
    "isin": "INF109KA1GG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125025",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-10-22",
    "closeData": "",
    "isin": "INF109KA1GH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125026",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 70-1285 Days Plan T - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-22",
    "closeData": "",
    "isin": "INF109KA1GI2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125099",
    "name": "Kotak FMP Series 126 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 126 (371 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125100",
    "name": "Kotak FMP Series 126 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 126 (371 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125101",
    "name": "Kotak FMP Series 126 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 126 (371 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125102",
    "name": "Kotak FMP Series 126 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 126 (371 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-10-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125150",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 24 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-31",
    "closeData": "",
    "isin": "INF204KA1560",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125151",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 24 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-31",
    "closeData": "",
    "isin": "INF204KA1578",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125152",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 24 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-31",
    "closeData": "",
    "isin": "INF204KA1586",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125153",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 24 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-10-31",
    "closeData": "",
    "isin": "INF204KA1594",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125184",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-07",
    "closeData": "",
    "isin": "INF109KA1GP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125185",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-07",
    "closeData": "",
    "isin": "INF109KA1GQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125186",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-07",
    "closeData": "",
    "isin": "INF109KA1GN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125187",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN F - 60 Months - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-07",
    "closeData": "",
    "isin": "INF109KA1GO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125192",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 25- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-11-01",
    "closeData": "",
    "isin": "INF204KA1628",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125193",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 25 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-11-01",
    "closeData": "",
    "isin": "INF204KA1602",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125194",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 25 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-11-01",
    "closeData": "",
    "isin": "INF204KA1610",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125195",
    "name": "Reliance Fixed Horizon Fund - XXIV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXIV - Series 25 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2013-11-01",
    "closeData": "",
    "isin": "INF204KA1636",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125255",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 1-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF204KA16H1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125256",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 1-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF204KA17H9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125257",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 1-Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF204KA18H7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125258",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 1 -Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF204KA19H5",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125259",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "360 ONE LIQUID FUND REGULAR PLAN  GROWTH",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01506",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125337",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "360 ONE LIQUID FUND REGULAR PLAN  WEEKLY DIVIDEND",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01514",
    "isinRe": "INF579M01571"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125342",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IIFL LIQUID FUND REGULAR PLAN  DAILY DIVIDEND REINVESTMENT",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01563",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125343",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "360 ONE LIQUID FUND DIRECT PLAN  WEEKLY DIVIDEND",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01639",
    "isinRe": "INF579M01696"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125345",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "360 ONE LIQUID FUND DIRECT PLAN  GROWTH",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01621",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "125349",
    "name": "360 ONE Liquid Fund (Formerly known as IIFL Liquid Fund) (An open ended Liquid Scheme)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "IIFL LIQUID FUND DIRECT PLAN  DAILY DIVIDEND REINVESTMENT",
    "minAmount": "5000/-",
    "launchDate": "2013-11-01",
    "closeData": "2013-11-12",
    "isin": "INF579M01688",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125260",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA1HC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125261",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B -Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA1HD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125262",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA1HE9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125263",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA1HB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125270",
    "name": "Reliance Close Ended Equity Fund - Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series A - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF204K015R3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125271",
    "name": "Reliance Close Ended Equity Fund - Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series A - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF204K017R9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125272",
    "name": "Reliance Close Ended Equity Fund - Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series A - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF204K016R1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125273",
    "name": "Reliance Close Ended Equity Fund - Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series A - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF204K018R7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "125301",
    "name": "PGIM India Midcap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "PGIM India Midcap Opportunities Fund - Direct Plan - Dividend Option - Payout",
    "minAmount": "Rs. 5000/- and in multiples of 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF663L01DW1",
    "isinRe": "INF663L01DX9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "125305",
    "name": "PGIM India Midcap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "PGIM India Midcap Opportunities Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF663L01DZ4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "125306",
    "name": "PGIM India Midcap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "PGIM India Midcap Opportunities Fund - Regular Plan - Dividend Option - Payout",
    "minAmount": "Rs. 5000/- and in multiples of 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF663L01EA5",
    "isinRe": "INF663L01EB3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "125307",
    "name": "PGIM India Midcap Opportunities Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "PGIM India Midcap Opportunities Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF663L01DV3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125311",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 2-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-13",
    "closeData": "",
    "isin": "INF204KA1EX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125312",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 2-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-13",
    "closeData": "",
    "isin": "INF204KA1EY8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125309",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 2-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-13",
    "closeData": "",
    "isin": "INF204KA1EV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125310",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 2 -Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-13",
    "closeData": "",
    "isin": "INF204KA1EW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125313",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 547 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-547 Days Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF109KB1FG7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125314",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 547 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-547 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF109KB1FH5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125315",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 547 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-547 Days Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF109KB1FI3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125316",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 547 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-547 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF109KB1FF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125318",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-366 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA10H5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125319",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-366 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA11H3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125320",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 71 - 366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-366 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-15",
    "closeData": "",
    "isin": "INF109KA18G0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "125350",
    "name": "Axis Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Axis Small Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF846K01K01",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "125351",
    "name": "Axis Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Axis Small Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF846K01K43",
    "isinRe": "INF846K01K50"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "125352",
    "name": "Axis Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Axis Small Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF846K01K19",
    "isinRe": "INF846K01K27"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "125354",
    "name": "Axis Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Axis Small Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-11",
    "closeData": "2013-11-25",
    "isin": "INF846K01K35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125356",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 3 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-20",
    "closeData": "",
    "isin": "INF204KA18G9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125357",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 3 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-20",
    "closeData": "",
    "isin": "INF204KA10H4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125358",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 3 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-20",
    "closeData": "",
    "isin": "INF204KA11H2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125359",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 3  - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-20",
    "closeData": "",
    "isin": "INF204KA19G7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125390",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 4 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF204KA1YO7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125392",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 4 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF204KA1YP4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125393",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 4 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF204KA1YM1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125398",
    "name": "Kotak FMP Series 127 (730 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 127 (730 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-11-12",
    "closeData": "",
    "isin": "INF174K018A9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125399",
    "name": "Kotak FMP Series 127 (730 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 127 (730 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-12",
    "closeData": "",
    "isin": "INF174K017A1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125400",
    "name": "Kotak FMP Series 127 (730 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 127 (730 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-12",
    "closeData": "",
    "isin": "INF174K019A7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "125401",
    "name": "Kotak FMP Series 127 (730 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 127 (730 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-11-12",
    "closeData": "",
    "isin": "INF174K016A3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125410",
    "name": "ICICI Prudential Value Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 2 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-18",
    "closeData": "",
    "isin": "INF109KB1L38",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125411",
    "name": "ICICI Prudential Value Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 2 - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-18",
    "closeData": "",
    "isin": "INF109KB1L20",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125432",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-22",
    "closeData": "",
    "isin": "INF109KB1EH8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125433",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-22",
    "closeData": "",
    "isin": "INF109KB1EI6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125434",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-22",
    "closeData": "",
    "isin": "INF109KB1EF2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125435",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-525 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-22",
    "closeData": "",
    "isin": "INF109KB1EG0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125436",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF109KA10L7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125437",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF109KA11L5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125438",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF109KA18K2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125439",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-369 Days Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF109KA19K0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125494",
    "name": "SBI SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "SBI Small Cap Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2009-07-27",
    "closeData": "",
    "isin": "INF200K01T28",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125495",
    "name": "SBI SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "SBI Small Cap Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-07-27",
    "closeData": "",
    "isin": "INF200K01T36",
    "isinRe": "INF200K01T44"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125496",
    "name": "SBI SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "SBI Small Cap Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-07-27",
    "closeData": "",
    "isin": "INF200K01T69",
    "isinRe": "INF200K01T77"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125497",
    "name": "SBI SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "SBI Small Cap Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2009-07-27",
    "closeData": "",
    "isin": "INF200K01T51",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125498",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI BANKING & PSU FUND - Regular Paln - Growth",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01U41",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125499",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01U58",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125500",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Regular Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01U74",
    "isinRe": "INF200K01U66"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125501",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01U82",
    "isinRe": "INF200K01U90"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125502",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01V16",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125503",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI BANKING & PSU FUND - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01V08",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125504",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Direct Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01V32",
    "isinRe": "INF200K01V24"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "125505",
    "name": "SBI BANKING & PSU FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SBI Banking & PSU Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2009-09-30",
    "closeData": "",
    "isin": "INF200K01V40",
    "isinRe": "INF200K01V57"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125527",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 6 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-28",
    "closeData": "",
    "isin": "INF204KA1FN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125528",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-28",
    "closeData": "",
    "isin": "INF204KA1FL2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125529",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 6 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-28",
    "closeData": "",
    "isin": "INF204KA1FO6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125530",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 6 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-11-28",
    "closeData": "",
    "isin": "INF204KA1FM0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125575",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-02",
    "closeData": "",
    "isin": "INF109KA1HS9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125576",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-02",
    "closeData": "",
    "isin": "INF109KA1HR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125577",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months Plan - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-02",
    "closeData": "",
    "isin": "INF109KA1HT7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125578",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN G - 60 Months Plan - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-02",
    "closeData": "",
    "isin": "INF109KA1HU5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "125595",
    "name": "Aditya Birla Sun Life Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Banking and Financial Services Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF209K011W7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "125596",
    "name": "Aditya Birla Sun Life Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Banking and Financial Services Fund - REGULAR - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF209K010W9",
    "isinRe": "INF209K012W5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "125597",
    "name": "Aditya Birla Sun Life Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Banking and Financial Services Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF209K014W1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "125598",
    "name": "Aditya Birla Sun Life Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Banking and Financial Services Fund - DIRECT - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2013-11-25",
    "closeData": "",
    "isin": "INF209K013W3",
    "isinRe": "INF209K015W8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125665",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 7 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-04",
    "closeData": "",
    "isin": "INF204KA1FP3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125666",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-04",
    "closeData": "",
    "isin": "INF204KA1FR9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125667",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 7 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-04",
    "closeData": "",
    "isin": "INF204KA1FQ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125668",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 7 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-04",
    "closeData": "",
    "isin": "INF204KA1FS7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125683",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-05",
    "closeData": "",
    "isin": "INF109KB1ED7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125684",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-05",
    "closeData": "",
    "isin": "INF109KB1EB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125685",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-05",
    "closeData": "",
    "isin": "INF109KB1EC9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125686",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-505 Days Plan H - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-05",
    "closeData": "",
    "isin": "INF109KB1EE5",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "125711",
    "name": "Shriram Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Shriram Hybrid Equity Fund- Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF680P01042",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "125712",
    "name": "Shriram Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Shriram Hybrid Equity Fund- Direct- IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF680P01059",
    "isinRe": "INF680P01067"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "125713",
    "name": "Shriram Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Shriram Hybrid Equity Fund- Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF680P01018",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "125714",
    "name": "Shriram Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Shriram Hybrid Equity Fund- Regular-IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2013-11-08",
    "closeData": "",
    "isin": "INF680P01026",
    "isinRe": "INF680P01034"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125715",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E - Growth Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-12-06",
    "closeData": "",
    "isin": "INF204KA1800",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125716",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E - Direct Plan - Growth Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-12-06",
    "closeData": "",
    "isin": "INF204KA1826",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125717",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E - Dividend Payout Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-12-06",
    "closeData": "",
    "isin": "INF204KA1818",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125718",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IV - Plan E - Direct Plan - Dividend Payout Option",
    "minAmount": "5000/- per option and in multiples of Re. 1",
    "launchDate": "2013-12-06",
    "closeData": "",
    "isin": "INF204KA1834",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125779",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 8 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-10",
    "closeData": "",
    "isin": "INF204KA1FT5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125780",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 8 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-10",
    "closeData": "",
    "isin": "INF204KA1FV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125781",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 8 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-10",
    "closeData": "",
    "isin": "INF204KA1FU3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125782",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 8 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-10",
    "closeData": "",
    "isin": "INF204KA1FW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125817",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-11",
    "closeData": "",
    "isin": "INF109KA1IS7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125818",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-11",
    "closeData": "",
    "isin": "INF109KA1IP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125819",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-11",
    "closeData": "",
    "isin": "INF109KA1IQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125820",
    "name": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund IV - PLAN H - 36 Months Plan - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-11",
    "closeData": "",
    "isin": "INF109KA1IR9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125847",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 9 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204KA1FZ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125848",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204KA1FX7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125849",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 9 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204KA1GA3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125850",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204KA1FY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125865",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-12",
    "closeData": "",
    "isin": "INF109KA1IN8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125866",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-12",
    "closeData": "",
    "isin": "INF109KA1IO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125867",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-12",
    "closeData": "",
    "isin": "INF109KA1IL2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125868",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-1095 Days Plan O - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-12",
    "closeData": "",
    "isin": "INF109KA1IM0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125887",
    "name": "Reliance Close Ended Equity Fund - Series B",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series B - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204K019R5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125888",
    "name": "Reliance Close Ended Equity Fund - Series B",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series B - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204K011S0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125889",
    "name": "Reliance Close Ended Equity Fund - Series B",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204K012S8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125890",
    "name": "Reliance Close Ended Equity Fund - Series B",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund - Series B - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": "INF204K010S2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125935",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 11 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125936",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 11 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125933",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 11 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": "INF204KA1GH8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125934",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 11 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": "INF204KA1GF2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125954",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 12 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF204KA1GL0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125955",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 12 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125956",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 12 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF204KA1GM8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "125957",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 12 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF204KA1GJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125969",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF109KB1CF6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125970",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF109KB1CG4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125971",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF109KB1CD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "125972",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 71-480 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-19",
    "closeData": "",
    "isin": "INF109KB1CE9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126003",
    "name": "Kotak FMP Series 130 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 130 (371 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126004",
    "name": "Kotak FMP Series 130 (371 Days)",
    "category": "Income",
    "navName": "otak FMP Series 130 (371 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126005",
    "name": "Kotak FMP Series 130 (371 Days)",
    "category": "Income",
    "navName": "otak FMP Series 130 (371 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126006",
    "name": "Kotak FMP Series 130 (371 Days)",
    "category": "Income",
    "navName": "otak FMP Series 130 (371 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126052",
    "name": "Kotak FMP Series 131 (1061 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 131 (1061 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": "INF174K01TB1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126053",
    "name": "Kotak FMP Series 131 (1061 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 131 (1061 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": "INF174K01SZ2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126054",
    "name": "Kotak FMP Series 131 (1061 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 131 (1061 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": "INF174K01TA3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126055",
    "name": "Kotak FMP Series 131 (1061 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 131 (1061 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126086",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-23",
    "closeData": "",
    "isin": "INF109KA1JJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126087",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-23",
    "closeData": "",
    "isin": "INF109KA1JK2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126088",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1825 Days - Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-23",
    "closeData": "",
    "isin": "INF109KA1JL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126113",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D -Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF109KA1JO4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126114",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF109KA1JP1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126115",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF109KA1JQ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126116",
    "name": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 5 - 1100 Days - Plan D -Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF109KA1JN6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126125",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-26",
    "closeData": "",
    "isin": "INF109KA12Q2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126126",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-26",
    "closeData": "",
    "isin": "INF109KA13Q0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126127",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2013-12-26",
    "closeData": "",
    "isin": "INF109KA10Q6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126128",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-26",
    "closeData": "",
    "isin": "INF109KA11Q4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126167",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-01",
    "closeData": "",
    "isin": "INF109KB1HL3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126169",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-01",
    "closeData": "",
    "isin": "INF109KB1HJ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126170",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-525 Days Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-01",
    "closeData": "",
    "isin": "INF109KB1HK5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126231",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years Plan - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-02",
    "closeData": "",
    "isin": "INF109KA1KF0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126232",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-02",
    "closeData": "",
    "isin": "INF109KA1KD5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126233",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-02",
    "closeData": "",
    "isin": "INF109KA1KE3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126234",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN A - 5 Years Plan - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-02",
    "closeData": "",
    "isin": "INF109KA1KG8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126254",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 13 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-03",
    "closeData": "",
    "isin": "INF204KA1GP1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126255",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 13 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-03",
    "closeData": "",
    "isin": "INF204KA1GQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126256",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 13 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-03",
    "closeData": "",
    "isin": "INF204KA1GN6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126257",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 13 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "126279",
    "name": "SBI Tax Advantage Fund - Series III",
    "category": "ELSS",
    "navName": "SBI Tax Advantage Fund - Series III - Direct Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2013-12-28",
    "closeData": "2014-03-27",
    "isin": "INF200K01X06",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "126378",
    "name": "SBI Tax Advantage Fund - Series III",
    "category": "ELSS",
    "navName": "SBI Tax Advantage Fund-Series- III - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2013-12-28",
    "closeData": "2014-03-27",
    "isin": "INF200K01W98",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "126379",
    "name": "SBI Tax Advantage Fund - Series III",
    "category": "ELSS",
    "navName": "SBI Tax Advantage Fund - Series III - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2013-12-28",
    "closeData": "2014-03-27",
    "isin": "INF200K01W80",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "126278",
    "name": "SBI Tax Advantage Fund - Series III",
    "category": "ELSS",
    "navName": "SBI Tax Advantage Fund-Series- III - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2013-12-28",
    "closeData": "2014-03-27",
    "isin": "INF200K01X14",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126280",
    "name": "Kotak FMP Series 132 (546 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 132 (546 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-27",
    "closeData": "",
    "isin": "INF174K01TD7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126281",
    "name": "Kotak FMP Series 132 (546 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 132 (546 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2013-12-27",
    "closeData": "",
    "isin": "INF174K01TF2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126282",
    "name": "Kotak FMP Series 132 (546 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 132 (546 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-27",
    "closeData": "",
    "isin": "INF174K01TE5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126283",
    "name": "Kotak FMP Series 132 (546 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 132 (546 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2013-12-27",
    "closeData": "",
    "isin": "INF174K01TG0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126324",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA14R6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126325",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA12R0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126327",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA13R8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126332",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA13S6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126334",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA11S0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126335",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF109KA12S8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "126350",
    "name": "Invesco India - Invesco Pan European Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Pan European Equity Fund of Fund - Regular Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF205K01A08",
    "isinRe": "INF205K01A16"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "126351",
    "name": "Invesco India - Invesco Pan European Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Pan European Equity Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF205K01ZZ9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "126352",
    "name": "Invesco India - Invesco Pan European Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Pan European Equity Fund of Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF205K01A32",
    "isinRe": "INF205K01A40"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "126353",
    "name": "Invesco India - Invesco Pan European Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Pan European Equity Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF205K01A24",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126371",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 14-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-08",
    "closeData": "",
    "isin": "INF204KA1GT3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126372",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 14-Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-08",
    "closeData": "",
    "isin": "INF204KA1GR7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "126391",
    "name": "DSP Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "DSP Dynamic Asset Allocation Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF740K01K99",
    "isinRe": "INF740K01L07"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "126392",
    "name": "DSP Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "DSP Dynamic Asset Allocation Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF740K01K65",
    "isinRe": "INF740K01K73"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "126393",
    "name": "DSP Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "DSP Dynamic Asset Allocation Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF740K01K81",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "126394",
    "name": "DSP Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "DSP Dynamic Asset Allocation Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF740K01K57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126395",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-10",
    "closeData": "",
    "isin": "INF109KB1HG3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126396",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-10",
    "closeData": "",
    "isin": "INF109KB1HH1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126397",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-500 Days Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-10",
    "closeData": "",
    "isin": "INF109KB1HF5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126473",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF109KA1LE1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126474",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF109KA1LC5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126475",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF109KA1LD3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126476",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1092 Days Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF109KA1LB7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126477",
    "name": "Nippon India Fixed Horizon Fund - XXV - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXV - Series 15 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF204KB1A06",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126478",
    "name": "Nippon India Fixed Horizon Fund - XXV - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXV - Series 15 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF204KB1ZZ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126479",
    "name": "Nippon India Fixed Horizon Fund - XXV - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXV - Series 15 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF204KB1A14",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126480",
    "name": "Nippon India Fixed Horizon Fund - XXV - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXV - Series 15 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-14",
    "closeData": "",
    "isin": "INF204KB1ZY2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126483",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 16 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF204KA1HC7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126484",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 16 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF204KA1HB9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126485",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 16 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF204KA1GZ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126486",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 16 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF204KA1HA1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126495",
    "name": "Kotak Hybrid Fixed Term Plan Series 2",
    "category": "Income",
    "navName": "Kotak Hybrid Fixed Term Plan Series 2 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF174K01TH8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126496",
    "name": "Kotak Hybrid Fixed Term Plan Series 2",
    "category": "Income",
    "navName": "Kotak Hybrid Fixed Term Plan Series 2 - Direct Plan - Diviedend",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF174K01TK2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126497",
    "name": "Kotak Hybrid Fixed Term Plan Series 2",
    "category": "Income",
    "navName": "Kotak Hybrid Fixed Term Plan Series 2 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF174K01TJ4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126498",
    "name": "Kotak Hybrid Fixed Term Plan Series 2",
    "category": "Income",
    "navName": "Kotak Hybrid Fixed Term Plan Series 2 - Regular Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-07",
    "closeData": "",
    "isin": "INF174K01TI6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126499",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF109KA1KX3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126500",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF109KA1LA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126501",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF109KA1KY1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126502",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-823 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-15",
    "closeData": "",
    "isin": "INF109KA1KZ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126560",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA18U1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126561",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA19U9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126562",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA16U5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126563",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-370 Days Plan G - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA17U3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126564",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA1LH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126565",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA1LI2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126566",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA1LF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126567",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN B - 1100 Days - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF109KA1LG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126641",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-23",
    "closeData": "",
    "isin": "INF109KB1GA8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126642",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-23",
    "closeData": "",
    "isin": "INF109KB1GC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126643",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-23",
    "closeData": "",
    "isin": "INF109KB1GB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126644",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-483 Days Plan J - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-23",
    "closeData": "",
    "isin": "INF109KB1FZ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126665",
    "name": "Kotak FMP Series 133 (524 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 133 (524 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF174K01TL0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126666",
    "name": "Kotak FMP Series 133 (524 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 133 (524 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": "INF174K01TN6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126667",
    "name": "Kotak FMP Series 133 (524 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 133 (524 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126668",
    "name": "Kotak FMP Series 133 (524 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 133 (524 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126675",
    "name": "ICICI Prudential Equity Savings Fund   Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Equity Savings Fund - Series 1 - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-20",
    "closeData": "",
    "isin": "INF109KA1LJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126676",
    "name": "ICICI Prudential Equity Savings Fund   Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Equity Savings Fund - Series 1 Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-20",
    "closeData": "",
    "isin": "INF109KA1LL6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126677",
    "name": "ICICI Prudential Equity Savings Fund   Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Equity Savings Fund - Series 1  - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-20",
    "closeData": "",
    "isin": "INF109KA1LK8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126678",
    "name": "ICICI Prudential Equity Savings Fund   Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Equity Savings Fund - Series 1 Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-20",
    "closeData": "",
    "isin": "INF109KA1LM4",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "126685",
    "name": "Canara Robeco Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "CANARA ROBECO CORPORATE BOND FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF760K01HB2",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "126686",
    "name": "Canara Robeco Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "CANARA ROBECO CORPORATE BOND FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF760K01HC0",
    "isinRe": "INF760K01HD8"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "126687",
    "name": "Canara Robeco Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "CANARA ROBECO CORPORATE BOND FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF760K01GY6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "126688",
    "name": "Canara Robeco Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "CANARA ROBECO CORPORATE BOND FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000.00 and multiples of Re.1.00 thereafter",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF760K01GZ3",
    "isinRe": "INF760K01HA4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126701",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 17 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF204KA1HF0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126702",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 17 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF204KA1HD5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126703",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 17 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF204KA1HE3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126704",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 17 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126751",
    "name": "Kotak FMP Series 134 (480 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 134 (480 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126752",
    "name": "Kotak FMP Series 134 (480 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 134 (480 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126753",
    "name": "Kotak FMP Series 134 (480 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 134 (480 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126754",
    "name": "Kotak FMP Series 134 (480 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 134 (480 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126801",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-31",
    "closeData": "",
    "isin": "INF109KB1CI0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126802",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-31",
    "closeData": "",
    "isin": "INF109KB1CJ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126803",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-31",
    "closeData": "",
    "isin": "INF109KB1CK6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126804",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-440 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-31",
    "closeData": "",
    "isin": "INF109KB1CH2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126815",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 72 - 366 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-29",
    "closeData": "",
    "isin": "INF109KA10W4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126816",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 72 - 366 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan K - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-29",
    "closeData": "",
    "isin": "INF109KA11W2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126817",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 72 - 366 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-01-29",
    "closeData": "",
    "isin": "INF109KA18V9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126829",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF109KA1MH2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126830",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF109KA1MI0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126831",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF109KA1MJ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126832",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-785 Days Plan O - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF109KA1MK6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126873",
    "name": "Kotak FMP Series 135 (455 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 135 (455 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF174K01P65",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126874",
    "name": "Kotak FMP Series 135 (455 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 135 (455 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF174K01P81",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126875",
    "name": "Kotak FMP Series 135 (455 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 135 (455 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "126876",
    "name": "Kotak FMP Series 135 (455 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 135 (455 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126889",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 18 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF204KA1E20",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126891",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 18 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF204KA1E46",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126898",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF204KA1HT1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126899",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF204KA1HV7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126900",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF204KA1HU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126901",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan A - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF204KA1HW5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126902",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF109KA1MM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126903",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF109KA1MO8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126904",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF109KA1ML4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126905",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN C - 1825 Days - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-04",
    "closeData": "",
    "isin": "INF109KA1MN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126927",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF109KA14W6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126928",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF109KA15W3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "126929",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan M - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF109KA12W0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126931",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 19 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF204KA1HN4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126932",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 19 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF204KA1HL8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126933",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 19 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126934",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 19 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF204KA1HO2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126939",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund- Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789F014Z9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126940",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789F015Z6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126941",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789F012Z3",
    "isinRe": "INF789F013Z1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "126942",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789F016Z4",
    "isinRe": "INF789F017Z2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133971",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1U61",
    "isinRe": "INF789FA1U79"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141375",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1U87",
    "isinRe": "INF789FA1U95"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "133972",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1V29",
    "isinRe": "INF789FA1V37"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134026",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FC1HE1",
    "isinRe": "INF789FC1HF8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136110",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FC1HC5",
    "isinRe": "INF789FC1HD3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139407",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1U20",
    "isinRe": "INF789FA1U38"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140116",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1U46",
    "isinRe": "INF789FA1U53"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140135",
    "name": "UTI - Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "UTI Banking & PSU Debt Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs10 under both the plans",
    "launchDate": "2014-01-27",
    "closeData": "",
    "isin": "INF789FA1V03",
    "isinRe": "INF789FA1V11"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126975",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 20 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-06",
    "closeData": "",
    "isin": "INF204KA1HQ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126976",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 20 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-06",
    "closeData": "",
    "isin": "INF204KA1HR5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126977",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 20 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-06",
    "closeData": "",
    "isin": "INF204KA1HP9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "126978",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 20 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-06",
    "closeData": "",
    "isin": "INF204KA1HS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127031",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-07",
    "closeData": "",
    "isin": "INF109KB1BT9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127032",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-07",
    "closeData": "",
    "isin": "INF109KB1BR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127033",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-425 Days Plan N - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-07",
    "closeData": "",
    "isin": "INF109KB1BS1",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "127039",
    "name": "Motilal Oswal Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Motilal Oswal Midcap Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF247L01411",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "127040",
    "name": "Motilal Oswal Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Motilal Oswal Midcap Fund-Regular - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF247L01437",
    "isinRe": "INF247L01429"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "127042",
    "name": "Motilal Oswal Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Motilal Oswal Midcap Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF247L01445",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "127044",
    "name": "Motilal Oswal Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Motilal Oswal Midcap Fund-Direct - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF247L01460",
    "isinRe": "INF247L01452"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127049",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA13Y4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127050",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA14Y2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127051",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA15Y9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127052",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-368 Days Plan P - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA12Y6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127066",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 21 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1HZ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127067",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 21 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1HX3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127068",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 21 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1IA9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127069",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 21 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1HY1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "127070",
    "name": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Asia Pacific IDCW Yield Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF336L01HD5",
    "isinRe": "INF336L01HE3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "127071",
    "name": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF336L01HF0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "127072",
    "name": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Asia Pacific IDCW Yield Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF336L01HG8",
    "isinRe": "INF336L01HH6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "127073",
    "name": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Asia Pacific (Ex Japan) Dividend Yield Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-03",
    "closeData": "",
    "isin": "INF336L01HC7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127080",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 22 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF204KA1ID3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127081",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 22 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF204KA1IC5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127082",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 22 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF204KA1IE1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127083",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 22 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF204KA1IB7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127125",
    "name": "Kotak FMP Series 136 (376 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 136 (376 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF174K01UA1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127126",
    "name": "Kotak FMP Series 136 (376 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 136 (376 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF174K01H65",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127127",
    "name": "Kotak FMP Series 136 (376 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 136 (376 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF174K01H81",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127128",
    "name": "Kotak FMP Series 136 (376 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 136 (376 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF174K01TY3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127129",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA1ND9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127131",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A -Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA1NB3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127132",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan A -Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF109KA1NC1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "127181",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01GG2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "127182",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Quarterly IDCW - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01FU5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "127183",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Growth Regular",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01GH0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "127184",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Quarterly IDCW - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01GA5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128332",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Annual IDCW - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01FX9",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128337",
    "name": "IDBI Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "IDBI Credit Risk Fund Annual IDCW - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "2014-02-24",
    "isin": "INF397L01GD9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127227",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF109KA1NP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127228",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF109KA1NO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127229",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF109KA1NQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127230",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-1075 Days Plan Q - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-11",
    "closeData": "",
    "isin": "INF109KA1NN8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127231",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-367 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-367 Days Plan R - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-13",
    "closeData": "",
    "isin": "INF109KA18W7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127233",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-367 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-367 Days Plan R - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-13",
    "closeData": "",
    "isin": "INF109KA16W1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127235",
    "name": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KA1NW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127236",
    "name": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KA1NX7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127237",
    "name": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KA1NY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127238",
    "name": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series VII - Annual Interval Plan - C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KA1NV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127239",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 23 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1IF8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127240",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 23 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1IH4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127241",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 23 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1IG6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127242",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 23 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF204KA1II2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127255",
    "name": "Kotak FMP Series 137 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 137 (371 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF174K01H24",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127256",
    "name": "Kotak FMP Series 137 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 137 (371 Days) -  Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": "INF174K01H40",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127257",
    "name": "Kotak FMP Series 137 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 137 (371 Days) -  Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127258",
    "name": "Kotak FMP Series 137 (371 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 137 (371 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127259",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KB1AZ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127260",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KB1BA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127261",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KB1AX3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127262",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-409 Days Plan S - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF109KB1AY1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127264",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 24 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1G10",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127266",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 24 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1F94",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127267",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 24 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1G28",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127268",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1IR3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127269",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1IT9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127270",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1IU7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127271",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan B - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-02-17",
    "closeData": "",
    "isin": "INF204KA1IS1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127351",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-19",
    "closeData": "",
    "isin": "INF109KA10Y0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127352",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-19",
    "closeData": "",
    "isin": "INF109KA18X5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127354",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 72-366 Days Plan T - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-19",
    "closeData": "",
    "isin": "INF109KA11Y8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127359",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 25 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-02-18",
    "closeData": "",
    "isin": "INF204KA1IN2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127360",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 25 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-02-18",
    "closeData": "",
    "isin": "INF204KA1IQ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127361",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 25 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-02-18",
    "closeData": "",
    "isin": "INF204KA1IO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127362",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 25 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-02-18",
    "closeData": "",
    "isin": "INF204KA1IP7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127387",
    "name": "Kotak FMP Series 140 (1095 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 140 (1095 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-12",
    "closeData": "",
    "isin": "INF174K01UN4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127388",
    "name": "Kotak FMP Series 140 (1095 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 140 (1095 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-12",
    "closeData": "",
    "isin": "INF174K01UP9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127389",
    "name": "Kotak FMP Series 140 (1095 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 140 (1095 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-12",
    "closeData": "",
    "isin": "INF174K01UO2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127390",
    "name": "Kotak FMP Series 140 (1095 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 140 (1095 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-12",
    "closeData": "",
    "isin": "INF174K01UQ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127400",
    "name": "Kotak FMP Series 141 (454 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 141 (454 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF174K01Q07",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127401",
    "name": "Kotak FMP Series 141 (454 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 141 (454 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF174K01Q31",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127402",
    "name": "Kotak FMP Series 141 (454 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 141 (454 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": "INF174K01Q23",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127403",
    "name": "Kotak FMP Series 141 (454 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 141 (454 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127524",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C -Direct Plan -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127525",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1IV5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127522",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1IW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127523",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan C -Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1IX1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127537",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 26 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF204KA1IZ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127538",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 26 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF204KA1JB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127540",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 27",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 27 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JD1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127541",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 27",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 27 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JF6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127542",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 27",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 27 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JG4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127543",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 27",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 27 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JE9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127544",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 28 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JI0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127545",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 28 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JK6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127546",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 28 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JH2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127547",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 28 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-02-24",
    "closeData": "",
    "isin": "INF204KA1JJ8",
    "isinRe": null
  },
  {
    "amc": "IIFCL Asset Management Co. Ltd.",
    "code": "127615",
    "name": "IIFCL MUTUAL FUND INFRASTRUCTURE DEBT FUND SERIES I",
    "category": "Income",
    "navName": "IIFCL MUTUAL FUND INFRASTRUCTURE DEBT FUND SERIES I",
    "minAmount": "3000000000",
    "launchDate": "2014-01-31",
    "closeData": "2014-02-06",
    "isin": "INF017Q01011NA",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127617",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-26",
    "closeData": "",
    "isin": "INF109KA18Z0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127618",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-26",
    "closeData": "",
    "isin": "INF109KB1011",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127619",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-26",
    "closeData": "",
    "isin": "INF109KA19Z8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127628",
    "name": "ICICI Prudential Value Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 3 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF109KA1OE5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127629",
    "name": "ICICI Prudential Value Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 3 - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF109KA1OD7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127650",
    "name": "Kotak FMP Series 142 (420 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 142 (420 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-20",
    "closeData": "",
    "isin": "INF174K01K86",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127651",
    "name": "Kotak FMP Series 142 (420 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 142 (420 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-20",
    "closeData": "",
    "isin": "INF174K01L10",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127652",
    "name": "Kotak FMP Series 142 (420 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 142 (420 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127653",
    "name": "Kotak FMP Series 142 (420 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 142 (420 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127712",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF109KA16Z4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127714",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-366 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF109KA14Z9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127716",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1094",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127717",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1078",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127718",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1086",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127720",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1BN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127721",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1BO0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127722",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-407 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KB1BP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127724",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 73 - 1140 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1140 Days Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KA1OX5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127725",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 73 - 1140 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1140 Days Plan E - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KA1OY3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127726",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 73 - 1140 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1140 Days Plan E - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KA1OV9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "127727",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 73 - 1140 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1140 Days Plan E - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": "INF109KA1OW7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127732",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 30 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-04",
    "closeData": "",
    "isin": "INF204KA1JP5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127733",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 30 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-04",
    "closeData": "",
    "isin": "INF204KA1JQ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127734",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 30 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-04",
    "closeData": "",
    "isin": "INF204KA1JS9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127735",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 30 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-04",
    "closeData": "",
    "isin": "INF204KA1JR1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127806",
    "name": "Kotak FMP Series 143 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 143 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-25",
    "closeData": "",
    "isin": "INF174K01J06",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127807",
    "name": "Kotak FMP Series 143 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 143 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-25",
    "closeData": "",
    "isin": "INF174K01J22",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127808",
    "name": "Kotak FMP Series 143 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 143 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-25",
    "closeData": "",
    "isin": "INF174K01J14",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "127809",
    "name": "Kotak FMP Series 143 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 143 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-02-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127841",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 31 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF204KA1JU5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127842",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 31 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127843",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 31 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF204KA1JT7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127844",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 31 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF204KA1JV3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "127849",
    "name": "BANK OF INDIA BALANCED ADVANTAGE FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANK OF INDIA BALANCED ADVANTAGE FUND REGULAR PLAN  GROWTH",
    "minAmount": "5000",
    "launchDate": "2014-02-21",
    "closeData": "2014-03-07",
    "isin": "INF761K01BR9",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "127850",
    "name": "BANK OF INDIA BALANCED ADVANTAGE FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANK OF INDIA BALANCED ADVANTAGE FUND DIRECT PLAN  GROWTH",
    "minAmount": "5000",
    "launchDate": "2014-02-21",
    "closeData": "2014-03-07",
    "isin": "INF761K01BO6",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "127851",
    "name": "BANK OF INDIA BALANCED ADVANTAGE FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANK OF INDIA BALANCED ADVANTAGE FUND  REGULAR PLAN IDCW",
    "minAmount": "5000",
    "launchDate": "2014-02-21",
    "closeData": "2014-03-07",
    "isin": "INF761K01BP3",
    "isinRe": "INF761K01BQ1"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "127852",
    "name": "BANK OF INDIA BALANCED ADVANTAGE FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANK OF INDIA BALANCED ADVANTAGE FUND  DIRECT PLAN IDCW",
    "minAmount": "5000",
    "launchDate": "2014-02-21",
    "closeData": "2014-03-07",
    "isin": "INF761K01BM0",
    "isinRe": "INF761K01BN8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127910",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 1 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF204KA1JX9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127911",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 1 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF204KA1JZ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127912",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 1 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF204KA1JY7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "127913",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 1 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF204KA1KA5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128006",
    "name": "Kotak Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Kotak Medium Term Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF174K01VQ5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128009",
    "name": "Kotak Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Kotak Medium Term Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF174K01VL6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128078",
    "name": "Kotak Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Kotak Medium Term Fund - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF174K01VN2",
    "isinRe": "INF174K01VP7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128079",
    "name": "Kotak Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Kotak Medium Term Fund - Direct - Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2014-02-28",
    "closeData": "",
    "isin": "INF174K01VSI",
    "isinRe": "INF174K01VU7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128038",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF109KB1797",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128039",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF109KB1805",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128040",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF109KB1813",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128041",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-391 Days Plan G - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF109KB1821",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128042",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF109KB1771",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128043",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF109KB1789",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128044",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF109KB1755",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128045",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-392 Days Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-05",
    "closeData": "",
    "isin": "INF109KB1763",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133147",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - IDCW Option - Direct Plan",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1I61",
    "isinRe": "INF179KA1I53"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "133148",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1I87",
    "isinRe": "INF179KA1I79"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128051",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - Growth Option - Direct Plan",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1FZ3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128052",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - Quarterly IDCW Option",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1GD8",
    "isinRe": "INF179KA1GE6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128053",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - Growth Option",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1GC0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128050",
    "name": "HDFC Credit Risk Debt Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HDFC Credit Risk Debt Fund - Quarterly IDCW - Direct Plan",
    "minAmount": "5000.00",
    "launchDate": "2014-03-06",
    "closeData": "2014-03-20",
    "isin": "INF179KA1GA4",
    "isinRe": "INF179KA1GB2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128058",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan H - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1037",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128060",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan H - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1052",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128074",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1BM4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128075",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1BJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128076",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1BL6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128077",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-390 Days Plan I - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1BK8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128081",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 2 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF204KA1KV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128082",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF204KA1KX7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128083",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128084",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 2 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF204KA1KW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128111",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1XS5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128112",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1XR7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128113",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1XP1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128114",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-830 Days Plan J - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF109KB1XQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128175",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF204KA1KR9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128176",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF204KA1KT5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128177",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF204KA1KS7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128178",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan E - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF204KA1KU3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128180",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 32 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF204KA1KB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128181",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 32 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF204KA1KD9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128183",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 33 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1KF4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128184",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 33 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1KG2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128185",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 33 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1KH0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128186",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 33 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128198",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 34",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 34 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1KJ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128199",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 34",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 34 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1KL2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128201",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF109KB1854",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128202",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF109KB1862",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128203",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF109KB1839",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128204",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-383 Days Plan K - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF109KB1847",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128205",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF109KA1PR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128206",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF109KA1PS2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128207",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF109KA1PP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128208",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1125 Days - Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-11",
    "closeData": "",
    "isin": "INF109KA1PQ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128209",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF109KA1QI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128210",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF109KA1QF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128211",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF109KA1QG5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128212",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN D - 1100 Days - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-12",
    "closeData": "",
    "isin": "INF109KA1QH3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128233",
    "name": "IDBI FLEXI CAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "IDBI FLEXI CAP FUND IDCW - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "2014-03-24",
    "isin": "INF397L01HC9",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128234",
    "name": "IDBI FLEXI CAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "IDBI FLEXI CAP FUND IDCW - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "2014-03-24",
    "isin": "INF397L01HG0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128235",
    "name": "IDBI FLEXI CAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "IDBI FLEXI CAP FUND Growth Regular",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "2014-03-24",
    "isin": "INF397L01HJ4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "128236",
    "name": "IDBI FLEXI CAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "IDBI FLEXI CAP FUND Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "2014-03-24",
    "isin": "INF397L01HF2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128265",
    "name": "Kotak FMP Series 145 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 145 (390 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF174K01L51",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128266",
    "name": "Kotak FMP Series 145 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 145 (390 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF174K01L93",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128267",
    "name": "Kotak FMP Series 145 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 145 (390 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": "INF174K01P16",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128268",
    "name": "Kotak FMP Series 145 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 145 (390 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128317",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 35- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1LA3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128318",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 35- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1LB1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128319",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 35- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1KZ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128320",
    "name": "Reliance Fixed Horizon Fund - XXV - Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXV - Series 35- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1LC9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128331",
    "name": "Nippon India ETF Nifty India Consumption",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty India Consumption",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF204KA1LD7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128350",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1896",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128351",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1904",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128352",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1870",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128353",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan N - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1888",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128354",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1532",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128356",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1524",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128357",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan M - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KB1516",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128358",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KA1QM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128359",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KA1QJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128360",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KA1QK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128361",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-1120 Days Plan L - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "",
    "isin": "INF109KA1QL5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128362",
    "name": "Kotak FMP Series 146 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 146 (388 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128363",
    "name": "Kotak FMP Series 146 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 146 (388 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128364",
    "name": "Kotak FMP Series 146 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 146 (388 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF174K01M19",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128365",
    "name": "Kotak FMP Series 146 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 146 (388 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-10",
    "closeData": "",
    "isin": "INF174K01M35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128458",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 4-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1LI6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128459",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 4-Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1LJ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128460",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 4-Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1LK2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128461",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 4-Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF204KA1LL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128492",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF109KA1RD0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128493",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C -Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF109KA1RE8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128494",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF109KA1RF5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128495",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan C - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF109KA1RG3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128496",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "",
    "isin": "INF109KB1AR5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128497",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "",
    "isin": "INF109KB1AS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128498",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "",
    "isin": "INF109KB1AP9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128499",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-378 Days Plan O - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "",
    "isin": "INF109KB1AQ7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128542",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IF9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128543",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IH5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128544",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IB8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128545",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1ID4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128538",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IC6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128539",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Flexi Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IE2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128540",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IG7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128541",
    "name": "HDFC FMP 3360D March 2014 (1)",
    "category": "Income",
    "navName": "HDFC FMP 3360D March 2014 (1) - Flexi Option",
    "minAmount": "5000",
    "launchDate": "2014-03-14",
    "closeData": "2014-03-25",
    "isin": "INF179KA1II3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128574",
    "name": "Kotak FMP Series 147 (384 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 147 (384 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF174K01M50",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128575",
    "name": "Kotak FMP Series 147 (384 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 147 (384 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF174K01M76",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128576",
    "name": "Kotak FMP Series 147 (384 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 147 (384 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128577",
    "name": "Kotak FMP Series 147 (384 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 147 (384 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128578",
    "name": "Kotak FMP Series 148 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 148 (388 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF174K01M92",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128579",
    "name": "Kotak FMP Series 148 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 148 (388 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": "INF174K01N18",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128580",
    "name": "Kotak FMP Series 148 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 148 (388 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128581",
    "name": "Kotak FMP Series 148 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 148 (388 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128590",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF204KA1LM8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128591",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI- Series 5- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF204KA1LN6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128592",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI- Series 5- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF204KA1LO4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128593",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI- Series 5- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128626",
    "name": "HDFC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HDFC Banking and PSU Debt Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "2014-03-25",
    "isin": "INF179KA1JA8",
    "isinRe": "INF179KA1JB6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128627",
    "name": "HDFC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HDFC Banking and PSU Debt Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "2014-03-25",
    "isin": "INF179KA1JD2",
    "isinRe": "INF179KA1JE0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128628",
    "name": "HDFC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HDFC Banking and PSU Debt Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "2014-03-25",
    "isin": "INF179KA1JC4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "128629",
    "name": "HDFC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HDFC Banking and PSU Debt Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-03-20",
    "closeData": "2014-03-25",
    "isin": "INF179KA1IZ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128635",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 6-  Growth Option",
    "minAmount": "Minimum application amount Rs. 5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF204KA1LQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128637",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 6- Direct Plan - Growth Option",
    "minAmount": "Minimum application amount Rs. 5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF204KA1LS5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128638",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 6- Direct Plan - Dividend Payout Option",
    "minAmount": "Minimum application amount Rs. 5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF204KA1LT3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128639",
    "name": "Nippon India ETF Nifty Dividend Opportunities 50",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty Dividend Opportunities 50",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2014-03-24",
    "closeData": "",
    "isin": "INF204KA1MS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128646",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1AJ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128648",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1AH6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128649",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-376 Days Plan Q - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1AI4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128650",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P- Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1656",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128652",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P- Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1631",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128653",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan P- Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-21",
    "closeData": "",
    "isin": "INF109KB1649",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128685",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 7- Dividend payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LV9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128686",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 7- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LX5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128687",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 7- Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LU1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128688",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 7- Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LW7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128689",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F- Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LY3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128690",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F- Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1MA1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128691",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1MB9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128692",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V - Plan F- Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF204KA1LZ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128752",
    "name": "Kotak FMP Series 150 (1109 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 150 (1109 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-18",
    "closeData": "",
    "isin": "INF174K01WI0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128753",
    "name": "Kotak FMP Series 150 (1109 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 150 (1109 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-18",
    "closeData": "",
    "isin": "INF174K01WK6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128754",
    "name": "Kotak FMP Series 150 (1109 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 150 (1109 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-18",
    "closeData": "",
    "isin": "INF174K01WH2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128755",
    "name": "Kotak FMP Series 150 (1109 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 150 (1109 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-18",
    "closeData": "",
    "isin": "INF174K01WJ8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128756",
    "name": "Kotak FMP Series 149 (386 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 149 (386 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF174K01N34",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128757",
    "name": "Kotak FMP Series 149 (386 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 149 (386 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128758",
    "name": "Kotak FMP Series 149 (386 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 149 (386 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": "INF174K01N59",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128759",
    "name": "Kotak FMP Series 149 (386 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 149 (386 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128808",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S- Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1938",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128809",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S- Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1946",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128810",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S- Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1920",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128811",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan S- Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1912",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128812",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan R - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1615",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128814",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-368 Days Plan R - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-26",
    "closeData": "",
    "isin": "INF109KB1599",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128816",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF109KB1AN4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128817",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF109KB1AO2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128818",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF109KB1AL8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128819",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 73-369 Days Plan T - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF109KB1AM6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128836",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MC7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128837",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 8- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MD5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128838",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 8- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1ME3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128839",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 8- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MF0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128840",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 9- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MH6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128841",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 9- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MI4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128842",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 9- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128843",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-03-28",
    "closeData": "",
    "isin": "INF204KA1MG8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128868",
    "name": "Kotak FMP Series 151 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 151 (388 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-24",
    "closeData": "",
    "isin": "INF174K01N75",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128869",
    "name": "Kotak FMP Series 151 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 151 (388 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-24",
    "closeData": "",
    "isin": "INF174K01N83",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128870",
    "name": "Kotak FMP Series 151 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 151 (388 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128871",
    "name": "Kotak FMP Series 151 (388 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 151 (388 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-24",
    "closeData": "",
    "isin": "INF174K01N91",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128902",
    "name": "Kotak FMP Series 153 (790 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 153 (790 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF174K01WY7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128903",
    "name": "Kotak FMP Series 153 (790 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 153 (790 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128904",
    "name": "Kotak FMP Series 153 (790 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 153 (790 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF174K01WX9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128905",
    "name": "Kotak FMP Series 153 (790 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 153 (790 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-25",
    "closeData": "",
    "isin": "INF174K01WZ4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128906",
    "name": "Kotak FMP Series 154 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 154 (390 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF174K01O17",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128907",
    "name": "Kotak FMP Series 154 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 154 (390 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF174K01O25",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128908",
    "name": "Kotak FMP Series 154 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 154 (390 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "128909",
    "name": "Kotak FMP Series 154 (390 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 154 (390 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-03-27",
    "closeData": "",
    "isin": "INF174K01O33",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128964",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 12- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1NB7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128965",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 12- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1NC5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128966",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 12- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1ND3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128967",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 12- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1NE1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128984",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1MX3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128985",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1MY1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128986",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G- Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1MZ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "128987",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan G- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF204KA1NA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128989",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series A - 750 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Corporate Bond Series A-750 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF109KA1SF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "128991",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series A - 750 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Corporate Bond Series A-750 Days - Direct Plan-Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF109KA1SH9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129006",
    "name": "Franklin India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Franklin India Banking & PSU Debt Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-02",
    "closeData": "",
    "isin": "INF090I01KO5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129007",
    "name": "Franklin India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Franklin India Banking and PSU Debt Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2014-04-02",
    "closeData": "",
    "isin": "INF090I01KP2",
    "isinRe": "INF090I01KQ0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129008",
    "name": "Franklin India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Franklin India Banking & PSU Debt Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-02",
    "closeData": "",
    "isin": "INF090I01KR8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129009",
    "name": "Franklin India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Franklin India Banking and PSU Debt Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2014-04-02",
    "closeData": "",
    "isin": "INF090I01KS6",
    "isinRe": "INF090I01KT4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129018",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF204KA1NF8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129019",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 13- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF204KA1NG6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129020",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 13- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF204KA1NH4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129021",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 13- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF204KA1NI2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129022",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF109KB1CN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129023",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF109KB1CO8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129024",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF109KB1CL4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129025",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan A - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-10",
    "closeData": "",
    "isin": "INF109KB1CM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129027",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-11",
    "closeData": "",
    "isin": "INF109KB1CU5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129028",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-11",
    "closeData": "",
    "isin": "INF109KB1CV3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129029",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-11",
    "closeData": "",
    "isin": "INF109KB1CW1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129026",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan B - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-11",
    "closeData": "",
    "isin": "INF109KB1CT7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129034",
    "name": "Kotak FMP Series 156 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 156 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-03",
    "closeData": "",
    "isin": "INF174K01K45",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129035",
    "name": "Kotak FMP Series 156 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 156 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-03",
    "closeData": "",
    "isin": "INF174K01P08",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129036",
    "name": "Kotak FMP Series 156 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 156 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-03",
    "closeData": "",
    "isin": "INF174K01O90",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129037",
    "name": "Kotak FMP Series 156 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 156 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-03",
    "closeData": "",
    "isin": "INF174K01K52",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "129046",
    "name": "Motilal Oswal Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Motilal Oswal Flexi cap Fund Direct Plan-Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF247L01502",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "129047",
    "name": "Motilal Oswal Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Motilal Oswal Flexi Cap Fund Direct - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF247L01528",
    "isinRe": "INF247L01510"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "129048",
    "name": "Motilal Oswal Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Motilal Oswal Flexi Cap Fund Regular Plan-Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF247L01478",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "129049",
    "name": "Motilal Oswal Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Motilal Oswal Flexi cap Fund Regular - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-04-07",
    "closeData": "",
    "isin": "INF247L01494",
    "isinRe": "INF247L01486"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129066",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E -Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SN7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129067",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129068",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SP2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129069",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days - Plan E -Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129070",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E- 1100 Days - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SL1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129071",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E- 1100 Days - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129072",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V - PLAN E- 1100 Days - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF109KA1SK3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129113",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 14- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-16",
    "closeData": "",
    "isin": "INF204KA1NJ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129114",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 14- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-16",
    "closeData": "",
    "isin": "INF204KA1NL6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129115",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 14- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-16",
    "closeData": "",
    "isin": "INF204KA1NM4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129116",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 14- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-16",
    "closeData": "",
    "isin": "INF204KA1NK8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129147",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 15- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF204KA1NN2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129148",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 15- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF204KA1NO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129149",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 15- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF204KA1NP7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129150",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 15- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF204KA1NQ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129183",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 16- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF204KA1NR3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129184",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 16- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF204KA1NU7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129185",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 16- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF204KA1NS1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129186",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 16- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF204KA1NT9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "129187",
    "name": "Invesco India - Invesco Global Equity Income Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Equity Income Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- per application and in multiples of 1",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF205K01B15",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "129188",
    "name": "Invesco India - Invesco Global Equity Income Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Equity Income Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- per application and in multiples of 1",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF205K01B49",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "129189",
    "name": "Invesco India - Invesco Global Equity Income Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Equity Income Fund of Fund - Regular Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of 1",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF205K01B23",
    "isinRe": "INF205K01B31"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "129190",
    "name": "Invesco India - Invesco Global Equity Income Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Equity Income Fund of Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of 1",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF205K01B56",
    "isinRe": "INF205K01B64"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "129210",
    "name": "Sundaram Infrastructure Advantage Fund (Erstwhile Sundaram Capex Opportunities)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Infrastructure Advantage Fund Regular Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-08-10",
    "closeData": "",
    "isin": "INF903J01F76",
    "isinRe": "INF903J01F84"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "129211",
    "name": "Sundaram Infrastructure Advantage Fund (Erstwhile Sundaram Capex Opportunities)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Infrastructure Advantage Fund Direct Plan - Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-08-10",
    "closeData": "",
    "isin": "INF903J01G00",
    "isinRe": "INF903J01G18"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "129212",
    "name": "Sundaram Infrastructure Advantage Fund (Erstwhile Sundaram Capex Opportunities)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Infrastructure Advantage Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2005-08-10",
    "closeData": "",
    "isin": "INF903J01F92",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "129213",
    "name": "Sundaram Infrastructure Advantage Fund (Erstwhile Sundaram Capex Opportunities)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Infrastructure Advantage Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2005-08-10",
    "closeData": "",
    "isin": "INF903J01F68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129228",
    "name": "ICICI Prudential Value Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 4 Direct Plan Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TS4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129229",
    "name": "ICICI Prudential Value Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 4  Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129234",
    "name": "ICICI Prudential Value Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 4 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TR6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129235",
    "name": "ICICI Prudential Value Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 4 Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129230",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days Plan D Direct Plan- Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TW6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129231",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TV8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129232",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TT2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129233",
    "name": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1825 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KA1TU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129236",
    "name": "ICICI Prudential Fixed Maturity Plan   Series 74   780 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 780 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1XL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129237",
    "name": "ICICI Prudential Fixed Maturity Plan   Series 74   780 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 780 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1XM8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129238",
    "name": "ICICI Prudential Fixed Maturity Plan   Series 74   780 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 780 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1XN6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129239",
    "name": "ICICI Prudential Fixed Maturity Plan   Series 74   780 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 780 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1XO4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129240",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF109KB1CR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129241",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF109KB1CQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129243",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan C - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF109KB1CP5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129244",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1DY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129245",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1DZ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129246",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1EK2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129247",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-367 Days Plan D - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1EA3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129248",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1CX9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129249",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1CZ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129251",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan F - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-21",
    "closeData": "",
    "isin": "INF109KB1CY7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129252",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G - Direct Plan - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF109KA1TJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129253",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF109KA1TK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129254",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G - Cumulative",
    "minAmount": "5000",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF109KA1TH7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129255",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan G - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF109KA1TI5",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "129286",
    "name": "IL&FS Infrastructure Fund Series 1B",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1B - Growth",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01033",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "130566",
    "name": "IL&FS Infrastructure Fund Series 1B",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1B - Dividend Payout",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01041",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "129287",
    "name": "IL&FS Infrastructure Debt Fund Series 1A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1A - Growth",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01017",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "130565",
    "name": "IL&FS Infrastructure Debt Fund Series 1A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1A - Dividend payout option",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01025",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "129288",
    "name": "IL&FS Infrastructure Debt Fund Series 1C",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1C - Growth",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01058",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "130567",
    "name": "IL&FS Infrastructure Debt Fund Series 1C",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 1C- Dividend Payout Option",
    "minAmount": "500000000",
    "launchDate": "2013-06-18",
    "closeData": "",
    "isin": "INF613Q01066",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129289",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 17- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1NV5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129290",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 17- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1NW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129291",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 17- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1NX1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129292",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 17- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129293",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 18- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1OC3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129294",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 18- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1NZ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129295",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 18- Dividend payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1OA7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129296",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 18- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF204KA1OB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129312",
    "name": "ICICI Prudential Dividend Yield Equity Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "ICICI Prudential Dividend Yield Equity Fund Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF109KA1UA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129309",
    "name": "ICICI Prudential Dividend Yield Equity Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "ICICI Prudential Dividend Yield Equity Fund Direct Plan IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF109KA1UB8",
    "isinRe": "INF109KA1UC6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129310",
    "name": "ICICI Prudential Dividend Yield Equity Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "ICICI Prudential Dividend Yield Equity Fund Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF109KA1TX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129311",
    "name": "ICICI Prudential Dividend Yield Equity Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "ICICI Prudential Dividend Yield Equity Fund IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF109KA1TY2",
    "isinRe": "INF109KA1TZ9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129339",
    "name": "Kotak FMP Series 157 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 157 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF174K01K60",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129340",
    "name": "Kotak FMP Series 157 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 157 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": "INF174K01L02",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129341",
    "name": "Kotak FMP Series 157 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 157 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129342",
    "name": "Kotak FMP Series 157 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 157 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129351",
    "name": "Kotak FMP Series 158 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 158 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF174K01L44",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129352",
    "name": "Kotak FMP Series 158 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 158 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF174K01L77",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129353",
    "name": "Kotak FMP Series 158 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 158 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": "INF174K01L69",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129354",
    "name": "Kotak FMP Series 158 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 158 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129405",
    "name": "Kotak FMP Series 159 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 159 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF174K01Q56",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129406",
    "name": "Kotak FMP Series 159 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 159 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF174K01Q64",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129407",
    "name": "Kotak FMP Series 159 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 159 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF174K01XY5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129408",
    "name": "Kotak FMP Series 159 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 159 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-23",
    "closeData": "",
    "isin": "INF174K01Q72",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129409",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan Series 74 368 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-04-28",
    "closeData": "",
    "isin": "INF109KB1EJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129410",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan Series 74 368 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-28",
    "closeData": "",
    "isin": "INF109KB1DW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129412",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan Series 74 368 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-04-28",
    "closeData": "",
    "isin": "INF109KB1DV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129417",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 19- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-30",
    "closeData": "",
    "isin": "INF204KA1OE9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129418",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 19- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-30",
    "closeData": "",
    "isin": "INF204KA1OF6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129419",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 19- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129420",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 19- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-04-30",
    "closeData": "",
    "isin": "INF204KA1OD1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129438",
    "name": "Franklin India Feeder - Templeton European Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Templeton European Opportunities Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF090I01KU2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129439",
    "name": "Franklin India Feeder - Templeton European Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Templeton European Opportunities Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF090I01KV0",
    "isinRe": "INF090I01KW8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129440",
    "name": "Franklin India Feeder - Templeton European Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Templeton European Opportunities Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF090I01KX6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "129441",
    "name": "Franklin India Feeder - Templeton European Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Franklin India Feeder - Templeton European Opportunities Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2014-04-25",
    "closeData": "",
    "isin": "INF090I01KY4",
    "isinRe": "INF090I01KZ1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129470",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74-369 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-05",
    "closeData": "",
    "isin": "INF109KB1FD4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129471",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan I - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-05",
    "closeData": "",
    "isin": "INF109KB1FC6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129472",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-05",
    "closeData": "",
    "isin": "INF109KB1FB8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129473",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-05",
    "closeData": "",
    "isin": "INF109KB1FE2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129508",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 20- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-07",
    "closeData": "",
    "isin": "INF204KA1OH2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129509",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 20- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-07",
    "closeData": "",
    "isin": "INF204KA1OI0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129510",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 20- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-07",
    "closeData": "",
    "isin": "INF204KA1OJ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129511",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 20- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129519",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 21- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129520",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 21- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OM2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129521",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 21- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1ON0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129522",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 21",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 21- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129540",
    "name": "Kotak FMP Series 160 (1039 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 160 (1039 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-02",
    "closeData": "",
    "isin": "INF174K01XZ2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129541",
    "name": "Kotak FMP Series 160 (1039 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 160 (1039 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-02",
    "closeData": "",
    "isin": "INF174K01YB1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129542",
    "name": "Kotak FMP Series 160 (1039 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 160 (1039 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-02",
    "closeData": "",
    "isin": "INF174K01YA3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129543",
    "name": "Kotak FMP Series 160 (1039 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 160 (1039 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-02",
    "closeData": "",
    "isin": "INF174K01YC9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129560",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 22- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OP5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129561",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 22- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OQ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129562",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 22- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OS9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129563",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 22",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 22- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OR1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129574",
    "name": "Reliance Close Ended Equity Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund II- Series A- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OT7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129571",
    "name": "Reliance Close Ended Equity Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund II- Series A- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OV3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129572",
    "name": "Reliance Close Ended Equity Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund II- Series A- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OW1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129573",
    "name": "Reliance Close Ended Equity Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Close Ended Equity Fund II- Series A- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-09",
    "closeData": "",
    "isin": "INF204KA1OU5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129638",
    "name": "Kotak FMP Series 161 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 161 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-06",
    "closeData": "",
    "isin": "INF174K01Q98",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129639",
    "name": "Kotak FMP Series 161 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 161 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-06",
    "closeData": "",
    "isin": "INF174K01R14",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129640",
    "name": "Kotak FMP Series 161 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 161 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-06",
    "closeData": "",
    "isin": "INF174K01R06",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129641",
    "name": "Kotak FMP Series 161 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 161 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-06",
    "closeData": "",
    "isin": "INF174K01R22",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129642",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF109KB1FA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129643",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan J Direct Plan-Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF109KB1EZ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129644",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF109KB1EX5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129645",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF109KB1EY3",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "129646",
    "name": "Union Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Union Small Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of  Rs.1 thereafter.",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF582M01BW5",
    "isinRe": "INF582M01BV7"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "129647",
    "name": "Union Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Union Small Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of  Rs.1 thereafter.",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF582M01BY1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "129648",
    "name": "Union Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Union Small Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of  Rs.1 thereafter.",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF582M01CA9",
    "isinRe": "INF582M01BZ8"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "129649",
    "name": "Union Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Union Small Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of  Rs.1 thereafter.",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF582M01BU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129679",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 23- Dividend Payout Opiton",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1OY7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129680",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 23- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1OX9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129681",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 23- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1PA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129682",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 23",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 23- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1OZ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129683",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 24- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1PB2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129684",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 24- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1PE6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129685",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 24- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1PC0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129686",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 24",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 24- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF204KA1PD8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129715",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 - 369 Days Plan K - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KB1FL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129716",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 369 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KB1FM5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129717",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 369 Days Plan K Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KB1FJ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129718",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan K Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KB1FK9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129729",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 25- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-21",
    "closeData": "",
    "isin": "INF204KA12D9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129730",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 25- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-21",
    "closeData": "",
    "isin": "INF204KA10D3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129731",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 25- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-21",
    "closeData": "",
    "isin": "INF204KA11D1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129732",
    "name": "Reliance Fixed Horizon Fund- XXVI- Series 25",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 25- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-21",
    "closeData": "",
    "isin": "INF204KA19C6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129733",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF204KA1PL1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129734",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H- Direct Plan- Dividend payout option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF204KA1PM9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129735",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF204KA1PJ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129736",
    "name": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund V- Plan H- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF204KA1PK3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129737",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - Plan F -1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V- Plan F - 1100 Days -Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KA1VK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129738",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - Plan F -1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V -Plan F -1100 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KA1VL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129739",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - Plan F -1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V- Plan F- 1100 Days -Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KA1VJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129740",
    "name": "ICICI Prudential Capital Protection Oriented Fund V - Plan F -1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund V- Plan F -1100 Days Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-19",
    "closeData": "",
    "isin": "INF109KA1VM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129741",
    "name": "ICICI Prudential Multiple Yield Fund- Series 6 -1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF109KA1UZ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129742",
    "name": "ICICI Prudential Multiple Yield Fund- Series 6 -1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF109KA1UX2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129743",
    "name": "ICICI Prudential Multiple Yield Fund- Series 6 -1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF109KA1UY0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129744",
    "name": "ICICI Prudential Multiple Yield Fund- Series 6 -1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 6 - 1100 Days Plan F Direct Plan- Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-15",
    "closeData": "",
    "isin": "INF109KA1VA8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129765",
    "name": "Kotak FMP Series 162 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 162 (370 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF174K01R48",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129766",
    "name": "Kotak FMP Series 162 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 162 (370 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF174K01R30",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129767",
    "name": "Kotak FMP Series 162 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 162 (370 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF174K01YK2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "129768",
    "name": "Kotak FMP Series 162 (370 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 162 (370 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-05-12",
    "closeData": "",
    "isin": "INF174K01R55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129797",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 1092 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF109KA1VB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129798",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 1092 Days Plan P Direct Plan-Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF109KA1VD2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129799",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 1092 Days Plan P Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF109KA1VE0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129800",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-1092 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 1092 Days Plan P - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-20",
    "closeData": "",
    "isin": "INF109KA1VC4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129837",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 26- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF204KA1PP2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129838",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 26- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF204KA1PQ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129839",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund- XXVI- Series 26- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF204KA1PN7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "129840",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 26",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 26- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF204KA1PO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129856",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 74 - 367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 367 Days Plan N Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF109KB1FT0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129853",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 74 - 367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -367 Days Plan N Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF109KB1FR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129854",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 74 - 367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 367 Days Plan N Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF109KB1FS2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129855",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 74 - 367 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 367 Days Plan N  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-22",
    "closeData": "",
    "isin": "INF109KB1FU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129857",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN A - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI -1825 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-23",
    "closeData": "",
    "isin": "INF109KA1VV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129858",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN A - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-23",
    "closeData": "",
    "isin": "INF109KA1VW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129859",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN A - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan A Direct Plan Cumulative option",
    "minAmount": "5000",
    "launchDate": "2014-05-23",
    "closeData": "",
    "isin": "INF109KA1VX0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129860",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN A - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-23",
    "closeData": "",
    "isin": "INF109KA1VY8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129916",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-26",
    "closeData": "",
    "isin": "INF109KB1GR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129913",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan L - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-26",
    "closeData": "",
    "isin": "INF109KB1GS0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129914",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74- 369 Days Plan L Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-26",
    "closeData": "",
    "isin": "INF109KB1GQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129915",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-369 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan L Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-26",
    "closeData": "",
    "isin": "INF109KB1GP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129967",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 370 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan Q Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-30",
    "closeData": "",
    "isin": "INF109KB1GJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129968",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 370 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 -370 Days Plan Q Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-05-30",
    "closeData": "",
    "isin": "INF109KB1GH3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129969",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 370 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan Q Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-30",
    "closeData": "",
    "isin": "INF109KB1GK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129970",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 370 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan Q Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-05-30",
    "closeData": "",
    "isin": "INF109KB1GI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "129999",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan R Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB1GU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130000",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan R Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB1GT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130001",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 74-368 Days Plan R Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB1GW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130002",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74- 368 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -368 Days Plan R Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB1GV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130003",
    "name": "ICICI Prudential Growth Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund- Series 1 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB12R8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130004",
    "name": "ICICI Prudential Growth Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund- Series 1 Dividend",
    "minAmount": "5000",
    "launchDate": "2014-06-02",
    "closeData": "",
    "isin": "INF109KB13R6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130005",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan S  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF109KB1HV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130006",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan S Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF109KB1HW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130007",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan S Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF109KB1HY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130008",
    "name": "ICICI Prudential Fixed Maturity Plan-Series 74-370 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan S Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF109KB1HX8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130009",
    "name": "Reliance Fixed Horizon Fund-XXVI-Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund-XXVI-Series 28-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-05",
    "closeData": "",
    "isin": "INF204KA1PV0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130010",
    "name": "Reliance Fixed Horizon Fund-XXVI-Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund-XXVI-Series 28-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-05",
    "closeData": "",
    "isin": "INF204KA1PX6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130011",
    "name": "Reliance Fixed Horizon Fund-XXVI-Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund-XXVI-Series 28-Direct Plan Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130012",
    "name": "Reliance Fixed Horizon Fund-XXVI-Series 28",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund-XXVI-Series 28-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-05",
    "closeData": "",
    "isin": "INF204KA1PW8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130037",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1PZ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130050",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QF1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130051",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QA2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130052",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Direct Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QG9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130053",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QB0",
    "isinRe": "INF204KA1QD6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148286",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 2 - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB16R8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148287",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB14R3",
    "isinRe": "INF204KB15R0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148288",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 2 - Direct Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB15Q2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148289",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB18Q6",
    "isinRe": "INF204KB19Q4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148290",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND -  SEGREGATED PORTFOLIO 2 - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB12R7",
    "isinRe": "INF204KB13R5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148082",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - SEGREGATED PORTFOLIO 1 - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB10N0",
    "isinRe": "INF204KB11N8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148083",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 1 - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB14N2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148084",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB12N6",
    "isinRe": "INF204KB13N4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148283",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 2 - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB11R9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148284",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND -  SEGREGATED PORTFOLIO 2 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB16Q0",
    "isinRe": "INF204KB17Q8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148285",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 2 - Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB10R1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148077",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 1 - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB19M3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148078",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 1 - Direct Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB13M6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148079",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB14M4",
    "isinRe": "INF204KB15M1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148080",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Nippon India Strategic Debt Fund - Segregated Portfolio 1 - Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB18M5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148081",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KB16M9",
    "isinRe": "INF204KB17M7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130054",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QH7",
    "isinRe": "INF204KA1QJ3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130055",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QI5",
    "isinRe": "INF204KA1QK1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130056",
    "name": "Nippon India Strategic Debt Fund (Number of Segregated Portfolios - 2)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "NIPPON INDIA STRATEGIC DEBT FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-06",
    "closeData": "",
    "isin": "INF204KA1QC8",
    "isinRe": "INF204KA1QE4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130061",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 9 Years Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -9 Years Plan U IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WN9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130062",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 9 Years Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 9 Years Plan U Direct Plan IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130063",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 9 Years Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -9 Years Plan U Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WM1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130064",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 9 Years Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 -9 Years Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WO7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130085",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 -369 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KB1GL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130086",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KB1GM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130087",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KB1GO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130088",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -Series 74 -369 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KB1GN1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130145",
    "name": "ICICI Prudential Multiple Yield Fund- Series 7 -1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130146",
    "name": "ICICI Prudential Multiple Yield Fund- Series 7 -1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130147",
    "name": "ICICI Prudential Multiple Yield Fund- Series 7 -1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130148",
    "name": "ICICI Prudential Multiple Yield Fund- Series 7 -1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-09",
    "closeData": "",
    "isin": "INF109KA1WV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130177",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan V Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KB1IJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130178",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KB1IH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130179",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KB1II7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130180",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 74 -370 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan V Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KB1IK3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130181",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI -1100 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KA1XD8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130182",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1100 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KA1XG1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130183",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1100 Days Plan B Direct Plan Cumulative option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KA1XF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130184",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1100 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF109KA1XE6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130189",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 29 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QL9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130190",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 29 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QN5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130191",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 29 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QM7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130192",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 29",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 29 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QO3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130193",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 30 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QP0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130194",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 30 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QR6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130195",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 30 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QQ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130196",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 30",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 30 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF204KA1QS4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "130205",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund- Regular Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01EF3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "130206",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01EA4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "130207",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01EG1",
    "isinRe": "INF754K01EH9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "130208",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund- Regular Plan -Bonus Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01EJ5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "130209",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01EB2",
    "isinRe": "INF754K01EC0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141605",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund - Monthly  Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01HT7",
    "isinRe": "INF754K01HU5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141606",
    "name": "Edelweiss Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Edelweiss Arbitrage Fund - Monthly  Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-06-12",
    "closeData": "",
    "isin": "INF754K01HW1",
    "isinRe": "INF754K01HX9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130219",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF109KA1XH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130220",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI -1825 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF109KA1XJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130221",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan C Direct Plan Dividend option",
    "minAmount": "5000",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF109KA1XK3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130222",
    "name": "ICICI Prudential Capital Protection Oriented Fund VI - PLAN C - 1825 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund Series VI- 1825 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF109KA1XI7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130227",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 31 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF204KA1QT2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130228",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 31 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF204KA1QV8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130229",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 31 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF204KA1QW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130230",
    "name": "Reliance Fixed Horizon Fund - XXVI - Series 31",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVI - Series 31 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-17",
    "closeData": "",
    "isin": "INF204KA1QU0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130264",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 32- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-20",
    "closeData": "",
    "isin": "INF204KA1QX4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130265",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 32- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-20",
    "closeData": "",
    "isin": "INF204KA1QY2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130266",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 32- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-20",
    "closeData": "",
    "isin": "INF204KA1QZ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130267",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 32",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 32- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-20",
    "closeData": "",
    "isin": "INF204KA1RA0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130311",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PK3",
    "isinRe": "INF846K01PL1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130312",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PM9",
    "isinRe": "INF846K01PN7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130313",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PR8",
    "isinRe": "INF846K01PS6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130314",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PJ5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130309",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PO5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130310",
    "name": "Axis Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Axis Credit Risk Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-06-25",
    "closeData": "2014-07-09",
    "isin": "INF846K01PP2",
    "isinRe": "INF846K01PQ0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130315",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-25",
    "closeData": "",
    "isin": "INF109KB1JL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130316",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-25",
    "closeData": "",
    "isin": "INF109KB1JJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130317",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-25",
    "closeData": "",
    "isin": "INF109KB1JM7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130318",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 370 Days Plan X Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-25",
    "closeData": "",
    "isin": "INF109KB1JK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130329",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-30",
    "closeData": "",
    "isin": "INF109KB1JC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130330",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-30",
    "closeData": "",
    "isin": "INF109KB1JB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130331",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 368 Days Plan Y Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-30",
    "closeData": "",
    "isin": "INF109KB1JD6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130333",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-27",
    "closeData": "",
    "isin": "INF109KA1XX6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130334",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-27",
    "closeData": "",
    "isin": "INF109KA1XW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130335",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-06-27",
    "closeData": "",
    "isin": "INF109KA1XY4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130336",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI -1100 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-06-27",
    "closeData": "",
    "isin": "INF109KA1XV0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130404",
    "name": "ICICI Prudential Fixed Maturity Plan - Series - 74 - 367 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series - 74 -367 days Plan Z Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF109KB1JF1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130405",
    "name": "ICICI Prudential Fixed Maturity Plan - Series - 74 - 367 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series -74 - 367 Days Plan Z Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF109KB1JG9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130402",
    "name": "ICICI Prudential Fixed Maturity Plan - Series - 74 - 367 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 74 - 367 Days Plan Z Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF109KB1JH7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130403",
    "name": "ICICI Prudential Fixed Maturity Plan - Series - 74 - 367 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series -74 - 367 Days Plan Z Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF109KB1JI5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130413",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 33- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF204KA1RB8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130414",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI Series- 33- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF204KA1RC6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130415",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 33- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF204KA1RD4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130416",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 33",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 33- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-03",
    "closeData": "",
    "isin": "INF204KA1RE2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130438",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-07",
    "closeData": "",
    "isin": "INF109KA1YN5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130439",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-07",
    "closeData": "",
    "isin": "INF109KA1YO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130440",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-07",
    "closeData": "",
    "isin": "INF109KA1YP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130441",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-07",
    "closeData": "",
    "isin": "INF109KA1YQ8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "130490",
    "name": "DSP Global Allocation Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Allocation Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF740K01Z35",
    "isinRe": "INF740K01Z43"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "130491",
    "name": "DSP Global Allocation Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Allocation Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF740K01Z68",
    "isinRe": "INF740K01Z76"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "130492",
    "name": "DSP Global Allocation Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Allocation Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF740K01Z27",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "130493",
    "name": "DSP Global Allocation Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Allocation Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF740K01Z50",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130496",
    "name": "HDFC Large and Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HDFC Large and Mid Cap Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "1994-01-06",
    "closeData": "1994-01-08",
    "isin": "INF179KA1RT1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130497",
    "name": "HDFC Large and Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HDFC Large and Mid Cap Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "1994-01-06",
    "closeData": "1994-01-08",
    "isin": "INF179KA1RU9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130498",
    "name": "HDFC Large and Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HDFC Large and Mid Cap Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1994-01-06",
    "closeData": "1994-01-08",
    "isin": "INF179KA1RQ7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130499",
    "name": "HDFC Large and Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HDFC Large and Mid Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "1994-01-06",
    "closeData": "1994-01-08",
    "isin": "INF179KA1RR5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130501",
    "name": "HDFC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HDFC Small Cap Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-02-11",
    "closeData": "",
    "isin": "INF179KA1SA9",
    "isinRe": "INF179KA1SB7"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130502",
    "name": "HDFC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HDFC Small Cap Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-02-11",
    "closeData": "",
    "isin": "INF179KA1RZ8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130503",
    "name": "HDFC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HDFC Small Cap Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2008-02-11",
    "closeData": "",
    "isin": "INF179KA1RW5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130504",
    "name": "HDFC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HDFC Small Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2008-02-11",
    "closeData": "",
    "isin": "INF179KA1RX3",
    "isinRe": "INF179KA1RY1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130533",
    "name": "HDFC Dynamic PE Ratio Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Dynamic PE Ratio Fund of Funds - Growth Option",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "2012-01-31",
    "isin": "INF179KA1SF8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130540",
    "name": "HDFC Dynamic PE Ratio Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Dynamic PE Ratio Fund of Funds - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "2012-01-31",
    "isin": "INF179KA1SG6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130543",
    "name": "HDFC Dynamic PE Ratio Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Dynamic PE Ratio Fund of Funds - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "2012-01-31",
    "isin": "INF179KA1SC5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "130547",
    "name": "HDFC Dynamic PE Ratio Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Dynamic PE Ratio Fund of Funds - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2012-01-17",
    "closeData": "2012-01-31",
    "isin": "INF179KA1SD3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130593",
    "name": "ICICI Prudential Growth Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 2 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-07-14",
    "closeData": "",
    "isin": "INF109KC1796",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130594",
    "name": "ICICI Prudential Growth Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 2 Dividend",
    "minAmount": "5000",
    "launchDate": "2014-07-14",
    "closeData": "",
    "isin": "INF109KC1804",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130629",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2014-07-15",
    "closeData": "",
    "isin": "INF204KA1RQ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130630",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund- VI- Plan A- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2014-07-15",
    "closeData": "",
    "isin": "INF204KA1RR4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130631",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2014-07-15",
    "closeData": "",
    "isin": "INF204KA1RO1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130632",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund- VI- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2014-07-15",
    "closeData": "",
    "isin": "INF204KA1RP8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130633",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI-  Series 35- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF204KA1RM5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130634",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 35- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF204KA1RN3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130635",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 35- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF204KA1RJ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130636",
    "name": "Reliance Fixed Horizon Fund XXVI- Series 35",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVI- Series 35- Dividend payout option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF204KA1RK9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130641",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF109KA1ZB7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130642",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF109KA1YZ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130643",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-16",
    "closeData": "",
    "isin": "INF109KA1ZA9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130650",
    "name": "Reliance Capital Builder Fund- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series A- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-17",
    "closeData": "",
    "isin": "INF204KA1RW4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130651",
    "name": "Reliance Capital Builder Fund- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series A- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-17",
    "closeData": "",
    "isin": "INF204KA1RX2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130652",
    "name": "Reliance Capital Builder Fund- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series A- Direct Plan- Dividend Payout Opotion",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-17",
    "closeData": "",
    "isin": "INF204KA1RY0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130653",
    "name": "Reliance Capital Builder Fund- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series A- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-07-17",
    "closeData": "",
    "isin": "INF204KA1RV6",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130722",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01I83",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130723",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Regular Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01I42",
    "isinRe": "INF205K01I67"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130724",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01I91",
    "isinRe": "INF205K01J17"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130725",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Direct Plan - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01J09",
    "isinRe": "INF205K01J25"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130726",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Regular Plan - Discretionary IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01I59",
    "isinRe": "INF205K01I75"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "130721",
    "name": "Invesco India Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Invesco India Credit Risk Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- per application and in multiples of Re",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF205K01I34",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130771",
    "name": "Axis Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Axis Arbitrage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-07-25",
    "closeData": "2014-08-08",
    "isin": "INF846K01QC8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130773",
    "name": "Axis Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Axis Arbitrage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-07-25",
    "closeData": "2014-08-08",
    "isin": "INF846K01PZ1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130774",
    "name": "Axis Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Axis Arbitrage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-07-25",
    "closeData": "2014-08-08",
    "isin": "INF846K01QA2",
    "isinRe": "INF846K01QB0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "130776",
    "name": "Axis Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Axis Arbitrage Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2014-07-25",
    "closeData": "2014-08-08",
    "isin": "INF846K01QD6",
    "isinRe": "INF846K01QE4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130777",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-28",
    "closeData": "",
    "isin": "INF109KA1ZS1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130778",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-28",
    "closeData": "",
    "isin": "INF109KA1ZT9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130779",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-07-28",
    "closeData": "",
    "isin": "INF109KA1ZQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130780",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1352 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-07-28",
    "closeData": "",
    "isin": "INF109KA1ZR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130803",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF109KA1ZW3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130804",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF109KA1ZX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130805",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF109KA1ZV5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130806",
    "name": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Corporate Bond Series B - 1100 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-01",
    "closeData": "",
    "isin": "INF109KA1ZU7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130834",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-06",
    "closeData": "",
    "isin": "INF109KA1A82",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130835",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-06",
    "closeData": "",
    "isin": "INF109KA1A90",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130836",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-06",
    "closeData": "",
    "isin": "INF109KA1A74",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130837",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-06",
    "closeData": "",
    "isin": "INF109KA1A66",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130858",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Japan Equity Fund- Growth Plan- Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SJ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130859",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA - JAPAN EQUITY FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SL5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130860",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Japan Equity Fund- Direct Plan- Growth Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SI1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130861",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Japan Equity Fund- Direct Plan- Growth Plan- Bonus Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SK7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130863",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Japan Equity Fund- Growth Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SH3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130864",
    "name": "Nippon India Japan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA - JAPAN EQUITY FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-08-08",
    "closeData": "",
    "isin": "INF204KA1SM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130872",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF109KA1B08",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130873",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF109KA1B16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130870",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF109KA1B24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130871",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan G Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF109KA1B32",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130874",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SP6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130875",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 3- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SR2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130876",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 3- Dividend Payout option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SQ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130877",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 3- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SS0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130878",
    "name": "Reliance Capital Builder Fund- Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series B- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1ST8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130879",
    "name": "Reliance Capital Builder Fund- Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series B- Direct Plan- Growth Opiton",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130880",
    "name": "Reliance Capital Builder Fund- Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series B- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SW2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130881",
    "name": "Reliance Capital Builder Fund- Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series B- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-08-12",
    "closeData": "",
    "isin": "INF204KA1SU6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130882",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF204KA1SX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130883",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF204KA1SY8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130884",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF204KA1SZ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "130885",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan B- Direct Plan- DIvidend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF204KA1TA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130985",
    "name": "ICICI Prudential Value Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 5 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1J18",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130986",
    "name": "ICICI Prudential Value Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 5 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1J34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130987",
    "name": "ICICI Prudential Value Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 5  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1J00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130988",
    "name": "ICICI Prudential Value Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 5 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1J26",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130989",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-19",
    "closeData": "",
    "isin": "INF109KA1K15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130990",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-19",
    "closeData": "",
    "isin": "INF109KA1J83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130991",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-19",
    "closeData": "",
    "isin": "INF109KA1K07",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "130992",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-19",
    "closeData": "",
    "isin": "INF109KA1J91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131006",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1K98",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131007",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1K80",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131008",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1K64",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131009",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-22",
    "closeData": "",
    "isin": "INF109KA1K72",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131014",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF109KA1K49",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131016",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF109KA1K23",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131017",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-08-14",
    "closeData": "",
    "isin": "INF109KA1K31",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131018",
    "name": "UTI - Focussed Equity Fund - Series I ( 2195 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund Series - 1 (2195 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF789FC1Q48",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131019",
    "name": "UTI - Focussed Equity Fund - Series I ( 2195 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund Series - 1 (2195 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF789FC1Q63",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131020",
    "name": "UTI - Focussed Equity Fund - Series I ( 2195 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund Series - 1 (2195 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF789FC1Q30",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "131021",
    "name": "UTI - Focussed Equity Fund - Series I ( 2195 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund Series - 1 (2195 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2014-08-13",
    "closeData": "",
    "isin": "INF789FC1Q55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131051",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1N46",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131059",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1N87",
    "isinRe": "INF109KA1O29"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131061",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1O37",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131062",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1N53",
    "isinRe": "INF109KA1N95"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131053",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1O45",
    "isinRe": "INF109KA1O86"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131054",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1O52",
    "isinRe": "INF109KA1O94"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131055",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1O60",
    "isinRe": "INF109KA1P02"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131056",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Direct Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1O78",
    "isinRe": "INF109KA1P10"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131057",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1N61",
    "isinRe": "INF109KA1O03"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131058",
    "name": "ICICI Prudential Constant Maturity Gilt Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "ICICI Prudential Constant Maturity Gilt Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF109KA1N79",
    "isinRe": "INF109KA1O11"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131076",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-08-28",
    "closeData": "",
    "isin": "INF204KA1TF5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131077",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-08-28",
    "closeData": "",
    "isin": "INF204KA1TG3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131078",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 4- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-08-28",
    "closeData": "",
    "isin": "INF204KA1TH1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131079",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 4- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-08-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131096",
    "name": "Kotak FMP Series 163 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 163 (1100 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF174K01ZZ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131097",
    "name": "Kotak FMP Series 163 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 163 (1100 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF174K01A13",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131098",
    "name": "Kotak FMP Series 163 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 163 (1100 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": "INF174K01A05",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131099",
    "name": "Kotak FMP Series 163 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 163 (1100 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-08-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-03",
    "closeData": "",
    "isin": "INF109KA1P93",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-03",
    "closeData": "",
    "isin": "INF109KA1P77",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-03",
    "closeData": "",
    "isin": "INF109KA1P85",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-03",
    "closeData": "",
    "isin": "INF109KA1P69",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131167",
    "name": "Reliance Fixed Horizon Fund - XXVII - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVII - Series 5 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF204KA1TT6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131168",
    "name": "Reliance Fixed Horizon Fund - XXVII - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVII - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF204KA1TV2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131169",
    "name": "Reliance Fixed Horizon Fund - XXVII - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVII - Series 5 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF204KA1TU4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131170",
    "name": "Reliance Fixed Horizon Fund - XXVII - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund - XXVII - Series 5 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131175",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF109KA1Q27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131176",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF109KA1Q01",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131177",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF109KA1Q35",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131178",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VI - 1100 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-05",
    "closeData": "",
    "isin": "INF109KA1Q19",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131195",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-09",
    "closeData": "",
    "isin": "INF109KA1R26",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131196",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-09",
    "closeData": "",
    "isin": "INF109KA1R34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131197",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-09",
    "closeData": "",
    "isin": "INF109KA1R42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131198",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-09",
    "closeData": "",
    "isin": "INF109KA1R59",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131215",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131216",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 6- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-10",
    "closeData": "",
    "isin": "INF204KA1TZ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131217",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 6- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131218",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2014-09-10",
    "closeData": "",
    "isin": "INF204KA1TX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131261",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF109KA1R91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131262",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF109KA1R75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131263",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF109KA1R83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131264",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1338 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF109KA1R67",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131269",
    "name": "Reliance Capital Builder Fund- Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series C- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF204KA1UC0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131270",
    "name": "Reliance Capital Builder Fund- Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series C- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF204KA1UB2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131271",
    "name": "Reliance Capital Builder Fund- Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series C- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF204KA1UD8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131272",
    "name": "Reliance Capital Builder Fund- Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund- Series C- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF204KA1UE6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131297",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K012I0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131298",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K013I8",
    "isinRe": "INF740K014I6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131299",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K015I3",
    "isinRe": "INF740K016I1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131300",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Regular Plan - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K017I9",
    "isinRe": "INF740K018I7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131301",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K019I5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131302",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Direct Plan - IDCW - Quarterly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K014J4",
    "isinRe": "INF740K015J1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131303",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K012J8",
    "isinRe": "INF740K013J6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "131304",
    "name": "DSP 10Y G-Sec Fund",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "DSP 10Y G-Sec Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2014-09-16",
    "closeData": "",
    "isin": "INF740K010J2",
    "isinRe": "INF740K011J0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131305",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF204KA1UF3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131306",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF204KA1UG1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131307",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C- Direct Plan Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF204KA1UI7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131308",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan C- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-12",
    "closeData": "",
    "isin": "INF204KA1UH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131309",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1T73",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131310",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1T65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131311",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1T40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131312",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1T57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131313",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S58",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131314",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131315",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131316",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days - Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1825 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S41",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131317",
    "name": "ICICI Prudential Growth Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 3 Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S17",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131318",
    "name": "ICICI Prudential Growth Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 3 IDCW",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF109KA1S09",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131331",
    "name": "Nippon India ETF S&P BSE Sensex",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF S&P BSE Sensex",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF204KA1UN7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131342",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI Plan D- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UO5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131343",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UQ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131344",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UP2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131345",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan D- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UR8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131346",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1US6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131347",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UT4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131348",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 7- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": "INF204KA1UU2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131349",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 7- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-09-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131350",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF109KA1U13",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131351",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF109KA1T99",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131352",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF109KA1T81",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131353",
    "name": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days - Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 7 - 1100 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF109KA1U05",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131354",
    "name": "BANDHAN Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANDHAN Balanced Advantage Fund Regular Plan IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF194KA1UF5",
    "isinRe": "INF194KA1UG3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131355",
    "name": "BANDHAN Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANDHAN Balanced Advantage Fund Direct Plan Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF194KA1UH1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131356",
    "name": "BANDHAN Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANDHAN Balanced Advantage Fund Direct Plan IDCW",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF194KA1UI9",
    "isinRe": "INF194KA1UJ7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "131357",
    "name": "BANDHAN Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "BANDHAN Balanced Advantage Fund Regular Plan Growth",
    "minAmount": "Rs.1000/- and any amount thereafter",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF194KA1UE8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131372",
    "name": "Kotak Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Kotak Equity Savings Fund - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF174K01C78",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131373",
    "name": "Kotak Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Kotak Equity Savings Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF174K01D28",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131374",
    "name": "Kotak Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Kotak Equity Savings Fund - Regular - Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF174K01C86",
    "isinRe": "INF174K01D02"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "131375",
    "name": "Kotak Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Kotak Equity Savings Fund - Direct - Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2014-09-17",
    "closeData": "",
    "isin": "INF174K01D36",
    "isinRe": "INF174K01D51"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131378",
    "name": "Reliance Interval Fund III- Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund III- Series 1- Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131379",
    "name": "Reliance Interval Fund III- Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund III- Series 1- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF204KA1VD6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131380",
    "name": "Reliance Interval Fund III- Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund III- Series 1- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131381",
    "name": "Reliance Interval Fund III- Series 1",
    "category": "Income",
    "navName": "Reliance Interval Fund III- Series 1- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2014-09-23",
    "closeData": "",
    "isin": "INF204KA1VB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131457",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF109KA1W52",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131454",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF109KA1W45",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131455",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF109KA1W29",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131456",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF109KA1W37",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131458",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-01",
    "closeData": "",
    "isin": "INF109KA1W11",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131459",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-01",
    "closeData": "",
    "isin": "INF109KA1V95",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131460",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-01",
    "closeData": "",
    "isin": "INF109KA1W03",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131461",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan L Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-01",
    "closeData": "",
    "isin": "INF109KA1V87",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131462",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF204KA1VS4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131463",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF204KA1VT2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131464",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF204KA1VU0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131465",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan E- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-10-07",
    "closeData": "",
    "isin": "INF204KA1VV8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131537",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 8- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000 and Rs. 1 thereafter",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": "INF204KA1UU2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131538",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 8- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000 and Rs. 1 thereafter",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": "INF204KA1UV0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131539",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 8- Growth Option",
    "minAmount": "Rs. 5000 and Rs. 1 thereafter",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": "INF204KA1US6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131540",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 8- Dividend Payout Option",
    "minAmount": "Rs. 5000 and Rs. 1 thereafter",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131545",
    "name": "ICICI Prudential Growth Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 4 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": "INF109KA1Y76",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131546",
    "name": "ICICI Prudential Growth Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 4 Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-13",
    "closeData": "",
    "isin": "INF109KA1Y68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131574",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA16B5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131575",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA17B3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131576",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA19B9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131577",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1824 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA18B1",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "131580",
    "name": "360 ONE Focused Equity Fund (Formerly known as IIFL Focused Equity Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "360 ONE Focused Equity Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2014-10-08",
    "closeData": "",
    "isin": "INF579M01902",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "131581",
    "name": "360 ONE Focused Equity Fund (Formerly known as IIFL Focused Equity Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "360 ONE Focused Equity Fund - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-08",
    "closeData": "",
    "isin": "INF579M01910",
    "isinRe": "INF579M01928"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "131578",
    "name": "360 ONE Focused Equity Fund (Formerly known as IIFL Focused Equity Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "360 ONE Focused Equity Fund -Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-10-08",
    "closeData": "",
    "isin": "INF579M01878",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "131579",
    "name": "360 ONE Focused Equity Fund (Formerly known as IIFL Focused Equity Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "360 ONE Focused Equity Fund - Regular Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-08",
    "closeData": "",
    "isin": "INF579M01886",
    "isinRe": "INF579M01894"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131590",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 9- Growth option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-17",
    "closeData": "",
    "isin": "INF204KA1WG7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131591",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 9- Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-17",
    "closeData": "",
    "isin": "INF204KA1WH5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131592",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 9- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-17",
    "closeData": "",
    "isin": "INF204KA1WJ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "131593",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 9- Direct Plan-Growth option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-17",
    "closeData": "",
    "isin": "INF204KA1WI3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131600",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-21",
    "closeData": "",
    "isin": "INF109KA19A1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131601",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-21",
    "closeData": "",
    "isin": "INF109KA18A3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131599",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan N Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-21",
    "closeData": "",
    "isin": "INF109KA10B8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131602",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA14B0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131603",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA12B4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "131604",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan O Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-20",
    "closeData": "",
    "isin": "INF109KA13B2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131652",
    "name": "ING Liquid Fund",
    "category": "Liquid",
    "navName": "ING Vysya Liquid Fund-Auto Sweep Growth Option",
    "minAmount": "Retail 5000 Insti 10000000Super insti 150000000",
    "launchDate": "1999-12-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131665",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Aditya Birla Sun Life Balanced Advantage Fund -REGULAR - IDCW",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": "INF084M01AC6",
    "isinRe": "INF084M01AD4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131666",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Aditya Birla Sun Life Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": "INF084M01AB8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131667",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ING Vysya Balanced Fund-Quarterly Dividend Reinvest.",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131668",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ING Vysya Balanced Fund-Institutional Weekly Dividend",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131669",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ING Vysya Balanced Fund-Institutional Daily Dividend",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131670",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Aditya Birla Sun Life Balanced Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": "INF084M01DJ5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131671",
    "name": "Aditya Birla Sun Life Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Aditya Birla Sun Life Balanced Advantage Fund -DIRECT - IDCW",
    "minAmount": "1000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": "INF084M01DK3",
    "isinRe": "INF084M01DL1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131710",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Monthly Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131711",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Quarterly Dividend Reinvest",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131712",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Half yearly Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131713",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Annual Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131720",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Monthly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131721",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Half yearly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131722",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Annual Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131724",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Quarterly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131732",
    "name": "ING Large Cap Equity Fund",
    "category": "Growth",
    "navName": "ING Vysya Nifty Plus Fund-Dividend Reinvestment Plan",
    "minAmount": "5000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131864",
    "name": "Aditya Birla Sun Life Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Asset Allocator FoF- Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-07-09",
    "closeData": "",
    "isin": "INF084M01AE2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131865",
    "name": "Aditya Birla Sun Life Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Asset Allocator FoF- Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-07-09",
    "closeData": "",
    "isin": "INF084M01DM9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131866",
    "name": "Aditya Birla Sun Life Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Asset Allocator FoF-DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-07-09",
    "closeData": "",
    "isin": "INF084M01DN7",
    "isinRe": "INF084M01DO5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131863",
    "name": "Aditya Birla Sun Life Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Asset Allocator FoF-REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-07-09",
    "closeData": "",
    "isin": "INF084M01AF9",
    "isinRe": "INF084M01AG7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131895",
    "name": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme - REGULAR - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-12-08",
    "closeData": "2006-12-15",
    "isin": "INF084M01AI3",
    "isinRe": "INF084M01AJ1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131896",
    "name": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-12-08",
    "closeData": "2006-12-15",
    "isin": "INF084M01AH5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131897",
    "name": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme - DIRECT - IDCW",
    "minAmount": "5000",
    "launchDate": "2006-12-08",
    "closeData": "2006-12-15",
    "isin": "INF084M01DQ0",
    "isinRe": "INF084M01DR8"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "131898",
    "name": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Active Debt Multi Manager FoF Scheme - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-12-08",
    "closeData": "2006-12-15",
    "isin": "INF084M01DP2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132003",
    "name": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund-REGULAR - RETAIL - IDCW",
    "minAmount": "5000",
    "launchDate": "2007-11-22",
    "closeData": "2007-12-07",
    "isin": "INF084M01BA8",
    "isinRe": "INF084M01BB6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132005",
    "name": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund- Retail Plan - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-11-22",
    "closeData": "2007-12-07",
    "isin": "INF084M01AZ7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132009",
    "name": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund - Retail Plan - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-11-22",
    "closeData": "2007-12-07",
    "isin": "INF084M01EH7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132010",
    "name": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Excellence Equity Fund Of Fund-DIRECT - RETAIL - IDCW",
    "minAmount": "5000",
    "launchDate": "2007-11-22",
    "closeData": "2007-12-07",
    "isin": "INF084M01EI5",
    "isinRe": "INF084M01EJ3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132138",
    "name": "Aditya Birla Sun Life Global Emerging Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla SUn Life Global Emerging Opportunities Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-07-29",
    "closeData": "",
    "isin": "INF084M01AT0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132139",
    "name": "Aditya Birla Sun Life Global Emerging Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Emerging Opportunities Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-29",
    "closeData": "",
    "isin": "INF084M01AU8",
    "isinRe": "INF084M01AV6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132140",
    "name": "Aditya Birla Sun Life Global Emerging Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Emerging Opportunities Fund - Direct plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-07-29",
    "closeData": "",
    "isin": "INF084M01EB0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132141",
    "name": "Aditya Birla Sun Life Global Emerging Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Global Emerging Opportunities Fund - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2008-07-29",
    "closeData": "",
    "isin": "INF084M01EC8",
    "isinRe": "INF084M01ED6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132218",
    "name": "ING Liquid Fund",
    "category": "Liquid",
    "navName": "ING Vysya Liquid Fund-Auto Sweep Growth Option",
    "minAmount": "Retail 5000 Insti 10000000Super insti 150000000",
    "launchDate": "1999-12-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132233",
    "name": "ING Balanced Fund",
    "category": "Balanced",
    "navName": "ING Vysya Balanced Fund-Quarterly Dividend Reinvest.",
    "minAmount": "5000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132234",
    "name": "ING Balanced Fund",
    "category": "Balanced",
    "navName": "ING Vysya Balanced Fund-Institutional Weekly Dividend",
    "minAmount": "5000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132235",
    "name": "ING Balanced Fund",
    "category": "Balanced",
    "navName": "ING Vysya Balanced Fund-Institutional Daily Dividend",
    "minAmount": "5000/-",
    "launchDate": "2000-03-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132276",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Monthly Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132277",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Quarterly Dividend Reinvest",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132278",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Half yearly Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132279",
    "name": "ING Dynamic Duration Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan A-Annual Dividend Reinvest.",
    "minAmount": "1000",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132286",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Monthly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132287",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Half yearly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132288",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Annual Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132290",
    "name": "ING MIP Fund",
    "category": "Income",
    "navName": "ING Vysya MIP Fund Plan B-Quarterly Dividend Reinvest.",
    "minAmount": "Growth op 10000/- Div op 20000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132298",
    "name": "ING Large Cap Equity Fund",
    "category": "Growth",
    "navName": "ING Vysya Nifty Plus Fund-Dividend Reinvestment Plan",
    "minAmount": "5000/-",
    "launchDate": "2004-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132762",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F- Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF204KA1WK9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132763",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F- Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF204KA1WM5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132764",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF204KA1WN3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132765",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VI- Plan F- Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples of Rs. 1 thereafter",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF204KA1WL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132830",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA18D7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132831",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA16D1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132832",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA17D9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132833",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1103 Days Plan P Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA19D5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132834",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA12E8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132835",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA13E6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132836",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA11E0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132837",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan Q Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-10-30",
    "closeData": "",
    "isin": "INF109KA10E2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132859",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF204KA1WW4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132860",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 11- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF204KA1WY0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132861",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 11- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF204KA1WZ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "132862",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 11- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132875",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-05",
    "closeData": "",
    "isin": "INF109KA16E9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132876",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-05",
    "closeData": "",
    "isin": "INF109KA17E7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132877",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-05",
    "closeData": "",
    "isin": "INF109KA14E4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132878",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-05",
    "closeData": "",
    "isin": "INF109KA15E1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "132887",
    "name": "Kotak FMP Series 169 (1097 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 169 (1097 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF174K01E50",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "132888",
    "name": "Kotak FMP Series 169 (1097 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 169 (1097 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF174K01E84",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "132889",
    "name": "Kotak FMP Series 169 (1097 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 169 (1097 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF174K01E76",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "132890",
    "name": "Kotak FMP Series 169 (1097 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 169 (1097 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-10-27",
    "closeData": "",
    "isin": "INF174K01E68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132900",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF109KA10F9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132901",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF109KA18E5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132902",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan R Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-10",
    "closeData": "",
    "isin": "INF109KA19E3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "132923",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES I",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series I - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-11-01",
    "closeData": "2015-01-31",
    "isin": "INF200K017X1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "132924",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES I",
    "category": "ELSS",
    "navName": "SBI LONG TERM ADVANTAGE FUND - SERIES I - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-11-01",
    "closeData": "2015-01-31",
    "isin": "INF200K016X3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "132933",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES I",
    "category": "ELSS",
    "navName": "SBI LONG TERM ADVANTAGE FUND - SERIES I - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-11-01",
    "closeData": "2015-01-31",
    "isin": "INF200K018X9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "132934",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES I",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series I - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-11-01",
    "closeData": "2015-01-31",
    "isin": "INF200K019X7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132951",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF109KA18F2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132952",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF109KA17F4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132953",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF109KA19F0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "132954",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF109KA16F6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "132987",
    "name": "Franklin India Multi - Asset Solution Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Multi - Asset Solution Fund of Funds- Growth Plan",
    "minAmount": "5000",
    "launchDate": "2014-11-07",
    "closeData": "",
    "isin": "INF090I01LE4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "132988",
    "name": "Franklin India Multi - Asset Solution Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Multi - Asset Solution Fund of Funds- IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-07",
    "closeData": "",
    "isin": "INF090I01LF1",
    "isinRe": "INF090I01LG9"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "132989",
    "name": "Franklin India Multi - Asset Solution Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Multi - Asset Solution Fund of Funds- Growth Plan - Direct",
    "minAmount": "5000",
    "launchDate": "2014-11-07",
    "closeData": "",
    "isin": "INF090I01LH7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "132990",
    "name": "Franklin India Multi - Asset Solution Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Multi - Asset Solution Fund of Funds - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-07",
    "closeData": "",
    "isin": "INF090I01LI5",
    "isinRe": "INF090I01LJ3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132995",
    "name": "Aditya Birla Sun Life Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Aditya Birla Sun Life Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF209KA1TP9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132996",
    "name": "Aditya Birla Sun Life Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Aditya Birla Sun Life Equity Savings Fund - Direct - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF209KA1TQ7",
    "isinRe": "INF209KA1TR5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132997",
    "name": "Aditya Birla Sun Life Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Aditya Birla Sun Life Equity Savings Fund - Regular - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF209KA1TT1",
    "isinRe": "INF209KA1TU9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132998",
    "name": "Aditya Birla Sun Life Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Aditya Birla Sun Life Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2014-11-11",
    "closeData": "",
    "isin": "INF209KA1TS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133003",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-13",
    "closeData": "",
    "isin": "INF109KA15F8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133005",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-13",
    "closeData": "",
    "isin": "INF109KA12F5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133006",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1100 Days Plan T Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-13",
    "closeData": "",
    "isin": "INF109KA14F1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133041",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF109KA14H7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133042",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF109KA15H4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133043",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF109KA13H9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133044",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 75 - 1246 Days Plan U Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF109KA12H1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133045",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF204KA1XX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133046",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF204KA1XV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133047",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF204KA1XU6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133048",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-11-19",
    "closeData": "",
    "isin": "INF204KA1XW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133049",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA12J7",
    "isinRe": "INF109KA15J0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133050",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA15I2",
    "isinRe": "INF109KA18I6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133051",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Cumulative option",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA14I5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133052",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA13J5",
    "isinRe": "INF109KA16J8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133053",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA16I0",
    "isinRe": "INF109KA19I4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133054",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Direct Plan - Cumulative option",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA11J9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133056",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA14J3",
    "isinRe": "INF109KA17J6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133057",
    "name": "ICICI Prudential Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "ICICI Prudential Equity Savings Fund - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2014-11-18",
    "closeData": "",
    "isin": "INF109KA17I8",
    "isinRe": "INF109KA10J1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133110",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1822 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1822 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-28",
    "closeData": "",
    "isin": "INF109KA10K9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133111",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1822 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1822 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-28",
    "closeData": "",
    "isin": "INF109KA11K7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133112",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1822 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1822 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-11-28",
    "closeData": "",
    "isin": "INF109KA18J4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133113",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1822 Days - Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1822 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-11-28",
    "closeData": "",
    "isin": "INF109KA19J2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133122",
    "name": "Kotak Nifty Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty Bank ETF",
    "minAmount": "Rs. 5000/- and in multiples of Rs1000 for purchas",
    "launchDate": "2014-11-24",
    "closeData": "2014-11-28",
    "isin": "INF174K01F59",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133128",
    "name": "ICICI Prudential Growth Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 5 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "",
    "isin": "INF109KA13K3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133129",
    "name": "ICICI Prudential Growth Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 5 Dividend",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "",
    "isin": "INF109KA12K5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133131",
    "name": "Reliance Capital Builder Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II- Series A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples for Rs. 1 thereafter",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF204KA1YL3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133132",
    "name": "Reliance Capital Builder Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II- Series A- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and multiples for Rs. 1 thereafter",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF204KA1YK5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133133",
    "name": "Reliance Capital Builder Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II- Series A- Dividend Payout Option",
    "minAmount": "Rs. 5000 and multiples for Rs. 1 thereafter",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF204KA1YJ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133134",
    "name": "Reliance Capital Builder Fund II- Series A",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II- Series A- Growth Option",
    "minAmount": "Rs. 5000 and multiples for Rs. 1 thereafter",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF204KA1YI9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133172",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1101 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-03",
    "closeData": "",
    "isin": "INF109KA16L4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133173",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1101 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-03",
    "closeData": "",
    "isin": "INF109KA17L2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133174",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 -1101 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-03",
    "closeData": "",
    "isin": "INF109KA18L0",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "133181",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund) - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01AW8",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "133184",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund) - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01BA2",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140801",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01AX6",
    "isinRe": "INF666M01AY4"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140802",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01BB0",
    "isinRe": "INF666M01BC8"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140803",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Direct Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CA0",
    "isinRe": "INF666M01CB8"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140804",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Regular Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CG7",
    "isinRe": "INF666M01CH5"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140805",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund) - Direct Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CE2",
    "isinRe": "INF666M01CF9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140806",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Regular Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CK9",
    "isinRe": "INF666M01CL7"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140807",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund) - Direct Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CC6",
    "isinRe": "INF666M01CD4"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140808",
    "name": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Groww Arbitrage Fund (formerly known as Indiabulls Arbitrage Fund)- Regular Plan- Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2014-12-01",
    "closeData": "2014-12-15",
    "isin": "INF666M01CI3",
    "isinRe": "INF666M01CJ1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133199",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 15- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-05",
    "closeData": "",
    "isin": "INF204KA1YQ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133200",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 15- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-05",
    "closeData": "",
    "isin": "INF204KA1YS8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133201",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 15- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-05",
    "closeData": "",
    "isin": "INF204KA1YR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133202",
    "name": "Reliance Fixed Horizon Fund XXVII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVII- Series 15- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-05",
    "closeData": "",
    "isin": "INF204KA1YT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133216",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-10",
    "closeData": "",
    "isin": "INF109KA10N3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133217",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-10",
    "closeData": "",
    "isin": "INF109KA11N1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133218",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-10",
    "closeData": "",
    "isin": "INF109KA18M8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133219",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-10",
    "closeData": "",
    "isin": "INF109KA19M6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133244",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-15",
    "closeData": "",
    "isin": "INF109KA14N5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133245",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-15",
    "closeData": "",
    "isin": "INF109KA15N2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133246",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-15",
    "closeData": "",
    "isin": "INF109KA12N9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133247",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-15",
    "closeData": "",
    "isin": "INF109KA13N7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133248",
    "name": "Kotak FMP Series 170 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 170 (1099 days) - Regular Plan - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF174K01F75",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133249",
    "name": "Kotak FMP Series 170 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 170 (1099 days) - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF174K01F67",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133250",
    "name": "Kotak FMP Series 170 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 170 (1099 days) - Direct plan - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF174K01F91",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133251",
    "name": "Kotak FMP Series 170 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 170 (1099 days) - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-12-08",
    "closeData": "",
    "isin": "INF174K01F83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133274",
    "name": "ICICI Prudential Growth Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 6  Dividend",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA14O3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133275",
    "name": "ICICI Prudential Growth Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 6 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA15O0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133293",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN D - 1822 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1822 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA10P8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133294",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN D - 1822 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1822 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA13P2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133295",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN D - 1822 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1822 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA12P4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133296",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN D - 1822 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1822 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2014-12-19",
    "closeData": "",
    "isin": "INF109KA11P6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133303",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-12-22",
    "closeData": "",
    "isin": "INF204KA1ZF2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133304",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-12-22",
    "closeData": "",
    "isin": "INF204KA1ZC9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133305",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-12-22",
    "closeData": "",
    "isin": "INF204KA1ZD7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133306",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII - Plan B - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2014-12-22",
    "closeData": "",
    "isin": "INF204KA1ZE5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133307",
    "name": "LIC MF Nifty 8-13 yr G-Sec ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "LIC MF Nifty 8-13 yr G-Sec ETF",
    "minAmount": "Rs.5000 &in multiples of Rs1 thereafter during NFO",
    "launchDate": "2014-12-17",
    "closeData": "2014-12-22",
    "isin": "INF767K01MV5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133308",
    "name": "Reliance Capital Builder Fund II - Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series B - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF204KA1ZG0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133309",
    "name": "Reliance Capital Builder Fund II - Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series B - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF204KA1ZH8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133310",
    "name": "Reliance Capital Builder Fund II - Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series B - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF204KA1ZI6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133311",
    "name": "Reliance Capital Builder Fund II - Series B",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series B - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF204KA1ZJ4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "133323",
    "name": "Sundaram Long Term Tax Advantage Fund Series I (10 years)",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series I Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2014-12-18",
    "closeData": "",
    "isin": "INF903J013A1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "133324",
    "name": "Sundaram Long Term Tax Advantage Fund Series I (10 years)",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series I Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-12-18",
    "closeData": "",
    "isin": "INF903J014A9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "133325",
    "name": "Sundaram Long Term Tax Advantage Fund Series I (10 years)",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series I Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2014-12-18",
    "closeData": "",
    "isin": "INF903J015A6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "133322",
    "name": "Sundaram Long Term Tax Advantage Fund Series I (10 years)",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series I Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2014-12-18",
    "closeData": "",
    "isin": "INF903J012A3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133326",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF204KA1ZO4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133327",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 2- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF204KA1ZQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133328",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 2- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF204KA1ZR7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133329",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 2- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF204KA1ZP1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133361",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI LONG TERM ADVANTAGE FUND - SERIES II - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-12-22",
    "closeData": "2015-03-23",
    "isin": "INF200KA1317",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133362",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series II - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-12-22",
    "closeData": "2015-03-23",
    "isin": "INF200KA1325",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133363",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series II - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-12-22",
    "closeData": "2015-03-23",
    "isin": "INF200KA1341",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133364",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES II",
    "category": "ELSS",
    "navName": "SBI LONG TERM ADVANTAGE FUND - SERIES II - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2014-12-22",
    "closeData": "2015-03-23",
    "isin": "INF200KA1333",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133379",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-01",
    "closeData": "",
    "isin": "INF109KA16P5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133380",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-01",
    "closeData": "",
    "isin": "INF109KA17P3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133381",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-01",
    "closeData": "",
    "isin": "INF109KA15P7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133382",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-01",
    "closeData": "",
    "isin": "INF109KA14P0",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "133383",
    "name": "Motilal Oswal Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Motilal Oswal Long Term Equity Fund Regular - IDCW Payout",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF247L01551",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "133384",
    "name": "Motilal Oswal Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Motilal Oswal Long Term Equity Fund Direct - IDCW Payout",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF247L01577",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "133385",
    "name": "Motilal Oswal Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Motilal Oswal Long Term Equity Fund (MOFLTE) - Regular Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF247L01544",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "133386",
    "name": "Motilal Oswal Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Motilal Oswal Long Term Equity Fund (MOFLTE) - Direct Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2014-12-26",
    "closeData": "",
    "isin": "INF247L01569",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133425",
    "name": "ICICI Prudential Growth Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 7  Dividend",
    "minAmount": "5000",
    "launchDate": "2015-01-05",
    "closeData": "",
    "isin": "INF109KA18P1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133426",
    "name": "ICICI Prudential Growth Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 7 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-01-05",
    "closeData": "",
    "isin": "INF109KA19P9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133431",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-07",
    "closeData": "",
    "isin": "INF109KA16Q3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133432",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-07",
    "closeData": "",
    "isin": "INF109KA17Q1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133433",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-07",
    "closeData": "",
    "isin": "INF109KA15Q5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133434",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1103 Days Plan F Cumulative option",
    "minAmount": "5000",
    "launchDate": "2015-01-07",
    "closeData": "",
    "isin": "INF109KA14Q8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133435",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-08",
    "closeData": "",
    "isin": "INF109KA19Q7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133436",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-08",
    "closeData": "",
    "isin": "INF109KA10R4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133438",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1288 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-08",
    "closeData": "",
    "isin": "INF109KA18Q9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133456",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF109KA17R9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133457",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF109KA16R1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133458",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF109KA15S1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133467",
    "name": "ICICI Prudential Growth Fund - Series 8",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 8 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF109KA10S2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133468",
    "name": "ICICI Prudential Growth Fund - Series 8",
    "category": "Growth",
    "navName": "ICICI Prudential Growth Fund - Series 8  Dividend",
    "minAmount": "5000",
    "launchDate": "2015-01-09",
    "closeData": "",
    "isin": "INF109KA19R5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133471",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 4- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-12",
    "closeData": "",
    "isin": "INF204KA1B15",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133472",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 4-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-12",
    "closeData": "",
    "isin": "INF204KA1B07",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133473",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-12",
    "closeData": "",
    "isin": "INF204KA1A99",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133470",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-01-12",
    "closeData": "",
    "isin": "INF204KA1A81",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133486",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Regular-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FP0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148350",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Direct - Bonus Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148331",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Regular- Monthly IDCW Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IR0",
    "isinRe": "INF955L01IS8"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148332",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Regular- Quarterly IDCW Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IP4",
    "isinRe": "INF955L01IQ2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148333",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Direct- Growth Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IT6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148334",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Direct- Monthly IDCW Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IW0",
    "isinRe": "INF955L01IX8"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148335",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund-Direct- Quarterly IDCW Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IU4",
    "isinRe": "INF955L01IV2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148349",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Regular- Bonus Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133522",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Regular -Monthly Dividend Reinvestment Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FW6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133523",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Direct-Monthly Dividend Reinvestment Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01GB8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133524",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Direct- Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01GA0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148330",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund- Regular- Growth Option- Segregated Portfolio- 1",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01IO7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133488",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Direct-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FR6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133517",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Regular-Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FU0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133518",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Regular-Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FV8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133519",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Regular -Quarterly Dividend Reinvestment Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FX4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133520",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Direct- Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01FZ9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "133521",
    "name": "Baroda BNP Paribas Credit Risk Fund (scheme has one segregated portfolio))",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Baroda BNP Paribas Credit Risk Fund -Direct-Quarterly Dividend Reinvestment Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-08",
    "closeData": "2015-01-21",
    "isin": "INF955L01GC6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133501",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 76 -1185 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 76 -1185 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-16",
    "closeData": "",
    "isin": "INF109KA19S3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133502",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 76 -1185 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 76 -1185 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-16",
    "closeData": "",
    "isin": "INF109KA17S7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133503",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 76 -1185 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 76 -1185 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-16",
    "closeData": "",
    "isin": "INF109KA16S9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133504",
    "name": "ICICI Prudential Fixed Maturity Plan -Series 76 -1185 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan-Series 76 -1185 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-16",
    "closeData": "",
    "isin": "INF109KA18S5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133513",
    "name": "Aditya Birla Sun Life Manufacturing Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Manufacturing Equity Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF209KA1YH6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133514",
    "name": "Aditya Birla Sun Life Manufacturing Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Manufacturing Equity Fund - Direct - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF209KA1YL8",
    "isinRe": "INF209KA1YM6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133515",
    "name": "Aditya Birla Sun Life Manufacturing Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Manufacturing Equity Fund - Regular - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF209KA1YI4",
    "isinRe": "INF209KA1YJ2"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "133516",
    "name": "Aditya Birla Sun Life Manufacturing Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Manufacturing Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF209KA1YK0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133530",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-01-19",
    "closeData": "",
    "isin": "INF204KA1B49",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133531",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-01-19",
    "closeData": "",
    "isin": "INF204KA1B56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133532",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-01-19",
    "closeData": "",
    "isin": "INF204KA1B31",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133533",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan C- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-01-19",
    "closeData": "",
    "isin": "INF204KA1B23",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133630",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Wealth Creation Scheme- Direct Plan-Growth Plan- Bonus Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1B98",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133631",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Wealth Creation Scheme- Growth Plan- Bonus Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1B80",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133565",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Wealth Creation Scheme- Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1B64",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133566",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "NIPPON INDIA RETIREMENT FUND - WEALTH CREATION SCHEME - Direct Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C14",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133567",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "NIPPON INDIA RETIREMENT FUND - WEALTH CREATION SCHEME - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C06",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133568",
    "name": "Nippon India Retirement Fund- Wealth Creation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Wealth Creation Scheme- Direct Plan- Growth Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1B72",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133569",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Income Generation Scheme- Direct Plan- Growth Plan - Growth Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C30",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133570",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "NIPPON INDIA RETIREMENT FUND - INCOME GENERATION SCHEME - Direct Plan - IDCW Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C71",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133571",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "NIPPON INDIA RETIREMENT FUND - INCOME GENERATION SCHEME - IDCW Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C63",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133572",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Income Generation Scheme-Growth Plan - Growth Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C22",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133628",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Income Generation Scheme- Growth Plan- Bonus Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C48",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133629",
    "name": "Nippon India Retirement Fund- Income Generation Scheme",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Nippon India Retirement Fund- Income Generation Scheme- Direct Plan-Growth Plan- Bonus Option",
    "minAmount": "Rs.5 000 & in multiples of Re.500 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF204KA1C55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133577",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-27",
    "closeData": "",
    "isin": "INF109KA12T6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133578",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-01-27",
    "closeData": "",
    "isin": "INF109KA10T0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133579",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-27",
    "closeData": "",
    "isin": "INF109KA11T8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133580",
    "name": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 8 - 1103 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-01-27",
    "closeData": "",
    "isin": "INF109KA13T4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133636",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN G - 1285 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF109KA18T3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133637",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN G - 1285 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF109KA19T1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133639",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN G - 1285 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1285 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF109KA10U8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133662",
    "name": "Kotak FMP Series 171 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 171 (1099 Days) - Growth",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF174K01G82",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133663",
    "name": "Kotak FMP Series 171 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 171 (1099 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF174K01H08",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133664",
    "name": "Kotak FMP Series 171 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 171 (1099 Days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF174K01G90",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133665",
    "name": "Kotak FMP Series 171 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 171 (1099 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "",
    "isin": "INF174K01H16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133670",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-06",
    "closeData": "",
    "isin": "INF109KA16V3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133672",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-06",
    "closeData": "",
    "isin": "INF109KA15V5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133673",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1155 Days Plan K Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-06",
    "closeData": "",
    "isin": "INF109KA14V8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133709",
    "name": "LIC MF Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "LIC MF Large & Mid Cap Fund-Direct Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "2015-02-16",
    "isin": "INF767K01ND1",
    "isinRe": "INF767K01NF6"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133710",
    "name": "LIC MF Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "LIC MF Large & Mid Cap Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "2015-02-16",
    "isin": "INF767K01NE9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133711",
    "name": "LIC MF Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "LIC MF Large & Mid Cap Fund-Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "2015-02-16",
    "isin": "INF767K01NB5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133712",
    "name": "LIC MF Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "LIC MF Large & Mid Cap Fund-Regular Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2015-02-02",
    "closeData": "2015-02-16",
    "isin": "INF767K01NA7",
    "isinRe": "INF767K01NC3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133770",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Coporate Bond Fund- Retail Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133771",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Retail Plan- Weekly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01897",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133772",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Institutional Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01921",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133773",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Institutional Plan-Daily Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133774",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Institutional Plan-Monthly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133775",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Standard Plan-Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BX2 ",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133794",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Standard Plan- Annual Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BV6 ",
    "isinRe": "INF178L01BW4 "
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133795",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "kotak Corporate Bond Fund- Direct Plan - Annual Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01CF7 ",
    "isinRe": "INF178L01CG5 "
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133788",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan- Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01CH3 ",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133789",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan- Weekly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01CD2 ",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133790",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan -Daily Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01CE0 ",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133791",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BY0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133792",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan- Monthly Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BZ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133793",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Direct Plan- Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01CC4 ",
    "isinRe": "INF178L01CB6 "
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133782",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Regular Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BO1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133783",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Retail Plan-Daily Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133784",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Coporate Bond Fund- Institutional Plan-Weekly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133785",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Institutional Plan-Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133786",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Standard Plan-Weekly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BT0 ",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133787",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Regular Plan-Monthly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BQ6",
    "isinRe": "INF178L01BP8"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133776",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Standard Plan-Daily Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BU8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133777",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Regular Plan-Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01BR4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133778",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Retail Plan-Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133779",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Retail Plan- Monthly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF178L01855",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133780",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Retail Plan- Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133781",
    "name": "Kotak Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Kotak Corporate Bond Fund- Institutional Plan-Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133796",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund - Standard Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": "INF178L01095",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133797",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund - Institutional Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133798",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund - Institutional Plan-Dividend",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133799",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund - Standard Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": "INF178L01079",
    "isinRe": "INF178L01087"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133800",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund- Direct Plan- Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": "INF178L01AK1",
    "isinRe": "INF178L01AJ3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133801",
    "name": "Kotak Infrastructure and Economic Reform Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Infrastructure & Economic Reform Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-01-10",
    "closeData": "2008-01-31",
    "isin": "INF178L01AL9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133805",
    "name": "Kotak Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Kotak Low Duration Fund- Regular Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-04",
    "isin": "INF178L01202",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133809",
    "name": "Kotak Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Kotak Low Duration Fund- Regular Plan-Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-04",
    "isin": "INF178L01210",
    "isinRe": "INF178L01228"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133810",
    "name": "Kotak Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Kotak Low Duration Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-04",
    "isin": "INF178L01AX4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133812",
    "name": "Kotak Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Kotak Low Duration Fund - Direct Plan- Standard Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2008-02-29",
    "closeData": "2008-03-04",
    "isin": "INF178L01AZ9",
    "isinRe": "INF178L01AY2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133814",
    "name": "Kotak World Gold Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak World Gold Fund - Standard Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2008-04-15",
    "closeData": "2008-05-14",
    "isin": "INF178L01145",
    "isinRe": "INF178L01137"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133815",
    "name": "Kotak World Gold Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak World Gold Fund - Standard Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-04-15",
    "closeData": "2008-05-14",
    "isin": "INF178L01152",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133816",
    "name": "Kotak World Gold Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak World Gold Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-04-15",
    "closeData": "2008-05-14",
    "isin": "INF178L01AO3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133817",
    "name": "Kotak World Gold Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak World Gold Fund- Direct Plan- Dividend Option",
    "minAmount": "5000",
    "launchDate": "2008-04-15",
    "closeData": "2008-05-14",
    "isin": "INF178L01AM7",
    "isinRe": "INF178L01AN5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133830",
    "name": "Kotak US Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak US Equity Fund - Standard Plan - Dividend option",
    "minAmount": "5000",
    "launchDate": "2013-11-29",
    "closeData": "2013-12-13",
    "isin": "INF178L01CJ9",
    "isinRe": "INF178L01CK7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133831",
    "name": "Kotak US Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak US Equity Fund - Direct Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2013-11-29",
    "closeData": "2013-12-13",
    "isin": "INF178L01CL5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133832",
    "name": "Kotak US Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak US Equity Fund - Direct Plan - Dividend option",
    "minAmount": "5000",
    "launchDate": "2013-11-29",
    "closeData": "2013-12-13",
    "isin": "INF178L01CM3",
    "isinRe": "INF178L01CN1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "133833",
    "name": "Kotak US Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak US Equity Fund - Standard Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2013-11-29",
    "closeData": "2013-12-13",
    "isin": "INF178L01CI1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "133836",
    "name": "PGIM India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "PGIM India Flexi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-25",
    "isin": "INF663L01FJ3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "133837",
    "name": "PGIM India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "PGIM India Flexi Cap Fund - Direct Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-25",
    "isin": "INF663L01FG9",
    "isinRe": "INF663L01FH7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "133838",
    "name": "PGIM India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "PGIM India Flexi Cap Fund - Regular Plan - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-25",
    "isin": "INF663L01FK1",
    "isinRe": "INF663L01FL9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "133839",
    "name": "PGIM India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "PGIM India Flexi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-25",
    "isin": "INF663L01FF1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133840",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-02-13",
    "closeData": "",
    "isin": "INF204KA1E79",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133841",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-02-13",
    "closeData": "",
    "isin": "INF204KA1E87",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133842",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-02-13",
    "closeData": "",
    "isin": "INF204KA1E95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133843",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 7- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-02-13",
    "closeData": "",
    "isin": "INF204KA1F03",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133857",
    "name": "SBI BANKING & FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Banking & Financial Services Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-24",
    "isin": "INF200KA1515",
    "isinRe": "INF200KA1523"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133858",
    "name": "SBI BANKING & FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI BANKING & FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-24",
    "isin": "INF200KA1473",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133859",
    "name": "SBI BANKING & FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI BANKING & FINANCIAL SERVICES FUND - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-24",
    "isin": "INF200KA1507",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "133860",
    "name": "SBI BANKING & FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Banking & Financial Services Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-02-11",
    "closeData": "2015-02-24",
    "isin": "INF200KA1481",
    "isinRe": "INF200KA1499"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133861",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN H - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-18",
    "closeData": "",
    "isin": "INF109KA14X4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133862",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN H - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-18",
    "closeData": "",
    "isin": "INF109KA15X1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133863",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN H - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-18",
    "closeData": "",
    "isin": "INF109KA17X7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133864",
    "name": "ICICI Prudential Capital Protection Oriented Fund VII - PLAN H - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VII - 1284 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-18",
    "closeData": "",
    "isin": "INF109KA16X9",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "133867",
    "name": "BANK OF INDIA CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANK OF INDIA Credit Risk Fund - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2015-02-06",
    "closeData": "2015-02-20",
    "isin": "INF761K01DH6",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "133868",
    "name": "BANK OF INDIA CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANK OF INDIA Credit Risk  Fund - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2015-02-06",
    "closeData": "2015-02-20",
    "isin": "INF761K01DG8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133874",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-19",
    "closeData": "",
    "isin": "INF109KA16Y7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133875",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-02-19",
    "closeData": "",
    "isin": "INF109KA18Y3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133876",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-19",
    "closeData": "",
    "isin": "INF109KA19Y1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133877",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1142 Days Plan M Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-02-19",
    "closeData": "",
    "isin": "INF109KA17Y5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133882",
    "name": "Reliance Capital Builder Fund II - Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series C - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF204KA1F78",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133883",
    "name": "Reliance Capital Builder Fund II - Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series C - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF204KA1F86",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133884",
    "name": "Reliance Capital Builder Fund II - Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series C - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF204KA1F52",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133885",
    "name": "Reliance Capital Builder Fund II - Series C",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund II - Series C - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF204KA1F60",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "133908",
    "name": "LIC MF Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Banking and Financial Services Fund-Direct Plan-IDCW",
    "minAmount": "5000/-& thereafter multiples of 1/- during NFO",
    "launchDate": "2015-03-09",
    "closeData": "2015-03-23",
    "isin": "INF767K01NJ8",
    "isinRe": "INF767K01NL4"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134015",
    "name": "LIC MF Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Banking and Financial Services Fund-Regular Plan-IDCW",
    "minAmount": "5000/-& thereafter multiples of 1/- during NFO",
    "launchDate": "2015-03-09",
    "closeData": "2015-03-23",
    "isin": "INF767K01NG4",
    "isinRe": "INF767K01NI0"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134016",
    "name": "LIC MF Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Banking and Financial Services Fund-Regular Plan-Growth",
    "minAmount": "5000/-& thereafter multiples of 1/- during NFO",
    "launchDate": "2015-03-09",
    "closeData": "2015-03-23",
    "isin": "INF767K01NH2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "134017",
    "name": "LIC MF Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "LIC MF Banking and Financial Services Fund-Direct Plan-Growth",
    "minAmount": "5000/-& thereafter multiples of 1/- during NFO",
    "launchDate": "2015-03-09",
    "closeData": "2015-03-23",
    "isin": "INF767K01NK6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133919",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740KA1OO9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133920",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K019P0",
    "isinRe": "INF740K010Q7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133921",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Regular Plan - IDCW - Quarterly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K011Q5",
    "isinRe": "INF740K012Q3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133922",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740KA1OL5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133923",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740KA1OM3",
    "isinRe": "INF740KA1ON1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133924",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Direct Plan - IDCW - Quarterly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K016Q4",
    "isinRe": "INF740K017Q2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133925",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K013Q1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133926",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K018P2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133927",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740KA1OP6",
    "isinRe": "INF740KA1OQ4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "133928",
    "name": "DSP Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DSP Low Duration Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2015-02-27",
    "closeData": "",
    "isin": "INF740K014Q9",
    "isinRe": "INF740K015Q6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133965",
    "name": "ICICI Prudential India Recovery Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 1 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF109KB1128",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "133966",
    "name": "ICICI Prudential India Recovery Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 1 Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-09",
    "closeData": "",
    "isin": "INF109KB1110",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133984",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-03-05",
    "closeData": "",
    "isin": "INF204KA1G69",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133985",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-03-05",
    "closeData": "",
    "isin": "INF204KA1G51",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133986",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-03-05",
    "closeData": "",
    "isin": "INF204KA1G44",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "133987",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan D- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-03-05",
    "closeData": "",
    "isin": "INF204KA1G36",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134005",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1132 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1132 Days Plan P Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF109KB1219",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134007",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1132 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1132 Days Plan P Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF109KB1235",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134008",
    "name": "SBI Nifty Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty Bank ETF",
    "minAmount": "5000 and in multiples of Re.1 thereaft(During NFO)",
    "launchDate": "2015-03-02",
    "closeData": "2015-03-16",
    "isin": "INF200KA1580",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134013",
    "name": "SBI Nifty Next 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty Next 50 ETF",
    "minAmount": "5000 and in multiples of Re.1 thereaft(During NFO)",
    "launchDate": "2015-03-02",
    "closeData": "2015-03-16",
    "isin": "INF200KA1598",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134014",
    "name": "SBI S&P BSE 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI S&P BSE 100 ETF",
    "minAmount": "5000 and in multiples of Re.1 thereaft(During NFO)",
    "launchDate": "2015-03-02",
    "closeData": "2015-03-16",
    "isin": "INF200KA1572",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134018",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN A - 1300 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1300 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF109KB1276",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134020",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN A - 1300 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1300 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF109KB1268",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134021",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN A - 1300 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1300 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF109KB1250",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134022",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 10- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF204KA1H35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134023",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 10- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF204KA1H43",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134024",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF204KA1H19",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134025",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-10",
    "closeData": "",
    "isin": "INF204KA1H27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134042",
    "name": "ICICI Prudential India Recovery Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 2 Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-18",
    "closeData": "",
    "isin": "INF109KB1292",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134043",
    "name": "ICICI Prudential India Recovery Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 2 Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-18",
    "closeData": "",
    "isin": "INF109KB1300",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134108",
    "name": "SBI DYNAMIC ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Dynamic Asset Allocation Fund- Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-03-10",
    "closeData": "2015-03-24",
    "isin": "INF200KA1655",
    "isinRe": "INF200KA1663"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134109",
    "name": "SBI DYNAMIC ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Dynamic Asset Allocation Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-03-10",
    "closeData": "2015-03-24",
    "isin": "INF200KA1648",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134110",
    "name": "SBI DYNAMIC ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Dynamic Asset Allocation Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-03-10",
    "closeData": "2015-03-24",
    "isin": "INF200KA1671",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134111",
    "name": "SBI DYNAMIC ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Dynamic Asset Allocation Fund- Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-03-10",
    "closeData": "2015-03-24",
    "isin": "INF200KA1689",
    "isinRe": "INF200KA1697"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134160",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-19",
    "closeData": "",
    "isin": "INF109KB1375",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134162",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-19",
    "closeData": "",
    "isin": "INF109KB1367",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134163",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1100 Days Plan T Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-19",
    "closeData": "",
    "isin": "INF109KB1359",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134176",
    "name": "Kotak FMP Series 172 (1126 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 172 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-12",
    "closeData": "",
    "isin": "INF174K01I49",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134177",
    "name": "Kotak FMP Series 172 (1126 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 172 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-12",
    "closeData": "",
    "isin": "INF174K01I23",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134178",
    "name": "Kotak FMP Series 172 (1126 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 172 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-12",
    "closeData": "",
    "isin": "INF174K01I31",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134179",
    "name": "Kotak FMP Series 172 (1126 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 172 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-12",
    "closeData": "",
    "isin": "INF174K01I56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134180",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 14- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-20",
    "closeData": "",
    "isin": "INF204KA1J66",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134181",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 14- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-20",
    "closeData": "",
    "isin": "INF204KA1J58",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134182",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 14- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-20",
    "closeData": "",
    "isin": "INF204KA1J41",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134183",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 14- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-03-20",
    "closeData": "",
    "isin": "INF204KA1J33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134186",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1391",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134187",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1409",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134188",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1120 Days Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1417",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134189",
    "name": "ICICI Prudential Value Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 6 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1573",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134190",
    "name": "ICICI Prudential Value Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 6 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1581",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134191",
    "name": "ICICI Prudential Value Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 6  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1565",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134192",
    "name": "ICICI Prudential Value Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 6  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-23",
    "closeData": "",
    "isin": "INF109KB1557",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134259",
    "name": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF109KB1730",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134260",
    "name": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF109KB1748",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134261",
    "name": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF109KB1722",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134262",
    "name": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 9 - 1140 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF109KB1714",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134305",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-26",
    "closeData": "",
    "isin": "INF109KB1698",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134307",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-26",
    "closeData": "",
    "isin": "INF109KB1680",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134308",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1108 Days Plan V Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-26",
    "closeData": "",
    "isin": "INF109KB1672",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134309",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF109KB1979",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134310",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF109KB1987",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134311",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF109KB1961",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134312",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1127 Days Plan W Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF109KB1953",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134347",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-06",
    "closeData": "",
    "isin": "INF109KB1AT1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134348",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-06",
    "closeData": "",
    "isin": "INF109KB1AV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134349",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-06",
    "closeData": "",
    "isin": "INF109KB1AU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134350",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-06",
    "closeData": "",
    "isin": "INF109KB1AW5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100780",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Retail Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IL7",
    "isinRe": "INF903J01IM5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100781",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Retail Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IK9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100782",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Regular Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IP8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100783",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Regular Plan - Weekly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IQ6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100784",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IN3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "100793",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Regular Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01IO1",
    "isinRe": "INF903J01IR4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134368",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J018I3",
    "isinRe": "INF903J011J6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134369",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J019I1",
    "isinRe": "INF903J014J0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134370",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Monthly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J012J4",
    "isinRe": "INF903J013J2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134362",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund Regular Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J019H3",
    "isinRe": "INF903J013I4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134363",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J017I5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134364",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Half Yearly Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J016J5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134365",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Annual Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J017J3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134366",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Daily Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J010J8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134367",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Direct Quarterly Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J015J7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134356",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J016H9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134357",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Half Yearly Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J015I9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134358",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "SundaramBanking&PSUDebtFund-RegularDailyDividendReinvestment",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J010I0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134359",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Monthly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J018H5",
    "isinRe": "INF903J012I6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134360",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Weekly Dividend",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J017H7",
    "isinRe": "INF903J011I8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134361",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Quarterly Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J014I2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "118326",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Regular Plan - Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01LY4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119625",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01PA5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119626",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Direct Plan - Monthly Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01PB3",
    "isinRe": "INF903J01PE7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119627",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Direct Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01PC1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "119629",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking and PSU Debt Fund Direct Plan - Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J01RI4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "134355",
    "name": "Sundaram Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Sundaram Banking & PSU Debt Fund - Regular Annual Bonus",
    "minAmount": "5000",
    "launchDate": "2015-03-25",
    "closeData": "",
    "isin": "INF903J016I7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134383",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1K47",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134384",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Regular Plan - Bonus",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1K70",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134385",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Direct - IDCW",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1K96",
    "isinRe": "INF209KA1L04"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134386",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Regular - IDCW",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1K54",
    "isinRe": "INF209KA1K62"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134387",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1K88",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "134388",
    "name": "Aditya Birla Sun Life Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "Aditya Birla Sun Life Credit Risk Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000 and in multiples of re. 1/-",
    "launchDate": "2015-03-30",
    "closeData": "",
    "isin": "INF209KA1L12",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134397",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-10",
    "closeData": "",
    "isin": "INF109KB1BH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134399",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-10",
    "closeData": "",
    "isin": "INF109KB1BF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134400",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1134 Days Plan Y Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-10",
    "closeData": "",
    "isin": "INF109KB1BG6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134409",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF204KA1M04",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134410",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF204KA1L88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134411",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF204KA1L96",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134412",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VII- Plan E- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF204KA1L70",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134458",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF109KB1BB7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134459",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF109KB1BC5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134460",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF109KB1BE1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134461",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 76 - 1135 Days Plan Z Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-15",
    "closeData": "",
    "isin": "INF109KB1BD3",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "134493",
    "name": "Quantum Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Quantum Dynamic Bond Fund - Direct Plan Monthly IDCW",
    "minAmount": "Rs. 500",
    "launchDate": "2015-04-29",
    "closeData": "",
    "isin": "INF082J01184",
    "isinRe": "INF082J01192"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "134494",
    "name": "Quantum Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Quantum Dynamic Bond Fund - Direct Plan Growth Option",
    "minAmount": "Rs. 500",
    "launchDate": "2015-04-29",
    "closeData": "",
    "isin": "INF082J01176",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141060",
    "name": "Quantum Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Quantum Dynamic Bond Fund - Regular Plan Monthly IDCW",
    "minAmount": "Rs. 500",
    "launchDate": "2015-04-29",
    "closeData": "",
    "isin": "INF082J01226",
    "isinRe": "INF082J01234"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "141061",
    "name": "Quantum Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Quantum Dynamic Bond Fund - Regular Plan Growth Option",
    "minAmount": "Rs. 500",
    "launchDate": "2015-04-29",
    "closeData": "",
    "isin": "INF082J01218",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134495",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1JV2",
    "isinRe": "INF789FB1KP2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134496",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1JW0",
    "isinRe": "INF789FB1JX8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134497",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1JY6",
    "isinRe": "INF789FB1JZ3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134498",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KC0",
    "isinRe": "INF789FB1KD8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134499",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1JU4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134500",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KA4",
    "isinRe": "INF789FB1KB2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134501",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KF3",
    "isinRe": "INF789FB1KG1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134502",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KH9",
    "isinRe": "INF789FB1KI7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134503",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134504",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KJ5",
    "isinRe": "INF789FB1KK3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134505",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KL1",
    "isinRe": "INF789FB1KM9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "134506",
    "name": "UTI - Medium Term Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-03-25",
    "closeData": "2015-03-30",
    "isin": "INF789FB1KO5",
    "isinRe": "INF789FB1KN7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134537",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DI8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134538",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DG2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134539",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DH0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134540",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1132 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134541",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DD9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134542",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DC1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134543",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DE7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134544",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1103 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-04-28",
    "closeData": "",
    "isin": "INF109KB1DB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134545",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Nippon India Banking  & PSU Debt Fund- Growth Plan- Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1T56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134546",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Nippon India Banking  & PSU Debt Fund- Growth Plan- Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1T64",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134547",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Nippon India Banking  & PSU Debt Fund- Direct Plan-Growth Plan- Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U53",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134548",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1T72",
    "isinRe": "INF204KA1T80"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134549",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U38",
    "isinRe": "INF204KA1U46"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134556",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U95",
    "isinRe": "INF204KA1V03"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134550",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1V37",
    "isinRe": "INF204KA1V45"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134551",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1T98",
    "isinRe": "INF204KA1U04"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134552",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Nippon India Banking  & PSU Debt Fund- Direct Plan-Growth Plan- Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U61",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134553",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - WEEKLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U12",
    "isinRe": "INF204KA1U20"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134554",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - Direct Plan - WEEKLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1V11",
    "isinRe": "INF204KA1V29"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134555",
    "name": "Nippon India Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "NIPPON INDIA BANKING & PSU DEBT FUND - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF204KA1U79",
    "isinRe": "INF204KA1U87"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134565",
    "name": "Kotak India Growth Fund - Series I",
    "category": "Growth",
    "navName": "Kotak India Gorwth Fund - Series I - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-04-13",
    "closeData": "",
    "isin": "INF174K01P24",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134566",
    "name": "Kotak India Growth Fund - Series I",
    "category": "Growth",
    "navName": "Kotak India Gorwth Fund - Series I - Regular Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-04-13",
    "closeData": "",
    "isin": "INF174K01P32",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134567",
    "name": "Kotak India Growth Fund - Series I",
    "category": "Growth",
    "navName": "Kotak India Gorwth Fund - Series I - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-04-13",
    "closeData": "",
    "isin": "INF174K01P40",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134568",
    "name": "Kotak India Growth Fund - Series I",
    "category": "Growth",
    "navName": "Kotak India Gorwth Fund - Series I - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-04-13",
    "closeData": "",
    "isin": "INF174K01P57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134573",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF109KB1DN8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134574",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF109KB1DO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134575",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF109KB1DP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134576",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1473 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-05-05",
    "closeData": "",
    "isin": "INF109KB1DQ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134589",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 18- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-07",
    "closeData": "",
    "isin": "INF204KA1V78",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134590",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 18- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-07",
    "closeData": "",
    "isin": "INF204KA1V86",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134591",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 18- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-07",
    "closeData": "",
    "isin": "INF204KA1V60",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134592",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 18- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-07",
    "closeData": "",
    "isin": "INF204KA1V52",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134593",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund- Growth Plan- Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1V94",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134594",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund- Direct Plan- Growth Plan-Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W77",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134595",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund- Direct Plan- Growth Plan- Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W85",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134596",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W10",
    "isinRe": "INF204KA1W28"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134597",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W93",
    "isinRe": "INF204KA1X01"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134598",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W51",
    "isinRe": "INF204KA1W69"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148278",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB19S0",
    "isinRe": "INF204KB10T7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148279",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 2 - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB15S8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148280",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 2 - Growth Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB16S6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148281",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB17S4",
    "isinRe": "INF204KB18S2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148282",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB11T5",
    "isinRe": "INF204KB12T3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147700",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 1 - Growth Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB19E0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148273",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 2 - Direct Plan - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB17R6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148274",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 2 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB18R4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148275",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB19R2",
    "isinRe": "INF204KB10S9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148276",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB11S7",
    "isinRe": "INF204KB12S5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148277",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB13S3",
    "isinRe": "INF204KB14S1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147694",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB12F2",
    "isinRe": "INF204KB13F0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147695",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 1 - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB18E2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147696",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB10F6",
    "isinRe": "INF204KB11F4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147697",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 1 - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB11E7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147698",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB12E5",
    "isinRe": "INF204KB13E3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147699",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB14F8",
    "isinRe": "INF204KB15F5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147691",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund - Segregated Portfolio 1 - Direct Plan - Growth Plan - Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB10E9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147692",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB14E1",
    "isinRe": "INF204KB15E8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147693",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KB16E6",
    "isinRe": "INF204KB17E4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134599",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - Direct Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1X35",
    "isinRe": "INF204KA1X43"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134600",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W36",
    "isinRe": "INF204KA1W44"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134601",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "NIPPON INDIA EQUITY SAVINGS FUND - Direct Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1X19",
    "isinRe": "INF204KA1X27"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134602",
    "name": "Nippon India Equity Savings Fund (Number of Segregated Portfolios - 2)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Nippon India Equity Savings Fund- Growth Plan- Bonus Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-12",
    "closeData": "",
    "isin": "INF204KA1W02",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134628",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-05-15",
    "closeData": "",
    "isin": "INF109KB1EL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134629",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-05-15",
    "closeData": "",
    "isin": "INF109KB1EM8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134630",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1130 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-05-15",
    "closeData": "",
    "isin": "INF109KB1EN6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134635",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 19- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF204KA11A7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134636",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 19- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF204KA12A5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134637",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 19- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF204KA13A3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134638",
    "name": "Reliance Fixed Horizon Fund XXVIII- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXVIII- Series 19- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF204KA14A1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134639",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DB2",
    "isinRe": "INF200KA1DC0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134640",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DI7",
    "isinRe": "INF200KA1DJ5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134641",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DG1",
    "isinRe": "INF200KA1DH9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134642",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DD8",
    "isinRe": "INF200KA1DE6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134643",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DF3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "134644",
    "name": "SBI EQUITY SAVINGS FUND",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "SBI Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2015-05-11",
    "closeData": "2015-05-25",
    "isin": "INF200KA1DA4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134656",
    "name": "ICICI Prudential Value Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 7 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF109KB1EU1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134657",
    "name": "ICICI Prudential Value Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 7 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-05-19",
    "closeData": "",
    "isin": "INF109KB1EW7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134716",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1101 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-02",
    "closeData": "",
    "isin": "INF109KB1GX0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134717",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1101 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-02",
    "closeData": "",
    "isin": "INF109KB1GZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134718",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1101 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-02",
    "closeData": "",
    "isin": "INF109KB1GY8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134719",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1101 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-02",
    "closeData": "",
    "isin": "INF109KB1HA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134733",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-05",
    "closeData": "",
    "isin": "INF109KB1HE8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134730",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-05",
    "closeData": "",
    "isin": "INF109KB1HD0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134731",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-05",
    "closeData": "",
    "isin": "INF109KB1HB4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134732",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-05",
    "closeData": "",
    "isin": "INF109KB1HC2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134766",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134767",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134764",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134765",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1125 Days Plan I Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134772",
    "name": "Reliance Capital Builder Fund III - Series A (3 years)",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund III - Series A - Direct Plan - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": "INF204KA16D0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134773",
    "name": "Reliance Capital Builder Fund III - Series A (3 years)",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund III - Series A - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": "INF204KA15D2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134774",
    "name": "Reliance Capital Builder Fund III - Series A (3 years)",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund III - Series A - Growth Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": "INF204KA13D7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134775",
    "name": "Reliance Capital Builder Fund III - Series A (3 years)",
    "category": "Growth",
    "navName": "Reliance Capital Builder Fund III - Series A - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2015-06-10",
    "closeData": "",
    "isin": "INF204KA14D5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134782",
    "name": "Nippon India ETF Nifty 50 Value 20",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 50 Value 20",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1",
    "launchDate": "2015-06-11",
    "closeData": "",
    "isin": "INF204KA17D8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "134813",
    "name": "Mirae Asset Hybrid-Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mirae Asset Hybrid-Equity Fund -Direct Plan-Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-08",
    "closeData": "",
    "isin": "INF769K01DH9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "134814",
    "name": "Mirae Asset Hybrid-Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mirae Asset Hybrid   Equity Fund Direct IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-08",
    "closeData": "",
    "isin": "INF769K01DI7",
    "isinRe": "INF769K01DJ5"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "134815",
    "name": "Mirae Asset Hybrid-Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mirae Asset Hybrid-Equity Fund -Regular Plan-Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-08",
    "closeData": "",
    "isin": "INF769K01DE6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "134816",
    "name": "Mirae Asset Hybrid-Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mirae Asset Hybrid   Equity Fund Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-08",
    "closeData": "",
    "isin": "INF769K01DF3",
    "isinRe": "INF769K01DG1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134824",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134825",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134826",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134827",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1118 Days Plan J Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134838",
    "name": "Kotak FMP Series 175 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 175 (1100 Days) - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-06-17",
    "closeData": "",
    "isin": "INF174K01R71",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134839",
    "name": "Kotak FMP Series 175 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 175 (1100 Days) - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-06-17",
    "closeData": "",
    "isin": "INF174K01R89",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134840",
    "name": "Kotak FMP Series 175 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 175 (1100 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-06-17",
    "closeData": "",
    "isin": "INF174K01S05",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "134841",
    "name": "Kotak FMP Series 175 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 175 (1100 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-06-17",
    "closeData": "",
    "isin": "INF174K01R97",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134842",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1107 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1107 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF109KB1IC0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134843",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1107 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1107 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF109KB1IA4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134844",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1107 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1107 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF109KB1IB2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134845",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1107 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1107 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF109KB1HZ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134888",
    "name": "Reliance Interval Fund -IV - Series 2 (1110 days)",
    "category": "Income",
    "navName": "Reliance Interval Fund -IV -Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF204KA12F4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134889",
    "name": "Reliance Interval Fund -IV - Series 2 (1110 days)",
    "category": "Income",
    "navName": "Reliance Interval Fund -IV -Series 2 - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF204KA10F8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134890",
    "name": "Reliance Interval Fund -IV - Series 2 (1110 days)",
    "category": "Income",
    "navName": "Reliance Interval Fund -IV -Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF204KA13F2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134891",
    "name": "Reliance Interval Fund -IV - Series 2 (1110 days)",
    "category": "Income",
    "navName": "Reliance Interval Fund -IV -Series 2 - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-06-23",
    "closeData": "",
    "isin": "INF204KA11F6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134912",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134913",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134914",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134915",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1106 Days Plan K Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134922",
    "name": "Nippon India US Equity Opportunites Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India US Equity Opportunites Fund- Growth Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF204KA12G2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134923",
    "name": "Nippon India US Equity Opportunites Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India US Equity Opportunites Fund- Direct Plan- Growth Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF204KA15G5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134924",
    "name": "Nippon India US Equity Opportunites Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA - US EQUITY OPPORTUNITES FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF204KA13G0",
    "isinRe": "INF204KA14G8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134925",
    "name": "Nippon India US Equity Opportunites Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "NIPPON INDIA - US EQUITY OPPORTUNITES FUND - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF204KA16G3",
    "isinRe": "INF204KA17G1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134926",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-06",
    "closeData": "",
    "isin": "INF109KB1IW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134927",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-06",
    "closeData": "",
    "isin": "INF109KB1IU2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134928",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-06",
    "closeData": "",
    "isin": "INF109KB1IT4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134929",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan L Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-06",
    "closeData": "",
    "isin": "INF109KB1IV0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134930",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF109KB1IR8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134931",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF109KB1IP2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134932",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF109KB1IQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134933",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1100 Days Plan M Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-03",
    "closeData": "",
    "isin": "INF109KB1IS6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134942",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1IX6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134943",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1JA2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134944",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1IY4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "134945",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1115 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1IZ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "134999",
    "name": "Reliance Interval Fund IV- Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund IV- Series 3- Direct Plan-Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-07-14",
    "closeData": "",
    "isin": "INF204KA15I1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135000",
    "name": "Reliance Interval Fund IV- Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund IV- Series 3- Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-07-14",
    "closeData": "",
    "isin": "INF204KA12I8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135001",
    "name": "Reliance Interval Fund IV- Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund IV- Series 3- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-07-14",
    "closeData": "",
    "isin": "INF204KA14I4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135002",
    "name": "Reliance Interval Fund IV- Series 3",
    "category": "Income",
    "navName": "Reliance Interval Fund IV- Series 3- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2015-07-14",
    "closeData": "",
    "isin": "INF204KA13I6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135011",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 77 - 1105 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1105 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1JV8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135012",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 77 - 1105 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1105 Days Plan N - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1JX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135013",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 77 - 1105 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1105 Days Plan N - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1JW6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135014",
    "name": "ICICI Prudential Fixed Maturity Plan- Series 77 - 1105 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1105 Days Plan N - Direct plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-09",
    "closeData": "",
    "isin": "INF109KB1JY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135035",
    "name": "ICICI Prudential Value Fund - Series 8",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 8 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-20",
    "closeData": "",
    "isin": "INF109KC1JK0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135036",
    "name": "ICICI Prudential Value Fund - Series 8",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 8 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-20",
    "closeData": "",
    "isin": "INF109KC1JJ2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135047",
    "name": "Kotak FMP Series 176 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 176 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-07-10",
    "closeData": "",
    "isin": "INF174K01S70",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135048",
    "name": "Kotak FMP Series 176 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 176 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-07-10",
    "closeData": "",
    "isin": "INF174K01S96",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135049",
    "name": "Kotak FMP Series 176 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 176 - Direct Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-07-10",
    "closeData": "",
    "isin": "INF174K01T04",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135050",
    "name": "Kotak FMP Series 176 (1100 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 176 - Regular Plan - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-07-10",
    "closeData": "",
    "isin": "INF174K01S88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135084",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-07-22",
    "closeData": "",
    "isin": "INF204KA10J0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135085",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-07-22",
    "closeData": "",
    "isin": "INF204KA12J6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135086",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-07-22",
    "closeData": "",
    "isin": "INF204KA11J8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135087",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-07-22",
    "closeData": "",
    "isin": "INF204KA13J4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135088",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135089",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135090",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135091",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1104 Days Plan O Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135092",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-07-24",
    "closeData": "",
    "isin": "INF204KA14J2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135093",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 1- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-07-24",
    "closeData": "",
    "isin": "INF204KA15J9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135094",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 1- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-07-24",
    "closeData": "",
    "isin": "INF204KA16J7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135095",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 1- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-07-24",
    "closeData": "",
    "isin": "INF204KA17J5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "135106",
    "name": "SBI Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty 50 ETF",
    "minAmount": "5000 and in multiples of Re.1 thereaft(During NFO)",
    "launchDate": "2015-07-20",
    "closeData": "",
    "isin": "INF200KA1FS1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135107",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-25",
    "closeData": "",
    "isin": "INF109KB1KQ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135108",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-25",
    "closeData": "",
    "isin": "INF109KB1KN3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135109",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-25",
    "closeData": "",
    "isin": "INF109KB1KP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135110",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1101 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-25",
    "closeData": "",
    "isin": "INF109KB1KO1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135113",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135114",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1101 Days Plan P Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "139520",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Direct Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01XP6",
    "isinRe": "INF846K01XQ4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "139521",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Regular Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01XR2",
    "isinRe": "INF846K01XS0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135120",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VJ3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135121",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VK1",
    "isinRe": "INF846K01VL9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135122",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VO3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135123",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VP0",
    "isinRe": "INF846K01VQ8"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135124",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VM7",
    "isinRe": "INF846K01VN5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135125",
    "name": "Axis Equity Saver Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Axis Equity Saver Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-07-27",
    "closeData": "2015-08-10",
    "isin": "INF846K01VR6",
    "isinRe": "INF846K01VS4"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135157",
    "name": "Reliance Fixed Horizon Fund XXIX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 2 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": "INF204KA18J3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135158",
    "name": "Reliance Fixed Horizon Fund XXIX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": "INF204KA10K8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135159",
    "name": "Reliance Fixed Horizon Fund XXIX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 2 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": "INF204KA19J1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135160",
    "name": "Reliance Fixed Horizon Fund XXIX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": "INF204KA11K6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135175",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135176",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135177",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135178",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1170 Days Plan Q Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135179",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN H - 1105 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1105 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-04",
    "closeData": "",
    "isin": "INF109KB1KZ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135180",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN H - 1105 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1105 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-04",
    "closeData": "",
    "isin": "INF109KB1LA8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135181",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN H - 1105 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1105 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-04",
    "closeData": "",
    "isin": "INF109KB1LB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135182",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN H - 1105 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1105 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-04",
    "closeData": "",
    "isin": "INF109KB1LC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135192",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN I - 1290 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1290 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-08",
    "closeData": "",
    "isin": "INF109KB1LN1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135193",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN I - 1290 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1290 Days Plan I Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-08",
    "closeData": "",
    "isin": "INF109KB1LM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135194",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN I - 1290 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1290 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-08",
    "closeData": "",
    "isin": "INF109KB1LO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135195",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN I - 1290 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII - 1290 Days Plan I Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-08",
    "closeData": "",
    "isin": "INF109KB1LL5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135212",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-08-12",
    "closeData": "",
    "isin": "INF204KA12K4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135213",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 3- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-08-12",
    "closeData": "",
    "isin": "INF204KA13K2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135214",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 3- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-08-12",
    "closeData": "",
    "isin": "INF204KA15K7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135215",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 3- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-08-12",
    "closeData": "",
    "isin": "INF204KA14K0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135216",
    "name": "ICICI Prudential India Recovery Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 3 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-11",
    "closeData": "",
    "isin": "INF109KB1LQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135217",
    "name": "ICICI Prudential India Recovery Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 3 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-11",
    "closeData": "",
    "isin": "INF109KB1LP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135322",
    "name": "ICICI Prudential India Recovery Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 3 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-11",
    "closeData": "",
    "isin": "INF109KB1ME8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135323",
    "name": "ICICI Prudential India Recovery Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 3 Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-11",
    "closeData": "",
    "isin": "INF109KB1MD0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135218",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN J - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1284 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-13",
    "closeData": "",
    "isin": "INF109KB1LU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135219",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN J - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1284 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-13",
    "closeData": "",
    "isin": "INF109KB1LR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135220",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN J - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1284 Days Plan J Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-13",
    "closeData": "",
    "isin": "INF109KB1LT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135221",
    "name": "ICICI Prudential Capital Protection Oriented Fund VIII - PLAN J - 1284 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series VIII -1284 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-13",
    "closeData": "",
    "isin": "INF109KB1LS0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135228",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF204KA11L4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135229",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF204KA10L6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135230",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF204KA19K9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135231",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan B-Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF204KA18K1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135236",
    "name": "Kotak FMP Series 178 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 178 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-08-17",
    "closeData": "",
    "isin": "INF174K01T95",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135237",
    "name": "Kotak FMP Series 178 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 178 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-08-17",
    "closeData": "",
    "isin": "INF174K01U01",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135238",
    "name": "Kotak FMP Series 178 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 178 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-08-17",
    "closeData": "",
    "isin": "INF174K01U19",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135239",
    "name": "Kotak FMP Series 178 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 178 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-08-17",
    "closeData": "",
    "isin": "INF174K01T87",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135240",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135241",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135242",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135243",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1162 Days Plan R Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135286",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-25",
    "closeData": "",
    "isin": "INF109KB1MB4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135287",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-25",
    "closeData": "",
    "isin": "INF109KB1MA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135288",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-08-25",
    "closeData": "",
    "isin": "INF109KB1MC2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135289",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1151 Days Plan S Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-08-25",
    "closeData": "",
    "isin": "INF109KB1LZ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135301",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II - I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-I (1998 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF789FB1W18",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135302",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II - I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-I (1998 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF789FB1W34",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135303",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II - I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-I (1998 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF789FB1W00",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135304",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II - I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-I (1998 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-08-14",
    "closeData": "",
    "isin": "INF789FB1W26",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135316",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF109KB1MH1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135317",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF109KB1MI9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135318",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF109KB1MF5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135319",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1144 Days Plan T Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF109KB1MG3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135320",
    "name": "UTI Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI Nifty 50 ETF",
    "minAmount": "During NFO Rs 5000 and in multiple of Rs1 /-",
    "launchDate": "2015-08-24",
    "closeData": "",
    "isin": "INF789FB1X41",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135321",
    "name": "UTI S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI S&P BSE Sensex ETF",
    "minAmount": "During NFO Rs5000/- and in multiple of Rs1/-",
    "launchDate": "2015-08-24",
    "closeData": "",
    "isin": "INF789FB1X58",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135328",
    "name": "Kotak Capital Protection Oriented Scheme Series 1",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 1 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-09-07",
    "closeData": "",
    "isin": "INF174K01U43",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135329",
    "name": "Kotak Capital Protection Oriented Scheme Series 1",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 1 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-09-07",
    "closeData": "",
    "isin": "INF174K01U35",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135330",
    "name": "Kotak Capital Protection Oriented Scheme Series 1",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 1 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-09-07",
    "closeData": "",
    "isin": "INF174K01U27",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135331",
    "name": "Kotak Capital Protection Oriented Scheme Series 1",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 1 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-09-07",
    "closeData": "",
    "isin": "INF174K01U50",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135332",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-04",
    "closeData": "",
    "isin": "INF204KA14M6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135333",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-04",
    "closeData": "",
    "isin": "INF204KA15M3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135334",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 6- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-04",
    "closeData": "",
    "isin": "INF204KA16M1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135335",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 6- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-04",
    "closeData": "",
    "isin": "INF204KA17M9",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "135341",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund) - Direct Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01BE4",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "135342",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Direct Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01BF1",
    "isinRe": "INF666M01BG9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "135343",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund) - Regular Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01BH7",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "135344",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Regular Plan - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01BI5",
    "isinRe": "INF666M01BJ3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140819",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Direct Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01DC4",
    "isinRe": "INF666M01DD2"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140820",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Regular Plan- Half Yearly -Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01DI1",
    "isinRe": "INF666M01DJ9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140815",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01CY0",
    "isinRe": "INF666M01CZ7"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140816",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment.",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01DE0",
    "isinRe": "INF666M01DF7"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140817",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Direct Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01DA8",
    "isinRe": "INF666M01DB6"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "140818",
    "name": "Groww Value Fund (formerly known as Indiabulls Value Fund)",
    "category": "Equity Scheme - Value Fund",
    "navName": "Groww Value Fund (formerly known as Indiabulls Value Fund)- Regular Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "500",
    "launchDate": "2015-09-03",
    "closeData": "2015-09-07",
    "isin": "INF666M01DG5",
    "isinRe": "INF666M01DH3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135353",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135354",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1173 Days Plan U Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135355",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan U Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135356",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1134 Days Plan U Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135357",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-II (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-II (1997 days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF789FB1Z23",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135358",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-II (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-II (1997 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF789FB1Z49",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135359",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-II (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-II (1997 Days) - Direct Plan- Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF789FB1Z56",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135360",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-II (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-II (1997 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-09-01",
    "closeData": "",
    "isin": "INF789FB1Z31",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135372",
    "name": "Kotak FMP Series 179",
    "category": "Income",
    "navName": "Kotak FMP Series 179 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-09-09",
    "closeData": "",
    "isin": "INF174K01U68",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135373",
    "name": "Kotak FMP Series 179",
    "category": "Income",
    "navName": "Kotak FMP Series 179 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-09-09",
    "closeData": "",
    "isin": "INF174K01U76",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135374",
    "name": "Kotak FMP Series 179",
    "category": "Income",
    "navName": "Kotak FMP Series 179 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-09-09",
    "closeData": "",
    "isin": "INF174K01U92",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135375",
    "name": "Kotak FMP Series 179",
    "category": "Income",
    "navName": "Kotak FMP Series 179 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-09-09",
    "closeData": "",
    "isin": "INF174K01U84",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135392",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF204KA16N9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135393",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 7- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF204KA14N4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135394",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 7- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF204KA13N6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135395",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF204KA15N1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135396",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135397",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135398",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135399",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1123 Days Plan V Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135400",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-22",
    "closeData": "",
    "isin": "INF109KB1MS8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135401",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-22",
    "closeData": "",
    "isin": "INF109KB1MT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135402",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-22",
    "closeData": "",
    "isin": "INF109KB1MU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135403",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1129 Days Plan W Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-22",
    "closeData": "",
    "isin": "INF109KB1MR0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135404",
    "name": "ICICI Prudential Business Cycle Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 1 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF109KB1MV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135405",
    "name": "ICICI Prudential Business Cycle Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 1 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF109KB1MW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135406",
    "name": "ICICI Prudential Business Cycle Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 1  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF109KB1MX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135407",
    "name": "ICICI Prudential Business Cycle Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 1 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-18",
    "closeData": "",
    "isin": "INF109KB1MY6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135414",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-23",
    "closeData": "",
    "isin": "INF204KA19N3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135415",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 8- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-23",
    "closeData": "",
    "isin": "INF204KA18N5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135416",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 8- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-23",
    "closeData": "",
    "isin": "INF204KA17N7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135417",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-23",
    "closeData": "",
    "isin": "INF204KA10O0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135424",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135425",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X Regular Plan Dividen Option",
    "minAmount": "5000",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135426",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135427",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1116 Days Plan X Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135428",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135429",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135430",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135431",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 77 - 1415 Days Plan Y Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135432",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": "INF204KA14O2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135433",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": "INF204KA13O4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135434",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": "INF204KA12O6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135435",
    "name": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund VIII- Plan C- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2015-09-28",
    "closeData": "",
    "isin": "INF204KA11O8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135440",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 9- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": "INF204KA19O1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135441",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 9- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": "INF204KA18O3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135442",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": "INF204KA10P7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135443",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 9- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-09-29",
    "closeData": "",
    "isin": "INF204KA17O5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135480",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 10- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-08",
    "closeData": "",
    "isin": "INF204KA14P9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135481",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-08",
    "closeData": "",
    "isin": "INF204KA11P5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135482",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 10- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-08",
    "closeData": "",
    "isin": "INF204KA13P1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135483",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-08",
    "closeData": "",
    "isin": "INF204KA14P9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135484",
    "name": "Kotak FMP Series 180",
    "category": "Income",
    "navName": "Kotak FMP Series 180 (1099 Days) - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-10-07",
    "closeData": "",
    "isin": "INF174K01Y23",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135485",
    "name": "Kotak FMP Series 180",
    "category": "Income",
    "navName": "Kotak FMP Series 180 (1099 Days) - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-10-07",
    "closeData": "",
    "isin": "INF174K01Y31",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135486",
    "name": "Kotak FMP Series 180",
    "category": "Income",
    "navName": "Kotak FMP Series 180 (1099 Days) - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-10-07",
    "closeData": "",
    "isin": "INF174K01Y49",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135488",
    "name": "Kotak FMP Series 180",
    "category": "Income",
    "navName": "Kotak FMP Series 180 (1099 Days) - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-10-07",
    "closeData": "",
    "isin": "INF174K01Y15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135496",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN A - 1101 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1101 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-09",
    "closeData": "",
    "isin": "INF109KB1NU2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135493",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN A - 1101 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1101 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-09",
    "closeData": "",
    "isin": "INF109KB1NW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135494",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN A - 1101 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1101 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-09",
    "closeData": "",
    "isin": "INF109KB1NT4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135495",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN A - 1101 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1101 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-09",
    "closeData": "",
    "isin": "INF109KB1NV0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "135506",
    "name": "SBI EQUITY OPPORTUNITIES FUND - SERIES IV",
    "category": "Growth",
    "navName": "SBI EQUITY OPPORTUNITIES FUND - SERIES IV - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-10-07",
    "closeData": "2015-10-21",
    "isin": "INF200KA1GN0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "135507",
    "name": "SBI EQUITY OPPORTUNITIES FUND - SERIES IV",
    "category": "Growth",
    "navName": "SBI EQUITY OPPORTUNITIES FUND -  SERIES IV - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-10-07",
    "closeData": "2015-10-21",
    "isin": "INF200KA1GP5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "135508",
    "name": "SBI EQUITY OPPORTUNITIES FUND - SERIES IV",
    "category": "Growth",
    "navName": "SBI Equity Opportunities Fund-Series IV - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-10-07",
    "closeData": "2015-10-21",
    "isin": "INF200KA1GQ3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "135509",
    "name": "SBI EQUITY OPPORTUNITIES FUND - SERIES IV",
    "category": "Growth",
    "navName": "SBI Equity Opportunities Fund-Series IV - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2015-10-07",
    "closeData": "2015-10-21",
    "isin": "INF200KA1GO8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135510",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-III (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-III (1998 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-10-05",
    "closeData": "",
    "isin": "INF789FB10E9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135511",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-III (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-III (1998 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-10-05",
    "closeData": "",
    "isin": "INF789FB11E7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135512",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-III (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-III (1998 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-10-05",
    "closeData": "",
    "isin": "INF789FB12E5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135513",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-III (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-III (1998 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-10-05",
    "closeData": "",
    "isin": "INF789FB13E3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135543",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN B - 1103 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX -1103 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-19",
    "closeData": "",
    "isin": "INF109KB1OE4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135544",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN B - 1103 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX -1103 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-19",
    "closeData": "",
    "isin": "INF109KB1OC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135545",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN B - 1103 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX -1103 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-19",
    "closeData": "",
    "isin": "INF109KB1OB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135546",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN B - 1103 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX -1103 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-19",
    "closeData": "",
    "isin": "INF109KB1OD6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135547",
    "name": "ICICI Prudential Business Cycle Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 2 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-20",
    "closeData": "",
    "isin": "INF109KB1OF1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135548",
    "name": "ICICI Prudential Business Cycle Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 2 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-20",
    "closeData": "",
    "isin": "INF109KB1OG9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135549",
    "name": "ICICI Prudential Business Cycle Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 2  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-10-20",
    "closeData": "",
    "isin": "INF109KB1OI5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135550",
    "name": "ICICI Prudential Business Cycle Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 2  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-10-20",
    "closeData": "",
    "isin": "INF109KB1OH7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135559",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 13- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-23",
    "closeData": "",
    "isin": "INF204KA15Q4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135560",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-23",
    "closeData": "",
    "isin": "INF204KA13Q9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135561",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 13- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-23",
    "closeData": "",
    "isin": "INF204KA16Q2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135562",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 13- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-10-23",
    "closeData": "",
    "isin": "INF204KA14Q7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "135598",
    "name": "PGIM India ELSS Tax Saver Fund (Earlier known as PGIM India Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "PGIM India ELSS Tax Saver Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2015-10-19",
    "closeData": "2015-12-04",
    "isin": "INF663L01FQ8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "135599",
    "name": "PGIM India ELSS Tax Saver Fund (Earlier known as PGIM India Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "PGIM India ELSS Tax Saver Fund - Regular Plan - Dividend Option",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2015-10-19",
    "closeData": "2015-12-04",
    "isin": "INF663L01FR6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "135600",
    "name": "PGIM India ELSS Tax Saver Fund (Earlier known as PGIM India Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "PGIM India ELSS Tax Saver Fund - Direct Plan - Dividend Option",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2015-10-19",
    "closeData": "2015-12-04",
    "isin": "INF663L01FO3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "135601",
    "name": "PGIM India ELSS Tax Saver Fund (Earlier known as PGIM India Long Term Equity Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "PGIM India ELSS Tax Saver Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs.500/- thereafter.",
    "launchDate": "2015-10-19",
    "closeData": "2015-12-04",
    "isin": "INF663L01FN5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135607",
    "name": "LIC MF Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "LIC MF Nifty 50 ETF",
    "minAmount": "Rs5000 & thereafter multiples of Rs1 during NFO",
    "launchDate": "2015-11-02",
    "closeData": "2015-11-16",
    "isin": "INF767K01OS7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135608",
    "name": "Kotak FMP Series 181",
    "category": "Income",
    "navName": "Kotak FMP Series 181 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-10-27",
    "closeData": "",
    "isin": "INF174K01Z55",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135609",
    "name": "Kotak FMP Series 181",
    "category": "Income",
    "navName": "Kotak FMP Series 181 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-10-27",
    "closeData": "",
    "isin": "INF174K01Z48",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135610",
    "name": "Kotak FMP Series 181",
    "category": "Income",
    "navName": "Kotak FMP Series 181 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-10-27",
    "closeData": "",
    "isin": "INF174K01Z30",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135611",
    "name": "Kotak FMP Series 181",
    "category": "Income",
    "navName": "Kotak FMP Series 181 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-10-27",
    "closeData": "",
    "isin": "INF174K01Z63",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135634",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 14-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-05",
    "closeData": "",
    "isin": "INF204KA18Q8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135635",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 14- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-05",
    "closeData": "",
    "isin": "INF204KA17Q0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135636",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 14- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-05",
    "closeData": "",
    "isin": "INF204KA10R3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135637",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 14-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-05",
    "closeData": "",
    "isin": "INF204KA19Q6",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135652",
    "name": "Navi ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Navi ELSS Tax Saver Fund - Regular Plan IDCW Payout",
    "minAmount": "Rs. 500/-",
    "launchDate": "2015-11-09",
    "closeData": "2015-12-21",
    "isin": "INF959L01CG8",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135653",
    "name": "Navi ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Navi ELSS Tax Saver Fund - Direct Plan IDCW Payout",
    "minAmount": "Rs. 500/-",
    "launchDate": "2015-11-09",
    "closeData": "2015-12-21",
    "isin": "INF959L01CE3",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135654",
    "name": "Navi ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Navi ELSS Tax Saver Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 500/-",
    "launchDate": "2015-11-09",
    "closeData": "2015-12-21",
    "isin": "INF959L01CD5",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135655",
    "name": "Navi ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Navi ELSS Tax Saver Fund- Regular Plan- Growth Option",
    "minAmount": "Rs. 500/-",
    "launchDate": "2015-11-09",
    "closeData": "2015-12-21",
    "isin": "INF959L01CF0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "135672",
    "name": "LIC MF S & P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "LIC MF S & P BSE Sensex ETF",
    "minAmount": "Rs5000 & thereafter multiples of Rs1 during NFO",
    "launchDate": "2015-11-09",
    "closeData": "2015-11-23",
    "isin": "INF767K01OT5",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135677",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CH6",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135678",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund- Regular Plan- Growth Option",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CK0",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135679",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund - Direct Normal IDCW Payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CI4",
    "isinRe": "INF959L01CJ2"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "135680",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund - Regular Normal IDCW Payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CL8",
    "isinRe": "INF959L01CM6"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "141410",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "NAVI Large & Midcap Fund - Regular Half Yearly IDCW Payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CT1",
    "isinRe": "INF959L01CU9"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "141411",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund - Regular Annual IDCW payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CV7",
    "isinRe": "INF959L01CW5"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "141414",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund - Direct Annual IDCW Payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CR5",
    "isinRe": "INF959L01CS3"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "141415",
    "name": "Navi Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Navi Large & Midcap Fund - Direct Half Yearly IDCW Payout",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2015-11-16",
    "closeData": "2015-11-30",
    "isin": "INF959L01CP9",
    "isinRe": "INF959L01CQ7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "135681",
    "name": "SUNDARAM LONG TERM TAX ADVANTAGE SERIES II",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series II Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-11-03",
    "closeData": "",
    "isin": "INF903J012W7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "135682",
    "name": "SUNDARAM LONG TERM TAX ADVANTAGE SERIES II",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series II Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-03",
    "closeData": "",
    "isin": "INF903J011W9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "135683",
    "name": "SUNDARAM LONG TERM TAX ADVANTAGE SERIES II",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series II Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2015-11-03",
    "closeData": "",
    "isin": "INF903J010W1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "135684",
    "name": "SUNDARAM LONG TERM TAX ADVANTAGE SERIES II",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series II Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-03",
    "closeData": "",
    "isin": "INF903J019V4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135717",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-11-19",
    "closeData": "",
    "isin": "INF109KB1ON5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135718",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-11-19",
    "closeData": "",
    "isin": "INF109KB1OP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135719",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-11-19",
    "closeData": "",
    "isin": "INF109KB1OQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135720",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days - Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1775 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-11-19",
    "closeData": "",
    "isin": "INF109KB1OO3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135744",
    "name": "Kotak Nifty 50 Value 20 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty 50 Value 20 ETF - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2015-11-12",
    "closeData": "",
    "isin": "INF174K01Z71",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135745",
    "name": "Kotak Capital Protection Oriented Scheme Series 2",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 2 - Dividend",
    "minAmount": "5000",
    "launchDate": "2015-11-16",
    "closeData": "",
    "isin": "INF174K01Z97",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135746",
    "name": "Kotak Capital Protection Oriented Scheme Series 2",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 2 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-16",
    "closeData": "",
    "isin": "INF174K010A6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135747",
    "name": "Kotak Capital Protection Oriented Scheme Series 2",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 2 - Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-16",
    "closeData": "",
    "isin": "INF174K01Z89",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135748",
    "name": "Kotak Capital Protection Oriented Scheme Series 2",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 2 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-11-16",
    "closeData": "",
    "isin": "INF174K011A4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135755",
    "name": "Kotak FMP Series 182",
    "category": "Income",
    "navName": "Kotak FMP Series 182 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-20",
    "closeData": "",
    "isin": "INF174K012A2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135756",
    "name": "Kotak FMP Series 182",
    "category": "Income",
    "navName": "Kotak FMP Series 182 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-11-20",
    "closeData": "",
    "isin": "INF174K013A0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135757",
    "name": "Kotak FMP Series 182",
    "category": "Income",
    "navName": "Kotak FMP Series 182 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-11-20",
    "closeData": "",
    "isin": "INF174K014A8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135758",
    "name": "Kotak FMP Series 182",
    "category": "Income",
    "navName": "Kotak FMP Series 182 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-11-20",
    "closeData": "",
    "isin": "INF174K015A5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135759",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - Lock in - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WJ1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135760",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - Lock in - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WK9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135761",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - No Lock in - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WN3",
    "isinRe": "INF846K01WL7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135762",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - Lock in - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WO1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135763",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - No Lock in - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WS2",
    "isinRe": "INF846K01WQ6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135764",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - No Lock in - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WR4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135765",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - Lock in - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WP8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "135766",
    "name": "Axis Children's Gift Fund",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "Axis Children's Gift Fund - No Lock in - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2015-11-18",
    "closeData": "2015-12-02",
    "isin": "INF846K01WM5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135767",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 16- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-27",
    "closeData": "",
    "isin": "INF204KA11S9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135768",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 16- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-27",
    "closeData": "",
    "isin": "INF204KA12S7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135769",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 16- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-27",
    "closeData": "",
    "isin": "INF204KA10S1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135770",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 16",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 16- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-11-27",
    "closeData": "",
    "isin": "INF204KA19R4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "135781",
    "name": "Mirae Asset Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mirae Asset Tax Saver Fund-Direct Plan -Growth",
    "minAmount": "500",
    "launchDate": "2015-11-20",
    "closeData": "2015-12-18",
    "isin": "INF769K01DM9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "135782",
    "name": "Mirae Asset Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mirae Asset Tax Saver Fund Direct IDCW",
    "minAmount": "500",
    "launchDate": "2015-11-20",
    "closeData": "2015-12-18",
    "isin": "INF769K01DN7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "135783",
    "name": "Mirae Asset Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mirae Asset Tax Saver Fund Regular IDCW",
    "minAmount": "500",
    "launchDate": "2015-11-20",
    "closeData": "2015-12-18",
    "isin": "INF769K01DL1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "135784",
    "name": "Mirae Asset Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mirae Asset Tax Saver Fund-Regular Plan-Growth",
    "minAmount": "500",
    "launchDate": "2015-11-20",
    "closeData": "2015-12-18",
    "isin": "INF769K01DK3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135792",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Banking & Financial Services Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z36",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135793",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Banking And Financial Services Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z10",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135794",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Banking And Financial Services Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Y86",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135789",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Banking & Financial Services Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z28",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135790",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Banking & Financial Services Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Y94",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135791",
    "name": "Tata Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Banking & Financial Services Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z02",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135795",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Digital India Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z85",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135796",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Digital India Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z51",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135797",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Digital India Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z44",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135798",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Digital India Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z93",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135799",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Digital India Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z69",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135800",
    "name": "Tata Digital India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Digital India Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter .",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K01Z77",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135801",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Consumer Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K015A1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135802",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Consumer Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K011A0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135803",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Consumer Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K012A8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135804",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata India Consumer Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K010A2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135805",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata India Consumer Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K013A6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135806",
    "name": "Tata India Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Consumer Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K014A4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135807",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Pharma & Health Care Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K010B0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135808",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Pharma & Healthcare Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K011B8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135809",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Pharma & Health Care Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K017A7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135810",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata India Pharma & Healthcare Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K019A3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135811",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA India Pharma & Healthcare Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K018A5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135812",
    "name": "Tata India Pharma & Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata India Pharma & Healthcare Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K016A9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135813",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Resources & Energy Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K015B9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135814",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Resources & Energy Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K013B4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135815",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Resources & Energy Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K012B6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135816",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Resources & Energy Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K017B5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135817",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Resources & Energy Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K016B7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "135818",
    "name": "Tata Resources & Energy Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Resources & Energy Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000 & in multiples of Re.1/- thereafter.",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF277K014B2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135845",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF109KB1OU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135846",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF109KB1OR6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135847",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF109KB1OT2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135848",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1212 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF109KB1OS4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135849",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN C - 1195 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1195 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-08",
    "closeData": "",
    "isin": "INF109KB1OX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135850",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN C - 1195 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1195 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-08",
    "closeData": "",
    "isin": "INF109KB1OY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135851",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN C - 1195 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1195 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-08",
    "closeData": "",
    "isin": "INF109KB1OV8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135852",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN C - 1195 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1195 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-08",
    "closeData": "",
    "isin": "INF109KB1OW6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "135853",
    "name": "HDFC NIFTY 50 Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY 50 Exchange Traded Fund - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2015-11-30",
    "closeData": "2015-12-02",
    "isin": "INF179KC1965",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "135854",
    "name": "HDFC S&P BSE SENSEX Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC S&P BSE Sensex Exchange Traded Fund - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2015-11-30",
    "closeData": "2015-12-02",
    "isin": "INF179KC1973",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "135859",
    "name": "Edelweiss ETF - Nifty Bank",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Edelweiss ETF - Nifty Bank",
    "minAmount": "5000",
    "launchDate": "2015-12-14",
    "closeData": "2015-12-14",
    "isin": "INF754K01EL1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135860",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series II-IV (1997 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF789FB19J9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135861",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-IV (1997 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF789FB10K6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135862",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-IV (1997 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF789FB18J1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135863",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series II-IV (1997 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2015-12-04",
    "closeData": "",
    "isin": "INF789FB11K4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135864",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 18- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF204KA17S6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135865",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 18- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF204KA18S4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135866",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 18- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF204KA19S2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135867",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 18- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF204KA10T9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135884",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 19- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-12-21",
    "closeData": "",
    "isin": "INF204KA11T7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135885",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 19- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-12-21",
    "closeData": "",
    "isin": "INF204KA13T3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135886",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 19- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-12-21",
    "closeData": "",
    "isin": "INF204KA14T1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135887",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 19- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2015-12-21",
    "closeData": "",
    "isin": "INF204KA12T5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135888",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135889",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135890",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135891",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1198 Days Plan B Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135906",
    "name": "Kotak FMP Series 183",
    "category": "Income",
    "navName": "Kotak FMP Series 183 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF174K010B4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135907",
    "name": "Kotak FMP Series 183",
    "category": "Income",
    "navName": "Kotak FMP Series 183 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF174K011B2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135908",
    "name": "Kotak FMP Series 183",
    "category": "Income",
    "navName": "Kotak FMP Series 183 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF174K013B8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135909",
    "name": "Kotak FMP Series 183",
    "category": "Income",
    "navName": "Kotak FMP Series 183 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-12-17",
    "closeData": "",
    "isin": "INF174K012B0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135910",
    "name": "Kotak FMP Series 184 (1205 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 184 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF174K015B3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135911",
    "name": "Kotak FMP Series 184 (1205 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 184 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF174K017B9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135912",
    "name": "Kotak FMP Series 184 (1205 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 184 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF174K016B1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "135913",
    "name": "Kotak FMP Series 184 (1205 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 184 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF174K014B6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "135914",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Growth",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1L81",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "135915",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Periodic IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1L99",
    "isinRe": "INF194KA1M07"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "135916",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Growth",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1M23",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "135917",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Periodic IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1M31",
    "isinRe": "INF194KA1M49"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139198",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Monthly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1S43",
    "isinRe": "INF194KA1S50"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139199",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Quarterly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1S76",
    "isinRe": "INF194KA1S84"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139200",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Half Yearly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1T00",
    "isinRe": "INF194KA1T18"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139201",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Direct Annual IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1T34",
    "isinRe": "INF194KA1T42"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139202",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Monthly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1R28",
    "isinRe": "INF194KA1R36"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139203",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Half Yearly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1R85",
    "isinRe": "INF194KA1R93"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139204",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Annual IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1S19",
    "isinRe": "INF194KA1S27"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139205",
    "name": "BANDHAN Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BANDHAN Corporate Bond Fund - Regular Quarterly IDCW",
    "minAmount": "Fresh Purchase - Rs.5000/- and in multiple of Re1",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF194KA1R51",
    "isinRe": "INF194KA1R69"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135924",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-26",
    "closeData": "",
    "isin": "INF109KB1PE1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135925",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-26",
    "closeData": "",
    "isin": "INF109KB1PG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135926",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-26",
    "closeData": "",
    "isin": "INF109KB1PF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135927",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days - Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-26",
    "closeData": "",
    "isin": "INF109KB1PD3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135928",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF109KB1PJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135929",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF109KB1PK8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135930",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF109KB1PH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135931",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2015-12-28",
    "closeData": "",
    "isin": "INF109KB1PI2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135938",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 20- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": "INF204KA15T8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135939",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 20- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": "INF204KA16T6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135940",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 20- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": "INF204KA18T2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "135941",
    "name": "Reliance Fixed Horizon Fund XXIX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXIX- Series 20- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": "INF204KA17T4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135958",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135959",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135960",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135961",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1197 Days Plan D Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135962",
    "name": "UTI - Long Term Advantage Fund Series III",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series III - Direct Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF789FB19N1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135963",
    "name": "UTI - Long Term Advantage Fund Series III",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series III - Regular Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF789FB16N7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135964",
    "name": "UTI - Long Term Advantage Fund Series III",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series III - Regular Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF789FB17N5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "135965",
    "name": "UTI - Long Term Advantage Fund Series III",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series III - Direct Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2015-12-18",
    "closeData": "",
    "isin": "INF789FB18N3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135972",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN D - 1378 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1378 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-04",
    "closeData": "",
    "isin": "INF109KB1PR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135973",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN D - 1378 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1378 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-04",
    "closeData": "",
    "isin": "INF109KB1PS1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135974",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN D - 1378 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1378 Days Plan D Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-04",
    "closeData": "",
    "isin": "INF109KB1PP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135975",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN D - 1378 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1378 Days Plan D Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-04",
    "closeData": "",
    "isin": "INF109KB1PQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135992",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-07",
    "closeData": "",
    "isin": "INF109KB1PV5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135993",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-07",
    "closeData": "",
    "isin": "INF109KB1PW3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135994",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-07",
    "closeData": "",
    "isin": "INF109KB1PU7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135995",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1190 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-07",
    "closeData": "",
    "isin": "INF109KB1PT9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135996",
    "name": "ICICI Prudential Business Cycle Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 3 Cumulative option",
    "minAmount": "5000",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF109KB1PX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135997",
    "name": "ICICI Prudential Business Cycle Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 3 Dividend option",
    "minAmount": "5000",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF109KB1PY9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135998",
    "name": "ICICI Prudential Business Cycle Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 3 Direct Plan Cumulative option",
    "minAmount": "5000",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF109KB1PZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "135999",
    "name": "ICICI Prudential Business Cycle Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Business Cycle Fund - Series 3 Direct Plan Dividend option",
    "minAmount": "5000",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF109KB1QA7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136000",
    "name": "Reliance Fixed Horizon Fund XXX - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 1 -Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF204KA11U5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136001",
    "name": "Reliance Fixed Horizon Fund XXX - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 1 -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF204KA19T0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136002",
    "name": "Reliance Fixed Horizon Fund XXX - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 1 -Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF204KA12U3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136003",
    "name": "Reliance Fixed Horizon Fund XXX - Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 1 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-08",
    "closeData": "",
    "isin": "INF204KA10U7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "136004",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES III",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series III - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-12-31",
    "closeData": "2016-03-30",
    "isin": "INF200KA1HL2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "136005",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES III",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series III - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-12-31",
    "closeData": "2016-03-30",
    "isin": "INF200KA1HM0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "136006",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES III",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series III - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-12-31",
    "closeData": "2016-03-30",
    "isin": "INF200KA1HO6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "136007",
    "name": "SBI LONG TERM ADVANTAGE FUND - SERIES III",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series III - Direct Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2015-12-31",
    "closeData": "2016-03-30",
    "isin": "INF200KA1HN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136030",
    "name": "Reliance Fixed Horizon Fund XXX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 2 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA14U9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136031",
    "name": "Reliance Fixed Horizon Fund XXX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 2 -Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA15U6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136028",
    "name": "Reliance Fixed Horizon Fund XXX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 2 -Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA16U4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136029",
    "name": "Reliance Fixed Horizon Fund XXX - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 2 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA13U1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136046",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-11",
    "closeData": "",
    "isin": "INF109KB1QD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136047",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-11",
    "closeData": "",
    "isin": "INF109KB1QB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136048",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-11",
    "closeData": "",
    "isin": "INF109KB1QC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136049",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1185 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-11",
    "closeData": "",
    "isin": "INF109KB1QE9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136056",
    "name": "Reliance Fixed Horizon Fund XXX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX-Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA11V3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136057",
    "name": "Reliance Fixed Horizon Fund XXX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX-Series 3- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA14V7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136058",
    "name": "Reliance Fixed Horizon Fund XXX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX-Series 3- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA13V9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136059",
    "name": "Reliance Fixed Horizon Fund XXX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX-Series 3- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-15",
    "closeData": "",
    "isin": "INF204KA12V1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136072",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136073",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136074",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136075",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1185 Days Plan C Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136082",
    "name": "Kotak FMP Series 185",
    "category": "Income",
    "navName": "Kotak FMP Series 185 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2016-01-12",
    "closeData": "",
    "isin": "INF174K019B5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136083",
    "name": "Kotak FMP Series 185",
    "category": "Income",
    "navName": "Kotak FMP Series 185 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2016-01-12",
    "closeData": "",
    "isin": "INF174K010C2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136084",
    "name": "Kotak FMP Series 185",
    "category": "Income",
    "navName": "Kotak FMP Series 185 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2016-01-12",
    "closeData": "",
    "isin": "INF174K018B7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136085",
    "name": "Kotak FMP Series 185",
    "category": "Income",
    "navName": "Kotak FMP Series 185 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2016-01-12",
    "closeData": "",
    "isin": "INF174K011C0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136090",
    "name": "HDFC Retirement Savings Fund - Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Equity Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MG8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136094",
    "name": "HDFC Retirement Savings Fund - Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Equity Plan - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MF0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136121",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-V (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-V (1997 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": "INF789FB10P5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136122",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-V (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series II-V (1997 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": "INF789FB11P3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136123",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-V (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series II-V (1997 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": "INF789FB12P1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136124",
    "name": "UTI - Dual Advantage Fixed Term Fund Series II-V (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series II-V (1997 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-01-18",
    "closeData": "",
    "isin": "INF789FB13P9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136125",
    "name": "Reliance Fixed Horizon Fund XXX - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 4 -Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF204KA18V8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136126",
    "name": "Reliance Fixed Horizon Fund XXX - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 4 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF204KA16V2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136127",
    "name": "Reliance Fixed Horizon Fund XXX - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 4 -Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF204KA17V0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136128",
    "name": "Reliance Fixed Horizon Fund XXX - Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 4 -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF204KA15V4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136133",
    "name": "Kotak FMP Series 186",
    "category": "Income",
    "navName": "Kotak FMP Series 186 - Regular Dividend",
    "minAmount": "5000",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF174KA1DV2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136134",
    "name": "Kotak FMP Series 186",
    "category": "Income",
    "navName": "Kotak FMP Series 186 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF174KA1DU4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136135",
    "name": "Kotak FMP Series 186",
    "category": "Income",
    "navName": "Kotak FMP Series 186 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF174KA1DW0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136136",
    "name": "Kotak FMP Series 186",
    "category": "Income",
    "navName": "Kotak FMP Series 186 - Direct Dividend",
    "minAmount": "5000",
    "launchDate": "2016-01-29",
    "closeData": "",
    "isin": "INF174KA1DX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136151",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136152",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136153",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136154",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1103 Days Plan D Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136155",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G Regular Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136156",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136157",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136158",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1175 Days Plan G Regular Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136170",
    "name": "Reliance Fixed Horizon Fund XXX- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-09",
    "closeData": "",
    "isin": "INF204KA19V6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136171",
    "name": "Reliance Fixed Horizon Fund XXX- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 5- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-09",
    "closeData": "",
    "isin": "INF204KA10W3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136172",
    "name": "Reliance Fixed Horizon Fund XXX- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 5- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-09",
    "closeData": "",
    "isin": "INF204KA12W9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136173",
    "name": "Reliance Fixed Horizon Fund XXX- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 5- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-09",
    "closeData": "",
    "isin": "INF204KA11W1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136180",
    "name": "ICICI Prudential India Recovery Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 4 Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": "INF109KB1QX9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136181",
    "name": "ICICI Prudential India Recovery Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 4 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": "INF109KB1QY7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136182",
    "name": "ICICI Prudential India Recovery Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 4 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": "INF109KB1QW1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136183",
    "name": "ICICI Prudential India Recovery Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 4 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": "INF109KB1QV3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136184",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136185",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136186",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136187",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1174 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136220",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF109KB1VT7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136221",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF109KB1VV3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136222",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF109KB1VU5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136223",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan K Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF109KB1VW1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136224",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-11",
    "closeData": "",
    "isin": "INF109KB1RD9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136225",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-11",
    "closeData": "",
    "isin": "INF109KB1RE7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136226",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-11",
    "closeData": "",
    "isin": "INF109KB1RF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136227",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1170 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-11",
    "closeData": "",
    "isin": "INF109KB1RG2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136228",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136229",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136230",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136231",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1168 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RS7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136232",
    "name": "Reliance Fixed Horizon Fund XXX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF204KA14W5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136233",
    "name": "Reliance Fixed Horizon Fund XXX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 6- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF204KA15W2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136234",
    "name": "Reliance Fixed Horizon Fund XXX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 6- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF204KA16W0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136235",
    "name": "Reliance Fixed Horizon Fund XXX- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF204KA13W7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136236",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RU3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136237",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136238",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RV1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136239",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 188 Days Plan L Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-15",
    "closeData": "",
    "isin": "INF109KB1RT5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136246",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-02-16",
    "closeData": "",
    "isin": "INF204KA19W4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136247",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-02-16",
    "closeData": "",
    "isin": "INF204KA17W8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136248",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-02-16",
    "closeData": "",
    "isin": "INF204KA10X1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136249",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan A- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-02-16",
    "closeData": "",
    "isin": "INF204KA18W6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136270",
    "name": "KOTAK FMP SERIES 187",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 187 - REGULAR DIVIDEND",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K017C7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136271",
    "name": "KOTAK FMP SERIES 187",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 187 - DIRECT GROWTH",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K018C5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136272",
    "name": "KOTAK FMP SERIES 187",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 187 - DIRECT DIVIDEND",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K019C3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136273",
    "name": "KOTAK FMP SERIES 187",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 187 - REGULAR GROWTH",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K016C9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136274",
    "name": "KOTAK FMP SERIES 188",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 188 - DIRECT DIVIDEND",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K013D4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136275",
    "name": "KOTAK FMP SERIES 188",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 188 - DIRECT GROWTH",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K012D6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136276",
    "name": "KOTAK FMP SERIES 188",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 188 - REGULAR DIVIDEND",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K011D8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136277",
    "name": "KOTAK FMP SERIES 188",
    "category": "Income",
    "navName": "KOTAK FMP SERIES 188 - REGULAR GROWTH",
    "minAmount": "200000000",
    "launchDate": "2016-02-10",
    "closeData": "",
    "isin": "INF174K010D0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136284",
    "name": "Kotak Capital Protection Oriented Scheme Series 3",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme Series 3 - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-26",
    "isin": "INF174K010E8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136285",
    "name": "Kotak Capital Protection Oriented Scheme Series 3",
    "category": "Income",
    "navName": "Kotak Capitak Protection Oriented Scheme Series 3 Direct Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-26",
    "isin": "INF174K011E6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136286",
    "name": "Kotak Capital Protection Oriented Scheme Series 3",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme Series 3 Regular Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-26",
    "isin": "INF174K018D3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136287",
    "name": "Kotak Capital Protection Oriented Scheme Series 3",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme Series 3 Regular Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-26",
    "isin": "INF174K019D1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136294",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -1150 Days Plan N Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-18",
    "closeData": "",
    "isin": "INF109KB1SB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136295",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -1150 Days Plan N Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-18",
    "closeData": "",
    "isin": "INF109KB1SD7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136296",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -1150 Days Plan N Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-18",
    "closeData": "",
    "isin": "INF109KB1SC9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136297",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -1150 Days Plan N Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-18",
    "closeData": "",
    "isin": "INF109KB1SE5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136298",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan M Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "",
    "isin": "INF109KB1SA3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136299",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "",
    "isin": "INF109KB1RX7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136300",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "",
    "isin": "INF109KB1RZ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136301",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -95 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 95 Days Plan M Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "",
    "isin": "INF109KB1RY5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136305",
    "name": "KOTAK FMP SERIES 189",
    "category": "Income",
    "navName": "Kotak FMP Series 189 - Regular - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-16",
    "isin": "INF174K015D9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136306",
    "name": "KOTAK FMP SERIES 189",
    "category": "Income",
    "navName": "Kotak FMP Series 189 - Direct - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-16",
    "isin": "INF174K017D5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136307",
    "name": "KOTAK FMP SERIES 189",
    "category": "Income",
    "navName": "Kotak FMP Series 189 - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-16",
    "isin": "INF174K016D7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136308",
    "name": "KOTAK FMP SERIES 189",
    "category": "Income",
    "navName": "Kotak FMP Series 189 - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-12",
    "closeData": "2016-02-16",
    "isin": "INF174K014D2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136309",
    "name": "Reliance Fixed Horizon Fund XXX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA11X9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136310",
    "name": "Reliance Fixed Horizon Fund XXX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA12X7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136311",
    "name": "Reliance Fixed Horizon Fund XXX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA13X5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136312",
    "name": "Reliance Fixed Horizon Fund XXX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 7- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA14X3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136313",
    "name": "Reliance Fixed Horizon Fund XXX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 8- Direct Plan- Dividen Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA18X4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136314",
    "name": "Reliance Fixed Horizon Fund XXX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA15X0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136315",
    "name": "Reliance Fixed Horizon Fund XXX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 8- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA17X6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136316",
    "name": "Reliance Fixed Horizon Fund XXX- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF204KA16X8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136317",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1145 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1145 Days Plan O Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136318",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1145 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1145 Days Plan O Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136319",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1145 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1145 Days Plan O Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136320",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1145 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1145 Days Plan O Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136327",
    "name": "Reliance Fixed Horizon Fund XXX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-24",
    "closeData": "",
    "isin": "INF204KA19X2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136328",
    "name": "Reliance Fixed Horizon Fund XXX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 9- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-24",
    "closeData": "",
    "isin": "INF204KA10Y9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136329",
    "name": "Reliance Fixed Horizon Fund XXX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 9- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-24",
    "closeData": "",
    "isin": "INF204KA11Y7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136330",
    "name": "Reliance Fixed Horizon Fund XXX- Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 9- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-02-24",
    "closeData": "",
    "isin": "INF204KA12Y5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136362",
    "name": "Kotak FMP Series 190",
    "category": "Income",
    "navName": "Kotak FMP Series 190 - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "2016-02-17",
    "isin": "INF174K014E0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136363",
    "name": "Kotak FMP Series 190",
    "category": "Income",
    "navName": "Kotak FMP Series 190 - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "2016-02-17",
    "isin": "INF174K012E4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136364",
    "name": "Kotak FMP Series 190",
    "category": "Income",
    "navName": "Kotak FMP Series 190 - Regular - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "2016-02-17",
    "isin": "INF174K013E2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "136365",
    "name": "Kotak FMP Series 190",
    "category": "Income",
    "navName": "Kotak FMp Series 190 - Direct - Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2016-02-17",
    "closeData": "2016-02-17",
    "isin": "INF174K015E7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136366",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1147 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1147 Days Plan P Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136367",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1147 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1147 Days Plan P Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136368",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1147 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1147 Days Plan P Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136369",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -1147 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1147 Days Plan P Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136370",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN E - 1325 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1325 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": "INF109KB1SP1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136371",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN E - 1325 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1325 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": "INF109KB1SO4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136372",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN E - 1325 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1325 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": "INF109KB1SN6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136373",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN E - 1325 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1325 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-02-25",
    "closeData": "",
    "isin": "INF109KB1SQ9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136417",
    "name": "UTI - Dual Advantage Fixed Term Fund Series III-I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series III-I (1998 Days) - Regular Plan - IDCW",
    "minAmount": "Sunil Patil and V. Srivasta",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF789FB18V6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136418",
    "name": "UTI - Dual Advantage Fixed Term Fund Series III-I (1998 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series III-I (1998 Days) - Direct Plan - IDCW",
    "minAmount": "Sunil Patil and V. Srivasta",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF789FB10W1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136419",
    "name": "UTI - Dual Advantage Fixed Term Fund Series III-I (1998 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series III-I (1998 Days) - Direct Plan - Growth Option",
    "minAmount": "Sunil Patil and V. Srivasta",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF789FB11W9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "136420",
    "name": "UTI - Dual Advantage Fixed Term Fund Series III-I (1998 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series III-I (1998 Days) - Regular Plan - Growth Option",
    "minAmount": "Sunil Patil and V. Srivasta",
    "launchDate": "2016-02-22",
    "closeData": "",
    "isin": "INF789FB19V4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136435",
    "name": "Reliance Fixed Horizon Fund XXX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 10- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": "INF204KA16Y6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136436",
    "name": "Reliance Fixed Horizon Fund XXX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": "INF204KA13Y3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136437",
    "name": "Reliance Fixed Horizon Fund XXX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": "INF204KA14Y1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136438",
    "name": "Reliance Fixed Horizon Fund XXX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 10- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": "INF204KA15Y8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136445",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136446",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 -97 Days Plan Q Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136449",
    "name": "Reliance Fixed Horizon Fund XXX- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA17Y4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136450",
    "name": "Reliance Fixed Horizon Fund XXX- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 11- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA10Z6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136451",
    "name": "Reliance Fixed Horizon Fund XXX- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 11- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA18Y2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136452",
    "name": "Reliance Fixed Horizon Fund XXX- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 11- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA19Y0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136453",
    "name": "Reliance Fixed Horizon Fund XXX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 12- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA13Z0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136454",
    "name": "Reliance Fixed Horizon Fund XXX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 12- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA14Z8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136455",
    "name": "Reliance Fixed Horizon Fund XXX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 12- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA11Z4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136456",
    "name": "Reliance Fixed Horizon Fund XXX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 12- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-04",
    "closeData": "",
    "isin": "INF204KA12Z2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136459",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": "INF109KB1TA1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136460",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": "INF109KB1TC7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136461",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": "INF109KB1TB9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136462",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1127 Days Plan R Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": "INF109KB1SZ0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136463",
    "name": "HDFC Retirement Savings Fund - Hybrid-Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Hybrid-Equity Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MI4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136464",
    "name": "HDFC Retirement Savings Fund - Hybrid-Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Hybrid-Equity Plan - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MH6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136465",
    "name": "HDFC Retirement Savings Fund - Hybrid-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Hybrid-Debt Plan - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MJ2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "136466",
    "name": "HDFC Retirement Savings Fund - Hybrid-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "HDFC Retirement Savings Fund - Hybrid-Debt Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-02-05",
    "closeData": "2016-02-19",
    "isin": "INF179KB1MK0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "136473",
    "name": "LIC MF Nifty 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "LIC MF Nifty 100 ETF",
    "minAmount": "Rs. 5000 & in multiples of Rs. 1 thereafter.",
    "launchDate": "2016-03-04",
    "closeData": "2016-03-11",
    "isin": "INF767K01PC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136505",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136506",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136507",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "136508",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 91 Days Plan S Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136509",
    "name": "Reliance Fixed Horizon Fund XXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 13 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-11",
    "closeData": "",
    "isin": "INF204KB1098",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136510",
    "name": "Reliance Fixed Horizon Fund XXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 13 -Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-11",
    "closeData": "",
    "isin": "INF204KB1106",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136511",
    "name": "Reliance Fixed Horizon Fund XXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 13 -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-11",
    "closeData": "",
    "isin": "INF204KB1080",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136512",
    "name": "Reliance Fixed Horizon Fund XXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 13 -Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-11",
    "closeData": "",
    "isin": "INF204KB1114",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136519",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF204KA18Z9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136520",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF204KA17Z1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136521",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF204KA16Z3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "136522",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan B- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF204KA15Z5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136565",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Regular Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1462",
    "isinRe": "INF740KA1470"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136566",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Regular Plan - IDCW - Quarterly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1488",
    "isinRe": "INF740KA1496"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136567",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1504",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136568",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1512",
    "isinRe": "INF740KA1520"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136569",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Direct Plan - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1538",
    "isinRe": "INF740KA1546"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136570",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Direct Plan - IDCW - Quarterly",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1553",
    "isinRe": "INF740KA1561"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136563",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1439",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "136564",
    "name": "DSP Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "DSP Equity Savings Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2016-03-08",
    "closeData": "2016-03-22",
    "isin": "INF740KA1447",
    "isinRe": "INF740KA1454"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "138224",
    "name": "Reliance Fixed Horizon Fund XXX - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 14 -Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF204KB1023",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "138225",
    "name": "Reliance Fixed Horizon Fund XXX - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 14 -Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF204KB1015",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "138226",
    "name": "Reliance Fixed Horizon Fund XXX - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 14 -Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF204KB1031",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "138227",
    "name": "Reliance Fixed Horizon Fund XXX - Series 14",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 14 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF204KA19Z7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138275",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Direct Plan - Half Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01WI7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138276",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Direct Plan - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01XC8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138277",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01XB0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138269",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138270",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01OW5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138271",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01PB6",
    "isinRe": "INF223J01PC4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138272",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Direct Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01OZ8",
    "isinRe": "INF223J01PA8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138273",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01OT1",
    "isinRe": "INF223J01OU9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138274",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Half Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01WH9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138263",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DHFL Pramerica Short Maturity Fund - Premium Plus Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138264",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DW8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138265",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01KB7",
    "isinRe": "INF223J01KC5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138266",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01KD3",
    "isinRe": "INF223J01KE1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138267",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "DHFL Pramerica Short Duration Fund - Institutional Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138268",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01OX3",
    "isinRe": "INF223J01OY1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138257",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Institutional Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DG1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138258",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Institutional Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138259",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Institutional Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138260",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Premium Plus Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DP2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138261",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Premium Plus - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DR8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138262",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Premium Plus - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DN7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138254",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DU2",
    "isinRe": "INF223J01DV0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138255",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Maturity Fund - Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DX6",
    "isinRe": "INF223J01DY4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138256",
    "name": "PGIM India Short Duration Fund (Earlier known as PGIM India Short Maturity Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "PGIM India Short Duration Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-15",
    "isin": "INF223J01DT4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138278",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund-DIVIDEND-WEEKLY DIVIDEND",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BN1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138279",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund-DIVIDEND-MONTHLY DIVIDEND",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BR2",
    "isinRe": "INF223J01BS0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138304",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India INSTA CASH FUND-Monthly Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01VD0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138305",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Direct Plan -  Annual Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01WW8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138306",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India INSTA CASH FUND - Annual Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01WV0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138298",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NT3",
    "isinRe": "INF223J01NU1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138299",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NS5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138300",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NX5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138301",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Direct Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NV9",
    "isinRe": "INF223J01NW7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138302",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Direct Plan -  Regular Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NQ9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138303",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Direct Plan -Monthly Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01VE8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138292",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund- Annual Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01IP1",
    "isinRe": "INF223J01IQ9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138293",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DHFL Pramerica Insta cash Fund - Institutional Plan - Quarterly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138294",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Quarterly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01IN6",
    "isinRe": "INF223J01IO4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138295",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DHFL Pramerica Insta cash Fund - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138296",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NO4",
    "isinRe": "INF223J01NP1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138297",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Direct Plan - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01NR7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138286",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BQ4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138287",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BL5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138288",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Growth",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BP6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138289",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BU6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138290",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DHFL Pramerica Insta Cash Fund - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138291",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "DHFL Pramerica Insta Cash Fund - Annual Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138280",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund-DIVIDEND-Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BI1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138281",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund-Growth-GROWTH",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BJ9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138282",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund-Institutional Plan-Growth",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BB6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138283",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Insta Cash Fund-Institutional Plan-Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BA8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138284",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India INSTA CASH FUND - Regular Plan BONUS OPTION",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BH3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138285",
    "name": "PGIM India Liquid Fund (Earlier known as PGIM India Insta Cash Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "PGIM India Liquid Fund - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1 thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01BO9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138307",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF663L01GU8",
    "isinRe": "INF663L01GV6"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138308",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF663L01GW4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138309",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund Wealth Plan -Dividend Option",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01242",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138310",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund Wealth Plan - Growth Option",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01267",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138311",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - BONUS OPTION",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01895",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138312",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF663L01GR4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138313",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - Direct Plan - Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF663L01GS2",
    "isinRe": "INF663L01GT0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138314",
    "name": "PGIM India Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "PGIM India Large Cap Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- & in multiples of Rs.1/- thereafter",
    "launchDate": "2003-01-14",
    "closeData": "",
    "isin": "INF223J01MM0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138315",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01DE6",
    "isinRe": "INF223J01DF3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138316",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01DC0",
    "isinRe": "INF223J01DD8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138317",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01CY6",
    "isinRe": "INF223J01CZ3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138318",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01DB2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138319",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Institutional Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138332",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01OR5",
    "isinRe": "INF223J01OS3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138333",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Direct Plan - Half-Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01WG1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138334",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Half-Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01WF3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138335",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DHFL Pramerica Premier Bond Fund - Direct Plan - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138336",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01WZ1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138326",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DHFL Pramerica Premier Bond Fund - Premium Plus Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138327",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DHFL Pramerica Premier Bond Fund - Premium Plus Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138328",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DHFL Pramerica Premier Bond Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138329",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01OM6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138330",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01OO2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138331",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01OP9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138320",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Institutional Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138321",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Institutional Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138322",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Institutional Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138323",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Corporate Bond Fund - Premium Plus Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01CQ2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138324",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "PGIM India Premier Bond Fund - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": "INF223J01DA4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138325",
    "name": "PGIM India Corporate Bond Fund (Earlier Known as PGIM India Premier Bond Fund)",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DHFL Pramerica Premier Bond Fund - Premium Plus Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2003-01-14",
    "closeData": "2003-01-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138337",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FX1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138338",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Regular Plan - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FW3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138339",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Regular Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01GC3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138340",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Regular Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FY9",
    "isinRe": "INF223J01FZ6"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138341",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FO0",
    "isinRe": "INF223J01FP7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138342",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FH4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138367",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Annual Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01XI5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138368",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Annual Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01XH7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138361",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Direct Plan - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QL3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138362",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Direct Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QR0",
    "isinRe": "INF223J01QS8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138363",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QK5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138364",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QT6",
    "isinRe": "INF223J01QU4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138365",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Monthly Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01VJ7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138366",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Monthly Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01VK5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138355",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Premium Plus Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138356",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Premium Plus Plan - Growth",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138357",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QM1",
    "isinRe": "INF223J01QN9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138358",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QO7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138359",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QP4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138360",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01QI9",
    "isinRe": "INF223J01QJ7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138349",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FI2",
    "isinRe": "INF223J01FJ0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138350",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Premium Plus Plan - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138351",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Regular Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138352",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Regular Plan - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138353",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01JH6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138354",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01JJ2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138343",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Growth",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FK8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138344",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Duration Fund - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FL6",
    "isinRe": "INF223J01FM4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138345",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01FN2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138346",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "PGIM India Ultra Short Term Fund - Regular Plan - Bonus",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": "INF223J01GA7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138347",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Premium Plus Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138348",
    "name": "PGIM India Ultra Short Duration Fund (Earlier known as PGIM India Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "DHFL Pramerica Ultra Short Term Fund - Premium Plus Plan - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of Rs. 1 thereafter",
    "launchDate": "2006-09-01",
    "closeData": "2006-09-01",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138369",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Monthly Dividend option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01FC5",
    "isinRe": "INF223J01FD3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138370",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Quarterly  Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01FE1",
    "isinRe": "INF223J01FF8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138371",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Annual Dividend option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01EZ9",
    "isinRe": "INF223J01FA9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138372",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01FB7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138375",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01QA6",
    "isinRe": "INF223J01QB4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138376",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01QD0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138377",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01QE8",
    "isinRe": "INF223J01QF5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138378",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01QG3",
    "isinRe": "INF223J01QH1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142425",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Regular Plan - Half yearly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YK2",
    "isinRe": "INF663L01YL0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142426",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Regular Plan - Regular Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YO4",
    "isinRe": "INF663L01YP1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142427",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Half yearly Dividend option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YM8",
    "isinRe": "INF663L01YN6"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142428",
    "name": "PGIM India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "PGIM India Equity Savings Fund - Direct Plan - Regular Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YQ9",
    "isinRe": "INF663L01YR7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138381",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01HA8",
    "isinRe": "INF663L01HB6"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138382",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01HC4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138383",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund Wealth Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01325",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138384",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund Wealth Plan- Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01309",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142419",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Regular Plan - Half yearly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YW7",
    "isinRe": "INF663L01YX5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142420",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Direct Plan - Haly yearly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01YY3",
    "isinRe": "INF663L01YZ0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142421",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01ZA0",
    "isinRe": "INF663L01ZB8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142422",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01ZC6",
    "isinRe": "INF663L01ZD4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "139822",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund-Quarterly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01OC0",
    "isinRe": "INF663L01OD8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "139823",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund-Direct Plan-Quarterly Divdend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01OE6",
    "isinRe": "INF663L01OF3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138385",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - BONUS OPTION",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF223J01903",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138386",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01GX2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138387",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund - Direct Plan - Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01GY0",
    "isinRe": "INF663L01GZ7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138388",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "DHFL Pramerica Hybrid Equity Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "139820",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund-Direct Plan-Monthly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01OA4",
    "isinRe": "INF663L01OB2"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "139821",
    "name": "PGIM India Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "PGIM India Hybrid Equity Fund-Monthly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2004-01-15",
    "closeData": "",
    "isin": "INF663L01NY6",
    "isinRe": "INF663L01NZ3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138423",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - GR",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HV4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138424",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund- MONTHLY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HR2",
    "isinRe": "INF663L01HS0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138425",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund- WEEKLY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01IE8",
    "isinRe": "INF663L01IF5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138426",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - FORTNIGHTLY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HT8",
    "isinRe": "INF663L01HU6"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138427",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund INSTITUTIONAL -GR",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JN7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138428",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DHFL Pramerica Low Duration Fund INSTITUTIONAL PLAN BONUS OPTION",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149812",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149813",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149806",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149807",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Direct Plan - Quarterly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149808",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149809",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Regular Plan- Annual Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149810",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1 - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149811",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Segregated Portfolio 1- Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138447",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Monthly Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JR8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138448",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Monthly Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JT4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138449",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan -  Annual  Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JS6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138450",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund  -  Annual  Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HD2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138451",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DHFL Pramerica Low Duration Fund - Direct Dividend - Reinvestment",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138452",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HW2",
    "isinRe": "INF663L01HX0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138441",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Daily Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HF7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138442",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Fortnightly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HM3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138443",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HE0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138444",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HO9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138445",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HI1",
    "isinRe": "INF663L01HJ9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138446",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Weekly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HG5",
    "isinRe": "INF663L01HH3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138435",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund- Bonus Option",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JQ0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138436",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - QUARTERLY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01IC2",
    "isinRe": "INF663L01ID0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138437",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - ANNUAL DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HY8",
    "isinRe": "INF663L01HZ5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138438",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund DIV OPTION",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01IA6",
    "isinRe": "INF663L01IB4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138439",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HK7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138440",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138429",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund INSTITUTIONAL - MONTHLY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF223J01986",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138430",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund - DAILY DIV",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01HQ4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138431",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund INSTITUTIONAL -Weekly",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JP2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138432",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "PGIM India Low Duration Fund INSTITUTIONAL-Daily",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": "INF663L01JO5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138433",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DHFL Pramerica Low Duration Fund INSTITUTIONAL -Fortnightly",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138434",
    "name": "PGIM India Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "DHFL Pramerica Low Duration Fund- INSTITUTIONAL DIVIDEND",
    "minAmount": "Rs. 5000/-and in multiples of 1/- thereafter.",
    "launchDate": "2007-06-20",
    "closeData": "2007-06-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138453",
    "name": "PGIM India Emerging Markets Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Emerging Markets Equity Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2007-07-31",
    "closeData": "2007-08-29",
    "isin": "INF223J01AY0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138454",
    "name": "PGIM India Emerging Markets Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Emerging Markets Equity Fund - Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2007-07-31",
    "closeData": "2007-08-29",
    "isin": "INF223J01AW4",
    "isinRe": "INF223J01AX2"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138456",
    "name": "PGIM India Emerging Markets Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Emerging Markets Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2007-07-31",
    "closeData": "2007-08-29",
    "isin": "INF223J01NL0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138457",
    "name": "PGIM India Emerging Markets Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Emerging Markets Equity Fund - Direct Plan - Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2007-07-31",
    "closeData": "2007-08-29",
    "isin": "INF223J01NJ4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138458",
    "name": "PGIM India Emerging Markets Equity Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Emerging Markets Equity Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2007-07-31",
    "closeData": "2007-08-29",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138467",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01AO1",
    "isinRe": "INF223J01AP8"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138468",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DHFL Pramerica Gilt Fund - Institutional Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138469",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Institutional Plan - Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138470",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01AQ6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138477",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DHFL Pramerica Gilt Fund - Direct Plan - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138478",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "DHFL Pramerica Gilt Fund - Annual Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138471",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01AR4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138472",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01ND7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138473",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Direct Plan -  Regular Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138474",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01NB1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138475",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Half Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01VL3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138476",
    "name": "PGIM India Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "PGIM India Gilt Fund - Direct Plan - Half Yearly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2008-10-07",
    "closeData": "2008-10-21",
    "isin": "INF223J01VM1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138523",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Equity Opportunities Fund - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": "INF223J01AU8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138524",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Equity Opportunities Fund - Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": "INF223J01AS2",
    "isinRe": "INF223J01AT0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138525",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Equity Opportunities Fund - Bonus",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": "INF223J01AV6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138526",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DHFL Pramerica Global Equity Opportunities Fund - Direct Plan - Bonus",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138527",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Equity Opportunities Fund - Direct Plan - Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": "INF223J01NG0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138528",
    "name": "PGIM India Global Equity Opportunities Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Equity Opportunities Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2010-04-06",
    "closeData": "2010-04-30",
    "isin": "INF223J01NF2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138563",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan- Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01SX4",
    "isinRe": "INF223J01SY2"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138564",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01SZ9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138565",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Monthly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01SU0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138566",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund -Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01SW6",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138567",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan- Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01WB2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138568",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan- Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01WD8",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138575",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan-  Quarterly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VG3",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138576",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Quarterly Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VF5",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138577",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan- Annual Bonus Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138579",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt Fund - Regular Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223JA1LR7",
    "isinRe": "INF223JA1LS5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138580",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt Fund - Direct Plan - Regular Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223JA1LT3",
    "isinRe": "INF223JA1LU1"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138569",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan- Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VZ3",
    "isinRe": "INF223J01WA4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138570",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Weekly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VT6",
    "isinRe": "INF223J01VU4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138571",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Quarterly Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VV2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138572",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Annual Dividend",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138573",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund - Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VH1",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138574",
    "name": "PGIM India Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "PGIM India Banking and PSU Debt fund- Direct Plan-  Regular Bonus",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2013-02-22",
    "closeData": "2013-03-05",
    "isin": "INF223J01VI9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138875",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J013N7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138876",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J019N4",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138877",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Regular Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J019M6",
    "isinRe": "INF223J014N5"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138878",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J011N1",
    "isinRe": "INF223J016N0"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138879",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J010N3",
    "isinRe": "INF223J015N2"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138880",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J012N9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142423",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Half yearly Dividend Option",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF663L01YS5",
    "isinRe": "INF663L01YT3"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "142424",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Direct Plan - Half yearly Dividend Option",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF663L01YU1",
    "isinRe": "INF663L01YV9"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138883",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Monthly Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J011M3",
    "isinRe": "INF223J015M4"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138884",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Quarterly Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J010M5",
    "isinRe": "INF223J014M7"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138885",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Annual Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J012M1",
    "isinRe": "INF223J016M2"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "138887",
    "name": "PGIM India Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "PGIM India Arbitrage Fund - Regular Plan - Regular Dividend",
    "minAmount": "Rs. 5000/- & in multiples of Re.1/- thereafter",
    "launchDate": "2014-08-13",
    "closeData": "2014-08-22",
    "isin": "INF223J019L8",
    "isinRe": "INF223J013M9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139077",
    "name": "KOTAK FMP SERIES 191",
    "category": "Income",
    "navName": "Kotak FMP Series 191 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-03-10",
    "closeData": "",
    "isin": "INF174K019E9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139078",
    "name": "KOTAK FMP SERIES 191",
    "category": "Income",
    "navName": "Kotak FMP Series 191 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-03-10",
    "closeData": "",
    "isin": "INF174K018E1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139079",
    "name": "KOTAK FMP SERIES 191",
    "category": "Income",
    "navName": "Kotak FMP Series 191 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-03-10",
    "closeData": "",
    "isin": "INF174K017E3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139080",
    "name": "KOTAK FMP SERIES 191",
    "category": "Income",
    "navName": "Kotak FMP Series 191 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-03-10",
    "closeData": "",
    "isin": "INF174K016E5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139084",
    "name": "Kotak FMP Series 192",
    "category": "Income",
    "navName": "Kotak FMP Series 192 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF174K010F5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139081",
    "name": "Kotak FMP Series 192",
    "category": "Income",
    "navName": "Kotak FMP Series 192 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF174K011F3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139082",
    "name": "Kotak FMP Series 192",
    "category": "Income",
    "navName": "Kotak FMP Series 192 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF174K013F9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139083",
    "name": "Kotak FMP Series 192",
    "category": "Income",
    "navName": "Kotak FMP Series 192 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF174K012F1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139091",
    "name": "HSBC Fixed Term Series 125 (1114 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 125 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF336L01KX7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139092",
    "name": "HSBC Fixed Term Series 125 (1114 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 125 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF336L01LA3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139093",
    "name": "HSBC Fixed Term Series 125 (1114 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 125 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF336L01KZ2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139094",
    "name": "HSBC Fixed Term Series 125 (1114 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 125 - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-14",
    "closeData": "",
    "isin": "INF336L01KY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139099",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TN4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139100",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TO2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139101",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TL8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139102",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1156 Days Plan U Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TM6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139103",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TP9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139104",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TR5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139105",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139106",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1135 Days Plan W Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-16",
    "closeData": "",
    "isin": "INF109KB1TQ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139107",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TD5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139108",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TE3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TF0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1130 Days Plan T Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TG8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TW5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139113",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139114",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1281 Days Plan V Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-17",
    "closeData": "",
    "isin": "INF109KB1TT1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139116",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN F - 1120 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1120 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-15",
    "closeData": "",
    "isin": "INF109KB1TI4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139117",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN F - 1120 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1120 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-15",
    "closeData": "",
    "isin": "INF109KB1TJ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139118",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN F - 1120 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1120 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-15",
    "closeData": "",
    "isin": "INF109KB1TK0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139119",
    "name": "ICICI Prudential Capital Protection Oriented Fund IX - PLAN F - 1120 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series IX - 1120 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-15",
    "closeData": "",
    "isin": "INF109KB1TH6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139143",
    "name": "Reliance Fixed Horizon Fund XXX- Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 17- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-22",
    "closeData": "",
    "isin": "INF204KB1163",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139144",
    "name": "Reliance Fixed Horizon Fund XXX- Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 17- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-22",
    "closeData": "",
    "isin": "INF204KB1171",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139145",
    "name": "Reliance Fixed Horizon Fund XXX- Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 17- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-22",
    "closeData": "",
    "isin": "INF204KB1189",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139146",
    "name": "Reliance Fixed Horizon Fund XXX- Series 17",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 17- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-03-22",
    "closeData": "",
    "isin": "INF204KB1197",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139155",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139156",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139157",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139158",
    "name": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 10 - 1825 Days Plan E Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139177",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-23",
    "closeData": "",
    "isin": "INF109KB1TY1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139178",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-23",
    "closeData": "",
    "isin": "INF109KB1TX3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139179",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-03-23",
    "closeData": "",
    "isin": "INF109KB1UA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139180",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1115 Days Plan X Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-03-23",
    "closeData": "",
    "isin": "INF109KB1TZ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139242",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139243",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139244",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139241",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1106 Days Plan Y Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139245",
    "name": "Reliance Fixed Horizon Fund XXX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 18- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1270",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139246",
    "name": "Reliance Fixed Horizon Fund XXX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 18- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1254",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139247",
    "name": "Reliance Fixed Horizon Fund XXX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 18- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1262",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139248",
    "name": "Reliance Fixed Horizon Fund XXX- Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 18- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1247",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139249",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1296",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139250",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1312",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139251",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1304",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139252",
    "name": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C",
    "category": "Income",
    "navName": "Reliance Dual Advantage Fixed Tenure Fund IX- Plan C- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-04-20",
    "closeData": "",
    "isin": "INF204KB1304",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139253",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF109KB1UN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139254",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF109KB1UO0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139255",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF109KB1UP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139256",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 78 - 1102 Days Plan Z Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF109KB1UQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139261",
    "name": "ICICI Prudential India Recovery Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 5 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-25",
    "closeData": "",
    "isin": "INF109KB1US1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139262",
    "name": "ICICI Prudential India Recovery Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 5 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-25",
    "closeData": "",
    "isin": "INF109KB1UR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139263",
    "name": "ICICI Prudential India Recovery Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 5 Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-25",
    "closeData": "",
    "isin": "INF109KB1UT9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139264",
    "name": "ICICI Prudential India Recovery Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 5 Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-25",
    "closeData": "",
    "isin": "INF109KB1UU7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139265",
    "name": "Reliance Fixed Horizon Fund XXX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 19- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-22",
    "closeData": "",
    "isin": "INF204KB1320",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139266",
    "name": "Reliance Fixed Horizon Fund XXX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 19- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-22",
    "closeData": "",
    "isin": "INF204KB1338",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139267",
    "name": "Reliance Fixed Horizon Fund XXX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 19- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-22",
    "closeData": "",
    "isin": "INF204KB1346",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139268",
    "name": "Reliance Fixed Horizon Fund XXX- Series 19",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 19- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-04-22",
    "closeData": "",
    "isin": "INF204KB1353",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139269",
    "name": "Kotak FMP Series 193",
    "category": "Income",
    "navName": "Kotak FMP Series 193 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF174K014F7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139270",
    "name": "Kotak FMP Series 193",
    "category": "Income",
    "navName": "Kotak FMP Series 193 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF174K017F0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139271",
    "name": "Kotak FMP Series 193",
    "category": "Income",
    "navName": "Kotak FMP Series 193 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF174K016F2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139272",
    "name": "Kotak FMP Series 193",
    "category": "Income",
    "navName": "Kotak FMP Series 193 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-04-12",
    "closeData": "",
    "isin": "INF174K015F4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139295",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-28",
    "closeData": "",
    "isin": "INF109KB1UZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139296",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-28",
    "closeData": "",
    "isin": "INF109KB1VA7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139297",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-04-28",
    "closeData": "",
    "isin": "INF109KB1VB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139298",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1218 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-04-28",
    "closeData": "",
    "isin": "INF109KB1VC3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139331",
    "name": "Kotak FMP Series 194",
    "category": "Income",
    "navName": "Kotak FMP Series 194 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-05-05",
    "closeData": "",
    "isin": "INF174K019F6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139332",
    "name": "Kotak FMP Series 194",
    "category": "Income",
    "navName": "Kotak FMP Series 194 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-05-05",
    "closeData": "",
    "isin": "INF174K010G3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139333",
    "name": "Kotak FMP Series 194",
    "category": "Income",
    "navName": "Kotak FMP Series 194 - Regular- Growth",
    "minAmount": "200000000",
    "launchDate": "2016-05-05",
    "closeData": "",
    "isin": "INF174K018F8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139334",
    "name": "Kotak FMP Series 194",
    "category": "Income",
    "navName": "Kotak FMP Series 194 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-05-05",
    "closeData": "",
    "isin": "INF174K011G1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139335",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1437",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139336",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1429",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139337",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1411",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139338",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan D- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1403",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139340",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-17",
    "closeData": "",
    "isin": "INF109KB1VM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139341",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-05-17",
    "closeData": "",
    "isin": "INF109KB1VN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139342",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-05-17",
    "closeData": "",
    "isin": "INF109KB1VL4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139343",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1105 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-17",
    "closeData": "",
    "isin": "INF109KB1VO8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139345",
    "name": "Reliance Fixed Horizon Fund XXX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 20- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1445",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139346",
    "name": "Reliance Fixed Horizon Fund XXX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 20- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1452",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139347",
    "name": "Reliance Fixed Horizon Fund XXX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 20- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1460",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139348",
    "name": "Reliance Fixed Horizon Fund XXX- Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXX- Series 20- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF204KB1478",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139359",
    "name": "Kotak Capital Protection Oriented Scheme Series 4",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 4 - Regular Dividend",
    "minAmount": "200000000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF174K013G7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139360",
    "name": "Kotak Capital Protection Oriented Scheme Series 4",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 4 - Regular Growth",
    "minAmount": "200000000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF174K012G9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139361",
    "name": "Kotak Capital Protection Oriented Scheme Series 4",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 4 - Direct Dividend",
    "minAmount": "200000000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF174K015G2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139362",
    "name": "Kotak Capital Protection Oriented Scheme Series 4",
    "category": "Income",
    "navName": "Kotak Capital Protection Oriented Scheme - Series 4 - Direct Growth",
    "minAmount": "200000000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF174K014G5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139363",
    "name": "HSBC Fixed Term Series 126 (1138 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 126 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF336L01LD7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139364",
    "name": "HSBC Fixed Term Series 126 (1138 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 126 - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF336L01LC9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139365",
    "name": "HSBC Fixed Term Series 126 (1138 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 126 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF336L01LB1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "139366",
    "name": "HSBC Fixed Term Series 126 (1138 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 126 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-16",
    "closeData": "",
    "isin": "INF336L01LE5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139368",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF109KB1VP5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139369",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF109KB1VQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139370",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF109KB1VS9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139371",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1235 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-05-20",
    "closeData": "",
    "isin": "INF109KB1VR1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139376",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-25",
    "closeData": "",
    "isin": "INF204KB1569",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139377",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 1- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-25",
    "closeData": "",
    "isin": "INF204KB1577",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139378",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 1- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-25",
    "closeData": "",
    "isin": "INF204KB1593",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139379",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 1",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 1- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-05-25",
    "closeData": "",
    "isin": "INF204KB1585",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139391",
    "name": "ICICI Prudential India Recovery Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 6 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139392",
    "name": "ICICI Prudential India Recovery Fund - Series 6",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 6 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-05-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139393",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AD8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139395",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV-XIV (1831 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AG1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139397",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1972",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139398",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AB2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139399",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV-XIV (1831 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139400",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AC0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139401",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AF3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139402",
    "name": "UTI - FTIF Series XXIV - XIV (1831 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXIV - XIV (1831 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-05-26",
    "closeData": "",
    "isin": "INF789FC1AA4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139408",
    "name": "ICICI Prudential India Recovery Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 7 Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-03",
    "closeData": "",
    "isin": "INF109KB1WJ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139409",
    "name": "ICICI Prudential India Recovery Fund - Series 7",
    "category": "Growth",
    "navName": "ICICI Prudential India Recovery Fund - Series 7 Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-03",
    "closeData": "",
    "isin": "INF109KB1WI8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139410",
    "name": "Reliance Fixed Horizon Fund XXXI Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI Series 2 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": "INF204KB1619",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139411",
    "name": "Reliance Fixed Horizon Fund XXXI Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": "INF204KB1635",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139412",
    "name": "Reliance Fixed Horizon Fund XXXI Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI Series 2 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": "INF204KB1601",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139413",
    "name": "Reliance Fixed Horizon Fund XXXI Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": "INF204KB1627",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "139430",
    "name": "SBI Nifty 10 yr Benchmark G-Sec ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty 10 yr Benchmark G-Sec ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2016-06-02",
    "closeData": "2016-06-08",
    "isin": "INF200KA1JT1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139435",
    "name": "Kotak FMP Series 195 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 195 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-06-06",
    "closeData": "",
    "isin": "INF174K012H7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139436",
    "name": "Kotak FMP Series 195 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 195 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-06-06",
    "closeData": "",
    "isin": "INF174K019G4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139437",
    "name": "Kotak FMP Series 195 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 195 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-06-06",
    "closeData": "",
    "isin": "INF174K010H1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139438",
    "name": "Kotak FMP Series 195 (1099 Days)",
    "category": "Income",
    "navName": "Kotak FMP Series 195 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-06-06",
    "closeData": "",
    "isin": "INF174K011H9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C  Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139449",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139450",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1126 Days Plan C Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139451",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-17",
    "closeData": "",
    "isin": "INF109KB1XD7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139452",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D  Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-17",
    "closeData": "",
    "isin": "INF109KB1XG0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139453",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D  Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-17",
    "closeData": "",
    "isin": "INF109KB1XF2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139454",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1140 Days Plan D  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-17",
    "closeData": "",
    "isin": "INF109KB1XE5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139455",
    "name": "ICICI Prudential Nifty50 Value 20 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty50 Value 20 ETF",
    "minAmount": "5000",
    "launchDate": "2016-06-13",
    "closeData": "",
    "isin": "INF109KB1WY5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139462",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF204KB1767",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139463",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF204KB1775",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139464",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 4- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF204KB1783",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139465",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 4- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF204KB1791",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139470",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B Cumulative Plan",
    "minAmount": "5000",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF109KB1WZ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139471",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B Direct Plan Cumulative Plan",
    "minAmount": "5000",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF109KB1XB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139472",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B Dividend Plan",
    "minAmount": "5000",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF109KB1XA3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139473",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series X - 1375 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-21",
    "closeData": "",
    "isin": "INF109KB1XC9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139474",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-22",
    "closeData": "",
    "isin": "INF109KB1XI6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139475",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-22",
    "closeData": "",
    "isin": "INF109KB1XH8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139476",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E  Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-06-22",
    "closeData": "",
    "isin": "INF109KB1XK2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139477",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1130 Days Plan E  Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-06-22",
    "closeData": "",
    "isin": "INF109KB1XJ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139496",
    "name": "Nippon India ETF Nifty 8-13 yr G-Sec Long Term Gilt",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 8-13 yr G-Sec Long Term Gilt",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2016-06-27",
    "closeData": "",
    "isin": "INF204KB1882",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139519",
    "name": "ICICI Prudential S&P BSE Midcap Select ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential S&P BSE Midcap Select ETF",
    "minAmount": "5000",
    "launchDate": "2016-06-28",
    "closeData": "",
    "isin": "INF109KB1XT3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "139527",
    "name": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND - DIRECT PLAN GROWTH",
    "minAmount": "5000",
    "launchDate": "2016-06-29",
    "closeData": "2016-07-13",
    "isin": "INF761K01DM6",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "139528",
    "name": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND - REGULAR  PLAN IDCW",
    "minAmount": "5000",
    "launchDate": "2016-06-29",
    "closeData": "2016-07-13",
    "isin": "INF761K01DQ7",
    "isinRe": "INF761K01DR5"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "139529",
    "name": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND - REGULAR  PLAN  GROWTH",
    "minAmount": "5000",
    "launchDate": "2016-06-29",
    "closeData": "2016-07-13",
    "isin": "INF761K01DP9",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "139530",
    "name": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND - DIRECT PLAN IDCW",
    "minAmount": "5000",
    "launchDate": "2016-06-29",
    "closeData": "2016-07-13",
    "isin": "INF761K01DN4",
    "isinRe": "INF761K01DO2"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139534",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01028",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139535",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01051",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139536",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01069",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139537",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01010",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139538",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01044",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139539",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Unclaimed Redemption / IDCW Upto three years",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139540",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Unclaimed Redemption / IDCW Beyond 3 years",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139541",
    "name": "Mahindra Manulife Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Mahindra Manulife Liquid Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2016-07-04",
    "closeData": "2016-07-04",
    "isin": "INF174V01036",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139542",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 5- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-11",
    "closeData": "",
    "isin": "INF204KB1AD9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139543",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 5- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-11",
    "closeData": "",
    "isin": "INF204KB1AE7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139544",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-11",
    "closeData": "",
    "isin": "INF204KB1AB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139545",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 5- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-11",
    "closeData": "",
    "isin": "INF204KB1AC1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139548",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139549",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139546",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139547",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan F Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139569",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AM0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139570",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AK4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139571",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AJ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139572",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan E- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AL2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139573",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AF4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139574",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AG2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139575",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 6- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AH0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139576",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 6- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-18",
    "closeData": "",
    "isin": "INF204KB1AI8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139577",
    "name": "Kotak FMP Series 196",
    "category": "Income",
    "navName": "Kotak FMP Series 196 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": "INF174K016H8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139578",
    "name": "Kotak FMP Series 196",
    "category": "Income",
    "navName": "Kotak FMP Series 196 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": "INF174K015H0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139579",
    "name": "Kotak FMP Series 196",
    "category": "Income",
    "navName": "Kotak FMP Series 196 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": "INF174K014H3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139580",
    "name": "Kotak FMP Series 196",
    "category": "Income",
    "navName": "Kotak FMP Series 196 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": "INF174K013H5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "139581",
    "name": "Aditya Birla Sun Life S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life S&P BSE Sensex ETF",
    "minAmount": "Rs 5000/- and in multiples of Rs 1 /- thereafter",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": "INF209KB10E8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139586",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139587",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139588",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139589",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1113 Days Plan G Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139590",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-19",
    "closeData": "",
    "isin": "INF109KB1YE3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139591",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-19",
    "closeData": "",
    "isin": "INF109KB1YF0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139592",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-19",
    "closeData": "",
    "isin": "INF109KB1YC7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139593",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan A 1427 Days Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-19",
    "closeData": "",
    "isin": "INF109KB1YD5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139594",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139595",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AO6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139596",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AP3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139597",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 7- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AQ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139600",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AT5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139601",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F-Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AR9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139598",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AU3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139599",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund IX- Plan F-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-07-21",
    "closeData": "",
    "isin": "INF204KB1AS7",
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "139616",
    "name": "Taurus Unclaimed Dividend & Redemption Liability (Less than 3 yrs)",
    "category": "Money Market",
    "navName": "Taurus Unclaimed Dividend - Growth",
    "minAmount": "NA",
    "launchDate": "2016-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "139617",
    "name": "Taurus Unclaimed Dividend & Redemption Liability (Less than 3 yrs)",
    "category": "Money Market",
    "navName": "Taurus Unclaimed Redemption - Growth",
    "minAmount": "NA",
    "launchDate": "2016-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "139618",
    "name": "Taurus Investor Education Pool (Greater than 3 yrs)",
    "category": "Money Market",
    "navName": "Taurus Investor Education Pool - Unclaimed Redemption - Growth",
    "minAmount": "NA",
    "launchDate": "2016-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Taurus Asset Management Company Limited",
    "code": "139619",
    "name": "Taurus Investor Education Pool (Greater than 3 yrs)",
    "category": "Money Market",
    "navName": "Taurus Investor Education Pool - Unclaimed Dividend - Growth",
    "minAmount": "NA",
    "launchDate": "2016-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139636",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139637",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139634",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139635",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1125 Days Plan H Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139638",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139639",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139640",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139641",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan I Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139642",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": "INF109KB1YO2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139643",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": "INF109KB1YQ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139644",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": "INF109KB1YR5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139645",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1120 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-07-27",
    "closeData": "",
    "isin": "INF109KB1YP9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139651",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF204KB1AZ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139652",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 8- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF204KB1BB1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139653",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF204KB1BA3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139654",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 8- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF204KB1BC9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139697",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139698",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139699",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139700",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan L Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139701",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": "INF109KB1YT1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139702",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": "INF109KB1YU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139703",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": "INF109KB1YV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139704",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1118 Days Plan K Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-09",
    "closeData": "",
    "isin": "INF109KB1YS3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139709",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series III Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF903JA1427",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139710",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series III Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF903JA1450",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139711",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series III Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF903JA1443",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139712",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series III Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2016-08-08",
    "closeData": "",
    "isin": "INF903JA1435",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139733",
    "name": "Nippon India Fixed Horizon Fund XXXI Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI Series 9 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF204KB1BT3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139734",
    "name": "Nippon India Fixed Horizon Fund XXXI Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF204KB1BW7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139735",
    "name": "Nippon India Fixed Horizon Fund XXXI Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF204KB1BV9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139736",
    "name": "Nippon India Fixed Horizon Fund XXXI Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI Series 9 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF204KB1BU1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139747",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Seires 79 - 1106 Days Plan M Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": "INF109KB1ZI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139748",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": "INF109KB1ZK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139749",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": "INF109KB1ZJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139750",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan M Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": "INF109KB1ZL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139751",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139752",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139753",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139754",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1106 Days Plan N Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139759",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139760",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139761",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZS0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139762",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan O Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139763",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139764",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139765",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139766",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan B 1394 Days Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF109KB1ZX0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139780",
    "name": "Mahindra Manulife ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mahindra Manulife ELSS Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF174V01085",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139781",
    "name": "Mahindra Manulife ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mahindra Manulife ELSS Fund - Direct Plan -Growth",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF174V01093",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139782",
    "name": "Mahindra Manulife ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mahindra Manulife ELSS Fund- Direct Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF174V01101",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "139783",
    "name": "Mahindra Manulife ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Mahindra Manulife ELSS Fund- Regular Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2016-08-22",
    "closeData": "",
    "isin": "INF174V01077",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139796",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 11- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-30",
    "closeData": "",
    "isin": "INF204KB1CT1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139797",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 11- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-30",
    "closeData": "",
    "isin": "INF204KB1CU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139798",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-30",
    "closeData": "",
    "isin": "INF204KB1CR5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139799",
    "name": "Reliance Fixed Horizon Fund XXXI- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXI- Series 11- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-08-30",
    "closeData": "",
    "isin": "INF204KB1CS3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139839",
    "name": "BANDHAN Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BANDHAN Nifty 50 ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/-",
    "launchDate": "2016-09-07",
    "closeData": "",
    "isin": "INF194KA1U07",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "139840",
    "name": "BANDHAN S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BANDHAN S&P BSE Sensex ETF",
    "minAmount": "Rs 5000 and multiples of Rs. 10/- thereafter for",
    "launchDate": "2016-09-07",
    "closeData": "",
    "isin": "INF194KA1T91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139841",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": "INF109KB1B48",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139842",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": "INF109KB1B63",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139843",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": "INF109KB1B71",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139844",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan P Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": "INF109KB1B55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139845",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139846",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139847",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139848",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1104 Days Plan Q Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139849",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 13- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-12",
    "closeData": "",
    "isin": "INF204KB1DE1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139850",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 13- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-12",
    "closeData": "",
    "isin": "INF204KB1DG6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139851",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 13- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-12",
    "closeData": "",
    "isin": "INF204KB1DF8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139852",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-12",
    "closeData": "",
    "isin": "INF204KB1DD3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139859",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-II (1831 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII -II  (1831 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF789FC1DO9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139860",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-II (1831 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series VIII-II (1831 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF789FC1DN1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139861",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-II (1831 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII - II (1831 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF789FC1DM3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139862",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-II (1831 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series VIII-II (1831 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF789FC1DL5",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139863",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund (MOFDYNAMIC) - Regular Plan - Annual Dividend Payout Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01601",
    "isinRe": "INF247L01627"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139865",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund Direct - Quarterly IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01643",
    "isinRe": "INF247L01668"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139866",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund Direct - Annual IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01650",
    "isinRe": "INF247L01676"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139870",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund (MOFDYNAMIC) - Regular Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01585",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139871",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund Regular - Quarterly IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01593",
    "isinRe": "INF247L01619"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "139872",
    "name": "Motilal Oswal Balance Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Motilal Oswal Balance Advantage Fund  (MOFDYNAMIC) - Direct Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-06",
    "closeData": "",
    "isin": "INF247L01635",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139885",
    "name": "Kotak FMP Series 198 (1102 days)",
    "category": "Income",
    "navName": "Kotak FMP Series 198 - Direct - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-09-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139886",
    "name": "Kotak FMP Series 198 (1102 days)",
    "category": "Income",
    "navName": "Kotak FMP Series 198 - Direct - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-09-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139887",
    "name": "Kotak FMP Series 198 (1102 days)",
    "category": "Income",
    "navName": "Kotak FMP Series 198 - Regular - Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2016-09-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "139888",
    "name": "Kotak FMP Series 198 (1102 days)",
    "category": "Income",
    "navName": "Kotak FMP Series 198 - Regular - Growth",
    "minAmount": "200000000",
    "launchDate": "2016-09-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139913",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139914",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139915",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139916",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 93 Days Plan X Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139917",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139918",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139919",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139920",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 181 Days Plan Y Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139921",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139922",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139923",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139924",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1408 Days Plan U Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139925",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139926",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139927",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139928",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1114 Days Plan V Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139930",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139931",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139932",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139929",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1119 Days Plan W Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139933",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139934",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139935",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139936",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1406 Days Plan R Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139940",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139937",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139938",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139939",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1399 Days Plan S Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139941",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": "INF109KB1C88",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139942",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": "INF109KB1C96",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139943",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": "INF109KB1C62",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139944",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 79 - 1404 Days Plan T Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-13",
    "closeData": "",
    "isin": "INF109KB1C70",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139963",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 15-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF204KB1EB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139964",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 15- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF204KB1ED1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139965",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 15- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF204KB1EE9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "139966",
    "name": "Nippon India Fixed Horizon Fund XXXI- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXI- Series 15- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF204KB1EC3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "139969",
    "name": "IDBI Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "IDBI Hybrid Equity Fund Growth Regular",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2016-10-03",
    "closeData": "2016-10-17",
    "isin": "INF397L01JG6",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "139970",
    "name": "IDBI Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "IDBI Hybrid Equity Fund IDCW Regular",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2016-10-03",
    "closeData": "2016-10-17",
    "isin": "INF397L01JD3",
    "isinRe": "INF397L01JE1"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "139971",
    "name": "IDBI Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "IDBI Hybrid Equity Fund Growth Direct",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2016-10-03",
    "closeData": "2016-10-17",
    "isin": "INF397L01JC5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "139972",
    "name": "IDBI Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "IDBI Hybrid Equity Fund IDCW Direct",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2016-10-03",
    "closeData": "2016-10-17",
    "isin": "INF397L01IZ8",
    "isinRe": "INF397L01JA9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139985",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-III (1281 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-III (1281 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-22",
    "closeData": "",
    "isin": "INF789FC1EA6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139986",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-III (1281 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-III (1281Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-22",
    "closeData": "",
    "isin": "INF789FC1EC2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139987",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-III (1281 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-III (1281 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-22",
    "closeData": "",
    "isin": "INF789FC1EB4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "139988",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-III (1281 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-III (1281 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-09-22",
    "closeData": "",
    "isin": "INF789FC1DZ5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139989",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series IV Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF903JA1534",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139990",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series IV Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF903JA1526",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139991",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series IV Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF903JA1518",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "139992",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series IV Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF903JA1500",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139993",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C - 1387 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C 1387 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF109KB1G35",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139994",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C - 1387 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C 1387 Days Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF109KB1G43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139995",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C - 1387 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C 1387 Days Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF109KB1G27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "139996",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C - 1387 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan C 1387 Days Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-09-27",
    "closeData": "",
    "isin": "INF109KB1G50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140019",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF109KB1H42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140020",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF109KB1H75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140021",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF109KB1H59",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140022",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1100 Days Plan A Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF109KB1H67",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140025",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140026",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140027",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140028",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1107 Days Plan B Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140029",
    "name": "ICICI Prudential Value Fund - Series 9",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 9 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-15",
    "closeData": "",
    "isin": "INF109KB1I25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140030",
    "name": "ICICI Prudential Value Fund - Series 9",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 9 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-15",
    "closeData": "",
    "isin": "INF109KB1I33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140031",
    "name": "ICICI Prudential Value Fund - Series 9",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 9 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-15",
    "closeData": "",
    "isin": "INF109KB1I41",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140032",
    "name": "ICICI Prudential Value Fund - Series 9",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 9 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-15",
    "closeData": "",
    "isin": "INF109KB1I58",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140043",
    "name": "UTI - Long Term Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series IV - Regular Plan - IDCW",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter w",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF789FC1FB1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140044",
    "name": "UTI - Long Term Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series IV - Direct Plan - IDCW",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter w",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF789FC1FD7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140045",
    "name": "UTI - Long Term Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series IV - Regular Plan - Growth Option",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter w",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF789FC1FC9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140046",
    "name": "UTI - Long Term Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series IV - Direct Plan - Growth Option",
    "minAmount": "Rs 500/- and in multiples of Rs 500/- thereafter w",
    "launchDate": "2016-10-10",
    "closeData": "",
    "isin": "INF789FC1FE5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140053",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF204KB1FB2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140054",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF204KB1FA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140055",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF204KB1EZ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140056",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF204KB1FC0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140057",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-20",
    "closeData": "",
    "isin": "INF109KB1J32",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140058",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-20",
    "closeData": "",
    "isin": "INF109KB1J08",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140059",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-10-20",
    "closeData": "",
    "isin": "INF109KB1J16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140060",
    "name": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 11 - Plan D 1361 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-10-20",
    "closeData": "",
    "isin": "INF109KB1J24",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140065",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-IV (1996 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series VIII-IV (1996 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF789FC1EZ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140066",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-IV (1996 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-IV (1996 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF789FC1EY6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140067",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-IV (1996 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series VIII-IV (1996 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF789FC1EX8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140068",
    "name": "UTI - Capital Protection Oriented Scheme - Series VIII-IV (1996 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series VIII-IV (1996 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-10-24",
    "closeData": "",
    "isin": "INF789FC1FA3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140080",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140081",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140082",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140083",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1127 Days Plan C - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140084",
    "name": "Nippon India ETF Nifty 50 BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 50 BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2001-12-12",
    "closeData": "",
    "isin": "INF204KB14I2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140085",
    "name": "Nippon India ETF Nifty Next 50 Junior BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty Next 50 Junior BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2003-02-06",
    "closeData": "",
    "isin": "INF732E01045",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140086",
    "name": "Nippon India ETF Nifty 1D Rate Liquid BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 1D Rate Liquid BeES - DAILY IDCW Option",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2003-07-01",
    "closeData": "",
    "isin": "INF732E01037",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140087",
    "name": "Nippon India ETF Nifty Bank BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty Bank BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2004-05-25",
    "closeData": "",
    "isin": "INF204KB15I9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140088",
    "name": "Nippon India ETF Gold BeES",
    "category": "Other Scheme - Gold ETF",
    "navName": "Nippon India ETF Gold BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2007-02-15",
    "closeData": "",
    "isin": "INF204KB17I5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140089",
    "name": "Nippon India ETF Nifty PSU Bank BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty PSU Bank BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2007-10-24",
    "closeData": "",
    "isin": "INF204KB16I7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140094",
    "name": "Nippon India ETF Nifty 50 Shariah BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 50 Shariah BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2009-02-04",
    "closeData": "2009-02-25",
    "isin": "INF732E01128",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140095",
    "name": "Nippon India ETF Hang Seng BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Hang Seng BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2010-02-15",
    "closeData": "",
    "isin": "INF204KB19I1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140102",
    "name": "Nippon India ETF Nifty Infrastructure BeES",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty Infrastructure BeES",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2010-09-09",
    "closeData": "2010-09-23",
    "isin": "INF732E01268",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140107",
    "name": "CPSE ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "CPSE ETF",
    "minAmount": "Directly with Fund - In creation of unit size; Fro",
    "launchDate": "2014-03-18",
    "closeData": "",
    "isin": "INF457M01133",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140108",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-15",
    "closeData": "",
    "isin": "INF109KB1K13",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-15",
    "closeData": "",
    "isin": "INF109KB1K21",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-15",
    "closeData": "",
    "isin": "INF109KB1K39",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1248 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-15",
    "closeData": "",
    "isin": "INF109KB1K05",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140121",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FP1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140123",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FY3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140124",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FT3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140125",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FS5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140126",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FQ9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140128",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FU1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140129",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FX5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140130",
    "name": "UTI - FTIF Series XXV-Plan-X (1229 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-X (1229 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-10",
    "closeData": "",
    "isin": "INF789FC1FW7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140131",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140132",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140133",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140134",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1105 Days Plan E - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140140",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-11-24",
    "closeData": "",
    "isin": "INF109KB1K96",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140141",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-11-24",
    "closeData": "",
    "isin": "INF109KB1L12",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140142",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-24",
    "closeData": "",
    "isin": "INF109KB1L04",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140143",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan A 2056 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-11-24",
    "closeData": "",
    "isin": "INF109KB1K88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140148",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-11-25",
    "closeData": "",
    "isin": "INF204KB1FH9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140149",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-11-25",
    "closeData": "",
    "isin": "INF204KB1FG1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140150",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-11-25",
    "closeData": "",
    "isin": "INF204KB1FE6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140151",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan B- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-11-25",
    "closeData": "",
    "isin": "INF204KB1FF3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140152",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FM9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140153",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 1- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FN7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140154",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 1- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FO5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140155",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 1- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FP2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140156",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 2- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FT4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140157",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 2- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FR8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140158",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 2- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FS6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140159",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FQ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140160",
    "name": "Reliance Fixed Horizon Fund XXXII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXII- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FU2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140161",
    "name": "Reliance Fixed Horizon Fund XXXII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXII- Series 3- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FV0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140162",
    "name": "Reliance Fixed Horizon Fund XXXII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXII- Series 3- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FW8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140163",
    "name": "Reliance Fixed Horizon Fund XXXII- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXII- Series 3- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FX6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140164",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FY4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140165",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1FZ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140166",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 4- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1GB0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140167",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 4- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-11-29",
    "closeData": "",
    "isin": "INF204KB1GA2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140168",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-I (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-I (1279 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-11-22",
    "closeData": "2016-12-06",
    "isin": "INF789FC1GF0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140169",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-I (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-I (1279 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-11-22",
    "closeData": "2016-12-06",
    "isin": "INF789FC1GG8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140170",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-I (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-I (1279 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-11-22",
    "closeData": "2016-12-06",
    "isin": "INF789FC1GH6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140171",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-I (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-I (1279 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2016-11-22",
    "closeData": "2016-12-06",
    "isin": "INF789FC1GE3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140172",
    "name": "Edelweiss Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Edelweiss Large & Mid Cap Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-04-19",
    "closeData": "",
    "isin": "INF843K01047",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140173",
    "name": "Edelweiss Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Edelweiss Large and Mid Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-04-19",
    "closeData": "",
    "isin": "INF843K01054",
    "isinRe": "INF843K01062"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140174",
    "name": "Edelweiss Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Edelweiss Large and Mid Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-04-19",
    "closeData": "",
    "isin": "INF843K01AM8",
    "isinRe": "INF843K01AN6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140175",
    "name": "Edelweiss Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Edelweiss Large & Mid Cap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-04-19",
    "closeData": "",
    "isin": "INF843K01AL0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140176",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Retail Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HE9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140177",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Retail Plan Daily - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HF6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140178",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Retail Plan - Weekly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HG4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140180",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Retail Plan Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HI0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140181",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan Daily - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HA7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140182",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GZ6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140198",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GO0",
    "isinRe": "INF754K01GN2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140199",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan Fortnightly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GV5",
    "isinRe": "INF754K01GR3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140192",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan Annual - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GX1",
    "isinRe": "INF754K01GT9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140193",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan daily - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GP7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140194",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan - Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GY9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140195",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan weekly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GU7",
    "isinRe": "INF754K01GQ5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140196",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GM4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140197",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Direct Plan Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GW3",
    "isinRe": "INF754K01GS1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140183",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HL4",
    "isinRe": "INF754K01HD1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140184",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan Fortnightly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HK6",
    "isinRe": "INF754K01HC3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140185",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan Weekly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HJ8",
    "isinRe": "INF754K01HB5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140188",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan Annual - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HQ3",
    "isinRe": "INF754K01HP5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140189",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan - Bonus Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01HR1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140190",
    "name": "Edelweiss Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Edelweiss Liquid Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-09-18",
    "closeData": "2007-09-20",
    "isin": "INF754K01GK8",
    "isinRe": "INF754K01GL6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140225",
    "name": "Edelweiss Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Edelweiss Mid Cap Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-11-09",
    "closeData": "",
    "isin": "INF843K01013",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140226",
    "name": "Edelweiss Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Edelweiss Mid Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-11-09",
    "closeData": "",
    "isin": "INF843K01021",
    "isinRe": "INF843K01039"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140227",
    "name": "Edelweiss Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Edelweiss Mid Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2007-11-09",
    "closeData": "",
    "isin": "INF843K01AP1",
    "isinRe": "INF843K01AQ9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140228",
    "name": "Edelweiss Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Edelweiss Mid Cap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2007-11-09",
    "closeData": "",
    "isin": "INF843K01AO4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140229",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01344",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140230",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Institutional Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01377",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140232",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01351",
    "isinRe": "INF843K01369"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140233",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Regular Plan - Annual - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01922",
    "isinRe": "INF843K01914"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140235",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01CF8",
    "isinRe": "INF843K01CG6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140236",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Direct Plan - Annual - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01CI2",
    "isinRe": "INF843K01CH4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140237",
    "name": "Edelweiss Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Edelweiss Money Market Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-06-09",
    "closeData": "2008-06-25",
    "isin": "INF843K01CE1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140242",
    "name": "Edelweiss Greater China Equity Off-shore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Greater China Equity Off-shore Fund - Regular Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2009-07-09",
    "closeData": "",
    "isin": "INF843K01138",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140243",
    "name": "Edelweiss Greater China Equity Off-shore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Greater China Equity Off-shore Fund - Direct Plan - Growth Option",
    "minAmount": "10000",
    "launchDate": "2009-07-09",
    "closeData": "",
    "isin": "INF843K01AU1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140255",
    "name": "Edelweiss ASEAN Equity Off-shore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss ASEAN Equity Off-shore Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-06-10",
    "closeData": "",
    "isin": "INF843K01609",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140256",
    "name": "Edelweiss ASEAN Equity Off-shore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss ASEAN Equity Off-shore Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-06-10",
    "closeData": "",
    "isin": "INF843K01AW7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140273",
    "name": "Edelweiss US Value Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss US Value Equity Offshore Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF843K01ED9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140274",
    "name": "Edelweiss US Value Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss US Value Equity Offshore Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2013-07-17",
    "closeData": "",
    "isin": "INF843K01EC1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140283",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Regular Plan - Growth Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FK1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140284",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Regular Plan - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FL9",
    "isinRe": "INF843K01FM7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140293",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Direct Plan Monthly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FI5",
    "isinRe": "INF843K01FH7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140294",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Regular Plan Fortnightly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FO3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140286",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Direct Plan - Growth Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FC8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140288",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Direct Plan - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FD6",
    "isinRe": "INF843K01FE4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140289",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Regular Plan Weekly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FN5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140290",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Direct Plan weekly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FF1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140291",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Direct Plan Fortnightly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FG9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140292",
    "name": "Edelweiss Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Edelweiss Banking and PSU Debt Fund - Regular Plan Monthly - IDCW Option",
    "minAmount": "10",
    "launchDate": "2013-08-26",
    "closeData": "",
    "isin": "INF843K01FQ8",
    "isinRe": "INF843K01FP0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140295",
    "name": "Edelweiss Europe Dynamic Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Europe Dynamic Equity Offshore Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF843K01GJ1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140296",
    "name": "Edelweiss Europe Dynamic Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Europe Dynamic Equity Offshore Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2014-01-17",
    "closeData": "",
    "isin": "INF843K01GI3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140297",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HC4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140298",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01GS2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140301",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HE0",
    "isinRe": "INF843K01HF7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140302",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01GU8",
    "isinRe": "INF843K01GV6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140303",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan Weekly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HG5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140304",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Direct Plan weekly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01GW4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140305",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan Fortnightly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HH3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140306",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Direct Plan Fortnightly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01GX2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140307",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HJ9",
    "isinRe": "INF843K01HI1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140308",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Direct Plan Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01GZ7",
    "isinRe": "INF843K01GY0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140310",
    "name": "Edelweiss Government Securities Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Edelweiss Government Securities Fund - Regular Plan - Annual - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-05",
    "closeData": "",
    "isin": "INF843K01HL5",
    "isinRe": "INF843K01HK7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140327",
    "name": "Edelweiss Emerging Markets Opportunities Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Emerging Markets Opportunities Equity Offshore Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-06-16",
    "closeData": "",
    "isin": "INF843K01IZ3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140328",
    "name": "Edelweiss Emerging Markets Opportunities Equity Offshore Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss Emerging Markets Opportunities Equity Offshore Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-06-16",
    "closeData": "",
    "isin": "INF843K01IY6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140347",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KC8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140348",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KE4",
    "isinRe": "INF843K01KF1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140349",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KI5",
    "isinRe": "INF843K01KJ3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140350",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Direct Plan - Bonus Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KD6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140351",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KG9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140352",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Regular Plan - Bonus Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF843K01KH7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141933",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Direct Plan - Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF754K01IN8",
    "isinRe": "INF754K01IO6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "141934",
    "name": "Edelweiss Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Edelweiss Equity Savings Fund - Regular Plan - Monthly - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-09-22",
    "closeData": "",
    "isin": "INF754K01IP3",
    "isinRe": "INF754K01IQ1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140353",
    "name": "Edelweiss Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Edelweiss Flexi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF843K01KK1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140354",
    "name": "Edelweiss Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Edelweiss Flexi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF843K01KL9",
    "isinRe": "INF843K01KM7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140355",
    "name": "Edelweiss Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Edelweiss Flexi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF843K01KN5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "140356",
    "name": "Edelweiss Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Edelweiss Flexi Cap Fund - Regular - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2015-01-13",
    "closeData": "",
    "isin": "INF843K01KO3",
    "isinRe": "INF843K01KP0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140363",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-12-06",
    "closeData": "",
    "isin": "INF109KB1L46",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140364",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-12-06",
    "closeData": "",
    "isin": "INF109KB1L53",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140365",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-12-06",
    "closeData": "",
    "isin": "INF109KB1L79",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140366",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan B 1222 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-12-06",
    "closeData": "",
    "isin": "INF109KB1L61",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140367",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GK0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140368",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GR5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140369",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GN4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140370",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GM6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140371",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GQ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140372",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GL8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140373",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GP9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140374",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GO2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140375",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GJ2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140376",
    "name": "UTI - FTIF Series XXV-Plan-XI (1211 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XI (1211 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-11-28",
    "closeData": "",
    "isin": "INF789FC1GI4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140381",
    "name": "BANDHAN HYBRID EQUITY FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "BANDHAN Hybrid Equity Fund-Regular Plan Growth",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2016-12-12",
    "closeData": "",
    "isin": "INF194KA1U15",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140382",
    "name": "BANDHAN HYBRID EQUITY FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "BANDHAN Hybrid Equity Fund-Direct Plan Growth",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2016-12-12",
    "closeData": "",
    "isin": "INF194KA1U56",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140383",
    "name": "BANDHAN HYBRID EQUITY FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "BANDHAN Hybrid Equity Fund-Direct Plan IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2016-12-12",
    "closeData": "",
    "isin": "INF194KA1U64",
    "isinRe": "INF194KA1U72"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140384",
    "name": "BANDHAN HYBRID EQUITY FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "BANDHAN Hybrid Equity Fund-Regular Plan IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2016-12-12",
    "closeData": "",
    "isin": "INF194KA1U23",
    "isinRe": "INF194KA1U31"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140407",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund - X - Plan C - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-19",
    "closeData": "",
    "isin": "INF204KB1GF1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140408",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund - X - Plan C - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-19",
    "closeData": "",
    "isin": "INF204KB1GD6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140409",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund - X - Plan C - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-19",
    "closeData": "",
    "isin": "INF204KB1GE4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140410",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund - X - Plan C - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-19",
    "closeData": "",
    "isin": "INF204KB1GC8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140421",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GS3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140423",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GY1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140424",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GX3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140415",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GT1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140416",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GW5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140417",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1HA9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140418",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GV7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140419",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1GZ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140420",
    "name": "UTI - FTIF Series XXV-Plan-XII (1198 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXV-XII (1198 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-09",
    "closeData": "",
    "isin": "INF789FC1HB7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140425",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 5- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF204KB1GH7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140426",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 5- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF204KB1GI5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140427",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF204KB1GG9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140428",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 5- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF204KB1GJ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140429",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-23",
    "closeData": "",
    "isin": "INF204KB1GN5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140430",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-23",
    "closeData": "",
    "isin": "INF204KB1GM7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140431",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-23",
    "closeData": "",
    "isin": "INF204KB1GL9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140432",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan D- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2016-12-23",
    "closeData": "",
    "isin": "INF204KB1GK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140433",
    "name": "ICICI Prudential Value Fund - Series 10",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 10 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF109KB1M03",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140434",
    "name": "ICICI Prudential Value Fund - Series 10",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 10 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF109KB1L95",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140435",
    "name": "ICICI Prudential Value Fund - Series 10",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 10 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF109KB1L87",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140436",
    "name": "ICICI Prudential Value Fund - Series 10",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 10 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2016-12-21",
    "closeData": "",
    "isin": "INF109KB1M11",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140438",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E- Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-03",
    "closeData": "",
    "isin": "INF204KB1GP0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140439",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-03",
    "closeData": "",
    "isin": "INF204KB1GO3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140440",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-03",
    "closeData": "",
    "isin": "INF204KB1GR6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140441",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan E- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-03",
    "closeData": "",
    "isin": "INF204KB1GQ8",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140442",
    "name": "Mahindra Manulife Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mahindra Manulife Equity Savings Fund -Direct Plan -Dividend",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF174V01192",
    "isinRe": "INF174V01200"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140444",
    "name": "Mahindra Manulife Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mahindra Manulife Equity Savings Fund - Direct Plan -Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF174V01184",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140446",
    "name": "Mahindra Manulife Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mahindra Manulife Equity Savings Fund -Regular Plan -Dividend",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF174V01168",
    "isinRe": "INF174V01176"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140447",
    "name": "Mahindra Manulife Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mahindra Manulife Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF174V01150",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140454",
    "name": "UTI - Long Term Advantage Fund Series V",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series V - Direct Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2016-12-22",
    "closeData": "",
    "isin": "INF789FC1HT9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140455",
    "name": "UTI - Long Term Advantage Fund Series V",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series V - Regular Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2016-12-22",
    "closeData": "",
    "isin": "INF789FC1HR3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140456",
    "name": "UTI - Long Term Advantage Fund Series V",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series V - Regular Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2016-12-22",
    "closeData": "",
    "isin": "INF789FC1HQ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140457",
    "name": "UTI - Long Term Advantage Fund Series V",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series V - Direct Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2016-12-22",
    "closeData": "",
    "isin": "INF789FC1HS1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "140458",
    "name": "IDBI Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "IDBI Midcap Fund IDCW Direct",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2017-01-05",
    "closeData": "2017-01-19",
    "isin": "INF397L01JH4",
    "isinRe": "INF397L01JI2"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "140459",
    "name": "IDBI Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "IDBI Midcap Fund IDCW Regular",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2017-01-05",
    "closeData": "2017-01-19",
    "isin": "INF397L01JL6",
    "isinRe": "INF397L01JM4"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "140460",
    "name": "IDBI Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "IDBI Midcap Fund Growth Regular",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2017-01-05",
    "closeData": "2017-01-19",
    "isin": "INF397L01JO0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "140461",
    "name": "IDBI Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "IDBI Midcap Fund Growth Direct",
    "minAmount": "Minimum Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2017-01-05",
    "closeData": "2017-01-19",
    "isin": "INF397L01JK8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140470",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HG6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140471",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HH4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140472",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HK8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140473",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HJ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140474",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140475",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HP7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140466",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HN2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140467",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HO0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140468",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HI2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140469",
    "name": "UTI - FTIF Series XXVI-Plan-I (1182 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-I (1182 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2016-12-27",
    "closeData": "",
    "isin": "INF789FC1HL6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "140486",
    "name": "SBI Long Term Advantage Fund - Series IV",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series IV - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500",
    "launchDate": "2016-12-30",
    "closeData": "2017-03-29",
    "isin": "INF200KA1LT7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "140487",
    "name": "SBI Long Term Advantage Fund - Series IV",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series IV - Direct Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500",
    "launchDate": "2016-12-30",
    "closeData": "2017-03-29",
    "isin": "INF200KA1LS9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "140488",
    "name": "SBI Long Term Advantage Fund - Series IV",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series IV - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500",
    "launchDate": "2016-12-30",
    "closeData": "2017-03-29",
    "isin": "INF200KA1LQ3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "140489",
    "name": "SBI Long Term Advantage Fund - Series IV",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series IV - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500",
    "launchDate": "2016-12-30",
    "closeData": "2017-03-29",
    "isin": "INF200KA1LR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140500",
    "name": "ICICI Prudential Value Fund - Series 11",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 11 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF109KB1M60",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140501",
    "name": "ICICI Prudential Value Fund - Series 11",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 11 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF109KB1M78",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140502",
    "name": "ICICI Prudential Value Fund - Series 11",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 11 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF109KB1M86",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140503",
    "name": "ICICI Prudential Value Fund - Series 11",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 11 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-10",
    "closeData": "",
    "isin": "INF109KB1M94",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140505",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-II (1278 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-II (1278 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-09",
    "closeData": "",
    "isin": "INF789FC1HV5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140506",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-II (1278 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-II (1278 Days) - Direct  Plan - Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-09",
    "closeData": "",
    "isin": "INF789FC1HW3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140507",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-II (1278 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-II (1278 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-09",
    "closeData": "",
    "isin": "INF789FC1HX1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140508",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-II (1278 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-II (1278 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-09",
    "closeData": "",
    "isin": "INF789FC1HU7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140509",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 7- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1HD4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140510",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1HA0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140511",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1HB8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140512",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1HC6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140513",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1GY2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140514",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1GX4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140515",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1GW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140516",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund X- Plan F- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-01-18",
    "closeData": "",
    "isin": "INF204KB1GZ9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140518",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1HY9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140519",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IA7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140520",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IH2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140521",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IE9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140522",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IB5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140523",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IG4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140524",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IF6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140525",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1HZ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140526",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1ID1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140527",
    "name": "UTI - FTIF Series XXVI-Plan-II (1176 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-II (1176 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-01-16",
    "closeData": "",
    "isin": "INF789FC1IC3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140533",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 8- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF204KB1HH5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140534",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF204KB1HE2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140535",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 8- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF204KB1HG7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140536",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF204KB1HF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140537",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1194 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1194 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF109KB1N85",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140538",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1194 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1194 Days Plan F - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF109KB1O01",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140539",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1194 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1194 Days Plan F - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF109KB1O19",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140540",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1194 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1194 Days Plan F - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF109KB1N93",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140541",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-III (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-III (1279 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF789FC1IN0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140542",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-III (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-III (1279 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF789FC1IO8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140543",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-III (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-III (1279 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF789FC1IP5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140544",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-III (1279 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-III (1279 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-01-24",
    "closeData": "",
    "isin": "INF789FC1IM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140545",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1187 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1187 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF109KB1O27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140546",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1187 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1187 Days Plan G - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF109KB1O50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140547",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1187 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1187 Days Plan G - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF109KB1O43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140548",
    "name": "ICICI Prudential Fixed Maturity Plan Series 80 - 1187 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1187 Days Plan G - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-01-27",
    "closeData": "",
    "isin": "INF109KB1O35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140559",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXXII - Series 9 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF204KB1HM5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140560",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXXII - Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF204KB1HO1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140561",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXXII - Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF204KB1HP8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140562",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XXXII - Series 9 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF204KB1HN3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140571",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140572",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140573",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140574",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1180 Days Plan H - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140599",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-13",
    "closeData": "",
    "isin": "INF204KB1HJ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140600",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 10- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-13",
    "closeData": "",
    "isin": "INF204KB1HK9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140601",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 10- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-13",
    "closeData": "",
    "isin": "INF204KB1HL7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140602",
    "name": "Nippon India Fixed Horizon Fund XXXII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXII- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-02-13",
    "closeData": "",
    "isin": "INF204KB1HI3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140603",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1X61",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140604",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund-Direct Plan-Quarterly IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1W70",
    "isinRe": "INF194KA1W88"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140605",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN CREDIT RISK FUND - DIRECT PLAN HALF YEARLY IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1X04",
    "isinRe": "INF194KA1X12"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140606",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund-Direct Plan-Periodic IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1W47",
    "isinRe": "INF194KA1W54"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140607",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund - Regular Plan Quarterly IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1V48",
    "isinRe": "INF194KA1V55"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140608",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund - Regular Plan Annual IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1W05",
    "isinRe": "INF194KA1W13"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140609",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund - Regular Plan Growth",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1W39",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140610",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund-Direct Plan-Annual IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1X38",
    "isinRe": "INF194KA1X46"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140611",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund - Regular Plan Half Yearly IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1V71",
    "isinRe": "INF194KA1V89"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "140612",
    "name": "BANDHAN CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "BANDHAN Credit Risk Fund - Regular Plan Periodic IDCW",
    "minAmount": "Rs 5000 and thereafter",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF194KA1V14",
    "isinRe": "INF194KA1V22"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140613",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Direct Plan -Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01267",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140614",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01226",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140615",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01234",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140616",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01259",
    "isinRe": "INF174V01242"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140617",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01275",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140618",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01283",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140619",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01309",
    "isinRe": "INF174V01291"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "140620",
    "name": "Mahindra Manulife Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Mahindra Manulife Low Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-02-14",
    "closeData": "",
    "isin": "INF174V01218",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140621",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF109KB1P83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140622",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF109KB1P91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140623",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF109KB1Q09",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140624",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1170 Days Plan I - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF109KB1Q17",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140631",
    "name": "Kotak FMP Series 199",
    "category": "Income",
    "navName": "Kotak FMP Series 199 - Regular Plan - Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF174K017K0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140632",
    "name": "Kotak FMP Series 199",
    "category": "Income",
    "navName": "Kotak FMP Series 199 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF174K018K8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140633",
    "name": "Kotak FMP Series 199",
    "category": "Income",
    "navName": "Kotak FMP Series 199 - Direct Plan - Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF174K019K6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140634",
    "name": "Kotak FMP Series 199",
    "category": "Income",
    "navName": "Kotak FMP Series 199 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF174K016K2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140635",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IX9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140636",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IQ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140640",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IZ4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140641",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IV3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140642",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IU5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140643",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IT7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140644",
    "name": "UTI - FTIF Series XXVI-Plan-III (1169 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-III (1169 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-06",
    "closeData": "",
    "isin": "INF789FC1IY7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140645",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-10",
    "closeData": "",
    "isin": "INF109KB1Q25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140646",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-10",
    "closeData": "",
    "isin": "INF109KB1Q41",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140647",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-10",
    "closeData": "",
    "isin": "INF109KB1Q33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140648",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan C 1255 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-10",
    "closeData": "",
    "isin": "INF109KB1Q58",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140649",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R08",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140650",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days - Direct Plan -  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140651",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140652",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan D 1247 Days - Direct Plan -  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R32",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140654",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series IV-IV (1997 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF789FC1IK6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140655",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-IV (1997 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF789FC1IL4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140656",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI - Dual Advantage Fixed Term Series IV-IV (1997 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF789FC1IJ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140657",
    "name": "UTI - Dual Advantage Fixed Term Fund Series IV-IV (1997 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund Series IV-IV (1997 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000 & in multiple of Rs 1 thereafter",
    "launchDate": "2017-02-09",
    "closeData": "",
    "isin": "INF789FC1II0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140661",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140662",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140663",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140664",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1253 Days Plan J - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-17",
    "closeData": "",
    "isin": "INF109KB1R73",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140693",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Direct Option - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C32",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140694",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Direct Option - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C24",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140695",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Regular Option - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C57",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140696",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Regular Option - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C73",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140697",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Direct Option - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C40",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "140698",
    "name": "HDFC FMP 1170D February 2017 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1170D February 2017 (1) - Regular Option - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-16",
    "closeData": "2017-02-28",
    "isin": "INF179KB1C65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140711",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140712",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K -  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140713",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140714",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1247 Days Plan K - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140737",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1HY0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140738",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 1- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1IA8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140739",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 1- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1IB6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140740",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 1- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1HZ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140741",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 2- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1IF7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140742",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1IC4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140743",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 2- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1ID2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140744",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 2- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF204KB1IE0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "140772",
    "name": "Mirae Asset Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mirae Asset Dynamic Bond Fund Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2017-03-03",
    "closeData": "2017-03-17",
    "isin": "INF769K01DP2",
    "isinRe": "INF769K01DQ0"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "140769",
    "name": "Mirae Asset Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mirae Asset Dynamic Bond Fund -Direct Plan -Growth",
    "minAmount": "5000",
    "launchDate": "2017-03-03",
    "closeData": "2017-03-17",
    "isin": "INF769K01DR8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "140770",
    "name": "Mirae Asset Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mirae Asset Dynamic Bond Fund Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2017-03-03",
    "closeData": "2017-03-17",
    "isin": "INF769K01DS6",
    "isinRe": "INF769K01DT4"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "140771",
    "name": "Mirae Asset Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mirae Asset Dynamic Bond Fund-Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2017-03-03",
    "closeData": "2017-03-17",
    "isin": "INF769K01DO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140773",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N -Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF109KB1U03",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140774",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF109KB1U11",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140775",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF109KB1T97",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140776",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1150 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-02",
    "closeData": "",
    "isin": "INF109KB1T89",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140779",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-28",
    "closeData": "",
    "isin": "INF109KB1T30",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140780",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-02-28",
    "closeData": "",
    "isin": "INF109KB1T14",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140777",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-28",
    "closeData": "",
    "isin": "INF109KB1T06",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140778",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1245 Days Plan L - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-02-28",
    "closeData": "",
    "isin": "INF109KB1T22",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140782",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140783",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140784",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140781",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1238 Days Plan M - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140853",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JL2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140854",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JK4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140861",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JS7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140855",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JT5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140856",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JQ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140857",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JR9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140858",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JM0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140859",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JO6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140860",
    "name": "UTI - FTIF Series XXVI-Plan-V (1160 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-V (1160 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-02-27",
    "closeData": "",
    "isin": "INF789FC1JP3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140863",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-07",
    "closeData": "",
    "isin": "INF204KB1IG5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140864",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 3 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-07",
    "closeData": "",
    "isin": "INF204KB1IH3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140865",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 3-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-07",
    "closeData": "",
    "isin": "INF204KB1II1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140866",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 3- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-07",
    "closeData": "",
    "isin": "INF204KB1IJ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140909",
    "name": "Kotak FMP Series 200",
    "category": "Income",
    "navName": "Kotak FMP Series 200-Regular Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2017-03-06",
    "closeData": "",
    "isin": "INF174K012M7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140910",
    "name": "Kotak FMP Series 200",
    "category": "Income",
    "navName": "Kotak FMP Series 200-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-06",
    "closeData": "",
    "isin": "INF174K011M9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140911",
    "name": "Kotak FMP Series 200",
    "category": "Income",
    "navName": "Kotak FMP Series 200-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-06",
    "closeData": "",
    "isin": "INF174K014M3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140912",
    "name": "Kotak FMP Series 200",
    "category": "Income",
    "navName": "Kotak FMP Series 200-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-06",
    "closeData": "",
    "isin": "INF174K013M5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140914",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF204KB1IS0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140915",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF204KB1IT8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140916",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 4- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF204KB1IV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140917",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 4- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF204KB1IU6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140924",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 5- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF204KB1IX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140925",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 5-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF204KB1IY8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140926",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 5-Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF204KB1IZ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140927",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF204KB1IW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140928",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V28",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140929",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V36",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140930",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V10",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140931",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1233 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V02",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140932",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V44",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140933",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V51",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140934",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V69",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140935",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1231 Days Plan P - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V77",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140942",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF109KB1W68",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140943",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF109KB1W76",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140944",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF109KB1W84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140945",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1227 Days Plan Q - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF109KB1W92",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140954",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V93",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140955",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1V85",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140956",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1W19",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140957",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1138 Days Plan R - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-13",
    "closeData": "",
    "isin": "INF109KB1W01",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140964",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1KB1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140966",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1KC9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140967",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1JX7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140968",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1KD7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140969",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1JV1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140970",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1JU3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140971",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1KA3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "140973",
    "name": "UTI - FTIF Series XXVI-Plan-VI (1146 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VI (1146 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-10",
    "closeData": "",
    "isin": "INF789FC1JY5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140974",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-21",
    "closeData": "",
    "isin": "INF204KB1JB4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140975",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-21",
    "closeData": "",
    "isin": "INF204KB1JA6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140976",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 6-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-21",
    "closeData": "",
    "isin": "INF204KB1JC2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "140977",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 6- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-21",
    "closeData": "",
    "isin": "INF204KB1JD0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140978",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-15",
    "closeData": "",
    "isin": "INF109KB1W27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140979",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days - Direct Plan -  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-15",
    "closeData": "",
    "isin": "INF109KB1W50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140980",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-15",
    "closeData": "",
    "isin": "INF109KB1W35",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "140981",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan E 1222 Days - Direct Plan -  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-15",
    "closeData": "",
    "isin": "INF109KB1W43",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140988",
    "name": "Kotak FMP Series 201",
    "category": "Income",
    "navName": "Kotak FMP Series 201-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF174K015M0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140989",
    "name": "Kotak FMP Series 201",
    "category": "Income",
    "navName": "Kotak FMP Series 201-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF174K017M6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140990",
    "name": "Kotak FMP Series 201",
    "category": "Income",
    "navName": "Kotak FMP Series 201-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF174K016M8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "140991",
    "name": "Kotak FMP Series 201",
    "category": "Income",
    "navName": "Kotak FMP Series 201-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-17",
    "closeData": "",
    "isin": "INF174K018M4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141008",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KE5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141010",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KI6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141011",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KM8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141012",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KF2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141013",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KL0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141014",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KK2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141015",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KN6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141016",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KH8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141017",
    "name": "UTI - FTIF Series XXVI-Plan-VII (1140 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VII (1140 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-16",
    "closeData": "",
    "isin": "INF789FC1KJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141024",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days - Direct Plan -  Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141025",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days - Direct Plan -  Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141026",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141027",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XI - Plan F 1215 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141036",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF204KB1JK5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141037",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF204KB1JI9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141038",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 7- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF204KB1JL3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141039",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF204KB1JJ7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141040",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days) - Growth",
    "minAmount": "5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF090I01LS4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141041",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF090I01LT2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141043",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan A (1108 days) - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF090I01LV8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141046",
    "name": "Kotak FMP Series 202",
    "category": "Income",
    "navName": "Kotak FMP Series 202-DirectPLan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-22",
    "closeData": "",
    "isin": "INF174K012N5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141047",
    "name": "Kotak FMP Series 202",
    "category": "Income",
    "navName": "Kotak FMP Series 202-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-22",
    "closeData": "",
    "isin": "INF174K011N7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141048",
    "name": "Kotak FMP Series 202",
    "category": "Income",
    "navName": "Kotak FMP Series 202-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-22",
    "closeData": "",
    "isin": "INF174K010N9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141049",
    "name": "Kotak FMP Series 202",
    "category": "Income",
    "navName": "Kotak FMP Series 202-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-03-22",
    "closeData": "",
    "isin": "INF174K019M2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141073",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141074",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141075",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141076",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan W - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141077",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141078",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141079",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141080",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1118 Days Plan X - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141081",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141082",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141083",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141084",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1215 Days Plan Y - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141089",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Y09",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141090",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Y25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141091",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Y33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141092",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1125 Days Plan S - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Y17",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141093",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141094",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141095",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141096",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1214 Days Plan V - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141097",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB10A8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141098",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB12A4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141099",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB11A6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141100",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1216 Days Plan U - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB13A2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141107",
    "name": "ICICI Prudential Value Fund - Series 12",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 12 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141108",
    "name": "ICICI Prudential Value Fund - Series 12",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 12 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-27",
    "closeData": "",
    "isin": "INF109KB1X91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141109",
    "name": "ICICI Prudential Value Fund - Series 12",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 12 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141110",
    "name": "ICICI Prudential Value Fund - Series 12",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 12 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-27",
    "closeData": "",
    "isin": "INF109KB1X75",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141120",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MO0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141113",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MQ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141114",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141115",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MT9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141116",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MP7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141117",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MS1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141119",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1ML6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141111",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MR3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141112",
    "name": "UTI - FTIF Series XXVI-Plan-VIII (1154 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-VIII (1154 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-24",
    "closeData": "",
    "isin": "INF789FC1MK8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141139",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE SERIES V",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series V Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF903JA1930",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141140",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE SERIES V",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series V Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF903JA1948",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141141",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE SERIES V",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series V Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF903JA1955",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141142",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE SERIES V",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series V Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF903JA1963",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141151",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JQ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141152",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141153",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 8- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JT6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141154",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 8-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JS8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141155",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JX8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141156",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JV2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141157",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JW0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141158",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan A- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF204KB1JU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141163",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Z65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141164",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Z99",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141165",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Z73",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141166",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 80 - 1225 Days Plan T - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-03-25",
    "closeData": "",
    "isin": "INF109KB1Z81",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141167",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1MU7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141168",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1NA7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141169",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1MZ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141170",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1MX1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141171",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1MY9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141173",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1ND1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141174",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1NC3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141175",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1MV5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141176",
    "name": "UTI - FTIF - Series XXVI - Plan IX(1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-IX (1113 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-03-29",
    "closeData": "",
    "isin": "INF789FC1NB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141177",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF204KB1JY6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141178",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 9- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF204KB1JZ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141179",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 9- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF204KB1KA4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141180",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 9- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF204KB1KB2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141181",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF109KB13B0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141182",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF109KB14B8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141183",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF109KB12B2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141184",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1205 Days Plan B - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-13",
    "closeData": "",
    "isin": "INF109KB15B5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141185",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF204KB1KC0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141186",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF204KB1KD8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141187",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 10- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF204KB1KE6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141188",
    "name": "Nippon India Fixed Horizon Fund XXXIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIII- Series 10- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF204KB1KF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141189",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-11",
    "closeData": "",
    "isin": "INF109KB18A1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141190",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-11",
    "closeData": "",
    "isin": "INF109KB19A9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141191",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-11",
    "closeData": "",
    "isin": "INF109KB11B4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141192",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1211 Days Plan A - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-11",
    "closeData": "",
    "isin": "INF109KB10B6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141197",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NM2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141198",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NI0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141199",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NE9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141200",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NG4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141201",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NF6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141193",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NL4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141194",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NK6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141195",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NN0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141196",
    "name": "UTI - FTIF - Series XXVI - Plan X (1107 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-X (1107 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-07",
    "closeData": "",
    "isin": "INF789FC1NJ8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141203",
    "name": "HSBC Fixed Term Series 128 (1106 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 128 - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF336L01LY3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141204",
    "name": "HSBC Fixed Term Series 128 (1106 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 128 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF336L01LX5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141205",
    "name": "HSBC Fixed Term Series 128 (1106 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 128 - IDCW - Tenure 1106 Days - Maturity  29-Apr-2020",
    "minAmount": "5000",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF336L01MA1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141206",
    "name": "HSBC Fixed Term Series 128 (1106 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 128 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-04-10",
    "closeData": "",
    "isin": "INF336L01LZ0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "141223",
    "name": "Mahindra Manulife Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Mahindra Manulife Multi Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF174V01366",
    "isinRe": "INF174V01358"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "141224",
    "name": "Mahindra Manulife Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Mahindra Manulife Multi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF174V01317",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "141225",
    "name": "Mahindra Manulife Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Mahindra Manulife Multi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF174V01333",
    "isinRe": "INF174V01325"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "141226",
    "name": "Mahindra Manulife Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Mahindra Manulife Multi Cap Fund - Direct Plan -Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF174V01341",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141233",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SU4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141234",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SX8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141235",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SV2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141236",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SZ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141237",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Direct Plan - Quarterly Div  Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SS8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141238",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SY6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141239",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TA4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141240",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TB2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141241",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1SW0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141242",
    "name": "UTI - FTIF - Series XXVI - Plan XI (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XI (1105 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1ST6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141243",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-I (1467 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-I (1467 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TC0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141244",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-I (1467 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-I (1467 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TF3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141245",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-I (1467 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-I (1467 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141246",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-I (1467 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-I (1467 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-12",
    "closeData": "",
    "isin": "INF789FC1TD8",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "141247",
    "name": "Union Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Union Largecap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF582M01CQ5",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "141248",
    "name": "Union Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Union Largecap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF582M01CU7",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "141249",
    "name": "Union Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Union Largecap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF582M01CV5",
    "isinRe": "INF582M01CW3"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "141250",
    "name": "Union Largecap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Union Largecap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF582M01CR3",
    "isinRe": "INF582M01CS1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141261",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-18",
    "closeData": "",
    "isin": "INF109KB17B1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141262",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-18",
    "closeData": "",
    "isin": "INF109KB18B9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141263",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-18",
    "closeData": "",
    "isin": "INF109KB19B7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141264",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-18",
    "closeData": "",
    "isin": "INF109KB16B3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141269",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF109KB14C6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141270",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF109KB17C9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141271",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF109KB15C3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141272",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1195 Days Plan D - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF109KB16C1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141281",
    "name": "Kotak FMP Series 203",
    "category": "Income",
    "navName": "Kotak FMP Series 203-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF174K010O7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141282",
    "name": "Kotak FMP Series 203",
    "category": "Income",
    "navName": "Kotak FMP Series 203 - Regular Plan - Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF174K018N2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141283",
    "name": "Kotak FMP Series 203",
    "category": "Income",
    "navName": "Kotak FMP Series 203 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF174K019N0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141284",
    "name": "Kotak FMP Series 203",
    "category": "Income",
    "navName": "Kotak FMP Series 203-Regular Plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2017-04-19",
    "closeData": "",
    "isin": "INF174K017N4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141285",
    "name": "ICICI Prudential Value Fund - Series 13",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 13 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-25",
    "closeData": "",
    "isin": "INF109KB11C2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141286",
    "name": "ICICI Prudential Value Fund - Series 13",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 13 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-25",
    "closeData": "",
    "isin": "INF109KB13C8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141287",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TH9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141288",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TG1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141289",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TP2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141290",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TL1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141291",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TO5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141292",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Direct Plan  - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TM9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141293",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TI7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141295",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TK3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141296",
    "name": "UTI - FTIF - Series XXVI - Plan XII (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XII (1096 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-20",
    "closeData": "",
    "isin": "INF789FC1TN7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141307",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TX6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141308",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TR8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141316",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TU2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141309",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TW8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141311",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TZ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141312",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TT4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141313",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TV0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141314",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TY4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141315",
    "name": "UTI - FTIF - Series XXVI - Plan XIII (1124 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIII (1124 Days)- Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-04-24",
    "closeData": "",
    "isin": "INF789FC1TQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141329",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-26",
    "closeData": "",
    "isin": "INF109KB14D4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141330",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-26",
    "closeData": "",
    "isin": "INF109KB13D6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141331",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-04-26",
    "closeData": "",
    "isin": "INF109KB15D1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141332",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1101 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-04-26",
    "closeData": "",
    "isin": "INF109KB12D8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141333",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Growth",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01LY2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141334",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Direct - Dividend",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01MC6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141335",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01MB8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141336",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Dividend",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01LZ9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141337",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Quarterly Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01MD4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141338",
    "name": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 1 - Plan B (1104 days) - Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF090I01MA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141343",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-03",
    "closeData": "",
    "isin": "INF109KB18F0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141344",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-03",
    "closeData": "",
    "isin": "INF109KB17F2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141345",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-03",
    "closeData": "",
    "isin": "INF109KB19F8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141346",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1190 Days Plan F - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-03",
    "closeData": "",
    "isin": "INF109KB16F4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141351",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-06",
    "closeData": "",
    "isin": "INF109KB11H1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141352",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-06",
    "closeData": "",
    "isin": "INF109KB18G8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141353",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-06",
    "closeData": "",
    "isin": "INF109KB19G6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141354",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1185 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-06",
    "closeData": "",
    "isin": "INF109KB10H3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141355",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF109KB16G2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141356",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF109KB17G0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141357",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF109KB14G7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141358",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1178 Days Plan H - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF109KB15G4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141365",
    "name": "Kotak FMP Series 204",
    "category": "Income",
    "navName": "Kotak FMP Series 204-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF174K015O6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141366",
    "name": "Kotak FMP Series 204",
    "category": "Income",
    "navName": "Kotak FMP Series 204-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF174K014O9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141367",
    "name": "Kotak FMP Series 204",
    "category": "Income",
    "navName": "Kotak FMP Series 204-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF174K016O4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141368",
    "name": "Kotak FMP Series 204",
    "category": "Income",
    "navName": "Kotak FMP Series 204-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-05-08",
    "closeData": "",
    "isin": "INF174K017O2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141371",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB12G1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141372",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB13G9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141373",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB10G5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141374",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1116 Days Plan I - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB11G3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141376",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB13H7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141377",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB15H2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141378",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB14H5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141379",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan A 1168 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF109KB12H9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141386",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 1 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NM3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141387",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 1 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NN1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141388",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 1 - Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NO9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141389",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 1 - Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NP6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141390",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 2 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NQ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141391",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 2 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NR2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141392",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 2 - Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NS0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141393",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 2 - Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-16",
    "closeData": "",
    "isin": "INF204KB1NT8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141394",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZP9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141395",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZQ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141396",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Direct Plan - Maturity Div Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZU9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141397",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZO2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141398",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZT1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141399",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZR5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141400",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZX3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141401",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZS3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141402",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZV7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141403",
    "name": "UTI - FTIF - Series XXVI - Plan XIV (1105 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XIV (1105 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-09",
    "closeData": "",
    "isin": "INF789FC1ZW5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141419",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund- XI- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan B- Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF204KB1NI1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141416",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund- XI- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan B- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF204KB1NL5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141417",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund- XI- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan B- Dividend Payout Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF204KB1NK7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141418",
    "name": "Nippon India Dual Advantage Fixed Tenure Fund- XI- Plan B",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan B- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF204KB1NJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141426",
    "name": "ICICI Prudential Value Fund - Series 14",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 14 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-25",
    "closeData": "",
    "isin": "INF109KB17J4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141427",
    "name": "ICICI Prudential Value Fund - Series 14",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 14 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-25",
    "closeData": "",
    "isin": "INF109KB19J0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141558",
    "name": "ICICI Prudential Value Fund - Series 14",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 14 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-25",
    "closeData": "",
    "isin": "INF109KB16J6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141559",
    "name": "ICICI Prudential Value Fund - Series 14",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 14 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-25",
    "closeData": "",
    "isin": "INF109KB18J2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141428",
    "name": "HDFC Equity Opp Fund - II - 1126D May 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1126D May 2017 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-05-26",
    "closeData": "2017-06-09",
    "isin": "INF179KC1AG2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141429",
    "name": "HDFC Equity Opp Fund - II - 1126D May 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1126D May 2017 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-05-26",
    "closeData": "2017-06-09",
    "isin": "INF179KC1AH0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141430",
    "name": "HDFC Equity Opp Fund - II - 1126D May 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1126D May 2017 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-05-26",
    "closeData": "2017-06-09",
    "isin": "INF179KC1AF4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141431",
    "name": "HDFC Equity Opp Fund - II - 1126D May 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1126D May 2017 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-05-26",
    "closeData": "2017-06-09",
    "isin": "INF179KC1AI8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141432",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 3-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": "INF204KB1NU6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141433",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 3- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": "INF204KB1NV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141434",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 3- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": "INF204KB1NX0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141435",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": "INF204KB1NW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141444",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days",
    "category": "Growth",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141445",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days",
    "category": "Growth",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141446",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days",
    "category": "Growth",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141447",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days",
    "category": "Growth",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan B 1289 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-05-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141454",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A20",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141455",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A87",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141456",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A79",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141457",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A61",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141449",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1B11",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141450",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1B03",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141451",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A53",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141452",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A95",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141453",
    "name": "UTI - FTIF - Series XXVI - Plan XV (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVI-XV (1097 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-19",
    "closeData": "",
    "isin": "INF789FC1A38",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141458",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-31",
    "closeData": "",
    "isin": "INF204KB1NZ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141459",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-31",
    "closeData": "",
    "isin": "INF204KB1NY8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141460",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 4- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-31",
    "closeData": "",
    "isin": "INF204KB1OB4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141461",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 4-Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-05-31",
    "closeData": "",
    "isin": "INF204KB1OA6",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141462",
    "name": "IDBI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "IDBI Small Cap Fund Growth Regular",
    "minAmount": "5000",
    "launchDate": "2017-06-01",
    "closeData": "2017-06-15",
    "isin": "INF397L01JW3",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141475",
    "name": "IDBI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "IDBI Small Cap Fund - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2017-06-01",
    "closeData": "2017-06-15",
    "isin": "INF397L01JS1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141476",
    "name": "IDBI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "IDBI Small Cap Fund IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2017-06-01",
    "closeData": "2017-06-15",
    "isin": "INF397L01JP7",
    "isinRe": "INF397L01JQ5"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141477",
    "name": "IDBI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "IDBI Small Cap Fund - IDCW Regular",
    "minAmount": "5000",
    "launchDate": "2017-06-01",
    "closeData": "2017-06-15",
    "isin": "INF397L01JT9",
    "isinRe": "INF397L01JU7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141463",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-II (1462 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-II (1462 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-22",
    "closeData": "",
    "isin": "INF789FC1A04",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141464",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-II (1462 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-II ( 1462 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-22",
    "closeData": "",
    "isin": "INF789FC1ZZ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141465",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-II (1462 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-II (1462 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-22",
    "closeData": "",
    "isin": "INF789FC1ZY1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141466",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-II (1462 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-II ( 1462 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-05-22",
    "closeData": "",
    "isin": "INF789FC1A12",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141467",
    "name": "HSBC Fixed Term Series 129 (1120 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 129 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-05-30",
    "closeData": "",
    "isin": "INF336L01MD5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141468",
    "name": "HSBC Fixed Term Series 129 (1120 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 129 - IDCW - Tenure 1120 Days - Maturity  30-Jun-2020",
    "minAmount": "5000",
    "launchDate": "2017-05-30",
    "closeData": "",
    "isin": "INF336L01ME3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141469",
    "name": "HSBC Fixed Term Series 129 (1120 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 129 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-05-30",
    "closeData": "",
    "isin": "INF336L01MB9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "141470",
    "name": "HSBC Fixed Term Series 129 (1120 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 129 - IDCW Direct Plan-Tenure 1120 Days-Maturity  30-Jun-2020",
    "minAmount": "5000",
    "launchDate": "2017-05-30",
    "closeData": "",
    "isin": "INF336L01MC7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141471",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-06",
    "closeData": "",
    "isin": "INF109KB12K3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141472",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-06",
    "closeData": "",
    "isin": "INF109KB10K7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141473",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-06",
    "closeData": "",
    "isin": "INF109KB11K5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141474",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1154 Days Plan J Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-06",
    "closeData": "",
    "isin": "INF109KB13K1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141482",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L35",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141483",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L27",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141484",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L19",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141485",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L50",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141486",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1K85",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141487",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L68",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141478",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1K93",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141479",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L43",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141480",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L01",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141481",
    "name": "UTI - FTIF - Series XXVII - Plan I (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-I (1113 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-05",
    "closeData": "",
    "isin": "INF789FC1L76",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141514",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-08",
    "closeData": "",
    "isin": "INF109KB16P3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141515",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-08",
    "closeData": "",
    "isin": "INF109KB19P7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141516",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-08",
    "closeData": "",
    "isin": "INF109KB18P9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141517",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1150 Days Plan K - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-08",
    "closeData": "",
    "isin": "INF109KB17P1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141518",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 6- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2017-06-14",
    "closeData": "",
    "isin": "INF204KB1PL0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141519",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 6- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2017-06-14",
    "closeData": "",
    "isin": "INF204KB1PJ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141520",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2017-06-14",
    "closeData": "",
    "isin": "INF204KB1PI6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141521",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 6- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2017-06-14",
    "closeData": "",
    "isin": "INF204KB1PK2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141526",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141527",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141528",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141529",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1147 Days Plan L - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141531",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1L92",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141532",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1L84",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141534",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M59",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141536",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M75",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141537",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M34",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141538",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M26",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141539",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M18",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141540",
    "name": "UTI - FTIF - Series XXVII - Plan II (1161 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-II (1161 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": "INF789FC1M67",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141541",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M - DIrect Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141542",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141543",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141544",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1143 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141545",
    "name": "Axis Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis Nifty 50 ETF",
    "minAmount": "NFO - Rs. 5000/-. Ongoing - Creation Unit Size",
    "launchDate": "2017-06-13",
    "closeData": "2017-06-21",
    "isin": "INF846K01W98",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141546",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141547",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141548",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141549",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1141 Days Plan N - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141550",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF204KB1PN6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141551",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF204KB1PM8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141552",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 7- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF204KB1PP1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141553",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF204KB1PO4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141554",
    "name": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141555",
    "name": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141556",
    "name": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141557",
    "name": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 13 - Plan A 1277 Days Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141564",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES VI",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series VI Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2017-06-23",
    "closeData": "",
    "isin": "INF903JA1AZ0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141565",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES VI",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series VI Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2017-06-23",
    "closeData": "",
    "isin": "INF903JA1AX5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141566",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES VI",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series VI Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2017-06-23",
    "closeData": "",
    "isin": "INF903JA1AY3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "141567",
    "name": "SUNDARAM LONG TERM MICRO CAP TAX ADVANTAGE FUND SERIES VI",
    "category": "ELSS",
    "navName": "Sundaram Long Term Micro Cap Tax Advantage Fund Series VI Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2017-06-23",
    "closeData": "",
    "isin": "INF903JA1BA1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141574",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N66",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141576",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N33",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141577",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N25",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141569",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1M83",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141570",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1M91",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141571",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N41",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141572",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N58",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141573",
    "name": "UTI - FTIF - Series XXVII - Plan III (1096 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-III (1096 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-06-22",
    "closeData": "",
    "isin": "INF789FC1N74",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141578",
    "name": "HDFC Equity Opp Fund - II - 1100D June 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1100D June 2017 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "2017-07-11",
    "isin": "INF179KC1AK4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141579",
    "name": "HDFC Equity Opp Fund - II - 1100D June 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1100D June 2017 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "2017-07-11",
    "isin": "INF179KC1AM0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141580",
    "name": "HDFC Equity Opp Fund - II - 1100D June 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1100D June 2017 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "2017-07-11",
    "isin": "INF179KC1AJ6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141581",
    "name": "HDFC Equity Opp Fund - II - 1100D June 2017 (1)",
    "category": "Growth",
    "navName": "HDFC Equity Opp Fund - II - 1100D June 2017 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "2017-07-11",
    "isin": "INF179KC1AL2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141582",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan C",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN C - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-06-29",
    "closeData": "",
    "isin": "INF204KB1PW7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141583",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan C- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-06-29",
    "closeData": "",
    "isin": "INF204KB1PV9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141584",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan C",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan C- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-06-29",
    "closeData": "",
    "isin": "INF204KB1PX5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141585",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan C",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN C - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-06-29",
    "closeData": "",
    "isin": "INF204KB1PU1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141586",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Direct Plan - Daily IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZN6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141587",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Direct Plan - Weekly IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZO4",
    "isinRe": "INF846K01ZP1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141588",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Direct Plan Growth",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZM8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141589",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Regular Plan - Daily IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZV9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141590",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZQ9",
    "isinRe": "INF846K01ZR7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141591",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Direct Plan - Regular IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZS5",
    "isinRe": "INF846K01ZT3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141592",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Regular Plan - Weekly IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZW7",
    "isinRe": "INF846K01ZX5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141593",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Regular Plan Growth",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZU1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141594",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Regular Plan - Monthly IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01ZY3",
    "isinRe": "INF846K01ZZ0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141595",
    "name": "AXIS Corporate Debt Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Axis Corporate Debt Fund - Regular Plan - Regular IDCW",
    "minAmount": "5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-06-23",
    "closeData": "2017-07-07",
    "isin": "INF846K01A03",
    "isinRe": "INF846K01A11"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141596",
    "name": "ICICI Prudential Nifty 100 Low Volatility 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 100 Low Volatility 30 ETF",
    "minAmount": "5000",
    "launchDate": "2017-06-23",
    "closeData": "",
    "isin": "INF109KB10T8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141597",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB12S6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141598",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB15S9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141599",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB14S2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141600",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan O - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB13S4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141601",
    "name": "ICICI Prudential Value Fund - Series 15",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 15 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB16S7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141602",
    "name": "ICICI Prudential Value Fund - Series 15",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 15 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB19S1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141603",
    "name": "ICICI Prudential Value Fund - Series 15",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 15 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB17S5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141604",
    "name": "ICICI Prudential Value Fund - Series 15",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 15 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-06-27",
    "closeData": "",
    "isin": "INF109KB18S3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141611",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-04",
    "closeData": "",
    "isin": "INF204KB1PR7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141612",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-04",
    "closeData": "",
    "isin": "INF204KB1PQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141613",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 8- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-04",
    "closeData": "",
    "isin": "INF204KB1PT3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141614",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 8- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-04",
    "closeData": "",
    "isin": "INF204KB1PS5",
    "isinRe": null
  },
  {
    "amc": "IIFCL Asset Management Co. Ltd.",
    "code": "141615",
    "name": "IIFCL MUTUAL FUND INFRASTRUCTURE DEBT FUND SERIES II",
    "category": "Income",
    "navName": "IIFCL MUTUAL FUND INFRASTRUCTURE DEBT FUND SERIES II",
    "minAmount": "2000000000",
    "launchDate": "2017-03-31",
    "closeData": "2017-04-12",
    "isin": "INF017Q01029",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141616",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV - Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-12",
    "closeData": "",
    "isin": "INF204KB1QF0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141617",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV - Series 9 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-12",
    "closeData": "",
    "isin": "INF204KB1QE3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141618",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV - Series 9 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-12",
    "closeData": "",
    "isin": "INF204KB1QD5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141619",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV - Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-12",
    "closeData": "",
    "isin": "INF204KB1QG8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141620",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O65",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141621",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O08",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141622",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O16",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141623",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O40",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141624",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O73",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141625",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O24",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141626",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O57",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141627",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1N90",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141628",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O32",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141629",
    "name": "UTI - FTIF - Series XXVII - Plan IV (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IV (1113 Days) - Regular - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-10",
    "closeData": "",
    "isin": "INF789FC1O81",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141642",
    "name": "Axis Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Axis Balanced Advantage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-07-11",
    "closeData": "2017-07-25",
    "isin": "INF846K01A29",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141643",
    "name": "Axis Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Axis Balanced Advantage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-07-11",
    "closeData": "2017-07-25",
    "isin": "INF846K01A37",
    "isinRe": "INF846K01A45"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141644",
    "name": "Axis Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Axis Balanced Advantage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-07-11",
    "closeData": "2017-07-25",
    "isin": "INF846K01A52",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141645",
    "name": "Axis Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Axis Balanced Advantage Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2017-07-11",
    "closeData": "2017-07-25",
    "isin": "INF846K01A60",
    "isinRe": "INF846K01A78"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141657",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 10- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-19",
    "closeData": "",
    "isin": "INF204KB1QI4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141658",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 10- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-19",
    "closeData": "",
    "isin": "INF204KB1QJ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141659",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 10- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-19",
    "closeData": "",
    "isin": "INF204KB1QK0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141660",
    "name": "Nippon India Fixed Horizon Fund XXXIV- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIV- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-07-19",
    "closeData": "",
    "isin": "INF204KB1QH6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141665",
    "name": "UTI - Nifty Next 50 Exchange Traded Fund.",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI Nifty Next 50 Exchange Traded Fund",
    "minAmount": "During NFO - Rs. 5000 and in multiples of Rs.1/-",
    "launchDate": "2017-07-18",
    "closeData": "",
    "isin": "INF789F1AUW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141670",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-17",
    "closeData": "",
    "isin": "INF109KB11T6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141671",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-17",
    "closeData": "",
    "isin": "INF109KB14T0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141668",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-17",
    "closeData": "",
    "isin": "INF109KB13T2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141669",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1162 Days Plan P - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-17",
    "closeData": "",
    "isin": "INF109KB12T4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141676",
    "name": "ICICI Prudential Value Fund - Series 16",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 16 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-24",
    "closeData": "",
    "isin": "INF109KB16T5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141677",
    "name": "ICICI Prudential Value Fund - Series 16",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 16 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-24",
    "closeData": "",
    "isin": "INF109KB15T7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141678",
    "name": "ICICI Prudential Value Fund - Series 16",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 16 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-24",
    "closeData": "",
    "isin": "INF109KB17T3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141679",
    "name": "ICICI Prudential Value Fund - Series 16",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 16 - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-24",
    "closeData": "",
    "isin": "INF109KB18T1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141688",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB14U8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141689",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB16U3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141686",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB13U0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141687",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1163 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB15U5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141692",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan D- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-07-31",
    "closeData": "",
    "isin": "INF204KB1QW5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141693",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan D",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN D - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-07-31",
    "closeData": "",
    "isin": "INF204KB1QT1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141690",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan D",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan D- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-07-31",
    "closeData": "",
    "isin": "INF204KB1QU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141691",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan D",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN D - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2017-07-31",
    "closeData": "",
    "isin": "INF204KB1QV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141694",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R - Cumulative option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB11U4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141695",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R - Dividend option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB12U2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141696",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R - Direct Plan - Dividend option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB10U6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141697",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan R - Direct Plan - Cumulative option",
    "minAmount": "5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF109KB19T9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141698",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-III (1389 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-III ( 1389 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF789FC1Q06",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141699",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-III (1389 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-III (1389 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF789FC1P98",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141700",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-III (1389 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series IX-III ( 1389 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF789FC1Q22",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141701",
    "name": "UTI - Capital Protection Oriented Scheme - Series IX-III (1389 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series IX-III (1389 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-27",
    "closeData": "",
    "isin": "INF789FC1Q14",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141716",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P64",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141717",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P15",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141718",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII- V (1097 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1O99",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141719",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P80",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141710",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P07",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141711",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P56",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141712",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P49",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141713",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P31",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141714",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-V (1097 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P72",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141715",
    "name": "UTI - FTIF - Series XXVII - Plan V (1097 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII- V (1097 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-07-26",
    "closeData": "",
    "isin": "INF789FC1P23",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141736",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141737",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141738",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141739",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1142 Days Plan S - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141748",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R39",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141749",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R21",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141750",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Direct Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R62",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141751",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R05",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141752",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1Q89",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141753",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1Q97",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141754",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R13",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141755",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1Q71",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141756",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R47",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141757",
    "name": "UTI - FTIF - Series XXVII - Plan VI (1113 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VI (1113 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-14",
    "closeData": "",
    "isin": "INF789FC1R54",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141758",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141759",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141760",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141761",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1133 Days Plan T - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141776",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1R88",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141777",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S12",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141778",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S53",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141769",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S38",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141770",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S20",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141771",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S46",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141773",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1R96",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141774",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1R70",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141775",
    "name": "UTI - FTIF - Series XXVII - Plan VII (1104 Days)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VII (1104 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-08-23",
    "closeData": "",
    "isin": "INF789FC1S04",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141779",
    "name": "UTI - Dual Advantage Fixed Term Fund Series V - I (1103 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund  Series V-I ( 1103 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF789FC1S87",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141780",
    "name": "UTI - Dual Advantage Fixed Term Fund Series V - I (1103 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund  Series V-I ( 1103 days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF789FC1S95",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141781",
    "name": "UTI - Dual Advantage Fixed Term Fund Series V - I (1103 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund  Series V-I ( 1103 days) - Direct  Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF789FC1T03",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141782",
    "name": "UTI - Dual Advantage Fixed Term Fund Series V - I (1103 Days)",
    "category": "Income",
    "navName": "UTI Dual Advantage Fixed Term Fund  Series V-I ( 1103 days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF789FC1S79",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141783",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141784",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141785",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141786",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1119 Days Plan U - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141787",
    "name": "ICICI Prudential Value Fund - Series 17",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 17 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF109KB10W2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141788",
    "name": "ICICI Prudential Value Fund - Series 17",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 17 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF109KB19V5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141789",
    "name": "ICICI Prudential Value Fund - Series 17",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 17 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF109KB12W8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141790",
    "name": "ICICI Prudential Value Fund - Series 17",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 17 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-08-29",
    "closeData": "",
    "isin": "INF109KB11W0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141791",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-06",
    "closeData": "",
    "isin": "INF204KB1RG6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141792",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 5- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-06",
    "closeData": "",
    "isin": "INF204KB1RH4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141793",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 5- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-06",
    "closeData": "",
    "isin": "INF204KB1RI2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141794",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 5- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-06",
    "closeData": "",
    "isin": "INF204KB1RJ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141795",
    "name": "Kotak FMP Series 209",
    "category": "Income",
    "navName": "Kotak FMP Series 209-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-08-30",
    "closeData": "",
    "isin": "INF174K015P3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141796",
    "name": "Kotak FMP Series 209",
    "category": "Income",
    "navName": "Kotak FMP Series 209-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-08-30",
    "closeData": "",
    "isin": "INF174K016P1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141797",
    "name": "Kotak FMP Series 209",
    "category": "Income",
    "navName": "Kotak FMP Series 209-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2017-08-30",
    "closeData": "",
    "isin": "INF174K018P7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141798",
    "name": "Kotak FMP Series 209",
    "category": "Income",
    "navName": "Kotak FMP Series 209-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-08-30",
    "closeData": "",
    "isin": "INF174K017P9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141799",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan E- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF204KB1RN2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141800",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan E",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN E - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF204KB1RK8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141801",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan E",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XI- Plan E- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF204KB1RL6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141802",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XI- Plan E",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XI - PLAN E - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF204KB1RM4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141803",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141804",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-09-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141805",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141806",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan V - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-09-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "141807",
    "name": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)- Direct Plan- Dividend Option",
    "minAmount": "Rs. 500 and in multiples of Re. 500 thereafter",
    "launchDate": "2017-09-21",
    "closeData": "2017-12-20",
    "isin": "INF666M01DL5",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "141808",
    "name": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)- Direct Plan- Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 500 thereafter",
    "launchDate": "2017-09-21",
    "closeData": "2017-12-20",
    "isin": "INF666M01DK7",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "141810",
    "name": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option (Payout)",
    "minAmount": "Rs. 500 and in multiples of Re. 500 thereafter",
    "launchDate": "2017-09-21",
    "closeData": "2017-12-20",
    "isin": "INF666M01DO9",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "141862",
    "name": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Groww ELSS Tax Saver Fund (formerly known as Indiabulls Tax Savings Fund)-Regular Plan-Growth Option",
    "minAmount": "Rs. 500 and in multiples of Re. 500 thereafter",
    "launchDate": "2017-09-21",
    "closeData": "2017-12-20",
    "isin": "INF666M01DN1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141815",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1U00",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141816",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1U26",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141824",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1T60",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141818",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1T86",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141819",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1T52",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141820",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1T94",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141821",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1U34",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141822",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1U18",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141823",
    "name": "UTI - FTIF SERIES XXVII - PLAN VIII (1117 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-VIII (1117 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-07",
    "closeData": "",
    "isin": "INF789FC1T78",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141825",
    "name": "UTI - Focussed Equity Fund - Series IV (1104 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series IV (1104 Days) - Regular Plan - Growth Option",
    "minAmount": "The minimum investment amount is  5000/- and in m",
    "launchDate": "2017-09-13",
    "closeData": "",
    "isin": "INF789FC1T29",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141826",
    "name": "UTI - Focussed Equity Fund - Series IV (1104 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series IV (1104 Days) - Direct Plan - Dividend  Option",
    "minAmount": "The minimum investment amount is  5000/- and in m",
    "launchDate": "2017-09-13",
    "closeData": "",
    "isin": "INF789FC1T37",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141827",
    "name": "UTI - Focussed Equity Fund - Series IV (1104 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series IV (1104 Days) - Direct Plan - Growth Option",
    "minAmount": "The minimum investment amount is  5000/- and in m",
    "launchDate": "2017-09-13",
    "closeData": "",
    "isin": "INF789FC1T45",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141828",
    "name": "UTI - Focussed Equity Fund - Series IV (1104 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series IV (1104 Days) - Regular Plan - Dividend Option",
    "minAmount": "The minimum investment amount is  5000/- and in m",
    "launchDate": "2017-09-13",
    "closeData": "",
    "isin": "INF789FC1T11",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141835",
    "name": "Kotak FMP Series 210",
    "category": "Income",
    "navName": "Kotak FMP Series 210-Regular plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2017-09-12",
    "closeData": "",
    "isin": "INF174K010Q2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141836",
    "name": "Kotak FMP Series 210",
    "category": "Income",
    "navName": "Kotak FMP Series 210 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2017-09-12",
    "closeData": "",
    "isin": "INF174K012Q8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141837",
    "name": "Kotak FMP Series 210",
    "category": "Income",
    "navName": "Kotak FMP Series 210-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-09-12",
    "closeData": "",
    "isin": "INF174K011Q0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141838",
    "name": "Kotak FMP Series 210",
    "category": "Income",
    "navName": "Kotak FMP Series 210-Regular Plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2017-09-12",
    "closeData": "",
    "isin": "INF174K019P5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141839",
    "name": "ICICI Prudential S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential S&P BSE Sensex Index Fund - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-14",
    "closeData": "",
    "isin": "INF109KB17W7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141840",
    "name": "ICICI Prudential S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential S&P BSE Sensex Index Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-09-14",
    "closeData": "",
    "isin": "INF109KB19W3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141841",
    "name": "ICICI Prudential S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential S&P BSE Sensex Index Fund - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-14",
    "closeData": "",
    "isin": "INF109KB10X0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141842",
    "name": "ICICI Prudential S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential S&P BSE Sensex Index Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-09-14",
    "closeData": "",
    "isin": "INF109KB12X6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141843",
    "name": "UTI - FTIF SERIES XXVII - PLAN IX (1160 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IX (1160 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-19",
    "closeData": "",
    "isin": "INF789FC1V33",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141845",
    "name": "UTI - FTIF SERIES XXVII - PLAN IX (1160 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IX (1160 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-19",
    "closeData": "",
    "isin": "INF789FC1V09",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141850",
    "name": "UTI - FTIF SERIES XXVII - PLAN IX (1160 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-IX (1160 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-09-19",
    "closeData": "",
    "isin": "INF789FC1U83",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141855",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-09-25",
    "closeData": "",
    "isin": "INF109KB10Y8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141856",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-25",
    "closeData": "",
    "isin": "INF109KB17X5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141853",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-09-25",
    "closeData": "",
    "isin": "INF109KB18X3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141854",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 81 - 1100 Days Plan W - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-25",
    "closeData": "",
    "isin": "INF109KB19X1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141857",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days - IDCW OPtion",
    "minAmount": "5000",
    "launchDate": "2017-09-21",
    "closeData": "",
    "isin": "INF109KB16X7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141858",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-21",
    "closeData": "",
    "isin": "INF109KB15X9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141859",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-09-21",
    "closeData": "",
    "isin": "INF109KB13X4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141860",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XII - Plan C 1270 Days - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-09-21",
    "closeData": "",
    "isin": "INF109KB14X2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141867",
    "name": "Nippon India Capital Builder Fund IV- Series A",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series A- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-29",
    "closeData": "",
    "isin": "INF204KB1RO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141868",
    "name": "Nippon India Capital Builder Fund IV- Series A",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series A- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-29",
    "closeData": "",
    "isin": "INF204KB1RQ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141869",
    "name": "Nippon India Capital Builder Fund IV- Series A",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series A- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-29",
    "closeData": "",
    "isin": "INF204KB1RR3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141870",
    "name": "Nippon India Capital Builder Fund IV- Series A",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series A- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-29",
    "closeData": "",
    "isin": "INF204KB1RP7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "141875",
    "name": "DSP Nifty 50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Equal Weight Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2017-09-29",
    "closeData": "2017-10-13",
    "isin": "INF740KA1CO4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "141876",
    "name": "DSP Nifty 50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Equal Weight Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2017-09-29",
    "closeData": "2017-10-13",
    "isin": "INF740KA1CP1",
    "isinRe": "INF740KA1CQ9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "141877",
    "name": "DSP Nifty 50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Equal Weight Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2017-09-29",
    "closeData": "2017-10-13",
    "isin": "INF740KA1CR7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "141878",
    "name": "DSP Nifty 50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Equal Weight Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter",
    "launchDate": "2017-09-29",
    "closeData": "2017-10-13",
    "isin": "INF740KA1CS5",
    "isinRe": "INF740KA1CT3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141879",
    "name": "ICICI Prudential Value Fund - Series 18",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 18 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-10-03",
    "closeData": "",
    "isin": "INF109KB11Y6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141880",
    "name": "ICICI Prudential Value Fund - Series 18",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 18 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-10-03",
    "closeData": "",
    "isin": "INF109KB14Y0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141881",
    "name": "ICICI Prudential Value Fund - Series 18",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 18 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-10-03",
    "closeData": "",
    "isin": "INF109KB12Y4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141882",
    "name": "ICICI Prudential Value Fund - Series 18",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 18 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-10-03",
    "closeData": "",
    "isin": "INF109KB13Y2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141883",
    "name": "Kotak FMP Series 211",
    "category": "Income",
    "navName": "Kotak FMP Series 211-Regular Plan -Growth option",
    "minAmount": "200000000",
    "launchDate": "2017-10-04",
    "closeData": "",
    "isin": "INF174K013Q6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141884",
    "name": "Kotak FMP Series 211",
    "category": "Income",
    "navName": "Kotak FMP Series 211-Regular Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2017-10-04",
    "closeData": "",
    "isin": "INF174K014Q4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141885",
    "name": "Kotak FMP Series 211",
    "category": "Income",
    "navName": "Kotak FMP Series 211-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-10-04",
    "closeData": "",
    "isin": "INF174K015Q1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141886",
    "name": "Kotak FMP Series 211",
    "category": "Income",
    "navName": "Kotak FMP Series 211-Direct Plan-Dividend option",
    "minAmount": "200000000",
    "launchDate": "2017-10-04",
    "closeData": "",
    "isin": "INF174K016Q9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141891",
    "name": "UTI - Long Term Advantage Fund Series VI",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series VI - Regular Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-10-05",
    "closeData": "",
    "isin": "INF789FC1V58",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141892",
    "name": "UTI - Long Term Advantage Fund Series VI",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series VI - Direct Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-10-05",
    "closeData": "",
    "isin": "INF789FC1V82",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141893",
    "name": "UTI - Long Term Advantage Fund Series VI",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series VI - Regular Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-10-05",
    "closeData": "",
    "isin": "INF789FC1V66",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141894",
    "name": "UTI - Long Term Advantage Fund Series VI",
    "category": "ELSS",
    "navName": "UTI Long Term Advantage Fund Series VI - Direct Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-10-05",
    "closeData": "",
    "isin": "INF789FC1V74",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141895",
    "name": "Nippon India Capital Builder Fund IV - Series B",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV - Series B - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1thereafter",
    "launchDate": "2017-10-23",
    "closeData": "",
    "isin": "INF204KB1RS1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141896",
    "name": "Nippon India Capital Builder Fund IV - Series B",
    "category": "Growth",
    "navName": "NIPPON INDIA CAPITAL BUILDER FUND IV - SERIES B - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1thereafter",
    "launchDate": "2017-10-23",
    "closeData": "",
    "isin": "INF204KB1RT9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141897",
    "name": "Nippon India Capital Builder Fund IV - Series B",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV - Series B - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1thereafter",
    "launchDate": "2017-10-23",
    "closeData": "",
    "isin": "INF204KB1RU7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141898",
    "name": "Nippon India Capital Builder Fund IV - Series B",
    "category": "Growth",
    "navName": "NIPPON INDIA CAPITAL BUILDER FUND IV - SERIES B - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1thereafter",
    "launchDate": "2017-10-23",
    "closeData": "",
    "isin": "INF204KB1RV5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141899",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W73",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141900",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Direct Plan - Maturity Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W65",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141907",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W24",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141901",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Regular Plan - Annual Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W08",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141902",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Regular Plan - Maturity Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W16",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141903",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Regular Plan - Flexi Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W32",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141904",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Direct Plan - Quarterly Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W40",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141905",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Regular Plan - Quarterly Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1V90",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141906",
    "name": "UTI - FTIF SERIES XXVII - PLAN X (1118 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVII-X (1118 Days) - Direct Plan - Annual Div Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-10-18",
    "closeData": "",
    "isin": "INF789FC1W57",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141909",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF204KB1RW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141910",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 6- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF204KB1RY9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141911",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 6 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF204KB1RZ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141912",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 6 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF204KB1RX1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141917",
    "name": "IDBI FOCUSED 30 EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "IDBI FOCUSED 30 EQUITY FUND - IDCW Regular",
    "minAmount": "5000",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF397L01KB5",
    "isinRe": "INF397L01KC3"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141918",
    "name": "IDBI FOCUSED 30 EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "IDBI FOCUSED 30 EQUITY FUND -IDCW Direct",
    "minAmount": "5000",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF397L01JX1",
    "isinRe": "INF397L01JY9"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141919",
    "name": "IDBI FOCUSED 30 EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "IDBI FOCUSED 30 EQUITY FUND - Growth Regular",
    "minAmount": "5000",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF397L01KE9",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "141920",
    "name": "IDBI FOCUSED 30 EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "IDBI FOCUSED 30 EQUITY FUND - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2017-10-30",
    "closeData": "",
    "isin": "INF397L01KA7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141921",
    "name": "HDFC Housing Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Housing Opportunities Fund  - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-11-16",
    "closeData": "2017-11-30",
    "isin": "INF179KC1AV1",
    "isinRe": "INF179KC1AW9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141922",
    "name": "HDFC Housing Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Housing Opportunities Fund  - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-11-16",
    "closeData": "2017-11-30",
    "isin": "INF179KC1AY5",
    "isinRe": "INF179KC1AZ2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141923",
    "name": "HDFC Housing Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Housing Opportunities Fund  - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-11-16",
    "closeData": "2017-11-30",
    "isin": "INF179KC1AX7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "141924",
    "name": "HDFC Housing Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Housing Opportunities Fund  - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2017-11-16",
    "closeData": "2017-11-30",
    "isin": "INF179KC1AU3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141925",
    "name": "Axis Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Axis Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5 000 and in multiples of Re. 1/-",
    "launchDate": "2017-10-30",
    "closeData": "2017-11-13",
    "isin": "INF846K01B28",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141926",
    "name": "Axis Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Axis Flexi Cap fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5 000 and in multiples of Re. 1/-",
    "launchDate": "2017-10-30",
    "closeData": "2017-11-13",
    "isin": "INF846K01B02",
    "isinRe": "INF846K01B10"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141927",
    "name": "Axis Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Axis Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5 000 and in multiples of Re. 1/-",
    "launchDate": "2017-10-30",
    "closeData": "2017-11-13",
    "isin": "INF846K01B51",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "141928",
    "name": "Axis Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Axis Flexi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5 000 and in multiples of Re. 1/-",
    "launchDate": "2017-10-30",
    "closeData": "2017-11-13",
    "isin": "INF846K01B36",
    "isinRe": "INF846K01B44"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141935",
    "name": "Kotak FMP Series 212",
    "category": "Income",
    "navName": "Kotak FMP Series 212-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-11-06",
    "closeData": "",
    "isin": "INF174K017Q7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141936",
    "name": "Kotak FMP Series 212",
    "category": "Income",
    "navName": "Kotak FMP Series 212-Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2017-11-06",
    "closeData": "",
    "isin": "INF174K018Q5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141937",
    "name": "Kotak FMP Series 212",
    "category": "Income",
    "navName": "Kotak FMP Series 212-Direct plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2017-11-06",
    "closeData": "",
    "isin": "INF174K019Q3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "141938",
    "name": "Kotak FMP Series 212",
    "category": "Income",
    "navName": "Kotka FMP Series 212-Direct plan -Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2017-11-06",
    "closeData": "",
    "isin": "INF174K010R0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141939",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-I (1230 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1Y14",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141940",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X80",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141942",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X31",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141943",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X49",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141944",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X72",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141945",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X98",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141947",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-I (1230 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X64",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141948",
    "name": "UTI - FTIF SERIES XXVIII - PLAN I (1230 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN I (1230 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2017-11-08",
    "closeData": "",
    "isin": "INF789FC1X56",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141949",
    "name": "BANK OF INDIA MID CAP TAX FUND SERIES 1",
    "category": "ELSS",
    "navName": "BANK OF INDIA Mid Cap Tax Fund Series 1 Direct Plan IDCW",
    "minAmount": "500",
    "launchDate": "2017-11-10",
    "closeData": "2018-02-09",
    "isin": "INF761K01DT1",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141950",
    "name": "BANK OF INDIA MID CAP TAX FUND SERIES 1",
    "category": "ELSS",
    "navName": "BANK OF INDIA Mid Cap Tax Fund Series 1 Direct Plan Growth",
    "minAmount": "500",
    "launchDate": "2017-11-10",
    "closeData": "2018-02-09",
    "isin": "INF761K01DS3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141951",
    "name": "BANK OF INDIA MID CAP TAX FUND SERIES 1",
    "category": "ELSS",
    "navName": "BANK OF INDIA Mid Cap Tax Fund Series 1 Regular Plan IDCW",
    "minAmount": "500",
    "launchDate": "2017-11-10",
    "closeData": "2018-02-09",
    "isin": "INF761K01DV7",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "141952",
    "name": "BANK OF INDIA MID CAP TAX FUND SERIES 1",
    "category": "ELSS",
    "navName": "BANK OF INDIA Mid Cap Tax Fund Series 1 Regular Plan Growth",
    "minAmount": "500",
    "launchDate": "2017-11-10",
    "closeData": "2018-02-09",
    "isin": "INF761K01DU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141953",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 7- IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-15",
    "closeData": "",
    "isin": "INF204KB1SB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141954",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 7 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-15",
    "closeData": "",
    "isin": "INF204KB1SD1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141955",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-15",
    "closeData": "",
    "isin": "INF204KB1SA7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141956",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-15",
    "closeData": "",
    "isin": "INF204KB1SC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "141957",
    "name": "BHARAT 22 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BHARAT 22 ETF",
    "minAmount": "5000",
    "launchDate": "2017-11-14",
    "closeData": "",
    "isin": "INF109KB15Y7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141989",
    "name": "UTI - Focussed Equity Fund - Series V (1102 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series V (1102 Days) - Regular Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-11-20",
    "closeData": "",
    "isin": "INF789FC1W99",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141990",
    "name": "UTI - Focussed Equity Fund - Series V (1102 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series V (1102 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-11-20",
    "closeData": "",
    "isin": "INF789FC1X07",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141987",
    "name": "UTI - Focussed Equity Fund - Series V (1102 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series V (1102 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-11-20",
    "closeData": "",
    "isin": "INF789FC1X23",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "141988",
    "name": "UTI - Focussed Equity Fund - Series V (1102 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series V (1102 Days) - Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2017-11-20",
    "closeData": "",
    "isin": "INF789FC1X15",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141991",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 9- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF204KB1SK6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141992",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 9 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF204KB1SL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141993",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF204KB1SI0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "141994",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 9 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF204KB1SJ8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "141999",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan A 1224 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01ML7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142000",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan A 1224 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01MO1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142001",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan A(1224 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01MK9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142002",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan A(1224 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01MN3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142003",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan A 1224 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01MM5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142004",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan A(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan A 1224 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-11-24",
    "closeData": "",
    "isin": "INF090I01MP8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142005",
    "name": "Nippon India Capital Builder Fund IV- Series C",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series C- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-01",
    "closeData": "",
    "isin": "INF204KB1SS9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142006",
    "name": "Nippon India Capital Builder Fund IV- Series C",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series C- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-01",
    "closeData": "",
    "isin": "INF204KB1ST7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142007",
    "name": "Nippon India Capital Builder Fund IV- Series C",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series C- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-01",
    "closeData": "",
    "isin": "INF204KB1SQ3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142008",
    "name": "Nippon India Capital Builder Fund IV- Series C",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series C- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-01",
    "closeData": "",
    "isin": "INF204KB1SR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142017",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB10Z5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142018",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142019",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142020",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB13Z9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142067",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB12Z1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142068",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB15Z4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142069",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB11Z3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142070",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1236 Days Plan A - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-11-29",
    "closeData": "",
    "isin": "INF109KB14Z7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142021",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-11",
    "closeData": "",
    "isin": "INF204KB1SU5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142022",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 11- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-11",
    "closeData": "",
    "isin": "INF204KB1SW1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142023",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 11 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-11",
    "closeData": "",
    "isin": "INF204KB1SX9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142024",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 11 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-11",
    "closeData": "",
    "isin": "INF204KB1SV3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142025",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-II (1210 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y63",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142026",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y48",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142027",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y30",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142028",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y55",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142029",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y71",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142030",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-II (1210 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Z13",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142031",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y89",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142032",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Z21",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142033",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Y97",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142034",
    "name": "UTI - FTIF SERIES XXVIII - PLAN II (1210 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN II (1210 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-11-28",
    "closeData": "",
    "isin": "INF789FC1Z05",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142035",
    "name": "Union Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Union Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-12-07",
    "closeData": "",
    "isin": "INF582M01DI0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142036",
    "name": "Union Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Union Balanced Advantage Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-12-07",
    "closeData": "",
    "isin": "INF582M01DK6",
    "isinRe": "INF582M01DJ8"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142037",
    "name": "Union Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Union Balanced Advantage Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-12-07",
    "closeData": "",
    "isin": "INF582M01DG4",
    "isinRe": "INF582M01DF6"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "142038",
    "name": "Union Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Union Balanced Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2017-12-07",
    "closeData": "",
    "isin": "INF582M01DE9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142071",
    "name": "ICICI Prudential Value Fund - Series 19",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 19 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KB16Z2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142072",
    "name": "ICICI Prudential Value Fund - Series 19",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 19 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KB18Z8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142073",
    "name": "ICICI Prudential Value Fund - Series 19",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 19 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KB19Z6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142074",
    "name": "ICICI Prudential Value Fund - Series 19",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 19 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KB17Z0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142075",
    "name": "Kotak FMP Series 213",
    "category": "Income",
    "navName": "Kotak FMP Series 213-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-12-08",
    "closeData": "",
    "isin": "INF174K011R8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142076",
    "name": "Kotak FMP Series 213",
    "category": "Income",
    "navName": "Kotak FMP Series 213-Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2017-12-08",
    "closeData": "",
    "isin": "INF174K012R6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142077",
    "name": "Kotak FMP Series 213",
    "category": "Income",
    "navName": "Kotak FMP Series 213-Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2017-12-08",
    "closeData": "",
    "isin": "INF174K014R2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142078",
    "name": "Kotak FMP Series 213",
    "category": "Income",
    "navName": "Kotak FMP Series 213-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2017-12-08",
    "closeData": "",
    "isin": "INF174K013R4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142085",
    "name": "HSBC Fixed Term Series 130 (1204 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 130 - IDCW - Tenure 1204 Days - Maturity  08-Apr-2021",
    "minAmount": "5000",
    "launchDate": "2017-12-15",
    "closeData": "",
    "isin": "INF336L01MI4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142086",
    "name": "HSBC Fixed Term Series 130 (1204 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 130 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2017-12-15",
    "closeData": "",
    "isin": "INF336L01MH6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142087",
    "name": "HSBC Fixed Term Series 130 (1204 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 130 - IDCW Direct Plan-Tenure 1204 Days-Maturity  08-Apr-2021",
    "minAmount": "5000",
    "launchDate": "2017-12-15",
    "closeData": "",
    "isin": "INF336L01MF0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142088",
    "name": "HSBC Fixed Term Series 130 (1204 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 130 - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-12-15",
    "closeData": "",
    "isin": "INF336L01MG8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142093",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-III (1203 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC11B1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142094",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC19A6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142095",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC18A8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142102",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC17A0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142096",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC12B9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142097",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-III (1203 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC16A2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142098",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC14A7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142099",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC10B3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142100",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC13A9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142101",
    "name": "UTI - FTIF SERIES XXVIII - PLAN III (1203 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN III (1203 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-14",
    "closeData": "",
    "isin": "INF789FC15A4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142103",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XII- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XII- Plan A- Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF204KB1TN8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142104",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XII- Plan A",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XII - PLAN A - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF204KB1TM0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142105",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XII- Plan A",
    "category": "Income",
    "navName": "NIPPON INDIA DUAL ADVANTAGE FIXED TENURE FUND XII - PLAN A - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF204KB1TK4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142106",
    "name": "Nippon India Dual Advantege Fixed Tenure Fund XII- Plan A",
    "category": "Income",
    "navName": "Nippon India Dual Advantage Fixed Tenure Fund XII- Plan A- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF204KB1TL2",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "142107",
    "name": "Mahindra Manulife Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mahindra Manulife Mid Cap Fund- Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-01-08",
    "closeData": "",
    "isin": "INF174V01499",
    "isinRe": "INF174V01481"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "142108",
    "name": "Mahindra Manulife Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mahindra Manulife Mid Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-01-08",
    "closeData": "",
    "isin": "INF174V01523",
    "isinRe": "INF174V01515"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "142109",
    "name": "Mahindra Manulife Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mahindra Manulife Mid Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-01-08",
    "closeData": "",
    "isin": "INF174V01473",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "142110",
    "name": "Mahindra Manulife Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mahindra Manulife Mid Cap Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-01-08",
    "closeData": "",
    "isin": "INF174V01507",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142115",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan B(1224 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MR4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142116",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan B 1224 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MS2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142111",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan B(1224 days)- Dividend -Direct",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MU8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142112",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan B(1224 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MT0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142113",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan B 1224 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MQ6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142114",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan B(1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan B(1224 days)- Quarterly Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2017-12-19",
    "closeData": "",
    "isin": "INF090I01MV6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142117",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 12- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF204KB1TA5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142118",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 12 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF204KB1TB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142119",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 12 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF204KB1SZ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142120",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 12- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF204KB1SY7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142121",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 13",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 13 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF204KB1TD9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142122",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF204KB1TC1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142123",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 13",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 13- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF204KB1TE7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142124",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 13",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 13 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF204KB1TF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142125",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1010",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142126",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1044",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142127",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142128",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142129",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1051",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142130",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1028",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142131",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1036",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142132",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1225 Days Plan B - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF109KC1069",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142133",
    "name": "ICICI Prudential Long Term Wealth Enhancement Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential Long Term Wealth Enhancement Fund - Direct Plan IDCW Option",
    "minAmount": "500",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF109KC1101",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142134",
    "name": "ICICI Prudential Long Term Wealth Enhancement Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential Long Term Wealth Enhancement Fund - Direct Plan Cumulative Option",
    "minAmount": "500",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF109KC1093",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142135",
    "name": "ICICI Prudential Long Term Wealth Enhancement Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential Long Term Wealth Enhancement Fund - IDCW Option",
    "minAmount": "500",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF109KC1085",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142136",
    "name": "ICICI Prudential Long Term Wealth Enhancement Fund",
    "category": "ELSS",
    "navName": "ICICI Prudential Long Term Wealth Enhancement Fund - Cumulative Option",
    "minAmount": "500",
    "launchDate": "2017-12-22",
    "closeData": "",
    "isin": "INF109KC1077",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "142137",
    "name": "SBI Long Term Advantage Fund - Series V",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series V - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2017-12-21",
    "closeData": "2018-03-20",
    "isin": "INF200KA1RV0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "142138",
    "name": "SBI Long Term Advantage Fund - Series V",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series V - Direct Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2017-12-21",
    "closeData": "2018-03-20",
    "isin": "INF200KA1RW8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "142139",
    "name": "SBI Long Term Advantage Fund - Series V",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund Series V - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2017-12-21",
    "closeData": "2018-03-20",
    "isin": "INF200KA1RU2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "142140",
    "name": "SBI Long Term Advantage Fund - Series V",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series V - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2017-12-21",
    "closeData": "2018-03-20",
    "isin": "INF200KA1RX6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142141",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan C 1205 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01NA8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142142",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan C 1205 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01MY0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142143",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan C(1205 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01MW4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142144",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan C(1205 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01MZ7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142145",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 2 - Plan C 1205 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01MX2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142146",
    "name": "Franklin India Fixed Maturity Plans- Series 2 - Plan C(1205 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 2- Plan C (1205 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2017-12-26",
    "closeData": "",
    "isin": "INF090I01NB6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142147",
    "name": "Nippon India Capital Builder Fund IV- Series D",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series D- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-29",
    "closeData": "",
    "isin": "INF204KB1TG2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142148",
    "name": "Nippon India Capital Builder Fund IV- Series D",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series D- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-29",
    "closeData": "",
    "isin": "INF204KB1TH0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142149",
    "name": "Nippon India Capital Builder Fund IV- Series D",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series D- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-29",
    "closeData": "",
    "isin": "INF204KB1TI8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142150",
    "name": "Nippon India Capital Builder Fund IV- Series D",
    "category": "Growth",
    "navName": "Nippon India Capital Builder Fund IV- Series D- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-12-29",
    "closeData": "",
    "isin": "INF204KB1TJ6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142151",
    "name": "Sundaram Long Term Tax Advantage Fund Series III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series III Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2017-12-27",
    "closeData": "",
    "isin": "INF903JA1BR5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142152",
    "name": "Sundaram Long Term Tax Advantage Fund Series III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series III Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2017-12-27",
    "closeData": "",
    "isin": "INF903JA1BS3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142153",
    "name": "Sundaram Long Term Tax Advantage Fund Series III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series III Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2017-12-27",
    "closeData": "",
    "isin": "INF903JA1BT1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142154",
    "name": "Sundaram Long Term Tax Advantage Fund Series III",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series III Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2017-12-27",
    "closeData": "",
    "isin": "INF903JA1BU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142155",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1119",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142156",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1143",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142157",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142158",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1127",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142159",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1168",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142160",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142161",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1135",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142162",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1217 Days Plan C - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF109KC1150",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142169",
    "name": "UTI - Long Term Advantage Fund Series VII",
    "category": "Growth",
    "navName": "UTI Long Term Advantage Fund Series VII - Regular Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF789FC16D6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142170",
    "name": "UTI - Long Term Advantage Fund Series VII",
    "category": "Growth",
    "navName": "UTI Long Term Advantage Fund Series VII - Direct Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF789FC17D4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142171",
    "name": "UTI - Long Term Advantage Fund Series VII",
    "category": "Growth",
    "navName": "UTI Long Term Advantage Fund Series VII - Regular Plan - IDCW",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF789FC15D8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142172",
    "name": "UTI - Long Term Advantage Fund Series VII",
    "category": "Growth",
    "navName": "UTI Long Term Advantage Fund Series VII - Direct Plan - Growth Option",
    "minAmount": "Rs 500 and in multiple of Rs 500",
    "launchDate": "2017-12-21",
    "closeData": "",
    "isin": "INF789FC18D2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142181",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 14- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-03",
    "closeData": "",
    "isin": "INF204KB1TO6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142182",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 14 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-03",
    "closeData": "",
    "isin": "INF204KB1TP3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142183",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 14- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-03",
    "closeData": "",
    "isin": "INF204KB1TQ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142184",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 14 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-03",
    "closeData": "",
    "isin": "INF204KB1TR9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142193",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 15- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TS7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142194",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 15 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142191",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 15 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TT5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142192",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 15- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TU3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142195",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 16",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 16- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TW9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142196",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 16",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 16 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TX7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142197",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 16",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXV - SERIES 16 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TZ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142198",
    "name": "Nippon India Fixed Horizon Fund XXXV- Series 16",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXV- Series 16- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-01-05",
    "closeData": "",
    "isin": "INF204KB1TY5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142201",
    "name": "UTI - FTIF SERIES XXVIII - PLAN IV (1204 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-IV (1204 Days) Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF789FC13C5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142208",
    "name": "UTI - FTIF SERIES XXVIII - PLAN IV (1204 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-IV (1204 Days) Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2017-12-28",
    "closeData": "",
    "isin": "INF789FC18B6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142219",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC12D5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142220",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC10D9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142222",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-V (1190 Days) Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC18C4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142223",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC17C6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142224",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC15C0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142225",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-V (1190 Days) Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC13D3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142226",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC16C8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142227",
    "name": "UTI - FTIF SERIES XXVIII - PLAN V (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN V (1190 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-01-01",
    "closeData": "2018-01-10",
    "isin": "INF789FC11D7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142233",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1176",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142234",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1200",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142235",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142236",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1184",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142237",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1226",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142238",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1192",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142239",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": "INF109KC1218",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142240",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1219 Days Plan D - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142241",
    "name": "Kotak FMP Series 214",
    "category": "Income",
    "navName": "Kotak FMP Series 214-RegularPlan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-01-04",
    "closeData": "",
    "isin": "INF174K015R9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142242",
    "name": "Kotak FMP Series 214",
    "category": "Income",
    "navName": "Kotak FMP Series 214-Regular Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-01-04",
    "closeData": "",
    "isin": "INF174K016R7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142243",
    "name": "Kotak FMP Series 214",
    "category": "Income",
    "navName": "Kotak FMP Series 214-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-01-04",
    "closeData": "",
    "isin": "INF174K017R5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142244",
    "name": "Kotak FMP Series 214",
    "category": "Income",
    "navName": "Kotak FMP Series 214-Direct Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-01-04",
    "closeData": "",
    "isin": "INF174K018R3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142260",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142261",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1283",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142262",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1317",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142263",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142264",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1291",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142265",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1325",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142266",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1309",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142267",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-12",
    "closeData": "",
    "isin": "INF109KC1275",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142268",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VI (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VI (1190 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-10",
    "closeData": "",
    "isin": "INF789FC17E2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142269",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VI (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VI (1190 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-10",
    "closeData": "",
    "isin": "INF789FC12E3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142272",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VI (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VI (1190 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-10",
    "closeData": "",
    "isin": "INF789FC14E9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142275",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VI (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VI (1190 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-10",
    "closeData": "",
    "isin": "INF789FC18E0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142277",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VI (1190 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VI (1190 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-10",
    "closeData": "",
    "isin": "INF789FC19D0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142278",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Regular - IDCW - Monthly",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DL8",
    "isinRe": "INF740KA1DM6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142279",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Direct - IDCW",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DO2",
    "isinRe": "INF740KA1DP9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142280",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Regular - IDCW",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DJ2",
    "isinRe": "INF740KA1DK0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142281",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Direct - IDCW - Monthly",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DQ7",
    "isinRe": "INF740KA1DR5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142282",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Regular - Growth",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DI4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142283",
    "name": "DSP Arbitrage fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "DSP Arbitrage Fund - Direct - Growth",
    "minAmount": "Rs 1000 /- and any amount after that.",
    "launchDate": "2018-01-15",
    "closeData": "2018-01-22",
    "isin": "INF740KA1DN4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142284",
    "name": "UTI - Focussed Equity Fund - Series VI (1150 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series VI (1150 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-01-22",
    "closeData": "",
    "isin": "INF789FC12G8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142285",
    "name": "UTI - Focussed Equity Fund - Series VI (1150 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series VI (1150 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-01-22",
    "closeData": "",
    "isin": "INF789FC10G2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142286",
    "name": "UTI - Focussed Equity Fund - Series VI (1150 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series VI (1150 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-01-22",
    "closeData": "",
    "isin": "INF789FC19F5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142287",
    "name": "UTI - Focussed Equity Fund - Series VI (1150 Days)",
    "category": "Growth",
    "navName": "UTI Focussed Equity Fund - Series VI (1150 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-01-22",
    "closeData": "",
    "isin": "INF789FC11G0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142288",
    "name": "ICICI Prudential Value Fund - Series 20",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 20 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF109KC1333",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142289",
    "name": "ICICI Prudential Value Fund - Series 20",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 20 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF109KC1358",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142290",
    "name": "ICICI Prudential Value Fund - Series 20",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 20 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF109KC1341",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142291",
    "name": "ICICI Prudential Value Fund - Series 20",
    "category": "Growth",
    "navName": "ICICI Prudential Value Fund - Series 20 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF109KC1366",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142298",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1374",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142299",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1408",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142292",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142293",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142294",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1424",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142295",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1382",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142296",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1416",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142297",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1187 Days Plan F - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-18",
    "closeData": "",
    "isin": "INF109KC1390",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142312",
    "name": "Kotak FMP Series 215",
    "category": "Income",
    "navName": "Kotak FMP Series 215-Direct Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF174K012S4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142313",
    "name": "Kotak FMP Series 215",
    "category": "Income",
    "navName": "Kotak FMP Series 215-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF174K011S6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142314",
    "name": "Kotak FMP Series 215",
    "category": "Income",
    "navName": "Kotka FMP Series 215-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF174K019R1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142315",
    "name": "Kotak FMP Series 215",
    "category": "Income",
    "navName": "Kotak FMP Series 215-Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF174K010S8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142326",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VII (1169 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC17F9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142327",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VII (1169 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC12F0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142328",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC10F4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142329",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC14F6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142320",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC15F3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142321",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC16F1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142322",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC18F7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142323",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC13F8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142324",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC19E8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142325",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VII (1169 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VII (1169 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-17",
    "closeData": "",
    "isin": "INF789FC11F2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142339",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1523",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142340",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1515",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142341",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1531",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142334",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1499",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142335",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142336",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1549",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142337",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": "INF109KC1507",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142338",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1215 Days Plan H - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142352",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1432",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142353",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142354",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142355",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1457",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142356",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1481",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142357",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1465",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142358",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1440",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142359",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1223 Days Plan G - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-01-23",
    "closeData": "",
    "isin": "INF109KC1473",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142361",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VIII (1171 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VIII (1171 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF789FC11H8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142362",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VIII (1171 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VIII (1171 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF789FC18G5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142365",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VIII (1171 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-VIII (1171 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF789FC16G9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142366",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VIII (1171 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VIII (1171 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF789FC13G6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142367",
    "name": "UTI - FTIF SERIES XXVIII - PLAN VIII (1171 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN VIII (1171 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF789FC15G1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142379",
    "name": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days Direct Plan IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF109KC1663",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142380",
    "name": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF109KC1648",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142381",
    "name": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF109KC1630",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142382",
    "name": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days",
    "category": "Income",
    "navName": "ICICI Prudential Multiple Yield Fund - Series 14 - Plan A 1228 Days Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF109KC1655",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "142383",
    "name": "Edelweiss Recently Listed IPO Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Edelweiss Recently Listed IPO Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-02",
    "closeData": "",
    "isin": "INF754K01MH2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "142384",
    "name": "Edelweiss Recently Listed IPO Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Edelweiss Recently Listed IPO Fund REGULAR PLAN - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-02",
    "closeData": "",
    "isin": "INF754K01MI0",
    "isinRe": "INF754K01MJ8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "142386",
    "name": "Edelweiss Recently Listed IPO Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Edelweiss Recently Listed IPO Fund - DIRECT PLAN - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-02",
    "closeData": "",
    "isin": "INF754K01MM2",
    "isinRe": "INF754K01MN0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "142388",
    "name": "Edelweiss Recently Listed IPO Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Edelweiss Recently Listed IPO Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-02",
    "closeData": "",
    "isin": "INF754K01ML4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142403",
    "name": "Kotak FMP Series 216",
    "category": "Income",
    "navName": "Kotak FMP Series 216 - Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF174K016S5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142404",
    "name": "Kotak FMP Series 216",
    "category": "Income",
    "navName": "Kotak FMP Series 216-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF174K015S7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142405",
    "name": "Kotak FMP Series 216",
    "category": "Income",
    "navName": "Kotak FMP Series 216-Regular Plan- Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF174K014S0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142406",
    "name": "Kotak FMP Series 216",
    "category": "Income",
    "navName": "Kotak FMP Series 216-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-01",
    "closeData": "",
    "isin": "INF174K013S2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142407",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1721",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142408",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1713",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142409",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142410",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1697",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142411",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1689",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142412",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1705",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142413",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": "INF109KC1671",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142414",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan I - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142415",
    "name": "Kotak India Growth Fund Series 4",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 4-Direct Plan-ayout of Income Distribution cum capital withdrawal option",
    "minAmount": "100000000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF174KA1GG6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142416",
    "name": "Kotak India Growth Fund Series 4",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 4-Regular Plan-ayout of Income Distribution cum capital withdrawal option",
    "minAmount": "100000000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF174KA1GE1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142417",
    "name": "Kotak India Growth Fund Series 4",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 4 - Regular Plan- Growth option",
    "minAmount": "100000000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF174KA1GD3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142418",
    "name": "Kotak India Growth Fund Series 4",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 4-Direct Plan - Growth Option",
    "minAmount": "100000000",
    "launchDate": "2018-01-29",
    "closeData": "",
    "isin": "INF174KA1GF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142443",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1739",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142444",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142445",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1754",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142446",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1788",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1747",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1770",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142449",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142450",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1157 Days Plan J - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-14",
    "closeData": "",
    "isin": "INF109KC1762",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142451",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 1",
    "category": "Growth",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 1- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF204KB1UG0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142452",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 1",
    "category": "Growth",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 1 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF204KB1UH8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142453",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 1",
    "category": "Growth",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 1 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF204KB1UF2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142454",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 1",
    "category": "Growth",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF204KB1UE5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142455",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 2 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-12",
    "closeData": "",
    "isin": "INF204KB1UL0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142456",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 2 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-12",
    "closeData": "",
    "isin": "INF204KB1UJ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142457",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 2- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-12",
    "closeData": "",
    "isin": "INF204KB1UK2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142458",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-12",
    "closeData": "",
    "isin": "INF204KB1UI6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142462",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 3 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-16",
    "closeData": "",
    "isin": "INF204KB1UP1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142459",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-16",
    "closeData": "",
    "isin": "INF204KB1UM8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142460",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 3 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-16",
    "closeData": "",
    "isin": "INF204KB1UN6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142461",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 3- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-16",
    "closeData": "",
    "isin": "INF204KB1UO4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142463",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1812",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142464",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142465",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1846",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142466",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142467",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1838",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142468",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1861",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142469",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1820",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142470",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1203 Days Plan K - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-09",
    "closeData": "",
    "isin": "INF109KC1853",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142477",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Cumulative Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1879",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142478",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Direct Plan Cumulative Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1903",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142479",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Quarterly Dividend Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1895",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142480",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Direct Plan Quarterly Dividend Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1929",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142481",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Half Yearly IDCW Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1887",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142482",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Direct Plan Dividend Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142483",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Direct Plan Half Yearly IDCW Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF109KC1911",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142484",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1199 Days Plan L - Dividend Option",
    "minAmount": "500",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142513",
    "name": "Reliance Fixed Horizon Fund XXXVI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVI- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF204KB1UQ9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142514",
    "name": "Reliance Fixed Horizon Fund XXXVI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVI- Series 4- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF204KB1US5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142515",
    "name": "Reliance Fixed Horizon Fund XXXVI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVI- Series 4- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF204KB1UR7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142516",
    "name": "Reliance Fixed Horizon Fund XXXVI- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVI- Series 4- Direct Plan-Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF204KB1UT3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142519",
    "name": "UTI - FTIF SERIES XXVIII - PLAN IX (1168 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-IX (1168 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-20",
    "closeData": "",
    "isin": "INF789FC10I8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142525",
    "name": "UTI - FTIF SERIES XXVIII - PLAN IX (1168 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-IX (1168 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-20",
    "closeData": "",
    "isin": "INF789FC15I7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142526",
    "name": "UTI - FTIF SERIES XXVIII - PLAN IX (1168 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN IX (1168 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-20",
    "closeData": "",
    "isin": "INF789FC17H5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142527",
    "name": "Kotak FMP Series 217",
    "category": "Income",
    "navName": "Kotak FMP Series 217-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF174K017T1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142528",
    "name": "Kotak FMP Series 217",
    "category": "Income",
    "navName": "Kotak FMP Series 217-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF174K016T3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142529",
    "name": "Kotak FMP Series 217",
    "category": "Income",
    "navName": "Kotak FMP Series 217-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF174K015T5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142530",
    "name": "Kotak FMP Series 217",
    "category": "Income",
    "navName": "Kotak FMP Series 217-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-15",
    "closeData": "",
    "isin": "INF174K018T9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142531",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES I",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series I Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-19",
    "closeData": "",
    "isin": "INF903JA1CH4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142532",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES I",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series I Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-02-19",
    "closeData": "",
    "isin": "INF903JA1CK8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142533",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES I",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series I Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-19",
    "closeData": "",
    "isin": "INF903JA1CJ0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "142534",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES I",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series I Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-02-19",
    "closeData": "",
    "isin": "INF903JA1CI2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142535",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan A(1157 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01NC4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142536",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan A(1157 days)- Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01NE0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142537",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan A(1157 days)- Growth-Direct",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01NF7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142538",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan A 1157 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01ND2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142539",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan A(1157 days)- Dividend Direct",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01NG5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142540",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan A(1157 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan A(1157 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-02-26",
    "closeData": "",
    "isin": "INF090I01NH3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142541",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1937",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142542",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1986",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142543",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1978",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142544",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1960",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142545",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142546",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142547",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1945",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142548",
    "name": "ICICI Prudential Fixed Maturity Plan Series 82 - 1185 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan M - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-24",
    "closeData": "",
    "isin": "INF109KC1952",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142563",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UU1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142564",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 5 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UX5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142565",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 5- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UW7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142566",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 5 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UV9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142567",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UY3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142568",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 6 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1UZ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142569",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 6 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1VB9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142570",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 6- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF204KB1VA1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142572",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC12J2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142573",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC14J8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142574",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC19I9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142576",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC16J3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142577",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC17I3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142578",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-X (1153 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC15J5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142579",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-X (1153 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC10J6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142580",
    "name": "UTI - FTIF SERIES XXVIII - PLAN X (1153 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN X (1153 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-02-21",
    "closeData": "",
    "isin": "INF789FC18I1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142581",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1994",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142582",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AC6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142583",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142584",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142585",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AB8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142586",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AE2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142587",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142588",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1185 Days Plan N - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AD4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "142589",
    "name": "DSP NIFTY 1D Rate Liquid ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP NIFTY 1D Rate Liquid ETF - IDCW - Daily Reinvest",
    "minAmount": "5000",
    "launchDate": "2018-02-22",
    "closeData": "",
    "isin": "INF740KA1EU7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142634",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - series 82 - 103 Days Plan O - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AI3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142635",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - series 82 - 103 Days Plan O - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AG7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142637",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142638",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 103 Days Plan O - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-02-28",
    "closeData": "",
    "isin": "INF109KC1AH5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "142640",
    "name": "Mirae Asset Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mirae Asset Short Term Fund Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2018-02-23",
    "closeData": "",
    "isin": "INF769K01DY4",
    "isinRe": "INF769K01DZ1"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "142641",
    "name": "Mirae Asset Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mirae Asset Short Term Fund -Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-23",
    "closeData": "",
    "isin": "INF769K01DX6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "142642",
    "name": "Mirae Asset Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mirae Asset Short Term Fund- Regular Plan -Growth",
    "minAmount": "5000",
    "launchDate": "2018-02-23",
    "closeData": "",
    "isin": "INF769K01DU2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "142643",
    "name": "Mirae Asset Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mirae Asset Short Term Fund Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2018-02-23",
    "closeData": "",
    "isin": "INF769K01DV0",
    "isinRe": "INF769K01DW8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142644",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 7- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF204KB1VI4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142645",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 7 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF204KB1VJ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142646",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF204KB1VG8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142647",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 7 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF204KB1VH6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142648",
    "name": "Kotak FMP Series 218",
    "category": "Income",
    "navName": "Kotak FMP Series 218-Regular Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF174K010U4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142649",
    "name": "Kotak FMP Series 218",
    "category": "Income",
    "navName": "Kotak FMP Series 218-Direct Plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF174K011U2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142650",
    "name": "Kotak FMP Series 218",
    "category": "Income",
    "navName": "Kotak FMP Series 218-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF174K012U0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142651",
    "name": "Kotak FMP Series 218",
    "category": "Income",
    "navName": "Kotak FMP Series 218-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF174K019T7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142668",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan B (1139 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NL5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142669",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan B (1139 days)- Dividend -Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NM3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142664",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan B 1139 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NJ9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142665",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan B 1139 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NK7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142666",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan B (1139 days)- Quarterly Dividend-Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NN1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142667",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan B(1139 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan B (1139 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF090I01NI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142692",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142693",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142694",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142695",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142696",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142697",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Direct Plan Half yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AT0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142698",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AS2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142699",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1175 Days Plan Q - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-08",
    "closeData": "",
    "isin": "INF109KC1AQ6",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "142700",
    "name": "IL&FS Infrastructure Debt Fund-3 Series 3-A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 3A - Dividend payout option - Direct",
    "minAmount": "10000000",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF613Q01082",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "142701",
    "name": "IL&FS Infrastructure Debt Fund-3 Series 3-A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 3A - Growth - Direct",
    "minAmount": "10000000",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF613Q01074",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "142702",
    "name": "IL&FS Infrastructure Debt Fund-3 Series 3-A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 3A - Growth - Regular",
    "minAmount": "10000000",
    "launchDate": "2018-01-01",
    "closeData": "",
    "isin": "INF613Q01090",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142710",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan C 1132 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NT8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142711",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan C (1132 days)- Growth-Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NR2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142712",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan C 1132 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NP6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142713",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan C 1132 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NS0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142714",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan C (1132 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NO9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142715",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan C(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan C 1132 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF090I01NQ4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142742",
    "name": "HSBC Fixed Term Series 132 (1149 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 132 - IDCW Direct Plan-Tenure 1149 Days-Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF336L01MK0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142739",
    "name": "HSBC Fixed Term Series 132 (1149 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 132 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF336L01ML8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142740",
    "name": "HSBC Fixed Term Series 132 (1149 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 132 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF336L01MJ2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142741",
    "name": "HSBC Fixed Term Series 132 (1149 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 132 - IDCW - Tenure 1149 Days - Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-02-27",
    "closeData": "",
    "isin": "INF336L01MM6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142757",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142758",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142759",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AK9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142760",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AJ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142761",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AO1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142762",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AM5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142763",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142764",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1136 Days Plan P - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-07",
    "closeData": "",
    "isin": "INF109KC1AN3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142765",
    "name": "Kotak FMP Series 219",
    "category": "Income",
    "navName": "Kotak FMP Series 219-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF174K014U6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142766",
    "name": "Kotak FMP Series 219",
    "category": "Income",
    "navName": "Kotka FMP Series 219-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF174K015U3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142767",
    "name": "Kotak FMP Series 219",
    "category": "Income",
    "navName": "Kotak FMP Series 219-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF174K013U8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142768",
    "name": "Kotak FMP Series 219",
    "category": "Income",
    "navName": "Kotak FMP Series 219-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF174K016U1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142789",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XI (1161 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC15K3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142790",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XI (1161 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC13K8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142792",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XI (1161 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC19J7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142794",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XI (1161 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC10K4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142795",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XI (1161 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC18J9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142796",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XI (1161 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC17J1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142798",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XI (1161 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XI (1161 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-05",
    "closeData": "",
    "isin": "INF789FC11K2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142807",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XII (1154 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC10L2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142808",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XII (1154 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC17K9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142811",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XII (1154 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC14L4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142812",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XII (1154 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC19K5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142813",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XII (1154 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC15L1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142814",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XII (1154 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XII (1154 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC18K7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142815",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XIII (1134 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC15M9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142844",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC13M4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142845",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC12M6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142846",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC14M2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142847",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC16M7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142848",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XIII (1134 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC10M0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142849",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC18L5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142850",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC11M8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142851",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC17L7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "142852",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIII (1134 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIII (1134 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF789FC19L3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142816",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF204KB1VK0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142817",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 8 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF204KB1VL8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142818",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 8 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF204KB1VN4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142819",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 8- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF204KB1VM6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142857",
    "name": "Kotak FMP Series 220",
    "category": "Income",
    "navName": "Kotak FMP Series 220-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF174K019U5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142858",
    "name": "Kotak FMP Series 220",
    "category": "Income",
    "navName": "Kotak FMP Series 220-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF174K017U9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142859",
    "name": "Kotak FMP Series 220",
    "category": "Income",
    "navName": "Kotak FMP Series 220-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF174K010V2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "142860",
    "name": "Kotak FMP Series 220",
    "category": "Income",
    "navName": "Kotak FMP Series 220-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-12",
    "closeData": "",
    "isin": "INF174K018U7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142881",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142882",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1AW4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142883",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1AV6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142884",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142885",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1AY0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142886",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1AZ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142887",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1AX2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142888",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1170 Days Plan R - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-14",
    "closeData": "",
    "isin": "INF109KC1BA8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142899",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142900",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BD2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142901",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BG5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142902",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142903",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142904",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF109KC1BE0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142905",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142906",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan S - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142913",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-23",
    "closeData": "",
    "isin": "INF204KB1XK6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142914",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 9 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-23",
    "closeData": "",
    "isin": "INF204KB1XL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142915",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVI- Series 9- Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-23",
    "closeData": "",
    "isin": "INF204KB1VU9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "142916",
    "name": "Nippon India Fixed Horizon Fund XXXVI- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVI - SERIES 9 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-03-23",
    "closeData": "",
    "isin": "INF204KB1XN0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142917",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan D(1132 days)- Growth-Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NX0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142918",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan D 1132 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NV4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142919",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan D (1132 days)- Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NY8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142920",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan D 1132 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NW2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142921",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan D(1132 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NU6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "142922",
    "name": "Franklin India Fixed Maturity Plans- Series 3 - Plan D(1132 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan D 1132 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF090I01NZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142945",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142946",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142947",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142948",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 99 Days Plan T - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142949",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 1 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF109KC1BM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142950",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 1 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF109KC1BO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142951",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 1 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF109KC1BN1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142952",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 1",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 1 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF109KC1BL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142961",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BS0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142962",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142963",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142964",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142965",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142966",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142967",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142968",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan U - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142977",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BY8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142978",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142979",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BX0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142980",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142981",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142982",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142975",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1CA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "142976",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1135 Days Plan V - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-03-21",
    "closeData": "",
    "isin": "INF109KC1BW2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142989",
    "name": "HSBC Fixed Term Series 131 (1140 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 131 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-03-15",
    "closeData": "",
    "isin": "INF336L01MN4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142990",
    "name": "HSBC Fixed Term Series 131 (1140 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 131 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-03-15",
    "closeData": "",
    "isin": "INF336L01MP9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142991",
    "name": "HSBC Fixed Term Series 131 (1140 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 131 - IDCW - Tenure 1140 Days -  Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-03-15",
    "closeData": "",
    "isin": "INF336L01MQ7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "142992",
    "name": "HSBC Fixed Term Series 131 (1140 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 131 - IDCW Direct Plan-Tenure 1140 Days - Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-03-15",
    "closeData": "",
    "isin": "INF336L01MO2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143007",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES II",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series II Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF903JA1CS1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143008",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES II",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series II Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF903JA1CQ5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143009",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES II",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series II Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF903JA1CP7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143010",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES II",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series II Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF903JA1CR3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143022",
    "name": "Kotak FMP Series 221",
    "category": "Income",
    "navName": "Kotak FMP Series 221-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K016V9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143023",
    "name": "Kotak FMP Series 221",
    "category": "Income",
    "navName": "Kotak FMP Series 221-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K012W6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143024",
    "name": "Kotak FMP Series 221",
    "category": "Income",
    "navName": "Kotak FMP Series 221-Direct plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K017V7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143021",
    "name": "Kotak FMP Series 221",
    "category": "Income",
    "navName": "Kotak FMP Series 221-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K018V5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143025",
    "name": "Kotak FMP Series 222",
    "category": "Income",
    "navName": "Kotak FMP Series 222-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K014V4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143026",
    "name": "Kotak FMP Series 222",
    "category": "Income",
    "navName": "Kotak FMP Series 222-Regular Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K012V8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143027",
    "name": "Kotak FMP Series 222",
    "category": "Income",
    "navName": "Kotak FMP Series 222-Regular Plan- Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K011V0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143028",
    "name": "Kotak FMP Series 222",
    "category": "Income",
    "navName": "Kotak FMP Series 222-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K013V6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143029",
    "name": "Kotak FMP Series 223",
    "category": "Income",
    "navName": "Kotak FMP Series 223-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K011W8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143030",
    "name": "Kotak FMP Series 223",
    "category": "Income",
    "navName": "Kotak FMP Series 223-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K010W0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143031",
    "name": "Kotak FMP Series 223",
    "category": "Income",
    "navName": "Kotak FMP Series 223-Regular Plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K019V3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143032",
    "name": "Kotak FMP Series 223",
    "category": "Income",
    "navName": "Kotak FMP Series 223-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-20",
    "closeData": "",
    "isin": "INF174K015V1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143039",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIV (1147 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XIV (1147 Days) - Direct Plan - Growth OptionI",
    "minAmount": "Rs5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF789FC15N7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143044",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIV (1147 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF Series XXVIII-XIV (1147 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF789FC10N8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143047",
    "name": "UTI - FTIF SERIES XXVIII - PLAN XIV (1147 DAYS)",
    "category": "Income",
    "navName": "UTI FTIF SERIES XXVIII - PLAN XIV (1147 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF789FC17M5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143049",
    "name": "HSBC Fixed Term Series 133 (1134 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 133 - IDCW Direct Plan-Tenure 1134 Days-Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF336L01MS3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143050",
    "name": "HSBC Fixed Term Series 133 (1134 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 133 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF336L01MR5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143051",
    "name": "HSBC Fixed Term Series 133 (1134 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 133 - IDCW - Tenure 1134 Days - Maturity  03-May-2021",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF336L01MU9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143052",
    "name": "HSBC Fixed Term Series 133 (1134 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 133 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-03-19",
    "closeData": "",
    "isin": "INF336L01MT1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143059",
    "name": "Kotak FMP Series 224",
    "category": "Income",
    "navName": "Kotak FMP Series 224-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF174K013W4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143060",
    "name": "Kotak FMP Series 224",
    "category": "Income",
    "navName": "Kotak FMP Series 224 - Regular Plan- Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF174K014W2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143061",
    "name": "Kotak FMP Series 224",
    "category": "Income",
    "navName": "Kotak FMP Series 224-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF174K015W9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143062",
    "name": "Kotak FMP Series 224",
    "category": "Income",
    "navName": "Kotak FMP Series 224-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-03-22",
    "closeData": "",
    "isin": "INF174K016W7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143079",
    "name": "Sundaram Long Term Tax Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series IV Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2018-03-27",
    "closeData": "",
    "isin": "INF903JA1CZ6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143080",
    "name": "Sundaram Long Term Tax Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series IV Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2018-03-27",
    "closeData": "",
    "isin": "INF903JA1DA7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143077",
    "name": "Sundaram Long Term Tax Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series IV Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2018-03-27",
    "closeData": "",
    "isin": "INF903JA1CX1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143078",
    "name": "Sundaram Long Term Tax Advantage Fund Series IV",
    "category": "ELSS",
    "navName": "Sundaram Long Term Tax Advantage Fund Series IV Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "500",
    "launchDate": "2018-03-27",
    "closeData": "",
    "isin": "INF903JA1CY9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143081",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF204KB1WA9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143082",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 1- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF204KB1WC5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143083",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 01 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF204KB1WB7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143084",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 01 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF204KB1WD3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143085",
    "name": "UTI - Capital Protection Oriented Scheme - Series X-II (1134 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series X-II ( 1134 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC10Q1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143086",
    "name": "UTI - Capital Protection Oriented Scheme - Series X-II (1134 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme Series X-II ( 1134 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC18P6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143087",
    "name": "UTI - Capital Protection Oriented Scheme - Series X-II (1134 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series X-II (1134 Days) - Regular Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC17P8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143088",
    "name": "UTI - Capital Protection Oriented Scheme - Series X-II (1134 Days)",
    "category": "Income",
    "navName": "UTI Capital Protection Oriented Scheme - Series X-II (1134 Days) - Direct Plan - IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC19P4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143099",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC13P7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143100",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX -I (1134 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC15P2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143101",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC19O7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143102",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC11P1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143104",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-I (1134 days) - Regular Plan - Growth Opton",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC10P3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143105",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC18O9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143106",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC12P9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143107",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC17O1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143108",
    "name": "UTI - Fixed Term Income fund - Series XXIX - I (1134 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - I (1134 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-02",
    "closeData": "",
    "isin": "INF789FC14P5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143113",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143114",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143115",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143116",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1126 Days Plan W - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143126",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143127",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CJ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143128",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CM1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143129",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CI9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143130",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CL3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143131",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143132",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CK5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143133",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1119 Days Plan X - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-11",
    "closeData": "",
    "isin": "INF109KC1CH1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143140",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-16",
    "closeData": "",
    "isin": "INF204KB1WE1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143141",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 2- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-16",
    "closeData": "",
    "isin": "INF204KB1WF8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143142",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 2- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-16",
    "closeData": "",
    "isin": "INF204KB1WH4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143143",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 2- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-16",
    "closeData": "",
    "isin": "INF204KB1WG6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143144",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143145",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CS8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143146",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143147",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143148",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143149",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143150",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CR0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143151",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 1141 Days Plan Y - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-14",
    "closeData": "",
    "isin": "INF109KC1CQ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143153",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 2 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-12",
    "closeData": "",
    "isin": "INF109KC1CX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143154",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 2 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-12",
    "closeData": "",
    "isin": "INF109KC1CY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143155",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 2 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-04-12",
    "closeData": "",
    "isin": "INF109KC1CW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143152",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 2",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 2 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-04-12",
    "closeData": "",
    "isin": "INF109KC1CV2",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143197",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan Monthly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DL6",
    "isinRe": "INF959L01DM4"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143198",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan Annual IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DR3",
    "isinRe": "INF959L01DS1"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143199",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan Quarterly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DN2",
    "isinRe": "INF959L01DO0"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143167",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan Normal IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01CY1",
    "isinRe": "INF959L01CZ8"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143185",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan Quarterly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DC5",
    "isinRe": "INF959L01DD3"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143186",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan Half Yearly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DE1",
    "isinRe": "INF959L01DF8"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143189",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan Half Yearly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DP7",
    "isinRe": "INF959L01DQ5"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143192",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan Monthly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DA9",
    "isinRe": "INF959L01DB7"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143195",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan Annual IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DG6",
    "isinRe": "INF959L01DH4"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143162",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DI2",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143163",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01CX3",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143166",
    "name": "Navi Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Navi Equity Hybrid Fund - Regular Plan Normal IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2018-04-09",
    "closeData": "2018-04-23",
    "isin": "INF959L01DJ0",
    "isinRe": "INF959L01DK8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143168",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF204KB1WI2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143169",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 03 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF204KB1WJ0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143170",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 03 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF204KB1WL6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143171",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 3- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF204KB1WK8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143172",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-20",
    "closeData": "",
    "isin": "INF204KB1WM4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143173",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 04 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-20",
    "closeData": "",
    "isin": "INF204KB1WN2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143174",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 4- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-20",
    "closeData": "",
    "isin": "INF204KB1WO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143175",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 04 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-04-20",
    "closeData": "",
    "isin": "INF204KB1WP7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "143177",
    "name": "SBI Long Term Advantage Fund - Series VI",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series VI - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2018-04-11",
    "closeData": "2018-07-10",
    "isin": "INF200KA1TN3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "143178",
    "name": "SBI Long Term Advantage Fund - Series VI",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series VI - Direct Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2018-04-11",
    "closeData": "2018-07-10",
    "isin": "INF200KA1TO1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "143179",
    "name": "SBI Long Term Advantage Fund - Series VI",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series VI - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2018-04-11",
    "closeData": "2018-07-10",
    "isin": "INF200KA1TP8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "143176",
    "name": "SBI Long Term Advantage Fund - Series VI",
    "category": "ELSS",
    "navName": "SBI Long Term Advantage Fund - Series VI - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500 thereafter",
    "launchDate": "2018-04-11",
    "closeData": "2018-07-10",
    "isin": "INF200KA1TM5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143180",
    "name": "Kotak FMP Series 225",
    "category": "Income",
    "navName": "Kotak FMP Series 225-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF174K010X8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143181",
    "name": "Kotak FMP Series 225",
    "category": "Income",
    "navName": "Kotak FMP Series 225-Regular Plan -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF174K017W5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143182",
    "name": "Kotak FMP Series 225",
    "category": "Income",
    "navName": "Kotak FMP Series 225-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF174K018W3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143183",
    "name": "Kotak FMP Series 225",
    "category": "Income",
    "navName": "Kotak FMP Series 225-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-04-10",
    "closeData": "",
    "isin": "INF174K019W1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143209",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC16Q8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143210",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-II ( 1118 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC19Q2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143215",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC17Q6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143216",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC12Q7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143217",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC13Q5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143218",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC15Q0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143219",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC11Q9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143220",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - II (1118 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC18Q4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143221",
    "name": "UTI - Fixed Term Income fund - Series XXIX - II (1118 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-II (1118 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-04-18",
    "closeData": "",
    "isin": "INF789FC14Q3",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "143211",
    "name": "IIFL CAPITAL ENHANCER FUND- SERIES 1",
    "category": "Growth",
    "navName": "IIFL CAPITAL ENHANCER FUND SERIES 1-REGULAR PLAN-DIVIDEND PAYOUT",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF579M01AB7",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "143212",
    "name": "IIFL CAPITAL ENHANCER FUND- SERIES 1",
    "category": "Growth",
    "navName": "IIFL CAPITAL ENHANCER FUND SERIES 1-DIRECT PLAN-GROWTH",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF579M01AD3",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "143213",
    "name": "IIFL CAPITAL ENHANCER FUND- SERIES 1",
    "category": "Growth",
    "navName": "IIFL CAPITAL ENHANCER FUND SERIES 1-DIRECT PLAN-DIVIDEND PAYOUT",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF579M01AE1",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "143214",
    "name": "IIFL CAPITAL ENHANCER FUND- SERIES 1",
    "category": "Growth",
    "navName": "IIFL CAPITAL ENHANCER FUND SERIES 1-REGULAR PLAN-GROWTH",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF579M01AC5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143223",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES III",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series III Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF903JA1DC3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143224",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES III",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series III Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF903JA1DE9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143225",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES III",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series III Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF903JA1DB5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143226",
    "name": "SUNDARAM EMERGING SMALL CAP SERIES III",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series III Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-04-23",
    "closeData": "",
    "isin": "INF903JA1DD1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "143239",
    "name": "Union Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Union Corporate Bond Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.1000 and Rs.1 thereafter",
    "launchDate": "2018-05-04",
    "closeData": "",
    "isin": "INF582M01DY7",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "143240",
    "name": "Union Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Union Corporate Bond Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs.1000 and Rs.1 thereafter",
    "launchDate": "2018-05-04",
    "closeData": "",
    "isin": "INF582M01EA5",
    "isinRe": "INF582M01DZ4"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "143241",
    "name": "Union Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Union Corporate Bond Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and Rs.1 thereafter",
    "launchDate": "2018-05-04",
    "closeData": "",
    "isin": "INF582M01DU5",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "143242",
    "name": "Union Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Union Corporate Bond Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and Rs.1 thereafter",
    "launchDate": "2018-05-04",
    "closeData": "",
    "isin": "INF582M01DW1",
    "isinRe": "INF582M01DV3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143243",
    "name": "Kotak India Growth Fund Series 5",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 5-Regular Plan-Growth Option",
    "minAmount": "100000000",
    "launchDate": "2018-04-25",
    "closeData": "",
    "isin": "INF174K011X6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143244",
    "name": "Kotak India Growth Fund Series 5",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 5-Direct Plan-Growth Option",
    "minAmount": "100000000",
    "launchDate": "2018-04-25",
    "closeData": "",
    "isin": "INF174K013X2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143245",
    "name": "Kotak India Growth Fund Series 5",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 5-Direct PLan-ayout of Income Distribution cum capital withdrawal option ",
    "minAmount": "100000000",
    "launchDate": "2018-04-25",
    "closeData": "",
    "isin": "INF174K014X0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143246",
    "name": "Kotak India Growth Fund Series 5",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 5-Regular Plan-ayout of Income Distribution cum capital withdrawal option ",
    "minAmount": "100000000",
    "launchDate": "2018-04-25",
    "closeData": "",
    "isin": "INF174K012X4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143247",
    "name": "ICICI Prudential S&P BSE 500 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential S&P BSE 500 ETF",
    "minAmount": "5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF109KC1V59",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143248",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB19D6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143249",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB10E3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143250",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB13E7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143251",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB12E9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143252",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB11E1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "143253",
    "name": "HDFC FMP 1434D May 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1434D May 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-05-04",
    "closeData": "2018-05-08",
    "isin": "INF179KB18D8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143254",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 05 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-09",
    "closeData": "",
    "isin": "INF204KB1WV5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143255",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 05 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-09",
    "closeData": "",
    "isin": "INF204KB1WX1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143256",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 5- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-09",
    "closeData": "",
    "isin": "INF204KB1WW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143257",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-09",
    "closeData": "",
    "isin": "INF204KB1WU7",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143260",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01035",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143261",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Regular Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01043",
    "isinRe": "INF879O01050"
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143262",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Direct Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01076",
    "isinRe": "INF879O01084"
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143263",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Direct Plan- Daily Reinvestment of IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01118",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143264",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Regular Plan- Daily Reinvestment of IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01126",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143265",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Direct Plan- Weekly Reinvestment of IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01134",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143266",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Regular Plan- Weekly Reinvestment of IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01142",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "143269",
    "name": "Parag Parikh Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Parag Parikh Liquid Fund- Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-09",
    "closeData": "2018-05-09",
    "isin": "INF879O01068",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143272",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 6- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF204KB1XA7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143273",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 06 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF204KB1XB5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143270",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 06 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF204KB1WZ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143271",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF204KB1WY9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143274",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-III (1131 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC19R0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143275",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC16R6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143276",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC17R4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143277",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC18R2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143278",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-III (1131 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC14R1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143279",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC11R7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143280",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC13R3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143282",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC10S7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143283",
    "name": "UTI - Fixed Term Income fund - Series XXIX - III (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - III (1131 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-03",
    "closeData": "",
    "isin": "INF789FC12R5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143292",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 7- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-16",
    "closeData": "",
    "isin": "INF204KB1XF6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143293",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-16",
    "closeData": "",
    "isin": "INF204KB1XC3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143290",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 7- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-16",
    "closeData": "",
    "isin": "INF204KB1XD1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143291",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 7- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-16",
    "closeData": "",
    "isin": "INF204KB1XE9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143340",
    "name": "UTI - Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty Next 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF789FC11T3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143341",
    "name": "UTI - Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty Next 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF789FC12T1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143342",
    "name": "Kotak FMP Series 226",
    "category": "Income",
    "navName": "Kotak FMP Series 226 - Regular Plan- Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-10",
    "closeData": "",
    "isin": "INF174K015X7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143343",
    "name": "Kotak FMP Series 226",
    "category": "Income",
    "navName": "Kotak FMP Series 226 - Direct Plan- Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-10",
    "closeData": "",
    "isin": "INF174K017X3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143344",
    "name": "Kotak FMP Series 226",
    "category": "Income",
    "navName": "Kotak FMP Series 226 - Regular Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-10",
    "closeData": "",
    "isin": "INF174K016X5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143345",
    "name": "Kotak FMP Series 226",
    "category": "Income",
    "navName": "KOtak FMP Series 226 - Direct Plan- Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-10",
    "closeData": "",
    "isin": "INF174K018X1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143346",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 8- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF204KB1XH2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143347",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 8- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF204KB1XJ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143348",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 8- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF204KB1XI0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143349",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF204KB1XG4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "143350",
    "name": "IDBI Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Banking & Financial Services Fund - Regular Plan (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-05-14",
    "closeData": "",
    "isin": "INF397L01KF6",
    "isinRe": "INF397L01KG4"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "143351",
    "name": "IDBI Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Banking & Financial Services Fund - Regular Plan (Growth)",
    "minAmount": "5000",
    "launchDate": "2018-05-14",
    "closeData": "",
    "isin": "INF397L01KI0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "143352",
    "name": "IDBI Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Banking & Financial Services Fund - Direct Plan (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-05-14",
    "closeData": "",
    "isin": "INF397L01KJ8",
    "isinRe": "INF397L01KK6"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "143353",
    "name": "IDBI Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Banking & Financial Services Fund - Direct Plan (Growth)",
    "minAmount": "5000",
    "launchDate": "2018-05-14",
    "closeData": "",
    "isin": "INF397L01KM2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143360",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IV (1422 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-IV (1422 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF789FC19S8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143361",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IV (1422 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-IV (1422 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF789FC14S9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143422",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IV (1422 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IV (1422 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF789FC10T5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143425",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IV (1422 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IV (1422 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-11",
    "closeData": "",
    "isin": "INF789FC12S3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143370",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days- Growth -Direct",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OD0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143371",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan E 1104 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OE8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143372",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan E 1104 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OF5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143373",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- series 3- Plan E (1104) days- Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OA6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143374",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan E 1104 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OB4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143375",
    "name": "Franklin India Fixed Maturity Plans -series 3- Plan E (1104) days",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan E 1104 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-05-17",
    "closeData": "2018-05-22",
    "isin": "INF090I01OC2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143376",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DA4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143377",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DC0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143378",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143379",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DF3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143380",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DD8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143381",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DE6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143382",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DB2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143383",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan A - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143387",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143384",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DP2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143385",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DN7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143386",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 82 - 91 Days Plan Z - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DM9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143388",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143395",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DL1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143389",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143390",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DI7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143391",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143392",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DJ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143393",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DG1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143394",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1412 Days Plan B - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF109KC1DK3",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "143396",
    "name": "IL&FS Infrastructure Debt Fund-3 Series 3-B",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 3B - Growth - Direct",
    "minAmount": "10000000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": "INF613Q01108",
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "143397",
    "name": "IL&FS Infrastructure Debt Fund-3 Series 3-B",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 3B - Dividend payout option",
    "minAmount": "10000000",
    "launchDate": "2018-04-05",
    "closeData": "",
    "isin": "INF613Q01116",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143428",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC19T6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143429",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC18T8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143430",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC10U3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143431",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC12U9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143432",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC14T7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143433",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC15T4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143434",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC17T0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143435",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-V (1113 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC16T2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143436",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - V (1113 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC13T9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143437",
    "name": "UTI - Fixed Term Income fund - Series XXIX - V (1113 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-V (1113 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF789FC11U1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143438",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DR8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143439",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DQ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143440",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DS6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143441",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 91 Days Plan C - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DT4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143442",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DU2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143443",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DX6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143444",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143445",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143446",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DZ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DV0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": "INF109KC1DY4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143449",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1406 Days Plan D - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143450",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 09 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF204KB1XL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143451",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 09 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF204KB1XN0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143452",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF204KB1XK6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143453",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 9- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF204KB1XM2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143454",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF204KB1XO8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143455",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 10 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF204KB1XP5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143456",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 10 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF204KB1XR1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143457",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 10- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF204KB1XQ3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143464",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duration Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01GX2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143507",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duration Fund - Regular Plan - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01GW4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143508",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duration Fund- Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01HA8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143509",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duration Fund- Direct Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01HB6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143510",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duratio Fund- Regular Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01GY0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "143511",
    "name": "Baroda BNP Paribas Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Baroda BNP Paribas Ultra Short Duration Fund- Direct Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-24",
    "closeData": "2018-05-29",
    "isin": "INF955L01GZ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143477",
    "name": "Kotak FMP Series 227",
    "category": "Income",
    "navName": "Kotak FMP Series 227 - Regular Plan -Growth Option.",
    "minAmount": "200000000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF174K019X9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143478",
    "name": "Kotak FMP Series 227",
    "category": "Income",
    "navName": "Kotak FMP Series 227 - Regular Plan- Dividend Option.",
    "minAmount": "200000000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF174K010Y6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143479",
    "name": "Kotak FMP Series 227",
    "category": "Income",
    "navName": "Kotak FMP Series 227 - Direct Plan - Growth Option.",
    "minAmount": "200000000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF174K011Y4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143480",
    "name": "Kotak FMP Series 227",
    "category": "Income",
    "navName": "Kotak FMP Series 227- Direct Plan - Dividend Option.",
    "minAmount": "200000000",
    "launchDate": "2018-05-21",
    "closeData": "",
    "isin": "INF174K012Y2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147681",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB11H0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147682",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB16H9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147675",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Nippon India Ultra Short Duration Fund - Segregated Portfolio 1 - Direct Plan - Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB10H2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147676",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB12H8",
    "isinRe": "INF204KB13H6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147677",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB14H4",
    "isinRe": "INF204KB15H1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147678",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB19H3",
    "isinRe": "INF204KB10I0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147679",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB11I8",
    "isinRe": "INF204KB12I6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147680",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - WEEKLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB13I4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143499",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01UF5",
    "isinRe": "INF204K01UG3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143500",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - DIRECT Plan - DAILY IDCW OPTION",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01YF7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143501",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - DIRECT Plan - WEEKLY IDCW OPTION",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01YG5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143502",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01YK7",
    "isinRe": "INF204K01YL5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147673",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - SEGREGATED PORTFOLIO 1 - DAILY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB18H5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147674",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Nippon India Ultra Short Duration Fund - Segregated Portfolio 1 - Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204KB17H7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143493",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Nippon India Ultra Short Duration Fund- Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01UE8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143494",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Nippon India Ultra Short Duration Fund- Direct Plan- Growth Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01YH3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143495",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - WEEKLY IDCW OPTION",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01UD0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143496",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01UH1",
    "isinRe": "INF204K01UI9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143497",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01YI1",
    "isinRe": "INF204K01YJ9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143498",
    "name": "Nippon India Ultra Short Duration Fund (Number of Segregated Portfolio -1)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "NIPPON INDIA ULTRA SHORT DURATION FUND - DAILY IDCW OPTION",
    "minAmount": "Rs.10000 & in multiples of Re. 1 thereafter",
    "launchDate": "2001-12-01",
    "closeData": "2001-12-06",
    "isin": "INF204K01UC2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143503",
    "name": "Sundaram Emerging Small Cap Series IV",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series IV Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF903JA1DN0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143504",
    "name": "Sundaram Emerging Small Cap Series IV",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series IV Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF903JA1DO8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143505",
    "name": "Sundaram Emerging Small Cap Series IV",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series IV Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF903JA1DQ3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143506",
    "name": "Sundaram Emerging Small Cap Series IV",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series IV Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-22",
    "closeData": "",
    "isin": "INF903JA1DP5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143516",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EI5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143517",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143518",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143519",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143512",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143513",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EM7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143514",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143515",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1113 Days Plan E - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-28",
    "closeData": "",
    "isin": "INF109KC1EN5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143520",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1ER6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143521",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1ET2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143522",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143523",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1EP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143524",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1EO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143525",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143526",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1ES4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143527",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan F - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-05-29",
    "closeData": "",
    "isin": "INF109KC1EQ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143528",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF204KB1XS9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143529",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 11- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF204KB1XU5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143530",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 11- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF204KB1XV3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143531",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 11",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 11- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF204KB1XT7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "143532",
    "name": "Invesco India Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Invesco India Equity & Bond Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF205K013Q9",
    "isinRe": "INF205K015Q4"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "143534",
    "name": "Invesco India Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Invesco India Equity & Bond Fund - Regular Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF205K010Q5",
    "isinRe": "INF205K012Q1"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "143536",
    "name": "Invesco India Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Invesco India Equity & Bond Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF205K011Q3",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "143537",
    "name": "Invesco India Equity & Bond Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Invesco India Equity & Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF205K014Q7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143562",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VI (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VI (1135 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF789FC11V9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143563",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VI (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VI (1135 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF789FC19U4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143567",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VI (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VI (1135 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF789FC16U0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143568",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VI (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VI (1135 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-05-25",
    "closeData": "",
    "isin": "INF789FC14U5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143597",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund (Direct) - Growth",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DS1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143598",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund - Growth",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AU3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143599",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund (Direct) - Weekly Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DQ5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143600",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund - Weekly Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AS7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143601",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FF3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143602",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund (Direct) - Fortnightly Dividend Option",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DR3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143592",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund (Direct) Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FH9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143594",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund - Daily Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AR9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143595",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund (Direct)- Daily Dividend Option",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DP7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143596",
    "name": "JM Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "JM Money Market Fund - Fortnighly Dividend",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AT5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143603",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Regular) - Daily IDCW",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AV1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143604",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Direct) - Daily IDCW",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DT9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143605",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Regular) - Fortnightly IDCW ",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AX7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143606",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Direct) - Fortnightly IDCW",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DV5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143607",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Regular) - Growth Option",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AY5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143608",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Regular) - Weekly IDCW",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01AW9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143609",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FJ5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143610",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Direct) - Bonus Option - Principal Units",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FL1",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143611",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Direct) - Weekly IDCW",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DU7",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "143612",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Direct) - Growth",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01DW3",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "148887",
    "name": "JM Low Duration Fund",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "JM Low Duration Fund (Regular) - Bonus Option - Bonus Units",
    "minAmount": "Rs.5000/- & in multiples of Re.1/-",
    "launchDate": "2006-09-25",
    "closeData": "2006-09-27",
    "isin": "INF192K01FK3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143613",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Direct Plan Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01EI2",
    "isinRe": "INF761K01EJ0"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143614",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01ED3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143615",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Direct Plan Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01EE1",
    "isinRe": "INF761K01EF8"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143616",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Direct Plan Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01EG6",
    "isinRe": "INF761K01EH4"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143617",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Regular Plan IDCW Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01EB7",
    "isinRe": "INF761K01EC5"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143618",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Regular Plan IDCW Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01DX3",
    "isinRe": "INF761K01DY1"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143619",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Regular Plan IDCW Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01DZ8",
    "isinRe": "INF761K01EA9"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "143620",
    "name": "BANK OF INDIA ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BANK OF INDIA Arbitrage Fund Regular Growth",
    "minAmount": "5000",
    "launchDate": "2018-05-31",
    "closeData": "",
    "isin": "INF761K01DW5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143621",
    "name": "Kotak FMP Series 228",
    "category": "Income",
    "navName": "Kotak FMP Series 228 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-30",
    "closeData": "",
    "isin": "INF174K016Y3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143622",
    "name": "Kotak FMP Series 228",
    "category": "Income",
    "navName": "Kotak FMP Series 228 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-30",
    "closeData": "",
    "isin": "INF174K013Y0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143623",
    "name": "Kotak FMP Series 228",
    "category": "Income",
    "navName": "Kotak FMP Series 228-Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-30",
    "closeData": "",
    "isin": "INF174K015Y5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143624",
    "name": "Kotak FMP Series 228",
    "category": "Income",
    "navName": "Kotak FMP Series 228- Regular Plan  -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-05-30",
    "closeData": "",
    "isin": "INF174K014Y8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143625",
    "name": "Kotak FMP Series 229",
    "category": "Income",
    "navName": "Kotak FMP Series 229 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF174K019Y7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143626",
    "name": "Kotak FMP Series 229",
    "category": "Income",
    "navName": "Kotak FMP Series 229 - Regular Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF174K018Y9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143627",
    "name": "Kotak FMP Series 229",
    "category": "Income",
    "navName": "Kotak FMP Series 229- Regular Plan  -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF174K017Y1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143628",
    "name": "Kotak FMP Series 229",
    "category": "Income",
    "navName": "Kotak FMP Series 229 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF174K010Z3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143643",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 12- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1XY7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143644",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 12 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1XZ4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143641",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 12- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1XW1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143642",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 12 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1XX9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143648",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 13- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1YC1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143645",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1YA5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143646",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 13- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1YB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143647",
    "name": "Reliance Fixed Horizon Fund XXXVII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVII- Series 13- Direct Plan- Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF204KB1YD9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143667",
    "name": "HSBC Fixed Term Series 134 (1118 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 134 - IDCW Direct Plan - Tenure 1118 Days - Maturity  29-Jun-2021",
    "minAmount": "5000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF336L01MW5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143668",
    "name": "HSBC Fixed Term Series 134 (1118 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 134 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF336L01MV7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143669",
    "name": "HSBC Fixed Term Series 134 (1118 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 134 - IDCW - Tenure 1118 Days - Maturity  29-Jun-2021",
    "minAmount": "5000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF336L01MX3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "143670",
    "name": "HSBC Fixed Term Series 134 (1118 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 134 - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-01",
    "closeData": "",
    "isin": "INF336L01MY1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143701",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - DIRECT Plan - HALF YEARLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YX7",
    "isinRe": "INF204KB1YY5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143702",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Nippon India Nivesh Lakshya Fund- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZB0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143703",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - ANNUAL IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZK1",
    "isinRe": "INF204KB1ZL9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143704",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Nippon India Nivesh Lakshya Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YQ1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143705",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YT5",
    "isinRe": "INF204KB1YU3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143706",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZC8",
    "isinRe": "INF204KB1ZD6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143707",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YR9",
    "isinRe": "INF204KB1YS7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143708",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZG9",
    "isinRe": "INF204KB1ZH7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143709",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YV1",
    "isinRe": "INF204KB1YW9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143710",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - HALF YEARLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZI5",
    "isinRe": "INF204KB1ZJ3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143711",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - DIRECT Plan - ANNUAL IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1YZ2",
    "isinRe": "INF204KB1ZA2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143712",
    "name": "Nippon India Nivesh Lakshya Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "NIPPON INDIA NIVESH LAKSHYA FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF204KB1ZE4",
    "isinRe": "INF204KB1ZF1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143713",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143714",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143715",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FO0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143716",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143717",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143718",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FM4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143719",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143720",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1108 Days Plan H - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF109KC1FN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143721",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143722",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143723",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143724",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 99 Days Plan I - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143725",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF109KC1FB7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143726",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF109KC1FA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143727",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF109KC1EY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143728",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 193 Days Plan G - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-07",
    "closeData": "",
    "isin": "INF109KC1EZ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143735",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FJ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143736",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143729",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143730",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143731",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FK8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143732",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FI2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143733",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FL6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143734",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1392 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF109KC1FG6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143737",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VII (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VII (1135 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-05",
    "closeData": "",
    "isin": "INF789FC18V4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143740",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VII (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VII (1135 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-05",
    "closeData": "",
    "isin": "INF789FC14V3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143741",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VII (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VII (1135 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-05",
    "closeData": "",
    "isin": "INF789FC11W7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143744",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VII (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VII (1135 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-05",
    "closeData": "",
    "isin": "INF789FC16V8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143745",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VII (1135 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VII (1135 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-05",
    "closeData": "",
    "isin": "INF789FC13V5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143755",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 15- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF204KB1YO6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143756",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 15 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF204KB1YP3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143757",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVII- Series 15- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF204KB1YM0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143758",
    "name": "Nippon India Fixed Horizon Fund XXXVII- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVII - SERIES 15 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF204KB1YN8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143777",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 3- Plan F (1098 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OK5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143778",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days )- Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OL3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143779",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 3- Plan F (1098 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OG3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143780",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 Plan F (1098 days) - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OJ7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143781",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 3- Plan F (1098 days)- Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OI9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "143782",
    "name": "Franklin India Fixed Maturity Plans- Series 3- Plan F (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 3 - Plan F 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF090I01OH1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "143783",
    "name": "Mirae Asset Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Healthcare Fund Direct Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "2018-06-25",
    "isin": "INF769K01ED6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "143784",
    "name": "Mirae Asset Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Healthcare Fund Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "2018-06-25",
    "isin": "INF769K01EE4",
    "isinRe": "INF769K01EF1"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "143785",
    "name": "Mirae Asset Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Healthcare Fund -Regular Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "2018-06-25",
    "isin": "INF769K01EA2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "143786",
    "name": "Mirae Asset Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Healthcare Fund Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2018-06-11",
    "closeData": "2018-06-25",
    "isin": "INF769K01EB0",
    "isinRe": "INF769K01EC8"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143787",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Navi Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EE9",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143788",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - REGULAR PLAN QUARTERLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EJ8",
    "isinRe": "INF959L01EK6"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143789",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - REGULAR PLAN NORMAL IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EF6",
    "isinRe": "INF959L01EG4"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143790",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - REGULAR PLAN MONTHLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EH2",
    "isinRe": "INF959L01EI0"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143791",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - DIRECT PLAN QUARTERLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01DY9",
    "isinRe": "INF959L01DZ6"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143792",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - DIRECT PLAN NORMAL IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01DU7",
    "isinRe": "INF959L01DV5"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143793",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Navi Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01DT9",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143794",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - REGULAR PLAN HALF YEARLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EL4",
    "isinRe": "INF959L01EM2"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143795",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - REGULAR PLAN ANNUAL IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EN0",
    "isinRe": "INF959L01EO8"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143796",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - DIRECT PLAN MONTHLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01DW3",
    "isinRe": "INF959L01DX1"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143797",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - DIRECT PLAN HALF YEARLY IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EA7",
    "isinRe": "INF959L01EB5"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "143798",
    "name": "Navi Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "NAVI FLEXI CAP FUND - DIRECT PLAN ANNUAL IDCW PAYOUT",
    "minAmount": "Rs. 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-18",
    "closeData": "2018-07-02",
    "isin": "INF959L01EC3",
    "isinRe": "INF959L01ED1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143799",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VIII (1127 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC11X5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143800",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC19W0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143801",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC18W2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143802",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC10X7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143803",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-VIII (1127 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC16W6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143804",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC14W1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143805",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC13W3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143806",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC17W4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143808",
    "name": "UTI - Fixed Term Income fund - Series XXIX - VIII (1127 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - VIII (1127 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-13",
    "closeData": "",
    "isin": "INF789FC12X3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143827",
    "name": "Sundaram Multi Cap Fund Series I",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series I Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF903JA1DU5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143828",
    "name": "Sundaram Multi Cap Fund Series I",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series I Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF903JA1DT7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143829",
    "name": "Sundaram Multi Cap Fund Series I",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series I Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF903JA1DS9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "143830",
    "name": "Sundaram Multi Cap Fund Series I",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series I Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-08",
    "closeData": "",
    "isin": "INF903JA1DR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143839",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143840",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143841",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143842",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 94 Days Plan K - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143847",
    "name": "Kotak FMP Series 230",
    "category": "Income",
    "navName": "Kotak FMP Series 230-Regular Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF174K012Z9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143848",
    "name": "Kotak FMP Series 230",
    "category": "Income",
    "navName": "Kotak FMP Series 230-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF174K013Z7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143849",
    "name": "Kotak FMP Series 230",
    "category": "Income",
    "navName": "Kotak FMP Series 230-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF174K014Z5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143850",
    "name": "Kotak FMP Series 230",
    "category": "Income",
    "navName": "Kotak FMP Series 230-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-11",
    "closeData": "",
    "isin": "INF174K011Z1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143863",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC19X8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143865",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC14X9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143866",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC10Y5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143867",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC18X0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143868",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-IX (1109 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC16X4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143870",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-IX (1109 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC11Y3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143871",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC13X1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143872",
    "name": "UTI - Fixed Term Income fund - Series XXIX - IX (1109 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - IX (1109 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-15",
    "closeData": "",
    "isin": "INF789FC15X6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143873",
    "name": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund - Cumulative Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF109KC1GE9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143874",
    "name": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund - Direct Plan - Cumulative Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF109KC1GH2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143875",
    "name": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF109KC1GG4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143876",
    "name": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Pharma Healthcare and Diagnostics (P.H.D) Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF109KC1GJ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143881",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1FZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143882",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1GC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143883",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1GA7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143884",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1GD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143877",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1FY9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143878",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": "INF109KC1GB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143879",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143880",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan L - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143899",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 1- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-21",
    "closeData": "",
    "isin": "INF204KB1A63",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143900",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 01 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-21",
    "closeData": "",
    "isin": "INF204KB1A71",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143901",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 1- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-21",
    "closeData": "",
    "isin": "INF204KB1A89",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143902",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 01 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-21",
    "closeData": "",
    "isin": "INF204KB1A97",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143903",
    "name": "ICICI Prudential BHARAT 22 FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential BHARAT 22 FOF - Direct Plan Cumulative Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF109KC1FX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143904",
    "name": "ICICI Prudential BHARAT 22 FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential BHARAT 22 FOF - Cumulative Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF109KC1FW3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143945",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 02 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B13",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143946",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 2- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B21",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143947",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 02 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B39",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143948",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B05",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143949",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 3- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B62",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143950",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 03 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B70",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143951",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B47",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "143952",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 03 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF204KB1B54",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143953",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GK6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143954",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143955",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GO8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143956",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143957",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GP5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143958",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143959",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143960",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1105 Days Plan M - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-26",
    "closeData": "",
    "isin": "INF109KC1GL4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143961",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 3 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-27",
    "closeData": "",
    "isin": "INF109KC1GS9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143962",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 3 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-27",
    "closeData": "",
    "isin": "INF109KC1GT7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143963",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 3 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-06-27",
    "closeData": "",
    "isin": "INF109KC1GR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "143964",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 3",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 3 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-27",
    "closeData": "",
    "isin": "INF109KC1GQ3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143965",
    "name": "Kotak FMP Series 231",
    "category": "Income",
    "navName": "Kotak FMP Series 231 - Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF174K015Z2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143966",
    "name": "Kotak FMP Series 231",
    "category": "Income",
    "navName": "Kotak FMP Series 231 - Regular Plan- Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF174K016Z0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143967",
    "name": "Kotak FMP Series 231",
    "category": "Income",
    "navName": "Kotak FMP Series 231- Direct Plan- Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF174K017Z8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143968",
    "name": "Kotak FMP Series 231",
    "category": "Income",
    "navName": "Kotak FMP Series 231- Direct Plan- Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-18",
    "closeData": "",
    "isin": "INF174K018Z6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143993",
    "name": "Kotak FMP Series 232",
    "category": "Income",
    "navName": "Kotak FMP Series 232- Regular Plan- Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF174K019Z4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143994",
    "name": "Kotak FMP Series 232",
    "category": "Income",
    "navName": "Kotak FMP Series 232-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF174KA1020",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143995",
    "name": "Kotak FMP Series 232",
    "category": "Income",
    "navName": "Kotak FMP Series 232-Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF174KA1012",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "143996",
    "name": "Kotak FMP Series 232",
    "category": "Income",
    "navName": "Kotak FMP Series 232-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-19",
    "closeData": "",
    "isin": "INF174KA1038",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143997",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XI (1112 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XI (1112 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF789F1A025",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "143999",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XI (1112 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XI (1112 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF789F1A017",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144003",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XI (1112 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XI (1112 DAYS) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF789F1A033",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144006",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XI (1112 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XI (1112 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF789FC16Z9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144007",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed maturity Plans- Series 4- Plan A (1098 days) - Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01OP4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144008",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan A 1098 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01OQ2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144009",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan A 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01ON9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144010",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan A 1098 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01OO7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144011",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days) -Growth",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01OM1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144012",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan A (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan A 1098 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-06-22",
    "closeData": "",
    "isin": "INF090I01OR0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144017",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 4- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": "INF204KB1C04",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144018",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 4- Direct Plan- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": "INF204KB1C12",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144019",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": "INF204KB1B88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144020",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 4",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 4- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": "INF204KB1B96",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144021",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144022",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144023",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144024",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144025",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144026",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144027",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144028",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1103 Days Plan N - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-06-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144035",
    "name": "SBI Debt Fund Series C - 19 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 19 (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-27",
    "closeData": "2018-07-02",
    "isin": "INF200KA1UK7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144036",
    "name": "SBI Debt Fund Series C - 19 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 19 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-27",
    "closeData": "2018-07-02",
    "isin": "INF200KA1UL5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144037",
    "name": "SBI Debt Fund Series C - 19 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 19 (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-27",
    "closeData": "2018-07-02",
    "isin": "INF200KA1UM3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144038",
    "name": "SBI Debt Fund Series C - 19 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 19 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-06-27",
    "closeData": "2018-07-02",
    "isin": "INF200KA1UN1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144061",
    "name": "Kotak FMP Series 233",
    "category": "Income",
    "navName": "Kotak FMP Series 233 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF174KA1046",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144062",
    "name": "Kotak FMP Series 233",
    "category": "Income",
    "navName": "Kotak FMP Series 233 - Regular Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF174KA1053",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144063",
    "name": "Kotak FMP Series 233",
    "category": "Income",
    "navName": "Kotak FMP Series 233 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF174KA1061",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144064",
    "name": "Kotak FMP Series 233",
    "category": "Income",
    "navName": "Kotak FMP Series 233 - Direct Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-06-25",
    "closeData": "",
    "isin": "INF174KA1079",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144065",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HJ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144066",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HH0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144067",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HK4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144068",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HI8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144069",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HL2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144070",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144071",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144072",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HG2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144073",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144074",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144075",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HC1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144076",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HF4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144077",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HA5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144078",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HD9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144079",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HB3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144080",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1735 Days Plan P - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-03",
    "closeData": "",
    "isin": "INF109KC1HE7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144081",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF204KB1C20",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144082",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 05 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF204KB1C38",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144083",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 05 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF204KB1C53",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144084",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 5- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF204KB1C46",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144095",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A090",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144096",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XIII (1122 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A124",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144097",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A116",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144098",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XIII (1122 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A074",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144099",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A066",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144100",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A082",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144102",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A058",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144103",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A108",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144104",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIII (1122 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIII (1122 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-02",
    "closeData": "",
    "isin": "INF789F1A041",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144113",
    "name": "SBI Dual Advantage Fund - Series XXIX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXIX - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-02",
    "closeData": "2018-07-16",
    "isin": "INF200KA1UP6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144114",
    "name": "SBI Dual Advantage Fund - Series XXIX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXIX - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-02",
    "closeData": "2018-07-16",
    "isin": "INF200KA1UQ4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144111",
    "name": "SBI Dual Advantage Fund - Series XXIX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXIX - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-02",
    "closeData": "2018-07-16",
    "isin": "INF200KA1UO9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144112",
    "name": "SBI Dual Advantage Fund - Series XXIX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXIX - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-02",
    "closeData": "2018-07-16",
    "isin": "INF200KA1UR2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144115",
    "name": "HSBC Fixed Term Series 135 (1117 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 135 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-06-29",
    "closeData": "",
    "isin": "INF336L01NB7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144116",
    "name": "HSBC Fixed Term Series 135 (1117 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 135 - IDCW - Tenure 1117 Days - Maturity  30-Jul-2021",
    "minAmount": "5000",
    "launchDate": "2018-06-29",
    "closeData": "",
    "isin": "INF336L01NC5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144117",
    "name": "HSBC Fixed Term Series 135 (1117 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 135 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-06-29",
    "closeData": "",
    "isin": "INF336L01MZ8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144118",
    "name": "HSBC Fixed Term Series 135 (1117 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 135 - IDCW Direct Plan - Tenure 1117 Days - Maturity  30-Jul-2021",
    "minAmount": "5000",
    "launchDate": "2018-06-29",
    "closeData": "",
    "isin": "INF336L01NA9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144159",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 6- Direct Plan -Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF204KB1C87",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144160",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 6- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF204KB1C61",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144161",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 06 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF204KB1C95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144162",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 06 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF204KB1C79",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144169",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144170",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144163",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HM0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144164",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144165",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144166",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144167",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HN8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144168",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1107 Days Plan Q - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF109KC1HO6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144171",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN GROWTH",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA10Q8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144172",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN DAILY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA12Q4",
    "isinRe": "INF194KA13Q2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144173",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN GROWTH",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA16R3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144174",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN-DAILY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA18R9",
    "isinRe": "INF194KA19R7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144195",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN -MONTHLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA11S2",
    "isinRe": "INF194KA12S0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144196",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN PERIODIC IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA17S9",
    "isinRe": "INF194KA18S7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144189",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN WEEKLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA14R8",
    "isinRe": "INF194KA15R5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144190",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN QUARTERLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA18Q1",
    "isinRe": "INF194KA19Q9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144191",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN- WEEKLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA10T2",
    "isinRe": "INF194KA11T0"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144192",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - DIRECT PLAN QUARTERLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA14S6",
    "isinRe": "INF194KA15S3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144193",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN PERIODIC IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA11R4",
    "isinRe": "INF194KA12R2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "144194",
    "name": "BANDHAN ULTRA SHORT TERM FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "BANDHAN ULTRA SHORT TERM FUND - REGULAR PLAN MONTHLY IDCW",
    "minAmount": "Subscription  Rs.100/- and any amount thereafter",
    "launchDate": "2018-07-06",
    "closeData": "",
    "isin": "INF194KA15Q7",
    "isinRe": "INF194KA16Q5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144200",
    "name": "Sundaram Multi Cap Fund Series II",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series II Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF903JA1EF4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144197",
    "name": "Sundaram Multi Cap Fund Series II",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series II Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF903JA1ED9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144198",
    "name": "Sundaram Multi Cap Fund Series II",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series II Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF903JA1EE7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144199",
    "name": "Sundaram Multi Cap Fund Series II",
    "category": "Growth",
    "navName": "Sundaram Multi Cap Fund Series II Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF903JA1EG2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144201",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIV (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XIV (1131 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF789F1A322",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144202",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIV (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIV (1131 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF789F1A298",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144206",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIV (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XIV (1131 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF789F1A272",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144209",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XIV (1131 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXIX - XIV (1131 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-05",
    "closeData": "",
    "isin": "INF789F1A264",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144227",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XV (1124 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XV (1124 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF789F1A413",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144231",
    "name": "UTI - Fixed Term Income fund - Series XXIX - XV (1124 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXIX-XV (1124 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-07-12",
    "closeData": "",
    "isin": "INF789F1A371",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144248",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 7- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D29",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144249",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 07 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D37",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144250",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D03",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144247",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 07 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D11",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144251",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 8- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D52",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144252",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 8- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D60",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144253",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 8- Direct Plan- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D78",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144254",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 8",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF204KB1D45",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144265",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan B 1098 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OU4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144266",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4 - Plan B (1098 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OX8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144267",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 4- Plan B (1098 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OS8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144268",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 4 - Plan B (1098 days)- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OV2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144269",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan B 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OT6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144270",
    "name": "Franklin India Fixed Maturity Plans Series 4- Plan B (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan B 1098 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF090I01OW0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144279",
    "name": "Kotak FMP Series 234",
    "category": "Income",
    "navName": "Kotak FMP Series 234 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1103",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144280",
    "name": "Kotak FMP Series 234",
    "category": "Income",
    "navName": "Kotak FMP Series 234 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1111",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144281",
    "name": "Kotak FMP Series 234",
    "category": "Income",
    "navName": "Kotak FMP Series 234 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1087",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144282",
    "name": "Kotak FMP Series 234",
    "category": "Income",
    "navName": "Kotak FMP Series 234 -Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1095",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144283",
    "name": "Kotak FMP Series 235",
    "category": "Income",
    "navName": "Kotak FMP Series 235 - Direct Plan - Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1145",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144284",
    "name": "Kotak FMP Series 235",
    "category": "Income",
    "navName": "Kotak FMP Series 235 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1129",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144285",
    "name": "Kotak FMP Series 235",
    "category": "Income",
    "navName": "Kotak FMP Series 235 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1152",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144286",
    "name": "Kotak FMP Series 235",
    "category": "Income",
    "navName": "Kotak FMP Series 235 - Regular Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-10",
    "closeData": "",
    "isin": "INF174KA1137",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144301",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HU3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144302",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HX7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144303",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144304",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HY5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144305",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144306",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HW9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144307",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HZ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144308",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan R - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-17",
    "closeData": "",
    "isin": "INF109KC1HV1",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "144309",
    "name": "BANK OF INDIA MID CAP TAX FUND Series 2",
    "category": "ELSS",
    "navName": "BANK OF INDIA Midcap Tax Fund Series 2 Direct Plan IDCW",
    "minAmount": "500",
    "launchDate": "2018-07-12",
    "closeData": "2018-10-11",
    "isin": "INF761K01EK8",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "144314",
    "name": "BANK OF INDIA MID CAP TAX FUND Series 2",
    "category": "ELSS",
    "navName": "BANK OF INDIA Midcap Tax Fund Series 2 Regular Plan Growth",
    "minAmount": "500",
    "launchDate": "2018-07-12",
    "closeData": "2018-10-11",
    "isin": "INF761K01EN2",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "144315",
    "name": "BANK OF INDIA MID CAP TAX FUND Series 2",
    "category": "ELSS",
    "navName": "BANK OF INDIA Midcap Tax Fund Series 2 Direct Plan Growth",
    "minAmount": "500",
    "launchDate": "2018-07-12",
    "closeData": "2018-10-11",
    "isin": "INF761K01EL6",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "144316",
    "name": "BANK OF INDIA MID CAP TAX FUND Series 2",
    "category": "ELSS",
    "navName": "BANK OF INDIA Midcap Tax Fund Series 2 Regular Plan IDCW",
    "minAmount": "500",
    "launchDate": "2018-07-12",
    "closeData": "2018-10-11",
    "isin": "INF761K01EM4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "144310",
    "name": "Union Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Union Equity Savings Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-07-19",
    "closeData": "",
    "isin": "INF582M01EG2",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "144311",
    "name": "Union Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Union Equity Savings Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-07-19",
    "closeData": "",
    "isin": "INF582M01EI8",
    "isinRe": "INF582M01EH0"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "144312",
    "name": "Union Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Union Equity Savings Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-07-19",
    "closeData": "",
    "isin": "INF582M01EC1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "144313",
    "name": "Union Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Union Equity Savings Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-07-19",
    "closeData": "",
    "isin": "INF582M01EE7",
    "isinRe": "INF582M01ED9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144333",
    "name": "Kotak Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Kotak Balanced Advantage Fund -Regular Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2018-07-13",
    "closeData": "",
    "isin": "INF174KA1186",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144334",
    "name": "Kotak Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Kotak Balanced Advantage Fund -Direct Plan  -Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "1000",
    "launchDate": "2018-07-13",
    "closeData": "",
    "isin": "INF174KA1194",
    "isinRe": "INF174KA1202"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144335",
    "name": "Kotak Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Kotak Balanced Advantage Fund  - Direct Plan -Growth Option",
    "minAmount": "1000",
    "launchDate": "2018-07-13",
    "closeData": "",
    "isin": "INF174KA1210",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144336",
    "name": "Kotak Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Kotak Balanced Advantage Fund - Regular Plan - Payout of Income Distribution cum capital withdrawal option Option",
    "minAmount": "1000",
    "launchDate": "2018-07-13",
    "closeData": "",
    "isin": "INF174KA1160",
    "isinRe": "INF174KA1178"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144341",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A603",
    "isinRe": "INF789F1A611"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144342",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A454",
    "isinRe": "INF789F1A462"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144343",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A470",
    "isinRe": "INF789F1A488"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144344",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A587",
    "isinRe": "INF789F1A595"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144345",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A447",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144346",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A546",
    "isinRe": "INF789F1A553"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144337",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A496",
    "isinRe": "INF789F1A504"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144338",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A512",
    "isinRe": "INF789F1A520"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144339",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A538",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144340",
    "name": "UTI - Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Corporate Bond Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF789F1A561",
    "isinRe": "INF789F1A579"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144347",
    "name": "SBI Debt Fund Series C - 20 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 20 - (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-17",
    "closeData": "2018-07-18",
    "isin": "INF200KA1US0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144348",
    "name": "SBI Debt Fund Series C - 20 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 20 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-17",
    "closeData": "2018-07-18",
    "isin": "INF200KA1UT8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144349",
    "name": "SBI Debt Fund Series C - 20 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 20 - (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-17",
    "closeData": "2018-07-18",
    "isin": "INF200KA1UU6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144350",
    "name": "SBI Debt Fund Series C - 20 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 20 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-17",
    "closeData": "2018-07-18",
    "isin": "INF200KA1UV4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144351",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 10 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF204KB1E36",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144352",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 10- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF204KB1E44",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144353",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF204KB1E28",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144354",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 10 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF204KB1E51",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144361",
    "name": "Kotak FMP Series 236",
    "category": "Income",
    "navName": "Kotak FMP Series 236 -Regular Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-16",
    "closeData": "",
    "isin": "INF174KA1236",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144362",
    "name": "Kotak FMP Series 236",
    "category": "Income",
    "navName": "Kotak FMP Series 236 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-16",
    "closeData": "",
    "isin": "INF174KA1244",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144363",
    "name": "Kotak FMP Series 236",
    "category": "Income",
    "navName": "Kotak FMP Series 236 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-16",
    "closeData": "",
    "isin": "INF174KA1228",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144364",
    "name": "Kotak FMP Series 236",
    "category": "Income",
    "navName": "Kotak FMP Series 236  - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-16",
    "closeData": "",
    "isin": "INF174KA1251",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144365",
    "name": "SBI Debt Fund Series C - 21 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 21 (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "2018-07-30",
    "isin": "INF200KA1UW2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144366",
    "name": "SBI Debt Fund Series C - 21 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 21 (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "2018-07-30",
    "isin": "INF200KA1UY8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144367",
    "name": "SBI Debt Fund Series C - 21 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 21 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "2018-07-30",
    "isin": "INF200KA1UX0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144368",
    "name": "SBI Debt Fund Series C - 21 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 21 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "2018-07-30",
    "isin": "INF200KA1UZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144376",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1ID7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144377",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1IB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144378",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1IE5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144379",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1IC9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144380",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1IA3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144381",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan S - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF109KC1IF2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144387",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1II6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144388",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1IL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144389",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1IJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144390",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1IM8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144391",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1IK2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144392",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1110 Days Plan T - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF109KC1IN6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144393",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E90",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144394",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E25",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144395",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Direct Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E09",
    "isinRe": "INF846K01E17"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144396",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Regular Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E74",
    "isinRe": "INF846K01E82"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144397",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E33",
    "isinRe": "INF846K01E41"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144398",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01E58",
    "isinRe": "INF846K01E66"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144399",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01F08",
    "isinRe": "INF846K01F16"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144400",
    "name": "Axis Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Axis Equity Hybrid Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 10/- thereafter",
    "launchDate": "2018-07-20",
    "closeData": "",
    "isin": "INF846K01F24",
    "isinRe": "INF846K01F32"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144401",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01531",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144402",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01572",
    "isinRe": "INF174V01564"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144403",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01580",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144404",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01622",
    "isinRe": "INF174V01614"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144405",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01556",
    "isinRe": "INF174V01549"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "144406",
    "name": "Mahindra Manulife Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Mahindra Manulife Dynamic Bond Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": "INF174V01606",
    "isinRe": "INF174V01598"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144427",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144428",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144429",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144430",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144425",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144426",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1106 Days Plan U - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144431",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144432",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144433",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144434",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 204 Days Plan V - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144435",
    "name": "Sundaram Emerging Small Cap Series V",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series V Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF903JA1EL2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144436",
    "name": "Sundaram Emerging Small Cap Series V",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series V Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF903JA1EM0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144437",
    "name": "Sundaram Emerging Small Cap Series V",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series V Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF903JA1EN8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144438",
    "name": "Sundaram Emerging Small Cap Series V",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series V Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-07-25",
    "closeData": "",
    "isin": "INF903JA1EO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144445",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 4 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF109KC1IU1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144446",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 4 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF109KC1IV9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144447",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 4 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF109KC1IX5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144448",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 4",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 4 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF109KC1IW7",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "144454",
    "name": "IDBI Long Term Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "IDBI Long Term Value Fund-Regular Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF397L01KN0",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "144455",
    "name": "IDBI Long Term Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "IDBI Long Term Value Fund-Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF397L01KU5",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "144452",
    "name": "IDBI Long Term Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "IDBI Long Term Value Fund-Direct Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF397L01KR1",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "144453",
    "name": "IDBI Long Term Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "IDBI Long Term Value Fund-Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF397L01KQ3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144457",
    "name": "Kotak FMP Series 237",
    "category": "Income",
    "navName": "Kotak FMP Series 237 - Regular Plan -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF174KA1277",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144458",
    "name": "Kotak FMP Series 237",
    "category": "Income",
    "navName": "Kotak FMP Series 237-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF174KA1293",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144459",
    "name": "Kotak FMP Series 237",
    "category": "Income",
    "navName": "Kotak FMP Series 237 - Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF174KA1285",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144460",
    "name": "Kotak FMP Series 237",
    "category": "Income",
    "navName": "Kotak FMP Series 237- Regular Plan -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-23",
    "closeData": "",
    "isin": "INF174KA1269",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144465",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - Direct - Monthly - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PH8",
    "isinRe": "INF090I01PG0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144466",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund- Growth Direct",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PD7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144467",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - Direct - Quarterly - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PL0",
    "isinRe": "INF090I01PK2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144468",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01OZ3",
    "isinRe": "INF090I01OY6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144461",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund- Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PA3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144462",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PC9",
    "isinRe": "INF090I01PB1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144463",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - Quarterly - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PJ4",
    "isinRe": "INF090I01PI6"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144464",
    "name": "Franklin India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Franklin India Equity Savings Fund - Monthly - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF090I01PF2",
    "isinRe": "INF090I01PE5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144469",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144470",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144471",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144472",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144473",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144474",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1361 Days Plan W - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144476",
    "name": "Kotak FMP Series 238",
    "category": "Income",
    "navName": "Kotak FMP Series 238 - Regular Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF174KA1319",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144477",
    "name": "Kotak FMP Series 238",
    "category": "Income",
    "navName": "Kotak FMP Series 238- Direct Plan -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF174KA1327",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144478",
    "name": "Kotak FMP Series 238",
    "category": "Income",
    "navName": "Kotak FMP Series 238 - Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF174KA1301",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144479",
    "name": "Kotak FMP Series 238",
    "category": "Income",
    "navName": "Kotak FMP Series 238-Direct Plan -Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-07-30",
    "closeData": "",
    "isin": "INF174KA1335",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144480",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF204KB1E69",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144481",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 11 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF204KB1E77",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144482",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 11- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF204KB1E85",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144483",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 11 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF204KB1E93",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144484",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A744",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144485",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Regular Plan - IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A736",
    "isinRe": "INF789F1A728"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144486",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A793",
    "isinRe": "INF789F1A785"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144487",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A835",
    "isinRe": "INF789F1A827"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144488",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A850",
    "isinRe": "INF789F1A843"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144489",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A819",
    "isinRe": "INF789F1A801"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144490",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A777",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144491",
    "name": "UTI - Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "UTI Equity Savings Fund - Direct Plan - IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-08-10",
    "closeData": "",
    "isin": "INF789F1A769",
    "isinRe": "INF789F1A751"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144492",
    "name": "SBI Debt Fund Series C - 22 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 22 (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-02",
    "closeData": "2018-08-16",
    "isin": "INF200KA1VA6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144493",
    "name": "SBI Debt Fund Series C - 22 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 22 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-02",
    "closeData": "2018-08-16",
    "isin": "INF200KA1VB4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144494",
    "name": "SBI Debt Fund Series C - 22 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 22 (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-02",
    "closeData": "2018-08-16",
    "isin": "INF200KA1VC2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144495",
    "name": "SBI Debt Fund Series C - 22 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 22 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-02",
    "closeData": "2018-08-16",
    "isin": "INF200KA1VD0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144527",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 12- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-09",
    "closeData": "",
    "isin": "INF204KB1F01",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144528",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 12- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-09",
    "closeData": "",
    "isin": "INF204KB1F27",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144529",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 12 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-09",
    "closeData": "",
    "isin": "INF204KB1F35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144530",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 12 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-09",
    "closeData": "",
    "isin": "INF204KB1F19",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144543",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "TATA Flexi Cap Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K016K8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144544",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "TATA Flexi Cap Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K011L7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144545",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "TATA Flexi Cap Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K010L9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144546",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Tata Flexi Cap Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K015K0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144547",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "TATA Flexi Cap Fund - Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K017K6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "144548",
    "name": "Tata Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Tata Flexi Cap Fund -Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF277K019K2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144555",
    "name": "HSBC Fixed Term Series 136 (1145 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 136 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF336L01NF8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144556",
    "name": "HSBC Fixed Term Series 136 (1145 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 136 - Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF336L01ND3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144557",
    "name": "HSBC Fixed Term Series 136 (1145 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 136 - IDCW - Tenure 1145 Days - Maturity  30-Sep-2021",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF336L01NG6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "144558",
    "name": "HSBC Fixed Term Series 136 (1145 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 136 - IDCW Direct Plan - Tenure 1145 Days - Maturity  30-Sep-2021",
    "minAmount": "5000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF336L01NE1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144571",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-I (1104 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A702",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144572",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A678",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144573",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A694",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144574",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-I (1104 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A652",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144575",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A637",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144576",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A645",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144577",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A660",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144579",
    "name": "UTI - Fixed Term Income fund - Series XXX - I (1104 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - I (1104 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-08",
    "closeData": "",
    "isin": "INF789F1A629",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144587",
    "name": "ICICI Prudential Nifty Next 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Next 50 ETF",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF109KC1NS5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144589",
    "name": "Kotak FMP Series 239",
    "category": "Income",
    "navName": "Kotak FMP Series 239 - Direct Plan - Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF174KA1368",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144590",
    "name": "Kotak FMP Series 239",
    "category": "Income",
    "navName": "Kotak FMP Series 239 - Direct Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF174KA1376",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144591",
    "name": "Kotak FMP Series 239",
    "category": "Income",
    "navName": "Kotak FMP Series 239 - Regular plan- Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF174KA1350",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144588",
    "name": "Kotak FMP Series 239",
    "category": "Income",
    "navName": "Kotak FMP Series 239 - Regular plan -Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-08-03",
    "closeData": "",
    "isin": "INF174KA1343",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144592",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 13- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF204KB1F50",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144593",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 13 - Direct Plan- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF204KB1F76",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144594",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 13- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF204KB1F68",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144595",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 13- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF204KB1F43",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144596",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JR5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144597",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144598",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JT1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144599",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144600",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JW5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144601",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1111 Days Plan Y - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JS3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144606",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JP9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144607",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JN4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144602",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JL8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144603",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JO2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144604",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JM6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144605",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1100 Days Plan X - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-14",
    "closeData": "",
    "isin": "INF109KC1JQ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144612",
    "name": "Kotak FMP Series 240",
    "category": "Income",
    "navName": "Kotak FMP Series 240-Regular Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1392",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144613",
    "name": "Kotak FMP Series 240",
    "category": "Income",
    "navName": "Kotak FMP Series 240 - Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1418",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144614",
    "name": "Kotak FMP Series 240",
    "category": "Income",
    "navName": "Kotak FMP Series 240 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1400",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144615",
    "name": "Kotak FMP Series 240",
    "category": "Income",
    "navName": "Kotak FMP Series 240 - Regular Plan - Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1384",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144616",
    "name": "Kotak FMP Series 241",
    "category": "Income",
    "navName": "Kotak FMP Series 241-Regular Plan-Dividend option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1434",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144617",
    "name": "Kotak FMP Series 241",
    "category": "Income",
    "navName": "Kotak FMP Series 241-Direct PLan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1442",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144618",
    "name": "Kotak FMP Series 241",
    "category": "Income",
    "navName": "Kotak FMP Series 241-Direct Plan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1459",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144619",
    "name": "Kotak FMP Series 241",
    "category": "Income",
    "navName": "Kotak FMP Series 241- Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-07",
    "closeData": "",
    "isin": "INF174KA1426",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144627",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 14 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF204KB1G18",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144624",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 14- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF204KB1F84",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144625",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXVIII - SERIES 14 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF204KB1F92",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144626",
    "name": "Nippon India Fixed Horizon Fund XXXVIII- Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXVIII- Series 14- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF204KB1G00",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144650",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Regular - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1JY8",
    "isinRe": "INF740KA1JZ5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144651",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Direct - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KH1",
    "isinRe": "INF740KA1KI9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144644",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Regular - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1JX0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144645",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Regular - IDCW - Monthly",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KA6",
    "isinRe": "INF740KA1KB4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144646",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Direct - Growth",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KE8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144647",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Direct - IDCW",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KF5",
    "isinRe": "INF740KA1KG3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144648",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Direct - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KJ7",
    "isinRe": "INF740KA1KK5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "144649",
    "name": "DSP Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "DSP Corporate Bond Fund - Regular - IDCW - Quarterly ",
    "minAmount": "Rs. 1000/  and any amount thereafter.",
    "launchDate": "2018-08-23",
    "closeData": "2018-09-05",
    "isin": "INF740KA1KC2",
    "isinRe": "INF740KA1KD0"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144652",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4 Plan C (1098 days) -Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PM8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144653",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 4 Plan C (1098 days) -Growth -Direct",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PP1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144654",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan C 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PN6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144655",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4 Plan C (1098 days) -Dividend -Direct",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PQ9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144656",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan C 1098 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PO4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144657",
    "name": "Franklin India Fixed Maturity Plans -Series 4-Plan C (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan C 1098 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF090I01PR7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144659",
    "name": "UTI - Fixed Term Income fund - Series XXX - II (1107 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - II (1107 DAYS) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-16",
    "closeData": "",
    "isin": "INF789F1A926",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144661",
    "name": "UTI - Fixed Term Income fund - Series XXX - II (1107 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income fund - Series XXX - II (1107 DAYS) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-16",
    "closeData": "",
    "isin": "INF789F1A868",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144663",
    "name": "UTI - Fixed Term Income fund - Series XXX - II (1107 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-II (1107 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-16",
    "closeData": "",
    "isin": "INF789F1A892",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144666",
    "name": "UTI - Fixed Term Income fund - Series XXX - II (1107 DAYS)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-II (1107 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-16",
    "closeData": "",
    "isin": "INF789F1A942",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144669",
    "name": "Kotak FMP Series 242",
    "category": "Income",
    "navName": "Kotak FMP Series 242 - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1467",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144670",
    "name": "Kotak FMP Series 242",
    "category": "Income",
    "navName": "Kotak FMP Series 242 -Regular Plan -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1475",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144671",
    "name": "Kotak FMP Series 242",
    "category": "Income",
    "navName": "Kotak FMP Series 242 - Direct Plan  -Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1483",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144672",
    "name": "Kotak FMP Series 242",
    "category": "Income",
    "navName": "Kotak FMP Series 242 - Direct Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1491",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144673",
    "name": "Kotak India Growth Fund Series 7",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 7 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1525",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144674",
    "name": "Kotak India Growth Fund Series 7",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 7 - Regular Plan - ayout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1517",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144675",
    "name": "Kotak India Growth Fund Series 7",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 7 -Direct Plan  -ayout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1533",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144676",
    "name": "Kotak India Growth Fund Series 7",
    "category": "Growth",
    "navName": "Kotak India Growth Fund Series 7 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-13",
    "closeData": "",
    "isin": "INF174KA1509",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "144681",
    "name": "Motilal Oswal Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Motilal Oswal Equity Hybrid Fund (MOFEH) - Direct Plan Growth Option",
    "minAmount": "5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-24",
    "closeData": "",
    "isin": "INF247L01692",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "144730",
    "name": "Motilal Oswal Equity Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Motilal Oswal Equity Hybrid Fund (MOFEH) - Regular Plan Growth Option",
    "minAmount": "5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-24",
    "closeData": "",
    "isin": "INF247L01684",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144688",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 15- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G67",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144689",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 15- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G75",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144690",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 15- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G83",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144691",
    "name": "Reliance Fixed Horizon Fund XXXVIII- Series 15",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXVIII- Series 15 - Direct Plan- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G91",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144692",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 1- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G42",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144693",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 1 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G59",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144694",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 1- Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G26",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144695",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 1 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF204KB1G34",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144696",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1JX3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144697",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1KA9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144698",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1JY1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144699",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1JZ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144700",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1KC5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144701",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 83 - 1101 Days Plan Z - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF109KC1KB7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144726",
    "name": "Sundaram Emerging Small cap Series VI",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VI Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF903JA1ET5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144727",
    "name": "Sundaram Emerging Small cap Series VI",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VI Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF903JA1EU3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144728",
    "name": "Sundaram Emerging Small cap Series VI",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VI Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF903JA1EV1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144729",
    "name": "Sundaram Emerging Small cap Series VI",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VI Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-08-20",
    "closeData": "",
    "isin": "INF903JA1EW9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144731",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAC3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144732",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAF6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144733",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1A991",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144734",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1A975",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144735",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1A967",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144736",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAE9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144737",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1A983",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144738",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAB5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144739",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAD1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144740",
    "name": "UTI - Fixed Term Income Fund Series XXX-III (1106 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-III (1106 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-08-27",
    "closeData": "",
    "isin": "INF789F1AAA7",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144753",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01F57",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144754",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Direct Plan Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01F40",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144755",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01F99",
    "isinRe": "INF846K01F81"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144756",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Direct Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G15",
    "isinRe": "INF846K01G07"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144757",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01F73",
    "isinRe": "INF846K01F65"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144758",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G31",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144759",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Regular Plan Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G23",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144760",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G72",
    "isinRe": "INF846K01G64"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144761",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Regular Plan - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G98",
    "isinRe": "INF846K01G80"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "144762",
    "name": "Axis Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Axis Ultra Short Term Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2018-08-27",
    "closeData": "2018-09-04",
    "isin": "INF846K01G56",
    "isinRe": "INF846K01G49"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144763",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan D (1098 days) - Growth",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PS5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144764",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series4 - Plan D (1098 days) Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PV9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144765",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan D 1098 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PU1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144766",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan D 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PT3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144767",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan D (1098 days)- Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PW7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144768",
    "name": "Franklin India Fixed Maturity Plans - Series 4 Plan D (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan D(1098 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-09-03",
    "closeData": "",
    "isin": "INF090I01PX5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144769",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 2- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H41",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144770",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 2- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H66",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144771",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 2 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H74",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144772",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 2 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H58",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144773",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 3- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H82",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144774",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 3- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1H90",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144775",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 3- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1I08",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144776",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 3 - Direct Plan- Dividend Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF204KB1I16",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144798",
    "name": "HDFC FMP 1487D August 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1487D August 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "2018-09-03",
    "isin": "INF179KB12O8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144800",
    "name": "HDFC FMP 1487D August 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1487D August 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "2018-09-03",
    "isin": "INF179KB19N5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144801",
    "name": "HDFC FMP 1487D August 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1487D August 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "2018-09-03",
    "isin": "INF179KB11O0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144796",
    "name": "HDFC FMP 1487D August 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1487D August 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "2018-09-03",
    "isin": "INF179KB10O2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144797",
    "name": "HDFC FMP 1487D August 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1487D August 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "2018-09-03",
    "isin": "INF179KB13O6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144814",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KE1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144815",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144816",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KI2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144817",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KD3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144818",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KF8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144819",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1101 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-08-31",
    "closeData": "",
    "isin": "INF109KC1KG6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144834",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Regular Plan - Reinvestment of Income Distribution cum Capital Withdrawal (IDCW) ",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1EZ2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144835",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1FA2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144836",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW) ",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1FB0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144837",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Direct Plan - Reinvestment of Income Distribution cum Capital Withdrawal (IDCW) ",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1FC8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144838",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1EX7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144839",
    "name": "Sundaram Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Sundaram Services Fund Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW) ",
    "minAmount": "5000",
    "launchDate": "2018-08-29",
    "closeData": "",
    "isin": "INF903JA1EY5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144840",
    "name": "SBI Debt Fund Series C - 23 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 23 - (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-28",
    "closeData": "2018-08-30",
    "isin": "INF200KA1VI9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144841",
    "name": "SBI Debt Fund Series C - 23 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 23 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-28",
    "closeData": "2018-08-30",
    "isin": "INF200KA1VJ7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144842",
    "name": "SBI Debt Fund Series C - 23 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 23 - (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-28",
    "closeData": "2018-08-30",
    "isin": "INF200KA1VK5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144843",
    "name": "SBI Debt Fund Series C - 23 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 23 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-08-28",
    "closeData": "2018-08-30",
    "isin": "INF200KA1VL3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144844",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144845",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144846",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144847",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 97 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144848",
    "name": "Kotak FMP Series 243",
    "category": "Income",
    "navName": "Kotak FMP Series 243-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-28",
    "closeData": "",
    "isin": "INF174KA1541",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144849",
    "name": "Kotak FMP Series 243",
    "category": "Income",
    "navName": "Kotak FMP Series 243 - Regular Plan -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-28",
    "closeData": "",
    "isin": "INF174KA1558",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144850",
    "name": "Kotak FMP Series 243",
    "category": "Income",
    "navName": "Kotak FMP Series 243-Direct Plan -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-28",
    "closeData": "",
    "isin": "INF174KA1566",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144851",
    "name": "Kotak FMP Series 243",
    "category": "Income",
    "navName": "Kotak FMP Series 243-Direct Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-08-28",
    "closeData": "",
    "isin": "INF174KA1574",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144888",
    "name": "Nippon India India Opportunities Fund - Series A",
    "category": "Growth",
    "navName": "Nippon India India Opportunities Fund - Series A - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF204KB1I24",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144889",
    "name": "Nippon India India Opportunities Fund - Series A",
    "category": "Growth",
    "navName": "Nippon India India Opportunities Fund - Series A - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF204KB1I40",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144890",
    "name": "Nippon India India Opportunities Fund - Series A",
    "category": "Growth",
    "navName": "NIPPON INDIA - INDIA OPPORTUNITIES FUND - SERIES A - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF204KB1I32",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144891",
    "name": "Nippon India India Opportunities Fund - Series A",
    "category": "Growth",
    "navName": "NIPPON INDIA - INDIA OPPORTUNITIES FUND - SERIES A - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF204KB1I57",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144898",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 4 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1I99",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144899",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 4- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1I81",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144900",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 4- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1I65",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144901",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 4 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1I73",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "144902",
    "name": "Shriram Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Shriram Flexi Cap Fund - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF680P01075",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "144903",
    "name": "Shriram Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Shriram Flexi Cap Fund - Regular-IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF680P01083",
    "isinRe": "INF680P01091"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "144905",
    "name": "Shriram Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Shriram Flexi Cap Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF680P01109",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "144906",
    "name": "Shriram Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Shriram Flexi Cap Fund - Direct-IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-07",
    "closeData": "",
    "isin": "INF680P01117",
    "isinRe": "INF680P01125"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144908",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 5- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1J07",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144909",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 5 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1J15",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144910",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 5- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1J23",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144911",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 5 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF204KB1J31",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144916",
    "name": "SBI S&P BSE Sensex Next 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI S&P BSE Sensex Next 50 ETF",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-05",
    "closeData": "2018-09-17",
    "isin": "INF200KA1VQ2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144917",
    "name": "SBI Debt Fund Series C - 24 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 24 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-06",
    "closeData": "2018-09-06",
    "isin": "INF200KA1VN9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144918",
    "name": "SBI Debt Fund Series C - 24 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 24 - (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-06",
    "closeData": "2018-09-06",
    "isin": "INF200KA1VO7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144919",
    "name": "SBI Debt Fund Series C - 24 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 24 - (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-06",
    "closeData": "2018-09-06",
    "isin": "INF200KA1VM1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144920",
    "name": "SBI Debt Fund Series C - 24 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 24 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-06",
    "closeData": "2018-09-06",
    "isin": "INF200KA1VP4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144921",
    "name": "Kotak FMP Series 244",
    "category": "Income",
    "navName": "Kotak FMP Series 244 - Direct Plan  -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-04",
    "closeData": "",
    "isin": "INF174KA1608",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144922",
    "name": "Kotak FMP Series 244",
    "category": "Income",
    "navName": "Kotak FMP Series 244 - Regular Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-04",
    "closeData": "",
    "isin": "INF174KA1590",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144923",
    "name": "Kotak FMP Series 244",
    "category": "Income",
    "navName": "Kotak FMP Series 244 - Regular Plan  -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-04",
    "closeData": "",
    "isin": "INF174KA1582",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "144924",
    "name": "Kotak FMP Series 244",
    "category": "Income",
    "navName": "Kotak FMP Series 244 -Direct Plan - Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-04",
    "closeData": "",
    "isin": "INF174KA1616",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144933",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 6- Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J49",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144934",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 6- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J64",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144935",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 6 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J72",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144936",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 6 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144937",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 7- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J80",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144938",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 7- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1K04",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144939",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 7- Direct Plan- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1K12",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "144940",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 7- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-17",
    "closeData": "",
    "isin": "INF204KB1J98",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144941",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KN2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144942",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KP7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144943",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KR3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144944",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KQ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144945",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KS1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144946",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1103 Days Plan C - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KO0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "144947",
    "name": "ICICI Prudential S&P BSE Liquid Rate ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential S&P BSE Liquid Rate ETF",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF109KC1KT9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144948",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAM2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144949",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAO8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144950",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAP5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144951",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAI0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144952",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAH2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144953",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAJ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144954",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAL4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144955",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAN0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144956",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAG4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "144957",
    "name": "UTI - Fixed Term Income Fund Series XXX-IV (1125 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IV (1125 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF789F1AAK6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144958",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01PY3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144959",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturit Plans- Series 4- Plan E (1098 days)- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01QB9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144960",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)- Dividend -Direct",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01QC7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144961",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan E 1098 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01QD5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144962",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan E 1098 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01QA1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "144963",
    "name": "Franklin India Fixed Maturity Plans- Series 4- Plan E (1098 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan E 1098 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "",
    "isin": "INF090I01PZ0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144976",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB10Q7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144977",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB11Q5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144978",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB13Q1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144979",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB12Q3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144980",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB14Q9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "144981",
    "name": "HDFC FMP 1381D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1381D September 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-14",
    "closeData": "2018-09-18",
    "isin": "INF179KB19P0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144986",
    "name": "Sundaram Emerging Small Cap Series VII",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VII Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF903JA1FG9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144987",
    "name": "Sundaram Emerging Small Cap Series VII",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VII Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF903JA1FF1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144988",
    "name": "Sundaram Emerging Small Cap Series VII",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap VII Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF903JA1FH7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "144989",
    "name": "Sundaram Emerging Small Cap Series VII",
    "category": "Growth",
    "navName": "Sundaram Emerging Small Cap Series VII Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF903JA1FI5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144990",
    "name": "SBI Debt Fund Series C - 25 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 25 - (1100 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-11",
    "closeData": "2018-09-17",
    "isin": "INF200KA1VR0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144991",
    "name": "SBI Debt Fund Series C - 25 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 25 (1100 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-11",
    "closeData": "2018-09-17",
    "isin": "INF200KA1VS8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144992",
    "name": "SBI Debt Fund Series C - 25 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 25 - (1100 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-11",
    "closeData": "2018-09-17",
    "isin": "INF200KA1VT6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "144993",
    "name": "SBI Debt Fund Series C - 25 (1100 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 25 (1100 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-11",
    "closeData": "2018-09-17",
    "isin": "INF200KA1VU4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145004",
    "name": "Kotak FMP Series 245",
    "category": "Income",
    "navName": "Kotak FMP Series 245-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF174KA1624",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145005",
    "name": "Kotak FMP Series 245",
    "category": "Income",
    "navName": "Kotak FMP Series 245 -Regular Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF174KA1632",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145006",
    "name": "Kotak FMP Series 245",
    "category": "Income",
    "navName": "Kotak FMP Series 245-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF174KA1640",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145007",
    "name": "Kotak FMP Series 245",
    "category": "Income",
    "navName": "Kotak FMP Series 245-Direct Plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-10",
    "closeData": "",
    "isin": "INF174KA1657",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145009",
    "name": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-14",
    "closeData": "",
    "isin": "INF789F1AAV3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145010",
    "name": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-V (1135 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-14",
    "closeData": "",
    "isin": "INF789F1AAY7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145014",
    "name": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-V (1135 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-14",
    "closeData": "",
    "isin": "INF789F1AAR1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145016",
    "name": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-V (1135 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-14",
    "closeData": "",
    "isin": "INF789F1AAQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145018",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KX1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145019",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KV5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145020",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KY9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145021",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KW3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145022",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KZ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145023",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1293 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-19",
    "closeData": "",
    "isin": "INF109KC1KU7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145024",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LA7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145025",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LB5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145026",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LC3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145027",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LF6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145028",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LD1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145029",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan E - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LE9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145030",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 8 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF204KB1K38",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145031",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 8- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF204KB1K46",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145032",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 8- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF204KB1K20",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145033",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 8 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF204KB1K53",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145034",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB15Q6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145035",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Direct Plan-Weekly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB18Q0",
    "isinRe": "INF179KB17Q2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145036",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Direct Plan-Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB10R5",
    "isinRe": "INF179KB19Q8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145037",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB16R2",
    "isinRe": "INF179KB15R4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145038",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Weekly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB14R7",
    "isinRe": "INF179KB13R9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145039",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Direct Plan-Daily IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB16Q4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145040",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB11R3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145041",
    "name": "HDFC Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HDFC Ultra Short Term Fund - Daily IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-18",
    "closeData": "2018-09-24",
    "isin": "INF179KB12R1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145042",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Growth",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FJ3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145043",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FM7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145056",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Monthly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FX4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145057",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Quarterly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FY2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145050",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Growth",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FR6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145051",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Monthly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FS4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145052",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Quarterly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FT2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145053",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Fortnightly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FW6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145054",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FU0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145055",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Direct Plan - Weekly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FV8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145044",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Monthly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FK1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145045",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Quarterly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FL9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145046",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Quarterly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FQ8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145047",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Weekly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FN5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145048",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Fortnightly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FO3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "145049",
    "name": "Sundaram Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Sundaram Money Market Fund Regular Plan - Monthly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "Rs.1000/-",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF903JA1FP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145075",
    "name": "ICICI Prudential Manufacturing Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Manufacturing Fund - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LJ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145076",
    "name": "ICICI Prudential Manufacturing Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Manufacturing Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LK6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145077",
    "name": "ICICI Prudential Manufacturing Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Manufacturing Fund - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LG4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145078",
    "name": "ICICI Prudential Manufacturing Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Manufacturing Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-21",
    "closeData": "",
    "isin": "INF109KC1LH2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145083",
    "name": "Kotak FMP Series 246",
    "category": "Income",
    "navName": "Kotak FMP Series 246 -Direct plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF174KA1699",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145084",
    "name": "Kotak FMP Series 246",
    "category": "Income",
    "navName": "Kotak FMP Series 246 -Regular Plan - Growth oPtion",
    "minAmount": "200000000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF174KA1665",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145085",
    "name": "Kotak FMP Series 246",
    "category": "Income",
    "navName": "Kotak FMP Series 246 - Regular Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF174KA1673",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145086",
    "name": "Kotak FMP Series 246",
    "category": "Income",
    "navName": "Kotak FMP Series 246 - Direct Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-12",
    "closeData": "",
    "isin": "INF174KA1681",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145103",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": "INF179KB18R8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145104",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": "INF179KB17R0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145105",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": "INF179KB19R6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145106",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": "INF179KB11S1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145107",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": "INF179KB10S3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145108",
    "name": "HDFC FMP 1372D September 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1372D September 2018-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "2018-09-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145109",
    "name": "Axis Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Axis Growth Opportunities Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2018-10-01",
    "closeData": "2018-10-15",
    "isin": "INF846K01J53",
    "isinRe": "INF846K01J61"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145110",
    "name": "Axis Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Axis Growth Opportunities Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2018-10-01",
    "closeData": "2018-10-15",
    "isin": "INF846K01J46",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145111",
    "name": "Axis Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Axis Growth Opportunities Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2018-10-01",
    "closeData": "2018-10-15",
    "isin": "INF846K01J87",
    "isinRe": "INF846K01J95"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145112",
    "name": "Axis Growth Opportunities Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Axis Growth Opportunities Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2018-10-01",
    "closeData": "2018-10-15",
    "isin": "INF846K01J79",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145119",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LM2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145120",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LO8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145121",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LR1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145122",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LP5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145123",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LN0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145124",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1286 Days Plan F - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF109KC1LQ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145125",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145126",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145127",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145128",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 92 Days Plan H - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145129",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": "INF109KC1LU5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145130",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": "INF109KC1LV3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145131",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": "INF109KC1LT7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145132",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 168 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-09-26",
    "closeData": "",
    "isin": "INF109KC1LS9",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "145137",
    "name": "Invesco India Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Invesco India Smallcap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF205K013T3",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "145138",
    "name": "Invesco India Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Invesco India Smallcap Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF205K012T5",
    "isinRe": "INF205K015T8"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "145139",
    "name": "Invesco India Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Invesco India Smallcap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF205K011T7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "145140",
    "name": "Invesco India Smallcap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Invesco India Smallcap Fund - Regular Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF205K010T9",
    "isinRe": "INF205K014T1"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145142",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 9 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-03",
    "closeData": "",
    "isin": "INF204KB1K95",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145143",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 9- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-03",
    "closeData": "",
    "isin": "INF204KB1K61",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145144",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 9",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 9 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-03",
    "closeData": "",
    "isin": "INF204KB1K79",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145141",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 9",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 9- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-03",
    "closeData": "",
    "isin": "INF204KB1K87",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145145",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABF4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145146",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABH0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145148",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABC1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145149",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABB3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145150",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABD9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145152",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABG2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145153",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABA5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145154",
    "name": "UTI - Fixed Term Income Fund Series XXX-VI (1107 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-VI (1107 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-09-28",
    "closeData": "",
    "isin": "INF789F1ABI8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145155",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 10- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-04",
    "closeData": "",
    "isin": "INF204KB1L11",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145156",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX Series 10- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-04",
    "closeData": "",
    "isin": "INF204KB1L29",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145157",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 10- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-04",
    "closeData": "",
    "isin": "INF204KB1L03",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145158",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 10- Direct Plan- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-04",
    "closeData": "",
    "isin": "INF204KB1L37",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145159",
    "name": "SBI Dual Advantage Fund - Series XXX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXX - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-27",
    "closeData": "2018-10-11",
    "isin": "INF200KA1VW0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145160",
    "name": "SBI Dual Advantage Fund - Series XXX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund - Series XXX - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-27",
    "closeData": "2018-10-11",
    "isin": "INF200KA1VY6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145161",
    "name": "SBI Dual Advantage Fund - Series XXX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund Series XXX - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-27",
    "closeData": "2018-10-11",
    "isin": "INF200KA1VX8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145162",
    "name": "SBI Dual Advantage Fund - Series XXX",
    "category": "Income",
    "navName": "SBI Dual Advantage Fund Series XXX - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-09-27",
    "closeData": "2018-10-11",
    "isin": "INF200KA1VV2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145167",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days) -Growth",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QE3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145168",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan F 1286 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QF0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145169",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan F 1286 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QI4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145170",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan F 1286 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QG8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145171",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 4 Plan F (1286 days)- Growth -Direct",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QH6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145172",
    "name": "Franklin India Fixed Maturity Plans- Series 4 Plan F (1286 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 4 - Plan F 1286 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF090I01QJ2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145177",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 11- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF204KB1L60",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145178",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 11 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF204KB1L78",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145179",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series 11- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF204KB1L45",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145180",
    "name": "Nippon India Fixed Horizon Fund XXXIX- Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 11 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF204KB1L52",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145181",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1MM0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145182",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1MN8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145183",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1MK4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145184",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1MI8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145185",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1ML2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145186",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1287 Days Plan I - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-05",
    "closeData": "",
    "isin": "INF109KC1MJ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145187",
    "name": "Kotak FMP Series 247",
    "category": "Income",
    "navName": "Kotak FMP Series 247 -Direct Plan -Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF174KA1723",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145188",
    "name": "Kotak FMP Series 247",
    "category": "Income",
    "navName": "Kotak FMP Series 247 -Regular Plan - Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF174KA1707",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145189",
    "name": "Kotak FMP Series 247",
    "category": "Income",
    "navName": "Kotak FMP Series 247 - Regular Plan -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF174KA1715",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145190",
    "name": "Kotak FMP Series 247",
    "category": "Income",
    "navName": "Kotak FMP Series 247 -Direct plan -Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2018-09-24",
    "closeData": "",
    "isin": "INF174KA1731",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145191",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145192",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145193",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145194",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan J - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145205",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "TATA SmallCap Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K017O8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145206",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Tata Small Cap Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K011O1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145207",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "TATA SmallCap Fund - Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K013O7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145208",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Tata Small Cap Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K015O2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145209",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "TATA Small Cap Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K012O9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145210",
    "name": "Tata Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "TATA Small Cap Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF277K016O0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145229",
    "name": "SBI Debt Fund Series C - 26(1125 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 26 - (1125 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-03",
    "closeData": "2018-10-04",
    "isin": "INF200KA1WB2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145230",
    "name": "SBI Debt Fund Series C - 26(1125 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 26 (1125 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-03",
    "closeData": "2018-10-04",
    "isin": "INF200KA1WC0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145231",
    "name": "SBI Debt Fund Series C - 26(1125 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 26 (1125 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-03",
    "closeData": "2018-10-04",
    "isin": "INF200KA1WA4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145232",
    "name": "SBI Debt Fund Series C - 26(1125 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 26 - (1125 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-03",
    "closeData": "2018-10-04",
    "isin": "INF200KA1VZ3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145239",
    "name": "Kotak FMP Series 248",
    "category": "Income",
    "navName": "Kotak FMP Series 248 - Direct Plan -Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-01",
    "closeData": "",
    "isin": "INF174KA1772",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145240",
    "name": "Kotak FMP Series 248",
    "category": "Income",
    "navName": "Kotak FMP Series 248 - Direct Plan -Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-01",
    "closeData": "",
    "isin": "INF174KA1764",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145241",
    "name": "Kotak FMP Series 248",
    "category": "Income",
    "navName": "Kotak FMP Series 248- Regular Plan - Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-01",
    "closeData": "",
    "isin": "INF174KA1756",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145242",
    "name": "Kotak FMP Series 248",
    "category": "Income",
    "navName": "Kotak FMP Series 248 -Regular Plan - Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-01",
    "closeData": "",
    "isin": "INF174KA1749",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145261",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 12- Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF204KB1L86",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145262",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 12- Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF204KB1L94",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145263",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 12- Direct Plan- Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF204KB1M02",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145264",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 12",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 12- Direct Plan - Dividend Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF204KB1M10",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145271",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018(1)-Direct Plan-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145272",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": "INF179KB13S7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145273",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145274",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": "INF179KB16S0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145275",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": "INF179KB18S6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145276",
    "name": "HDFC FMP 1280D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1280D October 2018(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "2018-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145277",
    "name": "kotak FMP SERIES 249",
    "category": "Income",
    "navName": "Kotak Fmp Series 249-Regular Plan -rowth Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF174KA1780",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145278",
    "name": "kotak FMP SERIES 249",
    "category": "Income",
    "navName": "Kotak Fmp Series 249-Regular Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF174KA1798",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145279",
    "name": "kotak FMP SERIES 249",
    "category": "Income",
    "navName": "Kotak Fmp Series 249-Direct Plan-Gwoth Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF174KA1806",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145280",
    "name": "kotak FMP SERIES 249",
    "category": "Income",
    "navName": "Kotak FMP Series 249-Direct Plan-Dividend Pyout option",
    "minAmount": "200000000",
    "launchDate": "2018-10-08",
    "closeData": "",
    "isin": "INF174KA1814",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145285",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB19S4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145286",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB12T7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145281",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB10T1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145282",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB11T9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145283",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB13T5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145284",
    "name": "HDFC FMP 1344D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1344D October 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-11",
    "closeData": "2018-10-23",
    "isin": "INF179KB14T3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145287",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI - Floater Fund - Regular Plan  - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACO4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145288",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACT3",
    "isinRe": "INF789F1ACU1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145295",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI - Floater Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACX5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145296",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ADC7",
    "isinRe": "INF789F1ADD5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145289",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACR7",
    "isinRe": "INF789F1ACS5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145290",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACY3",
    "isinRe": "INF789F1ACZ0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145291",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ADE3",
    "isinRe": "INF789F1ADF0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145292",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACV9",
    "isinRe": "INF789F1ACW7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145293",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ADA1",
    "isinRe": "INF789F1ADB9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145294",
    "name": "UTI - Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "UTI Floater Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-12",
    "closeData": "",
    "isin": "INF789F1ACP1",
    "isinRe": "INF789F1ACQ9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145301",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB18T4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145302",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB10U9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145297",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB16T8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145298",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB15T0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145299",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB17T6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145300",
    "name": "HDFC FMP 1274D OCTOBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1274D October 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-12",
    "closeData": "2018-10-16",
    "isin": "INF179KB19T2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145307",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MR9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145308",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MP3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145309",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MQ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145310",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MT5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145311",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MS7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145312",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1275 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF109KC1MO6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145313",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145314",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145315",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145316",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 155 Days Plan L - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145317",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QK0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145318",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 5 Plan A (1273 days)- Growth -Direct",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QN4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145319",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan A 1273 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QO2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145320",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan A 1273 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QP9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145321",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan A 1273 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QL8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145322",
    "name": "Franklin India Fixed Maturity Plans -Series 5 Plan A (1273 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan A 1273 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "",
    "isin": "INF090I01QM6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145325",
    "name": "UTI - Fixed Term Income Fund Series XXX-VIII (1286 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-VIII (1286 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF789F1ACE5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145329",
    "name": "UTI - Fixed Term Income Fund Series XXX-VIII (1286 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-VIII (1286 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-10",
    "closeData": "",
    "isin": "INF789F1ACJ4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145334",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABU3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145335",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABX7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145336",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABY5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145337",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABW9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145338",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABV1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145339",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ABZ2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145340",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ACA3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145341",
    "name": "UTI - Fixed Term Income Fund Series XXX-IX (1266 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-IX (1266 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-10-16",
    "closeData": "",
    "isin": "INF789F1ACC9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145343",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 13- Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF204KB1M69",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145344",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 13- Dividend Option",
    "minAmount": "Rs.5000 & in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF204KB1M77",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145345",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 13- Direct Plan- Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF204KB1M85",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145346",
    "name": "Reliance Fixed Horizon Fund XXXIX- Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXIX- Series 13- Direct Plan- Dividend Option",
    "minAmount": "Rs.5000 & in multiples of Re. 1 thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF204KB1M93",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145347",
    "name": "Nippon India Interval Fund V- Series 1",
    "category": "Income",
    "navName": "Nippon India Interval Fund V- Series 1- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF204KB1M28",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145348",
    "name": "Nippon India Interval Fund V- Series 1",
    "category": "Income",
    "navName": "Nippon India Interval Fund V- Series 1- Direct Plan- Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF204KB1M51",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145349",
    "name": "Nippon India Interval Fund V- Series 1",
    "category": "Income",
    "navName": "Nippon India Interval Fund V- Series 1- Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF204KB1M36",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145350",
    "name": "Nippon India Interval Fund V- Series 1",
    "category": "Income",
    "navName": "Nippon India Interval Fund V- Series 1- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF204KB1M44",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "145355",
    "name": "Mahindra Manulife Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mahindra Manulife Consumption Fund- Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF174V01630",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "145356",
    "name": "Mahindra Manulife Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mahindra Manulife Consumption Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF174V01663",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "145357",
    "name": "Mahindra Manulife Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mahindra Manulife Consumption Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF174V01689",
    "isinRe": "INF174V01671"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "145358",
    "name": "Mahindra Manulife Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mahindra Manulife Consumption Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "",
    "isin": "INF174V01655",
    "isinRe": "INF174V01648"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145371",
    "name": "SBI Debt Fund Series C - 27 (391 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 27 - (391 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "2018-10-19",
    "isin": "INF200KA19F7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145372",
    "name": "SBI Debt Fund Series C - 27 (391 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 27 - (391 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "2018-10-19",
    "isin": "INF200KA11G2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145373",
    "name": "SBI Debt Fund Series C - 27 (391 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 27 (391 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "2018-10-19",
    "isin": "INF200KA12G0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145374",
    "name": "SBI Debt Fund Series C - 27 (391 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 27 (391 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-10-19",
    "closeData": "2018-10-19",
    "isin": "INF200KA10G4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145379",
    "name": "Kotak FMP Series 250",
    "category": "Income",
    "navName": "Kotak FMP Series 250-Regular plan-growth option",
    "minAmount": "200000000",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF174KA1822",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145380",
    "name": "Kotak FMP Series 250",
    "category": "Income",
    "navName": "Kotak FMP Series 250-regular plan-Payout of Income Distribution cum capital withdrawal Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF174KA1830",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145381",
    "name": "Kotak FMP Series 250",
    "category": "Income",
    "navName": "Kotak FMP Series 250-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF174KA1848",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145382",
    "name": "Kotak FMP Series 250",
    "category": "Income",
    "navName": "Kotak FMP Series 250-Direct Plan-Dividend option",
    "minAmount": "200000000",
    "launchDate": "2018-10-17",
    "closeData": "",
    "isin": "INF174KA1855",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "145387",
    "name": "Baroda BNP Paribas Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Baroda BNP Paribas Balanced Advantage Fund-Regular Plan -Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-11-05",
    "isin": "INF955L01HC4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "145389",
    "name": "Baroda BNP Paribas Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Baroda BNP Paribas Balanced Advantage Fund-Regular Plan-IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-11-05",
    "isin": "INF955L01HD2",
    "isinRe": "INF955L01HE0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "145396",
    "name": "Baroda BNP Paribas Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Baroda BNP Paribas Balanced Advantage Fund-Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-11-05",
    "isin": "INF955L01HF7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "145397",
    "name": "Baroda BNP Paribas Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Baroda BNP Paribas Balanced Advantage Fund-Direct Plan-IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-11-05",
    "isin": "INF955L01HG5",
    "isinRe": "INF955L01HH3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145392",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018(1)-Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145393",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145394",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": "INF179KB17U4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145395",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": "INF179KB10V7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145388",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": "INF179KB18U2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145391",
    "name": "HDFC FMP 1261D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1261D October 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-22",
    "closeData": "2018-10-24",
    "isin": "INF179KB19U0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145405",
    "name": "Nippon India Fixed Horizon Fund XXXIX - Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 14 - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-10-25",
    "closeData": "",
    "isin": "INF204KB1N19",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145406",
    "name": "Nippon India Fixed Horizon Fund XXXIX - Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 14 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-10-25",
    "closeData": "",
    "isin": "INF204KB1N35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145407",
    "name": "Nippon India Fixed Horizon Fund XXXIX - Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX - Series 14 - Direct Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-10-25",
    "closeData": "",
    "isin": "INF204KB1N27",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145408",
    "name": "Nippon India Fixed Horizon Fund XXXIX - Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX -  Series 14 -Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-10-25",
    "closeData": "",
    "isin": "INF204KB1N01",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132178",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun life Financial Planning Fund - Conservative Plan - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01044",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132179",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01051",
    "isinRe": "INF084M01069"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132183",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01EQ8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132184",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Conservative Plan -Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01ER6",
    "isinRe": "INF084M01ES4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132174",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan - Regular Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01077",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132175",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund -Moderate  Plan -  Regular -  IDCW",
    "minAmount": "500",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01085",
    "isinRe": "INF084M01093"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132185",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan - Direct Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01ET2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132189",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Moderate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund -Moderate  Plan - Direct - IDCW",
    "minAmount": "500",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01EU0",
    "isinRe": "INF084M01EV8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145415",
    "name": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-29",
    "closeData": "",
    "isin": "INF789F1ADL8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145419",
    "name": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-29",
    "closeData": "",
    "isin": "INF789F1ADG8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145422",
    "name": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-X (1267 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-29",
    "closeData": "",
    "isin": "INF789F1ADM6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145423",
    "name": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-X (1267 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-29",
    "closeData": "",
    "isin": "INF789F1ADI4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145424",
    "name": "UTI - Fixed Term Income Fund Series XXX-X (1267 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-X (1267 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-10-29",
    "closeData": "",
    "isin": "INF789F1ADH6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129065",
    "name": "HSBC Managed Solutions - Growth",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Growth - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IA9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129199",
    "name": "HSBC Managed Solutions - Growth",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Growth - Dividend",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01HY1",
    "isinRe": "INF336L01HZ8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129200",
    "name": "HSBC Managed Solutions - Growth",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Growth - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01ID3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129201",
    "name": "HSBC Managed Solutions - Growth",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Growth - Dividend Direct",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IB7",
    "isinRe": "INF336L01IC5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129191",
    "name": "HSBC Managed Solutions - Moderate",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Moderate - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IG6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129192",
    "name": "HSBC Managed Solutions - Moderate",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions India Moderate Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IE1",
    "isinRe": "INF336L01IF8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129193",
    "name": "HSBC Managed Solutions - Moderate",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Moderate - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IJ0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129194",
    "name": "HSBC Managed Solutions - Moderate",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions India Moderate Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IH4",
    "isinRe": "INF336L01II2"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101198",
    "name": "LIC MF S & P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF S & P BSE Sensex Index Fund-Regular Plan-IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01121",
    "isinRe": "INF767K01113"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101199",
    "name": "LIC MF S & P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF S & P BSE Sensex Index Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01139",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120308",
    "name": "LIC MF S & P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF S & P BSE Sensex Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01FJ4",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120312",
    "name": "LIC MF S & P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF S & P BSE Sensex Index Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01FI6",
    "isinRe": "INF767K01FK2"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101200",
    "name": "LIC MF Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF Nifty 50 Index Fund-Regular Plan-IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01154",
    "isinRe": "INF767K01147"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "101201",
    "name": "LIC MF Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF Nifty 50 Index Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01162",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120307",
    "name": "LIC MF Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF Nifty 50 Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01FF2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "120309",
    "name": "LIC MF Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "LIC MF Nifty 50 Index Fund-Direct Plan-IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2002-11-14",
    "closeData": "2002-11-25",
    "isin": "INF767K01FG0",
    "isinRe": "INF767K01FH8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129195",
    "name": "HSBC Managed Solutions - Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Conservative - Growth",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IM4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129196",
    "name": "HSBC Managed Solutions - Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions India Conservative Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IK8",
    "isinRe": "INF336L01IL6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129197",
    "name": "HSBC Managed Solutions - Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions - Conservative - Growth Direct",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IP7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "129198",
    "name": "HSBC Managed Solutions - Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HSBC Managed Solutions India Conservative Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2014-04-09",
    "closeData": "",
    "isin": "INF336L01IN2",
    "isinRe": "INF336L01IO0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132180",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01101",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132181",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan -Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01119",
    "isinRe": "INF084M01127"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132186",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01EW6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "132187",
    "name": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Financial Planning Fund - Aggressive Plan - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2011-04-19",
    "closeData": "2011-05-03",
    "isin": "INF084M01EX4",
    "isinRe": "INF084M01EY2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145425",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB12U5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145426",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB11U7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145427",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB13U3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145428",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB15U8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145429",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB14U1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145430",
    "name": "HDFC FMP 1265D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1265D October 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-10-25",
    "closeData": "2018-10-25",
    "isin": "INF179KB16U6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145435",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NN6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145436",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NI6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145431",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NL0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145432",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145433",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NM8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145434",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan M - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-02",
    "closeData": "",
    "isin": "INF109KC1NK2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145437",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series 12-Regular Plan-Growth",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF174KA1889",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145438",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series 12-Regular Plan-Dividend Reinvestment",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF147KA1871",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145439",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "kotak Quarterly Interval Plan Series 12-Direct Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF174KA1905",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145440",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series 12-Direct Plan-Growth",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF174KA1913",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145441",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series 12-Regular Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF174KA1863",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145442",
    "name": "Kotak Quarterly Interval Plan Sr. 12",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series 12-Direct Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2018-10-26",
    "closeData": "",
    "isin": "INF174KA1897",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145443",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 5 - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-07",
    "closeData": "",
    "isin": "INF109KC1NQ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145444",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 5 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-07",
    "closeData": "",
    "isin": "INF109KC1NR7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145445",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 5 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-07",
    "closeData": "",
    "isin": "INF109KC1NP1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145446",
    "name": "ICICI Prudential Bharat Consumption Fund - Series 5",
    "category": "Growth",
    "navName": "ICICI Prudential Bharat Consumption Fund - Series 5 - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-07",
    "closeData": "",
    "isin": "INF109KC1NO4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "145453",
    "name": "DSP Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Healthcare Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-26",
    "isin": "INF740KA1LE6",
    "isinRe": "INF740KA1LF3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "145454",
    "name": "DSP Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Healthcare Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-26",
    "isin": "INF740KA1LG1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "145455",
    "name": "DSP Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Healthcare Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-26",
    "isin": "INF740KA1LH9",
    "isinRe": "INF740KA1LI7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "145456",
    "name": "DSP Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Healthcare Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-26",
    "isin": "INF740KA1LD8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101530",
    "name": "SBI LARGE & MIDCAP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "SBI Large & Midcap Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01289",
    "isinRe": "INF200K01297"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103024",
    "name": "SBI LARGE & MIDCAP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "SBI LARGE & MIDCAP FUND- REGULAR PLAN -Growth",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01305",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119720",
    "name": "SBI LARGE & MIDCAP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "SBI Large & Midcap Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01UH9",
    "isinRe": "INF200K01UI7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119721",
    "name": "SBI LARGE & MIDCAP FUND",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "SBI LARGE & MIDCAP FUND -DIRECT PLAN -Growth",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01UJ5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103883",
    "name": "SBI LONG TERM EQUITY FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "SBI Long Term Equity Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "500",
    "launchDate": "1993-02-24",
    "closeData": "",
    "isin": "INF200K01479",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105628",
    "name": "SBI LONG TERM EQUITY FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "SBI LONG TERM EQUITY FUND - REGULAR PLAN- GROWTH",
    "minAmount": "500",
    "launchDate": "1993-02-24",
    "closeData": "",
    "isin": "INF200K01495",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119722",
    "name": "SBI LONG TERM EQUITY FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "SBI Long Term Equity Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "500",
    "launchDate": "1993-02-24",
    "closeData": "",
    "isin": "INF200K01UK3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119723",
    "name": "SBI LONG TERM EQUITY FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "SBI LONG TERM EQUITY FUND - DIRECT PLAN -GROWTH",
    "minAmount": "500",
    "launchDate": "1993-02-24",
    "closeData": "",
    "isin": "INF200K01UM9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103034",
    "name": "SBI MAGNUM GLOBAL FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI MAGNUM GLOBAL FUND - REGULAR PLAN -GROWTH",
    "minAmount": "2000",
    "launchDate": "2005-06-06",
    "closeData": "",
    "isin": "INF200K01271",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103114",
    "name": "SBI MAGNUM GLOBAL FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Global Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2005-06-06",
    "closeData": "",
    "isin": "INF200K01255",
    "isinRe": "INF200K01263"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119710",
    "name": "SBI MAGNUM GLOBAL FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Global Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2005-06-06",
    "closeData": "",
    "isin": "INF200K01SL5",
    "isinRe": "INF200K01SM3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119711",
    "name": "SBI MAGNUM GLOBAL FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI MAGNUM GLOBAL FUND - DIRECT PLAN -GROWTH",
    "minAmount": "2000",
    "launchDate": "2005-06-06",
    "closeData": "",
    "isin": "INF200K01SN1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101551",
    "name": "SBI EQUITY HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "SBI Equity Hybrid Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-01-01",
    "closeData": "2005-01-01",
    "isin": "INF200K01115",
    "isinRe": "INF200K01123"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102885",
    "name": "SBI EQUITY HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "SBI EQUITY HYBRID FUND - REGULAR PLAN -Growth",
    "minAmount": "5000",
    "launchDate": "2005-01-01",
    "closeData": "2005-01-01",
    "isin": "INF200K01107",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119604",
    "name": "SBI EQUITY HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "SBI Equity Hybrid Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-01-01",
    "closeData": "2005-01-01",
    "isin": "INF200K01RW4",
    "isinRe": "INF200K01RX2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119609",
    "name": "SBI EQUITY HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "SBI EQUITY HYBRID FUND - DIRECT PLAN - Growth",
    "minAmount": "5000",
    "launchDate": "2005-01-01",
    "closeData": "2005-01-01",
    "isin": "INF200K01RY0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100644",
    "name": "SBI HEALTHCARE OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Healthcare Opportunities Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01453",
    "isinRe": "INF200K01461"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102823",
    "name": "SBI HEALTHCARE OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI HEALTHCARE OPPORTUNITIES FUND - REGULAR PLAN -GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01446",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119782",
    "name": "SBI HEALTHCARE OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Healthcare Opportunities Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01UN7",
    "isinRe": "INF200K01UO5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119783",
    "name": "SBI HEALTHCARE OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI HEALTHCARE OPPORTUNITIES FUND - DIRECT PLAN -GROWTH",
    "minAmount": "5000",
    "launchDate": "1993-01-01",
    "closeData": "1993-01-01",
    "isin": "INF200K01UP2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100915",
    "name": "SBI CONTRA FUND",
    "category": "Equity Scheme - Contra Fund",
    "navName": "SBI Contra Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2005-05-06",
    "closeData": "2005-05-06",
    "isin": "INF200K01347",
    "isinRe": "INF200K01354"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102414",
    "name": "SBI CONTRA FUND",
    "category": "Equity Scheme - Contra Fund",
    "navName": "SBI CONTRA FUND - REGULAR PLAN -GROWTH",
    "minAmount": "2000",
    "launchDate": "2005-05-06",
    "closeData": "2005-05-06",
    "isin": "INF200K01362",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119724",
    "name": "SBI CONTRA FUND",
    "category": "Equity Scheme - Contra Fund",
    "navName": "SBI Contra Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2005-05-06",
    "closeData": "2005-05-06",
    "isin": "INF200K01QY2",
    "isinRe": "INF200K01QZ9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119835",
    "name": "SBI CONTRA FUND",
    "category": "Equity Scheme - Contra Fund",
    "navName": "SBI CONTRA FUND - DIRECT PLAN - GROWTH",
    "minAmount": "2000",
    "launchDate": "2005-05-06",
    "closeData": "2005-05-06",
    "isin": "INF200K01RA0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102272",
    "name": "SBI NIFTY INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI NIFTY INDEX FUND - REGULAR PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2001-12-18",
    "closeData": "2001-12-18",
    "isin": "INF200K01537",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102273",
    "name": "SBI NIFTY INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Index Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2001-12-18",
    "closeData": "2001-12-18",
    "isin": "INF200K01545",
    "isinRe": "INF200K01552"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119826",
    "name": "SBI NIFTY INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Index Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2001-12-18",
    "closeData": "2001-12-18",
    "isin": "INF200K01TC2",
    "isinRe": "INF200K01TD0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119827",
    "name": "SBI NIFTY INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI NIFTY INDEX FUND - DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2001-12-18",
    "closeData": "2001-12-18",
    "isin": "INF200K01TE8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101206",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI OVERNIGHT FUND - REGULAR PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01LQ9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103140",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI Overnight Fund - Regular Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01LO4",
    "isinRe": "INF200K01LP1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "114297",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI Overnight Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01LN6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119831",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI Overnight Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01TJ7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119832",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI Overnight Fund - Direct Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01TL3",
    "isinRe": "INF200K01TM1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119833",
    "name": "SBI OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "SBI OVERNIGHT FUND - DIRECT PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-07-25",
    "closeData": "",
    "isin": "INF200K01TK5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102053",
    "name": "SBI MAGNUM MEDIUM DURATION FUND",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "SBI MAGNUM MEDIUM DURATION FUND - REGULAR PLAN - GROWTH",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01719",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102054",
    "name": "SBI MAGNUM MEDIUM DURATION FUND",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "SBI Magnum Medium Duration Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01727",
    "isinRe": "INF200K01735"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119824",
    "name": "SBI MAGNUM MEDIUM DURATION FUND",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "SBI MAGNUM MEDIUM DURATION FUND - DIRECT PLAN - GROWTH",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01VB0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119825",
    "name": "SBI MAGNUM MEDIUM DURATION FUND",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "SBI Magnum Medium Duration Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "25000",
    "launchDate": "2003-10-01",
    "closeData": "2003-10-01",
    "isin": "INF200K01UZ1",
    "isinRe": "INF200K01VA2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105274",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Institutional - Growth",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LU1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105275",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Institutional Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LR7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105278",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Institutional Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LV9",
    "isinRe": "INF200K01LW7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105279",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Institutional Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LS5",
    "isinRe": "INF200K01LT3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105280",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - REGULAR PLAN -Growth",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01MA1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105281",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LX5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105282",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Regular Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01MB9",
    "isinRe": "INF200K01MC7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105283",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Regular Plan - Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01LY3",
    "isinRe": "INF200K01LZ0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119799",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Direct Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01UU2",
    "isinRe": "INF200K01UV0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119800",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - DIRECT PLAN -Growth",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01UT4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119804",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Direct Paln - Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01UR8",
    "isinRe": "INF200K01US6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119805",
    "name": "SBI LIQUID FUND",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "SBI Liquid Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "50000000",
    "launchDate": "2007-03-22",
    "closeData": "",
    "isin": "INF200K01UQ0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102205",
    "name": "SBI DYNAMIC BOND FUND",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "SBI Dynamic Bond Fund - REGULAR PLAN - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-15",
    "closeData": "",
    "isin": "INF200K01958",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102206",
    "name": "SBI DYNAMIC BOND FUND",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "SBI Dynamic Bond Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2003-12-15",
    "closeData": "",
    "isin": "INF200K01966",
    "isinRe": "INF200K01974"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119670",
    "name": "SBI DYNAMIC BOND FUND",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "SBI Dynamic Bond Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2003-12-15",
    "closeData": "",
    "isin": "INF200K01RB8",
    "isinRe": "INF200K01RC6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119671",
    "name": "SBI DYNAMIC BOND FUND",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "SBI Dynamic Bond Fund - DIRECT PLAN - Growth",
    "minAmount": "5000",
    "launchDate": "2003-12-15",
    "closeData": "",
    "isin": "INF200K01RD4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102505",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI CREDIT RISK FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01685",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102506",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI Credit Risk Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01693",
    "isinRe": "INF200K01701"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "118211",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI Credit Risk Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01QK1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119792",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI Credit Risk Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01SS0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119793",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI Credit Risk Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01ST8",
    "isinRe": "INF200K01SU6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119798",
    "name": "SBI CREDIT RISK FUND",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "SBI CREDIT RISK FUND - DIRECT PLAN -GROWTH",
    "minAmount": "Rs. 10000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01SV4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102756",
    "name": "SBI FOCUSED EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI FOCUSED EQUITY FUND - REGULAR PLAN -GROWTH",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": "INF200K01370",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102765",
    "name": "SBI FOCUSED EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI Focused Equity Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": "INF200K01388",
    "isinRe": "INF200K01396"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119727",
    "name": "SBI FOCUSED EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI FOCUSED EQUITY FUND - DIRECT PLAN -GROWTH",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": "INF200K01RJ1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119834",
    "name": "SBI FOCUSED EQUITY FUND",
    "category": "Equity Scheme - Focused Fund",
    "navName": "SBI Focused Equity Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-08-23",
    "closeData": "2004-08-23",
    "isin": "INF200K01RH5",
    "isinRe": "INF200K01RI3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100927",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01867",
    "isinRe": "INF200K01875"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100928",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01883",
    "isinRe": "INF200K01891"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100929",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Regular Plan - Annual Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01909",
    "isinRe": "INF200K01917"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100968",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Regular Plan - Growth",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01859",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119836",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund  - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01TV2",
    "isinRe": "INF200K01TW0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119837",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Direct Plan - Annual Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01TQ2",
    "isinRe": "INF200K01TR0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119838",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01TT6",
    "isinRe": "INF200K01TU4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119839",
    "name": "SBI CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "SBI Conservative Hybrid Fund - Direct Plan - Growth",
    "minAmount": "10000",
    "launchDate": "2001-02-22",
    "closeData": "",
    "isin": "INF200K01TS8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100641",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI MAGNUM ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01LJ4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103884",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI Magnum Ultra Short Duration Fund - Regular Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01LM8",
    "isinRe": "INF200K01LL0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "105080",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI Magnum Ultra Short Duration Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01LK2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119784",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI Magnum Ultra Short Duration Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01TG3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119828",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI MAGNUM ULTRA SHORT DURATION FUND - DIRECT PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01TF5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119829",
    "name": "SBI MAGNUM ULTRA SHORT DURATION FUND",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "SBI Magnum Ultra Short Duration Fund - Direct Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-03-06",
    "closeData": "2007-03-14",
    "isin": "INF200K01TH1",
    "isinRe": "INF200K01TI9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102941",
    "name": "SBI MAGNUM MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "SBI Magnum MIDCAP FUND - REGULAR PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2005-02-21",
    "closeData": "2005-02-21",
    "isin": "INF200K01560",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102942",
    "name": "SBI MAGNUM MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "SBI Magnum MidCap Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-02-21",
    "closeData": "2005-02-21",
    "isin": "INF200K01578",
    "isinRe": "INF200K01586"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119715",
    "name": "SBI MAGNUM MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "SBI Magnum MidCap Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-02-21",
    "closeData": "2005-02-21",
    "isin": "INF200K01TN9",
    "isinRe": "INF200K01TO7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119716",
    "name": "SBI MAGNUM MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "SBI Magnum MIDCAP FUND - DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2005-02-21",
    "closeData": "2005-02-21",
    "isin": "INF200K01TP4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101002",
    "name": "SBI MAGNUM CONSTANT MATURITY FUND",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "SBI MAGNUM CONSTANT MATURITY FUND - REGULAR PLAN - GROWTH",
    "minAmount": "1000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AN9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101003",
    "name": "SBI MAGNUM CONSTANT MATURITY FUND",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "SBI Magnum Constant Maturity Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "1000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AO7",
    "isinRe": "INF200K01AP4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120136",
    "name": "SBI MAGNUM CONSTANT MATURITY FUND",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "SBI Magnum Constant Maturity Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "1000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01SI1",
    "isinRe": "INF200K01SJ9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "120137",
    "name": "SBI MAGNUM CONSTANT MATURITY FUND",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "SBI MAGNUM CONSTANT MATURITY FUND - DIRECT PLAN - GROWTH",
    "minAmount": "1000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01SK7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103145",
    "name": "SBI MAGNUM COMMA FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum COMMA Fund - REGULAR PLAN - Growth",
    "minAmount": "5000",
    "launchDate": "2005-06-30",
    "closeData": "",
    "isin": "INF200K01339",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103146",
    "name": "SBI MAGNUM COMMA FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Comma Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-06-30",
    "closeData": "",
    "isin": "INF200K01313",
    "isinRe": "INF200K01321"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119613",
    "name": "SBI MAGNUM COMMA FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum Comma Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-06-30",
    "closeData": "",
    "isin": "INF200K01RZ7",
    "isinRe": "INF200K01SA8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119705",
    "name": "SBI MAGNUM COMMA FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Magnum COMMA Fund - DIRECT PLAN - Growth",
    "minAmount": "5000",
    "launchDate": "2005-06-30",
    "closeData": "",
    "isin": "INF200K01SB6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "100999",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01990",
    "isinRe": "INF200K01AA6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101001",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND - REGULAR PLAN - GROWTH",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01982",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101932",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND -  GROWTH - PF (Regular) Option",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AK5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101933",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND -  GROWTH - PF (Fixed Period - 2 Yrs) Option",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AE8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101934",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND -  GROWTH - PF (Fixed Period - 3 Yrs) Option",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AH1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101935",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Income Distribution cum Capital Withdrawal Option (IDCW) - PF (Regular)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AL3",
    "isinRe": "INF200K01AM1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101950",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Income Distribution cum Capital Withdrawal Option (IDCW) - PF (Fixed Period - 1 Yr)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AC2",
    "isinRe": "INF200K01AD0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101951",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Income Distribution cum Capital Withdrawal Option (IDCW) - PF (Fixed Period - 2 Yrs)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AF5",
    "isinRe": "INF200K01AG3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "101952",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Income Distribution cum Capital Withdrawal Option (IDCW) - PF (Fixed Period - 3 Yrs)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AI9",
    "isinRe": "INF200K01AJ7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102061",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND -  GROWTH - PF (Fixed Period - 1 Yr) Option",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01AB4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119706",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI Magnum Gilt Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01SF7",
    "isinRe": "INF200K01SG5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119707",
    "name": "SBI MAGNUM GILT FUND",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "SBI MAGNUM GILT FUND - DIRECT PLAN - GROWTH",
    "minAmount": "100000",
    "launchDate": "2000-12-11",
    "closeData": "2000-12-11",
    "isin": "INF200K01SH3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103215",
    "name": "SBI FLEXICAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "SBI Flexicap Fund - REGULAR PLAN -Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1.",
    "launchDate": "2005-08-22",
    "closeData": "2005-08-22",
    "isin": "INF200K01222",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103216",
    "name": "SBI FLEXICAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "SBI Flexicap Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1.",
    "launchDate": "2005-08-22",
    "closeData": "2005-08-22",
    "isin": "INF200K01230",
    "isinRe": "INF200K01248"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119717",
    "name": "SBI FLEXICAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "SBI Flexicap Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1.",
    "launchDate": "2005-08-22",
    "closeData": "2005-08-22",
    "isin": "INF200K01UE6",
    "isinRe": "INF200K01UF3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119718",
    "name": "SBI FLEXICAP FUND",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "SBI Flexicap Fund - DIRECT PLAN - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1.",
    "launchDate": "2005-08-22",
    "closeData": "2005-08-22",
    "isin": "INF200K01UG1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103400",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01818",
    "isinRe": "INF200K01826"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103401",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01834",
    "isinRe": "INF200K01842"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103408",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01800",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103409",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Regular Plan - Annual Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01784",
    "isinRe": "INF200K01792"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119840",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Direct Plan - Annual Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01TX8",
    "isinRe": "INF200K01TY6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119841",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01UA4",
    "isinRe": "INF200K01UB2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119842",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI Multi Asset Allocation Fund - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01UC0",
    "isinRe": "INF200K01UD8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119843",
    "name": "SBI MULTI ASSET ALLOCATION FUND",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "SBI MULTI ASSET ALLOCATION FUND - DIRECT PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2005-11-09",
    "closeData": "",
    "isin": "INF200K01TZ3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103504",
    "name": "SBI BLUE CHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "SBI BLUE CHIP FUND-REGULAR PLAN GROWTH",
    "minAmount": "5000",
    "launchDate": "2005-12-23",
    "closeData": "",
    "isin": "INF200K01180",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "103616",
    "name": "SBI BLUE CHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "SBI Blue Chip Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-12-23",
    "closeData": "",
    "isin": "INF200K01164",
    "isinRe": "INF200K01172"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119585",
    "name": "SBI BLUE CHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "SBI Blue Chip Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2005-12-23",
    "closeData": "",
    "isin": "INF200K01QV8",
    "isinRe": "INF200K01QW6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119598",
    "name": "SBI BLUE CHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "SBI BLUE CHIP FUND-DIRECT PLAN -GROWTH",
    "minAmount": "5000",
    "launchDate": "2005-12-23",
    "closeData": "",
    "isin": "INF200K01QX4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104457",
    "name": "SBI ARBITRAGE OPPORTUNITIES FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "SBI Arbitrage Opportunities Fund - Regular Plan - Gr",
    "minAmount": "25000",
    "launchDate": "2006-09-15",
    "closeData": "",
    "isin": "INF200K01156",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "104458",
    "name": "SBI ARBITRAGE OPPORTUNITIES FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "SBI Arbitrage Opportunities Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "25000",
    "launchDate": "2006-09-15",
    "closeData": "",
    "isin": "INF200K01131",
    "isinRe": "INF200K01149"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119567",
    "name": "SBI ARBITRAGE OPPORTUNITIES FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "SBI Arbitrage Opportunities Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "25000",
    "launchDate": "2006-09-15",
    "closeData": "",
    "isin": "INF200K01QS4",
    "isinRe": "INF200K01QT2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119574",
    "name": "SBI ARBITRAGE OPPORTUNITIES FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "SBI Arbitrage Opportunities Fund - Direct Plan - Gr",
    "minAmount": "25000",
    "launchDate": "2006-09-15",
    "closeData": "",
    "isin": "INF200K01QU0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106095",
    "name": "SBI INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Infrastructure Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-05-11",
    "closeData": "",
    "isin": "INF200K01CU0",
    "isinRe": "INF200K01CV8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106096",
    "name": "SBI INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI INFRASTRUCTURE FUND -  REGULAR PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2007-05-11",
    "closeData": "",
    "isin": "INF200K01CT2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119695",
    "name": "SBI INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Infrastructure Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "5000",
    "launchDate": "2007-05-11",
    "closeData": "",
    "isin": "INF200K01RQ6",
    "isinRe": "INF200K01RR4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119700",
    "name": "SBI INFRASTRUCTURE FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI INFRASTRUCTURE FUND -  DIRECT PLAN - GROWTH",
    "minAmount": "5000",
    "launchDate": "2007-05-11",
    "closeData": "",
    "isin": "INF200K01RS2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106212",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI MAGNUM LOW DURATION FUND - REGULAR PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MO2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106213",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Regular Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01ML8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106214",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Regular Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MR5",
    "isinRe": "INF200K01MS3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106215",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Regular Plan - Fornightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MM6",
    "isinRe": "INF200K01MN4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106216",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MP9",
    "isinRe": "INF200K01MQ7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106217",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Ultra Short Term Fund - Institutional Plan - Growth",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MG8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119812",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI MAGNUM LOW DURATION FUND - DIRECT PLAN - GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01VM7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119818",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01VN5",
    "isinRe": "INF200K01VO3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119819",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Direct Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01VP0",
    "isinRe": "INF200K01VQ8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106221",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Ultra Short Term Fund - Institutional Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MD5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106222",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Ultra Short Term Fund- Institutional Plan - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MJ2",
    "isinRe": "INF200K01MK0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106223",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Short Horizon Debt Fund -Ultra Short Term Fund - Institutional Plan - Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01ME3",
    "isinRe": "INF200K01MF0"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106224",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Ultra Short Term Fund - Institutional Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01MH6",
    "isinRe": "INF200K01MI4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119810",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Direct Plan - Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01VJ3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119811",
    "name": "SBI MAGNUM LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "SBI Magnum Low Duration Fund - Direct Plan - Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "",
    "isin": "INF200K01VK1",
    "isinRe": "INF200K01VL9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106204",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN RETAIL GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106205",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN RETAIL DIVIDEND WEEKLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106206",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN RETAIL DIVIDEND FORTNIGHTLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106207",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN RETAIL DIVIDEND MONTHLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106210",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON FUND-SHORT TERM PLAN INSTITUTIONAL  DIVIDEND FORTNIGHTLY",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106227",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT HORIZON DEBT FUND-SHORT TERM FUND - RETAIL - GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01IG6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106234",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Regular Plan Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01HT1",
    "isinRe": "INF200K01HU9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119813",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01VF1",
    "isinRe": "INF200K01VG9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119815",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Direct Plan Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01VC8",
    "isinRe": "INF200K01VD6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119816",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT TERM DEBT FUND - DIRECT PLAN -GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01VE4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119817",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Direct Plan Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01VH7",
    "isinRe": "INF200K01VI5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106228",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Short Term Fund - Retail Dividend - Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01IE1",
    "isinRe": "INF200K01IF8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106229",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Short Term Fund - Retail Dividend - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01IC5",
    "isinRe": "INF200K01ID3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106230",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Horizon Debt Fund - Short Term Fund - Retail Dividend - Fortnightly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01IA9",
    "isinRe": "INF200K01IB7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106231",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI SHORT TERM DEBT FUND - REGULAR PLAN -GROWTH",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01HZ8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106232",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Regular Plan Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01HX3",
    "isinRe": "INF200K01HY1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "106233",
    "name": "SBI SHORT TERM DEBT FUND",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "SBI Short Term Debt Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "10000",
    "launchDate": "2007-07-19",
    "closeData": "2007-07-26",
    "isin": "INF200K01HV7",
    "isinRe": "INF200K01HW5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102503",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI  SAVINGS FUND - REGULAR PLAN - GROWTH",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01636",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102504",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01644",
    "isinRe": "INF200K01651"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "102860",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Regular Plan Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01669",
    "isinRe": "INF200K01677"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "114348",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Regular Plan Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200KA1K20",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119820",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01SX0",
    "isinRe": "INF200K01SY8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119821",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI  SAVINGS FUND - DIRECT PLAN - GROWTH",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01SZ5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119822",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Direct Plan Daily Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01SW2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "119823",
    "name": "SBI SAVINGS FUND",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "SBI Savings Fund - Direct Plan Weekly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "2000",
    "launchDate": "2004-07-05",
    "closeData": "",
    "isin": "INF200K01TA6",
    "isinRe": "INF200K01TB4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145457",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018(1)-Direct Plan-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145458",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": "INF179KB15V6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145459",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145460",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018(1)-Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145461",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": "INF179KB13V1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145462",
    "name": "HDFC FMP 1260D October 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1260D October 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-10-30",
    "closeData": "2018-10-30",
    "isin": "INF179KB16V4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "145471",
    "name": "Union Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Union Value Discovery Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF582M01EO6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "145472",
    "name": "Union Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Union Value Discovery Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF582M01EQ1",
    "isinRe": "INF582M01EP3"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "145473",
    "name": "Union Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Union Value Discovery Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF582M01EK4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "145474",
    "name": "Union Value Discovery Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Union Value Discovery Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF582M01EM0",
    "isinRe": "INF582M01EL2"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145481",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUNLIFE OVERNIGHT FUND-REGULAR PLAN-GROWTH",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZC3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145489",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND-  Direct - Weekly Reinvestment of IDCW",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZJ8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145482",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND-  Regular - Weekly Reinvestment of IDCW option",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZE9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145483",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND- Regular - Daily Reinvestment of IDCW",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZD1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145485",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND- Regular- Monthly Payout of IDCW option",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZG4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145486",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND-DIRECT PLAN-GROWTH",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZH2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145487",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND- Direct - Daily Reinvestment of IDCW",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZI0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145488",
    "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND-  Direct - Monthly Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2018-10-30",
    "closeData": "",
    "isin": "INF209KB1ZL4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145491",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB10W5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145492",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB19V8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145493",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB11W3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145494",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB13W9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145495",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB12W1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145496",
    "name": "HDFC FMP 1246D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1246D NOVEMBER 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-13",
    "isin": "INF179KB14W7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145497",
    "name": "SBI Debt Fund Series C - 28 (386 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 28 (386 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-12",
    "isin": "INF200KA16G1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145498",
    "name": "SBI Debt Fund Series C - 28 (386 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 28 (386 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-12",
    "isin": "INF200KA14G6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145499",
    "name": "SBI Debt Fund Series C - 28 (386 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 28 - (386 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-12",
    "isin": "INF200KA15G3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145500",
    "name": "SBI Debt Fund Series C - 28 (386 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 28 - (386 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-12",
    "closeData": "2018-11-12",
    "isin": "INF200KA13G8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145505",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADV7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145506",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADX3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145514",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADU9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145507",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADY1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145508",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADS3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145510",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADR5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145511",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADZ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145512",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADQ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145513",
    "name": "UTI - Fixed Term Income Fund Series XXX-XI (1246 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XI (1246 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF789F1ADW5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145515",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NX5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145516",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NV9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145517",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NY3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145518",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NZ0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145519",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NW7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145520",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1245 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF109KC1NU1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145521",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-16",
    "closeData": "",
    "isin": "INF109KC1PC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145522",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-16",
    "closeData": "",
    "isin": "INF109KC1PE0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145523",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-16",
    "closeData": "",
    "isin": "INF109KC1PF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145524",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan A 1275 Days - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-16",
    "closeData": "",
    "isin": "INF109KC1PD2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145525",
    "name": "Nippon India Fixed Horizon Fund - XXXIX- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 15 - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-11-13",
    "closeData": "",
    "isin": "INF204KB1N50",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145526",
    "name": "Nippon India Fixed Horizon Fund - XXXIX- Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXIX - SERIES 15 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-11-13",
    "closeData": "",
    "isin": "INF204KB1N76",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145527",
    "name": "Nippon India Fixed Horizon Fund - XXXIX- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX - Series 15 Growth option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-11-13",
    "closeData": "",
    "isin": "INF204KB1N43",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145528",
    "name": "Nippon India Fixed Horizon Fund - XXXIX- Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXIX- Series15- Direct Plan - Growth option",
    "minAmount": "Rs.5000 and in multiples of Re 1",
    "launchDate": "2018-11-13",
    "closeData": "",
    "isin": "INF204KB1N68",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145529",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan B (1244 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QQ7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145530",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan B (1244 days)- Growth Direct",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QT1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145531",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan B (1244 days)- Dividend - Direct",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QU9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145532",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan B (1244 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QV7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145533",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan B 1244 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QS3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145534",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan B (1244 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan B 1244 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF090I01QR5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145535",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Growth",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC17E3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145536",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Growth",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC11G1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145537",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC12G9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145538",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Weekly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC12F1",
    "isinRe": "INF109KC11F3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145539",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC16G0",
    "isinRe": "INF109KC15G2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145540",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Fortnightly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC14F7",
    "isinRe": "INF109KC13F9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149410",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight fund - Direct Plan - Unclaimed Redemption Transitory Scheme",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC15H0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149411",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight fund - Direct Plan - Unclaimed IDCW Stable Scheme",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC18H4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145547",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Daily IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC18E1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145548",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Monthly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC19E9",
    "isinRe": "INF109KC10F5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145549",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Half Yearly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC17F0",
    "isinRe": "INF109KC18F8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145550",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC13H5",
    "isinRe": "INF109KC14H3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149408",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight fund - Direct Plan - Unclaimed IDCW Transitory Scheme",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC16H8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149409",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight fund - Direct Plan - Unclaimed Redemption Stable Scheme",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC17H6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145541",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Fortnightly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC18G6",
    "isinRe": "INF109KC17G8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145542",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC13G7",
    "isinRe": "INF109KC14G5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145543",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Quarterly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC15F4",
    "isinRe": "INF109KC16F2"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145544",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC19G4",
    "isinRe": "INF109KC10H1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145545",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Direct Plan - Half Yearly IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC11H9",
    "isinRe": "INF109KC12H7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145546",
    "name": "ICICI Prudential Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ICICI Prudential Overnight Fund - Annual IDCW",
    "minAmount": "Rs. 100/- (plus in multiple of Re. 1)",
    "launchDate": "2018-11-14",
    "closeData": "",
    "isin": "INF109KC19F6",
    "isinRe": "INF109KC10G3"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "145551",
    "name": "Motilal Oswal Nasdaq 100 Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Nasdaq 100 Fund of Fund- Regular Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-22",
    "isin": "INF247L01700",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "145552",
    "name": "Motilal Oswal Nasdaq 100 Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Nasdaq 100 Fund of Fund- Direct Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-11-09",
    "closeData": "2018-11-22",
    "isin": "INF247L01718",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145553",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AEF8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145555",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AEA9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145556",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XII (1254 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AEC5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145557",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XII (1254 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AED3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145558",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XII (1254 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AEE1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145561",
    "name": "UTI - Fixed Term Income Fund Series XXX-XII (1254 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XII (1254 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF789F1AEB7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145563",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yearly Interval Plan Series 1-Regular Plan-Growth Option",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1939",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145564",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yealy Interval Plan Series 1-Direct Plan-Dividend Payout",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1947",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145565",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yearly Interval Plan Series 1-Direct Plan-Gwoth Option",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1954",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145566",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yeraly Interval Plan Series 1-Regular Plan-Dividend Reinvestment Option",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1962",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145567",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yeraly Interval Plan Series 1-Direct Plan-Dividend Reinvestment Option",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1970",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145568",
    "name": "Kotak Yearly Interval Plan Series 1",
    "category": "Income",
    "navName": "Kotak Yearly Interval Plan Series 1-Regular Plan-Dividend Payout",
    "minAmount": "20000000",
    "launchDate": "2018-11-12",
    "closeData": "",
    "isin": "INF174KA1921",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145585",
    "name": "Kotak FMP Series 251",
    "category": "Income",
    "navName": "Kotak FMP Series 251-1265 days -Regular Plan-Divident Payout Option",
    "minAmount": "200000000",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF174KA1996",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145586",
    "name": "Kotak FMP Series 251",
    "category": "Income",
    "navName": "Kotak Fmp series 251-1265 days-Direct Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF174KA1AB0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145587",
    "name": "Kotak FMP Series 251",
    "category": "Income",
    "navName": "Kotak FMP Series 251-1265 days-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF174KA1988",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145588",
    "name": "Kotak FMP Series 251",
    "category": "Income",
    "navName": "Kotak FMP Series 251-1265 days-Direct Paln-Gwoth option",
    "minAmount": "200000000",
    "launchDate": "2018-11-15",
    "closeData": "",
    "isin": "INF174KA1AA2",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145589",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund) - Direct Plan - Growth option",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EK5",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145590",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund) - Regular Plan - Growth option",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01ES8",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145591",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EQ2",
    "isinRe": "INF666M01ER0"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145592",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EY6",
    "isinRe": "INF666M01EZ3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145593",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option ( Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01ET6",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145594",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Regular Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EU4",
    "isinRe": "INF666M01EV2"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145595",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Direct Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EO7",
    "isinRe": "INF666M01EP4"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145596",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Direct Plan- - Income Distribution cum capital withdrawal Option ( Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EL3",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145597",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Direct Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EM1",
    "isinRe": "INF666M01EN9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145598",
    "name": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Groww Dynamic Bond Fund (formerly known as Indiabulls Dynamic Bond Fund)- Regular Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-16",
    "closeData": "2018-11-30",
    "isin": "INF666M01EW0",
    "isinRe": "INF666M01EX8"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145599",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund) - Direct Plan - Growth option",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FA3",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145600",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Direct Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FB1",
    "isinRe": "INF666M01FC9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145607",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Regular Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FO4",
    "isinRe": "INF666M01FP1"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145608",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Regular Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FQ9",
    "isinRe": "INF666M01FR7"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145601",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Direct Plan- Quarterly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FF2",
    "isinRe": "INF666M01FG0"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145602",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Direct Plan- Half Yearly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FH8",
    "isinRe": "INF666M01FI6"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145603",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FK2",
    "isinRe": "INF666M01FL0"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145604",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment.",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FM8",
    "isinRe": "INF666M01FN6"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145605",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund) - Regular Plan - Growth option",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FJ4",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "145606",
    "name": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Groww Aggressive Hybrid Fund (formerly known as Indiabulls Equity Hybrid Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs. 500 and in multiples of  Re. 1 thereafter",
    "launchDate": "2018-11-22",
    "closeData": "2018-12-06",
    "isin": "INF666M01FD7",
    "isinRe": "INF666M01FE5"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145609",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 1 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF204KB1O18",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145610",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 1 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF204KB1N92",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145611",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 1 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF204KB1N84",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145612",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 1 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF204KB1O00",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145613",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 2 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O59",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145614",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 2 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O26",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145615",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 2",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 2 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O34",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145616",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O42",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145617",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 3 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O91",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145618",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 3 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O67",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145619",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 3 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O83",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145620",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 3",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 3 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF204KB1O75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145621",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PH3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145622",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PI1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145623",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PG5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145624",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145625",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PJ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145626",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1288 Days Plan O - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF109KC1PK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145627",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PM3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145628",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145629",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145630",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145631",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145632",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1279 Days Plan P - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF109KC1PN1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "145633",
    "name": "Mirae Asset Nifty 50 ETF (MAN50ETF)",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty 50 ETF (MAN50ETF)",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2018-11-19",
    "closeData": "",
    "isin": "INF769K01EG9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145634",
    "name": "Kotak FMP Series 252",
    "category": "Income",
    "navName": "Kotak FMP Series 252-1261days-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF174KA1AC8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145635",
    "name": "Kotak FMP Series 252",
    "category": "Income",
    "navName": "Kotak FMP Series 252-1261 days-Regular Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF174KA1AD6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145636",
    "name": "Kotak FMP Series 252",
    "category": "Income",
    "navName": "Kotak FMP Series 252-1261 days-Direct Plan-Dividend Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF174KA1AF1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145637",
    "name": "Kotak FMP Series 252",
    "category": "Income",
    "navName": "Kotak FMP Series 252-1261 days Direct plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-11-20",
    "closeData": "",
    "isin": "INF174KA1AE4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145638",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D November 2018(1)-Direct Plan-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145639",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D NOVEMBER 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": "INF179KB18W8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145640",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D NOVEMBER 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": "INF179KB17W0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145641",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D November 2018(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145642",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D NOVEMBER 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": "INF179KB10X3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145643",
    "name": "HDFC FMP 1232D NOVEMBER 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1232D NOVEMBER 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-11-27",
    "closeData": "2018-11-27",
    "isin": "INF179KB15W4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145644",
    "name": "SBI Debt Fund Series C - 30 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 30 (385 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-26",
    "closeData": "2018-11-30",
    "isin": "INF200KA18G7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145645",
    "name": "SBI Debt Fund Series C - 30 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 30 (385 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-26",
    "closeData": "2018-11-30",
    "isin": "INF200KA10H2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145646",
    "name": "SBI Debt Fund Series C - 30 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 30 - (385 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-26",
    "closeData": "2018-11-30",
    "isin": "INF200KA17G9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145647",
    "name": "SBI Debt Fund Series C - 30 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 30 - (385 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-26",
    "closeData": "2018-11-30",
    "isin": "INF200KA19G5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145648",
    "name": "SBI Nifty 200 Quality 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty 200 Quality 30 ETF",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-11-26",
    "closeData": "2018-12-03",
    "isin": "INF200KA1WX6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145650",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEK8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145651",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145652",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEL6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145653",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AES1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145654",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEP7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145655",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AER3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145657",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEN2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145658",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIII (1224 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIII (1224 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2018-11-27",
    "closeData": "",
    "isin": "INF789F1AEO0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145663",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 4 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P33",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145664",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 4 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P17",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145665",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 4 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P25",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145666",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 4 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P09",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145670",
    "name": "Nippon India Interval Fund V - Series 2",
    "category": "Income",
    "navName": "Nippon India Interval Fund V - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P66",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145667",
    "name": "Nippon India Interval Fund V - Series 2",
    "category": "Income",
    "navName": "Nippon India Interval Fund V - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P74",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145668",
    "name": "Nippon India Interval Fund V - Series 2",
    "category": "Income",
    "navName": "Nippon India Interval Fund V - Series 2 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P58",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145669",
    "name": "Nippon India Interval Fund V - Series 2",
    "category": "Income",
    "navName": "Nippon India Interval Fund V - Series 2 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF204KB1P41",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145671",
    "name": "Axis Capital Builder Fund - Series 4 (1582 Days)",
    "category": "Growth",
    "navName": "Axis Capital Builder Fund Series 4 (1582 Days) - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs 10/- thereafter",
    "launchDate": "2018-12-03",
    "closeData": "2018-12-17",
    "isin": "INF846K01K76",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145672",
    "name": "Axis Capital Builder Fund - Series 4 (1582 Days)",
    "category": "Growth",
    "navName": "Axis Capital Builder Fund - Series 4 (1582 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs 10/- thereafter",
    "launchDate": "2018-12-03",
    "closeData": "2018-12-17",
    "isin": "INF846K01K68",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145673",
    "name": "Axis Capital Builder Fund - Series 4 (1582 Days)",
    "category": "Growth",
    "navName": "Axis Capital Builder Fund - Series 4 (1582 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs 10/- thereafter",
    "launchDate": "2018-12-03",
    "closeData": "2018-12-17",
    "isin": "INF846K01K84",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "145674",
    "name": "Axis Capital Builder Fund - Series 4 (1582 Days)",
    "category": "Growth",
    "navName": "Axis Capital Builder Fund Series 4 (1582 Days) - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs 10/- thereafter",
    "launchDate": "2018-12-03",
    "closeData": "2018-12-17",
    "isin": "INF846K01K92",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "145675",
    "name": "BANK OF INDIA SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANK OF INDIA Small Cap Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2018-11-28",
    "closeData": "2018-12-12",
    "isin": "INF761K01EO0",
    "isinRe": "INF761K01ES1"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "145676",
    "name": "BANK OF INDIA SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANK OF INDIA Small Cap Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2018-11-28",
    "closeData": "2018-12-12",
    "isin": "INF761K01EQ5",
    "isinRe": "INF761K01ET9"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "145677",
    "name": "BANK OF INDIA SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANK OF INDIA Small Cap Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2018-11-28",
    "closeData": "2018-12-12",
    "isin": "INF761K01ER3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "145678",
    "name": "BANK OF INDIA SMALL CAP FUND",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANK OF INDIA Small Cap Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2018-11-28",
    "closeData": "2018-12-12",
    "isin": "INF761K01EP7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "145693",
    "name": "Mirae Asset Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mirae Asset Equity Savings Fund- Direct Plan- Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF769K01EK1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "145694",
    "name": "Mirae Asset Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mirae Asset Equity Savings Fund Direct IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF769K01EJ3",
    "isinRe": "INF769K01EM7"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "145695",
    "name": "Mirae Asset Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mirae Asset Equity Savings Fund- Regular Plan- Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF769K01EI5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "145696",
    "name": "Mirae Asset Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Mirae Asset Equity Savings Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2018-11-26",
    "closeData": "",
    "isin": "INF769K01EH7",
    "isinRe": "INF769K01EL9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145704",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PX0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145705",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PS0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145706",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145701",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145702",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145703",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1272 Days Plan Q - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF109KC1PW2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145707",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QE8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145708",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QG3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145709",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QH1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145710",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QF5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145711",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QI9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145712",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1235 Days Plan R - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-05",
    "closeData": "",
    "isin": "INF109KC1QJ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145713",
    "name": "Kotak FMP Series 253",
    "category": "Income",
    "navName": "Kotak FMP Series 253-1260 days-Regular plan-Growth option",
    "minAmount": "200000000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF174KA1AG9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145714",
    "name": "Kotak FMP Series 253",
    "category": "Income",
    "navName": "Kotak FMP Series 253-1260 days -Regular paln-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF174KA1AH7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145715",
    "name": "Kotak FMP Series 253",
    "category": "Income",
    "navName": "Kotak FMP Series 253-1260 days-direct paln-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF174KA1AJ3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145716",
    "name": "Kotak FMP Series 253",
    "category": "Income",
    "navName": "Kotak FMP Series 253-1260 days-direct plan-growth option",
    "minAmount": "200000000",
    "launchDate": "2018-11-30",
    "closeData": "",
    "isin": "INF174KA1AI5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145717",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D DECEMBER 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": "INF179KB16X0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145718",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D December 2018(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145719",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D DECEMBER 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": "INF179KB18X6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145720",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D DECEMBER 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": "INF179KB15X2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145721",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D DECEMBER 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": "INF179KB19X4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145722",
    "name": "HDFC FMP 1224D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1224D DECEMBER 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-04",
    "closeData": "2018-12-05",
    "isin": "INF179KB10Y1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145727",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "TATA Arbitrage Fund Direct Plan - Monthly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K018Q1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145728",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "TATA Arbitrage Fund Regular Plan - Monthly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K016Q5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145723",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Tata Arbitrage Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K015Q7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145724",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Tata Arbitrage Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K017Q3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145725",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "TATA Arbitrage Fund Direct Plan - Monthly Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K019Q9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145726",
    "name": "Tata Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "TATA Arbitrage Fund Regular Plan - Monthly Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2018-12-10",
    "closeData": "2018-12-17",
    "isin": "INF277K010R6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145743",
    "name": "SBI Debt Fund Series C - 32 (380 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 32 (380 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-05",
    "closeData": "2018-12-12",
    "isin": "INF200KA12H8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145744",
    "name": "SBI Debt Fund Series C - 32 (380 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 32 (380 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-05",
    "closeData": "2018-12-12",
    "isin": "INF200KA14H4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145745",
    "name": "SBI Debt Fund Series C - 32 (380 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 32 - (380 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-05",
    "closeData": "2018-12-12",
    "isin": "INF200KA11H0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145746",
    "name": "SBI Debt Fund Series C - 32 (380 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 32 - (380 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-05",
    "closeData": "2018-12-12",
    "isin": "INF200KA13H6",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "145747",
    "name": "IDBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "IDBI Dividend Yield Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF397L01KY7",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "145748",
    "name": "IDBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "IDBI Dividend Yield Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF397L01KV3",
    "isinRe": "INF397L01KW1"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "145749",
    "name": "IDBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "IDBI Dividend Yield Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF397L01KZ4",
    "isinRe": "INF397L01LA5"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "145750",
    "name": "IDBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "IDBI Dividend Yield Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2018-12-03",
    "closeData": "",
    "isin": "INF397L01LC1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145751",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1QB4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145752",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1PZ5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145753",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1QD0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145754",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1QA6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145755",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1QC2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145756",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1224 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-06",
    "closeData": "",
    "isin": "INF109KC1PY8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145759",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 5 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-11",
    "closeData": "",
    "isin": "INF204KB1Q57",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145760",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-11",
    "closeData": "",
    "isin": "INF204KB1Q40",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145757",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 5",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 5 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-11",
    "closeData": "",
    "isin": "INF204KB1Q32",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145758",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 5 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-11",
    "closeData": "",
    "isin": "INF204KB1Q24",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145777",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan C 1259 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01QY1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145778",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan C 1259 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01RA9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145779",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan C 1259 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01RB7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145780",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series5 Plan C (1259 days)- Growth Direct",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01QZ8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145781",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan C 1259 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01QX3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145782",
    "name": "Franklin India Fixed Maturity Plans- Series 5- Plan C (1259 days)",
    "category": "Income",
    "navName": "Franklin Idnia Fixed Maturity Palns- Series 5 Plan C (1259 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF090I01QW5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145784",
    "name": "Kotak FMP Series 254",
    "category": "Income",
    "navName": "Kotak FMP Series 254-Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-10",
    "closeData": "",
    "isin": "INF174KA1AN5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145785",
    "name": "Kotak FMP Series 254",
    "category": "Income",
    "navName": "Kotak FMP Series 254-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-10",
    "closeData": "",
    "isin": "INF174KA1AL9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145786",
    "name": "Kotak FMP Series 254",
    "category": "Income",
    "navName": "Kotak FMP Series 254-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-10",
    "closeData": "",
    "isin": "INF174KA1AK1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145783",
    "name": "Kotak FMP Series 254",
    "category": "Income",
    "navName": "Kotak FMP Series 254-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-10",
    "closeData": "",
    "isin": "INF174KA1AM7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145787",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 6 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1S30",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145788",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 6",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 6 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1S14",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145789",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 6 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1S06",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145790",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1S22",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145795",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": "INF179KB12Y7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145796",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": "INF179KB11Y9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145797",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145798",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": "INF179KB15Y0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145799",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": "INF179KB14Y3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145800",
    "name": "HDFC FMP 1218D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1218D December 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-07",
    "closeData": "2018-12-11",
    "isin": "INF179KB16Y8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "145801",
    "name": "Tata Nifty 50 Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Tata Nifty 50 Exchange Traded Fund",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF277K015R5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145824",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - DIRECT Plan - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R64",
    "isinRe": "INF204KB1R72"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145825",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - DIRECT Plan - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R80",
    "isinRe": "INF204KB1R98"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145826",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - WEEKLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1Q81",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145827",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - MONTHLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1Q99",
    "isinRe": "INF204KB1R07"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145830",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - DAILY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1Q73",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145833",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - QUARTERLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R15",
    "isinRe": "INF204KB1R23"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145810",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Nippon India Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R31",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145811",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Nippon India Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1Q65",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145812",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - DIRECT Plan - DAILY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R49",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145823",
    "name": "Nippon India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NIPPON INDIA OVERNIGHT FUND - DIRECT Plan - WEEKLY IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF204KB1R56",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145818",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145813",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145814",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145815",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145816",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145817",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1225 Days Plan T - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "145819",
    "name": "Shriram Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Shriram Long Term Equity Fund - Direct Growth",
    "minAmount": "Rs. 500/-and in multiples of Rs. 500/- thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF680P01158",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "145820",
    "name": "Shriram Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Shriram Long Term Equity Fund - Regular Growth",
    "minAmount": "Rs. 500/-and in multiples of Rs. 500/- thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF680P01133",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "145821",
    "name": "Shriram Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Shriram Long Term Equity Fund - Regular-IDCW",
    "minAmount": "Rs. 500/-and in multiples of Rs. 500/- thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF680P01141",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "145822",
    "name": "Shriram Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Shriram Long Term Equity Fund - Direct- IDCW",
    "minAmount": "Rs. 500/-and in multiples of Rs. 500/- thereafter",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF680P01166",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "145834",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Direct Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01734",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "145946",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Regular Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01726",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146218",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Direct - IDCW  Quarterly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01791",
    "isinRe": "INF247L01783"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146220",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Regular - IDCW Monthly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01841",
    "isinRe": "INF247L01833"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146222",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Regular - IDCW Quarterly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01866",
    "isinRe": "INF247L01858"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146223",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Direct - IDCW Monthly Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01775",
    "isinRe": "INF247L01767"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147441",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Unclaimed Dividend- Upto 3 years",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147442",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Unclaimed Dividend - Greater than 3 years",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147443",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Unclaimed Redemption - Greater than 3 years",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147444",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund - Unclaimed Redemption - Upto 3 years",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146224",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Regular - IDCW Weekly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01874",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146226",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Direct - IDCW Fortnightly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01759",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146227",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Regular - IDCW Daily Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01817",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146228",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Direct - IDCW Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01742",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146229",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Direct - IDCW Weekly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01809",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "146230",
    "name": "Motilal Oswal Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Motilal Oswal Liquid Fund Regular - IDCW Fortnightly Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2018-12-11",
    "closeData": "2018-12-18",
    "isin": "INF247L01825",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145835",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFB4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145836",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEW3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145844",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEX1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145837",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEY9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145838",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEZ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145839",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFA6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145840",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFC2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145842",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEU7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145843",
    "name": "UTI - Fixed Term Income Fund Series XXX-XIV (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XIV (1209 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AEV5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145845",
    "name": "UTI - Fixed Term Income Fund Series XXX-XV (1223 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XV (1223 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFJ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145848",
    "name": "UTI - Fixed Term Income Fund Series XXX-XV (1223 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXX-XV (1223 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFE8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145851",
    "name": "UTI - Fixed Term Income Fund Series XXX-XV (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXX-XV (1223 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2018-12-12",
    "closeData": "",
    "isin": "INF789F1AFF5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145855",
    "name": "SBI DEBT FUND SERIES C - 33 (1216 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 33 (1216 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-13",
    "closeData": "2018-12-20",
    "isin": "INF200KA1XM7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145856",
    "name": "SBI DEBT FUND SERIES C - 33 (1216 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 33 (1216 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-13",
    "closeData": "2018-12-20",
    "isin": "INF200KA1XK1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145857",
    "name": "SBI DEBT FUND SERIES C - 33 (1216 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 33 (1216 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-13",
    "closeData": "2018-12-20",
    "isin": "INF200KA1XL9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145858",
    "name": "SBI DEBT FUND SERIES C - 33 (1216 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 33 (1216 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-13",
    "closeData": "2018-12-20",
    "isin": "INF200KA1XN5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102107",
    "name": "Franklin India Life Stage Fund of Funds - 20s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin INDIA LIFE STAGE FUND OF FUNDS - THE 20S PLAN (G)",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01593",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102108",
    "name": "Franklin India Life Stage Fund of Funds - 20s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 20's Plan - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01577",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118511",
    "name": "Franklin India Life Stage Fund of Funds - 20s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 20's Plan - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01HW4",
    "isinRe": "INF090I01HX2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118512",
    "name": "Franklin India Life Stage Fund of Funds - 20s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin INDIA LIFE STAGE FUND OF FUNDS - THE 20S PLAN - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01HY0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102109",
    "name": "Franklin India Life stage Fund of Funds - 30s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin INDIA LIFE STAGE FUND OF FUNDS - THE 30S PLAN (G)",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01627",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102110",
    "name": "Franklin India Life stage Fund of Funds - 30s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 30's Plan - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01601",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118513",
    "name": "Franklin India Life stage Fund of Funds - 30s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 30's Plan - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01HZ7",
    "isinRe": "INF090I01IA8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118514",
    "name": "Franklin India Life stage Fund of Funds - 30s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - The 30s Plan - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01IB6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102111",
    "name": "Franklin India Life Stage Fund of Funds - 40s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 40's Plan - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01635",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102112",
    "name": "Franklin India Life Stage Fund of Funds - 40s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin INDIA LIFE STAGE FUND OF FUNDS - THE 40S PLAN (G)",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01650",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118515",
    "name": "Franklin India Life Stage Fund of Funds - 40s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 40's Plan - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01IC4",
    "isinRe": "INF090I01ID2"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118516",
    "name": "Franklin India Life Stage Fund of Funds - 40s Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - The 40s Plan - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01IE0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102113",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 50's Plus Plan - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01668",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102114",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin INDIA LIFE STAGE FUND OF FUNDS - THE 50+S PLAN (G)",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01684",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118517",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 50's Plus Plan - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01IF7",
    "isinRe": "INF090I01IG5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118518",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - The 50s Plus - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2003-10-01",
    "closeData": "",
    "isin": "INF090I01IH3",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102546",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus Floating Rate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund Of Funds - 50's Plus Floating Rate Plan - IDCW ",
    "minAmount": "5000",
    "launchDate": "2004-06-01",
    "closeData": "",
    "isin": "INF090I01692",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "102547",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus Floating Rate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - The 50s Plus Flo (Gro)",
    "minAmount": "5000",
    "launchDate": "2004-06-01",
    "closeData": "",
    "isin": "INF090I01718",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118519",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus Floating Rate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund Of Funds - The 50S Plus Floating Rate Plan - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2004-06-01",
    "closeData": "",
    "isin": "INF090I01JO7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "118520",
    "name": "Franklin India Life Stage Fund of Funds - 50s plus Floating Rate Plan",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Franklin India Life Stage Fund of Funds - 50's Plus Floating Rate Plan - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2004-06-01",
    "closeData": "",
    "isin": "INF090I01JM1",
    "isinRe": "INF090I01JN9"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103730",
    "name": "Sahara Infrastructure Fund - FIXED PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---FIXED PRICING OPTION-Dividend Option",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01320",
    "isinRe": "INF515L01338"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103731",
    "name": "Sahara Infrastructure Fund - FIXED PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---FIXED PRICING OPTION-Growth Option",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01346",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120352",
    "name": "Sahara Infrastructure Fund - FIXED PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---FIXED PRICING -Direct-Dividend",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01924",
    "isinRe": "INF515L01932"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120353",
    "name": "Sahara Infrastructure Fund - FIXED PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---FIXED PRICING OPTION-Direct-Growth",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01940",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120354",
    "name": "Sahara Infrastructure Fund - VARIABLE PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---VARIABLE PRICING OPTION-Direct-Dividend",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01957",
    "isinRe": "INF515L01965"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120355",
    "name": "Sahara Infrastructure Fund - VARIABLE PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---VARIABLE PRICING OPTION-Direct-Growth",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01973",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103732",
    "name": "Sahara Infrastructure Fund - VARIABLE PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---VARIABLE PRICING OPTION-Dividend Option",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01353",
    "isinRe": "INF515L01361"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103733",
    "name": "Sahara Infrastructure Fund - VARIABLE PRICING OPTION",
    "category": "Growth",
    "navName": "Sahara Infrastructure Fund ---VARIABLE PRICING OPTION-Growth Option",
    "minAmount": "Rs.1000/- (Growth Opt) & Rs.5000/- (Dividend Opt)",
    "launchDate": "2006-02-15",
    "closeData": "",
    "isin": "INF515L01379",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101393",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Daily Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01049",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101394",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Growth option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01056",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101397",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing -  Weekly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01064",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101398",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Monthly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01072",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120253",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing -Direct - Daily Dividend Option- Direct",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01643",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120262",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Direct -Growth option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01650",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120265",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing - Direct - Weekly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01668",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120266",
    "name": "Sahara Liquid Fund - Fixed Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Fixed Pricing -Direct - Monthly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01676",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101399",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing - Daily Dividend option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01080",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101400",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing - Weekly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01098",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101401",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing- Monthly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01106",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "101402",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing - Growth option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01114",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120271",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing -Direct - Daily Dividend option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01684",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120272",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing -Direct - Weekly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01692",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120273",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing- Direct - Monthly Dividend Option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01700",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120280",
    "name": "Sahara Liquid Fund - Variable Pricing",
    "category": "Liquid",
    "navName": "Sahara Liquid Fund-Variable Pricing -Direct - Growth option",
    "minAmount": "1000 and multiples of Rs.1",
    "launchDate": "2002-02-06",
    "closeData": "",
    "isin": "INF515L01718",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103119",
    "name": "Sahara Wealth Plus Fund - Fixed Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Fixed Pricing Option-Dividend Option",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01262",
    "isinRe": "INF515L01270"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103120",
    "name": "Sahara Wealth Plus Fund - Fixed Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Fixed Pricing Option-Growth Option",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01288",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120340",
    "name": "Sahara Wealth Plus Fund - Fixed Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Fixed Pricing Option-Direct-Growth",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01882",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120341",
    "name": "Sahara Wealth Plus Fund - Fixed Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Fixed Pricing Option-Direct-Dividend Option",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01866",
    "isinRe": "INF515L01874"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103121",
    "name": "Sahara Wealth Plus Fund - Variable Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Variable Pricing Option-Dividend Option",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01296",
    "isinRe": "INF515L01304"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "103122",
    "name": "Sahara Wealth Plus Fund - Variable Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Variable Pricing Option-Growth Option",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01312",
    "isinRe": null
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120344",
    "name": "Sahara Wealth Plus Fund - Variable Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Variable Pricing Option-Direct -Dividend",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01890",
    "isinRe": "INF515L01908"
  },
  {
    "amc": "Sahara Asset Management Company Private Limited",
    "code": "120345",
    "name": "Sahara Wealth Plus Fund - Variable Pricing Option",
    "category": "Growth",
    "navName": "Sahara Wealth Plus Fund-Variable Pricing Option-Direct-Growth",
    "minAmount": "Rs 1000/-",
    "launchDate": "2005-07-04",
    "closeData": "",
    "isin": "INF515L01916",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145859",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018(1)-Direct Plan-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145860",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": "INF179KB17Y6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145861",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145862",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": "INF179KB10Z8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145863",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018(1)-Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145864",
    "name": "HDFC FMP 1211D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1211D December 2018(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-12-18",
    "closeData": "2018-12-18",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145869",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": "INF179KB16Z5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145870",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": "INF179KB18Z1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145865",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018(1)-Direct Option- Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145866",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": "INF179KB13Z2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145867",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018(1)-Direct Option-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145868",
    "name": "HDFC FMP 1203D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1203D December 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "2018-12-26",
    "isin": "INF179KB17Z3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145877",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 7 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S71",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145878",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 7",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 7 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S55",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145879",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 7 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S48",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145880",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S63",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "145881",
    "name": "Aditya Birla Sun Life Nifty Next 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sunlife Nifty Next 50 ETF",
    "minAmount": "5000 and in multiples of Rs 1000",
    "launchDate": "2018-12-11",
    "closeData": "",
    "isin": "INF209KB16D7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145886",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145887",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145888",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145889",
    "name": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days",
    "category": "Income",
    "navName": "ICICI Prudential Capital Protection Oriented Fund - Series XIV - Plan B 1201 Days - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145894",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Direct Plan-Monthly IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PR6",
    "isinRe": "INF767K01PS4"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145895",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Direct Plan-Growth",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PP0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145890",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Regular Plan-Growth",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PL9",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145891",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Direct Plan-Weekly IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PQ8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145892",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Regular Plan-Monthly IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PN5",
    "isinRe": "INF767K01PO3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145893",
    "name": "LIC MF Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "LIC MF Arbitrage Fund-Regular Plan-Weekly IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-18",
    "isin": "INF767K01PM7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145896",
    "name": "ICICI Prudential India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential India Opportunities Fund - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF109KC1RE6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145897",
    "name": "ICICI Prudential India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential India Opportunities Fund - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF109KC1RH9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145898",
    "name": "ICICI Prudential India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential India Opportunities Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF109KC1RI7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145899",
    "name": "ICICI Prudential India Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential India Opportunities Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF109KC1RF3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145900",
    "name": "Kotak FMP Series 255",
    "category": "Income",
    "navName": "Kotak FMP Series 255-Regular Plan-Growth Option",
    "minAmount": "20000000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF174KA1AO3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145901",
    "name": "Kotak FMP Series 255",
    "category": "Income",
    "navName": "Kotak FMP Series 255-Direct plan-Dividend Payout option",
    "minAmount": "20000000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF174KA1AR6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145902",
    "name": "Kotak FMP Series 255",
    "category": "Income",
    "navName": "Kotak FMP Series 255-Direct Plan-Growth n",
    "minAmount": "20000000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF174KA1AQ8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145903",
    "name": "Kotak FMP Series 255",
    "category": "Income",
    "navName": "Kotak FMP Series 255-Regular Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "20000000",
    "launchDate": "2018-12-17",
    "closeData": "",
    "isin": "INF174KA1AP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145904",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145905",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QZ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145906",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145907",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QW0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145908",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QX8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145909",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1254 Days Plan U - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-19",
    "closeData": "",
    "isin": "INF109KC1QY6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145910",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145911",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145912",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145913",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 169 Days Plan V - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2018-12-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145914",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 8 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1T13",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145915",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 8 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S97",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145916",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 8 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1S89",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145917",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 8 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF204KB1T05",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145931",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan D 1238 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RG6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145926",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RC5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145927",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan D 1238 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RE1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145928",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RH4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145929",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans -Series 5 Plan D (1238 days)- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RF8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "145930",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan D (1238 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan D 1238 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-01",
    "closeData": "",
    "isin": "INF090I01RD3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145935",
    "name": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-I (1209 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF789F1AFP4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145936",
    "name": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-I (1209 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF789F1AFR0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145939",
    "name": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF789F1AFT6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145940",
    "name": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF789F1AFO7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "145941",
    "name": "UTI - Fixed Term Income Fund Series XXXI-I (1209 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-I (1209 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2018-12-26",
    "closeData": "",
    "isin": "INF789F1AFU4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145948",
    "name": "SBI DEBT FUND SERIES C - 34 (1211 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 34 (1211 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-21",
    "closeData": "2018-12-31",
    "isin": "INF200KA1XR6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145949",
    "name": "SBI DEBT FUND SERIES C - 34 (1211 DAYS)",
    "category": "Income",
    "navName": "SBI DEBT FUND SERIES C - 34 (1211 DAYS) - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-21",
    "closeData": "2018-12-31",
    "isin": "INF200KA1XO3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145950",
    "name": "SBI DEBT FUND SERIES C - 34 (1211 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 34 (1211 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-21",
    "closeData": "2018-12-31",
    "isin": "INF200KA1XP0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145947",
    "name": "SBI DEBT FUND SERIES C - 34 (1211 DAYS)",
    "category": "Income",
    "navName": "SBI DEBT FUND SERIES C - 34 (1211 DAYS) - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-21",
    "closeData": "2018-12-31",
    "isin": "INF200KA1XQ8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145951",
    "name": "LIC MF Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "LIC MF Short Term Debt Fund-Regular Plan-IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-11",
    "closeData": "2019-01-25",
    "isin": "INF767K01PU0",
    "isinRe": "INF767K01PV8"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145952",
    "name": "LIC MF Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "LIC MF Short Term Debt Fund-Regular Plan-Growth",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-11",
    "closeData": "2019-01-25",
    "isin": "INF767K01PT2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145953",
    "name": "LIC MF Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "LIC MF Short Term Debt Fund-Direct Plan-IDCW",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-11",
    "closeData": "2019-01-25",
    "isin": "INF767K01PX4",
    "isinRe": "INF767K01PY2"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "145954",
    "name": "LIC MF Short Term Debt Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "LIC MF Short Term Debt Fund-Direct Plan-Growth",
    "minAmount": "Rs5000 & in multiples of Rs1 thereafter during NFO",
    "launchDate": "2019-01-11",
    "closeData": "2019-01-25",
    "isin": "INF767K01PW6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145957",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 9 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-31",
    "closeData": "",
    "isin": "INF204KB1T21",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145958",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-31",
    "closeData": "",
    "isin": "INF204KB1T47",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145955",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-31",
    "closeData": "",
    "isin": "INF204KB1T54",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "145956",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 9 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2018-12-31",
    "closeData": "",
    "isin": "INF204KB1T39",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145959",
    "name": "Kotak FMP Series 256",
    "category": "Income",
    "navName": "Kotak FMP Series 256-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF174KA1AS4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145960",
    "name": "Kotak FMP Series 256",
    "category": "Income",
    "navName": "Kotak FMP Series 256-Regular Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF174KA1AT2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145961",
    "name": "Kotak FMP Series 256",
    "category": "Income",
    "navName": "Kotak FMP Series 256-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF174KA1AU0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "145962",
    "name": "Kotak FMP Series 256",
    "category": "Income",
    "navName": "Kotak FMP Series 256-Direct Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2018-12-24",
    "closeData": "",
    "isin": "INF174KA1AV8",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145963",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Regular plan-Daily Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01092",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145964",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Regular plan-Weekly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01159",
    "isinRe": "INF03VN01167"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145965",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Regular plan-Fortnightly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01100",
    "isinRe": "INF03VN01118"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145966",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Direct plan-Fortnightly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01027",
    "isinRe": "INF03VN01035"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145967",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Direct plan-Monthly  Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01050",
    "isinRe": "INF03VN01068"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145968",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Regular plan-Growth Option",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01126",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145969",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Direct plan-Daily Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01019",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145970",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Direct plan-Weekly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01076",
    "isinRe": "INF03VN01084"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145971",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Direct plan-Growth Option",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01043",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "145972",
    "name": "WhiteOak Capital Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "WhiteOak Capital Liquid Fund- Regular plan-Monthly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 10000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF03VN01134",
    "isinRe": "INF03VN01142"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145981",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KC1015",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145982",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KB19Z9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145983",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KC1023",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145984",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KC1049",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145985",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KC1031",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "145986",
    "name": "HDFC FMP 1196D December 2018 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1196D December 2018 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-31",
    "closeData": "2019-01-02",
    "isin": "INF179KC1056",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145989",
    "name": "SBI DEBT FUND SERIES C - 35 (376 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 35 (376 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-27",
    "closeData": "2019-01-07",
    "isin": "INF200KA15J7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145990",
    "name": "SBI DEBT FUND SERIES C - 35 (376 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 35 (376 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-27",
    "closeData": "2019-01-07",
    "isin": "INF200KA16J5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145987",
    "name": "SBI DEBT FUND SERIES C - 35 (376 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 35 (376 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-27",
    "closeData": "2019-01-07",
    "isin": "INF200KA17J3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "145988",
    "name": "SBI DEBT FUND SERIES C - 35 (376 DAYS)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 35 (376 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2018-12-27",
    "closeData": "2019-01-07",
    "isin": "INF200KA18J1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145995",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RL1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145996",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RK3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145997",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RP2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145998",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RN7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "145999",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RO5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146000",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1247 Days Plan W - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2018-12-27",
    "closeData": "",
    "isin": "INF109KC1RM9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146001",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 10 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF204KB1T70",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146002",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 10 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF204KB1T62",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146003",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 10 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF204KB1T88",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146004",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 10",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 10 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF204KB1T96",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146005",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "TATA Balanced Advantage Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K011S2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146006",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "TATA Balanced Advantage Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K014S6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146007",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Tata Balanced Advantage Fund-Regular Plan-Growth",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K010S4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146008",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "TATA Balanced Advantage Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K015S3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146009",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "TATA Balanced Advantage Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K012S0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146010",
    "name": "Tata Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Tata Balanced Advantage Fund-Direct Plan-Growth",
    "minAmount": "5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-01-09",
    "closeData": "",
    "isin": "INF277K013S8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146011",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Cumulative Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146012",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Direct Plan Cumulative Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146013",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Direct Plan Quarterly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146014",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Direct Plan Half Yearly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146015",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Half Yearly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146016",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan X - Quarterly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146017",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Cumulative Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1RW8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146018",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Direct Plan Cumulative Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1SB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146019",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Quarterly IDCW Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1RX6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146020",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Direct Plan Quarterly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1RZ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146021",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Direct Plan Half Yearly Dividend Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1SA2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146022",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1204 Days Plan Y - Half Yearly IDCW Option",
    "minAmount": "10",
    "launchDate": "2019-01-04",
    "closeData": "",
    "isin": "INF109KC1RY4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146027",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 11 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF204KB1U10",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146028",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 11 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF204KB1U36",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146029",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 11 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF204KB1U02",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146030",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 11 - Direct Plan - Growth Plan",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF204KB1U28",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146031",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AFY6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146032",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGD8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146033",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGF3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146034",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGC0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146035",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGB2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146036",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGI7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146037",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146038",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AFZ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146039",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGG1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146040",
    "name": "UTI - Fixed Term Income Fund Series XXXI-II (1222 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-II (1222 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-01-03",
    "closeData": "",
    "isin": "INF789F1AGH9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146041",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 12 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF204KB1U77",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146042",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 12 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF204KB1U51",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146043",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 12 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF204KB1U69",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146044",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 12 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF204KB1U44",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146045",
    "name": "Kotak FMP Series 257",
    "category": "Income",
    "navName": "Kotak FMP Series 257-Direct plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF174KA1AY2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146046",
    "name": "Kotak FMP Series 257",
    "category": "Income",
    "navName": "Kotak FMP Series 257-Regular Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF174KA1AX4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146047",
    "name": "Kotak FMP Series 257",
    "category": "Income",
    "navName": "Kotak FMP Series 257-Direct Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF174KA1AZ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146048",
    "name": "Kotak FMP Series 257",
    "category": "Income",
    "navName": "Kotak FMP Series 257-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-02",
    "closeData": "",
    "isin": "INF174KA1AW6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146049",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan E (1224 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RI2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146050",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan E 1224 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RJ0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146051",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan E 1224 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RN2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146052",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan E 1224 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RM4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146053",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5- Plan E (1224 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RL6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146054",
    "name": "Franklin India Fixed Maturity Plans - Series 5- Plan E (1224 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan E 1224 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF090I01RK8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146055",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": "INF179KC1072",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146056",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": "INF179KC1064",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146057",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": "INF179KC1080",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146058",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": "INF179KC1098",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146059",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": "INF179KC1114",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146060",
    "name": "HDFC FMP 1190D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1190D January 2019(1)-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-04",
    "closeData": "2019-01-08",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146061",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1MB0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146062",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1MC8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149312",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Unclaimed IDCW - Upto 3 years (Invested in DSP Overnight Fund)",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149313",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Unclaimed IDCW - Beyond 3 years (Invested in DSP Overnight Fund)",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149310",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Unclaimed Redemption - Upto 3 years (Invested in DSP Overnight Fund)",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149311",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Unclaimed Redemption - Beyond 3 years (Invested in DSP Overnight Fund)",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146063",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Regular Plan - IDCW - Weekly",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1OV4",
    "isinRe": "INF740KA1OW2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146064",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Direct Plan - IDCW - Weekly",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1OS0",
    "isinRe": "INF740KA1OT8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146065",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Direct Plan - IDCW - Daily",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1OR2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146066",
    "name": "DSP Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "DSP Overnight Fund - Regular Plan - IDCW - Daily",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2019-01-07",
    "closeData": "2019-01-09",
    "isin": "INF740KA1OU6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146067",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund-Direct Plan - Weekly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K014T4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146068",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund Direct Plan - Weekly Reinvestment of Income Distribution cum capital withdrawal option   ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K015T1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146069",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund-Direct Plan - Monthly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K012T8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146070",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Tata Ultra Short Term Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K016S1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146071",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund-Regular Plan - Weekly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K019S5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146072",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund-Regular Plan - Monthly Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K017S9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146073",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund Regular Plan - Monthly Reinvestment of Income Distribution cum capital withdrawal option  ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K018S7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146074",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund Regular Plan -Weekly Reinvestment of Income Distribution cum capital withdrawal option  ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K010T2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146075",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Tata Ultra Short Term Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K011T0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146076",
    "name": "Tata Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "TATA Ultra Short Term Fund Direct Plan - Monthly Reinvestment of Income Distribution cum capital withdrawal option  ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-01-11",
    "closeData": "",
    "isin": "INF277K013T6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146081",
    "name": "IDFC FIXED TERM PLAN SERIES - 167 (1202 DAYS)",
    "category": "Income",
    "navName": "IDFC Fixed Term Plan Series 167 REGULAR PLAN QUARTERLY-IDCW (1202 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF194KB1280",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146082",
    "name": "IDFC FIXED TERM PLAN SERIES - 167 (1202 DAYS)",
    "category": "Income",
    "navName": "IDFC Fixed Term Plan Series 167 REGULAR PLAN HALF YEARLY-IDCW (1202 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF194KB1298",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146087",
    "name": "IDFC FIXED TERM PLAN SERIES - 167 (1202 DAYS)",
    "category": "Income",
    "navName": "IDFC Fixed Term Plan Series 167 REGULAR PLAN-GROWTH (1202 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF194KB1272",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146101",
    "name": "HSBC Fixed Term Series 137 (1187 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 137 - IDCW Direct Plan - Tenure 1187 Days - Maturity  18-Apr-2022",
    "minAmount": "5000",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF336L01NO0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146102",
    "name": "HSBC Fixed Term Series 137 (1187 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 137 Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF336L01NN2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146103",
    "name": "HSBC Fixed Term Series 137 (1187 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 137 - IDCW - Tenure 1187 Days - Maturity  18-Apr-2022",
    "minAmount": "5000",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF336L01NQ5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146104",
    "name": "HSBC Fixed Term Series 137 (1187 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 137 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-07",
    "closeData": "",
    "isin": "INF336L01NP7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146105",
    "name": "kotak fmp series 258",
    "category": "Income",
    "navName": "kotak fmp series 258-direct plan-growth option",
    "minAmount": "200000000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF174KA1BK9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146106",
    "name": "kotak fmp series 258",
    "category": "Income",
    "navName": "kotak fmp series 258-regular paln-growth option",
    "minAmount": "200000000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF174KA1BI3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146107",
    "name": "kotak fmp series 258",
    "category": "Income",
    "navName": "kotak fmp series 258-direct paln-dividend payout option",
    "minAmount": "200000000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF174KA1BL7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146108",
    "name": "kotak fmp series 258",
    "category": "Income",
    "navName": "kotak fmp series 258-regular plan-dividend payout option",
    "minAmount": "200000000",
    "launchDate": "2019-01-14",
    "closeData": "",
    "isin": "INF174KA1BJ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SF1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SE4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SD6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146113",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SH7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146114",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 84 - 1188 Days Plan Z - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-08",
    "closeData": "",
    "isin": "INF109KC1SG9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146115",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SI5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146116",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SM7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146117",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146118",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146119",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146120",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1197 Days Plan A - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF109KC1SN5",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146127",
    "name": "Canara Robeco Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "CANARA ROBECO SMALL CAP FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-01-25",
    "closeData": "2019-02-08",
    "isin": "INF760K01JF9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146128",
    "name": "Canara Robeco Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "CANARA ROBECO SMALL CAP FUND - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-01-25",
    "closeData": "2019-02-08",
    "isin": "INF760K01JG7",
    "isinRe": "INF760K01JH5"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146130",
    "name": "Canara Robeco Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "CANARA ROBECO SMALL CAP FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-01-25",
    "closeData": "2019-02-08",
    "isin": "INF760K01JC6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146131",
    "name": "Canara Robeco Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "CANARA ROBECO SMALL CAP FUND - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-01-25",
    "closeData": "2019-02-08",
    "isin": "INF760K01JD4",
    "isinRe": "INF760K01JE2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146138",
    "name": "Kotak Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Kotak Overnight Fund-Regular Plan- Daily Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF174KA1BB8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146140",
    "name": "Kotak Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Kotak Overnight Fund-Direct Plan- Daily Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF174KA1BE2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146141",
    "name": "Kotak Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Kotak Overnight Fund -Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF174KA1BF9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146142",
    "name": "Kotak Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Kotak Overnight Fund -Regular plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-10",
    "closeData": "",
    "isin": "INF174KA1BC6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146143",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019(1)-Direct Plan-Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146144",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": "INF179KC1171",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146145",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": "INF179KC1122",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146146",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": "INF179KC1163",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146147",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": "INF179KC1155",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146148",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1182D January 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": "INF179KC1148",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146150",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019(1)-Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146151",
    "name": "HDFC FMP 1182D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2019-01-14",
    "closeData": "2019-01-16",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146155",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1189",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146156",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1239",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146149",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1197",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146152",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1221",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146153",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1213",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146154",
    "name": "HDFC FMP 1175D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1175D January 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-17",
    "closeData": "2019-01-22",
    "isin": "INF179KC1205",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146165",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 13 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF204KB1V19",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146166",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 13 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF204KB1U93",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146167",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 13 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF204KB1U85",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146168",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 13 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF204KB1V01",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146169",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 14 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF204KB1V27",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146170",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 14 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF204KB1V50",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146171",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 14",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 14 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF204KB1V35",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146172",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 14",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 14 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF204KB1V43",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146173",
    "name": "SBI Debt Fund Series C - 38 (389 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 38 (389 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-15",
    "closeData": "2019-01-22",
    "isin": "INF200KA13K0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146174",
    "name": "SBI Debt Fund Series C - 38 (389 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 38 (389 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-15",
    "closeData": "2019-01-22",
    "isin": "INF200KA15K5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146175",
    "name": "SBI Debt Fund Series C - 38 (389 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 38 (389 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-15",
    "closeData": "2019-01-22",
    "isin": "INF200KA16K3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146176",
    "name": "SBI Debt Fund Series C - 38 (389 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 38 (389 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-15",
    "closeData": "2019-01-22",
    "isin": "INF200KA14K8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146187",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1314",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146188",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1330",
    "isinRe": "INF194KB1348"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146189",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1421",
    "isinRe": "INF194KB1439"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146190",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1462",
    "isinRe": "INF194KB1470"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146191",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1447",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146192",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1553",
    "isinRe": "INF194KB1561"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146183",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1496",
    "isinRe": "INF194KB1504"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146184",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Direct Plan - Periodic IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1520",
    "isinRe": "INF194KB1538"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146185",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1363",
    "isinRe": "INF194KB1371"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146186",
    "name": "BANDHAN Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANDHAN Overnight Fund - REGULAR PLAN PERIODIC IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2019-01-16",
    "closeData": "",
    "isin": "INF194KB1397",
    "isinRe": "INF194KB1405"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "146193",
    "name": "Edelweiss Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Edelweiss Small Cap Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF754K01JJ4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "146194",
    "name": "Edelweiss Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Edelweiss Small Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF754K01JK2",
    "isinRe": "INF754K01JL0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "146196",
    "name": "Edelweiss Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Edelweiss Small Cap Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF754K01JN6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "146197",
    "name": "Edelweiss Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Edelweiss Small Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF754K01JO4",
    "isinRe": "INF754K01JP1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146215",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YR4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146216",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YU8",
    "isinRe": "INF200KA1YV6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146207",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YM5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146208",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YN3",
    "isinRe": "INF200KA1YO1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146210",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YP8",
    "isinRe": "INF200KA1YQ6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146212",
    "name": "SBI Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "SBI Corporate Bond Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-16",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YS2",
    "isinRe": "INF200KA1YT0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146239",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGT4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146241",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGO5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146242",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGQ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146243",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGV0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146244",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGR8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146245",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGS6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146246",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGU2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146247",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGN7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146248",
    "name": "UTI - Fixed Term Income Fund Series XXXI-III (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-III (1174 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-18",
    "closeData": "",
    "isin": "INF789F1AGP2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147373",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IG3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147375",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund - Regular Plan - Monthly Dividend Reinvestment",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IC2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147376",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund - Regular Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IB4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147377",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund- Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01ID0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147378",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund - Direct Plan-Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IE8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147379",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund -Direct Plan - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IF5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147380",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund-Regular Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01HZ5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147381",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund-Regular Plan-Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01IA6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147382",
    "name": "Baroda BNP Paribas Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Baroda BNP Paribas Money Market Fund-Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-06-13",
    "closeData": "2019-06-18",
    "isin": "INF955L01HY8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146259",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1SS4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146260",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1SQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146255",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1SO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146256",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1SR6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146257",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1SP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146258",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1178 Days Plan B - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF109KC1ST2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146261",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHB0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146262",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHD6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146270",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHC8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146263",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHF1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146264",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AGX6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146265",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHG9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146266",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AGZ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146268",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AHE4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146269",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IV (1204 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IV (1204 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF789F1AGY4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146271",
    "name": "Nippon India ETF Nifty Midcap 150",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty Midcap 150",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-25",
    "closeData": "",
    "isin": "INF204KB1V68",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146276",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1254",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146277",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1247",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146278",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1262",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146279",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1288",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146280",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1270",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146281",
    "name": "HDFC FMP 1168D January 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1168D January 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-23",
    "closeData": "2019-01-29",
    "isin": "INF179KC1296",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146282",
    "name": "kotak FMP Series 259",
    "category": "Income",
    "navName": "kotak FMP Series 259-Regular Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF174KA1BN3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146283",
    "name": "kotak FMP Series 259",
    "category": "Income",
    "navName": "kotak FMP Series 259-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF174KA1BM5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146284",
    "name": "kotak FMP Series 259",
    "category": "Income",
    "navName": "kotak FMP Series 259 -Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF174KA1BO1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146285",
    "name": "kotak FMP Series 259",
    "category": "Income",
    "navName": "kotak FMP Series 259-Direct Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-01-21",
    "closeData": "",
    "isin": "INF174KA1BP8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146286",
    "name": "SBI Debt Fund Series C - 40 (1177 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 40 (1177 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-24",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YZ7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146287",
    "name": "SBI Debt Fund Series C - 40 (1177 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 40 (1177 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-24",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YX2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146288",
    "name": "SBI Debt Fund Series C - 40 (1177 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 40 - (1177 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-24",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YW4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146289",
    "name": "SBI Debt Fund Series C - 40 (1177 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 40- (1177 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-24",
    "closeData": "2019-01-29",
    "isin": "INF200KA1YY0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146290",
    "name": "kotak fmp series 260-1263 days",
    "category": "Income",
    "navName": "kotak FMP series 260-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-24",
    "closeData": "",
    "isin": "INF174KA1BQ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146291",
    "name": "kotak fmp series 260-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 260-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-24",
    "closeData": "",
    "isin": "INF174KA1BS2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146292",
    "name": "kotak fmp series 260-1263 days",
    "category": "Income",
    "navName": "kotak FMP Series 260- Direct Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-24",
    "closeData": "",
    "isin": "INF174KA1BT0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146293",
    "name": "kotak fmp series 260-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 260-Regular plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-24",
    "closeData": "",
    "isin": "INF174KA1BR4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146294",
    "name": "Union Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Union Arbitrage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF582M01EW9",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146295",
    "name": "Union Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Union Arbitrage Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF582M01EY5",
    "isinRe": "INF582M01EX7"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146296",
    "name": "Union Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Union Arbitrage Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF582M01EU3",
    "isinRe": "INF582M01ET5"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146297",
    "name": "Union Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Union Arbitrage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF582M01ES7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146298",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 15 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF204KB1W00",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146299",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 15 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF204KB1V92",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146300",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 15",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 15 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF204KB1V84",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146301",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 15",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 15 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF204KB1V76",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146302",
    "name": "Canara Robeco Capital Protection Oriented Fund - Series 10",
    "category": "Income",
    "navName": "CANARA ROBECO CAPITAL PROTECTION ORIENTED FUND SERIES 10 - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-02-15",
    "closeData": "2019-03-01",
    "isin": "INF760K01JI3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146303",
    "name": "Canara Robeco Capital Protection Oriented Fund - Series 10",
    "category": "Income",
    "navName": "CANARA ROBECO CAPITAL PROTECTION ORIENTED FUND SERIES 10 - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-02-15",
    "closeData": "2019-03-01",
    "isin": "INF760K01JK9",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146304",
    "name": "Canara Robeco Capital Protection Oriented Fund - Series 10",
    "category": "Income",
    "navName": "CANARA ROBECO CAPITAL PROTECTION ORIENTED FUND SERIES 10 - DIRECT PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-02-15",
    "closeData": "2019-03-01",
    "isin": "INF760K01JJ1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "146305",
    "name": "Canara Robeco Capital Protection Oriented Fund - Series 10",
    "category": "Income",
    "navName": "CANARA ROBECO CAPITAL PROTECTION ORIENTED FUND SERIES 10 - REGULAR PLAN - IDCW (Payout/Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-02-15",
    "closeData": "2019-03-01",
    "isin": "INF760K01JL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146306",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146307",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TB8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146308",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TE2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146309",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TD4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146310",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TC6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146311",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1175 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1TF9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146312",
    "name": "SBI Debt Fund Series C - 41 (1178 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 41 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-30",
    "closeData": "2019-02-04",
    "isin": "INF200KA1ZC3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146313",
    "name": "SBI Debt Fund Series C - 41 (1178 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 41 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-30",
    "closeData": "2019-02-04",
    "isin": "INF200KA1ZD1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146314",
    "name": "SBI Debt Fund Series C - 41 (1178 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 41 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-30",
    "closeData": "2019-02-04",
    "isin": "INF200KA1ZA7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146315",
    "name": "SBI Debt Fund Series C - 41 (1178 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 41 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-01-30",
    "closeData": "2019-02-04",
    "isin": "INF200KA1ZB5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146324",
    "name": "kotak FMP Series 261",
    "category": "Income",
    "navName": "kotak FMP Series 261-direct plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-25",
    "closeData": "",
    "isin": "INF174KA1BX2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146325",
    "name": "kotak FMP Series 261",
    "category": "Income",
    "navName": "kotak FMP Series 261-Regular Plan-Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-01-25",
    "closeData": "",
    "isin": "INF174KA1BV6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146326",
    "name": "kotak FMP Series 261",
    "category": "Income",
    "navName": "kotak FMP Series 261-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-25",
    "closeData": "",
    "isin": "INF174KA1BU8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146327",
    "name": "kotak FMP Series 261",
    "category": "Income",
    "navName": "kotak FMP Series 261-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-01-25",
    "closeData": "",
    "isin": "INF174KA1BW4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146329",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 16",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 16 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-04",
    "closeData": "",
    "isin": "INF204KB1W26",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146330",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 16",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 16 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-04",
    "closeData": "",
    "isin": "INF204KB1W18",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146331",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 16",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 16 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-04",
    "closeData": "",
    "isin": "INF204KB1W34",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146328",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 16",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 16 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-04",
    "closeData": "",
    "isin": "INF204KB1W42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146340",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146341",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146342",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SW6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146343",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SZ9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146344",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146345",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1185 Days Plan C - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-01-30",
    "closeData": "",
    "isin": "INF109KC1SV8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146346",
    "name": "ICICI Prudential Retirement Fund Pure Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Equity - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TQ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146347",
    "name": "ICICI Prudential Retirement Fund Pure Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Equity - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TR4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146348",
    "name": "ICICI Prudential Retirement Fund Pure Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Equity - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TT0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146349",
    "name": "ICICI Prudential Retirement Fund Pure Equity Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Equity - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TS2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146366",
    "name": "SBI Debt Fund Series C - 43 (384 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 43 (384 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-05",
    "closeData": "2019-02-13",
    "isin": "INF200KA18I3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146367",
    "name": "SBI Debt Fund Series C - 43 (384 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 43 (384 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-05",
    "closeData": "2019-02-13",
    "isin": "INF200KA19I1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146368",
    "name": "SBI Debt Fund Series C - 43 (384 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 43 (384 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-05",
    "closeData": "2019-02-13",
    "isin": "INF200KA10J8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146369",
    "name": "SBI Debt Fund Series C - 43 (384 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 43 (384 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-05",
    "closeData": "2019-02-13",
    "isin": "INF200KA17I5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146370",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan F 1203 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RQ5",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146371",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 5 Plan F (1203 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RO0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146372",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan F 1203 days - QTR Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RT9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146373",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan F 1203 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RP7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146374",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 5 - Plan F 1203 days - Direct - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RS1",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146375",
    "name": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 5 Plan F (1203 days)-Growth Direct",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF090I01RR3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146376",
    "name": "DSP Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MM7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146377",
    "name": "DSP Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MN5",
    "isinRe": "INF740KA1MO3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146378",
    "name": "DSP Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MK1",
    "isinRe": "INF740KA1ML9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146379",
    "name": "DSP Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MJ3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146380",
    "name": "DSP Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Next 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MD6",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146381",
    "name": "DSP Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Next 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MG9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146382",
    "name": "DSP Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Next 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1MH7",
    "isinRe": "INF740KA1MI5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "146383",
    "name": "DSP Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Next 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and any amount thereafter",
    "launchDate": "2019-02-11",
    "closeData": "2019-02-15",
    "isin": "INF740KA1ME4",
    "isinRe": "INF740KA1MF1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "146384",
    "name": "Baroda BNP Paribas Fixed Maturity Plan-Series P",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan- Series P- Regular Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-04",
    "closeData": "2019-02-12",
    "isin": "INF955L01HO9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "146385",
    "name": "Baroda BNP Paribas Fixed Maturity Plan-Series P",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan- Series P- Regular Plan- IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-04",
    "closeData": "2019-02-12",
    "isin": "INF955L01HP6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "146386",
    "name": "Baroda BNP Paribas Fixed Maturity Plan-Series P",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan- Series P- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-04",
    "closeData": "2019-02-12",
    "isin": "INF955L01HQ4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "146387",
    "name": "Baroda BNP Paribas Fixed Maturity Plan-Series P",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan- Series P- Direct Plan- IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-04",
    "closeData": "2019-02-12",
    "isin": "INF955L01HR2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146388",
    "name": "UTI - S&P BSE Sensex Next 50 Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI - S&P BSE Sensex Next 50 Exchange Traded Fund",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-02-26",
    "closeData": "",
    "isin": "INF789F1AUU3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146405",
    "name": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA- Regular - IDCW",
    "minAmount": "1000",
    "launchDate": "2019-01-22",
    "closeData": "2019-02-05",
    "isin": "INF209KB1E92",
    "isinRe": "INF209KB1F00"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146407",
    "name": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA DIRECT GROWTH",
    "minAmount": "1000",
    "launchDate": "2019-01-22",
    "closeData": "2019-02-05",
    "isin": "INF209KB1E84",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146408",
    "name": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA- Direct - IDCW",
    "minAmount": "1000",
    "launchDate": "2019-01-22",
    "closeData": "2019-02-05",
    "isin": "INF209KB1F18",
    "isinRe": "INF209KB1F26"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146409",
    "name": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA REGULAR GROWTH",
    "minAmount": "1000",
    "launchDate": "2019-01-22",
    "closeData": "2019-02-05",
    "isin": "INF209KB1E76",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146441",
    "name": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146442",
    "name": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146443",
    "name": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146444",
    "name": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 370 Days Plan A - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146445",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TL7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146446",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TO1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TM5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TK9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146449",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146450",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1168 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF109KC1TN3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146451",
    "name": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146452",
    "name": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146453",
    "name": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146454",
    "name": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 203 Days Plan B - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "146455",
    "name": "Invesco India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Invesco India Equity Savings Fund - Regular Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF205KA1015",
    "isinRe": "INF205KA1056"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "146456",
    "name": "Invesco India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Invesco India Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF205KA1023",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "146457",
    "name": "Invesco India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Invesco India Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF205KA1049",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "146458",
    "name": "Invesco India Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Invesco India Equity Savings Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF205KA1064",
    "isinRe": "INF205KA1031"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146465",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1312",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146466",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1353",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146467",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1346",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146468",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1304",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146469",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1338",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146470",
    "name": "HDFC FMP 1154D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1154D February 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "2019-02-12",
    "isin": "INF179KC1320",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146473",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 17",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 17 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF204KB1W75",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146474",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 17",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 17 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF204KB1W67",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146471",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 17",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 17 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF204KB1W83",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146472",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 17",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 17 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF204KB1W59",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146475",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHM7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146476",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHQ8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146484",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHP0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146477",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHI5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146478",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHH7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146479",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHJ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146480",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHK1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146481",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHN5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146482",
    "name": "UTI - Fixed Term Income Fund Series XXXI-V (1174 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-V (1174 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-06",
    "closeData": "",
    "isin": "INF789F1AHO3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146485",
    "name": "HSBC Fixed Term Series 139 (1163 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 139 - IDCW - Tenure 1163 Days - Maturity  03-May-2022",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": "INF336L01NU7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146486",
    "name": "HSBC Fixed Term Series 139 (1163 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 139 - IDCW Direct Plan - Tenure 1163 Days - Maturity  03-May-2022",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": "INF336L01NS1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146487",
    "name": "HSBC Fixed Term Series 139 (1163 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 139 - Direct Plan - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": "INF336L01NR3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146488",
    "name": "HSBC Fixed Term Series 139 (1163 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 139 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-12",
    "closeData": "",
    "isin": "INF336L01NT9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146493",
    "name": "SBI Debt Fund Series C - 44 (378 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 44 (378 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-14",
    "isin": "INF200KA11J6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146494",
    "name": "SBI Debt Fund Series C - 44 (378 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 44 (378 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-14",
    "isin": "INF200KA14J0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146495",
    "name": "SBI Debt Fund Series C - 44 (378 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 44 (378 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-14",
    "isin": "INF200KA13J2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146496",
    "name": "SBI Debt Fund Series C - 44 (378 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 44 (378 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-14",
    "isin": "INF200KA12J4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146497",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1379",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146498",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1361",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146499",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1387",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146500",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1411",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146501",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1403",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146502",
    "name": "HDFC FMP 1146D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1146D February 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "2019-02-20",
    "isin": "INF179KC1395",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146512",
    "name": "Nippon India Nifty Next 50 Junior BeES FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA NIFTY NEXT 50 JUNIOR BEES FOF - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-18",
    "closeData": "",
    "isin": "INF204KB1X17",
    "isinRe": "INF204KB1X09"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146513",
    "name": "Nippon India Nifty Next 50 Junior BeES FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Nifty Next 50 Junior BeES FoF - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-18",
    "closeData": "",
    "isin": "INF204KB1X25",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146514",
    "name": "Nippon India Nifty Next 50 Junior BeES FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Nifty Next 50 Junior BeES FoF - Growth Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-18",
    "closeData": "",
    "isin": "INF204KB1W91",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146515",
    "name": "Nippon India Nifty Next 50 Junior BeES FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA NIFTY NEXT 50 JUNIOR BEES FOF - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-18",
    "closeData": "",
    "isin": "INF204KB1X41",
    "isinRe": "INF204KB1X33"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "146516",
    "name": "IDBI Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Healthcare Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-02-08",
    "closeData": "",
    "isin": "INF397L01LH0",
    "isinRe": "INF397L01LI8"
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "146517",
    "name": "IDBI Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Healthcare Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-02-08",
    "closeData": "",
    "isin": "INF397L01LK4",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "146518",
    "name": "IDBI Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Healthcare Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-02-08",
    "closeData": "",
    "isin": "INF397L01LG2",
    "isinRe": null
  },
  {
    "amc": "IDBI Asset Management Ltd.",
    "code": "146519",
    "name": "IDBI Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "IDBI Healthcare Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-02-08",
    "closeData": "",
    "isin": "INF397L01LD9",
    "isinRe": "INF397L01LE7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146520",
    "name": "kotak FMP Series 262-1229 days",
    "category": "Income",
    "navName": "kotak FMP Series 262-Regular plan-Dividend Payout option",
    "minAmount": "200000000",
    "launchDate": "2019-02-11",
    "closeData": "",
    "isin": "INF174KA1CD2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146521",
    "name": "kotak FMP Series 262-1229 days",
    "category": "Income",
    "navName": "kotak FMP SEries 262-Direct Plan-Growth OPtion",
    "minAmount": "200000000",
    "launchDate": "2019-02-11",
    "closeData": "",
    "isin": "INF174KA1CE0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146522",
    "name": "kotak FMP Series 262-1229 days",
    "category": "Income",
    "navName": "koatk FMP Series 262-Direct paln -Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-02-11",
    "closeData": "",
    "isin": "INF174KA1CF7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146523",
    "name": "kotak FMP Series 262-1229 days",
    "category": "Income",
    "navName": "koatk FMP Series 262-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-02-11",
    "closeData": "",
    "isin": "INF174KA1CC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146530",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UP6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146531",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UK7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146532",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UN1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146533",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UL5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146534",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UO9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146535",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan F - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-14",
    "closeData": "",
    "isin": "INF109KC1UM3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146544",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VI (1167 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AHT2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146536",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VI (1167 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AHY2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146537",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VI (1167 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AIA0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146538",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AHX4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146539",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AHS4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146540",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VI (1167 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AIB8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146541",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VI (1167 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VI (1167 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-02-13",
    "closeData": "",
    "isin": "INF789F1AHZ9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146546",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 1)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 1) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-18",
    "closeData": "2019-02-28",
    "isin": "INF200KA1ZQ3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146547",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 1)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 1) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-18",
    "closeData": "2019-02-28",
    "isin": "INF200KA1ZR1",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "146548",
    "name": "Mahindra Manulife Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mahindra Manulife Large Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174V01713",
    "isinRe": "INF174V01705"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "146549",
    "name": "Mahindra Manulife Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mahindra Manulife Large Cap Fund - Direct Plan -Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174V01721",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "146550",
    "name": "Mahindra Manulife Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mahindra Manulife Large Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174V01747",
    "isinRe": "INF174V01739"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "146551",
    "name": "Mahindra Manulife Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Mahindra Manulife Large Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174V01697",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146552",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 18 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF204KB1X82",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146553",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 18 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF204KB1X74",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146554",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 18 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF204KB1X58",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146555",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 18",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 18 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF204KB1X66",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146574",
    "name": "Aditya Birla Sun Life Retirement Fund-The 30s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 30s Plan-Regular - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I07",
    "isinRe": "INF209KB1I15"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146575",
    "name": "Aditya Birla Sun Life Retirement Fund-The 30s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 30s Plan-Direct Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I23",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146576",
    "name": "Aditya Birla Sun Life Retirement Fund-The 30s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 30s Plan-Direct - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I31",
    "isinRe": "INF209KB1I49"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146577",
    "name": "Aditya Birla Sun Life Retirement Fund-The 30s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 30s Plan-Regular Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1H99",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146590",
    "name": "SBI Debt Fund Series C - 46 (376 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 46 (376 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-27",
    "closeData": "2019-03-05",
    "isin": "INF200KA19J9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146591",
    "name": "SBI Debt Fund Series C - 46 (376 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 46 (376 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-27",
    "closeData": "2019-03-05",
    "isin": "INF200KA12K2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146592",
    "name": "SBI Debt Fund Series C - 46 (376 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 46 (376 Days) - Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-27",
    "closeData": "2019-03-05",
    "isin": "INF200KA11K4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146593",
    "name": "SBI Debt Fund Series C - 46 (376 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 46 (376 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-02-27",
    "closeData": "2019-03-05",
    "isin": "INF200KA10K6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146594",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 19",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 19 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF204KB1Y24",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146595",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 19",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 19 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF204KB1X90",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146596",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 19",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XXXX - Series 19 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF204KB1Y16",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146597",
    "name": "Nippon India Fixed Horizon Fund - XXXX - Series 19",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XXXX - SERIES 19 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF204KB1Y08",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146598",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Regular Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CG5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146599",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Direct Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CJ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146600",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Regular Plan-Dividend Reinvestment",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CK7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146601",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Regular Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CH3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146602",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Direct Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CI1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146603",
    "name": "Kotak Quarterly Interval Plan Sr. 13",
    "category": "Income",
    "navName": "Kotak Quarterly Interval Plan Series13- Direct Plan- Dividend Reinvestment",
    "minAmount": "200000000",
    "launchDate": "2019-02-22",
    "closeData": "",
    "isin": "INF174KA1CL5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146604",
    "name": "Kotak FMP Series 263",
    "category": "Income",
    "navName": "Kotak FMP Series 263-1203 days- Regular plan- Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF174KA1CN1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146605",
    "name": "Kotak FMP Series 263",
    "category": "Income",
    "navName": "Kotak FMP Series 263-1203 days- Direct Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF174KA1CO9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146606",
    "name": "Kotak FMP Series 263",
    "category": "Income",
    "navName": "Kotak FMP Series 263-1203 days- Direct Plan-Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF174KA1CP6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146607",
    "name": "Kotak FMP Series 263",
    "category": "Income",
    "navName": "Kotak FMP Series 263-1203 days- Regular plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF174KA1CM3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146608",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIH5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146609",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIJ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146610",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AII3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146611",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIK9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146613",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIC6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146614",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIE2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146615",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIG7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146616",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AID4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146617",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VII (1155 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VII (1155 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-02-25",
    "closeData": "",
    "isin": "INF789F1AIF9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146627",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 20 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-06",
    "closeData": "",
    "isin": "INF204KB1Y65",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146628",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 20 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-06",
    "closeData": "",
    "isin": "INF204KB1Y57",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146629",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 20 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-06",
    "closeData": "",
    "isin": "INF204KB1Y40",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146630",
    "name": "Reliance Fixed Horizon Fund - XXXX - Series 20",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XXXX - Series 20 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-06",
    "closeData": "",
    "isin": "INF204KB1Y32",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146632",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1445",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146633",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1460",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146634",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1452",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146635",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1478",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146636",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1437",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146631",
    "name": "HDFC FMP 1133D February 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1133D February 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-02-28",
    "closeData": "2019-03-05",
    "isin": "INF179KC1429",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146641",
    "name": "SBI Equity Minimum Variance Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Equity Minimum Variance Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-02",
    "closeData": "2019-03-12",
    "isin": "INF200KA1A55",
    "isinRe": "INF200KA1A63"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146642",
    "name": "SBI Equity Minimum Variance Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Equity Minimum Variance Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-02",
    "closeData": "2019-03-12",
    "isin": "INF200KA1A89",
    "isinRe": "INF200KA1A97"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146643",
    "name": "SBI Equity Minimum Variance Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Equity Minimum Variance Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-02",
    "closeData": "2019-03-12",
    "isin": "INF200KA1A71",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146644",
    "name": "SBI Equity Minimum Variance Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "SBI Equity Minimum Variance Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-02",
    "closeData": "2019-03-12",
    "isin": "INF200KA1A48",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146647",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1528",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146648",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1536",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146649",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1510",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146650",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1486",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146645",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1494",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146646",
    "name": "HDFC FMP 1126D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1126D March 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-12",
    "isin": "INF179KC1502",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146655",
    "name": "SBI Debt Fund Series C - 48 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 48 (385 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-13",
    "isin": "INF200KA17K1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146656",
    "name": "SBI Debt Fund Series C - 48 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 48 (385 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-13",
    "isin": "INF200KA19K7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146657",
    "name": "SBI Debt Fund Series C - 48 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 48 (385 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-13",
    "isin": "INF200KA18K9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146658",
    "name": "SBI Debt Fund Series C - 48 (385 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 48 (385 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-06",
    "closeData": "2019-03-13",
    "isin": "INF200KA10L4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146659",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1UR2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146660",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1UU6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146661",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1UV4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146662",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1UQ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146663",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1UT8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146664",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1156 Days Plan G - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-04",
    "closeData": "",
    "isin": "INF109KC1US0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146665",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 6 - Plan C 1169 days - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SH2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146666",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 6 Plan C (1169 days)- Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SK6",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146667",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SL4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146668",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SG4",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146669",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans-Series 6 - Plan C 1169 days -QTR - IDCW ",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SI0",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "146670",
    "name": "Franklin India Fixed Maturity Plans - Series 6 Plan C (1169 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 6 Plan C (1169 days)- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF090I01SJ8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146671",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 1 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": "INF204KB1Z07",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146672",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 1 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": "INF204KB1Y99",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146673",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 1 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": "INF204KB1Y73",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146674",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 1",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 1 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": "INF204KB1Y81",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146677",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01N73",
    "isinRe": "INF846K01N81"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146678",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01O23",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146679",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01N99",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146680",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01O07",
    "isinRe": "INF846K01O15"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146681",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01O31",
    "isinRe": "INF846K01O49"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146682",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01O56",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146675",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01N65",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "146676",
    "name": "Axis Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Axis Overnight Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-03-07",
    "closeData": "2019-03-12",
    "isin": "INF846K01N40",
    "isinRe": "INF846K01N57"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146683",
    "name": "Kotak FMP Series 264-1200 days",
    "category": "Income",
    "navName": "Kotak FMP Series 264-1200 days- Direct plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF174KA1CT8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146684",
    "name": "Kotak FMP Series 264-1200 days",
    "category": "Income",
    "navName": "Kotak FMP Series 264-1200 days- Direct plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF174KA1CS0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146685",
    "name": "Kotak FMP Series 264-1200 days",
    "category": "Income",
    "navName": "Kotak FMP Series 264-1200 days- Regular plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF174KA1CQ4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146686",
    "name": "Kotak FMP Series 264-1200 days",
    "category": "Income",
    "navName": "Kotak FMP Series 264-1200 days- Regular plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF174KA1CR2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146695",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN Fixed Term Plan Series 179 REGULAR PLAN-GROWTH (3652 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1736",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146696",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS) - REGULAR PLAN - QUARTERLY DIVIDEND",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1744",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146697",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS) - REGULAR PLAN - HALF YEARLY DIVIDEND",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1751",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146698",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS) - DIRECT PLAN - QUARTERLY DIVIDEND",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1785",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146699",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN Fixed Term Plan Series 179 DIRECT PLAN HALF YEARLY-IDCW (3652 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1793",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146700",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS) - DIRECT PLAN - PERIODIC DIVIDEND",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1801",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146701",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN Fixed Term Plan Series 179 DIRECT PLAN-GROWTH (3652 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1777",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "146702",
    "name": "BANDHAN FIXED TERM PLAN SERIES - 179 (3652 DAYS)",
    "category": "Income",
    "navName": "BANDHAN Fixed Term Plan Series 179 REGULAR PLAN PERIODIC-IDCW (3652 days)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF194KB1769",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146704",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 2)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 2) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "2019-03-25",
    "isin": "INF200KA1B96",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146705",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 2)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 2) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-11",
    "closeData": "2019-03-25",
    "isin": "INF200KA1B88",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146711",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIM5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146713",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIT0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146714",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIQ6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146715",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIS2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146706",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIU8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146707",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIR4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146708",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIV6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146709",
    "name": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-VIII (1153 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-01",
    "closeData": "",
    "isin": "INF789F1AIP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146716",
    "name": "ICICI Prudential Retirement Fund Hybrid Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Conservative - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UA8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146717",
    "name": "ICICI Prudential Retirement Fund Hybrid Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Conservative - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TY0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146718",
    "name": "ICICI Prudential Retirement Fund Hybrid Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Conservative - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UB6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146719",
    "name": "ICICI Prudential Retirement Fund Hybrid Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Conservative - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TZ7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146720",
    "name": "ICICI Prudential Retirement Fund Hybrid Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Aggressive - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TX2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146721",
    "name": "ICICI Prudential Retirement Fund Hybrid Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Aggressive - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TW4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146722",
    "name": "ICICI Prudential Retirement Fund Hybrid Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Aggressive - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TU8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146723",
    "name": "ICICI Prudential Retirement Fund Hybrid Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Hybrid Aggressive - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1TV6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146724",
    "name": "ICICI Prudential Retirement Fund Pure Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Debt - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UD2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146725",
    "name": "ICICI Prudential Retirement Fund Pure Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Debt - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UF7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146726",
    "name": "ICICI Prudential Retirement Fund Pure Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Debt - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146727",
    "name": "ICICI Prudential Retirement Fund Pure Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "ICICI Prudential Retirement Fund - Pure Debt - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-02-07",
    "closeData": "",
    "isin": "INF109KC1UE0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146735",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF204KB1Z49",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146736",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 2 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF204KB1Z23",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146737",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 2 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF204KB1Z15",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146738",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 2",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF204KB1Z31",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146754",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146755",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146756",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146751",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146752",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146753",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1149 Days Plan H - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146757",
    "name": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146758",
    "name": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146759",
    "name": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146760",
    "name": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 92 Days Plan C - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146761",
    "name": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146762",
    "name": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146763",
    "name": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146764",
    "name": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 181 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146765",
    "name": "SBI Debt Fund Series C - 49 (390 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 49 (390 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-14",
    "closeData": "2019-03-19",
    "isin": "INF200KA13L8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146766",
    "name": "SBI Debt Fund Series C - 49 (390 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 49 (390 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-14",
    "closeData": "2019-03-19",
    "isin": "INF200KA14L6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146767",
    "name": "SBI Debt Fund Series C - 49 (390 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 49 (390 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-14",
    "closeData": "2019-03-19",
    "isin": "INF200KA12L0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146768",
    "name": "SBI Debt Fund Series C - 49 (390 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 49 (390 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-14",
    "closeData": "2019-03-19",
    "isin": "INF200KA11L2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146769",
    "name": "HSBC Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HSBC Large & Mid Cap Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF336L01NZ6",
    "isinRe": "INF336L01OA7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146770",
    "name": "HSBC Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HSBC Large & Mid Cap Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF336L01NW3",
    "isinRe": "INF336L01NX1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146771",
    "name": "HSBC Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HSBC Large & Mid Cap Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF336L01NY9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "146772",
    "name": "HSBC Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "HSBC Large & Mid Cap Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF336L01NV5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146773",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1551",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146774",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1569",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146775",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1585",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146776",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1593",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146777",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1544",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146778",
    "name": "HDFC FMP 1127D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1127D March 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "2019-03-18",
    "isin": "INF179KC1577",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146793",
    "name": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146794",
    "name": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146791",
    "name": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146792",
    "name": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 93 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146795",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VT6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146796",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VU4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146797",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VQ2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146798",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VV2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146799",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VS8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146800",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 10 Years Plan I - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF109KC1VR0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146801",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146802",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146803",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146804",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146805",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146806",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1141 Days Plan K - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146807",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VM1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146808",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146809",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VO7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146810",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VK5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146811",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VN9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146812",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1143 Days Plan J - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-13",
    "closeData": "",
    "isin": "INF109KC1VL3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146813",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146814",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146815",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146816",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146817",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146818",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1138 Days Plan L - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-14",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146821",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146822",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146823",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146824",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146819",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146820",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1130 Days Plan M - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146825",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146826",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146827",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146828",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146829",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146830",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1128 Days Plan N - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146831",
    "name": "Kotak FMP Series 265",
    "category": "Income",
    "navName": "kotak FMP SEries 265-Regular Plan-Dividend payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CV4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146832",
    "name": "Kotak FMP Series 265",
    "category": "Income",
    "navName": "Kotak FMP SEries 265-Direct Plan-Dividend payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CX0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146833",
    "name": "Kotak FMP Series 265",
    "category": "Income",
    "navName": "Kotak FMP Series 265-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CU6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146834",
    "name": "Kotak FMP Series 265",
    "category": "Income",
    "navName": "Kotak FMP Series 265-Direct Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CW2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146835",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Regular Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CZ5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146836",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Direct Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1DA6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146837",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Direct Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1DB4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146838",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Regular Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1CY8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146839",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Direct Plan- Dividend Reinvestment",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1DD0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146840",
    "name": "Kotak Monthly Interval Plan Series 4",
    "category": "Income",
    "navName": "Kotak Monthly Interval Plan Series 4-Regular Plan- Dividend Reinvestment",
    "minAmount": "200000000",
    "launchDate": "2019-03-11",
    "closeData": "",
    "isin": "INF174KA1DC2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146841",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 3 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB1Z56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146842",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 3 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB1Z64",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146843",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 3 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB1Z80",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146844",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 3",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 3 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB1Z56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146845",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 4 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB12A3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146846",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 4 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB1Z98",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146847",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 4 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB11A5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146848",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 4 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF204KB10A7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146857",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AJC4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146858",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AJE0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146865",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AIZ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146859",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AJF7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146860",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AIY0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146861",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AIX2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146862",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AIW4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146863",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AJB6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146864",
    "name": "UTI - Fixed Term Income Fund Series XXXI-IX (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-IX (1168 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-12",
    "closeData": "",
    "isin": "INF789F1AJD2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146867",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-X (1168 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJM3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146871",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-X (1168 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJH3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146872",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJL5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146873",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-X (1168 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJO9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146874",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-X (1168 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJN1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146876",
    "name": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-X (1168 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF789F1AJG5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146877",
    "name": "SBI Debt Fund Series C - 50 (383 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 50 (383 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "2019-03-27",
    "isin": "INF200KA15L3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146878",
    "name": "SBI Debt Fund Series C - 50 (383 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series C - 50 (383 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "2019-03-27",
    "isin": "INF200KA17L9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146879",
    "name": "SBI Debt Fund Series C - 50 (383 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 50 (383 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "2019-03-27",
    "isin": "INF200KA18L7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146880",
    "name": "SBI Debt Fund Series C - 50 (383 Days)",
    "category": "Income",
    "navName": "SBI Debt Fund Series - C - 50 (383 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "2019-03-27",
    "isin": "INF200KA16L1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146889",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1619",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146890",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1627",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146891",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1643",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146892",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1635",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146893",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1601",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146894",
    "name": "HDFC FMP 1120D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1120D March 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "2019-03-25",
    "isin": "INF179KC1650",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146895",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": "INF179KC1676",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146896",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": "INF179KC1692",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146897",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": "INF179KC1718",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146898",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": "INF179KC1668",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146899",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019(1)-Direct Plan-Quarterly Div Option",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "146900",
    "name": "HDFC FMP 1118D March 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1118D March 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-18",
    "closeData": "2019-03-27",
    "isin": "INF179KC1700",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146911",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1WY4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146912",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1XB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146913",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1WZ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146914",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1XC8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146915",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1XA2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146916",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan O - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": "INF109KC1XD6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146917",
    "name": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F - Direct Plan - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146918",
    "name": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F - Direct Plan - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146919",
    "name": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146920",
    "name": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Interval Fund - Series IX - 185 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146921",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XM7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146922",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XK1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146923",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XN5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146924",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XL9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146925",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XJ3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146926",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1129 Days Plan P - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XI5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146927",
    "name": "Aditya Birla Sun Life Retirement Fund-The 40s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 40s Plan- Direct - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I98",
    "isinRe": "INF209KB1J06"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146928",
    "name": "Aditya Birla Sun Life Retirement Fund-The 40s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 40s Plan-Regular - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I64",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146929",
    "name": "Aditya Birla Sun Life Retirement Fund-The 40s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 40s Plan-Regular Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I56",
    "isinRe": "INF209KB1I72"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146930",
    "name": "Aditya Birla Sun Life Retirement Fund-The 40s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 40s Plan-Direct Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1I80",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146931",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XR6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146932",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XQ8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146933",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XP0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146934",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XS4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146935",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XO3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146936",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1127 Days Plan Q - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-20",
    "closeData": "",
    "isin": "INF109KC1XT2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146937",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plan- Direct - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J55",
    "isinRe": "INF209KB1J63"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146938",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plan-Regular Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J14",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146939",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plan- Regular - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J22",
    "isinRe": "INF209KB1J30"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146940",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plan-Direct Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J48",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146941",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan-Regular Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J71",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146942",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan - Regular - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1J89",
    "isinRe": "INF209KB1J97"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146943",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan-Direct Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1K03",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "146944",
    "name": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Aditya Birla Sun Life Retirement Fund-The 50s Plus-Debt Plan - Direct - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-02-19",
    "closeData": "",
    "isin": "INF209KB1K11",
    "isinRe": "INF209KB1K29"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146949",
    "name": "ICICI Prudential Bharat Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Bharat Consumption Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF109KC1YF9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146950",
    "name": "ICICI Prudential Bharat Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Bharat Consumption Fund - Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF109KC1YA0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146951",
    "name": "ICICI Prudential Bharat Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Bharat Consumption Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF109KC1YD4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146952",
    "name": "ICICI Prudential Bharat Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Bharat Consumption Fund - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF109KC1YC6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146953",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HC4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146954",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Weekly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HD2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146955",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Weekly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HK7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146956",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Fortnightly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HL5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146957",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Monthly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HM3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146958",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Fortnightly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HE0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149818",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Unclaimed Redemption upto three years",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1LA0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149819",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Unclaimed Redemption  beyond three years",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1KY2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149820",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Unclaimed IDCW beyond three years",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1KX4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149821",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Unclaimed IDCW upto three years",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1KZ9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146959",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1GZ7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146960",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Monthly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HA8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146961",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Regular Plan - Monthly Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HF7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146962",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Monthly Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HH3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146963",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HG5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "146964",
    "name": "Sundaram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Sundaram Overnight Fund Direct Plan - Daily Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "5000",
    "launchDate": "2019-03-15",
    "closeData": "",
    "isin": "INF903JA1HJ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146965",
    "name": "Kotak FMP Series 266-1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 266- Regular Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-18",
    "closeData": "",
    "isin": "INF174KA1DF5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146966",
    "name": "Kotak FMP Series 266-1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 266- Direct Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-18",
    "closeData": "",
    "isin": "INF174KA1DH1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146967",
    "name": "Kotak FMP Series 266-1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 266- Regular Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-18",
    "closeData": "",
    "isin": "INF174KA1DE8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "146968",
    "name": "Kotak FMP Series 266-1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 266- 1188 - Direct Plan- Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-18",
    "closeData": "",
    "isin": "INF174KA1DG3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146969",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF204KB15A6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146970",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 5 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF204KB16A4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146971",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 5 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF204KB14A9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "146972",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 5",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 5 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF204KB13A1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146973",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-26",
    "closeData": "2019-03-27",
    "isin": "INF200KA1C95",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146974",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-26",
    "closeData": "2019-03-27",
    "isin": "INF200KA1D03",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146975",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-26",
    "closeData": "2019-03-27",
    "isin": "INF200KA1D11",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "146976",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 1 (3668 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-03-26",
    "closeData": "2019-03-27",
    "isin": "INF200KA1C87",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146977",
    "name": "Tata Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Tata Overnight Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF277K016T9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146978",
    "name": "Tata Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TATA Overnight Fund Direct Plan - Daily Reinvestment of Income Distribution cum capital withdrawal option   ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF277K019T3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146979",
    "name": "Tata Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TATA Overnight Fund Regular Plan - Daily Reinvestment of Income Distribution cum capital withdrawal option   ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF277K017T7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "146980",
    "name": "Tata Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Tata Overnight Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF277K018T5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146981",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XU0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146982",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XX4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146983",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XV8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146984",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XY2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146985",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XW6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "146986",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1140 Days Plan R - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-21",
    "closeData": "",
    "isin": "INF109KC1XZ9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146987",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJV4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146988",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJW2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146989",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJY8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146990",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJQ4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146991",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJR2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146994",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJT8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146995",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJX0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "146996",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XI (1169 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XI (1169 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-03-22",
    "closeData": "",
    "isin": "INF789F1AJS0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146997",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FJ3",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146998",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FK1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147005",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Direct Plan - Fortnightly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FF1",
    "isinRe": "INF582M01FD6"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147006",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FG9",
    "isinRe": "INF582M01FE4"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149451",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Unclaimed Amounts Plan - IDCW Beyond 3 years",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149452",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Unclaimed Amounts Plan - IDCW Upto 3 years",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149453",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Unclaimed Amounts Plan - Redemption Beyond 3 years",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149454",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Unclaimed Amounts Plan - Redemption Upto 3 years",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "146999",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Regular Plan - Weekly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FL9",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147000",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FP0",
    "isinRe": "INF582M01FN5"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147001",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Regular Plan - Fortnightly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FO3",
    "isinRe": "INF582M01FM7"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147002",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FB0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147003",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FA2",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147004",
    "name": "Union Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Union Overnight Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF582M01FC8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147019",
    "name": "Kotak FMP Series 267",
    "category": "Income",
    "navName": "Kotak FMP series 267-Regular Plan-Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "200000000",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF174KA1DJ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147020",
    "name": "Kotak FMP Series 267",
    "category": "Income",
    "navName": "Kotak FMP Series 267-Direct PLan-Dividend Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF174KA1DL3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147021",
    "name": "Kotak FMP Series 267",
    "category": "Income",
    "navName": "Kotak FMP Series 267-Direct PLan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF174KA1DK5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147022",
    "name": "Kotak FMP Series 267",
    "category": "Income",
    "navName": "Kotak FMP Series 267-Regular Plan-Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-03-25",
    "closeData": "",
    "isin": "INF174KA1DI9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147023",
    "name": "Kotak FMP Series 268-1174 days",
    "category": "Income",
    "navName": "Kotak FMP Series 268-1174 days-Regular Plan-Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF174KA1DM1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147024",
    "name": "Kotak FMP Series 268-1174 days",
    "category": "Income",
    "navName": "Kotak FMP Series 268- 1174 Days- Regular Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF174KA1DN9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147025",
    "name": "Kotak FMP Series 268-1174 days",
    "category": "Income",
    "navName": "Kotak FMP Series 268-1174 days-Direct Plan Growth",
    "minAmount": "200000000",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF174KA1DO7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147026",
    "name": "Kotak FMP Series 268-1174 days",
    "category": "Income",
    "navName": "Kotak FMP Series 268-1174 days- Direct Plan- Dividend Payout",
    "minAmount": "200000000",
    "launchDate": "2019-03-26",
    "closeData": "",
    "isin": "INF174KA1DP4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147027",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147028",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147029",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147030",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147031",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147032",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1122 Days Plan S - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147034",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147035",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147036",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147037",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147038",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147033",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1121 Days Plan T - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-03-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147039",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 6 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB17A2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147040",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 6 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB18A0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147041",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB19A8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147042",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 6",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 6 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB10B5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147043",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB11B3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147044",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 7 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB12B1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147045",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 7 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB14B7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147046",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 7",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 7 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": "INF204KB13B9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147052",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147047",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147048",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147049",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147050",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147051",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1115 Days Plan U - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147053",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147054",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147055",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147056",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147057",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147058",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan V - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147059",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147060",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147061",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147062",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147063",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147064",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1114 Days Plan W - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147065",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147066",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147067",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147068",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147069",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147070",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1109 Days Plan X - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147071",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKF5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147072",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XII (1148 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKH1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147073",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XII (1148 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKG3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147076",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKA6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147077",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XII (1148 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKC2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147078",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XII (1148 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKB4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147079",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XII (1148 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XII (1148 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-02",
    "closeData": "",
    "isin": "INF789F1AKD0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147095",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 8 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": "INF204KB18B8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147096",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 8",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 8 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": "INF204KB16B2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147097",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 8 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": "INF204KB15B4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147098",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 8",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 8 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": "INF204KB17B0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147099",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 9 - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-04-11",
    "closeData": "",
    "isin": "INF204KB10C3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147100",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 9 - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-04-11",
    "closeData": "",
    "isin": "INF204KB19B6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147101",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 9 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-04-11",
    "closeData": "",
    "isin": "INF204KB11C1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147102",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 9",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 9 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2019-04-11",
    "closeData": "",
    "isin": "INF204KB12C9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147103",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-09",
    "closeData": "2019-04-15",
    "isin": "INF200KA1D29",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147104",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-09",
    "closeData": "2019-04-15",
    "isin": "INF200KA1D45",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147105",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-09",
    "closeData": "2019-04-15",
    "isin": "INF200KA1D52",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147106",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 2 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-09",
    "closeData": "2019-04-15",
    "isin": "INF200KA1D37",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147107",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147108",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147109",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147110",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147111",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147112",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1102 Days Plan Y - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-09",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147113",
    "name": "Groww Nifty50 Exchange Traded Fund (formerly known as Indiabulls Nifty50 Exchange Traded Fund)",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Groww Nifty50 Exchange Traded Fund (formerly known as Indiabulls Nifty50 Exchange Traded) Fund",
    "minAmount": "Through AMC  50000 units Through Exchange  1 unit",
    "launchDate": "2019-04-08",
    "closeData": "",
    "isin": "INF666M01FS5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147133",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-16",
    "closeData": "2019-04-22",
    "isin": "INF200KA1D60",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147134",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-16",
    "closeData": "2019-04-22",
    "isin": "INF200KA1D78",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147135",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-16",
    "closeData": "2019-04-22",
    "isin": "INF200KA1D86",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147136",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 3 (1179 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-04-16",
    "closeData": "2019-04-22",
    "isin": "INF200KA1D94",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147137",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A21",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147138",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A54",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147139",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A39",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147140",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A62",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147141",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A70",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147142",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan A - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-18",
    "closeData": "",
    "isin": "INF109KC1A47",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147143",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147144",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147145",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147146",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147147",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147148",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 85 - 1111 Days Plan Z - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147149",
    "name": "HSBC Fixed Term Series 140 (1147 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 140 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": "INF336L01OD1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147150",
    "name": "HSBC Fixed Term Series 140 (1147 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 140 - IDCW Direct Plan - Tenure 1147 Days - Maturity  15-Jun-2022",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": "INF336L01OC3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147151",
    "name": "HSBC Fixed Term Series 140 (1147 days plan)",
    "category": "Income",
    "navName": "HSBC FTS 140 - IDCW - Tenure 1147 Days - Maturity  15-Jun-2022",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": "INF336L01OE9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147152",
    "name": "HSBC Fixed Term Series 140 (1147 days plan)",
    "category": "Income",
    "navName": "HSBC Fixed Term Series 140 Direct plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-04-16",
    "closeData": "",
    "isin": "INF336L01OB5",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147160",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Fortnightly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01341",
    "isinRe": "INF00XX01317"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147161",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Annually IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01366",
    "isinRe": "INF00XX01333"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147162",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Annually IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01275",
    "isinRe": "INF00XX01242"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147163",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01358",
    "isinRe": "INF00XX01325"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147164",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Weekly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01218",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147154",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01200",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147155",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Fortnightly Income Distribution cum capital withdrawal option Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01259",
    "isinRe": "INF00XX01226"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147156",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01267",
    "isinRe": "INF00XX01234"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147157",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01283",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147158",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01291",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147159",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01309",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147153",
    "name": "ITI Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "ITI Liquid Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "2019-04-24",
    "isin": "INF00XX01192",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147174",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKN9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147165",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKP4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147166",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKR0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147168",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKK5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147169",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKL3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147172",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIII (1127 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIII (1127 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-04-22",
    "closeData": "",
    "isin": "INF789F1AKQ2",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147183",
    "name": "ITI Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ITI Multi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-04-25",
    "closeData": "2019-05-09",
    "isin": "INF00XX01168",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147184",
    "name": "ITI Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ITI Multi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-04-25",
    "closeData": "2019-05-09",
    "isin": "INF00XX01135",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147185",
    "name": "ITI Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ITI Multi Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-04-25",
    "closeData": "2019-05-09",
    "isin": "INF00XX01143",
    "isinRe": "INF00XX01150"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147186",
    "name": "ITI Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "ITI Multi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-04-25",
    "closeData": "2019-05-09",
    "isin": "INF00XX01176",
    "isinRe": "INF00XX01184"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147187",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147188",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147189",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147190",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147191",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147192",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1099 Days Plan B - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-04-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147193",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HS0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147194",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Regular Plan - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HT8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149988",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Plan C - Unclaimed IDCW - Greater than 3 years",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149989",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Plan C - Unclaimed Redemption - Up to 3 years",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149990",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Plan C - Unclaimed Redemption - Greater than 3 years",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149991",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Plan C - Unclaimed IDCW - Up to 3 years",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147195",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Direct Plan - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HX0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147196",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HV4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147197",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HW2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147198",
    "name": "Baroda BNP Paribas Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Baroda BNP Paribas Overnight Fund - Regular Plan - Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF955L01HU6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147205",
    "name": "Mirae Asset Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mirae Asset Focused Fund Direct IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF769K01ET2",
    "isinRe": "INF769K01EW6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147206",
    "name": "Mirae Asset Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mirae Asset Focused Fund Direct Plan Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF769K01EU0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147203",
    "name": "Mirae Asset Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mirae Asset Focused Fund Regular Plan Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF769K01ES4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147204",
    "name": "Mirae Asset Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mirae Asset Focused Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-04-23",
    "closeData": "",
    "isin": "INF769K01ER6",
    "isinRe": "INF769K01EV8"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147211",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund - Direct Weekly Dividend Plan - Weekly - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01SR1",
    "isinRe": "INF090I01SQ3"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147212",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund - Direct Daily Dividend Plan - Daily - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01ST7",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147213",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight fund- Growth",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01SO8",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147214",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01SS9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147215",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund - Daily Dividend Plan - Daily - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01SM2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147216",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund - Weekly Dividend Plan - Weekly - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": "INF090I01SN0",
    "isinRe": "INF090I01SP5"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "149443",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund- Unclaimed IDCW Plan",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "149444",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund- Unclaimed Redemption Investor EducationPlan",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "149445",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund- Unclaimed IDCW Investor Education Plan",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "149446",
    "name": "Franklin India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Franklin India Overnight Fund- Unclaimed Redemption Plan",
    "minAmount": "5000",
    "launchDate": "2019-05-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147230",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147231",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147232",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147227",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147228",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147229",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1113 Days Plan C - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-02",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147235",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1940",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147236",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1932",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147237",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1908",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147238",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1924",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147233",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1916",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "147234",
    "name": "HDFC FMP 1100D April 2019 (1)",
    "category": "Income",
    "navName": "HDFC FMP 1100D April 2019 (1) - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-04-30",
    "closeData": "2019-05-07",
    "isin": "INF179KC1957",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147240",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 3)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 3) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-02",
    "closeData": "2019-05-16",
    "isin": "INF200KA1E44",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147242",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 3)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 3) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-02",
    "closeData": "2019-05-16",
    "isin": "INF200KA1E51",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147247",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-09",
    "closeData": "2019-05-15",
    "isin": "INF200KA1F01",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147248",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-09",
    "closeData": "2019-05-15",
    "isin": "INF200KA1F27",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147249",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-09",
    "closeData": "2019-05-15",
    "isin": "INF200KA1F35",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147250",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 6 (3668 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-09",
    "closeData": "2019-05-15",
    "isin": "INF200KA1F19",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147263",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKY6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147264",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKX8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147255",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKZ3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147256",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1ALA4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147257",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKU4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147259",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1ALC0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147261",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKW0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147262",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XIV (1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XIV (1111 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2019-05-08",
    "closeData": "",
    "isin": "INF789F1AKV2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147265",
    "name": "KOTAK FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "KOTAK FLOATING RATE FUND-REGULAR PLAN-STANDARD Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-05-09",
    "closeData": "",
    "isin": "INF174KA1EB2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147266",
    "name": "KOTAK FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "KOTAK FLOATING RATE FUND-REGULAR PLAN-GROWTH OPTION",
    "minAmount": "200000000",
    "launchDate": "2019-05-09",
    "closeData": "",
    "isin": "INF174KA1DY6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147267",
    "name": "KOTAK FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "KOTAK FLOATING RATE FUND-DIRECT PLAN-Standard Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "200000000",
    "launchDate": "2019-05-09",
    "closeData": "",
    "isin": "INF174KA1EF3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147269",
    "name": "KOTAK FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "KOTAK FLOATING RATE FUND-DIRECT PLAN-GROWTH OPTION",
    "minAmount": "200000000",
    "launchDate": "2019-05-09",
    "closeData": "",
    "isin": "INF174KA1EC0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147281",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147282",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147277",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147278",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147279",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147280",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1106 Days Plan D - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147283",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147284",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147285",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E - Direct Plan Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147286",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 95 Days Plan E - Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-15",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147287",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OF6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147288",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OP5",
    "isinRe": "INF336L01ON0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150499",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Unclaimed IDCW Below three years",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150500",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Unclaimed Redemption above three years",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150501",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Unclaimed IDCW Above three years",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150502",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Unclaimed Redemption Below three years",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147289",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Regular Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OM2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147290",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OL4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147291",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Direct Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OG4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147296",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OJ8",
    "isinRe": "INF336L01OH2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147300",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OK6",
    "isinRe": "INF336L01OI0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147301",
    "name": "HSBC Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "HSBC Overnight Fund - Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-05-21",
    "closeData": "",
    "isin": "INF336L01OQ3",
    "isinRe": "INF336L01OO8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "147303",
    "name": "DSP Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Quant Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2019-05-20",
    "closeData": "2019-06-03",
    "isin": "INF740KA1NN3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "147304",
    "name": "DSP Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Quant Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2019-05-20",
    "closeData": "2019-06-03",
    "isin": "INF740KA1NR4",
    "isinRe": "INF740KA1NS2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "147305",
    "name": "DSP Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Quant Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2019-05-20",
    "closeData": "2019-06-03",
    "isin": "INF740KA1NO1",
    "isinRe": "INF740KA1NP8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "147306",
    "name": "DSP Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "DSP Quant Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2019-05-20",
    "closeData": "2019-06-03",
    "isin": "INF740KA1NQ6",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147307",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Regular plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01282",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147308",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Regular plan-Daily Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01258",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147315",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Direct plan-Fortnightly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01183",
    "isinRe": "INF03VN01191"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147316",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Direct plan-Daily Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01175",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147309",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Regular plan-Fortnightly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01266",
    "isinRe": "INF03VN01274"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147310",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Regular plan-Weekly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01316",
    "isinRe": "INF03VN01324"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147311",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Direct plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01209",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147312",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Direct plan-Monthly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01217",
    "isinRe": "INF03VN01225"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147313",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Direct plan-Weekly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01233",
    "isinRe": "INF03VN01241"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "147314",
    "name": "WhiteOak Capital Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "WhiteOak Capital Ultra Short Term Fund- Regular plan-Monthly Payout/ Reinvestment of Income Distribution cum capital withdrawal option (IDCW)",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-05-24",
    "closeData": "",
    "isin": "INF03VN01290",
    "isinRe": "INF03VN01308"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147317",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-17",
    "closeData": "2019-05-22",
    "isin": "INF200KA1F50",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147318",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-17",
    "closeData": "2019-05-22",
    "isin": "INF200KA1F68",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147319",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-17",
    "closeData": "2019-05-22",
    "isin": "INF200KA1F76",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147320",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 7 (1175 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-17",
    "closeData": "2019-05-22",
    "isin": "INF200KA1F43",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147326",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALH9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147327",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALI7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147328",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALE6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147329",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALG1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147330",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALF3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147321",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALL1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147322",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALJ5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147323",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALK3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147324",
    "name": "UTI - Fixed Term Income Fund Series XXXI-XV (1099 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXI-XV (1099 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 1",
    "launchDate": "2019-05-20",
    "closeData": "",
    "isin": "INF789F1ALM9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147331",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-28",
    "closeData": "2019-06-03",
    "isin": "INF200KA1F84",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147332",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-28",
    "closeData": "2019-06-03",
    "isin": "INF200KA1F92",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147333",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-28",
    "closeData": "2019-06-03",
    "isin": "INF200KA1G00",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147334",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 8 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-05-28",
    "closeData": "2019-06-03",
    "isin": "INF200KA1G18",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147339",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147340",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147341",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147342",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147343",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147344",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1120 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-05-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147345",
    "name": "ICICI Prudential MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential MNC Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-05-28",
    "closeData": "",
    "isin": "INF109KC1D69",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147346",
    "name": "ICICI Prudential MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential MNC Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-05-28",
    "closeData": "",
    "isin": "INF109KC1D93",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147347",
    "name": "ICICI Prudential MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential MNC Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-05-28",
    "closeData": "",
    "isin": "INF109KC1E01",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147348",
    "name": "ICICI Prudential MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential MNC Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-05-28",
    "closeData": "",
    "isin": "INF109KC1D77",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147351",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 10 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-03",
    "closeData": "",
    "isin": "INF204KB13C7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147352",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 10 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-03",
    "closeData": "",
    "isin": "INF204KB16C0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147353",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 10",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 10 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-03",
    "closeData": "",
    "isin": "INF204KB15C2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147354",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 10",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 10 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-03",
    "closeData": "",
    "isin": "INF204KB14C5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147358",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 11 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-07",
    "closeData": "",
    "isin": "INF204KB10D1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147355",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 11 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-07",
    "closeData": "",
    "isin": "INF204KB19C4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147356",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 11",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 11 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-07",
    "closeData": "",
    "isin": "INF204KB18C6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147357",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 11",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 11 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-07",
    "closeData": "",
    "isin": "INF204KB17C8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147359",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 4)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 4) - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-04",
    "closeData": "2019-06-18",
    "isin": "INF200KA1G34",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147360",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 4)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 4) - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-04",
    "closeData": "2019-06-18",
    "isin": "INF200KA1G26",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147368",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALP2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147369",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALX6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147362",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Direct Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALV0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147363",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXII-I (1126 days) Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALT4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147364",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXII-I (1126 days) Regular Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALO5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147365",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALW8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147366",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALR8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147367",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALU2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147361",
    "name": "UTI - Fixed Term Income Fund Series XXXII-I(1126 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-I(1126 Days) - Regular Plan - Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2019-06-04",
    "closeData": "",
    "isin": "INF789F1ALQ0",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "147371",
    "name": "Quantum India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "QUANTUM INDIA ESG EQUITY FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "Rs. 500/- and in Multiples of Rs. 1/- thereafter",
    "launchDate": "2019-06-21",
    "closeData": "",
    "isin": "INF082J01390",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "147372",
    "name": "Quantum India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "QUANTUM INDIA ESG EQUITY FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "Rs. 500/- and in Multiples of Rs. 1/- thereafter",
    "launchDate": "2019-06-21",
    "closeData": "",
    "isin": "INF082J01382",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "147403",
    "name": "Shriram Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Shriram Balanced Advantage Fund - Direct- IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 500/- therea",
    "launchDate": "2019-06-14",
    "closeData": "",
    "isin": "INF680P01216",
    "isinRe": "INF680P01224"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "147404",
    "name": "Shriram Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Shriram Balanced Advantage Fund - Regular-IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 500/- therea",
    "launchDate": "2019-06-14",
    "closeData": "",
    "isin": "INF680P01182",
    "isinRe": "INF680P01190"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "147405",
    "name": "Shriram Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Shriram Balanced Advantage Fund - Regular Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 500/- therea",
    "launchDate": "2019-06-14",
    "closeData": "",
    "isin": "INF680P01174",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "147406",
    "name": "Shriram Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Shriram Balanced Advantage Fund - Direct Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 500/- therea",
    "launchDate": "2019-06-14",
    "closeData": "",
    "isin": "INF680P01208",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147407",
    "name": "Aditya Birla Sun Life Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Pharma and Healthcare Fund-Regular-Growth",
    "minAmount": "1000",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF209KB1N91",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147408",
    "name": "Aditya Birla Sun Life Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Pharma and Healthcare Fund-Regular - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF209KB1O09",
    "isinRe": "INF209KB1O17"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147409",
    "name": "Aditya Birla Sun Life Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Pharma and Healthcare Fund-Direct-Growth",
    "minAmount": "1000",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF209KB1O25",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147410",
    "name": "Aditya Birla Sun Life Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Pharma and Healthcare Fund-Direct - Payout of IDCW",
    "minAmount": "1000",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF209KB1O33",
    "isinRe": "INF209KB1O41"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147411",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXII-II (1111 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMD6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147412",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AME4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147414",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMF1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147415",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMH7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147416",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMA2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147417",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1ALZ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147418",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMB0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147419",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXII-II(1111 Days) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1AMC8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147420",
    "name": "UTI - Fixed Term Income Fund Series XXXII-II(1111 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXII-II (1111 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2019-06-19",
    "closeData": "",
    "isin": "INF789F1ALY4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147421",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-19",
    "closeData": "2019-06-24",
    "isin": "INF200KA1G59",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147422",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-19",
    "closeData": "2019-06-24",
    "isin": "INF200KA1G67",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147423",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-19",
    "closeData": "2019-06-24",
    "isin": "INF200KA1G42",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147424",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 9 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-19",
    "closeData": "2019-06-24",
    "isin": "INF200KA1G75",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147445",
    "name": "Mirae Asset Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mirae Asset Midcap Fund- Direct Growth Option",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-07-08",
    "closeData": "",
    "isin": "INF769K01FA9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147478",
    "name": "Mirae Asset Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mirae Asset Midcap Fund Direct IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-07-08",
    "closeData": "",
    "isin": "INF769K01EZ9",
    "isinRe": "INF769K01FC5"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147479",
    "name": "Mirae Asset Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mirae Asset Midcap Fund - Regular Plan-Growth Option",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-07-08",
    "closeData": "",
    "isin": "INF769K01EY2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147480",
    "name": "Mirae Asset Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Mirae Asset Midcap Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2019-07-08",
    "closeData": "",
    "isin": "INF769K01EX4",
    "isinRe": "INF769K01FB7"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147446",
    "name": "Mahindra Manulife Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mahindra Manulife Aggressive Hybrid Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-28",
    "closeData": "",
    "isin": "INF174V01788",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147447",
    "name": "Mahindra Manulife Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mahindra Manulife Aggressive Hybrid Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-28",
    "closeData": "",
    "isin": "INF174V01754",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147448",
    "name": "Mahindra Manulife Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mahindra Manulife Aggressive Hybrid Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-28",
    "closeData": "",
    "isin": "INF174V01804",
    "isinRe": "INF174V01796"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147449",
    "name": "Mahindra Manulife Aggressive Hybrid Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Mahindra Manulife Aggressive Hybrid Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-28",
    "closeData": "",
    "isin": "INF174V01770",
    "isinRe": "INF174V01762"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147450",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Direct Plan- Growth Option",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GL8",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147451",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Direct Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GN4",
    "isinRe": "INF666M01GO2"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147452",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Direct Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GP9",
    "isinRe": "INF666M01GQ7"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147453",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Direct Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GR5",
    "isinRe": "INF666M01GS3"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147454",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Regular Plan- Growth Option",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GT1",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147455",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Regular Plan- - Income Distribution cum capital withdrawal Option ( Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GU9",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "149395",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Unclaimed Redemption > 3 Years",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "149396",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Unclaimed Redemption < 3 Years",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147456",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Regular Plan- Weekly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GV7",
    "isinRe": "INF666M01GW5"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147457",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Regular Plan- Fortnightly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GX3",
    "isinRe": "INF666M01GY1"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147458",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Direct Plan- - Income Distribution cum capital withdrawal Option ( Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GM6",
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "147459",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Regular Plan- Monthly - Income Distribution cum capital withdrawal Option (Payout & Reinvestment)",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": "INF666M01GZ8",
    "isinRe": "INF666M01HA9"
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "149393",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Unclaimed Dividend > 3 Years",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Groww Asset Management Limited",
    "code": "149394",
    "name": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Groww Overnight Fund (formerly known as Indiabulls Overnight Fund)- Unclaimed Dividend < 3 years",
    "minAmount": "Rs.500/- and in multiples of Re. 1 thereafter",
    "launchDate": "2019-06-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147460",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 12 - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF204KB12D7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147461",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 12",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLI - SERIES 12 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF204KB14D3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147462",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 12 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF204KB11D9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147463",
    "name": "Nippon India Fixed Horizon Fund - XLI - Series 12",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLI - Series 12 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF204KB13D5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147464",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-26",
    "closeData": "2019-07-01",
    "isin": "INF200KA1H17",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147465",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-26",
    "closeData": "2019-07-01",
    "isin": "INF200KA1G83",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147466",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-26",
    "closeData": "2019-07-01",
    "isin": "INF200KA1G91",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147467",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 10 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-06-26",
    "closeData": "2019-07-01",
    "isin": "INF200KA1H09",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147468",
    "name": "Kotak FMP Series 272-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 272- Regular Plan- Growth option",
    "minAmount": "200000000",
    "launchDate": "2019-06-24",
    "closeData": "",
    "isin": "INF174KA1EG1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147469",
    "name": "Kotak FMP Series 272-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 272- Direct Plan- Growth Option",
    "minAmount": "200000000",
    "launchDate": "2019-06-24",
    "closeData": "",
    "isin": "INF174KA1EI7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147470",
    "name": "Kotak FMP Series 272-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 272- Direct Plan- Direct option",
    "minAmount": "200000000",
    "launchDate": "2019-06-24",
    "closeData": "",
    "isin": "INF174KA1EJ5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147471",
    "name": "Kotak FMP Series 272-1263 days",
    "category": "Income",
    "navName": "Kotak FMP Series 272-Regular Plan-Dividend Payout Option",
    "minAmount": "200000000",
    "launchDate": "2019-06-24",
    "closeData": "",
    "isin": "INF174KA1EH9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147472",
    "name": "Kotak Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Kotak Focused Equity Fund- Regular Plan-Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF174KA1EM9",
    "isinRe": "INF174KA1EL1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147473",
    "name": "Kotak Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Kotak Focused equity Fund- Direct Plan- Growth option",
    "minAmount": "5000",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF174KA1EN7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147475",
    "name": "Kotak Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Kotak Focused Equity Fund-Direct Plan- Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF174KA1EP2",
    "isinRe": "INF174KA1EO5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147477",
    "name": "Kotak Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Kotak Focused Equity Fund- Regular plan   Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-06-25",
    "closeData": "",
    "isin": "INF174KA1EK3",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "147481",
    "name": "Parag Parikh Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Parag Parikh Tax Saver Fund- Direct Growth",
    "minAmount": "500",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF879O01100",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "147482",
    "name": "Parag Parikh Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Parag Parikh Tax Saver Fund- Regular Growth",
    "minAmount": "500",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF879O01092",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147483",
    "name": "ICICI Prudential Nifty Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Bank ETF",
    "minAmount": "5000",
    "launchDate": "2019-07-03",
    "closeData": "",
    "isin": "INF109KC15I8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147484",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-03",
    "closeData": "2019-07-08",
    "isin": "INF200KA1H25",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147485",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-03",
    "closeData": "2019-07-08",
    "isin": "INF200KA1H33",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147486",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-03",
    "closeData": "2019-07-08",
    "isin": "INF200KA1H41",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147487",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 11 (1178 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-03",
    "closeData": "2019-07-08",
    "isin": "INF200KA1H58",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147490",
    "name": "Union Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Union Focused Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF582M01FW6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147491",
    "name": "Union Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Union Focused Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF582M01FU0",
    "isinRe": "INF582M01FT2"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147492",
    "name": "Union Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Union Focused Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF582M01FS4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147493",
    "name": "Union Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Union Focused Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF582M01FY2",
    "isinRe": "INF582M01FX4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147494",
    "name": "Baroda BNP Paribas Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Baroda BNP Paribas Equity Savings Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF955L01II9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147495",
    "name": "Baroda BNP Paribas Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Baroda BNP Paribas Equity Savings Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF955L01IK5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147496",
    "name": "Baroda BNP Paribas Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Baroda BNP Paribas Equity Savings Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF955L01IL3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "147498",
    "name": "Baroda BNP Paribas Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Baroda BNP Paribas Equity Savings Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-04",
    "closeData": "",
    "isin": "INF955L01IN9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147508",
    "name": "SBI Fixed Maturity Plan (FMP) Series 12 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 12 (1179 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-09",
    "closeData": "2019-07-15",
    "isin": "INF200KA1H66",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147509",
    "name": "SBI Fixed Maturity Plan (FMP) Series 12 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 12 (1179 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-09",
    "closeData": "2019-07-15",
    "isin": "INF200KA1H82",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147510",
    "name": "SBI Fixed Maturity Plan (FMP) Series 12 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 12 (1179 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-09",
    "closeData": "2019-07-15",
    "isin": "INF200KA1H90",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147511",
    "name": "SBI Fixed Maturity Plan (FMP) Series 12 (1179 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 12 (1179 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-09",
    "closeData": "2019-07-15",
    "isin": "INF200KA1H74",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147512",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Regular Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01PZ9",
    "isinRe": "INF767K01QA0"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147513",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Direct Plan-IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QE2",
    "isinRe": "INF767K01QF9"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147514",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Direct Plan-Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QH5",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147515",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QI3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147516",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Direct Plan-Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QG7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147517",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Regular Plan-Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QB8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147518",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Regular Plan-Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QC6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147519",
    "name": "LIC MF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "LIC MF Overnight Fund-Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "2019-07-15",
    "isin": "INF767K01QD4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147522",
    "name": "SBI Fixed Maturity Plan (FMP) Series 13 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 13 (1108 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-16",
    "closeData": "2019-07-22",
    "isin": "INF200KA1I16",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147523",
    "name": "SBI Fixed Maturity Plan (FMP) Series 13 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 13 (1108 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-16",
    "closeData": "2019-07-22",
    "isin": "INF200KA1I08",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147520",
    "name": "SBI Fixed Maturity Plan (FMP) Series 13 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 13 (1108 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-16",
    "closeData": "2019-07-22",
    "isin": "INF200KA1I24",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147521",
    "name": "SBI Fixed Maturity Plan (FMP) Series 13 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 13 (1108 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-16",
    "closeData": "2019-07-22",
    "isin": "INF200KA1I32",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147524",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)- Growth",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147525",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 6 Plan D (1097 days)- Dividend",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147526",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 6 Plan D (1097 days)- Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147527",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 6 Plan D (1097 days)- Quarterly Dividend",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147528",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans- Series 6 Plan D  (1097 days)- Quarterly Dividend- Direct",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "147529",
    "name": "Franklin India Fixed Maturity Plans Series 6 Plan D (1097 days)",
    "category": "Income",
    "navName": "Franklin India Fixed Maturity Plans - Series 6 Plan D (1097 days)- Growth - Direct",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147530",
    "name": "ICICI Prudential Nifty Private Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Private Bank ETF",
    "minAmount": "5000",
    "launchDate": "2019-08-01",
    "closeData": "",
    "isin": "INF109KC1E35",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "147534",
    "name": "Canara Robeco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "CANARA ROBECO OVERNIGHT FUND - REGULAR PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-07-19",
    "closeData": "2019-07-23",
    "isin": "INF760K01JO1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "147531",
    "name": "Canara Robeco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "CANARA ROBECO OVERNIGHT FUND - DIRECT PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2019-07-19",
    "closeData": "2019-07-23",
    "isin": "INF760K01JM5",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "147532",
    "name": "Canara Robeco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "CANARA ROBECO OVERNIGHT FUND - DIRECT PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-07-19",
    "closeData": "2019-07-23",
    "isin": "INF760K01JN3",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "147533",
    "name": "Canara Robeco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "CANARA ROBECO OVERNIGHT FUND - REGULAR PLAN - DAILY IDCW (Reinvestment)",
    "minAmount": "5000",
    "launchDate": "2019-07-19",
    "closeData": "2019-07-23",
    "isin": "INF760K01JP8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147535",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147536",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147537",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147538",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147539",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147540",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 86 - 1107 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-07-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147541",
    "name": "ITI Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "ITI Long Term Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF00XX01390",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147542",
    "name": "ITI Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "ITI Long Term Equity Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF00XX01408",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147543",
    "name": "ITI Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "ITI Long Term Equity Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF00XX01382",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147544",
    "name": "ITI Long Term Equity Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "ITI Long Term Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/- thereafter",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF00XX01374",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147545",
    "name": "Kotak FMP Series 273-1245 days",
    "category": "Income",
    "navName": "Kotak FMP Series 273-Regular Plan-Growth option",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF174KA1EQ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147546",
    "name": "Kotak FMP Series 273-1245 days",
    "category": "Income",
    "navName": "Kotak FMP Series 273-Regular Plan- Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF174KA1ER8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147547",
    "name": "Kotak FMP Series 273-1245 days",
    "category": "Income",
    "navName": "Kotak FMP Series 273-Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF174KA1ES6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147548",
    "name": "Kotak FMP Series 273-1245 days",
    "category": "Income",
    "navName": "Kotak FMP Series 273- Direct Plan- Dividend Payout",
    "minAmount": "5000",
    "launchDate": "2019-07-15",
    "closeData": "",
    "isin": "INF174KA1ET4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147549",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Fortnightly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KI4",
    "isinRe": "INF754K01KH6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147550",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Fortnightly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01JZ0",
    "isinRe": "INF754K01JY3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147551",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Annual - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KD5",
    "isinRe": "INF754K01KC7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147552",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Daily - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KF0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147553",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Weekly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KG8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147554",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Monthly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KB9",
    "isinRe": "INF754K01KA1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149476",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Unclaimed IDCW Plan - Above 3 years",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149477",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Unclaimed IDCW Plan - Upto 3 years",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149478",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Unclaimed Redemption Plan - Upto 3 years",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149479",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Unclaimed Redemption Plan - Above 3 years",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147555",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Monthly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KK0",
    "isinRe": "INF754K01KJ2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147556",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Annual - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KM6",
    "isinRe": "INF754K01KL8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147557",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Daily - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01JW7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147558",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Weekly - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01JX5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147569",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Regular Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01KE3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147570",
    "name": "Edelweiss Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Edelweiss Overnight Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2019-07-23",
    "closeData": "",
    "isin": "INF754K01JV9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147559",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-23",
    "closeData": "2019-07-29",
    "isin": "INF200KA11N8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147560",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-23",
    "closeData": "2019-07-29",
    "isin": "INF200KA13N4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147561",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-23",
    "closeData": "2019-07-29",
    "isin": "INF200KA14N2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147562",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 14 (384 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-23",
    "closeData": "2019-07-29",
    "isin": "INF200KA12N6",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147563",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": "INF174V01846",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147564",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Direct Plan -Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": "INF174V01838",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147565",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": "INF174V01812",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147566",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": "INF174V01820",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149470",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Unclaimed Redemption/IDCW upto 3 years",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149471",
    "name": "Mahindra Manulife Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mahindra Manulife Overnight Fund - Unclaimed Redemption/IDCW beyond 3 years",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2019-07-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147567",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01Q62",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147568",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R46",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147571",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01Q70",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147572",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R12",
    "isinRe": "INF846K01R04"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147573",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R38",
    "isinRe": "INF846K01R20"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147574",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01S11",
    "isinRe": "INF846K01S03"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147575",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R79",
    "isinRe": "INF846K01R61"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147576",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01Q96",
    "isinRe": "INF846K01Q88"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147577",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R53",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147578",
    "name": "Axis Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Axis Money Market Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2019-07-26",
    "closeData": "2019-08-01",
    "isin": "INF846K01R95",
    "isinRe": "INF846K01R87"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147579",
    "name": "Nippon India ETF S&P BSE Sensex Next 50",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF S&P BSE Sensex Next 50",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2019-07-29",
    "closeData": "",
    "isin": "INF204KB15D0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147580",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-30",
    "closeData": "2019-08-05",
    "isin": "INF200KA1I81",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147581",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-30",
    "closeData": "2019-08-05",
    "isin": "INF200KA1I99",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147582",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-30",
    "closeData": "2019-08-05",
    "isin": "INF200KA1J07",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147583",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 15 (1123 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-07-30",
    "closeData": "2019-08-05",
    "isin": "INF200KA1J15",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147600",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Regular Plan- Growth Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T43",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147605",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Direct Plan - Daily Dividend Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T35",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147606",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T01",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147607",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Regular Plan - Weekly Dividend Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T50",
    "isinRe": "INF663L01T68"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147608",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Direct Plan - Weekly Dividend Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T19",
    "isinRe": "INF663L01T27"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "147609",
    "name": "PGIM India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "PGIM India Overnight Fund - Regular Plan - Daily Dividend Option",
    "minAmount": "Minimum of Rs. 100/- and in multiples of Re.1/-",
    "launchDate": "2019-08-12",
    "closeData": "",
    "isin": "INF663L01T76",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147601",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 13 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-08-07",
    "closeData": "",
    "isin": "INF204KB18D4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147602",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 13 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-08-07",
    "closeData": "",
    "isin": "INF204KB16D8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147603",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 13 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-08-07",
    "closeData": "",
    "isin": "INF204KB19D2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147604",
    "name": "Reliance Fixed Horizon Fund - XLI - Series 13",
    "category": "Income",
    "navName": "Reliance Fixed Horizon Fund XLI - Series 13 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2019-08-07",
    "closeData": "",
    "isin": "INF204KB17D6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147613",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-13",
    "closeData": "2019-08-19",
    "isin": "INF200KA1J56",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147610",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-13",
    "closeData": "2019-08-19",
    "isin": "INF200KA1J31",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147611",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-13",
    "closeData": "2019-08-19",
    "isin": "INF200KA1J23",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147612",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 16 (1116 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-13",
    "closeData": "2019-08-19",
    "isin": "INF200KA1J49",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147614",
    "name": "Tata Nifty Private Bank Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Tata Nifty Private Bank Exchange Traded Fund",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2019-08-16",
    "closeData": "",
    "isin": "INF277K010X4",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147615",
    "name": "ITI Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ITI Arbitrage Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "",
    "isin": "INF00XX01457",
    "isinRe": "INF00XX01465"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147616",
    "name": "ITI Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ITI Arbitrage Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "",
    "isin": "INF00XX01424",
    "isinRe": "INF00XX01432"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147617",
    "name": "ITI Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ITI Arbitrage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "",
    "isin": "INF00XX01440",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147618",
    "name": "ITI Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "ITI Arbitrage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "",
    "isin": "INF00XX01416",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147619",
    "name": "Motilal Oswal Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Bank Index - Regular Plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01882",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147620",
    "name": "Motilal Oswal Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Bank Index Fund - Direct Plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01890",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147621",
    "name": "Motilal Oswal Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Midcap 150 Index Fund - Regular Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01908",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147622",
    "name": "Motilal Oswal Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Midcap 150 Index Fund - Direct Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01916",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147623",
    "name": "Motilal Oswal Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Smallcap 250 Index Fund- Direct Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01932",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147624",
    "name": "Motilal Oswal Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Smallcap 250 Index - Regular Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01924",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147625",
    "name": "Motilal Oswal Nifty 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 500 Index Fund - Direct Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01957",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147626",
    "name": "Motilal Oswal Nifty 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 500 Index Fund - Regular Plan",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-08-19",
    "closeData": "2019-08-30",
    "isin": "INF247L01940",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147627",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "2019-08-26",
    "isin": "INF200KA1J80",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147628",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "2019-08-26",
    "isin": "INF200KA1J98",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147629",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "2019-08-26",
    "isin": "INF200KA1J64",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147630",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 17 (1116 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-20",
    "closeData": "2019-08-26",
    "isin": "INF200KA1J72",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147631",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 5)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 5) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-22",
    "closeData": "2019-09-05",
    "isin": "INF200KA1K12",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147632",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 5)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 5) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-08-22",
    "closeData": "2019-09-05",
    "isin": "INF200KA1K04",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147633",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K012X0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147634",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K013X8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147635",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund-Regular Plan-Growth",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K011X2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147636",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund-Direct Plan-Growth",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K015X3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147637",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund  Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K016X1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147638",
    "name": "Tata Banking & PSU Debt FUnd",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Tata Banking & PSU Debt Fund  Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "5000/- & in multiples of Rs.1",
    "launchDate": "2019-09-19",
    "closeData": "",
    "isin": "INF277K014X6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147639",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-09",
    "closeData": "2019-09-16",
    "isin": "INF200KA1K38",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147640",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-09",
    "closeData": "2019-09-16",
    "isin": "INF200KA1K46",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147641",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-09",
    "closeData": "2019-09-16",
    "isin": "INF200KA1K53",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147642",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 18 (1108 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-09",
    "closeData": "2019-09-16",
    "isin": "INF200KA1K61",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147643",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 6)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 6) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-12",
    "closeData": "2019-09-26",
    "isin": "INF200KA1K79",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147644",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 6)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 6) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-12",
    "closeData": "2019-09-26",
    "isin": "INF200KA1K87",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147645",
    "name": "ICICI Prudential Global Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Global Advantage Fund (FOF) - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re.1 thereafter.",
    "launchDate": "2019-09-16",
    "closeData": "",
    "isin": "INF109KC1F42",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147646",
    "name": "ICICI Prudential Global Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Global Advantage Fund (FOF) - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re.1 thereafter.",
    "launchDate": "2019-09-16",
    "closeData": "",
    "isin": "INF109KC1F18",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147647",
    "name": "ICICI Prudential Global Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Global Advantage Fund (FOF) - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1 thereafter.",
    "launchDate": "2019-09-16",
    "closeData": "",
    "isin": "INF109KC1F00",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147648",
    "name": "ICICI Prudential Global Advantage Fund (FOF)",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Global Advantage Fund (FOF) - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1 thereafter.",
    "launchDate": "2019-09-16",
    "closeData": "",
    "isin": "INF109KC1F34",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147649",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMT2",
    "isinRe": "INF789F1AMU0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147650",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMV8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147651",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMK1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147658",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMY2",
    "isinRe": "INF789F1AMZ9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147659",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AML9",
    "isinRe": "INF789F1AMM7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147660",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMN5",
    "isinRe": "INF789F1AMO3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147652",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1ANC6",
    "isinRe": "INF789F1AND4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147653",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMR6",
    "isinRe": "INF789F1AMS4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147654",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1ANA0",
    "isinRe": "INF789F1ANB8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147655",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMI5",
    "isinRe": "INF789F1AMJ3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147656",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Direct Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMP0",
    "isinRe": "INF789F1AMQ8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "147657",
    "name": "UTi - Credit Risk Fund (Segregated - 13092019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 13092019) - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2019-09-13",
    "closeData": "",
    "isin": "INF789F1AMW6",
    "isinRe": "INF789F1AMX4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147661",
    "name": "ICICI Prudential Commodities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Commodities Fund - Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-09-25",
    "closeData": "",
    "isin": "INF109KC1F67",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147662",
    "name": "ICICI Prudential Commodities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Commodities Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-09-25",
    "closeData": "",
    "isin": "INF109KC1F91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147663",
    "name": "ICICI Prudential Commodities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Commodities Fund - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-09-25",
    "closeData": "",
    "isin": "INF109KC1F75",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147664",
    "name": "ICICI Prudential Commodities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Commodities Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- (plus in multiple of Re. 1)",
    "launchDate": "2019-09-25",
    "closeData": "",
    "isin": "INF109KC1G09",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147665",
    "name": "Axis Nifty 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 100 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs 1/- thereafter",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF846K01S52",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147666",
    "name": "Axis Nifty 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 100 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs 1/- thereafter",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF846K01S29",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147667",
    "name": "Axis Nifty 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 100 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs 1/- thereafter",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF846K01S60",
    "isinRe": "INF846K01S78"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147668",
    "name": "Axis Nifty 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 100 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs 1/- thereafter",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF846K01S37",
    "isinRe": "INF846K01S45"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147669",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-30",
    "closeData": "2019-10-07",
    "isin": "INF200KA1L29",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147670",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-30",
    "closeData": "2019-10-07",
    "isin": "INF200KA1K95",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147671",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-30",
    "closeData": "2019-10-07",
    "isin": "INF200KA1L03",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147672",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 19 (1115 Days) - Direct  Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-09-30",
    "closeData": "2019-10-07",
    "isin": "INF200KA1L11",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147701",
    "name": "Motilal Oswal Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Motilal Oswal Large and Midcap Fund - Regular Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF247L01965",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147703",
    "name": "Motilal Oswal Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Motilal Oswal Large and Midcap Fund Regular - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF247L01981",
    "isinRe": "INF247L01973"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147704",
    "name": "Motilal Oswal Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Motilal Oswal Large and Midcap Fund - Direct Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF247L01999",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147706",
    "name": "Motilal Oswal Large and Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Motilal Oswal Large and Midcap Fund Direct - IDCW Payout/Reinvestment",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2019-09-27",
    "closeData": "2019-10-11",
    "isin": "INF247L01AB3",
    "isinRe": "INF247L01AA5"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147718",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01549",
    "isinRe": "INF00XX01515"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147712",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Weekly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01499",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147713",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01564",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147714",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01473",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147715",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01556",
    "isinRe": "INF00XX01523"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147716",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Fortnightly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01531",
    "isinRe": "INF00XX01507"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147717",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01630",
    "isinRe": "INF00XX01606"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147707",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01648",
    "isinRe": "INF00XX01614"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147708",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01572",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147709",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01481",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147710",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Fortnightly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01622",
    "isinRe": "INF00XX01598"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147711",
    "name": "ITI Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "ITI Overnight Fund - Direct Plan - Weekly IDCW option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF00XX01580",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147719",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 20(1109 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "2019-10-14",
    "isin": "INF200KA1L45",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147720",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "2019-10-14",
    "isin": "INF200KA1L52",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147721",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "2019-10-14",
    "isin": "INF200KA1L37",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147722",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 20 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 20(1109 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-09",
    "closeData": "2019-10-14",
    "isin": "INF200KA1L60",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147723",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund-Regular Plan- Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EU2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147724",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund- Regular Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EW8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147725",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund-Direct Plan-Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EY4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147726",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund-Direct Plan-Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EX6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147727",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund- Direct Plan- Growth Option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EZ1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "147728",
    "name": "Kotak Pioneer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Pioneer Fund- Regular Plan- Reinvestment of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2019-10-09",
    "closeData": "",
    "isin": "INF174KA1EV0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147729",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01861",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147730",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Regular Plan - Weekly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01879",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147731",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01887",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147732",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Direct Plan - Weekly IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01903",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147733",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01895",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147734",
    "name": "Mahindra Manulife Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mahindra Manulife Ultra Short Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-10",
    "closeData": "",
    "isin": "INF174V01853",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147735",
    "name": "ADITYA BIRLA SUN LIFE NIFTY BANK ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty Bank ETF",
    "minAmount": "5000",
    "launchDate": "2019-10-16",
    "closeData": "",
    "isin": "INF209KB17D5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147736",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Direct Plan Growth",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FG6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147737",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Direct Daily IDCW",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FJ0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147738",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Direct Monthly IDCW",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FF8",
    "isinRe": "INF769K01FI2"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147739",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Regular Plan Growth",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FE1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147740",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Direct Weekly IDCW",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FK8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147741",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Regular Weekly IDCW",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FM4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147742",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Regular Monthly IDCW",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FD3",
    "isinRe": "INF769K01FH4"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147743",
    "name": "Mirae Asset Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Mirae Asset Overnight Fund Regular Daily  IDCW ",
    "minAmount": "Rs. 5000.00 and in multiples of Re. 1/- switches",
    "launchDate": "2019-10-15",
    "closeData": "",
    "isin": "INF769K01FL6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147744",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-17",
    "closeData": "2019-10-22",
    "isin": "INF200KA1L94",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147745",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-17",
    "closeData": "2019-10-22",
    "isin": "INF200KA1L78",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147746",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-17",
    "closeData": "2019-10-22",
    "isin": "INF200KA1M02",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147747",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 21 (1109 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-17",
    "closeData": "2019-10-22",
    "isin": "INF200KA1L86",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147748",
    "name": "Union Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Union Large & Midcap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF582M01GD4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147749",
    "name": "Union Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Union Large & Midcap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF582M01GG7",
    "isinRe": "INF582M01GF9"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147750",
    "name": "Union Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Union Large & Midcap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF582M01GA0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "147751",
    "name": "Union Large & Midcap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Union Large & Midcap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF582M01GC6",
    "isinRe": "INF582M01GB8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147752",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-29",
    "closeData": "2019-11-04",
    "isin": "INF200KA1M10",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147753",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-29",
    "closeData": "2019-11-04",
    "isin": "INF200KA1M28",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147754",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-29",
    "closeData": "2019-11-04",
    "isin": "INF200KA1M36",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147755",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 22 (1106 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-10-29",
    "closeData": "2019-11-04",
    "isin": "INF200KA1M44",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147761",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "TATA Focused Equity Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K012Y8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147756",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "TATA Focused Equity Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K011Y0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147757",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Tata Focused Equity Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K017X9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147758",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "TATA Focused Equity Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K018X7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147759",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "TATA Focused Equity Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K019X5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147760",
    "name": "Tata Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Tata Focused Equity Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2019-11-15",
    "closeData": "",
    "isin": "INF277K010Y2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147762",
    "name": "SBI Fixed Maturity Plan (FMP) -Series 23 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 23 (1106 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-05",
    "closeData": "2019-11-13",
    "isin": "INF200KA1M51",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147763",
    "name": "SBI Fixed Maturity Plan (FMP) -Series 23 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 23 (1106 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-05",
    "closeData": "2019-11-13",
    "isin": "INF200KA1M69",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147764",
    "name": "SBI Fixed Maturity Plan (FMP) -Series 23 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 23 (1106 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-05",
    "closeData": "2019-11-13",
    "isin": "INF200KA1M77",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147765",
    "name": "SBI Fixed Maturity Plan (FMP) -Series 23 (1106 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 23 (1106 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-05",
    "closeData": "2019-11-13",
    "isin": "INF200KA1M85",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147766",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Regular Plan-Daily IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QJ1",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147767",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Regular Plan-Weekly IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QL7",
    "isinRe": "INF767K01QK9"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147768",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Regular Plan-Monthly IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QN3",
    "isinRe": "INF767K01QM5"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147769",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Direct Plan-Weekly IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QR4",
    "isinRe": "INF767K01QQ6"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147770",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QO1",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147771",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Direct Plan-Daily IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QP8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147772",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QU8",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "147773",
    "name": "LIC MF Ultra Short Term Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "LIC MF Ultra Short Term Fund-Direct Plan-Monthly IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-22",
    "isin": "INF767K01QT0",
    "isinRe": "INF767K01QS2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147774",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-14",
    "closeData": "2019-11-20",
    "isin": "INF200KA1M93",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147775",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-14",
    "closeData": "2019-11-20",
    "isin": "INF200KA1N01",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147776",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-14",
    "closeData": "2019-11-20",
    "isin": "INF200KA1N27",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147777",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 24 (1107) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-14",
    "closeData": "2019-11-20",
    "isin": "INF200KA1N19",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147782",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-26",
    "isin": "INF200KA1N43",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147783",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-26",
    "isin": "INF200KA1N50",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147784",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-26",
    "isin": "INF200KA1N68",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147785",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 25 (1120 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-21",
    "closeData": "2019-11-26",
    "isin": "INF200KA1N35",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147788",
    "name": "ITI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ITI Balanced Advantage Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF00XX01697",
    "isinRe": "INF00XX01705"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147789",
    "name": "ITI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ITI Balanced Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF00XX01689",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147786",
    "name": "ITI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ITI Balanced Advantage Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF00XX01663",
    "isinRe": "INF00XX01671"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147787",
    "name": "ITI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "ITI Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF00XX01655",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147792",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-27",
    "closeData": "2019-12-03",
    "isin": "INF200KA1N92",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147793",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-27",
    "closeData": "2019-12-03",
    "isin": "INF200KA1O00",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147790",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-27",
    "closeData": "2019-12-03",
    "isin": "INF200KA1N76",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147791",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 26 (1112 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-27",
    "closeData": "2019-12-03",
    "isin": "INF200KA1N84",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147794",
    "name": "Motilal Oswal Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 50 Index Fund - Direct plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-12-03",
    "closeData": "",
    "isin": "INF247L01AE7",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147795",
    "name": "Motilal Oswal Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 50 Index Fund - Regular plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-12-03",
    "closeData": "",
    "isin": "INF247L01AF4",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147796",
    "name": "Motilal Oswal Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Next 50 Index Fund - Direct plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-12-03",
    "closeData": "",
    "isin": "INF247L01AC1",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "147797",
    "name": "Motilal Oswal Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Next 50 Index Fund - Regular plan - Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2019-12-03",
    "closeData": "",
    "isin": "INF247L01AD9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147822",
    "name": "Axis Retirement Savings Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Aggressive Plan - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01S94",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147823",
    "name": "Axis Retirement Savings Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Aggressive Plan - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T02",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147824",
    "name": "Axis Retirement Savings Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Aggressive Plan - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T10",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147825",
    "name": "Axis Retirement Savings Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Aggressive Plan - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01S86",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147826",
    "name": "Axis Retirement Savings Fund - Dynamic Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Dynamic Plan - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T28",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147827",
    "name": "Axis Retirement Savings Fund - Dynamic Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Dynamic Plan - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T36",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147828",
    "name": "Axis Retirement Savings Fund - Dynamic Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Dynamic Plan - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T44",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147829",
    "name": "Axis Retirement Savings Fund - Dynamic Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Dynamic Plan - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T51",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147830",
    "name": "Axis Retirement Savings Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Conservative Plan - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T69",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147831",
    "name": "Axis Retirement Savings Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Conservative Plan - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T77",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147832",
    "name": "Axis Retirement Savings Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Conservative Plan - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T85",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147833",
    "name": "Axis Retirement Savings Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Axis Retirement Savings Fund - Conservative Plan - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-11-29",
    "closeData": "",
    "isin": "INF846K01T93",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147834",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund (Regular) - Weekly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01LV8",
    "isinRe": "INF192K01LW6"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147835",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund (Direct) - Weekly IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01LZ9",
    "isinRe": "INF192K01MA0"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "149828",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund - (Direct) - Unclaimed IDCW - Growth Plan",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "149829",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund - (Direct) - Unclaimed Redemption - Growth Plan",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147836",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund (Regular) - Growth",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01LX4",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147837",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund - (Direct) - Growth",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01MB8",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147838",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund (Direct) - Daily IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01LY2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "147839",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund (Regular) - Daily IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": "INF192K01LU0",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "149826",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund - (Direct) - Unclaimed Redemption I.E.F. - Growth Plan",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "149827",
    "name": "JM Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "JM Overnight Fund - (Direct) - Unclaimed IDCW I.E.F. - Growth Plan",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2019-12-03",
    "closeData": "2019-12-03",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147840",
    "name": "Mahindra Manulife Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mahindra Manulife Large & Mid Cap Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-06",
    "closeData": "",
    "isin": "INF174V01945",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147841",
    "name": "Mahindra Manulife Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mahindra Manulife Large & Mid Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-06",
    "closeData": "",
    "isin": "INF174V01960",
    "isinRe": "INF174V01952"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147842",
    "name": "Mahindra Manulife Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mahindra Manulife Large & Mid Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-06",
    "closeData": "",
    "isin": "INF174V01937",
    "isinRe": "INF174V01929"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "147843",
    "name": "Mahindra Manulife Large & Mid Cap Fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Mahindra Manulife Large & Mid Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2019-12-06",
    "closeData": "",
    "isin": "INF174V01911",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147844",
    "name": "Aditya Birla Sun Life PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life PSU Equity Fund-Direct Plan-Growth",
    "minAmount": "500",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF209KB1O82",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147845",
    "name": "Aditya Birla Sun Life PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life PSU Equity Fund-Direct - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF209KB1O90",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147846",
    "name": "Aditya Birla Sun Life PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life PSU Equity Fund-Regular Plan-Growth",
    "minAmount": "500",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF209KB1O66",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "147847",
    "name": "Aditya Birla Sun Life PSU Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life PSU Equity Fund-Regular - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2019-12-09",
    "closeData": "",
    "isin": "INF209KB1O74",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147848",
    "name": "Bharat Bond ETF - April 2023",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Bharat Bond ETF - April 2023",
    "minAmount": "1000",
    "launchDate": "2019-12-12",
    "closeData": "",
    "isin": "INF754K01KN4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147849",
    "name": "Bharat Bond ETF - April 2030",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Bharat Bond ETF - April 2030",
    "minAmount": "1000",
    "launchDate": "2019-12-12",
    "closeData": "",
    "isin": "INF754K01KO2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147850",
    "name": "Bharat Bond FOF - April 2023",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Bharat Bond FOF - April 2023 - Regular Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KP9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147851",
    "name": "Bharat Bond FOF - April 2023",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Bharat Bond FOF - April 2023 - Direct Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KS3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147852",
    "name": "Bharat Bond FOF - April 2023",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2023 - Direct Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KT1",
    "isinRe": "INF754K01KU9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147853",
    "name": "Bharat Bond FOF - April 2023",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2023 - Regular Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KQ7",
    "isinRe": "INF754K01KR5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147856",
    "name": "Bharat Bond FOF - April 2030",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2030 - Regular Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KW5",
    "isinRe": "INF754K01KX3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147857",
    "name": "Bharat Bond FOF - April 2030",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Bharat Bond FOF - April 2030 - Direct Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KY1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147854",
    "name": "Bharat Bond FOF - April 2030",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Bharat Bond FOF - April 2030 - Regular Plan - Growth Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KV7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "147855",
    "name": "Bharat Bond FOF - April 2030",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2030 - Direct Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2019-12-13",
    "closeData": "",
    "isin": "INF754K01KZ8",
    "isinRe": "INF754K01LA9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147858",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147859",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G58",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147860",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G66",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147861",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G41",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147862",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G74",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147863",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1214 Days Plan A - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2019-12-21",
    "closeData": "",
    "isin": "INF109KC1G33",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147864",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Quant Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K013Y6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147865",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Quant Fund Direct Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K014Y4",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147866",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Quant Fund Regular Plan - Reinvestment of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K017Y7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147867",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Quant Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K016Y9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147868",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Quant Equity Fund Regular Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K018Y5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "147869",
    "name": "Tata Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "TATA Quant Equity Fund Direct Plan - Payout of Income Distribution cum capital withdrawal option ",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-01-03",
    "closeData": "",
    "isin": "INF277K015Y1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147870",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-03",
    "closeData": "2020-01-08",
    "isin": "INF200KA1O26",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147871",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-03",
    "closeData": "2020-01-08",
    "isin": "INF200KA1O42",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147872",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-03",
    "closeData": "2020-01-08",
    "isin": "INF200KA1O18",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147873",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 27 (1203 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-03",
    "closeData": "2020-01-08",
    "isin": "INF200KA1O34",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147878",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1114",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147879",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Regular Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1106",
    "isinRe": "INF205KA1098"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147880",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Regular Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1072",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147881",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Regular Plan - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1080",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147883",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1163",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147884",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Direct Plan - Daily IDCW (Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1122",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147885",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Direct Plan - Weekly IDCW (Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1130",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "147886",
    "name": "Invesco India Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Invesco India Overnight Fund - Direct Plan - Monthly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-01-07",
    "closeData": "",
    "isin": "INF205KA1155",
    "isinRe": "INF205KA1148"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147888",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01W07",
    "isinRe": "INF846K01W15"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147889",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U09",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147890",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01V16",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147897",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Regular Plan - Half Yearly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01V81",
    "isinRe": "INF846K01V99"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147898",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01V32",
    "isinRe": "INF846K01V24"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147905",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01V73",
    "isinRe": "INF846K01V65"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147891",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U90",
    "isinRe": "INF846K01V08"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147892",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U41",
    "isinRe": "INF846K01U33"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147893",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U66",
    "isinRe": "INF846K01U58"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147894",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U74",
    "isinRe": "INF846K01U82"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147895",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01U25",
    "isinRe": "INF846K01U17"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147896",
    "name": "Axis All Seasons Debt Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis All Seasons Debt Fund of Funds - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-10",
    "closeData": "",
    "isin": "INF846K01V57",
    "isinRe": "INF846K01V40"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147899",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1G90",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147900",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1H24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147901",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1H16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147902",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1H40",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147903",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1H08",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147904",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1174 Days Plan B - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1H32",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "147906",
    "name": "Mirae Asset Nifty Next 50 ETF (MANXT50ETF)",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty Next 50 ETF (MANxt50ETF)",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2020-01-13",
    "closeData": "",
    "isin": "INF769K01FN2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147907",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OW1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147908",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OR1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147909",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Regular Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OX9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147910",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Direct Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OS9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147911",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OY7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147912",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OT7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147915",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01OV3",
    "isinRe": "INF336L01OU5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "147916",
    "name": "HSBC Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "HSBC Ultra Short Duration Fund - Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-14",
    "closeData": "",
    "isin": "INF336L01PA4",
    "isinRe": "INF336L01OZ4"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147917",
    "name": "ITI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ITI Small Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF00XX01754",
    "isinRe": "INF00XX01762"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147918",
    "name": "ITI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ITI Small Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF00XX01721",
    "isinRe": "INF00XX01739"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147919",
    "name": "ITI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ITI Small Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF00XX01747",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "147920",
    "name": "ITI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ITI Small Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF00XX01713",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "147921",
    "name": "ICICI Prudential Nifty Midcap 150 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Midcap 150 ETF",
    "minAmount": "5000",
    "launchDate": "2020-01-15",
    "closeData": "",
    "isin": "INF109KC1G82",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147928",
    "name": "Axis ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis ESG Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-22",
    "closeData": "",
    "isin": "INF846K01W23",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147929",
    "name": "Axis ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis ESG Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-22",
    "closeData": "",
    "isin": "INF846K01W56",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147930",
    "name": "Axis ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis ESG Equity Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-22",
    "closeData": "",
    "isin": "INF846K01W64",
    "isinRe": "INF846K01W72"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "147931",
    "name": "Axis ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis ESG Equity Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-22",
    "closeData": "",
    "isin": "INF846K01W31",
    "isinRe": "INF846K01W49"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147932",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLII - Series 1 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF204KB12J4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147933",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLII - Series 1 - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF204KB11J6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147934",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLII - Series 1 - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF204KB10J8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "147935",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLII - Series 1 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-01-27",
    "closeData": "",
    "isin": "INF204KB13J2",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147953",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Regular Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01FA6",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "149412",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Unclaimed IDCW Plan - Greater than 3 Years",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "149413",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Unclaimed Redemption Plan - Upto 3 Years",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "149414",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Unclaimed IDCW Plan - Up to 3 Years",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "149415",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Unclaimed Redemption Plan - Greater than 3 Years",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147947",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Regular Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01FB4",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147948",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Direct Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01EY9",
    "isinRe": "INF761K01EX1"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147949",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Regular Plan- Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01FD0",
    "isinRe": "INF761K01FC2"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147950",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Direct Plan- Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01EW3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147951",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01EU7",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147952",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Direct Plan- Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01EV5",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "147936",
    "name": "BANK OF INDIA OVERNIGHT FUND",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "BANK OF INDIA Overnight Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2020-01-27",
    "closeData": "2020-01-28",
    "isin": "INF761K01EZ6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147941",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 7)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 7) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "2020-02-10",
    "isin": "INF200KA1O59",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "147942",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 7)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 7) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-01-27",
    "closeData": "2020-02-10",
    "isin": "INF200KA1O67",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "147943",
    "name": "BANDHAN Emerging Businesses Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANDHAN EMERGING BUSINESSES FUND - DIRECT PLAN IDCW",
    "minAmount": "Fresh Purchase 5000/- Additional Purchase 1000/-",
    "launchDate": "2020-02-03",
    "closeData": "",
    "isin": "INF194KB1AM2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "147944",
    "name": "BANDHAN Emerging Businesses Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANDHAN EMERGING BUSINESSES FUND - REGULAR PLAN GROWTH",
    "minAmount": "Fresh Purchase 5000/- Additional Purchase 1000/-",
    "launchDate": "2020-02-03",
    "closeData": "",
    "isin": "INF194KB1AJ8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "147945",
    "name": "BANDHAN Emerging Businesses Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANDHAN EMERGING BUSINESSES FUND - REGULAR PLAN IDCW",
    "minAmount": "Fresh Purchase 5000/- Additional Purchase 1000/-",
    "launchDate": "2020-02-03",
    "closeData": "",
    "isin": "INF194KB1AK6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "147946",
    "name": "BANDHAN Emerging Businesses Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "BANDHAN EMERGING BUSINESSES FUND - DIRECT PLAN GROWTH",
    "minAmount": "Fresh Purchase 5000/- Additional Purchase 1000/-",
    "launchDate": "2020-02-03",
    "closeData": "",
    "isin": "INF194KB1AL4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148019",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF204KB16J5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148020",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 2 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF204KB17J3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148021",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 2 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF204KB14J0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148022",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 2 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF204KB15J7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148029",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 3 - Direct Plan - Growth Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-10",
    "closeData": "",
    "isin": "INF204KB10K6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148030",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 3 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-10",
    "closeData": "",
    "isin": "INF204KB11K4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148031",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 3 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-10",
    "closeData": "",
    "isin": "INF204KB18J1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148032",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 3 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-02-10",
    "closeData": "",
    "isin": "INF204KB19J9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148033",
    "name": "ICICI Prudential India Equity FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential India Equity FOF - Growth",
    "minAmount": "5000",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF109KC1I15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148034",
    "name": "ICICI Prudential India Equity FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential India Equity FOF - IDCW",
    "minAmount": "5000",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF109KC1I31",
    "isinRe": "INF109KC1I23"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148035",
    "name": "ICICI Prudential India Equity FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential India Equity FOF - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF109KC1I49",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148036",
    "name": "ICICI Prudential India Equity FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential India Equity FOF - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2020-02-05",
    "closeData": "",
    "isin": "INF109KC1I64",
    "isinRe": "INF109KC1I56"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148037",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148038",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148039",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148040",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148041",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148042",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1163 Days Plan C - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148044",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148045",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148046",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148047",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148048",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148043",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1156 Days Plan D - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148049",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportunities Fund-Direct Plan-Dividend Payout",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K011Z7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148050",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportunities Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K012Z5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148051",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportunities Fund-Regular Plan-Dividend Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K013Z3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148052",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportnities Fund-Direct Plan-Dividend Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K010Z9",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148053",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportunities Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K019Y3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148054",
    "name": "Tata Multi Asset Opportunities Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Tata Multi Asset Opportunities Fund-Regular Plan-Dividend Payout",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF277K014Z1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148055",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-12",
    "closeData": "2020-02-17",
    "isin": "INF200KA1O83",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148056",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-12",
    "closeData": "2020-02-17",
    "isin": "INF200KA1O91",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148057",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-12",
    "closeData": "2020-02-17",
    "isin": "INF200KA1O75",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148058",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 28 (1163 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-12",
    "closeData": "2020-02-17",
    "isin": "INF200KA1P09",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148064",
    "name": "Edelweiss US Technology Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss US Technology Equity Fund of Fund- Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF754K01LC5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148063",
    "name": "Edelweiss US Technology Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Edelweiss US Technology Equity Fund of Fund- Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-02-14",
    "closeData": "",
    "isin": "INF754K01LB7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148065",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148066",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148067",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148068",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148069",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148070",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1157 Days Plan E - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-18",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148071",
    "name": "Union Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Union Midcap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-03-02",
    "closeData": "",
    "isin": "INF582M01GM5",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148072",
    "name": "Union Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Union Midcap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-03-02",
    "closeData": "",
    "isin": "INF582M01GO1",
    "isinRe": "INF582M01GN3"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148073",
    "name": "Union Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Union Midcap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-03-02",
    "closeData": "",
    "isin": "INF582M01GI3",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148074",
    "name": "Union Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Union Midcap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-03-02",
    "closeData": "",
    "isin": "INF582M01GK9",
    "isinRe": "INF582M01GJ1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148075",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APB3",
    "isinRe": "INF789F1APC1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148076",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APK4",
    "isinRe": "INF789F1APL2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148085",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI - Bond Fund ( Segregated - 17022020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOW2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148086",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI - Bond Fund (Segregated - 17022020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APF4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148087",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOZ5",
    "isinRe": "INF789F1APA5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148088",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APD9",
    "isinRe": "INF789F1APE7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148089",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOX0",
    "isinRe": "INF789F1AOY8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148090",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APM0",
    "isinRe": "INF789F1APN8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148091",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1API8",
    "isinRe": "INF789F1APJ6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148092",
    "name": "UTI - Bond Fund ( Segregated - 17022020 )",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "UTI Bond Fund ( Segregated - 17022020) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APG2",
    "isinRe": "INF789F1APH0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148104",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Saving Fund ( Segregated - 17022020) - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQH8",
    "isinRe": "INF789F1AQI6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148105",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Saving Fund (Segregated - 17022020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQJ4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148106",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Saving Fund (Segregated - 17022020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQP1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148107",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Medium Term Fund (Segregated 17022020) - Direct Plan Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148108",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Saving Fund (Segregated - 17022020) - Regular Plan - Monthly Payment Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQG0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148109",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Saving Fund ( Segregated - 17022020) - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQN6",
    "isinRe": "INF789F1AQO4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148116",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Medium Term Fund (Segregated 17022020) - Direct Plan Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148110",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Medium Term Fund (Segregated 17022020) - Direct Plan Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148111",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Saving Fund ( Segregated - 17022020) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQK2",
    "isinRe": "INF789F1AQL0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148112",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Regular Saving Fund (Segregated - 17022020) - Direct Plan - Monthly Payment Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQM8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148113",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Medium Term Fund (Segregated 17022020) - Direct Plan Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148114",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI - Medium Term Fund (Segregated 17022020) - Regular Plan Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148115",
    "name": "UTI - Regular Saving Fund (Segregated - 17022020)",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "UTI Regular Saving Fund ( Segregated - 17022020) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQQ9",
    "isinRe": "INF789F1AQR7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148117",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI - Dynamic Bond Fund (Segregated - 17022020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APO6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148118",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI - Dynamic Bond Fund (Segregated - 17022020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APX7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148119",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APR9",
    "isinRe": "INF789F1APS7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148120",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQA3",
    "isinRe": "INF789F1AQB1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148121",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APY5",
    "isinRe": "INF789F1APZ2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148122",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APT5",
    "isinRe": "INF789F1APU3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148123",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APV1",
    "isinRe": "INF789F1APW9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148124",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQE5",
    "isinRe": "INF789F1AQF2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148125",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AQC9",
    "isinRe": "INF789F1AQD7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148126",
    "name": "UTI - Dynamic Bond Fund ( Segregated - 17022020)",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "UTI Dynamic Bond Fund ( Segregated - 17022020 ) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1APP3",
    "isinRe": "INF789F1APQ1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148137",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOJ9",
    "isinRe": "INF789F1AOK7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148138",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 17022020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AON1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148131",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOA8",
    "isinRe": "INF789F1AOB6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148132",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOU6",
    "isinRe": "INF789F1AOV4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148133",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOQ4",
    "isinRe": "INF789F1AOR2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148134",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOF7",
    "isinRe": "INF789F1AOG5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148135",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOH3",
    "isinRe": "INF789F1AOI1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148136",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 17022020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOC4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148127",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOS0",
    "isinRe": "INF789F1AOT8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148128",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOO9",
    "isinRe": "INF789F1AOP6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148129",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOD2",
    "isinRe": "INF789F1AOE0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148130",
    "name": "UTI - Medium Term Fund (Segregated - 17022020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI Medium Term Fund ( Segregated - 17022020 ) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1AOL5",
    "isinRe": "INF789F1AOM3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148154",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Direct Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANN3",
    "isinRe": "INF789F1ANO1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148155",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANS2",
    "isinRe": "INF789F1ANT0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148156",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANH5",
    "isinRe": "INF789F1ANI3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148148",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANP8",
    "isinRe": "INF789F1ANQ6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148149",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Regular Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANW4",
    "isinRe": "INF789F1ANX2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148150",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANE2",
    "isinRe": "INF789F1ANF9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148151",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANL7",
    "isinRe": "INF789F1ANM5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148152",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Regular Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANU8",
    "isinRe": "INF789F1ANV6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148153",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Regular Plan - Monthly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANY0",
    "isinRe": "INF789F1ANZ7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148145",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI Credit Risk Fund ( Segregated - 17022020 ) - Direct Plan - Half-Yearly IDCW",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANJ1",
    "isinRe": "INF789F1ANK9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148146",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 17022020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANR4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148147",
    "name": "UTI - Credit Risk Fund (Segregated - 17022019)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 17022020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-02-17",
    "closeData": "",
    "isin": "INF789F1ANG7",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148163",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01T92",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148164",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Direct Plan - Weekly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U08",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148157",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U32",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148158",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Regular Plan - Weekly Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U40",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148159",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U24",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148160",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Regular Plan - Daily Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U57",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148161",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01T84",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148162",
    "name": "PGIM India Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "PGIM India Money Market Fund - Direct Plan - Daily Dividend Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 thereafter",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": "INF663L01U16",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148165",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148166",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148167",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148168",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148169",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148170",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1148 Days Plan F - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-02-28",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148171",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 8)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 8) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-03",
    "closeData": "2020-03-17",
    "isin": "INF200KA1P17",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148172",
    "name": "SBI Capital Protection Oriented Fund - Series A (Plan 8)",
    "category": "Income",
    "navName": "SBI Capital Protection Oriented Fund - Series A (Plan 8) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-03",
    "closeData": "2020-03-17",
    "isin": "INF200KA1P25",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148173",
    "name": "UTI Nifty Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI Nifty Bank ETF",
    "minAmount": "During NFO period- Rs 5000 an in multiple of Rs 1",
    "launchDate": "2020-08-31",
    "closeData": "",
    "isin": "INF789F1AUV1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148174",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLII - SERIES 4 - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB16N7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148175",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 4 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB15N9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148176",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon fund - XLII - Series 4 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB17N5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148177",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 4",
    "category": "Income",
    "navName": "NIPPON INDIA FIXED HORIZON FUND - XLII - SERIES 4 - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB19N1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148179",
    "name": "Nippon India Capital Protection Oriented Fund II - Plan A",
    "category": "Income",
    "navName": "NIPPON INDIA CAPITAL PROTECTION ORIENTED FUND II - PLAN A - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB13O2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148180",
    "name": "Nippon India Capital Protection Oriented Fund II - Plan A",
    "category": "Income",
    "navName": "Nippon India Capital Protection Oriented Fund  II - Plan A - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB10O8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148181",
    "name": "Nippon India Capital Protection Oriented Fund II - Plan A",
    "category": "Income",
    "navName": "NIPPON INDIA CAPITAL PROTECTION ORIENTED FUND II - PLAN A - IDCW Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB11O6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148178",
    "name": "Nippon India Capital Protection Oriented Fund II - Plan A",
    "category": "Income",
    "navName": "Nippon India Capital Protection Oriented Fund II - Plan A - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- per option and in multiples of Re. 1 t",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": "INF204KB12O4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148182",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": "INF109KC1K60",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148183",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148184",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148185",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": "INF109KC1K52",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148186",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": "INF109KC1K86",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148187",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1141 Days Plan G - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-03-05",
    "closeData": "",
    "isin": "INF109KC1K78",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148192",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-06",
    "closeData": "2020-03-13",
    "isin": "INF200KA1P41",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148193",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-06",
    "closeData": "2020-03-13",
    "isin": "INF200KA1P58",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148194",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-06",
    "closeData": "2020-03-13",
    "isin": "INF200KA1P66",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148195",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 31 (1160 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-03-06",
    "closeData": "2020-03-13",
    "isin": "INF200KA1P33",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148196",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQX5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148197",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1ARA1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148198",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Direct Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQY3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148199",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Direct Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQZ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148200",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Direct Plan - Flexi IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1ARB9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148201",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days) - Regular Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148202",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQS5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148203",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Regular Plan - Maturity IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQU1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148204",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXIII-I (1135 Days) - Regular Plan - Annual IDCW",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQT3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148205",
    "name": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days)",
    "category": "Income",
    "navName": "UTI - Fixed Term Income Fund Series XXXIII-I (1135 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2020-03-11",
    "closeData": "",
    "isin": "INF789F1AQV9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148222",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148223",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148224",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148225",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148226",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148227",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1134 Days Plan H - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148228",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148229",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148230",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148231",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148232",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148233",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1127 Days Plan I - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-12",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148240",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARM6",
    "isinRe": "INF789F1ARN4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148241",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARK0",
    "isinRe": "INF789F1ARL8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148242",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARF0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148243",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARO2",
    "isinRe": "INF789F1ARP9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148244",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARX3",
    "isinRe": "INF789F1ARY1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148245",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARD5",
    "isinRe": "INF789F1ARE3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148234",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARR5",
    "isinRe": "INF789F1ARS3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148235",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ART1",
    "isinRe": "INF789F1ARU9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148236",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARV7",
    "isinRe": "INF789F1ARW5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148237",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARQ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148238",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARI4",
    "isinRe": "INF789F1ARJ2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148239",
    "name": "UTI - Credit Risk Fund (Segregated - 06032020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 06032020) - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARG8",
    "isinRe": "INF789F1ARH6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148246",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1AST9",
    "isinRe": "INF789F1ASU7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148247",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASN2",
    "isinRe": "INF789F1ASO0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148248",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASC5",
    "isinRe": "INF789F1ASD3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148249",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASG6",
    "isinRe": "INF789F1ASH4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148250",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASI2",
    "isinRe": "INF789F1ASJ0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148257",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASB7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148251",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASP7",
    "isinRe": "INF789F1ASQ5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148252",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148253",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASK8",
    "isinRe": "INF789F1ASL6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148254",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Regular Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASR3",
    "isinRe": "INF789F1ASS1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148255",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ARZ8",
    "isinRe": "INF789F1ASA9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148256",
    "name": "UTI - Medium Term Fund ( Segregated - 06032020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 06032020) - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-03-06",
    "closeData": "",
    "isin": "INF789F1ASE1",
    "isinRe": "INF789F1ASF8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148336",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148337",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148338",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148339",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148340",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148341",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1128 Days Plan J - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-03-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148351",
    "name": "ITI Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ITI Large Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF00XX01770",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148352",
    "name": "ITI Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ITI Large Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF00XX01812",
    "isinRe": "INF00XX01820"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148353",
    "name": "ITI Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ITI Large Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF00XX01804",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148354",
    "name": "ITI Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "ITI Large Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF00XX01788",
    "isinRe": "INF00XX01796"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148355",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 5 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-03",
    "closeData": "",
    "isin": "INF204KB18U8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148356",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-03",
    "closeData": "",
    "isin": "INF204KB19U6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148357",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 5 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-03",
    "closeData": "",
    "isin": "INF204KB17U0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148358",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 5 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-03",
    "closeData": "",
    "isin": "INF204KB10V3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148369",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 32 ( 1140 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 32 (1140 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-07",
    "closeData": "2020-04-16",
    "isin": "INF200KA1P90",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148370",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 32 ( 1140 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 32 (1140 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-07",
    "closeData": "2020-04-16",
    "isin": "INF200KA1P82",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148367",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 32 ( 1140 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 32 (1140 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-07",
    "closeData": "2020-04-16",
    "isin": "INF200KA1P74",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148368",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 32 ( 1140 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 32 (1140 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-07",
    "closeData": "2020-04-16",
    "isin": "INF200KA1Q08",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148371",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 6 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-17",
    "closeData": "",
    "isin": "INF204KB13V7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148372",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 6 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-17",
    "closeData": "",
    "isin": "INF204KB14V5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148373",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 6 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-17",
    "closeData": "",
    "isin": "INF204KB11V1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148374",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 6",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 6 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-04-17",
    "closeData": "",
    "isin": "INF204KB12V9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148381",
    "name": "Motilal Oswal S&P 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P 500 Index Fund - Direct Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2020-04-15",
    "closeData": "2020-04-23",
    "isin": "INF247L01AG2",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148382",
    "name": "Motilal Oswal S&P 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P 500 Index Fund - Regular Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re.1/-",
    "launchDate": "2020-04-15",
    "closeData": "2020-04-23",
    "isin": "INF247L01AH0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148383",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-04-27",
    "isin": "INF200KA1Q16",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148384",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-04-27",
    "isin": "INF200KA1Q24",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148385",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-04-27",
    "isin": "INF200KA1Q32",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148386",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 33 (1128 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-04-27",
    "isin": "INF200KA1Q40",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148387",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-05-04",
    "isin": "INF200KA1Q73",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148388",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-05-04",
    "isin": "INF200KA1Q81",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148389",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-05-04",
    "isin": "INF200KA1Q57",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148390",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 34 (3682 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-04-23",
    "closeData": "2020-05-04",
    "isin": "INF200KA1Q65",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148393",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Direct Plan Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148394",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Direct Plan Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148395",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148396",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Half Yearly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148391",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148392",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 87 - 1115 Days Plan K - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2020-04-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "148397",
    "name": "IL&FS Infrastructure Debt Fund Series 2A",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 2A - Growth - Direct",
    "minAmount": "500000000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "148398",
    "name": "IL&FS Infrastructure Debt Fund Series 2B",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 2B - Growth - Direct",
    "minAmount": "500000000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "IL&FS Infra Asset Management Limited",
    "code": "148399",
    "name": "IL&FS Infrastructure Debt Fund Series 2C",
    "category": "Income",
    "navName": "IL&FS Infrastructure Debt Fund Series 2C - Growth - Direct",
    "minAmount": "500000000",
    "launchDate": "2014-03-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148400",
    "name": "Mirae Asset Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mirae Asset Arbitrage Fund Regular Growth",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-06-03",
    "closeData": "",
    "isin": "INF769K01FP7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148401",
    "name": "Mirae Asset Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mirae Asset Arbitrage Fund Direct Growth",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-06-03",
    "closeData": "",
    "isin": "INF769K01FS1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148402",
    "name": "Mirae Asset Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mirae Asset Arbitrage Fund Direct IDCW",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-06-03",
    "closeData": "",
    "isin": "INF769K01FR3",
    "isinRe": "INF769K01FT9"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148403",
    "name": "Mirae Asset Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mirae Asset Arbitrage Fund Regular IDCW",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-06-03",
    "closeData": "",
    "isin": "INF769K01FO0",
    "isinRe": "INF769K01FQ5"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148404",
    "name": "BANK OF INDIA FLEXI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANK OF INDIA Flexi Cap Fund Direct Plan -Growth",
    "minAmount": "5000",
    "launchDate": "2020-06-10",
    "closeData": "2020-06-23",
    "isin": "INF761K01FF5",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148405",
    "name": "BANK OF INDIA FLEXI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANK OF INDIA Flexi Cap Fund Regular Plan -Growth",
    "minAmount": "5000",
    "launchDate": "2020-06-10",
    "closeData": "2020-06-23",
    "isin": "INF761K01FI9",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148406",
    "name": "BANK OF INDIA FLEXI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANK OF INDIA Flexi Cap Fund Direct Plan -IDCW",
    "minAmount": "5000",
    "launchDate": "2020-06-10",
    "closeData": "2020-06-23",
    "isin": "INF761K01FE8",
    "isinRe": "INF761K01FG3"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148407",
    "name": "BANK OF INDIA FLEXI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANK OF INDIA Flexi Cap Fund Regular Plan -IDCW",
    "minAmount": "5000",
    "launchDate": "2020-06-10",
    "closeData": "2020-06-23",
    "isin": "INF761K01FH1",
    "isinRe": "INF761K01FJ7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148408",
    "name": "Nippon India ETF Nifty IT",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty IT",
    "minAmount": "NFO - Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2020-06-24",
    "closeData": "",
    "isin": "INF204KB15V2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148409",
    "name": "HSBC Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HSBC Focused Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2020-07-01",
    "closeData": "",
    "isin": "INF336L01PE6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148410",
    "name": "HSBC Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HSBC Focused Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2020-07-01",
    "closeData": "",
    "isin": "INF336L01PF3",
    "isinRe": "INF336L01PG1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148411",
    "name": "HSBC Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HSBC Focused Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2020-07-01",
    "closeData": "",
    "isin": "INF336L01PB2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148412",
    "name": "HSBC Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "HSBC Focused Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2020-07-01",
    "closeData": "",
    "isin": "INF336L01PC0",
    "isinRe": "INF336L01PD8"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148416",
    "name": "Mirae Asset Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Mirae Asset Banking and PSU Debt Fund Regular Growth Plan",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-07-08",
    "closeData": "",
    "isin": "INF769K01FV5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148417",
    "name": "Mirae Asset Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Mirae Asset Banking and PSU Debt Fund Direct IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-07-08",
    "closeData": "",
    "isin": "INF769K01FX1",
    "isinRe": "INF769K01FZ6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148418",
    "name": "Mirae Asset Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Mirae Asset Banking and PSU Debt Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-07-08",
    "closeData": "",
    "isin": "INF769K01FU7",
    "isinRe": "INF769K01FW3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148419",
    "name": "Mirae Asset Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Mirae Asset Banking and PSU Debt Fund Direct Growth",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-07-08",
    "closeData": "",
    "isin": "INF769K01FY9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148420",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148422",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148423",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148424",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148425",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148426",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Regular Plan - Flexi  Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148427",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148428",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148429",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct Plan - Flexi  Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148430",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148431",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148432",
    "name": "UTI - Credit Risk Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "UTI - Credit Risk Fund (Segregated - 07072020) - Direct  Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148421",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148433",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148434",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148435",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148436",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148443",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct Plan - Flexi Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148437",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Regular Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148438",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct Plan - Quarterly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148439",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct  Plan - Monthly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148440",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct Plan - Half Yearly Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148441",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148442",
    "name": "UTI - Medium Term Fund (Segregated - 07072020)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "UTI - Medium Term Fund (Segregated - 07072020) - Direct Plan - Annual Dividend Option",
    "minAmount": "Rs 5000",
    "launchDate": "2020-07-07",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148444",
    "name": "BHARAT Bond ETF- April 2025",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BHARAT Bond ETF April 2025",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LD3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148445",
    "name": "BHARAT Bond ETF- April 2031",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BHARAT Bond ETF- April 2031",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LE1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148446",
    "name": "BHARAT Bond FOF- April 2025",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF- April 2025- Direct Pan- Growth Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LI2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148447",
    "name": "BHARAT Bond FOF- April 2025",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2025 - Regular Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LG6",
    "isinRe": "INF754K01LH4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148448",
    "name": "BHARAT Bond FOF- April 2025",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF- April 2025- Regular Plan- Growth Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LF8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148449",
    "name": "BHARAT Bond FOF- April 2025",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2025 - Direct Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LJ0",
    "isinRe": "INF754K01LK8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148450",
    "name": "BHARAT Bond FOF- April 2031",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF- April 2031- Direct Plan- Growth Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LO0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148451",
    "name": "BHARAT Bond FOF- April 2031",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2031 - Regular Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LM4",
    "isinRe": "INF754K01LN2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148452",
    "name": "BHARAT Bond FOF- April 2031",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF- April 2031- Regular Plan- Growth Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LL6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148453",
    "name": "BHARAT Bond FOF- April 2031",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond FOF - April 2031 - Direct Plan - IDCW Option",
    "minAmount": "1000",
    "launchDate": "2020-07-14",
    "closeData": "",
    "isin": "INF754K01LP7",
    "isinRe": "INF754K01LQ5"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148454",
    "name": "Motilal Oswal Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Motilal Oswal Multi Asset Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-07-15",
    "closeData": "",
    "isin": "INF247L01AI8",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148455",
    "name": "Motilal Oswal Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Motilal Oswal Multi Asset Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-07-15",
    "closeData": "",
    "isin": "INF247L01AJ6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148456",
    "name": "ICICI Prudential Nifty Alpha Low- Volatility 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Alpha Low- Volatility 30 ETF",
    "minAmount": "5000",
    "launchDate": "2020-08-03",
    "closeData": "",
    "isin": "INF109KC1N59",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148457",
    "name": "Nippon India Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Nippon India Multi Asset Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-08-07",
    "closeData": "2020-08-21",
    "isin": "INF204KB19V4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148458",
    "name": "Nippon India Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "NIPPON INDIA MULTI ASSET FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-08-07",
    "closeData": "2020-08-21",
    "isin": "INF204KB10W1",
    "isinRe": "INF204KB11W9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148459",
    "name": "Nippon India Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Nippon India Multi Asset Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-08-07",
    "closeData": "2020-08-21",
    "isin": "INF204KB16V0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148460",
    "name": "Nippon India Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "NIPPON INDIA MULTI ASSET FUND - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2020-08-07",
    "closeData": "2020-08-21",
    "isin": "INF204KB17V8",
    "isinRe": "INF204KB18V6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148461",
    "name": "HDFC NIFTY BANK EXCHANGE TRADED FUND",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY Banking Exchange Traded Fund - GROWTH",
    "minAmount": "5000",
    "launchDate": "2020-08-10",
    "closeData": "2020-08-14",
    "isin": "INF179KC1AN8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148462",
    "name": "Kotak FMP Series 286 - 1355 days",
    "category": "Income",
    "navName": "Kotak FMP Series 286 - Regular Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2020-08-07",
    "closeData": "",
    "isin": "INF174KA1FA1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148463",
    "name": "Kotak FMP Series 286 - 1355 days",
    "category": "Income",
    "navName": "Kotak FMP Series 286 - Regular Plan-Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2020-08-07",
    "closeData": "",
    "isin": "INF174KA1FB9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148464",
    "name": "Kotak FMP Series 286 - 1355 days",
    "category": "Income",
    "navName": "Kotak FMP Series 286 - Direct Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2020-08-07",
    "closeData": "",
    "isin": "INF174KA1FC7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148465",
    "name": "Kotak FMP Series 286 - 1355 days",
    "category": "Income",
    "navName": "Kotak FMP Series 286 - Direct Plan-Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2020-08-07",
    "closeData": "",
    "isin": "INF174KA1FD5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148466",
    "name": "ICICI Prudential Nifty IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty IT ETF",
    "minAmount": "5000",
    "launchDate": "2020-08-12",
    "closeData": "",
    "isin": "INF109KC16I6",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148467",
    "name": "Mahindra Manulife Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mahindra Manulife Arbitrage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-12",
    "closeData": "",
    "isin": "INF174V01978",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148468",
    "name": "Mahindra Manulife Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mahindra Manulife Arbitrage Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-12",
    "closeData": "",
    "isin": "INF174V01AA0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148469",
    "name": "Mahindra Manulife Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mahindra Manulife Arbitrage Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-12",
    "closeData": "",
    "isin": "INF174V01994",
    "isinRe": "INF174V01986"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148470",
    "name": "Mahindra Manulife Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Mahindra Manulife Arbitrage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-12",
    "closeData": "",
    "isin": "INF174V01AC6",
    "isinRe": "INF174V01AB8"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148471",
    "name": "Baroda BNP Paribas Large and Mid Cap fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Baroda BNP Paribas Large and Mid Cap Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-17",
    "closeData": "",
    "isin": "INF955L01JR8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148472",
    "name": "Baroda BNP Paribas Large and Mid Cap fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Baroda BNP Paribas Large and Mid Cap Fund-Regular plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-17",
    "closeData": "",
    "isin": "INF955L01JS6",
    "isinRe": "INF955L01JT4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148474",
    "name": "Baroda BNP Paribas Large and Mid Cap fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Baroda BNP Paribas Large and Mid Cap Fund- Direct Plan -Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-17",
    "closeData": "",
    "isin": "INF955L01JU2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148475",
    "name": "Baroda BNP Paribas Large and Mid Cap fund",
    "category": "Equity Scheme - Large & Mid Cap Fund",
    "navName": "Baroda BNP Paribas Large and Mid Cap Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-08-17",
    "closeData": "",
    "isin": "INF955L01JV0",
    "isinRe": "INF955L01JW8"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148477",
    "name": "Union Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Union Medium Duration Fund - Regular Plan -  Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-08-24",
    "closeData": "",
    "isin": "INF582M01GU8",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148478",
    "name": "Union Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Union Medium Duration Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-08-24",
    "closeData": "",
    "isin": "INF582M01GW4",
    "isinRe": "INF582M01GV6"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148479",
    "name": "Union Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Union Medium Duration Fund - Direct Plan -  Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-08-24",
    "closeData": "",
    "isin": "INF582M01GQ6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148480",
    "name": "Union Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Union Medium Duration Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-08-24",
    "closeData": "",
    "isin": "INF582M01GS2",
    "isinRe": "INF582M01GR4"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148481",
    "name": "Invesco India Focused 20 Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Invesco India Focused 20 Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-09-09",
    "closeData": "",
    "isin": "INF205KA1213",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148482",
    "name": "Invesco India Focused 20 Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Invesco India Focused 20 Equity Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-09-09",
    "closeData": "",
    "isin": "INF205KA1205",
    "isinRe": "INF205KA1221"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148483",
    "name": "Invesco India Focused 20 Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Invesco India Focused 20 Equity Fund - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-09-09",
    "closeData": "",
    "isin": "INF205KA1189",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148484",
    "name": "Invesco India Focused 20 Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Invesco India Focused 20 Equity Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-09-09",
    "closeData": "",
    "isin": "INF205KA1171",
    "isinRe": "INF205KA1197"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148485",
    "name": "Axis Global Equity Alpha Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Equity Alpha Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-09-04",
    "closeData": "2020-09-18",
    "isin": "INF846K01X06",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148486",
    "name": "Axis Global Equity Alpha Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Equity Alpha Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-09-04",
    "closeData": "2020-09-18",
    "isin": "INF846K01X30",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148487",
    "name": "Axis Global Equity Alpha Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Equity Alpha Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-09-04",
    "closeData": "2020-09-18",
    "isin": "INF846K01X14",
    "isinRe": "INF846K01X22"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148488",
    "name": "Axis Global Equity Alpha Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Equity Alpha Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-09-04",
    "closeData": "2020-09-18",
    "isin": "INF846K01X48",
    "isinRe": "INF846K01X55"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148489",
    "name": "SBI Magnum Children's Benefit Fund - Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "SBI Magnum Children's Benefit Fund - Investment Plan - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2020-09-08",
    "closeData": "2020-09-08",
    "isin": "INF200KA1Q99",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148490",
    "name": "SBI Magnum Children's Benefit Fund - Investment Plan",
    "category": "Solution Oriented Scheme - Children s Fund",
    "navName": "SBI Magnum Children's Benefit Fund - Investment Plan - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1 thereafter",
    "launchDate": "2020-09-08",
    "closeData": "2020-09-08",
    "isin": "INF200KA1R07",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148500",
    "name": "Mirae Asset Equity Allocator Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Equity Allocator Fund of Fund Direct Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-09-08",
    "closeData": "",
    "isin": "INF769K01GE9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148501",
    "name": "Mirae Asset Equity Allocator Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Equity Allocator Fund  of Fund Direct IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-09-08",
    "closeData": "",
    "isin": "INF769K01GD1",
    "isinRe": "INF769K01GF6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148502",
    "name": "Mirae Asset Equity Allocator Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Equity allocator Fund Of Fund Regular Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-09-08",
    "closeData": "",
    "isin": "INF769K01GB5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148503",
    "name": "Mirae Asset Equity Allocator Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Equity Allocator Fund  of Fund Regular IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2020-09-08",
    "closeData": "",
    "isin": "INF769K01GA7",
    "isinRe": "INF769K01GC3"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148504",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund(Formerly Known as Sundaram Blue Chip Fund) Regular Plan - Growth",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1IZ3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148505",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund (Formerly Known as Sundaram Blue Chip Fund)  Regular Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1JA4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148506",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund (Formerly Known as Sundaram Blue Chip Fund) Regular Plan - Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1JB2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148507",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund (Formerly Known as Sundaram Blue Chip Fund)Direct Plan - Growth",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1JC0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148508",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund (Formerly Known as Sundaram Blue Chip Fund) Direct Plan - Payout of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1JD8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "148509",
    "name": "Sundaram Large Cap Fund ( Formerly Know as Sundaram Blue Chip Fund)",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "Sundaram Large Cap Fund (Formerly Known as Sundaram Blue Chip  Fund)  Direct Plan - Reinvestment of Income Distribution cum Capital Withdrawal (IDCW)",
    "minAmount": "100",
    "launchDate": "2020-09-17",
    "closeData": "",
    "isin": "INF903JA1JE6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148514",
    "name": "ICICI Prudential ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential ESG FUND - IDCW",
    "minAmount": "5000",
    "launchDate": "2020-09-21",
    "closeData": "",
    "isin": "INF109KC1N91",
    "isinRe": "INF109KC1N83"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148515",
    "name": "ICICI Prudential ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential ESG FUND - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2020-09-21",
    "closeData": "",
    "isin": "INF109KC1O25",
    "isinRe": "INF109KC1O17"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148516",
    "name": "ICICI Prudential ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential ESG FUND - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-09-21",
    "closeData": "",
    "isin": "INF109KC1O09",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148517",
    "name": "ICICI Prudential ESG FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential ESG FUND - Growth",
    "minAmount": "5000",
    "launchDate": "2020-09-21",
    "closeData": "",
    "isin": "INF109KC1N75",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148518",
    "name": "Nippon India Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Smallcap 250 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF204KB12W7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148519",
    "name": "Nippon India Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Smallcap 250 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF204KB15W0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148520",
    "name": "Nippon India Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY SMALLCAP 250 INDEX FUND - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF204KB13W5",
    "isinRe": "INF204KB14W3"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148521",
    "name": "Nippon India Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY SMALLCAP 250 INDEX FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF204KB16W8",
    "isinRe": "INF204KB17W6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148530",
    "name": "Mirae Asset Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mirae Asset Ultra Short Duration Fund Regular Growth",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF769K01GH2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148531",
    "name": "Mirae Asset Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mirae Asset Ultra Short Duration Fund Regular IDCW",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF769K01GG4",
    "isinRe": "INF769K01GI0"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148532",
    "name": "Mirae Asset Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mirae Asset Ultra Short Duration Fund Direct IDCW",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF769K01GJ8",
    "isinRe": "INF769K01GL4"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148529",
    "name": "Mirae Asset Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Mirae Asset Ultra Short Duration Fund Direct Growth",
    "minAmount": "Purchase   Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2020-09-28",
    "closeData": "",
    "isin": "INF769K01GK6",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148533",
    "name": "ITI Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ITI Banking & PSU Debt Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF00XX01846",
    "isinRe": "INF00XX01853"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148534",
    "name": "ITI Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ITI Banking & PSU Debt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF00XX01861",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148535",
    "name": "ITI Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ITI Banking & PSU Debt Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF00XX01838",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148536",
    "name": "ITI Banking & PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "ITI Banking & PSU Debt Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF00XX01879",
    "isinRe": "INF00XX01887"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148537",
    "name": "Aditya Birla Sun Life Special Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Special Opportunities Fund-Regular Plan-Growth",
    "minAmount": "500",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF209KB1T46",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148538",
    "name": "Aditya Birla Sun Life Special Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Special Opportunities Fund-Regular - IDCW",
    "minAmount": "500",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF209KB1T53",
    "isinRe": "INF209KB1T61"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148539",
    "name": "Aditya Birla Sun Life Special Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Special Opportunities Fund-Direct-Growth",
    "minAmount": "500",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF209KB1T79",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148540",
    "name": "Aditya Birla Sun Life Special Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Special Opportunities Fund-Direct - IDCW",
    "minAmount": "500",
    "launchDate": "2020-10-05",
    "closeData": "",
    "isin": "INF209KB1T87",
    "isinRe": "INF209KB1T95"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148541",
    "name": "SBI Nifty Private Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty Private Bank ETF",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-13",
    "isin": "INF200KA1S22",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148542",
    "name": "SBI Nifty IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty IT ETF",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-13",
    "isin": "INF200KA1S14",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148543",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate Debt Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R15",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148544",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate DEBT Fund - Regular Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R23",
    "isinRe": "INF200KA1R31"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148546",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate DEBT Fund - Regular Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R49",
    "isinRe": "INF200KA1R56"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148549",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate DEBT Fund - Direct Plan - Quarterly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R98",
    "isinRe": "INF200KA1S06"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148550",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate Debt Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R64",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148551",
    "name": "SBI Floating Rate Debt Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "SBI Floating Rate DEBT Fund - Direct Plan - Monthly Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF200KA1R72",
    "isinRe": "INF200KA1R80"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148555",
    "name": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF754K01LV5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148556",
    "name": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF754K01LW3",
    "isinRe": "INF754K01LX1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148557",
    "name": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss MSCI India Domestic & World Healthcare 45 index Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF754K01LR3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148558",
    "name": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2020-10-06",
    "closeData": "2020-10-20",
    "isin": "INF754K01LS1",
    "isinRe": "INF754K01LT9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148559",
    "name": "Axis NIFTY Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis NIFTY Bank ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2020-10-16",
    "closeData": "",
    "isin": "INF846K01X63",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148560",
    "name": "quant ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant ESG Equity Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2020-10-15",
    "closeData": "",
    "isin": "INF966L01AB8",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148561",
    "name": "quant ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant ESG Equity Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2020-10-15",
    "closeData": "",
    "isin": "INF966L01AC6",
    "isinRe": "INF966L01AF9"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148563",
    "name": "quant ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant ESG Equity Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2020-10-15",
    "closeData": "",
    "isin": "INF966L01AE2",
    "isinRe": "INF966L01AG7"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148564",
    "name": "quant ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant ESG Equity Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2020-10-15",
    "closeData": "",
    "isin": "INF966L01AD4",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148566",
    "name": "Mahindra Manulife Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mahindra Manulife Focused Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-26",
    "closeData": "",
    "isin": "INF174V01AF9",
    "isinRe": "INF174V01AE2"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148567",
    "name": "Mahindra Manulife Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mahindra Manulife Focused Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-26",
    "closeData": "",
    "isin": "INF174V01AG7",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148569",
    "name": "Mahindra Manulife Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mahindra Manulife Focused Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-26",
    "closeData": "",
    "isin": "INF174V01AI3",
    "isinRe": "INF174V01AH5"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148571",
    "name": "Mahindra Manulife Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Mahindra Manulife Focused Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-10-26",
    "closeData": "",
    "isin": "INF174V01AD4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148572",
    "name": "Mirae Asset Nifty 100 ESG Sector Leaders ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset ESG Sector Leaders ETF",
    "minAmount": "Rs. 5000 per application and in multiples of Re.",
    "launchDate": "2020-10-27",
    "closeData": "",
    "isin": "INF769K01GS9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148573",
    "name": "Mirae Asset Nifty 100 ESG Sector Leaders Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset ESG Sector Leaders Fund  of Fund Regular IDCW",
    "minAmount": "Minimum investment of Rs.5000/- and in multiples",
    "launchDate": "2020-10-27",
    "closeData": "",
    "isin": "INF769K01GM2",
    "isinRe": "INF769K01GO8"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148574",
    "name": "Mirae Asset Nifty 100 ESG Sector Leaders Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset ESG Sector Leaders Fund of Fund Direct Growth",
    "minAmount": "Minimum investment of Rs.5000/- and in multiples",
    "launchDate": "2020-10-27",
    "closeData": "",
    "isin": "INF769K01GQ3",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148575",
    "name": "Mirae Asset Nifty 100 ESG Sector Leaders Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset ESG Sector Leaders Fund  of Fund Direct IDCW",
    "minAmount": "Minimum investment of Rs.5000/- and in multiples",
    "launchDate": "2020-10-27",
    "closeData": "",
    "isin": "INF769K01GP5",
    "isinRe": "INF769K01GR1"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148576",
    "name": "Mirae Asset Nifty 100 ESG Sector Leaders Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset ESG Sector Leaders Fund of Fund Regular Growth",
    "minAmount": "Minimum investment of Rs.5000/- and in multiples",
    "launchDate": "2020-10-27",
    "closeData": "",
    "isin": "INF769K01GN0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148585",
    "name": "Nippon India ETF Nifty CPSE Bond Plus SDL Sep2024 50 50",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty CPSE Bond Plus SDL Sep2024 50 50",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2020-11-03",
    "closeData": "",
    "isin": "INF204KB18W4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148586",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 7 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-11-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148587",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLII - Series 7 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-11-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148588",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 7 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-11-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148589",
    "name": "Nippon India Fixed Horizon Fund - XLII - Series 7",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLII - Series 7 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2020-11-04",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148590",
    "name": "Union Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Union Hybrid Equity Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF582M01HE0",
    "isinRe": "INF582M01HD2"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148591",
    "name": "Union Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Union Hybrid Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF582M01HC4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148592",
    "name": "Union Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Union Hybrid Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF582M01GY0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "148593",
    "name": "Union Hybrid Equity Fund",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Union Hybrid Equity Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF582M01HA8",
    "isinRe": "INF582M01GZ7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148594",
    "name": "DSP Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "DSP Value Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2020-11-20",
    "closeData": "2020-12-04",
    "isin": "INF740KA1PM0",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148595",
    "name": "DSP Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "DSP Value Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2020-11-20",
    "closeData": "2020-12-04",
    "isin": "INF740KA1PP3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148596",
    "name": "DSP Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "DSP Value Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2020-11-20",
    "closeData": "2020-12-04",
    "isin": "INF740KA1PQ1",
    "isinRe": "INF740KA1PR9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148597",
    "name": "DSP Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "DSP Value Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2020-11-20",
    "closeData": "2020-12-04",
    "isin": "INF740KA1PN8",
    "isinRe": "INF740KA1PO6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148598",
    "name": "ICICI Prudential QUANT FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Quant Fund Growth",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2020-11-23",
    "closeData": "",
    "isin": "INF109KC1O33",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148599",
    "name": "ICICI Prudential QUANT FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Quant Fund IDCW",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2020-11-23",
    "closeData": "",
    "isin": "INF109KC1O58",
    "isinRe": "INF109KC1O41"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148600",
    "name": "ICICI Prudential QUANT FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Quant Fund Direct Plan Growth",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2020-11-23",
    "closeData": "",
    "isin": "INF109KC1O66",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148601",
    "name": "ICICI Prudential QUANT FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Quant Fund Direct Plan IDCW",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2020-11-23",
    "closeData": "",
    "isin": "INF109KC1O82",
    "isinRe": "INF109KC1O74"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148602",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FF0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148603",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Regular Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FE3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148604",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Regular Plan - Dividend Reinvestment Option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FG8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148605",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Direct Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FH6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148606",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FI4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148607",
    "name": "Kotak ESG Opportunities Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak ESG Opportunities Fund - Direct Plan - Dividend Reinvestment Option",
    "minAmount": "5000",
    "launchDate": "2020-11-20",
    "closeData": "",
    "isin": "INF174KA1FJ2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148608",
    "name": "HDFC DIVIDEND YIELD FUND",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "HDFC Dividend Yield Fund - IDCW Option Direct Plan",
    "minAmount": "5000",
    "launchDate": "2020-11-27",
    "closeData": "2020-12-11",
    "isin": "INF179KC1AP3",
    "isinRe": "INF179KC1AQ1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148609",
    "name": "HDFC DIVIDEND YIELD FUND",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "HDFC Dividend Yield Fund - Growth Option Direct Plan",
    "minAmount": "5000",
    "launchDate": "2020-11-27",
    "closeData": "2020-12-11",
    "isin": "INF179KC1AO6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148610",
    "name": "HDFC DIVIDEND YIELD FUND",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "HDFC Dividend Yield Fund - Growth Plan",
    "minAmount": "5000",
    "launchDate": "2020-11-27",
    "closeData": "2020-12-11",
    "isin": "INF179KC1AR9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148611",
    "name": "HDFC DIVIDEND YIELD FUND",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "HDFC Dividend Yield Fund - IDCW Plan",
    "minAmount": "5000",
    "launchDate": "2020-11-27",
    "closeData": "2020-12-11",
    "isin": "INF179KC1AS7",
    "isinRe": "INF179KC1AT5"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148614",
    "name": "Invesco India - Invesco Global Consumer Trends Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Consumer Trends Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF205KA1270",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148615",
    "name": "Invesco India - Invesco Global Consumer Trends Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Consumer Trends Fund of Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF205KA1262",
    "isinRe": "INF205KA1288"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148612",
    "name": "Invesco India - Invesco Global Consumer Trends Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Consumer Trends Fund of Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF205KA1239",
    "isinRe": "INF205KA1254"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148613",
    "name": "Invesco India - Invesco Global Consumer Trends Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco Global Consumer Trends Fund of Fund - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF205KA1247",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148616",
    "name": "UTI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "UTI Small Cap Fund - Regular Plan - IDCW (Payout)",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2020-12-02",
    "closeData": "",
    "isin": "INF789F1AUP3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148617",
    "name": "UTI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "UTI Small Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2020-12-02",
    "closeData": "",
    "isin": "INF789F1AUO6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148618",
    "name": "UTI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "UTI Small Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2020-12-02",
    "closeData": "",
    "isin": "INF789F1AUQ1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148619",
    "name": "UTI Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "UTI Small Cap Fund - Direct Plan - IDCW (Payout)",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2020-12-02",
    "closeData": "",
    "isin": "INF789F1AUR9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148620",
    "name": "Motilal Oswal Nifty 5 year benchmark G-Sec ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nifty 5 year Benchmark G-sec ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-11-23",
    "closeData": "2020-12-02",
    "isin": "INF247L01AK4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148621",
    "name": "Mirae Asset Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Banking and Financial Services Fund Regular Growth",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-11-25",
    "closeData": "",
    "isin": "INF769K01GU5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148622",
    "name": "Mirae Asset Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Banking and Financial Services Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-11-25",
    "closeData": "",
    "isin": "INF769K01GT7",
    "isinRe": "INF769K01GV3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148623",
    "name": "Mirae Asset Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Banking and Financial Services Fund Direct Growth",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-11-25",
    "closeData": "",
    "isin": "INF769K01GX9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148624",
    "name": "Mirae Asset Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Mirae Asset Banking and Financial Services Fund Direct  IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2020-11-25",
    "closeData": "",
    "isin": "INF769K01GW1",
    "isinRe": "INF769K01GY7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148628",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund- Direct Plan -Growth Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01KC8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148629",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund -Direct Plan-Monthly IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01KF1",
    "isinRe": "INF955L01KG9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148630",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund-Direct plan-Quarterly IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01KD6",
    "isinRe": "INF955L01KE4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148625",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund-Regular Plan -Growth Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01JX6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148626",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund-Regular Plan -Monthly IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01KA2",
    "isinRe": "INF955L01KB0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "148627",
    "name": "Baroda BNP Paribas Banking and PSU Bond Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Baroda BNP Paribas Banking and PSU Bond Fund-Regular Plan-Quarterly IDCW Option",
    "minAmount": "5000 and in multiples of Re.1/- theerafter",
    "launchDate": "2020-11-27",
    "closeData": "",
    "isin": "INF955L01JY4",
    "isinRe": "INF955L01JZ1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148633",
    "name": "Axis Special Situations Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Special Situations Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF846K01Y13",
    "isinRe": "INF846K01Y21"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148634",
    "name": "Axis Special Situations Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Special Situations Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF846K01X71",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148631",
    "name": "Axis Special Situations Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Special Situations Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF846K01Y05",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148632",
    "name": "Axis Special Situations Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Special Situations Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF846K01X89",
    "isinRe": "INF846K01X97"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148635",
    "name": "Aditya Birla Sun Life ESG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life ESG Fund-Regular Plan-Growth",
    "minAmount": "500",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF209KB1U43",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148636",
    "name": "Aditya Birla Sun Life ESG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life ESG Fund-Regular - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF209KB1U50",
    "isinRe": "INF209KB1U68"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148637",
    "name": "Aditya Birla Sun Life ESG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life ESG Fund-Direct Plan-Growth",
    "minAmount": "500",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF209KB1U76",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148640",
    "name": "Aditya Birla Sun Life ESG Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life ESG Fund-Direct - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2020-12-04",
    "closeData": "",
    "isin": "INF209KB1U84",
    "isinRe": "INF209KB1U92"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148641",
    "name": "Nippon India Passive Flexicap FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Passive Flexicap FoF - Regular Plan - Growth Option",
    "minAmount": "Rs 500 and in multiples of Re 1 thereafter",
    "launchDate": "2020-12-10",
    "closeData": "",
    "isin": "INF204KB13X3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148642",
    "name": "Nippon India Passive Flexicap FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Passive Flexicap FoF - Direct Plan - Growth Option",
    "minAmount": "Rs 500 and in multiples of Re 1 thereafter",
    "launchDate": "2020-12-10",
    "closeData": "",
    "isin": "INF204KB16X6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148643",
    "name": "Nippon India Passive Flexicap FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA PASSIVE FLEXICAP FOF - IDCW Option",
    "minAmount": "Rs 500 and in multiples of Re 1 thereafter",
    "launchDate": "2020-12-10",
    "closeData": "",
    "isin": "INF204KB14X1",
    "isinRe": "INF204KB15X8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148644",
    "name": "Nippon India Passive Flexicap FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA PASSIVE FLEXICAP FOF - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 500 and in multiples of Re 1 thereafter",
    "launchDate": "2020-12-10",
    "closeData": "",
    "isin": "INF204KB17X4",
    "isinRe": "INF204KB18X2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148645",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FL8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148646",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FO2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148647",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Regular Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FK0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148648",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Direct Plan - Dividend Reinvestment",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FP9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148649",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Direct Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FN4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148650",
    "name": "Kotak International REIT FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak International REIT FOF - Regular Plan - Dividend Reinvestment",
    "minAmount": "5000",
    "launchDate": "2020-12-07",
    "closeData": "",
    "isin": "INF174KA1FM6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148651",
    "name": "ICICI Prudential Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Business Cycle Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2020-12-29",
    "closeData": "",
    "isin": "INF109KC1P24",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148652",
    "name": "ICICI Prudential Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Business Cycle Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2020-12-29",
    "closeData": "",
    "isin": "INF109KC1P40",
    "isinRe": "INF109KC1P32"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148653",
    "name": "ICICI Prudential Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Business Cycle Fund Growth",
    "minAmount": "5000",
    "launchDate": "2020-12-29",
    "closeData": "",
    "isin": "INF109KC1O90",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148654",
    "name": "ICICI Prudential Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Business Cycle Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2020-12-29",
    "closeData": "",
    "isin": "INF109KC1P16",
    "isinRe": "INF109KC1P08"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148655",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND - REGULAR GROWTH",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01016",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148656",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND - DIRECT GROWTH",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01107",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148675",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND DIRECT PLAN WEEKLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01123",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148678",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND DIRECT PLAN DAILY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01115",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148679",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND REGULAR PLAN WEEKLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01032",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148681",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND DIRECT PLAN MONTHLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL OPTION",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01149",
    "isinRe": "INF0GCD01131"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148667",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND REGULAR PLAN DAILY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01024",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148668",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND REGULAR PLAN QUARTERLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01073",
    "isinRe": "INF0GCD01065"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148669",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND REGULAR PLAN ANNUAL INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01099",
    "isinRe": "INF0GCD01081"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148670",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND DIRECT PLAN QUARTERLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01164",
    "isinRe": "INF0GCD01156"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148672",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND DIRECT PLAN ANNUAL INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL OPTION",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01180",
    "isinRe": "INF0GCD01172"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148674",
    "name": "TRUSTMF BANKING & PSU DEBT FUND",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "TRUSTMF BANKING & PSU DEBT FUND REGULAR PLAN MONTHLY INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-01-15",
    "closeData": "2021-01-27",
    "isin": "INF0GCD01057",
    "isinRe": "INF0GCD01040"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148657",
    "name": "PGIM India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "PGIM India Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-15",
    "closeData": "",
    "isin": "INF663L01V72",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148658",
    "name": "PGIM India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "PGIM India Balanced Advantage Fund -  Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-15",
    "closeData": "",
    "isin": "INF663L01V49",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148659",
    "name": "PGIM India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "PGIM India Balanced Advantage Fund - Direct Plan - Dividend Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-15",
    "closeData": "",
    "isin": "INF663L01V56",
    "isinRe": "INF663L01V64"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "148660",
    "name": "PGIM India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "PGIM India Balanced Advantage Fund - Regular Plan - Dividend Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-15",
    "closeData": "",
    "isin": "INF663L01V80",
    "isinRe": "INF663L01V98"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148661",
    "name": "Kotak NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak NASDAQ 100 Fund of Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-01-11",
    "closeData": "",
    "isin": "INF174KA1FR5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148662",
    "name": "Kotak NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak NASDAQ 100 Fund of Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-01-11",
    "closeData": "",
    "isin": "INF174KA1FQ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148663",
    "name": "Nippon India Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Asset Allocator FoF - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2021-01-18",
    "closeData": "",
    "isin": "INF204KB13Y1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148664",
    "name": "Nippon India Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA ASSET ALLOCATOR FOF - DIRECT Plan - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2021-01-18",
    "closeData": "",
    "isin": "INF204KB17Y2",
    "isinRe": "INF204KB18Y0"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148665",
    "name": "Nippon India Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "NIPPON INDIA ASSET ALLOCATOR FOF - IDCW Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2021-01-18",
    "closeData": "",
    "isin": "INF204KB14Y9",
    "isinRe": "INF204KB15Y6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148666",
    "name": "Nippon India Asset Allocator FoF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Asset Allocator FoF - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re 1 thereafter",
    "launchDate": "2021-01-18",
    "closeData": "",
    "isin": "INF204KB16Y4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148686",
    "name": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T05",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148695",
    "name": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S71",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148684",
    "name": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S89",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148685",
    "name": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Hybrid Plan - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S97",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148701",
    "name": "Axis Greater China Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Greater China Equity Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-29",
    "closeData": "",
    "isin": "INF846K01Y62",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148702",
    "name": "Axis Greater China Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Greater China Equity Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-29",
    "closeData": "",
    "isin": "INF846K01Y70",
    "isinRe": "INF846K01Y88"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148699",
    "name": "Axis Greater China Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Greater China Equity Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-29",
    "closeData": "",
    "isin": "INF846K01Y39",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148700",
    "name": "Axis Greater China Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Greater China Equity Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-29",
    "closeData": "",
    "isin": "INF846K01Y47",
    "isinRe": "INF846K01Y54"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148703",
    "name": "UTI Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 200 Momentum 30 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2021-02-18",
    "closeData": "",
    "isin": "INF789F1AUT5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "148704",
    "name": "UTI Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 200 Momentum 30 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiples of Rs 1/- thereafter",
    "launchDate": "2021-02-18",
    "closeData": "",
    "isin": "INF789F1AUS7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148705",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN GROWTH",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AP5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148706",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN QUARTERLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AU5",
    "isinRe": "INF194KB1AV3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148707",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN MONTHLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AS9",
    "isinRe": "INF194KB1AT7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148708",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN WEEKLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AR1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148709",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN PERIODIC IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AW1",
    "isinRe": "INF194KB1AX9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148710",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN DAILY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AQ3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148717",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN ANNUAL IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BJ6",
    "isinRe": "INF194KB1BK4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148718",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - REGULAR PLAN ANNUAL IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1AY7",
    "isinRe": "INF194KB1AZ4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148711",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN GROWTH",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BA5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148712",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN QUARTERLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BF4",
    "isinRe": "INF194KB1BG2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148713",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN MONTHLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BD9",
    "isinRe": "INF194KB1BE7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148714",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN DAILY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BB3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148715",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN PERIODIC IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BH0",
    "isinRe": "INF194KB1BI8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148716",
    "name": "BANDHAN FLOATING RATE FUND",
    "category": "Debt Scheme - Floater Fund",
    "navName": "BANDHAN FLOATING RATE FUND  - DIRECT PLAN WEEKLY IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-02-10",
    "closeData": "",
    "isin": "INF194KB1BC1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148719",
    "name": "Nippon India Nifty 50 Value 20 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty 50 Value 20 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB19Y8",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148720",
    "name": "Nippon India Nifty 50 Value 20 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY 50 VALUE 20 INDEX FUND - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB10Z4",
    "isinRe": "INF204KB11Z2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148721",
    "name": "Nippon India Nifty 50 Value 20 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty 50 Value 20 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB12Z0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148722",
    "name": "Nippon India Nifty 50 Value 20 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY 50 VALUE 20 INDEX FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB13Z8",
    "isinRe": "INF204KB14Z6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148723",
    "name": "Nippon India Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Midcap 150 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB15Z3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148724",
    "name": "Nippon India Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY MIDCAP 150 INDEX FUND - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB16Z1",
    "isinRe": "INF204KB17Z9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148725",
    "name": "Nippon India Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "NIPPON INDIA NIFTY MIDCAP 150 INDEX FUND - DIRECT Plan - IDCW Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB19Z5",
    "isinRe": "INF204KC1014"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148726",
    "name": "Nippon India Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Midcap 150 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000 and in multiples of Re.1 thereafter",
    "launchDate": "2021-02-04",
    "closeData": "",
    "isin": "INF204KB18Z7",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148727",
    "name": "Mahindra Manulife Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mahindra Manulife Short Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-09",
    "closeData": "",
    "isin": "INF174V01AJ1",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148728",
    "name": "Mahindra Manulife Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mahindra Manulife Short Duration Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-09",
    "closeData": "",
    "isin": "INF174V01AL7",
    "isinRe": "INF174V01AK9"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148729",
    "name": "Mahindra Manulife Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mahindra Manulife Short Duration Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-09",
    "closeData": "",
    "isin": "INF174V01AM5",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "148730",
    "name": "Mahindra Manulife Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Mahindra Manulife Short Duration Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-09",
    "closeData": "",
    "isin": "INF174V01AO1",
    "isinRe": "INF174V01AN3"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148731",
    "name": "ITI Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "ITI Mid Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-15",
    "closeData": "",
    "isin": "INF00XX01937",
    "isinRe": "INF00XX01945"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148732",
    "name": "ITI Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "ITI Mid Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-15",
    "closeData": "",
    "isin": "INF00XX01895",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148733",
    "name": "ITI Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "ITI Mid Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-15",
    "closeData": "",
    "isin": "INF00XX01929",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148734",
    "name": "ITI Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "ITI Mid Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-02-15",
    "closeData": "",
    "isin": "INF00XX01903",
    "isinRe": "INF00XX01911"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148735",
    "name": "HSBC Global Equity Climate Change Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Equity Climate Change Fund of Fund - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2021-03-03",
    "closeData": "",
    "isin": "INF336L01PY4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148736",
    "name": "HSBC Global Equity Climate Change Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Equity Climate Change Fund of Fund IDCW",
    "minAmount": "5000",
    "launchDate": "2021-03-03",
    "closeData": "",
    "isin": "INF336L01PZ1",
    "isinRe": "INF336L01QA2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148737",
    "name": "HSBC Global Equity Climate Change Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Equity Climate Change Fund of Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2021-03-03",
    "closeData": "",
    "isin": "INF336L01PV0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "148738",
    "name": "HSBC Global Equity Climate Change Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HSBC Global Equity  Climate Change Fund of Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-03-03",
    "closeData": "",
    "isin": "INF336L01PW8",
    "isinRe": "INF336L01PX6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148739",
    "name": "Kotak FMP Series 287 - 1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 287 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-02-16",
    "closeData": "",
    "isin": "INF174KA1FS3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148740",
    "name": "Kotak FMP Series 287 - 1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 287 - Regular Plan - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2021-02-16",
    "closeData": "",
    "isin": "INF174KA1FT1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148741",
    "name": "Kotak FMP Series 287 - 1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 287 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-02-16",
    "closeData": "",
    "isin": "INF174KA1FU9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148742",
    "name": "Kotak FMP Series 287 - 1188 days",
    "category": "Income",
    "navName": "Kotak FMP Series 287 - Direct Plan - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2021-02-16",
    "closeData": "",
    "isin": "INF174KA1FV7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148743",
    "name": "Kotak Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Next 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-02-17",
    "closeData": "",
    "isin": "INF174KA1FX3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148744",
    "name": "Kotak Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Next 50 Index Fund - Regular Plan - Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-02-17",
    "closeData": "",
    "isin": "INF174KA1FW5",
    "isinRe": "INF174KA1FY1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148745",
    "name": "Kotak Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Next 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-02-17",
    "closeData": "",
    "isin": "INF174KA1GA9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148746",
    "name": "Kotak Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Next 50 Index Fund - Direct Plan -Payout of Income Distribution cum capital withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-02-17",
    "closeData": "",
    "isin": "INF174KA1FZ8",
    "isinRe": "INF174KA1GB7"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148747",
    "name": "Motilal Oswal Asset Allocation Fund of Fund- Aggressive",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Asset Allocation FOF- A- Direct Growth",
    "minAmount": "100000000",
    "launchDate": "2021-02-19",
    "closeData": "2021-03-05",
    "isin": "INF247L01AL2",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148748",
    "name": "Motilal Oswal Asset Allocation Fund of Fund- Aggressive",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Asset Allocation FOF- A- Regular Growth",
    "minAmount": "100000000",
    "launchDate": "2021-02-19",
    "closeData": "2021-03-05",
    "isin": "INF247L01AM0",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148749",
    "name": "Motilal Oswal Asset Allocation Fund of Fund- Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Asset Allocation FOF- C- Regular Growth",
    "minAmount": "100000000",
    "launchDate": "2021-02-19",
    "closeData": "2021-03-05",
    "isin": "INF247L01AO6",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "148750",
    "name": "Motilal Oswal Asset Allocation Fund of Fund- Conservative",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Asset Allocation FOF- C- Direct Growth",
    "minAmount": "100000000",
    "launchDate": "2021-02-19",
    "closeData": "2021-03-05",
    "isin": "INF247L01AN8",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148751",
    "name": "Invesco India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India ESG Equity Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2021-02-26",
    "closeData": "",
    "isin": "INF205KA1338",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148752",
    "name": "Invesco India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India ESG Equity Fund - Direct Plan - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2021-02-26",
    "closeData": "",
    "isin": "INF205KA1320",
    "isinRe": "INF205KA1346"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148753",
    "name": "Invesco India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India ESG Equity Fund - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2021-02-26",
    "closeData": "",
    "isin": "INF205KA1304",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "148754",
    "name": "Invesco India ESG Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Invesco India ESG Equity Fund - IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2021-02-26",
    "closeData": "",
    "isin": "INF205KA1296",
    "isinRe": "INF205KA1312"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148755",
    "name": "Mirae Asset Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Mirae Asset Corporate Bond Fund Direct Growth Plan",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2021-02-24",
    "closeData": "",
    "isin": "INF769K01HD9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148756",
    "name": "Mirae Asset Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Mirae Asset Corporate Bond Fund Direct IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2021-02-24",
    "closeData": "",
    "isin": "INF769K01HC1",
    "isinRe": "INF769K01HE7"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148757",
    "name": "Mirae Asset Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Mirae Asset Corporate Bond Fund Regular Growth",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2021-02-24",
    "closeData": "",
    "isin": "INF769K01HA5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148758",
    "name": "Mirae Asset Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Mirae Asset Corporate Bond Fund Regular IDCW",
    "minAmount": "Investors can invest under the Scheme during the N",
    "launchDate": "2021-02-24",
    "closeData": "",
    "isin": "INF769K01GZ4",
    "isinRe": "INF769K01HB3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148759",
    "name": "SBI International Access - US Equity FoF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "SBI International Access- US Equity FoF Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-01",
    "closeData": "2021-03-15",
    "isin": "INF200KA1U02",
    "isinRe": "INF200KA1U10"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148760",
    "name": "SBI International Access - US Equity FoF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "SBI International Access - US Equity FoF - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-01",
    "closeData": "2021-03-15",
    "isin": "INF200KA1U28",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148761",
    "name": "SBI International Access - US Equity FoF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "SBI International Access- US Equity FoF Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-01",
    "closeData": "2021-03-15",
    "isin": "INF200KA1U36",
    "isinRe": "INF200KA1U44"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148762",
    "name": "SBI International Access - US Equity FoF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "SBI International Access - US Equity FoF - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-01",
    "closeData": "2021-03-15",
    "isin": "INF200KA1T96",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148763",
    "name": "Kotak Nifty IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty IT ETF",
    "minAmount": "Rs. 5000",
    "launchDate": "2021-02-26",
    "closeData": "",
    "isin": "INF174KA1GC5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148768",
    "name": "DSP Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "DSP Floater Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2021-03-04",
    "closeData": "2021-03-17",
    "isin": "INF740KA1PS7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148769",
    "name": "DSP Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "DSP Floater Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2021-03-04",
    "closeData": "2021-03-17",
    "isin": "INF740KA1PW9",
    "isinRe": "INF740KA1PX7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148770",
    "name": "DSP Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "DSP Floater Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2021-03-04",
    "closeData": "2021-03-17",
    "isin": "INF740KA1PT5",
    "isinRe": "INF740KA1PU3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "148771",
    "name": "DSP Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "DSP Floater Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500/  and any amount thereafter.",
    "launchDate": "2021-03-04",
    "closeData": "2021-03-17",
    "isin": "INF740KA1PV1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148774",
    "name": "Kotak FMP Series 288 - 1177 days",
    "category": "Income",
    "navName": "Kotak FMP Series 288 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-05",
    "closeData": "",
    "isin": "INF174KA1GI2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148775",
    "name": "Kotak FMP Series 288 - 1177 days",
    "category": "Income",
    "navName": "Kotak FMP Series 288 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-05",
    "closeData": "",
    "isin": "INF174KA1GK8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148776",
    "name": "Kotak FMP Series 288 - 1177 days",
    "category": "Income",
    "navName": "Kotak FMP Series 288 - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-05",
    "closeData": "",
    "isin": "INF174KA1GH4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148777",
    "name": "Kotak FMP Series 288 - 1177 days",
    "category": "Income",
    "navName": "Kotak FMP Series 288 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-05",
    "closeData": "",
    "isin": "INF174KA1GJ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148778",
    "name": "Kotak FMP Series 289 - 1179 days",
    "category": "Income",
    "navName": "Kotak FMP Series 289 - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-08",
    "closeData": "",
    "isin": "INF174KA1GL6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148779",
    "name": "Kotak FMP Series 289 - 1179 days",
    "category": "Income",
    "navName": "Kotak FMP Series 289 - Regular Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-08",
    "closeData": "",
    "isin": "INF174KA1GM4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148780",
    "name": "Kotak FMP Series 289 - 1179 days",
    "category": "Income",
    "navName": "Kotak FMP Series 289 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-08",
    "closeData": "",
    "isin": "INF174KA1GN2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148781",
    "name": "Kotak FMP Series 289 - 1179 days",
    "category": "Income",
    "navName": "Kotak FMP Series 289 - Direct Plan - Dividend Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-03-08",
    "closeData": "",
    "isin": "INF174KA1GO0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148786",
    "name": "BANDHAN CRISIL IBX GILT JUNE 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt June 2027 Index Fund Regular Plan-Growth",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BL2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148787",
    "name": "BANDHAN CRISIL IBX GILT JUNE 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt June 2027 Index Fund Regular Plan-IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BM0",
    "isinRe": "INF194KB1BO6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148788",
    "name": "BANDHAN CRISIL IBX GILT JUNE 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt June 2027 Index Fund Direct Plan-IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BQ1",
    "isinRe": "INF194KB1BR9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148789",
    "name": "BANDHAN CRISIL IBX GILT JUNE 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt June 2027 Index Fund Direct Plan-Growth",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BP3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148790",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2028 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt April 2028 Index Fund Regular Plan-Growth",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BS7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148791",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2028 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt April 2028 Index Fund Direct Plan-IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BW9",
    "isinRe": "INF194KB1BX7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148792",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2028 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt April 2028 Index Fund Direct Plan-Growth",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BV1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "148793",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2028 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX Gilt April 2028 Index Fund Regular Plan-IDCW",
    "minAmount": "The minimum application amount shall be Rs.5000/-",
    "launchDate": "2021-03-12",
    "closeData": "",
    "isin": "INF194KB1BT5",
    "isinRe": "INF194KB1BU3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148794",
    "name": "Edelweiss Nifty PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2026 50  50 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-03-10",
    "closeData": "2021-03-16",
    "isin": "INF754K01MA7",
    "isinRe": "INF754K01MB5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148795",
    "name": "Edelweiss Nifty PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-03-10",
    "closeData": "2021-03-16",
    "isin": "INF754K01MD1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148796",
    "name": "Edelweiss Nifty PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-03-10",
    "closeData": "2021-03-16",
    "isin": "INF754K01ME9",
    "isinRe": "INF754K01MF6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "148797",
    "name": "Edelweiss Nifty PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-03-10",
    "closeData": "2021-03-16",
    "isin": "INF754K01LZ6",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148798",
    "name": "Nippon India ETF Nifty SDL Apr 2026 Top 20 Equal Weight",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty SDL Apr 2026 Top 20 Equal Weight",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-03-15",
    "closeData": "",
    "isin": "INF204KC1022",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148799",
    "name": "Axis NIFTY IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis IT ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-03-18",
    "closeData": "",
    "isin": "INF846K01Y96",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "148800",
    "name": "Nippon India ETF Nifty 5 yr Benchmark G-Sec",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India ETF Nifty 5 yr Benchmark G-Sec",
    "minAmount": "Rs. 5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-03-22",
    "closeData": "",
    "isin": "INF204KC1030",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148805",
    "name": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W41",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148806",
    "name": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund - Regular - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W58",
    "isinRe": "INF209KB1W66"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148807",
    "name": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W74",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148809",
    "name": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Midcap 150 Index Fund - Direct - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W82",
    "isinRe": "INF209KB1W90"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148811",
    "name": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1V83",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148812",
    "name": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund-Regular - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1V91",
    "isinRe": "INF209KB1W09"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148814",
    "name": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund-Direct - Payout of IDCW",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W25",
    "isinRe": "INF209KB1W33"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148815",
    "name": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Smallcap 50 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-03-15",
    "closeData": "2021-03-26",
    "isin": "INF209KB1W17",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148821",
    "name": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF - Growth",
    "minAmount": "1000",
    "launchDate": "2021-03-23",
    "closeData": "",
    "isin": "INF109KC1P57",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148822",
    "name": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2021-03-23",
    "closeData": "",
    "isin": "INF109KC1P81",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148823",
    "name": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-03-23",
    "closeData": "",
    "isin": "INF109KC1Q07",
    "isinRe": "INF109KC1P99"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148824",
    "name": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty 100 Low Volatility 30 ETF FOF - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-03-23",
    "closeData": "",
    "isin": "INF109KC1P73",
    "isinRe": "INF109KC1P65"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148827",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-22",
    "closeData": "2021-03-24",
    "isin": "INF200KA1U77",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148828",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498 Days) Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-22",
    "closeData": "2021-03-24",
    "isin": "INF200KA1U85",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148825",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-22",
    "closeData": "2021-03-24",
    "isin": "INF200KA1U51",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148826",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 41 (1498 Days) Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-22",
    "closeData": "2021-03-24",
    "isin": "INF200KA1U69",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148829",
    "name": "Kotak FMP Series 291 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 291- Regular Plan - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2021-03-22",
    "closeData": "",
    "isin": "INF174KA1GU7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148830",
    "name": "Kotak FMP Series 291 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 291 - Direct Plan - Dividend Payout Option",
    "minAmount": "5000",
    "launchDate": "2021-03-22",
    "closeData": "",
    "isin": "INF174KA1GW3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148831",
    "name": "Kotak FMP Series 291 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 291 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-03-22",
    "closeData": "",
    "isin": "INF174KA1GV5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148832",
    "name": "Kotak FMP Series 291 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 291 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-03-22",
    "closeData": "",
    "isin": "INF174KA1GT9",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148833",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Regular Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01198",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148834",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Regular Plan-Daily Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01206",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148836",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Regular Plan-Weekly Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01214",
    "isinRe": "INF0GCD01222"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148837",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Direct Plan-Weekly Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01271",
    "isinRe": "INF0GCD01289"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148839",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Direct Plan-Monthly Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01305",
    "isinRe": "INF0GCD01297"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148841",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Direct Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01255",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148842",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Direct Plan-Daily Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01263",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "148843",
    "name": "TRUSTMF Liquid Fund",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "TRUSTMF Liquid Fund-Regular Plan-Monthly Income Distribution cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-04-08",
    "closeData": "",
    "isin": "INF0GCD01248",
    "isinRe": "INF0GCD01230"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148845",
    "name": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 42 (1857 Days) Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-25",
    "closeData": "2021-03-26",
    "isin": "INF200KA1V01",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148846",
    "name": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-25",
    "closeData": "2021-03-26",
    "isin": "INF200KA1V19",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148847",
    "name": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-25",
    "closeData": "2021-03-26",
    "isin": "INF200KA1U93",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148848",
    "name": "SBI Fixed Maturity Plan (FMP) Series 42 (1857 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 42 (1857 Days) Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-03-25",
    "closeData": "2021-03-26",
    "isin": "INF200KA1V27",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "148882",
    "name": "Canara Robeco Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Canara Robeco Focused Equity Fund - Direct Plan - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-04-23",
    "closeData": "2021-05-07",
    "isin": "INF760K01JS2",
    "isinRe": "INF760K01JR4"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "148883",
    "name": "Canara Robeco Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Canara Robeco Focused Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-04-23",
    "closeData": "2021-05-07",
    "isin": "INF760K01JQ6",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "148884",
    "name": "Canara Robeco Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Canara Robeco Focused Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-04-23",
    "closeData": "2021-05-07",
    "isin": "INF760K01JT0",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "148885",
    "name": "Canara Robeco Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Canara Robeco Focused Equity Fund - Regular Plan - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-04-23",
    "closeData": "2021-05-07",
    "isin": "INF760K01JV6",
    "isinRe": "INF760K01JU8"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148900",
    "name": "HDFC ASSET ALLOCATOR FUND OF FUNDS",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Asset Allocator Fund of Funds - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF179KC1BE5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148901",
    "name": "HDFC ASSET ALLOCATOR FUND OF FUNDS",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Asset Allocator Fund of Funds - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF179KC1BD7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148902",
    "name": "HDFC ASSET ALLOCATOR FUND OF FUNDS",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Asset Allocator Fund of Funds - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF179KC1BB1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148903",
    "name": "HDFC ASSET ALLOCATOR FUND OF FUNDS",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Asset Allocator Fund of Funds - Growth Option -Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF179KC1BA3",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148906",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01952",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148907",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Weekly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01978",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148908",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Fortnightly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AK9",
    "isinRe": "INF00XX01AH5"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148909",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Annually IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AM5",
    "isinRe": "INF00XX01AJ1"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148910",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AF9",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148911",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Fortnightly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AB8",
    "isinRe": "INF00XX01986"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148912",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01960",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148913",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AE2",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148914",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AC6",
    "isinRe": "INF00XX01994"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148915",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AG7",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148916",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AL7",
    "isinRe": "INF00XX01AI3"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148917",
    "name": "ITI Ultra Short Duration Fund",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "ITI Ultra Short Duration Fund - Regular Plan - Annually IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF00XX01AD4",
    "isinRe": "INF00XX01AA0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148918",
    "name": "Aditya Birla Sun Life Multi-Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Aditya Birla Sun Life Multi-Cap Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2021-04-19",
    "closeData": "2021-05-03",
    "isin": "INF209KB1Y23",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148919",
    "name": "Aditya Birla Sun Life Multi-Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Aditya Birla Sun Life Multi-Cap Fund-Regular-IDCW Payout",
    "minAmount": "500",
    "launchDate": "2021-04-19",
    "closeData": "2021-05-03",
    "isin": "INF209KB1Y31",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148920",
    "name": "Aditya Birla Sun Life Multi-Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Aditya Birla Sun Life Multi-Cap Fund-Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2021-04-19",
    "closeData": "2021-05-03",
    "isin": "INF209KB1Y56",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148921",
    "name": "Aditya Birla Sun Life Multi-Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Aditya Birla Sun Life Multi-Cap Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-04-19",
    "closeData": "2021-05-03",
    "isin": "INF209KB1Y49",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148922",
    "name": "quant Quantamental Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Quantamental Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-13",
    "closeData": "",
    "isin": "INF966L01AI3",
    "isinRe": "INF966L01AJ1"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148923",
    "name": "quant Quantamental Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Quantamental Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-13",
    "closeData": "",
    "isin": "INF966L01AH5",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148924",
    "name": "quant Quantamental Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Quantamental Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-13",
    "closeData": "",
    "isin": "INF966L01AL7",
    "isinRe": "INF966L01AM5"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "148925",
    "name": "quant Quantamental Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Quantamental Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-04-13",
    "closeData": "",
    "isin": "INF966L01AK9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148926",
    "name": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF",
    "minAmount": "NFO - Rs. 5000/-. Ongoing - Creation Unit Size",
    "launchDate": "2021-04-23",
    "closeData": "",
    "isin": "INF846K01Z04",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148927",
    "name": "Mirae Asset NYSE FANG+ ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset NYSE FANG + ETF",
    "minAmount": "During NFO Period  Rs. 5000 per application and i",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF769K01HF4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148928",
    "name": "Mirae Asset NYSE FANG + ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset NYSE FANG + ETF Fund of Fund Direct Growth",
    "minAmount": "5000",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF769K01HH0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "148929",
    "name": "Mirae Asset NYSE FANG + ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset NYSE FANG + ETF Fund of Fund Regular Growth",
    "minAmount": "5000",
    "launchDate": "2021-04-19",
    "closeData": "",
    "isin": "INF769K01HG2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148934",
    "name": "Axis NIFTY Healthcare ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis NIFTY Healthcare ETF",
    "minAmount": "NFO - Rs. 5000/-. Ongoing - Creation Unit Size",
    "launchDate": "2021-04-30",
    "closeData": "",
    "isin": "INF846K01Z12",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148939",
    "name": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-27",
    "closeData": "2021-04-28",
    "isin": "INF200KA1V35",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148940",
    "name": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-27",
    "closeData": "2021-04-28",
    "isin": "INF200KA1V43",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148941",
    "name": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-27",
    "closeData": "2021-04-28",
    "isin": "INF200KA1V50",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148942",
    "name": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 43 (1616 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-04-27",
    "closeData": "2021-04-28",
    "isin": "INF200KA1V68",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148943",
    "name": "SBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Next 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2021-04-28",
    "closeData": "2021-05-11",
    "isin": "INF200KA1V76",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148944",
    "name": "SBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Next 50 Index Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2021-04-28",
    "closeData": "2021-05-11",
    "isin": "INF200KA1V84",
    "isinRe": "INF200KA1V92"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148945",
    "name": "SBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Next 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2021-04-28",
    "closeData": "2021-05-11",
    "isin": "INF200KA1W00",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148946",
    "name": "SBI Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Next 50 Index Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2021-04-28",
    "closeData": "2021-05-11",
    "isin": "INF200KA1W18",
    "isinRe": "INF200KA1W26"
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148947",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Direct Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277K015Z8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148948",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Regular Plan-Growth",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277K018Z2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148949",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Regular Plan-IDCW Reinvestment",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277K019Z0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148950",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Regular Plan-IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277KA1018",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148951",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Direct Plan-IDCW Reinvestment",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277K016Z6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "148952",
    "name": "Tata Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Tata Dividend Yield Fund-Direct Plan-IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2021-05-03",
    "closeData": "",
    "isin": "INF277K017Z4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148953",
    "name": "Axis Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Innovation Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-05-10",
    "closeData": "",
    "isin": "INF846K01Z53",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148954",
    "name": "Axis Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Innovation Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-05-10",
    "closeData": "",
    "isin": "INF846K01Z20",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148955",
    "name": "Axis Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Innovation Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-05-10",
    "closeData": "",
    "isin": "INF846K01Z38",
    "isinRe": "INF846K01Z46"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148956",
    "name": "Axis Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis Global Innovation Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-05-10",
    "closeData": "",
    "isin": "INF846K01Z61",
    "isinRe": "INF846K01Z79"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148957",
    "name": "ICICI Prudential Nifty Healthcare ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Healthcare ETF",
    "minAmount": "1000",
    "launchDate": "2021-05-06",
    "closeData": "",
    "isin": "INF109KC1Q72",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "148958",
    "name": "Parag Parikh Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Parag Parikh Conservative Hybrid Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-05-07",
    "closeData": "2021-05-21",
    "isin": "INF879O01175",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "148959",
    "name": "Parag Parikh Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Parag Parikh Conservative Hybrid Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-05-07",
    "closeData": "2021-05-21",
    "isin": "INF879O01209",
    "isinRe": null
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "148960",
    "name": "Parag Parikh Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Parag Parikh Conservative Hybrid Fund - Regular Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2021-05-07",
    "closeData": "2021-05-21",
    "isin": "INF879O01191",
    "isinRe": "INF879O01183"
  },
  {
    "amc": "PPFAS Asset Management Pvt. Ltd.",
    "code": "148961",
    "name": "Parag Parikh Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "Parag Parikh Conservative Hybrid Fund - Direct Plan - Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2021-05-07",
    "closeData": "2021-05-21",
    "isin": "INF879O01167",
    "isinRe": "INF879O01159"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148962",
    "name": "Aditya Birla Sun Life Nifty 50 Equal Weight index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Equal Weight Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-05-19",
    "closeData": "",
    "isin": "INF209KB1Z71",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148963",
    "name": "Aditya Birla Sun Life Nifty 50 Equal Weight index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Equal Weight Index Fund-Direct-IDCW",
    "minAmount": "500",
    "launchDate": "2021-05-19",
    "closeData": "",
    "isin": "INF209KB1Z89",
    "isinRe": "INF209KB1Z97"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148964",
    "name": "Aditya Birla Sun Life Nifty 50 Equal Weight index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Equal Weight Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2021-05-19",
    "closeData": "",
    "isin": "INF209KB1Z55",
    "isinRe": "INF209KB1Z63"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "148965",
    "name": "Aditya Birla Sun Life Nifty 50 Equal Weight index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty 50 Equal Weight Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2021-05-19",
    "closeData": "",
    "isin": "INF209KB1Z48",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148966",
    "name": "SBI Fixed Maturity Plan (FMP) Series 44 (1855 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 44 (1855 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-24",
    "closeData": "2021-06-01",
    "isin": "INF200KA1W34",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148967",
    "name": "SBI Fixed Maturity Plan (FMP) Series 44 (1855 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 44 (1855 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-24",
    "closeData": "2021-06-01",
    "isin": "INF200KA1W59",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148968",
    "name": "SBI Fixed Maturity Plan (FMP) Series 44 (1855 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 44 (1855 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-24",
    "closeData": "2021-06-01",
    "isin": "INF200KA1W67",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148969",
    "name": "SBI Fixed Maturity Plan (FMP) Series 44 (1855 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 44 (1855 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-24",
    "closeData": "2021-06-01",
    "isin": "INF200KA1W42",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148970",
    "name": "ITI Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ITI Value Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-25",
    "closeData": "",
    "isin": "INF00XX01AR4",
    "isinRe": "INF00XX01AS2"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148971",
    "name": "ITI Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ITI Value Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-25",
    "closeData": "",
    "isin": "INF00XX01AO1",
    "isinRe": "INF00XX01AP8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148972",
    "name": "ITI Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ITI Value Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-25",
    "closeData": "",
    "isin": "INF00XX01AQ6",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "148973",
    "name": "ITI Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "ITI Value Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-05-25",
    "closeData": "",
    "isin": "INF00XX01AN3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148974",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Regular Plan-Growth",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1GX1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148975",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Direct Plan-IDCW Reinvestment",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1HC3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148976",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Direct Plan-IDCW Payout",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1HB5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148977",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Regular Plan-IDCW Reinvestment",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1GZ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148978",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Direct Plan-Growth",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1HA7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "148979",
    "name": "Kotak Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 50 Index Fund - Regular Plan-IDCW Payout",
    "minAmount": "Rs. 100/- and in multiples of Re. 1 for purchases",
    "launchDate": "2021-05-31",
    "closeData": "",
    "isin": "INF174KA1GY9",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148980",
    "name": "BANK OF INDIA BLUECHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANK OF INDIA Bluechip Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-06-08",
    "closeData": "2021-06-22",
    "isin": "INF761K01FL3",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148981",
    "name": "BANK OF INDIA BLUECHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANK OF INDIA Bluechip Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-06-08",
    "closeData": "2021-06-22",
    "isin": "INF761K01FK5",
    "isinRe": "INF761K01FM1"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148982",
    "name": "BANK OF INDIA BLUECHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANK OF INDIA Bluechip Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-06-08",
    "closeData": "2021-06-22",
    "isin": "INF761K01FO7",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "148983",
    "name": "BANK OF INDIA BLUECHIP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BANK OF INDIA Bluechip Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-06-08",
    "closeData": "2021-06-22",
    "isin": "INF761K01FN9",
    "isinRe": "INF761K01FP4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148984",
    "name": "HDFC Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Banking & Financial Services Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-06-11",
    "closeData": "2021-06-25",
    "isin": "INF179KC1BK2",
    "isinRe": "INF179KC1BL0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148985",
    "name": "HDFC Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Banking & Financial Services Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-06-11",
    "closeData": "2021-06-25",
    "isin": "INF179KC1BH8",
    "isinRe": "INF179KC1BI6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148986",
    "name": "HDFC Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Banking & Financial Services Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-06-11",
    "closeData": "2021-06-25",
    "isin": "INF179KC1BG0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "148987",
    "name": "HDFC Banking & Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Banking & Financial Services Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-06-11",
    "closeData": "2021-06-25",
    "isin": "INF179KC1BJ4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148988",
    "name": "ICICI Prudential Flexicap fund-",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ICICI Prudential Flexicap Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2021-06-28",
    "closeData": "",
    "isin": "INF109KC1Q98",
    "isinRe": "INF109KC1R06"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148989",
    "name": "ICICI Prudential Flexicap fund-",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ICICI Prudential Flexicap Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2021-06-28",
    "closeData": "",
    "isin": "INF109KC1Q80",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148990",
    "name": "ICICI Prudential Flexicap fund-",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ICICI Prudential Flexicap Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-06-28",
    "closeData": "",
    "isin": "INF109KC1R14",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "148991",
    "name": "ICICI Prudential Flexicap fund-",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ICICI Prudential Flexicap Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2021-06-28",
    "closeData": "",
    "isin": "INF109KC1R22",
    "isinRe": "INF109KC1R30"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148992",
    "name": "Axis Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Quant Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-06-11",
    "closeData": "",
    "isin": "INF846K011A2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148993",
    "name": "Axis Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Quant Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-06-11",
    "closeData": "",
    "isin": "INF846K01Z87",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148994",
    "name": "Axis Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Quant Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-06-11",
    "closeData": "",
    "isin": "INF846K01Z95",
    "isinRe": "INF846K010A4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "148995",
    "name": "Axis Quant Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Quant Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-06-11",
    "closeData": "",
    "isin": "INF846K012A0",
    "isinRe": "INF846K013A8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148996",
    "name": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-06-14",
    "closeData": "2021-06-16",
    "isin": "INF200KA1W75",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148997",
    "name": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 45 (1840Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-06-14",
    "closeData": "2021-06-16",
    "isin": "INF200KA1W83",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148998",
    "name": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-06-14",
    "closeData": "2021-06-16",
    "isin": "INF200KA1X09",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148999",
    "name": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 45 (1840 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-06-14",
    "closeData": "2021-06-16",
    "isin": "INF200KA1W91",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149000",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Monthly Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1042",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149001",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Quarterly Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1059",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149002",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Periodic Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1034",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149003",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1091",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149004",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Monthly Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1117",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149036",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Periodic Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1133",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149037",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Monthly Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1075",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149038",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Quarterly Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1158",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149005",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Quarterly Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1125",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149006",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Periodic Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1109",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149007",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund -Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1026",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149033",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Periodic Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1067",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149034",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Direct Plan-IDCW Quarterly Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1083",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149035",
    "name": "Tata Floating Rate Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Tata Floating Rate Fund-Regular Plan-IDCW Monthly Dividend Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF277KA1141",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149008",
    "name": "Nippon India Nifty Pharma ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India Nifty Pharma ETF",
    "minAmount": "Rs. 1000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-06-21",
    "closeData": "",
    "isin": "INF204KC1089",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149012",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Regular - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1379",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149013",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Direct - Discretionary IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1411",
    "isinRe": "INF205KA1445"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149014",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Direct - Quarterly IDCW (Payout / Reinvestment))",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1403",
    "isinRe": "INF205KA1437"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149009",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Regular - Quarterly IDCW (Payout / Reinvestment)",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1353",
    "isinRe": "INF205KA1387"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149010",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Regular - Discretionary IDCW(Payout / Reinvestment))",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1361",
    "isinRe": "INF205KA1395"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149011",
    "name": "Invesco India Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Invesco India Medium Duration Fund - Direct - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2021-06-29",
    "closeData": "",
    "isin": "INF205KA1429",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149015",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days) - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-06-22",
    "closeData": "",
    "isin": "INF209KB10A6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149016",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days) - Regular Plan - Payout of IDCW option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-06-22",
    "closeData": "",
    "isin": "INF209KB11A4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149017",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days) - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-06-22",
    "closeData": "",
    "isin": "INF209KB12A2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149018",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TI (1837 days) - Direct Plan - Payout of IDCW option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-06-22",
    "closeData": "",
    "isin": "INF209KB13A0",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149019",
    "name": "PGIM India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "PGIM India Small Cap Fund - Direct Plan- Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-07-09",
    "closeData": "",
    "isin": "INF663L01W06",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149020",
    "name": "PGIM India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "PGIM India Small Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-07-09",
    "closeData": "",
    "isin": "INF663L01W30",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149031",
    "name": "PGIM India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "PGIM India Small Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-07-09",
    "closeData": "",
    "isin": "INF663L01W14",
    "isinRe": "INF663L01W22"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149032",
    "name": "PGIM India Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "PGIM India Small Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs.5000 & in multiples of Re.1 thereafter",
    "launchDate": "2021-07-09",
    "closeData": "",
    "isin": "INF663L01W48",
    "isinRe": "INF663L01W55"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149021",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01AT0",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149022",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01AY0",
    "isinRe": "INF00XX01AU8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149023",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Direct Plan - Monthly IDCWOption",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BH3",
    "isinRe": "INF00XX01BD2"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149024",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01AZ7",
    "isinRe": "INF00XX01AV6"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149025",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BI1",
    "isinRe": "INF00XX01BE0"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149026",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Regular Plan - Half Yearly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BA8",
    "isinRe": "INF00XX01AW4"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149027",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Regular Plan - Annually IDCWOption",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BB6",
    "isinRe": "INF00XX01AX2"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149028",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Direct Plan - Half Yearly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BJ9",
    "isinRe": "INF00XX01BF7"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149029",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BC4",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149030",
    "name": "ITI Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "ITI Dynamic Bond Fund - Direct Plan - Annually IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-06-25",
    "closeData": "",
    "isin": "INF00XX01BK7",
    "isinRe": "INF00XX01BG5"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149040",
    "name": "Navi Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty 50 Index Fund-Regular Plan-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2021-07-03",
    "closeData": "2021-07-12",
    "isin": "INF959L01FQ0",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149039",
    "name": "Navi Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty 50 Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2021-07-03",
    "closeData": "2021-07-12",
    "isin": "INF959L01FP2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149041",
    "name": "SBI Nifty Consumption ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "SBI Nifty Consumption ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof.",
    "launchDate": "2021-06-30",
    "closeData": "2021-07-14",
    "isin": "INF200KA1X17",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149042",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-02",
    "closeData": "2021-07-06",
    "isin": "INF200KA1X33",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149043",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-02",
    "closeData": "2021-07-06",
    "isin": "INF200KA1X41",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149044",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-02",
    "closeData": "2021-07-06",
    "isin": "INF200KA1X58",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149045",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 46 (1850 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-02",
    "closeData": "2021-07-06",
    "isin": "INF200KA1X25",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148683",
    "name": "SBI Retirement Benefit Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Plan - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S55",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148691",
    "name": "SBI Retirement Benefit Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Plan - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S48",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148694",
    "name": "SBI Retirement Benefit Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Plan - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S63",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148698",
    "name": "SBI Retirement Benefit Fund - Aggressive Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Aggressive Plan - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1S30",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148690",
    "name": "SBI Retirement Benefit Fund - Conservative Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Hybrid Plan - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T70",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148692",
    "name": "SBI Retirement Benefit Fund - Conservative Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Hybrid Plan - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T54",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148693",
    "name": "SBI Retirement Benefit Fund - Conservative Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Hybrid Plan - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T88",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148697",
    "name": "SBI Retirement Benefit Fund - Conservative Hybrid Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Hybrid Plan - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T62",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148687",
    "name": "SBI Retirement Benefit Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Plan - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T21",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148688",
    "name": "SBI Retirement Benefit Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Plan - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T39",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148689",
    "name": "SBI Retirement Benefit Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Plan - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T47",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "148696",
    "name": "SBI Retirement Benefit Fund - Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "SBI Retirement Benefit Fund - Conservative Plan - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-01-20",
    "closeData": "2021-02-03",
    "isin": "INF200KA1T13",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149052",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K019A5",
    "isinRe": "INF846K010B2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149053",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K016B9",
    "isinRe": "INF846K015B1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149054",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K019B3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149055",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K016A1",
    "isinRe": "INF846K015A3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149046",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K018B5",
    "isinRe": "INF846K017B7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149047",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K014B4",
    "isinRe": "INF846K013B6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149048",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K012B8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149049",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K014A6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149050",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Direct Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K011B0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149051",
    "name": "Axis Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Axis Floater Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF846K018A7",
    "isinRe": "INF846K017A9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149056",
    "name": "Kotak Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Innovation Fund of Fund - Regular Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-07-08",
    "closeData": "",
    "isin": "INF174KA1HD1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149058",
    "name": "Kotak Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Innovation Fund of Fund- Regular Plan -IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-07-08",
    "closeData": "",
    "isin": "INF174KA1HE9",
    "isinRe": "INF174KA1HF6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149059",
    "name": "Kotak Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Innovation Fund of Fund- Direct Plan -Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-07-08",
    "closeData": "",
    "isin": "INF174KA1HG4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149060",
    "name": "Kotak Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Kotak Global Innovation Fund of Fund- Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs 1 for purchase",
    "launchDate": "2021-07-08",
    "closeData": "",
    "isin": "INF174KA1HH2",
    "isinRe": "INF174KA1HI0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149062",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days) - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF209KB16A3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149063",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF209KB17A1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149064",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days) - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF209KB14A8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149065",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TJ (1838 days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2021-07-12",
    "closeData": "",
    "isin": "INF209KB15A5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149066",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Direct Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1174",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149067",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Direct Plan-IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1182",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149068",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1190",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149069",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Regular Plan-IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1216",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149070",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Regular Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1208",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149071",
    "name": "Tata Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Business Cycle Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2021-07-16",
    "closeData": "",
    "isin": "INF277KA1166",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149072",
    "name": "ICICI Prudential Nifty FMCG ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty FMCG ETF",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "",
    "isin": "INF109KC1R48",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149077",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Direct Plan-Weekly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01388",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149078",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Direct Plan-Monthly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01396",
    "isinRe": "INF0GCD01404"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149079",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Direct Plan-Quarterly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01412",
    "isinRe": "INF0GCD01420"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149080",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Regular Plan-Monthly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01339",
    "isinRe": "INF0GCD01347"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149073",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Regular Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01313",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149074",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Regular Plan-Weekly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01321",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149075",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Regular Plan-Quarterly Income Distribution Cum Capital Withdrawal",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01354",
    "isinRe": "INF0GCD01362"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149076",
    "name": "TRUSTMF Short Term Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "TRUSTMF Short Term Fund-Direct Plan-Growth",
    "minAmount": "1000",
    "launchDate": "2021-07-20",
    "closeData": "2021-08-03",
    "isin": "INF0GCD01370",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149084",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 47 (1434 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 47 (1434 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-19",
    "closeData": "2021-07-22",
    "isin": "INF200KA1X74",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149081",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 47 (1434 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 47 (1434 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-19",
    "closeData": "2021-07-22",
    "isin": "INF200KA1X90",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149082",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 47 (1434 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 47 (1434 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-19",
    "closeData": "2021-07-22",
    "isin": "INF200KA1X82",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149083",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 47 (1434 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) Series 47 (1434 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-19",
    "closeData": "2021-07-22",
    "isin": "INF200KA1X66",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "149085",
    "name": "Canara Robeco Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Canara Robeco Value Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-13",
    "closeData": "2021-08-27",
    "isin": "INF760K01JW4",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "149086",
    "name": "Canara Robeco Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Canara Robeco Value Fund - Direct Plan - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-13",
    "closeData": "2021-08-27",
    "isin": "INF760K01JY0",
    "isinRe": "INF760K01JX2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "149087",
    "name": "Canara Robeco Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Canara Robeco Value Fund - Regular Plan - IDCW (Payout/Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-13",
    "closeData": "2021-08-27",
    "isin": "INF760K01KB6",
    "isinRe": "INF760K01KA8"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "149088",
    "name": "Canara Robeco Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Canara Robeco Value Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-13",
    "closeData": "2021-08-27",
    "isin": "INF760K01JZ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149089",
    "name": "Nippon India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Nippon India Flexi Cap Fund - Regular Plan - Growth Plan - Growth Option",
    "minAmount": "500 & in multiples of Re. 1 thereafter",
    "launchDate": "2021-07-26",
    "closeData": "",
    "isin": "INF204KC1097",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149094",
    "name": "Nippon India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Nippon India Flexi Cap Fund - Direct Plan - Growth Plan - Growth Option",
    "minAmount": "500 & in multiples of Re. 1 thereafter",
    "launchDate": "2021-07-26",
    "closeData": "",
    "isin": "INF204KC1121",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149095",
    "name": "Nippon India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Nippon India Flexi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "500 & in multiples of Re. 1 thereafter",
    "launchDate": "2021-07-26",
    "closeData": "",
    "isin": "INF204KC1139",
    "isinRe": "INF204KC1147"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149096",
    "name": "Nippon India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Nippon India Flexi Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "500 & in multiples of Re. 1 thereafter",
    "launchDate": "2021-07-26",
    "closeData": "",
    "isin": "INF204KC1105",
    "isinRe": "INF204KC1113"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149090",
    "name": "UTI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "UTI Focused Equity Fund - Regular Plan - Growth Option",
    "minAmount": "Initial invt is Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-18",
    "isin": "INF789F1AUY5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149091",
    "name": "UTI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "UTI Focused Equity Fund - Direct Plan - Growth Option",
    "minAmount": "Initial invt is Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-18",
    "isin": "INF789F1AVA3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149092",
    "name": "UTI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "UTI Focused Equity Fund - Regular Plan - IDCW Payout Option",
    "minAmount": "Initial invt is Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-18",
    "isin": "INF789F1AUZ2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149093",
    "name": "UTI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "UTI Focused Equity Fund - Direct Plan - IDCW Payout Option",
    "minAmount": "Initial invt is Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-18",
    "isin": "INF789F1AVB1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149097",
    "name": "BANDHAN US EQUITY FUND OF FUND",
    "category": "Other Scheme - FoF Overseas",
    "navName": "BANDHAN US EQUITY FUND OF FUND - IDCW - REGULAR PLAN",
    "minAmount": "Rs.1000/- any amount thereafter",
    "launchDate": "2021-07-29",
    "closeData": "",
    "isin": "INF194KB1CD7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149098",
    "name": "BANDHAN US EQUITY FUND OF FUND",
    "category": "Other Scheme - FoF Overseas",
    "navName": "BANDHAN US EQUITY FUND OF FUND - GROWTH - DIRECT PLAN",
    "minAmount": "Rs.1000/- any amount thereafter",
    "launchDate": "2021-07-29",
    "closeData": "",
    "isin": "INF194KB1CF2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149099",
    "name": "BANDHAN US EQUITY FUND OF FUND",
    "category": "Other Scheme - FoF Overseas",
    "navName": "BANDHAN US EQUITY FUND OF FUND - IDCW - DIRECT PLAN",
    "minAmount": "Rs.1000/- any amount thereafter",
    "launchDate": "2021-07-29",
    "closeData": "",
    "isin": "INF194KB1CG0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149100",
    "name": "BANDHAN US EQUITY FUND OF FUND",
    "category": "Other Scheme - FoF Overseas",
    "navName": "BANDHAN US EQUITY FUND OF FUND - GROWTH - REGULAR PLAN",
    "minAmount": "Rs.1000/- any amount thereafter",
    "launchDate": "2021-07-29",
    "closeData": "",
    "isin": "INF194KB1CC9",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149101",
    "name": "Mahindra Manulife Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mahindra Manulife Flexi Cap Fund - Regular Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "",
    "isin": "INF174V01AP8",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149102",
    "name": "Mahindra Manulife Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mahindra Manulife Flexi Cap Fund - Regular Plan -IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "",
    "isin": "INF174V01AR4",
    "isinRe": "INF174V01AQ6"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149103",
    "name": "Mahindra Manulife Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mahindra Manulife Flexi Cap Fund - Direct Plan -IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "",
    "isin": "INF174V01AU8",
    "isinRe": "INF174V01AT0"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149104",
    "name": "Mahindra Manulife Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mahindra Manulife Flexi Cap Fund - Direct Plan -Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "",
    "isin": "INF174V01AS2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149105",
    "name": "Mirae Asset Nifty Financial Services ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty Financial Services ETF",
    "minAmount": "During NFO Period  Rs. 5000",
    "launchDate": "2021-07-22",
    "closeData": "",
    "isin": "INF769K01HI8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149106",
    "name": "HDFC NIFTY50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY50 Equal weight Index Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-13",
    "isin": "INF179KC1BN6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149107",
    "name": "HDFC NIFTY50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY50 Equal weight Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-08-04",
    "closeData": "2021-08-13",
    "isin": "INF179KC1BM8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149108",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "2021-08-03",
    "isin": "INF200KA1Y32",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149109",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "2021-08-03",
    "isin": "INF200KA1Y08",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149110",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "2021-08-03",
    "isin": "INF200KA1Y16",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149111",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 48 (1458 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-07-30",
    "closeData": "2021-08-03",
    "isin": "INF200KA1Y24",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149112",
    "name": "Mirae Asset Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Mirae Asset Money Market Fund Direct Growth",
    "minAmount": "a minimum investment of Rs.5000/",
    "launchDate": "2021-08-04",
    "closeData": "",
    "isin": "INF769K01HN8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149113",
    "name": "Mirae Asset Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Mirae Asset Money Market Fund Direct IDCW",
    "minAmount": "a minimum investment of Rs.5000/",
    "launchDate": "2021-08-04",
    "closeData": "",
    "isin": "INF769K01HM0",
    "isinRe": "INF769K01HO6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149114",
    "name": "Mirae Asset Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Mirae Asset Money Market Fund Regular IDCW",
    "minAmount": "a minimum investment of Rs.5000/",
    "launchDate": "2021-08-04",
    "closeData": "",
    "isin": "INF769K01HJ6",
    "isinRe": "INF769K01HL2"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149115",
    "name": "Mirae Asset Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Mirae Asset Money Market Fund Regular Growth",
    "minAmount": "a minimum investment of Rs.5000/",
    "launchDate": "2021-08-04",
    "closeData": "",
    "isin": "INF769K01HK4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149116",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Regular Plan -  Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HO9",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149117",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Direct Plan -  Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HG5",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149118",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HP6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149119",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HH3",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149120",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Regular Plan -  Weekly IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HQ4",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149121",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Direct Plan -  Weekly IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HI1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149122",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Regular Plan -  Monthly IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HS0",
    "isinRe": "INF582M01HR2"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "149123",
    "name": "Union Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "Union Money Market Fund - Direct Plan -  Monthly IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs.1 thereafter",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF582M01HK7",
    "isinRe": "INF582M01HJ9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149131",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 1 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-08-13",
    "closeData": "",
    "isin": "INF204KC1154",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149128",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 1 - Regular Plan - Income Distribution cum Capital Withdrawal Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-08-13",
    "closeData": "",
    "isin": "INF204KC1162",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149129",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 1- Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-08-13",
    "closeData": "",
    "isin": "INF204KC1170",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149130",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 1 - Direct Plan - Income Distribution Cum Capital Withdrawal Plan",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-08-13",
    "closeData": "",
    "isin": "INF204KC1188",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149132",
    "name": "SBI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Balanced Advantage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-12",
    "closeData": "2021-08-25",
    "isin": "INF200KA1Y40",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149133",
    "name": "SBI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Balanced Advantage Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-12",
    "closeData": "2021-08-25",
    "isin": "INF200KA1Y57",
    "isinRe": "INF200KA1Y65"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149134",
    "name": "SBI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Balanced Advantage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-12",
    "closeData": "2021-08-25",
    "isin": "INF200KA1Y73",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149135",
    "name": "SBI Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "SBI Balanced Advantage Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-12",
    "closeData": "2021-08-25",
    "isin": "INF200KA1Y81",
    "isinRe": "INF200KA1Y99"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149136",
    "name": "Kotak FMP Series 292",
    "category": "Income",
    "navName": "Kotak FMP Series 292 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-08-10",
    "closeData": "",
    "isin": "INF174KA1HK6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149137",
    "name": "Kotak FMP Series 292",
    "category": "Income",
    "navName": "Kotak FMP Series 292 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2021-08-10",
    "closeData": "",
    "isin": "INF174KA1HL4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149138",
    "name": "Kotak FMP Series 292",
    "category": "Income",
    "navName": "Kotak FMP Series 292 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2021-08-10",
    "closeData": "",
    "isin": "INF174KA1HN0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149139",
    "name": "Kotak FMP Series 292",
    "category": "Income",
    "navName": "Kotak FMP Series 292 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-08-10",
    "closeData": "",
    "isin": "INF174KA1HM2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149140",
    "name": "Baroda BNP Paribas Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Business Cycle Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF955L01KH7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149141",
    "name": "Baroda BNP Paribas Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Business Cycle Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF955L01KK1",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149142",
    "name": "Baroda BNP Paribas Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Business Cycle Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF955L01KI5",
    "isinRe": "INF955L01KJ3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "149143",
    "name": "Baroda BNP Paribas Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Baroda BNP Paribas Business Cycle Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-08-24",
    "closeData": "",
    "isin": "INF955L01KL9",
    "isinRe": "INF955L01KM7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149146",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-18",
    "closeData": "2021-08-23",
    "isin": "INF200KA1Z15",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149147",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-18",
    "closeData": "2021-08-23",
    "isin": "INF200KA1Z23",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149144",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-18",
    "closeData": "2021-08-23",
    "isin": "INF200KA1Z31",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149145",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 49 (1823 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-08-18",
    "closeData": "2021-08-23",
    "isin": "INF200KA1Z07",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149148",
    "name": "Kotak FMP Series 293 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 293- Regular Plan- Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-26",
    "closeData": "",
    "isin": "INF174KA1HO8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149149",
    "name": "Kotak FMP Series 293 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 293- Direct Plan - Growth",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-26",
    "closeData": "",
    "isin": "INF174KA1HQ3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149150",
    "name": "Kotak FMP Series 293 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 293 - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-26",
    "closeData": "",
    "isin": "INF174KA1HP5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149151",
    "name": "Kotak FMP Series 293 - 1826 days",
    "category": "Income",
    "navName": "Kotak FMP Series 293 - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-08-26",
    "closeData": "",
    "isin": "INF174KA1HR1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149156",
    "name": "Axis NIFTY India Consumption ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis NIFTY India Consumption ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-08-30",
    "closeData": "",
    "isin": "INF846K016C7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149157",
    "name": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF - Growth",
    "minAmount": "1000",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF109KC1R55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149158",
    "name": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF109KC1R89",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149159",
    "name": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF109KC1R71",
    "isinRe": "INF109KC1R63"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149160",
    "name": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Nifty Alpha Low - Volatility 30 ETF FOF - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF109KC1S05",
    "isinRe": "INF109KC1R97"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149164",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 2 - Direct Plan - Income Distribution cum Capital withdrawal Plan - Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2021-09-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149161",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 2 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2021-09-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149162",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 2 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2021-09-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149163",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 2 - Regular Plan - Income Distribution cum Capital Withdrawal Plan - Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2021-09-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149165",
    "name": "Axis Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Axis Value Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-02",
    "closeData": "",
    "isin": "INF846K014C2",
    "isinRe": "INF846K015C9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149166",
    "name": "Axis Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Axis Value Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-02",
    "closeData": "",
    "isin": "INF846K010C0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149167",
    "name": "Axis Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Axis Value Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-02",
    "closeData": "",
    "isin": "INF846K013C4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149168",
    "name": "Axis Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Axis Value Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-02",
    "closeData": "",
    "isin": "INF846K011C8",
    "isinRe": "INF846K012C6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149169",
    "name": "Mirae Asset S&P 500 Top 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset S&P 500 Top 50 ETF",
    "minAmount": "Rs. 5000 per application",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF769K01HP3",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149170",
    "name": "Mirae Asset S&P 500 Top 50 ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset S&P 500 Top 50 ETF Fund of Fund Direct Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF769K01HR9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149171",
    "name": "Mirae Asset S&P 500 Top 50 ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset S&P 500 Top 50 ETF Fund of Fund Regular Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-09-01",
    "closeData": "",
    "isin": "INF769K01HQ1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149173",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days) - Regular Plan -Income Distribution Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-02",
    "closeData": "2021-09-06",
    "isin": "INF200KA1Z56",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149174",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-02",
    "closeData": "2021-09-06",
    "isin": "INF200KA1Z64",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149175",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days) - Direct Plan - Income Distribution Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-02",
    "closeData": "2021-09-06",
    "isin": "INF200KA1Z72",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149172",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 50 (1843 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-02",
    "closeData": "2021-09-06",
    "isin": "INF200KA1Z49",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149180",
    "name": "HDFC Developed World Indexes Fund of Funds",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HDFC Developed World Indexes Fund of Funds - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-09-17",
    "closeData": "2021-10-01",
    "isin": "INF179KC1BO4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149181",
    "name": "HDFC Developed World Indexes Fund of Funds",
    "category": "Other Scheme - FoF Overseas",
    "navName": "HDFC Developed World Indexes Fund of Funds - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-09-17",
    "closeData": "2021-10-01",
    "isin": "INF179KC1BP1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149182",
    "name": "Kotak Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Kotak Multicap Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 for purchase",
    "launchDate": "2021-09-08",
    "closeData": "",
    "isin": "INF174KA1HS9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149183",
    "name": "Kotak Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Kotak Multicap Fund- Regular Plan -IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 for purchase",
    "launchDate": "2021-09-08",
    "closeData": "",
    "isin": "INF174KA1HT7",
    "isinRe": "INF174KA1HU5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149185",
    "name": "Kotak Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Kotak Multicap Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 for purchase",
    "launchDate": "2021-09-08",
    "closeData": "",
    "isin": "INF174KA1HV3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149187",
    "name": "Kotak Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Kotak Multicap Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1 for purchase",
    "launchDate": "2021-09-08",
    "closeData": "",
    "isin": "INF174KA1HW1",
    "isinRe": "INF174KA1HX9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149188",
    "name": "DSP FMP Series - 264 - 60M - 17D",
    "category": "Income",
    "navName": "DSP FMP Series - 264 - 60M - 17D - Regular Plan - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-09-07",
    "closeData": "",
    "isin": "INF740KA1QE5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149189",
    "name": "DSP FMP Series - 264 - 60M - 17D",
    "category": "Income",
    "navName": "DSP FMP Series - 264 - 60M - 17D - Regular - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-09-07",
    "closeData": "",
    "isin": "INF740KA1QF2",
    "isinRe": "INF740KA1QG0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149190",
    "name": "DSP FMP Series - 264 - 60M - 17D",
    "category": "Income",
    "navName": "DSP FMP Series - 264 - 60M - 17D - Direct - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-09-07",
    "closeData": "",
    "isin": "INF740KA1QH8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149191",
    "name": "DSP FMP Series - 264 - 60M - 17D",
    "category": "Income",
    "navName": "DSP FMP Series - 264 - 60M - 17D - Direct - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-09-07",
    "closeData": "",
    "isin": "INF740KA1QI6",
    "isinRe": "INF740KA1QJ4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149194",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days) - Direct Plan - Income Distribution Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-14",
    "closeData": "2021-09-15",
    "isin": "INF200KA11A5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149195",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-14",
    "closeData": "2021-09-15",
    "isin": "INF200KA10A7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149196",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days) - Regular Plan - Income Distribution Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-14",
    "closeData": "2021-09-15",
    "isin": "INF200KA1Z98",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149197",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 51 (1846 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-14",
    "closeData": "2021-09-15",
    "isin": "INF200KA1Z80",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149198",
    "name": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2021-09-15",
    "closeData": "",
    "isin": "INF209KB16B1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149199",
    "name": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-09-15",
    "closeData": "",
    "isin": "INF209KB18B7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149200",
    "name": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2021-09-15",
    "closeData": "",
    "isin": "INF209KB19B5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149201",
    "name": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Plus PSU Bond SEP 2026 60 40 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2021-09-15",
    "closeData": "",
    "isin": "INF209KB17B9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149226",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Direct Plan Quarterly - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1T12",
    "isinRe": "INF109KC1T38"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149227",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Direct Plan Annual - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1T46",
    "isinRe": "INF109KC1T53"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149208",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1S13",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149210",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Direct Plan Growth",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1S88",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149222",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Weekly - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1S39",
    "isinRe": "INF109KC1S21"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149223",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Quarterly - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1S47",
    "isinRe": "INF109KC1S54"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149224",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Annual - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1S62",
    "isinRe": "INF109KC1S70"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149225",
    "name": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty PSU Bond Plus SDL Sep 2027 40 60 Index Fund - Direct Plan Weekly - IDCW",
    "minAmount": "Rs. 1000/- and any amount thereafter",
    "launchDate": "2021-09-16",
    "closeData": "",
    "isin": "INF109KC1T04",
    "isinRe": "INF109KC1S96"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149212",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Cumulative Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149213",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Direct Plan Cumulative Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149214",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Quarterly Dividend Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149215",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Half Yearly Dividend Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149216",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Direct Plan Quarterly Dividend Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149217",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1828 Days Plan A - Direct Plan Half Yearly Dividend Option",
    "minAmount": "Rs. 5000 & in multiples of Rs.10 thereafter",
    "launchDate": "2021-09-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149218",
    "name": "ICICI Prudential NASDAQ 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential NASDAQ 100 Index Fund - Growth",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2021-09-27",
    "closeData": "",
    "isin": "INF109KC1U27",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149219",
    "name": "ICICI Prudential NASDAQ 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential NASDAQ 100 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2021-09-27",
    "closeData": "",
    "isin": "INF109KC1U50",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149220",
    "name": "ICICI Prudential NASDAQ 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential NASDAQ 100 Index Fund - IDCW",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2021-09-27",
    "closeData": "",
    "isin": "INF109KC1U35",
    "isinRe": "INF109KC1U43"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149221",
    "name": "ICICI Prudential NASDAQ 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential NASDAQ 100 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- (plus in multiple of Re. 1)",
    "launchDate": "2021-09-27",
    "closeData": "",
    "isin": "INF109KC1U68",
    "isinRe": "INF109KC1U76"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149230",
    "name": "Mahindra Manulife Asia Pacific REITs FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mahindra Manulife Asia Pacific REITs FOF - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-28",
    "closeData": "",
    "isin": "INF174V01AY0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149231",
    "name": "Mahindra Manulife Asia Pacific REITs FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mahindra Manulife Asia Pacific REITs FOF - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-28",
    "closeData": "",
    "isin": "INF174V01BA8",
    "isinRe": "INF174V01AZ7"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149228",
    "name": "Mahindra Manulife Asia Pacific REITs FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mahindra Manulife Asia Pacific REITs FOF - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-28",
    "closeData": "",
    "isin": "INF174V01AV6",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149229",
    "name": "Mahindra Manulife Asia Pacific REITs FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mahindra Manulife Asia Pacific REITs FOF - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-28",
    "closeData": "",
    "isin": "INF174V01AX2",
    "isinRe": "INF174V01AW4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149232",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-27",
    "isin": "INF200KA14A9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149233",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days) - Regular Plan - Income Distribution Cum Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-27",
    "isin": "INF200KA13A1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149234",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days) - Direct Plan - Income Distribution Cum Withdrawal (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-27",
    "isin": "INF200KA15A6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149235",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 52 (1848 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-27",
    "isin": "INF200KA12A3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149236",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 3 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149237",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 3 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149238",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 3 - Regular Plan - Income Distribution cum Capital withdrawal Plan - Payout Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149239",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIII - Series 3 - Direct Plan - Income Distribution cum Capital withdrawal Plan - Payout option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149240",
    "name": "Motilal Oswal 5 Year G-Sec Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal 5 Year G-Sec Fund Of Fund Direct -Growth",
    "minAmount": "Rs.500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-30",
    "isin": "INF247L01AQ1",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149241",
    "name": "Motilal Oswal 5 Year G-Sec Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal 5 Year G-Sec Fund Of Fund Regular -Growth",
    "minAmount": "Rs.500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-09-24",
    "closeData": "2021-09-30",
    "isin": "INF247L01AR9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149242",
    "name": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": "INF846K010D8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149243",
    "name": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": "INF846K017C5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149244",
    "name": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Nifty AAA Bond Plus SDL April 2026 50 50 ETF FOF - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": "INF846K018C3",
    "isinRe": "INF846K019C1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149245",
    "name": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Nifty AAA Bond Plus SDL Apr 2026 50 50 ETF FOF - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof",
    "launchDate": "2021-09-30",
    "closeData": "",
    "isin": "INF846K011D6",
    "isinRe": "INF846K012D4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149246",
    "name": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2021-09-30",
    "closeData": "2021-10-08",
    "isin": "INF754K01MU5",
    "isinRe": "INF754K01MV3"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149247",
    "name": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-09-30",
    "closeData": "2021-10-08",
    "isin": "INF754K01MT7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149248",
    "name": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2021-09-30",
    "closeData": "2021-10-08",
    "isin": "INF754K01MQ3",
    "isinRe": "INF754K01MR1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149249",
    "name": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY PSU Bond Plus SDL Apr 2027 50 50 Index Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2021-09-30",
    "closeData": "2021-10-08",
    "isin": "INF754K01MP5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115943",
    "name": "Tata Retirement Savings Fund-Moderate Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund- Moderate Plan-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01FH8",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119255",
    "name": "Tata Retirement Savings Fund-Moderate Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund- Moderate Plan -Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01QN3",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "115944",
    "name": "Tata Retirement Savings Fund-Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund- Conservative Plan-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01FI6",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "119256",
    "name": "Tata Retirement Savings Fund-Conservative Plan",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Tata Retirement Savings Fund- Conservative Plan-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-10-07",
    "closeData": "",
    "isin": "INF277K01QM5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149250",
    "name": "Edelweiss Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 50 Index Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NB3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149251",
    "name": "Edelweiss Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 50 Index Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NC1",
    "isinRe": "INF754K01ND9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149252",
    "name": "Edelweiss Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 50 Index Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01MX9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149253",
    "name": "Edelweiss Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 50 Index Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01MY7",
    "isinRe": "INF754K01MZ4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149254",
    "name": "Edelweiss Nifty 100 Quality 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 100 Quality 30 Index Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NJ6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149255",
    "name": "Edelweiss Nifty 100 Quality 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 100 Quality 30 Index Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NK4",
    "isinRe": "INF754K01NL2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149256",
    "name": "Edelweiss Nifty 100 Quality 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 100 Quality 30 Index Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NF4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149257",
    "name": "Edelweiss Nifty 100 Quality 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty 100 Quality 30 Index Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-07",
    "closeData": "",
    "isin": "INF754K01NG2",
    "isinRe": "INF754K01NH0"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "149259",
    "name": "LIC MF Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "LIC MF Balanced Advantage Fund-Regular Plan-Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2021-10-20",
    "closeData": "2021-11-03",
    "isin": "INF767K01QV6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "149260",
    "name": "LIC MF Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "LIC MF Balanced Advantage Fund-Direct Plan-IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2021-10-20",
    "closeData": "2021-11-03",
    "isin": "INF767K01QZ7",
    "isinRe": "INF767K01RA8"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "149261",
    "name": "LIC MF Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "LIC MF Balanced Advantage Fund-Direct Plan-Growth",
    "minAmount": "Rs.5000/-",
    "launchDate": "2021-10-20",
    "closeData": "2021-11-03",
    "isin": "INF767K01QY0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "149258",
    "name": "LIC MF Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "LIC MF Balanced Advantage Fund-Regular Plan-IDCW",
    "minAmount": "Rs.5000/-",
    "launchDate": "2021-10-20",
    "closeData": "2021-11-03",
    "isin": "INF767K01QW4",
    "isinRe": "INF767K01QX2"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149262",
    "name": "Aditya Birla Sun Life Nifty Healthcare ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty Healthcare ETF",
    "minAmount": "500",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF209KB10C2",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "149263",
    "name": "NJ Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "NJ Balanced Advantage Fund - Regular Plan - IDCW Payout Option",
    "minAmount": "Rs. 500",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF0J8L01016",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "149264",
    "name": "NJ Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "NJ Balanced Advantage Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF0J8L01040",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "149265",
    "name": "NJ Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "NJ Balanced Advantage Fund - Direct Plan - IDCW Payout Option",
    "minAmount": "Rs. 500",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF0J8L01032",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "149266",
    "name": "NJ Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "NJ Balanced Advantage Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF0J8L01024",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149267",
    "name": "Kotak Nifty AAA Bond Plus SDL Apr 2026 70 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty AAA Bond Plus SDL Apr 2026 70 30 ETF",
    "minAmount": "5000",
    "launchDate": "2021-10-08",
    "closeData": "",
    "isin": "INF174KA1HY7",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149268",
    "name": "ITI Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Pharma and Healthcare Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-10-18",
    "closeData": "",
    "isin": "INF00XX01BO9",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149269",
    "name": "ITI Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Pharma and Healthcare Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-10-18",
    "closeData": "",
    "isin": "INF00XX01BM3",
    "isinRe": "INF00XX01BN1"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149270",
    "name": "ITI Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Pharma and Healthcare Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-10-18",
    "closeData": "",
    "isin": "INF00XX01BL5",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149271",
    "name": "ITI Pharma and Healthcare Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Pharma and Healthcare Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs. 1/- thereafter",
    "launchDate": "2021-10-18",
    "closeData": "",
    "isin": "INF00XX01BP6",
    "isinRe": "INF00XX01BQ4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149272",
    "name": "Kotak Nifty AAA Bond Plus SDL Apr 2031 70 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty AAA Bond Plus SDL Apr 2031 70 30 ETF",
    "minAmount": "5000",
    "launchDate": "2021-10-11",
    "closeData": "",
    "isin": "INF174KA1HZ4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149277",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days) - Direct Plan - Income Distribution Cum Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-10-19",
    "closeData": "2021-10-19",
    "isin": "INF200KA19A8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149278",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-10-19",
    "closeData": "2021-10-19",
    "isin": "INF200KA16A4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149279",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-10-19",
    "closeData": "2021-10-19",
    "isin": "INF200KA18A0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149280",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 53 (1839 Days) - Regular Plan - Income  Distribution Cum Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-10-19",
    "closeData": "2021-10-19",
    "isin": "INF200KA17A2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149281",
    "name": "ICICI Prudential Nifty Smallcap 250 Index Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Nifty Smallcap 250 Index Fund - Growth",
    "minAmount": "100",
    "launchDate": "2021-10-12",
    "closeData": "",
    "isin": "INF109KC1U84",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149282",
    "name": "ICICI Prudential Nifty Smallcap 250 Index Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Nifty Smallcap 250 Index Fund - IDCW",
    "minAmount": "100",
    "launchDate": "2021-10-12",
    "closeData": "",
    "isin": "INF109KC1U92",
    "isinRe": "INF109KC1V00"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149283",
    "name": "ICICI Prudential Nifty Smallcap 250 Index Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Nifty Smallcap 250 Index Fund - Direct Plan - Growth",
    "minAmount": "100",
    "launchDate": "2021-10-12",
    "closeData": "",
    "isin": "INF109KC1V18",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149284",
    "name": "ICICI Prudential Nifty Smallcap 250 Index Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "ICICI Prudential Nifty Smallcap 250 Index Fund - Direct Plan - IDCW",
    "minAmount": "100",
    "launchDate": "2021-10-12",
    "closeData": "",
    "isin": "INF109KC1V26",
    "isinRe": "INF109KC1V34"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149285",
    "name": "ICICI Prudential Nifty India Consumption ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty India Consumption ETF",
    "minAmount": "1000",
    "launchDate": "2021-10-18",
    "closeData": "",
    "isin": "INF109KC1V42",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149286",
    "name": "DSP Nifty 50 Equal Weight ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Nifty 50 Equal Weight ETF",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-10-18",
    "closeData": "2021-10-29",
    "isin": "INF740KA1QK2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149287",
    "name": "HDFC NIFTY Next 50 index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Next 50 Index Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-10-22",
    "closeData": "2021-10-29",
    "isin": "INF179KC1BR7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149288",
    "name": "HDFC NIFTY Next 50 index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Next 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-10-22",
    "closeData": "2021-10-29",
    "isin": "INF179KC1BQ9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149289",
    "name": "Aditya Birla Sun Life Nasdaq 100 FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Nasdaq 100 FOF-Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-15",
    "closeData": "",
    "isin": "INF209KB19C3",
    "isinRe": "INF209KB10D0"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149290",
    "name": "Aditya Birla Sun Life Nasdaq 100 FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Nasdaq 100 FOF-Regular Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-15",
    "closeData": "",
    "isin": "INF209KB15C1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149291",
    "name": "Aditya Birla Sun Life Nasdaq 100 FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Nasdaq 100 FOF-Direct-Growth",
    "minAmount": "5000",
    "launchDate": "2021-10-15",
    "closeData": "",
    "isin": "INF209KB18C5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149292",
    "name": "Aditya Birla Sun Life Nasdaq 100 FOF",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Aditya Birla Sun Life Nasdaq 100 FOF-Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2021-10-15",
    "closeData": "",
    "isin": "INF209KB16C9",
    "isinRe": "INF209KB17C7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149293",
    "name": "Aditya Birla Sun Life Nifty IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty IT ETF",
    "minAmount": "500",
    "launchDate": "2021-10-20",
    "closeData": "",
    "isin": "INF209KB11D8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149294",
    "name": "Aditya Birla Sun Life Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Business Cycle Fund-Regular IDCW Payout",
    "minAmount": "500",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF209KB13D4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149295",
    "name": "Aditya Birla Sun Life Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Business Cycle Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF209KB14D2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149296",
    "name": "Aditya Birla Sun Life Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Business Cycle Fund-Regular-Growth",
    "minAmount": "500",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF209KB12D6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149297",
    "name": "Aditya Birla Sun Life Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Aditya Birla Sun Life Business Cycle Fund-Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF209KB15D9",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149298",
    "name": "PGIM India Global Select Real Estate Securities Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Select Real Estate Securities Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF663L01W63",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149299",
    "name": "PGIM India Global Select Real Estate Securities Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Select Real Estate Securities Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF663L01W97",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149300",
    "name": "PGIM India Global Select Real Estate Securities Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Select Real Estate Securities Fund of Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF663L01W71",
    "isinRe": "INF663L01W89"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "149301",
    "name": "PGIM India Global Select Real Estate Securities Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "PGIM India Global Select Real Estate Securities Fund of Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF663L01X05",
    "isinRe": "INF663L01X13"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149305",
    "name": "BANDHAN MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANDHAN MULTI CAP FUND - GROWTH - REGULAR PLAN",
    "minAmount": "RRs. 1000/-",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF194KB1CI6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149302",
    "name": "BANDHAN MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANDHAN MULTI CAP FUND - IDCW - REGULAR PLAN",
    "minAmount": "RRs. 1000/-",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF194KB1CJ4",
    "isinRe": "INF194KB1CK2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149303",
    "name": "BANDHAN MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANDHAN MULTI CAP FUND - GROWTH - DIRECT PLAN",
    "minAmount": "RRs. 1000/-",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF194KB1CL0",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149304",
    "name": "BANDHAN MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "BANDHAN MULTI CAP FUND - IDCW - DIRECT PLAN",
    "minAmount": "RRs. 1000/-",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF194KB1CM8",
    "isinRe": "INF194KB1CN6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149306",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days) - Regular Plan - Income Distribution Cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-01",
    "closeData": "2021-11-03",
    "isin": "INF200KA11B3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149307",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-01",
    "closeData": "2021-11-03",
    "isin": "INF200KA12B1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149308",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-01",
    "closeData": "2021-11-03",
    "isin": "INF200KA10B5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149309",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 54 (1842 Days) - Direct Plan - Income Distribution Cum Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-01",
    "closeData": "2021-11-03",
    "isin": "INF200KA13B9",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "149316",
    "name": "360 ONE QUANT FUND (Formerly known as IIFL QUANT FUND)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "360 ONE QUANT FUND REGULAR INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-11-08",
    "closeData": "",
    "isin": "INF579M01AG6",
    "isinRe": "INF579M01AH4"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "149317",
    "name": "360 ONE QUANT FUND (Formerly known as IIFL QUANT FUND)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "360 ONE QUANT FUND REGULAR GROWTH",
    "minAmount": "1000",
    "launchDate": "2021-11-08",
    "closeData": "",
    "isin": "INF579M01AF8",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "149318",
    "name": "360 ONE QUANT FUND (Formerly known as IIFL QUANT FUND)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "360 ONE QUANT FUND DIRECT GROWTH",
    "minAmount": "1000",
    "launchDate": "2021-11-08",
    "closeData": "",
    "isin": "INF579M01AI2",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "149319",
    "name": "360 ONE QUANT FUND (Formerly known as IIFL QUANT FUND)",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "360 ONE QUANT FUND DIRECT INCOME DISTRIBUTION CUM CAPITAL WITHDRAWAL",
    "minAmount": "1000",
    "launchDate": "2021-11-08",
    "closeData": "",
    "isin": "INF579M01AJ0",
    "isinRe": "INF579M01AK8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149321",
    "name": "ITI Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Banking and Financial Services Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF00XX01BU6",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149322",
    "name": "ITI Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Banking and Financial Services Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF00XX01BV4",
    "isinRe": "INF00XX01BW2"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149323",
    "name": "ITI Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Banking and Financial Services Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF00XX01BS0",
    "isinRe": "INF00XX01BT8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149324",
    "name": "ITI Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ITI Banking and Financial Services Fund -Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1 thereafter",
    "launchDate": "2021-11-15",
    "closeData": "",
    "isin": "INF00XX01BR2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149325",
    "name": "Nippon India Taiwan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Taiwan Equity Fund- Regular Plan- IDCW option",
    "minAmount": "Rs.500/- & in multiples of Re. 1 thereafter",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF204KC1287",
    "isinRe": "INF204KC1295"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149327",
    "name": "Nippon India Taiwan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Taiwan Equity Fund- Direct Plan- IDCW option",
    "minAmount": "Rs.500/- & in multiples of Re. 1 thereafter",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF204KC1311",
    "isinRe": "INF204KC1329"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149328",
    "name": "Nippon India Taiwan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Taiwan Equity fund- Regular Plan- Growth Option",
    "minAmount": "Rs.500/- & in multiples of Re. 1 thereafter",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF204KC1279",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149329",
    "name": "Nippon India Taiwan Equity Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Nippon India Taiwan Equity Fund- Direct Plan- Growth Option",
    "minAmount": "Rs.500/- & in multiples of Re. 1 thereafter",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF204KC1303",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149331",
    "name": "ICICI Prudential S&P BSE 500 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential S&P BSE 500 ETF FOF - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF109KC1V91",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149332",
    "name": "ICICI Prudential S&P BSE 500 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential S&P BSE 500 ETF FOF - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF109KC1V75",
    "isinRe": "INF109KC1V83"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149333",
    "name": "ICICI Prudential S&P BSE 500 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential S&P BSE 500 ETF FOF - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF109KC1W09",
    "isinRe": "INF109KC1W17"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149334",
    "name": "ICICI Prudential S&P BSE 500 ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential S&P BSE 500 ETF FOF - Growth",
    "minAmount": "1000",
    "launchDate": "2021-11-12",
    "closeData": "",
    "isin": "INF109KC1V67",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "149335",
    "name": "Quant Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quant Value Fund - Growth Option  - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-11-10",
    "closeData": "2021-11-24",
    "isin": "INF966L01AN3",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "149336",
    "name": "Quant Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quant Value Fund - IDCW Option- Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-11-10",
    "closeData": "2021-11-24",
    "isin": "INF966L01AR4",
    "isinRe": "INF966L01AS2"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "149337",
    "name": "Quant Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quant Value Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-11-10",
    "closeData": "2021-11-24",
    "isin": "INF966L01AQ6",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "149338",
    "name": "Quant Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Quant Value Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2021-11-10",
    "closeData": "2021-11-24",
    "isin": "INF966L01AO1",
    "isinRe": "INF966L01AP8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149339",
    "name": "Edelweiss NIFTY Large Midcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY Large Midcap 250 Index Fund - Direct Plan Payout",
    "minAmount": "5000",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-26",
    "isin": "INF754K01NS7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149341",
    "name": "Edelweiss NIFTY Large Midcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY Large Midcap 250 Index Fund - Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-26",
    "isin": "INF754K01NN8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149342",
    "name": "Edelweiss NIFTY Large Midcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY Large Midcap 250 Index Fund - Regular Plan Payout",
    "minAmount": "5000",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-26",
    "isin": "INF754K01NO6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149343",
    "name": "Edelweiss NIFTY Large Midcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss NIFTY Large Midcap 250 Index Fund - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-26",
    "isin": "INF754K01NR9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149345",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-16",
    "closeData": "2021-11-22",
    "isin": "INF200KA16B2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149346",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days) - Regular Plan - Income Distribution Cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-16",
    "closeData": "2021-11-22",
    "isin": "INF200KA15B4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149347",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-16",
    "closeData": "2021-11-22",
    "isin": "INF200KA14B7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149348",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 55 (1849 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-16",
    "closeData": "2021-11-22",
    "isin": "INF200KA17B0",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149349",
    "name": "Motilal Oswal MSCI EAFE Top 100 Select Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal MSCI EAFE Top 100 Select Index Fund - Direct Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-25",
    "isin": "INF247L01AS7",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149350",
    "name": "Motilal Oswal MSCI EAFE Top 100 Select Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal MSCI EAFE Top 100 Select Index Fund - Regular Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-25",
    "isin": "INF247L01AT5",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149351",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1299",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149352",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Periodic Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1331",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149353",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Monthly Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1315",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149354",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Quarterly Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1323",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149355",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Periodic Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1232",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149356",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Periodic Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1265",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149363",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Monthly Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1349",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149364",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Monthly Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1240",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149357",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Monthly Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1273",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149358",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Quarterly Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1281",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149359",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Quarterly Reinvestment",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1356",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149360",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Regular Plan-IDCW Periodic Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1307",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149361",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-Growth",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1224",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149362",
    "name": "Tata Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "Tata Corporate Bond Fund-Direct Plan-IDCW Quarterly Payout",
    "minAmount": "5000/-",
    "launchDate": "2021-11-22",
    "closeData": "",
    "isin": "INF277KA1257",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149368",
    "name": "HDFC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HDFC Multi Cap Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-11-23",
    "closeData": "2021-12-07",
    "isin": "INF179KC1BS5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149365",
    "name": "HDFC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HDFC Multi Cap Fund - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-11-23",
    "closeData": "2021-12-07",
    "isin": "INF179KC1BW7",
    "isinRe": "INF179KC1BX5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149366",
    "name": "HDFC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HDFC Multi Cap Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2021-11-23",
    "closeData": "2021-12-07",
    "isin": "INF179KC1BV9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149367",
    "name": "HDFC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HDFC Multi Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2021-11-23",
    "closeData": "2021-12-07",
    "isin": "INF179KC1BT3",
    "isinRe": "INF179KC1BU1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149371",
    "name": "Axis Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-29",
    "isin": "INF846K016D5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149372",
    "name": "Axis Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-29",
    "isin": "INF846K017D3",
    "isinRe": "INF846K018D1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149373",
    "name": "Axis Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-29",
    "isin": "INF846K013D2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149374",
    "name": "Axis Nifty 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-15",
    "closeData": "2021-11-29",
    "isin": "INF846K014D0",
    "isinRe": "INF846K015D7"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149379",
    "name": "Mirae Asset Hang Seng TECH ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Hang Seng TECH ETF",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-11-17",
    "closeData": "",
    "isin": "INF769K01HS7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149380",
    "name": "Mirae Asset Hang Seng TECH ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Hang Seng TECH ETF Fund of Fund Regular Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-11-17",
    "closeData": "",
    "isin": "INF769K01HT5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149381",
    "name": "Mirae Asset Hang Seng TECH ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae AssetHang Seng TECH ETF Fund of Fund Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-11-17",
    "closeData": "",
    "isin": "INF769K01HU3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149382",
    "name": "Axis Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Axis Multicap Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-26",
    "closeData": "2021-12-10",
    "isin": "INF846K016E3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149383",
    "name": "Axis Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Axis Multicap Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-26",
    "closeData": "2021-12-10",
    "isin": "INF846K013E0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149384",
    "name": "Axis Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Axis Multicap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-26",
    "closeData": "2021-12-10",
    "isin": "INF846K014E8",
    "isinRe": "INF846K015E5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149387",
    "name": "Axis Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Axis Multicap Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-11-26",
    "closeData": "2021-12-10",
    "isin": "INF846K017E1",
    "isinRe": "INF846K018E9"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149388",
    "name": "ICICI Prudential Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Midcap 150 Index Fund - IDCW",
    "minAmount": "100",
    "launchDate": "2021-12-03",
    "closeData": "",
    "isin": "INF109KC1W33",
    "isinRe": "INF109KC1W41"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149389",
    "name": "ICICI Prudential Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Midcap 150 Index Fund - Direct Plan - Growth",
    "minAmount": "100",
    "launchDate": "2021-12-03",
    "closeData": "",
    "isin": "INF109KC1W58",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149390",
    "name": "ICICI Prudential Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Midcap 150 Index Fund - Growth",
    "minAmount": "100",
    "launchDate": "2021-12-03",
    "closeData": "",
    "isin": "INF109KC1W25",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149391",
    "name": "ICICI Prudential Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Midcap 150 Index Fund - Direct Plan - IDCW",
    "minAmount": "100",
    "launchDate": "2021-12-03",
    "closeData": "",
    "isin": "INF109KC1W66",
    "isinRe": "INF109KC1W74"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149392",
    "name": "DSP Nifty 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Nifty 50 ETF",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-17",
    "isin": "INF740KA1CL0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149397",
    "name": "Kotak Nifty Alpha 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty Alpha 50 ETF",
    "minAmount": "5000",
    "launchDate": "2021-12-01",
    "closeData": "",
    "isin": "INF174KA1IA5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149398",
    "name": "BHARAT Bond ETF - April 2032",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BHARAT Bond ETF - April 2032",
    "minAmount": "1000",
    "launchDate": "2021-12-03",
    "closeData": "2021-12-09",
    "isin": "INF754K01OB1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149399",
    "name": "BHARAT Bond ETF FOF - April 2032",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2032 Regular Plan Growth",
    "minAmount": "1000",
    "launchDate": "2021-12-03",
    "closeData": "2021-12-09",
    "isin": "INF754K01NV1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149400",
    "name": "BHARAT Bond ETF FOF - April 2032",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2032 Regular Plan Dividend",
    "minAmount": "1000",
    "launchDate": "2021-12-03",
    "closeData": "2021-12-09",
    "isin": "INF754K01NW9",
    "isinRe": "INF754K01NX7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149401",
    "name": "BHARAT Bond ETF FOF - April 2032",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2032 Direct Growth",
    "minAmount": "1000",
    "launchDate": "2021-12-03",
    "closeData": "2021-12-09",
    "isin": "INF754K01NY5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149402",
    "name": "BHARAT Bond ETF FOF - April 2032",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2032 Direct Plan Dividend",
    "minAmount": "1000",
    "launchDate": "2021-12-03",
    "closeData": "2021-12-09",
    "isin": "INF754K01NZ2",
    "isinRe": "INF754K01OA3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149403",
    "name": "DSP Nifty Midcap 150 Quality 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Nifty Midcap 150 Quality 50 ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof.",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-17",
    "isin": "INF740KA1QL0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149404",
    "name": "Mahindra Manulife Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mahindra Manulife Balanced Advantage Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-09",
    "closeData": "",
    "isin": "INF174V01BB6",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149405",
    "name": "Mahindra Manulife Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mahindra Manulife Balanced Advantage Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-09",
    "closeData": "",
    "isin": "INF174V01BC4",
    "isinRe": "INF174V01BD2"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149406",
    "name": "Mahindra Manulife Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mahindra Manulife Balanced Advantage Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-09",
    "closeData": "",
    "isin": "INF174V01BE0",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "149407",
    "name": "Mahindra Manulife Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mahindra Manulife Balanced Advantage Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-09",
    "closeData": "",
    "isin": "INF174V01BF7",
    "isinRe": "INF174V01BG5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149416",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2021-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149417",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2021-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149418",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149419",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 95 Days Plan B - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-08",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149420",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-15",
    "isin": "INF200KA10C3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149421",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-15",
    "isin": "INF200KA19B6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149422",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-15",
    "isin": "INF200KA18B8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149423",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 56 (1232 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-06",
    "closeData": "2021-12-15",
    "isin": "INF200KA11C1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149428",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149429",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149424",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149425",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149426",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149427",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1833 Days Plan C - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149430",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-08",
    "closeData": "2021-12-10",
    "isin": "INF200KA15C2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149431",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-08",
    "closeData": "2021-12-10",
    "isin": "INF200KA14C5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149432",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-08",
    "closeData": "2021-12-10",
    "isin": "INF200KA12C9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149433",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 57 (1835 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-08",
    "closeData": "2021-12-10",
    "isin": "INF200KA13C7",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149438",
    "name": "Motilal Oswal Nasdaq Q50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nasdaq Q50 ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2021-12-10",
    "closeData": "",
    "isin": "INF247L01AU3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149439",
    "name": "ICICI Prudential Passive Multi-Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Multi-Asset Fund of Funds - Growth",
    "minAmount": "1000",
    "launchDate": "2021-12-27",
    "closeData": "",
    "isin": "INF109KC1X81",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149440",
    "name": "ICICI Prudential Passive Multi-Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Multi-Asset Fund of Funds - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-12-27",
    "closeData": "",
    "isin": "INF109KC1X99",
    "isinRe": "INF109KC1Y07"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149441",
    "name": "ICICI Prudential Passive Multi-Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Multi-Asset Fund of Funds - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2021-12-27",
    "closeData": "",
    "isin": "INF109KC1Y15",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149442",
    "name": "ICICI Prudential Passive Multi-Asset Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI Prudential Passive Multi-Asset Fund of Funds - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2021-12-27",
    "closeData": "",
    "isin": "INF109KC1Y23",
    "isinRe": "INF109KC1Y31"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149447",
    "name": "Navi Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Next 50 Index Fund- Direct Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-01-01",
    "closeData": "2022-01-14",
    "isin": "INF959L01FR8",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149448",
    "name": "Navi Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Next 50 Index Fund- Regular Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-01-01",
    "closeData": "2022-01-14",
    "isin": "INF959L01FS6",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "149449",
    "name": "Samco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Samco Flexi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0K1H01016",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "149450",
    "name": "Samco Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Samco Flexi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0K1H01024",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149455",
    "name": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund - Direct Plan Growth",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF109KC1Z55",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149456",
    "name": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF109KC1Z22",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149457",
    "name": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF109KC1Z30",
    "isinRe": "INF109KC1Z48"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149458",
    "name": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "ICICI Prudential Strategic Metal and Energy Equity Fund of Fund - Direct Plan IDCW",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF109KC1Z63",
    "isinRe": "INF109KC1Z71"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149459",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-30",
    "closeData": "2022-01-04",
    "isin": "INF200KA16C0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149460",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-30",
    "closeData": "2022-01-04",
    "isin": "INF200KA18C6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149461",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days) - Direct Plan - Income Distribution cum Capital withdrawal option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-30",
    "closeData": "2022-01-04",
    "isin": "INF200KA19C4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149462",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 58 (1842 Days) - Regular Plan - Income Distribution cum Capital withdrawal option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2021-12-30",
    "closeData": "2022-01-04",
    "isin": "INF200KA17C8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149463",
    "name": "ICICI Prudential Nifty Auto ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Auto ETF",
    "minAmount": "1000",
    "launchDate": "2022-01-05",
    "closeData": "",
    "isin": "INF109KC1Y49",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149464",
    "name": "ICICI PRUDENTIAL SILVER ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI PRUDENTIAL SILVER ETF",
    "minAmount": "100",
    "launchDate": "2022-01-05",
    "closeData": "",
    "isin": "INF109KC1Y56",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149465",
    "name": "Nippon India Nifty Auto ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India Nifty Auto ETF",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-01-05",
    "closeData": "",
    "isin": "INF204KC1337",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149466",
    "name": "Axis Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Next 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-07",
    "closeData": "2022-01-21",
    "isin": "INF846K019E7",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149467",
    "name": "Axis Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Next 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-07",
    "closeData": "2022-01-21",
    "isin": "INF846K012F9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149468",
    "name": "Axis Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Next 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-07",
    "closeData": "2022-01-21",
    "isin": "INF846K013F7",
    "isinRe": "INF846K014F5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149469",
    "name": "Axis Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Next 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-07",
    "closeData": "2022-01-21",
    "isin": "INF846K010F3",
    "isinRe": "INF846K011F1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149472",
    "name": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-03",
    "closeData": "2022-01-17",
    "isin": "INF200KA10D1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149473",
    "name": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-03",
    "closeData": "2022-01-17",
    "isin": "INF200KA11D9",
    "isinRe": "INF200KA12D7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149474",
    "name": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-03",
    "closeData": "2022-01-17",
    "isin": "INF200KA13D5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149475",
    "name": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-03",
    "closeData": "2022-01-17",
    "isin": "INF200KA14D3",
    "isinRe": "INF200KA15D0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149480",
    "name": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)",
    "category": "Other Scheme - Index Funds",
    "navName": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund-Income Distribution CUM Capital Withdrawal",
    "minAmount": "100",
    "launchDate": "1999-06-16",
    "closeData": "",
    "isin": "INF173K01AH0",
    "isinRe": "INF173K01AI8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149481",
    "name": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)",
    "category": "Other Scheme - Index Funds",
    "navName": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)-Growth",
    "minAmount": "100",
    "launchDate": "1999-06-16",
    "closeData": "",
    "isin": "INF173K01AG2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149482",
    "name": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)",
    "category": "Other Scheme - Index Funds",
    "navName": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)- Direct Plan - Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "100",
    "launchDate": "1999-06-16",
    "closeData": "",
    "isin": "INF173K01EW1",
    "isinRe": "INF173K01EX9"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149483",
    "name": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund)",
    "category": "Other Scheme - Index Funds",
    "navName": "Sundaram Nifty 100 Equal Weight Fund (Formerly Known as Principal Nifty 100 Equal Weight Fund) - Direct Plan - Growth Option",
    "minAmount": "100",
    "launchDate": "1999-06-16",
    "closeData": "",
    "isin": "INF173K01EZ4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149519",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)- Growth Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01DG6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149520",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) - Weekly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1JU2",
    "isinRe": "INF173K01DI2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149521",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)- Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01DJ0",
    "isinRe": "INF173K01DK8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149522",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) -Daily Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01DH4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149523",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) - Direct Plan - Daily Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01FR8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149524",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) - Direct Plan - Weekly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1KA2",
    "isinRe": "INF173K01FW8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149751",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) Regular Fortnightly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1JV0",
    "isinRe": "INF903JA1JR8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149752",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) Regular Quarterly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1JT4",
    "isinRe": "INF903JA1JS6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149525",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) - Direct Plan - Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01FT4",
    "isinRe": "INF173K01FU2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149526",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF173K01FS6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149747",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Know as Principal Low Duation Fund) Regular Principal Units",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1JW8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149748",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) Direct Fortnightly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1KB0",
    "isinRe": "INF903JA1JX6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149749",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund) Direct Principal Units",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1KC8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149750",
    "name": "Sundaram Low Duration Fund (Formerly Known as Principal Low Duration Fund)",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "Sundaram Low Duration Fund(Formerly Known as Principal Low Duration Fund) Direct Quarterly IDCW",
    "minAmount": "Rs. 1000",
    "launchDate": "2004-08-19",
    "closeData": "2004-09-06",
    "isin": "INF903JA1JZ1",
    "isinRe": "INF903JA1JY4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149531",
    "name": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)-Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "300",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": "INF173K01197",
    "isinRe": "INF173K01205"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149532",
    "name": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)-Growth Option",
    "minAmount": "300",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": "INF173K01189",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149533",
    "name": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)- Direct Plan - Growth Option",
    "minAmount": "300",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": "INF173K01EK6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149534",
    "name": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Sundaram Focused Fund (Formerly Known as Principal Focused Multicap Fund)- Direct Plan - Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "300",
    "launchDate": "2005-09-23",
    "closeData": "",
    "isin": "INF173K01EM2",
    "isinRe": "INF173K01EL4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149719",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)- Direct Plan IDCW Weekly",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01CA1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149720",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund) Direct Plan Fortnightly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF903JA1JO5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149721",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund) Direct Plan IDCW Daily",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01BZ0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149722",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Prinicpal Ultra Short Term Fund) Regular Plan Fortnightly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF903JA1JL1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149723",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Prinicpal Ultra Short Term Fund) Regular Plan Quarterly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF903JA1JN7",
    "isinRe": "INF903JA1JM9"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149724",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund) Direct Plan Quarterly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF903JA1JQ0",
    "isinRe": "INF903JA1JP2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149535",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)-Growth Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01CU9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149536",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)-Daily Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01CV7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149537",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)-Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01CX3",
    "isinRe": "INF173K01CY1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149538",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)-Weekly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01CW5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149539",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)- Direct Plan -Growth Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01HI3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149540",
    "name": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)",
    "category": "Debt Scheme - Ultra Short Duration Fund",
    "navName": "Sundaram Ultra Short Duration Fund (Formerly Known as Principal Ultra Short Term Fund)- Direct Plan - Income Distribution CUM Capital Withdrawal Option - Monthly",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2007-12-20",
    "closeData": "",
    "isin": "INF173K01HK9",
    "isinRe": "INF173K01HJ1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149549",
    "name": "Sundaram Arbitrage Fund(Formerly Known as Prinicpal Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Sundaram Arbitrage Fund( Formerly Known as Principal Arbitrage Fund) - Direct Plan- Monthly Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs. 5000",
    "launchDate": "2016-03-28",
    "closeData": "",
    "isin": "INF173K01NC4",
    "isinRe": "INF173K01NE0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149550",
    "name": "Sundaram Arbitrage Fund(Formerly Known as Prinicpal Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Sundaram Arbitrage Fund (Formerly Known as Prinicpal Arbitrage Fund) - Direct Plan - Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2016-03-28",
    "closeData": "",
    "isin": "INF173K01NF7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149551",
    "name": "Sundaram Arbitrage Fund(Formerly Known as Prinicpal Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Sundaram Arbitrage Fund (Formerly Known as Principal Arbitrage Fund)- Regular Plan - Monthly Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs. 5000",
    "launchDate": "2016-03-28",
    "closeData": "",
    "isin": "INF173K01MY0",
    "isinRe": "INF173K01NA8"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149552",
    "name": "Sundaram Arbitrage Fund(Formerly Known as Prinicpal Arbitrage Fund)",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "Sundaram Arbitrage Fund (Formerly Know as Principal Arbitrage Fund) - Regular Plan - Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2016-03-28",
    "closeData": "",
    "isin": "INF173K01NB6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149569",
    "name": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund) Regular Growth",
    "minAmount": "500",
    "launchDate": "1996-01-01",
    "closeData": "",
    "isin": "INF173K01361",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149570",
    "name": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund) - Direct Plan Growth",
    "minAmount": "500",
    "launchDate": "1996-01-01",
    "closeData": "",
    "isin": "INF173K01HZ7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149571",
    "name": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund) Direct Plan - Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "500",
    "launchDate": "1996-01-01",
    "closeData": "",
    "isin": "INF173K01NN1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149572",
    "name": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund)",
    "category": "Equity Scheme - ELSS",
    "navName": "Sundaram Tax Savings Fund (Formerly Known as Principal Tax Savings Fund) Regular Plan - Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "500",
    "launchDate": "1996-01-01",
    "closeData": "",
    "isin": "INF173K01NM3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149731",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KE4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149732",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KG9",
    "isinRe": "INF903JA1KK1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149733",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Plan Halfyearly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KN5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149734",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KH7",
    "isinRe": "INF903JA1KD6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149735",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Plan Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KJ3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149736",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Principal Units",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KI5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149725",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Plan Principal Units",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KQ8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149726",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Plan Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KR6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149727",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Plan Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KP0",
    "isinRe": "INF903JA1KL9"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149728",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Plan Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KM7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149729",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KO3",
    "isinRe": "INF903JA1KS4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149730",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) Regular Halfyearly IDCW",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF903JA1KF1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149585",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)- Growth Plan",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF173K01BA3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149586",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)- Income Distribution CUM Capital Withdrawal Option - Monthly",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF173K01BB1",
    "isinRe": "INF173K01BC9"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149587",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)- Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF173K01GP0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149588",
    "name": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund)",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "Sundaram Short Duration Fund (Formerly Known as Principal Short Term Debt Fund) - Direct Plan - Income Distribution CUM Capital Withdrawal Plan - Monthly",
    "minAmount": "5000",
    "launchDate": "2002-04-24",
    "closeData": "",
    "isin": "INF173K01GQ8",
    "isinRe": "INF173K01GR6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149599",
    "name": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)-Growth",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "1999-12-09",
    "closeData": "",
    "isin": "INF173K01CL8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149600",
    "name": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)- Monthly Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "1999-12-09",
    "closeData": "",
    "isin": "INF173K01CI4",
    "isinRe": "INF173K01CJ2"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149601",
    "name": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)- Direct Plan - Growth Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "1999-12-09",
    "closeData": "",
    "isin": "INF173K01FE6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149602",
    "name": "Sundaram Aggressive Hybrid Fund (Formerly Known as Principal Hybrid Equity Fund)",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Sundaram Aggressive Hybrid Fund(Formerly Known as Principal Hybrid Equity Fund)-Direct Plan - Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "1999-12-09",
    "closeData": "",
    "isin": "INF173K01FB2",
    "isinRe": "INF173K01FC0"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149742",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as  Principal Cash Management Fund) Regular Plan Principal Units",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JI7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149744",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram  Liquid Fund (Formerly Known as Principal Cash Management Fund) Direct Quarterly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JK3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149745",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) Unclaimed Redemption More 3 Years",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149664",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) - Direct Plan - Growth Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01GU0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149665",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund(Formerly Known as Principal Cash Management Fund)- Direct Plan - Income Distribution CUM Capital Withdrawal Option - Weekly",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01GY2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149737",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) Direct Principal Units",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JH9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149738",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) Direct Fortnightly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JJ5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149739",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) Regular Fortnightly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JF3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149740",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) Regular Quarterly IDCW",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF903JA1JG1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149658",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) - Daily Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01DB7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149659",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Principal Cash Management Fund) - Weekly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01DC5",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149660",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) - Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01DD3",
    "isinRe": "INF173K01DE1"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149661",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) -Growth Option",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01DA9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149662",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund) -Direct Plan - Income Distribution CUM Capital Withdrawal Option - Monthly",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01GV8",
    "isinRe": "INF173K01GW6"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149663",
    "name": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund)",
    "category": "Debt Scheme - Liquid Fund",
    "navName": "Sundaram Liquid Fund (Formerly Known as Principal Cash Management Fund )- Direct Plan - Income Distribution CUM Capital Withdrawal Option - Daily",
    "minAmount": "Rs.1000/- and amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01GT2",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149668",
    "name": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund) - Direct Plan -Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs 100 and any amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01FN7",
    "isinRe": "INF173K01FO5"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149669",
    "name": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)-Direct Plan - Growth Option",
    "minAmount": "Rs 100 and any amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01FQ0",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149666",
    "name": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)- Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs 100 and any amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01957",
    "isinRe": "INF173K01965"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149667",
    "name": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Sundaram Multi Cap Fund (Formerly Known as Principal Multi Cap Growth Fund)-Growth Option",
    "minAmount": "Rs 100 and any amount thereafter",
    "launchDate": "2000-10-03",
    "closeData": "",
    "isin": "INF173K01940",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149674",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund) - Growth Option",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01213",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149675",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)- Half Yearly Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01247",
    "isinRe": "INF173K01254"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149676",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund) -Quarterly Income Distribution CUM Capital Withdrawal",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01262",
    "isinRe": "INF173K01270"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149677",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as  Principal Equity Savings Fund) - Direct Plan - Income Distribution CUM Capital Withdrawal Option - Half Yearly",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01HC6",
    "isinRe": "INF173K01HD4"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149678",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)- Direct Plan - Income Distribution CUM Capital Withdrawal Option - Quarterly",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01HF9",
    "isinRe": "INF173K01HG7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149679",
    "name": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund)",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "Sundaram Equity Savings Fund (Formerly Known as Principal Equity Savings Fund) - Direct Plan - Growth Option",
    "minAmount": "Rs. 100 and any amount thereafter",
    "launchDate": "2002-04-22",
    "closeData": "",
    "isin": "INF173K01GZ9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149699",
    "name": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)- Direct Plan - Half Yearly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "5000",
    "launchDate": "2004-09-06",
    "closeData": "",
    "isin": "INF173K01ES9",
    "isinRe": "INF173K01ET7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149700",
    "name": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund) - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-09-06",
    "closeData": "",
    "isin": "INF173K01EV3",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149697",
    "name": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)-Growth Plan",
    "minAmount": "5000",
    "launchDate": "2004-09-06",
    "closeData": "",
    "isin": "INF173K01AC1",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149698",
    "name": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "Sundaram Dividend Yield Fund (Formerly Known as Principal Dividend Yield Fund)- Half Yearly Income Distribution CUM Capital Withdrawal Plan",
    "minAmount": "5000",
    "launchDate": "2004-09-06",
    "closeData": "",
    "isin": "INF173K01AD9",
    "isinRe": "INF173K01AE7"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149715",
    "name": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund)",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund) - Growth Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "2010-11-26",
    "closeData": "",
    "isin": "INF173K01585",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149716",
    "name": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund)",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund) - Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "2010-11-26",
    "closeData": "",
    "isin": "INF173K01551",
    "isinRe": "INF173K01569"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149717",
    "name": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund)",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Sundaram Balanced Advantage Fund ( Formerly Known as Principal Balanced Advantage Fund) - Direct Plan - Growth Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "2010-11-26",
    "closeData": "",
    "isin": "INF173K01FI7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "149718",
    "name": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund)",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Sundaram Balanced Advantage Fund (Formerly Known as Principal Balanced Advantage Fund) - Direct Plan - Monthly Income Distribution CUM Capital Withdrawal Option",
    "minAmount": "Rs.100/- and any amount thereafter under each Plan",
    "launchDate": "2010-11-26",
    "closeData": "",
    "isin": "INF173K01FF3",
    "isinRe": "INF173K01FG1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149753",
    "name": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-10",
    "closeData": "2022-01-20",
    "isin": "INF846K015F2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149754",
    "name": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund- Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-10",
    "closeData": "2022-01-20",
    "isin": "INF846K016F0",
    "isinRe": "INF846K017F8"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149755",
    "name": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-10",
    "closeData": "2022-01-20",
    "isin": "INF846K018F6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149756",
    "name": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 70 30 CPSE Plus SDL April 2025 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-10",
    "closeData": "2022-01-20",
    "isin": "INF846K019F4",
    "isinRe": "INF846K010G1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149757",
    "name": "Kotak Nifty Midcap 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty Midcap 50 ETF",
    "minAmount": "5000",
    "launchDate": "2022-01-06",
    "closeData": "",
    "isin": "INF174KA1IB3",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149758",
    "name": "Nippon India Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Nippon India Silver ETF",
    "minAmount": "Rs.1000 & in multiples of Re.1 thereafter.",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF204KC1402",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149760",
    "name": "Nippon India Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Silver ETF FOF- Direct Plan-Growth Option",
    "minAmount": "Rs.100/- & in multiples of Re. 1 thereafter",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF204KC1378",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149761",
    "name": "Nippon India Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Silver ETF FOF- Regular Plan-IDCW Option",
    "minAmount": "Rs.100/- & in multiples of Re. 1 thereafter",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF204KC1352",
    "isinRe": "INF204KC1360"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149762",
    "name": "Nippon India Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Silver ETF FOF- Direct Plan-IDCW Option",
    "minAmount": "Rs.100/- & in multiples of Re. 1 thereafter",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF204KC1386",
    "isinRe": "INF204KC1394"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149759",
    "name": "Nippon India Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Nippon India Silver ETF FOF-Regular Plan- Growth Option",
    "minAmount": "Rs.100/- & in multiples of Re. 1 thereafter",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF204KC1345",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149763",
    "name": "Invesco India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Invesco India Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF205KA1494",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149764",
    "name": "Invesco India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Invesco India Flexi Cap Fund - IDCW",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF205KA1452",
    "isinRe": "INF205KA1478"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149765",
    "name": "Invesco India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Invesco India Flexi Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF205KA1486",
    "isinRe": "INF205KA1502"
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149766",
    "name": "Invesco India Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Invesco India Flexi Cap Fund - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF205KA1460",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149771",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund- XLIII-Series 4- Regular Plan-Growth Option",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149772",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund- XLIII-Series 4- Direct Plan-Payout of IDCW",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149773",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund- XLIII-Series 4- Regular Plan-Payout of IDCW",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149774",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund- XLIII-Series 4- Direct Plan-Growth Option",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149775",
    "name": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND - Direct Plan - Growth",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF109KC1Y98",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149776",
    "name": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND - Direct Plan - IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF109KC1Z06",
    "isinRe": "INF109KC1Z14"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149777",
    "name": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND - Growth",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF109KC1Y64",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149778",
    "name": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND",
    "category": "Other Scheme - FoF Domestic",
    "navName": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND - IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF109KC1Y72",
    "isinRe": "INF109KC1Y80"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149779",
    "name": "Aditya Birla Sun Life Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Silver ETF",
    "minAmount": "500",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF209KB19F6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149783",
    "name": "Aditya Birla Sun Life Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Silver ETF FOF-Regular Growth",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF209KB13F9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149784",
    "name": "Aditya Birla Sun Life Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Silver ETF FOF-Regular IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF209KB14F7",
    "isinRe": "INF209KB15F4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149780",
    "name": "Aditya Birla Sun Life Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Silver ETF FOF-Direct Growth",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF209KB16F2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149781",
    "name": "Aditya Birla Sun Life Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Silver ETF FOF-Direct IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-13",
    "closeData": "",
    "isin": "INF209KB17F0",
    "isinRe": "INF209KB18F8"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149786",
    "name": "Mirae Asset Nifty India Manufacturing ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty India Manufacturing ETF",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-10",
    "closeData": "",
    "isin": "INF769K01IB1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149787",
    "name": "Mirae Asset Nifty India Manufacturing ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Nifty India Manufacturing ETF FOF - Direct Plan- IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-10",
    "closeData": "",
    "isin": "INF769K01HY5",
    "isinRe": "INF769K01IA3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149788",
    "name": "Mirae Asset Nifty India Manufacturing ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Nifty India Manufacturing ETF FOF - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-10",
    "closeData": "",
    "isin": "INF769K01HW9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149789",
    "name": "Mirae Asset Nifty India Manufacturing ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Nifty India Manufacturing ETF FOF - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-10",
    "closeData": "",
    "isin": "INF769K01HV1",
    "isinRe": "INF769K01HX7"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149790",
    "name": "Mirae Asset Nifty India Manufacturing ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Mirae Asset Nifty India Manufacturing ETF FOF - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-10",
    "closeData": "",
    "isin": "INF769K01HZ2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149791",
    "name": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund-Regular-IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-01-14",
    "closeData": "",
    "isin": "INF209KB10F5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149792",
    "name": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-01-14",
    "closeData": "",
    "isin": "INF209KB19E9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149793",
    "name": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund-Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-01-14",
    "closeData": "",
    "isin": "INF209KB12F1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149794",
    "name": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Apr 2027 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-01-14",
    "closeData": "",
    "isin": "INF209KB11F3",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149795",
    "name": "TRUSTMF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TRUST MF OVERNIGHT FUND-REGULAR-GROWTH",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0GCD01438",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149796",
    "name": "TRUSTMF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TRUST MF OVERNIGHT FUND-DIRECT PLAN-DAILY IDCW REINVESTMENT",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0GCD01461",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149797",
    "name": "TRUSTMF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TRUST MF OVERNIGHT FUND-DIRECT PLAN-GROWTH",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0GCD01453",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "149798",
    "name": "TRUSTMF Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "TRUST MF OVERNIGHT FUND-REGULAR PLAN-DAILY IDCW REINVESTMENT",
    "minAmount": "1000",
    "launchDate": "2022-01-17",
    "closeData": "",
    "isin": "INF0GCD01446",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149799",
    "name": "Motilal Oswal Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 200 Momentum 30 Index Fund-Regular Plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-21",
    "closeData": "2022-02-04",
    "isin": "INF247L01AW9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149800",
    "name": "Motilal Oswal Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty 200 Momentum 30 Index Fund-Direct Plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-21",
    "closeData": "2022-02-04",
    "isin": "INF247L01AV1",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149801",
    "name": "Motilal Oswal Nifty 200 Momentum 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal Nifty 200 Momentum 30 ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-01-21",
    "closeData": "2022-02-04",
    "isin": "INF247L01AX7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149802",
    "name": "UTI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Sensex Index Fund - Regular Plan  -Growth Option",
    "minAmount": "Rs. 5000/- and multiple of Rs 1/- thereafter.",
    "launchDate": "2022-01-19",
    "closeData": "",
    "isin": "INF789F1AVC9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149803",
    "name": "UTI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Sensex Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and multiple of Rs 1/- thereafter.",
    "launchDate": "2022-01-19",
    "closeData": "",
    "isin": "INF789F1AVD7",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149804",
    "name": "Navi Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Bank Index Fund- Direct Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-01-17",
    "closeData": "2022-01-31",
    "isin": "INF959L01FT4",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149805",
    "name": "Navi Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Bank Index Fund- Regular Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-01-17",
    "closeData": "2022-01-31",
    "isin": "INF959L01FU2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149817",
    "name": "DSP Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Innovation Fund of Fund - Regular - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF740KA1QM8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149814",
    "name": "DSP Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Innovation Fund of Fund - Regular - IDCW",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF740KA1QN6",
    "isinRe": "INF740KA1QO4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149815",
    "name": "DSP Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Innovation Fund of Fund - Direct - IDCW",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF740KA1QQ9",
    "isinRe": "INF740KA1QR7"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149816",
    "name": "DSP Global Innovation Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "DSP Global Innovation Fund of Fund - Direct - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-01-24",
    "closeData": "2022-02-08",
    "isin": "INF740KA1QP1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149822",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-27",
    "closeData": "2022-01-28",
    "isin": "INF200KA19D2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149823",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-27",
    "closeData": "2022-01-28",
    "isin": "INF200KA10E9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149824",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-27",
    "closeData": "2022-01-28",
    "isin": "INF200KA18D4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149825",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 59 (1618 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-01-27",
    "closeData": "2022-01-28",
    "isin": "INF200KA17D6",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149830",
    "name": "Navi US Total Stock Market Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Navi US Total Stock Market Fund of Fund- Direct Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF959L01FV0",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149831",
    "name": "Navi US Total Stock Market Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Navi US Total Stock Market Fund of Fund- Regular Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF959L01FW8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149832",
    "name": "BANDHAN NIFTY 100 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY 100 INDEX FUND - DIRECT PLAN - GROWTH",
    "minAmount": "FRs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "",
    "isin": "INF194KB1CR7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149833",
    "name": "BANDHAN NIFTY 100 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY 100 INDEX FUND - REGULAR PLAN - GROWTH",
    "minAmount": "FRs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "",
    "isin": "INF194KB1CO4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149834",
    "name": "BANDHAN NIFTY 100 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY 100 INDEX FUND - REGULAR PLAN - IDCW",
    "minAmount": "FRs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "",
    "isin": "INF194KB1CP1",
    "isinRe": "INF194KB1CQ9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "149835",
    "name": "BANDHAN NIFTY 100 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY 100 INDEX FUND - DIRECT PLAN - IDCW",
    "minAmount": "FRs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "",
    "isin": "INF194KB1CS5",
    "isinRe": "INF194KB1CT3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149836",
    "name": "Aditya Birla Sun Life Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Next 50 Index Fund-Regular IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-31",
    "closeData": "",
    "isin": "INF209KB13G7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149837",
    "name": "Aditya Birla Sun Life Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Next 50 Index Fund-Regular Growth",
    "minAmount": "100",
    "launchDate": "2022-01-31",
    "closeData": "",
    "isin": "INF209KB12G9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149838",
    "name": "Aditya Birla Sun Life Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Next 50 Index Fund-Direct Growth",
    "minAmount": "100",
    "launchDate": "2022-01-31",
    "closeData": "",
    "isin": "INF209KB10G3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149839",
    "name": "Aditya Birla Sun Life Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty Next 50 Index Fund-Direct IDCW",
    "minAmount": "100",
    "launchDate": "2022-01-31",
    "closeData": "",
    "isin": "INF209KB11G1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149840",
    "name": "Kotak Manufacture in India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Manufacture in India Fund - Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-02-01",
    "closeData": "",
    "isin": "INF174KA1IC1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149841",
    "name": "Kotak Manufacture in India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Manufacture in India Fund - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-02-01",
    "closeData": "",
    "isin": "INF174KA1IF4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149842",
    "name": "Kotak Manufacture in India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Manufacture in India Fund - Direct Plan IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-02-01",
    "closeData": "",
    "isin": "INF174KA1IG2",
    "isinRe": "INF174KA1IH0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149843",
    "name": "Kotak Manufacture in India Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Manufacture in India Fund - Regular Plan IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-02-01",
    "closeData": "",
    "isin": "INF174KA1ID9",
    "isinRe": "INF174KA1IE7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149846",
    "name": "Axis Equity ETFs FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Equity ETFs FOF - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF846K012G7",
    "isinRe": "INF846K013G5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149847",
    "name": "Axis Equity ETFs FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Equity ETFs FOF - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF846K011G9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149844",
    "name": "Axis Equity ETFs FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Equity ETFs FOF - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF846K014G3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149845",
    "name": "Axis Equity ETFs FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Equity ETFs FOF - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-04",
    "closeData": "2022-02-18",
    "isin": "INF846K015G0",
    "isinRe": "INF846K016G8"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149852",
    "name": "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr  2027 Top 12 Equal Weight Index Fund-Regular Plan- Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter.",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1II8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149853",
    "name": "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr  2027 Top 12 Equal Weight Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter.",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IJ6",
    "isinRe": "INF174KA1IK4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149854",
    "name": "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter.",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IM0",
    "isinRe": "INF174KA1IN8"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149855",
    "name": "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr  2027 Top 12 Equal Weight Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter.",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IL2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149858",
    "name": "ICICI Prudential Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Bank Index Fund - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-02-10",
    "closeData": "",
    "isin": "INF109KC11A4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149859",
    "name": "ICICI Prudential Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Bank Index Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2022-02-10",
    "closeData": "",
    "isin": "INF109KC1Z89",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149860",
    "name": "ICICI Prudential Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Bank Index Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-02-10",
    "closeData": "",
    "isin": "INF109KC1Z97",
    "isinRe": "INF109KC10A6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149861",
    "name": "ICICI Prudential Nifty Bank Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Bank Index Fund - Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2022-02-10",
    "closeData": "",
    "isin": "INF109KC12A2",
    "isinRe": "INF109KC13A0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149862",
    "name": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IO6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149864",
    "name": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IS7",
    "isinRe": "INF174KA1IT5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149865",
    "name": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund-Direct Plan- Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IR9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "149866",
    "name": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-04",
    "closeData": "",
    "isin": "INF174KA1IP3",
    "isinRe": "INF174KA1IQ1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149868",
    "name": "HDFC NIFTY 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY 100 Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-02-11",
    "closeData": "2022-02-18",
    "isin": "INF179KC1BY3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149869",
    "name": "HDFC NIFTY 100 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY 100 Index Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-02-11",
    "closeData": "2022-02-18",
    "isin": "INF179KC1BZ0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149871",
    "name": "HDFC NIFTY 100 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty 100 Equal Weight Index Fund - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-02-11",
    "closeData": "2022-02-18",
    "isin": "INF179KC1CB9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149870",
    "name": "HDFC NIFTY 100 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty 100 Equal Weight Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-02-11",
    "closeData": "2022-02-18",
    "isin": "INF179KC1CA1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149872",
    "name": "Axis CRISIL IBX SDL May 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX SDL May 2027 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "2022-02-21",
    "isin": "INF846K010H9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149873",
    "name": "Axis CRISIL IBX SDL May 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX SDL May 2027 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "2022-02-21",
    "isin": "INF846K017G6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149874",
    "name": "Axis CRISIL IBX SDL May 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX SDL May 2027 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "2022-02-21",
    "isin": "INF846K018G4",
    "isinRe": "INF846K019G2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149875",
    "name": "Axis CRISIL IBX SDL May 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX SDL May 2027 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-07",
    "closeData": "2022-02-21",
    "isin": "INF846K011H7",
    "isinRe": "INF846K012H5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149876",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-09",
    "closeData": "2022-02-14",
    "isin": "INF200KA11E7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149877",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-09",
    "closeData": "2022-02-14",
    "isin": "INF200KA12E5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149878",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-09",
    "closeData": "2022-02-14",
    "isin": "INF200KA13E3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149879",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 60 (1878 Days) - Direct Plan - Income Distribution cum Capital Withdrwal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-09",
    "closeData": "2022-02-14",
    "isin": "INF200KA14E1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149880",
    "name": "UTI S&P BSE Low Volatility Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Low Volatility Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter.",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF789F1AVE5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "149881",
    "name": "UTI S&P BSE Low Volatility Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Low Volatility Index Fund - Direct Plan  - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Rs.1/- thereafter.",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF789F1AVF2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149887",
    "name": "SBI MultiCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "SBI Multicap Fund- Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-14",
    "closeData": "2022-02-28",
    "isin": "INF200KA19E0",
    "isinRe": "INF200KA10F6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149882",
    "name": "SBI MultiCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "SBI Multicap Fund- Direct Plan- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-14",
    "closeData": "2022-02-28",
    "isin": "INF200KA18E2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149883",
    "name": "SBI MultiCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "SBI Multicap Fund- Regular Plan- Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-14",
    "closeData": "2022-02-28",
    "isin": "INF200KA16E6",
    "isinRe": "INF200KA17E4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149886",
    "name": "SBI MultiCap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "SBI Multicap Fund- Regular Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-14",
    "closeData": "2022-02-28",
    "isin": "INF200KA15E8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149888",
    "name": "Aditya Birla Sun Life Crisil IBX AAA - Jun 2023 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Jun-2023 Index Fund-Regular IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF209KB19G4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149889",
    "name": "Aditya Birla Sun Life Crisil IBX AAA - Jun 2023 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Jun-2023 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF209KB18G6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149890",
    "name": "Aditya Birla Sun Life Crisil IBX AAA - Jun 2023 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA-Jun 2023 Index Fund-Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF209KB11H9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "149891",
    "name": "Aditya Birla Sun Life Crisil IBX AAA - Jun 2023 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA- Jun 2023 Index Fund",
    "minAmount": "500",
    "launchDate": "2022-02-14",
    "closeData": "",
    "isin": "INF209KB10H1",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149892",
    "name": "Navi Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Midcap 150 Index Fund Direct Plan- Growth",
    "minAmount": "500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-02",
    "isin": "INF959L01FX6",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149893",
    "name": "Navi Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty Midcap 150 Index Fund Regular Plan- Growth",
    "minAmount": "500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-02",
    "isin": "INF959L01FY4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149896",
    "name": "Axis Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Smallcap 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-07",
    "isin": "INF846K016H6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149897",
    "name": "Axis Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Smallcap 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-07",
    "isin": "INF846K017H4",
    "isinRe": "INF846K018H2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149894",
    "name": "Axis Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Smallcap 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-07",
    "isin": "INF846K013H3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149895",
    "name": "Axis Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Smallcap 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "2022-03-07",
    "isin": "INF846K014H1",
    "isinRe": "INF846K015H8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149898",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund - Regular Plan -Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01BX0",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149903",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Regular Plan- Quarterly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01BZ5",
    "isinRe": "INF00XX01BY8"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149904",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Regular  Plan- Half Yearly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CB4",
    "isinRe": "INF00XX01CA6"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149905",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Regular Plan-  Annually IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CD0",
    "isinRe": "INF00XX01CC2"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149906",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Direct Plan- Quarterly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CG3",
    "isinRe": "INF00XX01CF5"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149907",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Direct Plan- Annually IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CK5",
    "isinRe": "INF00XX01CJ7"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149908",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CE8",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "149909",
    "name": "ITI Conservative Hybrid Fund",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "ITI Conservative Hybrid Fund- Direct Plan- Half Yearly IDCW Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-02-21",
    "closeData": "",
    "isin": "INF00XX01CI9",
    "isinRe": "INF00XX01CH1"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149910",
    "name": "Navi NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Navi NASDAQ 100 Fund of Fund- Direct- Growth",
    "minAmount": "500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-03",
    "closeData": "2022-03-14",
    "isin": "INF959L01FZ1",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "149911",
    "name": "Navi NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Navi NASDAQ 100 Fund of Fund- Regular- Growth",
    "minAmount": "500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-03",
    "closeData": "2022-03-14",
    "isin": "INF959L01GA2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149914",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIII-Series 5- Direct Plan-Payout of IDCW",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-25",
    "closeData": "",
    "isin": "INF204KC1485",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149915",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIII-Series 5- Regular Plan-Growth Option",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-25",
    "closeData": "",
    "isin": "INF204KC1451",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149916",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIII-Series 5- Regular Plan-Payout of IDCW",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-25",
    "closeData": "",
    "isin": "INF204KC1469",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "149917",
    "name": "Nippon India Fixed Horizon Fund - XLIII - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIII-Series 5- Direct Plan-Growth Option",
    "minAmount": "5000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-02-25",
    "closeData": "",
    "isin": "INF204KC1477",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "149918",
    "name": "Mirae Asset Nifty Midcap 150 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty Midcap 150 ETF Regular Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-02-24",
    "closeData": "",
    "isin": "INF769K01IC9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149919",
    "name": "Motilal Oswal S&P BSE Low Volatility Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Low Volatility Index Fund-Direct plan",
    "minAmount": "500",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-16",
    "isin": "INF247L01AY5",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149920",
    "name": "Motilal Oswal S&P BSE Low Volatility Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Low Volatility Index Fund-Regular plan",
    "minAmount": "500",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-16",
    "isin": "INF247L01AZ2",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "149921",
    "name": "Motilal Oswal S&P BSE Low Volatility ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal S&P BSE Low Volatility ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-16",
    "isin": "INF247L01BA3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149922",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CF0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149923",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - Quarterly Dividend Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CE3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149924",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CC7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149925",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CG8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149926",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CD5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149927",
    "name": "HDFC FMP 1861D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1861D March 2022 - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2022-03-04",
    "closeData": "2022-03-08",
    "isin": "INF179KC1CH6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149928",
    "name": "ICICI Prudential Nifty 5 yr Benchmark G-SEC ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 5 yr Benchmark G-SEC ETF",
    "minAmount": "1000",
    "launchDate": "2022-03-04",
    "closeData": "",
    "isin": "INF109KC14A8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149929",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CL8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149930",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CM6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149931",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CJ2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149932",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CN4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149933",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - Quarterly Dividend Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CK0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "149934",
    "name": "HDFC FMP 1162D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1162D March 2022 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-09",
    "closeData": "2022-03-14",
    "isin": "INF179KC1CI4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149935",
    "name": "Axis Nifty Midcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Midcap 50 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-10",
    "closeData": "2022-03-21",
    "isin": "INF846K010I7",
    "isinRe": "INF846K011I5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149936",
    "name": "Axis Nifty Midcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Midcap 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-10",
    "closeData": "2022-03-21",
    "isin": "INF846K019H0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149937",
    "name": "Axis Nifty Midcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Midcap 50 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-10",
    "closeData": "2022-03-21",
    "isin": "INF846K013I1",
    "isinRe": "INF846K014I9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "149938",
    "name": "Axis Nifty Midcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty Midcap 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-10",
    "closeData": "2022-03-21",
    "isin": "INF846K012I3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149945",
    "name": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-03-03",
    "closeData": "",
    "isin": "INF754K01OH8",
    "isinRe": "INF754K01OI6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149947",
    "name": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-03-03",
    "closeData": "",
    "isin": "INF754K01OD7",
    "isinRe": "INF754K01OE5"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149949",
    "name": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund - Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-03",
    "closeData": "",
    "isin": "INF754K01OC9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "149950",
    "name": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL PSU Plus SDL 50 50 Oct 2025 Index Fund - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-03",
    "closeData": "",
    "isin": "INF754K01OG0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149957",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Direct Plan Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149958",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149953",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149954",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149955",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Direct Plan Cumulative Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149956",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1858 Days Plan D - Direct Plan Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "149959",
    "name": "Tata Nifty India Digital Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Tata Nifty India Digital Exchange Traded Fund",
    "minAmount": "5000/-",
    "launchDate": "2022-03-14",
    "closeData": "",
    "isin": "INF277KA1364",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149960",
    "name": "Invesco India - Invesco EQQQ Nasdaq-100 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco EQQQ Nasdaq-100 ETF Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-03-30",
    "closeData": "2022-04-13",
    "isin": "INF205KA1577",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "149961",
    "name": "Invesco India - Invesco EQQQ Nasdaq-100 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Invesco India - Invesco EQQQ Nasdaq-100 ETF Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2022-03-30",
    "closeData": "2022-04-13",
    "isin": "INF205KA1585",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "149962",
    "name": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund - Direct - Payout of IDCW",
    "minAmount": "5000",
    "launchDate": "2022-03-15",
    "closeData": "",
    "isin": "INF336L01QI5",
    "isinRe": "INF336L01QJ3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "149963",
    "name": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-15",
    "closeData": "",
    "isin": "INF336L01QK1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "149964",
    "name": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund - Regular - payout of IDCW",
    "minAmount": "5000",
    "launchDate": "2022-03-15",
    "closeData": "",
    "isin": "INF336L01QL9",
    "isinRe": "INF336L01QM7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "149966",
    "name": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-15",
    "closeData": "",
    "isin": "INF336L01QH7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149968",
    "name": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund - Regular - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2022-03-11",
    "closeData": "2022-03-17",
    "isin": "INF740KA1QS5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149969",
    "name": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund - Regular - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2022-03-11",
    "closeData": "2022-03-17",
    "isin": "INF740KA1QT3",
    "isinRe": "INF740KA1QU1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149970",
    "name": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund - Direct - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2022-03-11",
    "closeData": "2022-03-17",
    "isin": "INF740KA1QV9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "149971",
    "name": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Jun 2028 30 70 Index Fund - Direct - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2022-03-11",
    "closeData": "2022-03-17",
    "isin": "INF740KA1QW7",
    "isinRe": "INF740KA1QX5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149992",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 61 (1927 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 61 (1927 Days)- Regular Plan- Income Distribution Cum Capital withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-15",
    "closeData": "2022-03-21",
    "isin": "INF200KA12F2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149993",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 61 (1927 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 61 (1927 Days)- Direct Plan- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-15",
    "closeData": "2022-03-21",
    "isin": "INF200KA13F0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149994",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 61 (1927 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 61 (1927 Days)- Regular Plan- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-15",
    "closeData": "2022-03-21",
    "isin": "INF200KA11F4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "149995",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 61 (1927 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 61 (1927 Days)- Direct Plan- Income Distribution Cum Capital withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-15",
    "closeData": "2022-03-21",
    "isin": "INF200KA14F8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149996",
    "name": "ICICI Prudential Nifty SDL Sep 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2027 Index Fund - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF109KC12B0",
    "isinRe": "INF109KC13B8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149997",
    "name": "ICICI Prudential Nifty SDL Sep 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2027 Index Fund - Direct Plan - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF109KC15B3",
    "isinRe": "INF109KC16B1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149998",
    "name": "ICICI Prudential Nifty SDL Sep 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2027 Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF109KC11B2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "149999",
    "name": "ICICI Prudential Nifty SDL Sep 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2027 Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF109KC14B6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150000",
    "name": "Kotak Nifty 100 Low Volatility 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty 100 Low Volatility 30 ETF",
    "minAmount": "Rs. 5000",
    "launchDate": "2022-03-15",
    "closeData": "",
    "isin": "INF174KA1IY5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150001",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CR5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150002",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CO2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150003",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CP9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150004",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CS3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150005",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - Quarterly Dividend Option",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CT1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150006",
    "name": "HDFC FMP 1876D March 2022",
    "category": "Income",
    "navName": "HDFC FMP 1876D March 2022 - Quarterly Dividend Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-03-23",
    "closeData": "2022-03-28",
    "isin": "INF179KC1CQ7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150008",
    "name": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF204KC1535",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150009",
    "name": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF204KC1519",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150011",
    "name": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF204KC1493",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150012",
    "name": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA CPSE Bond Plus SDL - Apr 2027 Maturity 60 40 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF204KC1527",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150157",
    "name": "Baroda BNP Paribas ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BARODA BNP PARIBAS ELSS Fund- Regular - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-11-07",
    "closeData": "",
    "isin": "INF251K01AA9",
    "isinRe": "INF251K01993"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150158",
    "name": "Baroda BNP Paribas ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BARODA BNP PARIBAS ELSS Fund - Direct Plan - IDCW Option",
    "minAmount": "500",
    "launchDate": "2005-11-07",
    "closeData": "",
    "isin": "INF251K01HH9",
    "isinRe": "INF251K01HG1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150159",
    "name": "Baroda BNP Paribas ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BARODA BNP PARIBAS ELSS Fund - Direct Plan - Growth Option",
    "minAmount": "500",
    "launchDate": "2005-11-07",
    "closeData": "",
    "isin": "INF251K01HF3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150156",
    "name": "Baroda BNP Paribas ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "BARODA BNP PARIBAS ELSS Fund - Regular - Growth Option",
    "minAmount": "500",
    "launchDate": "2005-11-07",
    "closeData": "",
    "isin": "INF251K01985",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150160",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund-Defunct Plan-Growth Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DL0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150161",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund-Defunct Plan-Weekly IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DN6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150162",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund-Defunct Plan-Monthly IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DO4",
    "isinRe": "INF251K01DP1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150163",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund-Defunct Plan- Daily IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DM8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150164",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION FUND - Regular Plan - DAILY IDCW OPTION",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DR7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150165",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION FUND - Regular Plan - GROWTH OPTION",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DQ9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150166",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION FUND - Regular Plan - WEEKLY IDCW OPTION",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DS5",
    "isinRe": "INF251K01DT3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150167",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION FUND - Regular Plan - MONTHLY IDCW OPTION",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01DU1",
    "isinRe": "INF251K01DV9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150168",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01GK5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150169",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01GJ7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150170",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01GN9",
    "isinRe": "INF251K01GO7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150171",
    "name": "BARODA BNP PARIBAS LOW DURATION FUND",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "BARODA BNP PARIBAS LOW DURATION Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "Rs.10000/-Reg Plan & Rs.1 Cr in Inst.Plan",
    "launchDate": "2005-10-17",
    "closeData": "",
    "isin": "INF251K01GL3",
    "isinRe": "INF251K01GM1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150172",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund-Defunct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01EF0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150173",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01DW7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150180",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Growth Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01GW0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150181",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Half Yearly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HD8",
    "isinRe": "INF251K01HE6"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150182",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01GZ3",
    "isinRe": "INF251K01HA4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150183",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Quarterly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HB2",
    "isinRe": "INF251K01HC0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150184",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01GY6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150174",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Daily IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01DX5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150175",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Weekly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01DY3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150176",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01DZ0",
    "isinRe": "INF251K01EA1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150177",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Quarterly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01EB9",
    "isinRe": "INF251K01EC7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150178",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Regular Plan - Half Yearly IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01ED5",
    "isinRe": "INF251K01EE3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150179",
    "name": "Baroda BNP Paribas Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "Baroda BNP Paribas Dynamic Bond Fund - Direct Plan - Daily IDCW Option",
    "minAmount": "5000/-",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01GX8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150187",
    "name": "BARODA BNP PARIBAS LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BARODA BNP PARIBAS LARGE CAP Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HN7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150188",
    "name": "BARODA BNP PARIBAS LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BARODA BNP PARIBAS LARGE CAP Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HL1",
    "isinRe": "INF251K01HM9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150185",
    "name": "BARODA BNP PARIBAS LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BARODA BNP PARIBAS LARGE CAP Fund- Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01894",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150186",
    "name": "BARODA BNP PARIBAS LARGE CAP FUND",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "BARODA BNP PARIBAS LARGE CAP Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01910",
    "isinRe": "INF251K01902"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150204",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative hybrid Fund-Regular Plan-Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01852",
    "isinRe": "INF251K01860"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150205",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative Hybrid Fund-Regular Plan-Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01878",
    "isinRe": "INF251K01886"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150206",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative Hybrid Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HR8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150207",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative Hybrid Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HS6",
    "isinRe": "INF251K01HT4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150208",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative Hybrid Fund - Direct Plan - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01HU2",
    "isinRe": "INF251K01HV0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150203",
    "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND",
    "category": "Hybrid Scheme - Conservative Hybrid Fund",
    "navName": "BARODA BNP PARIBAS Conservative Hybrid Fund-Regular Plan-Growth Option",
    "minAmount": "5000",
    "launchDate": "2004-08-09",
    "closeData": "",
    "isin": "INF251K01845",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150209",
    "name": "Baroda BNP Paribas Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BARODA BNP PARIBAS Mid Cap Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-03-13",
    "closeData": "",
    "isin": "INF251K01AW3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150210",
    "name": "Baroda BNP Paribas Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BARODA BNP PARIBAS Mid Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2006-03-13",
    "closeData": "",
    "isin": "INF251K01AX1",
    "isinRe": "INF251K01AY9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150211",
    "name": "Baroda BNP Paribas Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BARODA BNP PARIBAS Mid Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2006-03-13",
    "closeData": "",
    "isin": "INF251K01HO5",
    "isinRe": "INF251K01HP2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150212",
    "name": "Baroda BNP Paribas Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BARODA BNP PARIBAS Mid Cap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2006-03-13",
    "closeData": "",
    "isin": "INF251K01HQ0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150228",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Defunct Plan - Quarterly-IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EL8",
    "isinRe": "INF251K01EM6"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150229",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Defunct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EI4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150230",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Defunct Plan - Annual-IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EN4",
    "isinRe": "INF251K01EO2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150231",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Defunct Plan - Monthly-IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EJ2",
    "isinRe": "INF251K01EK0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150232",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS CORPORATE BOND FUND - Regular Plan - QUARTERLY IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01ES3",
    "isinRe": "INF251K01ET1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150233",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS CORPORATE BOND FUND - Regular Plan - ANNUAL IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EU9",
    "isinRe": "INF251K01EV7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150234",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS CORPORATE BOND FUND - Regular Plan - MONTHLY IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EQ7",
    "isinRe": "INF251K01ER5"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150235",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS CORPORATE BOND FUND - Regular Plan - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01EP9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150236",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Direct Plan - Annual IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01GU4",
    "isinRe": "INF251K01GV2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150237",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01GP4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150238",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01GQ2",
    "isinRe": "INF251K01GR0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150239",
    "name": "BARODA BNP PARIBAS CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "BARODA BNP PARIBAS Corporate Bond Fund - Direct Plan - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2008-09-29",
    "closeData": "2008-10-22",
    "isin": "INF251K01GS8",
    "isinRe": "INF251K01GT6"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150305",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Defunct Plan - Daily IDCW Option - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150306",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Direct Plan - Monthly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150294",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Direct Plan - Growth - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150295",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration - Regular Plan - Quarterly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150300",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Defunct Plan - Monthly IDCW Option - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150301",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Defunct Plan - Growth Option - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150303",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Direct Plan - Quarterly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150304",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund  - Direct Plan - Weekly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150288",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Regular Plan - Daily IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150289",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Regular Plan - Weekly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150290",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Defunct Plan - Weekly IDCW Option - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150291",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Regular Plan - Growth - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150292",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration - Regular Plan - Monthly IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150293",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "Baroda BNP Paribas Medium Duration Fund - Direct Plan - Daily IDCW - Segregated Portfolio - 1",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150244",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Direct Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LV2",
    "isinRe": "INF251K01MD8"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150245",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Direct Plan - Calendar Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LW0",
    "isinRe": "INF251K01ME6"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150246",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Direct Plan - Half yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LX8",
    "isinRe": "INF251K01MF3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150247",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Direct Plan - Annual IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LY6",
    "isinRe": "INF251K01MG1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150248",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Regular Plan - Half Yearly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LR0",
    "isinRe": "INF251K01MB2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150249",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Regular Plan - Annual IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LP4",
    "isinRe": "INF251K01LZ3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150240",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LT6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150241",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LU4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150242",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Regular Plan - Calendar Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LQ2",
    "isinRe": "INF251K01MA4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150243",
    "name": "Baroda BNP Paribas Medium Duration Fund (scheme has one segregated portfolio)",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "BARODA BNP PARIBAS Medium Duration Fund - Regular Plan - Monthly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2014-02-14",
    "closeData": "2014-02-28",
    "isin": "INF251K01LS8",
    "isinRe": "INF251K01MC0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150253",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND-REGULAR PLAN- ADHOC IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01OP8",
    "isinRe": "INF251K01OO1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150254",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND REGULAR PLAN MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01PD1",
    "isinRe": "INF251K01PC3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150255",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND DIRECT PLAN MONTHLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01PF6",
    "isinRe": "INF251K01PE9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150256",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND DIRECT PLAN QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01PJ8",
    "isinRe": "INF251K01PI0"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150257",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND REGULAR PLAN QUARTERLY IDCW Option",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01PH2",
    "isinRe": "INF251K01PG4"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150250",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND- REGULAR PLAN- GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01ON3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150251",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND-DIRECT PLAN-GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01OT0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150252",
    "name": "BARODA BNP PARIBAS ARBITRAGE FUND",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "BARODA BNP PARIBAS ARBITRAGE FUND -DIRECT PLAN - ADHOC IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2016-12-08",
    "closeData": "2016-12-22",
    "isin": "INF251K01OV6",
    "isinRe": "INF251K01OU8"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150258",
    "name": "BARODA BNP PARIBAS AGGRESSIVE HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Baroda BNP Paribas Aggressive Hybrid Fund- REGULAT PLAN -GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2017-03-17",
    "closeData": "2017-03-31",
    "isin": "INF251K01OW4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150259",
    "name": "BARODA BNP PARIBAS AGGRESSIVE HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Baroda BNP Paribas Aggressive Hybrid Fund -DIRECT PLAN- IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2017-03-17",
    "closeData": "2017-03-31",
    "isin": "INF251K01PB5",
    "isinRe": "INF251K01PA7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150260",
    "name": "BARODA BNP PARIBAS AGGRESSIVE HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Baroda BNP Paribas Aggressive Hybrid Fund- DIRECT PLAN - GROWTH OPTION",
    "minAmount": "5000",
    "launchDate": "2017-03-17",
    "closeData": "2017-03-31",
    "isin": "INF251K01OZ7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150261",
    "name": "BARODA BNP PARIBAS AGGRESSIVE HYBRID FUND",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "Baroda BNP Paribas Aggressive Hybrid Fund- REGULAR PLAN- IDCW OPTION",
    "minAmount": "5000",
    "launchDate": "2017-03-17",
    "closeData": "2017-03-31",
    "isin": "INF251K01OY0",
    "isinRe": "INF251K01OX2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150262",
    "name": "Baroda BNP Paribas Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BARODA BNP PARIBAS Focused Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-15",
    "closeData": "",
    "isin": "INF251K01PL4",
    "isinRe": "INF251K01PM2"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150263",
    "name": "Baroda BNP Paribas Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BARODA BNP PARIBAS Focused Fund - Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-15",
    "closeData": "",
    "isin": "INF251K01PK6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150264",
    "name": "Baroda BNP Paribas Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BARODA BNP PARIBAS Focused Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-15",
    "closeData": "",
    "isin": "INF251K01PN0",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150265",
    "name": "Baroda BNP Paribas Focused Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "BARODA BNP PARIBAS Focused Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2017-09-15",
    "closeData": "",
    "isin": "INF251K01PO8",
    "isinRe": "INF251K01PP5"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150266",
    "name": "Baroda BNP Paribas India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BARODA BNP PARIBAS India Consumption Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF251K01PT7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150267",
    "name": "Baroda BNP Paribas India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BARODA BNP PARIBAS India Consumption Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF251K01PS9",
    "isinRe": "INF251K01PR1"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150268",
    "name": "Baroda BNP Paribas India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BARODA BNP PARIBAS India Consumption Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF251K01PQ3",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150269",
    "name": "Baroda BNP Paribas India Consumption Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BARODA BNP PARIBAS India Consumption Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2018-08-17",
    "closeData": "",
    "isin": "INF251K01PV3",
    "isinRe": "INF251K01PU5"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150284",
    "name": "Baroda BNP Paribas Aqua Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Baroda BNP Paribas Aqua Fund of Fund - Regular Plan Growth",
    "minAmount": "Rs. 5000 per application and in multiples of Rs.1",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF251K01QQ1",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150285",
    "name": "Baroda BNP Paribas Aqua Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Baroda BNP Paribas Aqua Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 per application and in multiples of Rs.1",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF251K01QT5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150286",
    "name": "Baroda BNP Paribas Aqua Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Baroda BNP Paribas Aqua Fund of Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 per application and in multiples of Rs.1",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF251K01QR9",
    "isinRe": "INF251K01QS7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150287",
    "name": "Baroda BNP Paribas Aqua Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Baroda BNP Paribas Aqua Fund of Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 per application and in multiples of Rs.1",
    "launchDate": "2021-04-16",
    "closeData": "",
    "isin": "INF251K01QU3",
    "isinRe": "INF251K01QV1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150308",
    "name": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND - Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF109KC17B9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150309",
    "name": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF109KC18B7",
    "isinRe": "INF109KC19B5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150310",
    "name": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND - Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF109KC10C2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150311",
    "name": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND - Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF109KC11C0",
    "isinRe": "INF109KC12C8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150312",
    "name": "UTI Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty Midcap 150 Quality 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Minimum Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF789F1AVG0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150313",
    "name": "UTI Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty Midcap 150 Quality 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Minimum Rs.5000/- & in multiple of Rs 1/-",
    "launchDate": "2022-03-28",
    "closeData": "",
    "isin": "INF789F1AVH8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150314",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days) - Regular Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF209KB12H7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150315",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days) - Regular Plan - Payout of IDCW option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF209KB13H5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150316",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days) - Direct Plan - Growth Option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF209KB14H3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150317",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TQ (1879 days) - Direct Plan - Payout of IDCW option",
    "minAmount": "Rs. 1000/- and in multiples of Rs.10/- thereafter",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF209KB15H0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150318",
    "name": "Kotak FMP Series 294",
    "category": "Income",
    "navName": "Kotak FMP Series 294-105 days-Regular Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1IU3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150319",
    "name": "Kotak FMP Series 294",
    "category": "Income",
    "navName": "Kotak FMP Series 294-105 days - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1IV1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150320",
    "name": "Kotak FMP Series 294",
    "category": "Income",
    "navName": "Kotak FMP Series-294-105 days-Direct Plan- Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1IW9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150321",
    "name": "Kotak FMP Series 294",
    "category": "Income",
    "navName": "Kotak FMP Series 294-Direct Plan-IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1IX7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150325",
    "name": "Kotak FMP Series 295 - 176 days",
    "category": "Income",
    "navName": "Kotak FMP Series 295 - 176 days Direct Plan IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1JC9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150322",
    "name": "Kotak FMP Series 295 - 176 days",
    "category": "Income",
    "navName": "Kotak FMP Series 295 - 176 days Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1IZ2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150323",
    "name": "Kotak FMP Series 295 - 176 days",
    "category": "Income",
    "navName": "Kotak FMP Series 295 - 176 days Regular Plan IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1JA3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150324",
    "name": "Kotak FMP Series 295 - 176 days",
    "category": "Income",
    "navName": "Kotak FMP Series 295 - 176 days Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-03-22",
    "closeData": "",
    "isin": "INF174KA1JB1",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150326",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund- Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1372",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150327",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund-Direct Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1380",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150328",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund-Direct Plan- IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1398",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150329",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund-Regular Plan- IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1422",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150330",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund-Regular Plan -Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1406",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150331",
    "name": "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty SDL Plus AAA PSU Bond Dec 6040 Index Fund -Regular Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-24",
    "closeData": "",
    "isin": "INF277KA1414",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150340",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund-Direct Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1448",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150341",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund-Direct Plan- IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1455",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150342",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund-Regular Plan-IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1471",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150343",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund-Regular Plan-IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1489",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150344",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund-Direct Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1430",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150345",
    "name": "Tata Nifty India Digital ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Tata Nifty India Digital ETF Fund of Fund -Regular Plan-Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF277KA1463",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150346",
    "name": "WhiteOak Capital Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "WhiteOak Capital Flexi Cap Fund Direct Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-12",
    "closeData": "2022-07-26",
    "isin": "INF03VN01530",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150347",
    "name": "WhiteOak Capital Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "WhieOak Capital Flexi Cap Fund Regular Plan Growth",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-12",
    "closeData": "2022-07-26",
    "isin": "INF03VN01506",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150348",
    "name": "WhiteOak Capital Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "WhiteOak Capital Flexi Cap Fund Regular Plan IDCW",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-12",
    "closeData": "2022-07-26",
    "isin": "INF03VN01522",
    "isinRe": "INF03VN01514"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150349",
    "name": "WhiteOak Capital Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "WhiteOak Capital Flexi Cap Fund Direct Plan IDCW",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-12",
    "closeData": "2022-07-26",
    "isin": "INF03VN01555",
    "isinRe": "INF03VN01548"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150350",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-Apr 2027 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB18H4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150351",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-Apr 2027 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB17H6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150352",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-Apr 2027 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB10I9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150353",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-Apr 2027 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB19H2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150354",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB11I7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150355",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB14I1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150356",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB12I5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150357",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU-APR 2025 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF209KB13I3",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150358",
    "name": "Mirae Asset Nifty SDL Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL Jun 2027 Index Fund -Direct Plan - Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF769K01IH8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150359",
    "name": "Mirae Asset Nifty SDL Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL Jun 2027 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF769K01IG0",
    "isinRe": "INF769K01II6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150360",
    "name": "Mirae Asset Nifty SDL Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL Jun 2027 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF769K01IE5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150361",
    "name": "Mirae Asset Nifty SDL Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL Jun 2027 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-03-25",
    "closeData": "",
    "isin": "INF769K01ID7",
    "isinRe": "INF769K01IF2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150362",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 62 (191 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 62 (191 Days)- Direct Plan- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-29",
    "closeData": "2022-03-29",
    "isin": "INF200KA17F1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150363",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 62 (191 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 62 (191 Days)- Regular Plan- Income Distribution Cum Capital Withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-29",
    "closeData": "2022-03-29",
    "isin": "INF200KA16F3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150364",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 62 (191 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 62 (191 Days)- Direct Plan- Income Distribution Cum Capital Withdrawal option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-29",
    "closeData": "2022-03-29",
    "isin": "INF200KA18F9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150365",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 62 (191 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)- Series 62 (191 Days)- Regular Plan- Growth option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-03-29",
    "closeData": "2022-03-29",
    "isin": "INF200KA15F5",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "150366",
    "name": "NJ Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NJ Arbitrage Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF0J8L01057",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "150367",
    "name": "NJ Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "NJ Arbitrage Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF0J8L01065",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "150369",
    "name": "NJ Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NJ Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiple of Rs. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF0J8L01081",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "150368",
    "name": "NJ Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "NJ Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiple of Rs. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF0J8L01073",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150376",
    "name": "Edelweiss Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Edelweiss Focused Equity Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-12",
    "closeData": "",
    "isin": "INF754K01OP1",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150382",
    "name": "Edelweiss Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Edelweiss Focused Equity Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-12",
    "closeData": "",
    "isin": "INF754K01OL0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150383",
    "name": "Edelweiss Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Edelweiss Focused Equity Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-12",
    "closeData": "",
    "isin": "INF754K01OQ9",
    "isinRe": "INF754K01OR7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150384",
    "name": "Edelweiss Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "Edelweiss Focused Equity Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-12",
    "closeData": "",
    "isin": "INF754K01OM8",
    "isinRe": "INF754K01ON6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150377",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-18",
    "isin": "INF200KA19L5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150378",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-18",
    "isin": "INF200KA12M8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150379",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-18",
    "isin": "INF200KA11M0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150380",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 66 (1361 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-18",
    "isin": "INF200KA10M2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150385",
    "name": "Baroda BNP Paribas Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Baroda BNP Paribas Flexi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-07-25",
    "closeData": "",
    "isin": "INF251K01QW9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150386",
    "name": "Baroda BNP Paribas Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Baroda BNP Paribas Flexi Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-07-25",
    "closeData": "",
    "isin": "INF251K01QX7",
    "isinRe": "INF251K01QY5"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150387",
    "name": "Baroda BNP Paribas Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Baroda BNP Paribas Flexi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-07-25",
    "closeData": "",
    "isin": "INF251K01QZ2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150388",
    "name": "Baroda BNP Paribas Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Baroda BNP Paribas Flexi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-07-25",
    "closeData": "",
    "isin": "INF251K01RA3",
    "isinRe": "INF251K01RB1"
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "150389",
    "name": "Quantum Nifty 50 ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Nifty 50 ETF Fund of Fund - Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2022-07-18",
    "closeData": "2022-08-01",
    "isin": "INF082J01424",
    "isinRe": null
  },
  {
    "amc": "Quantum Asset Management Company Private Limited",
    "code": "150390",
    "name": "Quantum Nifty 50 ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Quantum Nifty 50 ETF Fund of Fund - Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2022-07-18",
    "closeData": "2022-08-01",
    "isin": "INF082J01416",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150391",
    "name": "LIC MF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "LIC MF Money Market Fund-Direct IDCW",
    "minAmount": "Rs.5000/- and in multiple of Rs.1/- thereafter",
    "launchDate": "2022-07-28",
    "closeData": "2022-07-29",
    "isin": "INF767K01RF7",
    "isinRe": "INF767K01RG5"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150392",
    "name": "LIC MF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "LIC MF Money Market Fund-Direct Growth",
    "minAmount": "Rs.5000/- and in multiple of Rs.1/- thereafter",
    "launchDate": "2022-07-28",
    "closeData": "2022-07-29",
    "isin": "INF767K01RE0",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150393",
    "name": "LIC MF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "LIC MF Money Market Fund-Regular Growth",
    "minAmount": "Rs.5000/- and in multiple of Rs.1/- thereafter",
    "launchDate": "2022-07-28",
    "closeData": "2022-07-29",
    "isin": "INF767K01RB6",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150394",
    "name": "LIC MF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "LIC MF Money Market Fund-Regular IDCW",
    "minAmount": "Rs.5000/- and in multiple of Rs.1/- thereafter",
    "launchDate": "2022-07-28",
    "closeData": "2022-07-29",
    "isin": "INF767K01RC4",
    "isinRe": "INF767K01RD2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150399",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CZ8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150400",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CV7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150395",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CU9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150396",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CX3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150397",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CW5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150398",
    "name": "HDFC FMP 1158D July 2022",
    "category": "Income",
    "navName": "HDFC FMP 1158D July 2022 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-07-19",
    "closeData": "2022-07-25",
    "isin": "INF179KC1CY1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150401",
    "name": "BANDHAN MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANDHAN MIDCAP FUND - IDCW - DIRECT PLAN",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF194KB1DK0",
    "isinRe": "INF194KB1DL8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150402",
    "name": "BANDHAN MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANDHAN MIDCAP FUND - GROWTH - REGULAR PLAN",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF194KB1DM6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150403",
    "name": "BANDHAN MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANDHAN MIDCAP FUND - IDCW - REGULAR PLAN",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF194KB1DN4",
    "isinRe": "INF194KB1DO2"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150404",
    "name": "BANDHAN MIDCAP FUND",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "BANDHAN MIDCAP FUND - GROWTH - DIRECT PLAN",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF194KB1DJ2",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150405",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01ID0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150406",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01IH1",
    "isinRe": "INF582M01II9"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150407",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01HW2",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150408",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01IA6",
    "isinRe": "INF582M01IB4"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150456",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Direct Plan - Half-yearly IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01HX0",
    "isinRe": "INF582M01HY8"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150457",
    "name": "Union Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "Union Gilt Fund - Regular Plan - Half-yearly IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF582M01IE8",
    "isinRe": "INF582M01IF5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150419",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVN6",
    "isinRe": "INF789F1AVO4"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150420",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Regular Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVP1",
    "isinRe": "INF789F1AVQ9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150423",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVW7",
    "isinRe": "INF789F1AVX5"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150424",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Direct Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVY3",
    "isinRe": "INF789F1AVZ0"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150409",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVI6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150410",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVR7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150411",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVJ4",
    "isinRe": "INF789F1AVK2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150412",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVS5",
    "isinRe": "INF789F1AVT3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150415",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Direct Plan - Half Yearly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVU1",
    "isinRe": "INF789F1AVV9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150416",
    "name": "UTI Gilt Fund with 10 year Constant Duration",
    "category": "Debt Scheme - Gilt Fund with 10 year constant duration",
    "navName": "UTI Gilt Fund with 10 year Constant Duration - Regular Plan - Half Yearly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter",
    "launchDate": "2022-07-18",
    "closeData": "",
    "isin": "INF789F1AVL0",
    "isinRe": "INF789F1AVM8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150427",
    "name": "DSP Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Midcap 150 Quality 50 Index Fund - Regular - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-07-18",
    "closeData": "2022-07-29",
    "isin": "INF740KA1QY3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150428",
    "name": "DSP Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Midcap 150 Quality 50 Index Fund - Direct - Growth",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-07-18",
    "closeData": "2022-07-29",
    "isin": "INF740KA1RB9",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150429",
    "name": "DSP Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Midcap 150 Quality 50 Index Fund - Direct - IDCW",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-07-18",
    "closeData": "2022-07-29",
    "isin": "INF740KA1RC7",
    "isinRe": "INF740KA1RD5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150430",
    "name": "DSP Nifty Midcap 150 Quality 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty Midcap 150 Quality 50 Index Fund - Regular - IDCW",
    "minAmount": "Rs. 500/- and any amount thereafter",
    "launchDate": "2022-07-18",
    "closeData": "2022-07-29",
    "isin": "INF740KA1QZ0",
    "isinRe": "INF740KA1RA1"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "150439",
    "name": "quant Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "quant Large Cap Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-20",
    "closeData": "",
    "isin": "INF966L01AU8",
    "isinRe": "INF966L01AV6"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "150440",
    "name": "quant Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "quant Large Cap Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-20",
    "closeData": "",
    "isin": "INF966L01AT0",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "150441",
    "name": "quant Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "quant Large Cap Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-20",
    "closeData": "",
    "isin": "INF966L01AW4",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "150442",
    "name": "quant Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "quant Large Cap Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2022-07-20",
    "closeData": "",
    "isin": "INF966L01AX2",
    "isinRe": "INF966L01AY0"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150443",
    "name": "Motilal Oswal S&P BSE Financials ex Bank 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Financials ex Bank 30 Index Fund Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-22",
    "isin": "INF247L01BC9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150444",
    "name": "Motilal Oswal S&P BSE Financials ex Bank 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Financials ex Bank 30 Index Fund Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-22",
    "isin": "INF247L01BD7",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150445",
    "name": "Motilal Oswal S&P BSE Healthcare ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal S&P BSE Healthcare ETF",
    "minAmount": "500",
    "launchDate": "2022-07-14",
    "closeData": "2022-07-22",
    "isin": "INF247L01BB1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150447",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC15D9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150448",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC18D3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150449",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC17D5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150450",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC14D2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150524",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC16D7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150525",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 226 Days Plan E - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC19D1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150451",
    "name": "ICICI Prudential Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 200 Momentum 30 Index Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC19C3",
    "isinRe": "INF109KC10D0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150452",
    "name": "ICICI Prudential Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 200 Momentum 30 Index Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC11D8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150453",
    "name": "ICICI Prudential Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 200 Momentum 30 Index Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC12D6",
    "isinRe": "INF109KC13D4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150454",
    "name": "ICICI Prudential Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty 200 Momentum 30 Index Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC18C5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150455",
    "name": "ICICI Prudential Nifty 200 Momentum 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 200 Momentum 30 ETF",
    "minAmount": "1000",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF109KC17C7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150458",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days) - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF200KA16M9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150459",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days) - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF200KA14M4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150460",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days) - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF200KA15M1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150461",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 67 (1467 Days) - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF200KA13M6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150466",
    "name": "ICICI Prudential Nifty IT Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty IT Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF109KC10E8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150467",
    "name": "ICICI Prudential Nifty IT Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty IT Index Fund - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF109KC11E6",
    "isinRe": "INF109KC12E4"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150468",
    "name": "ICICI Prudential Nifty IT Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty IT Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF109KC13E2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150469",
    "name": "ICICI Prudential Nifty IT Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty IT Index Fund - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-07-28",
    "closeData": "",
    "isin": "INF109KC14E0",
    "isinRe": "INF109KC15E7"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150470",
    "name": "Mirae Asset Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mirae Asset Balanced Advantage Fund Direct Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF769K01IN6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150471",
    "name": "Mirae Asset Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mirae Asset Balanced Advantage Fund Direct Plan- IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF769K01IM8",
    "isinRe": "INF769K01IO4"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150473",
    "name": "Mirae Asset Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mirae Asset Balanced Advantage Fund Regular Plan- Growth",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF769K01IK2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150474",
    "name": "Mirae Asset Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Mirae Asset Balanced Advantage Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2022-07-21",
    "closeData": "",
    "isin": "INF769K01IJ4",
    "isinRe": "INF769K01IL0"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150476",
    "name": "HDFC NIFTY 100 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY 100 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-07-25",
    "closeData": "2022-08-01",
    "isin": "INF179KC1DB7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150477",
    "name": "HDFC NIFTY NEXT 50 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY NEXT 50 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-07-25",
    "closeData": "2022-08-01",
    "isin": "INF179KC1DA9",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "150478",
    "name": "Franklin India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Franklin India Balanced Advantage Fund- IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF090I01WO0",
    "isinRe": "INF090I01WP7"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "150479",
    "name": "Franklin India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Franklin India Balanced Advantage Fund- IDCW- Direct",
    "minAmount": "5000",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF090I01WR3",
    "isinRe": "INF090I01WS1"
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "150480",
    "name": "Franklin India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Franklin India Balanced Advantage Fund- Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF090I01WN2",
    "isinRe": null
  },
  {
    "amc": "Franklin Templeton Asset Management (India) Private Limited",
    "code": "150481",
    "name": "Franklin India Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "Franklin India Balanced Advantage Fund- Growth- Direct",
    "minAmount": "5000",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF090I01WQ5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150482",
    "name": "Aditya Birla Sun Life Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Aditya Birla Sun Life Long Duration Fund-Regular Growth",
    "minAmount": "100",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF209KB14J9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150483",
    "name": "Aditya Birla Sun Life Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Aditya Birla Sun Life Long Duration Fund-Regular IDCW Payout",
    "minAmount": "100",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF209KB15J6",
    "isinRe": "INF209KB16J4"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150484",
    "name": "Aditya Birla Sun Life Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Aditya Birla Sun Life Long Duration Fund-Direct Growth",
    "minAmount": "100",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF209KB17J2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150485",
    "name": "Aditya Birla Sun Life Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Aditya Birla Sun Life Long Duration Fund-Direct IDCW Payout",
    "minAmount": "100",
    "launchDate": "2022-07-22",
    "closeData": "",
    "isin": "INF209KB18J0",
    "isinRe": "INF209KB19J8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150487",
    "name": "Nippon India Nifty Alpha Low Volatility30IndexFund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Alpha Low Volatility 30 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-08-01",
    "closeData": "",
    "isin": "INF204KC1584",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150488",
    "name": "Nippon India Nifty Alpha Low Volatility30IndexFund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Alpha Low Volatility 30 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-08-01",
    "closeData": "",
    "isin": "INF204KC1592",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150490",
    "name": "Nippon India Nifty Alpha Low Volatility30IndexFund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Alpha Low Volatility 30 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-08-01",
    "closeData": "",
    "isin": "INF204KC1550",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150491",
    "name": "Nippon India Nifty Alpha Low Volatility30IndexFund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty Alpha Low Volatility 30 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2022-08-01",
    "closeData": "",
    "isin": "INF204KC1568",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150492",
    "name": "Kotak Nifty India Consumption ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty India Consumption ETF",
    "minAmount": "5000",
    "launchDate": "2022-07-25",
    "closeData": "",
    "isin": "INF174KA1JE5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150497",
    "name": "Aditya Birla Sun Life Nifty 200 Quality 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty 200 Quality 30 ETF",
    "minAmount": "100",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF209KB15K4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150498",
    "name": "Aditya Birla Sun Life Nifty 200 Momentum 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life Nifty 200 Momentum 30 ETF",
    "minAmount": "100",
    "launchDate": "2022-07-29",
    "closeData": "",
    "isin": "INF209KB14K7",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150505",
    "name": "Canara Robeco Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Canara Robeco Banking and PSU Debt Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-12",
    "isin": "INF760K01KC4",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150507",
    "name": "Canara Robeco Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Canara Robeco Banking and PSU Debt Fund- Direct Plan- IDCW Payout",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-12",
    "isin": "INF760K01KE0",
    "isinRe": "INF760K01KD2"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150503",
    "name": "Canara Robeco Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Canara Robeco Banking and PSU Debt Fund- Regular Plan- Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-12",
    "isin": "INF760K01KF7",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150504",
    "name": "Canara Robeco Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "Canara Robeco Banking and PSU Debt Fund- Regular Plan- IDCW (Payout/ Reinvestment)",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-12",
    "isin": "INF760K01KH3",
    "isinRe": "INF760K01KG5"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "150511",
    "name": "TRUSTMF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "TRUSTMF MONEY MARKET FUND-REGULAR PLAN-GROWTH",
    "minAmount": "1000",
    "launchDate": "2022-08-05",
    "closeData": "",
    "isin": "INF0GCD01479",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "150512",
    "name": "TRUSTMF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "TRUSTMF MONEY MARKET FUND-REGULAR PLAN-MONTHLY IDCW",
    "minAmount": "1000",
    "launchDate": "2022-08-05",
    "closeData": "",
    "isin": "INF0GCD01495",
    "isinRe": "INF0GCD01487"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "150513",
    "name": "TRUSTMF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "TRUSTMF MONEY MARKET FUND-DIRECT PLAN-GROWTH",
    "minAmount": "1000",
    "launchDate": "2022-08-05",
    "closeData": "",
    "isin": "INF0GCD01503",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "150514",
    "name": "TRUSTMF Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "TRUSTMF MONEY MARKET FUND-DIRECT PLAN-MONTHLY IDCW",
    "minAmount": "1000",
    "launchDate": "2022-08-05",
    "closeData": "",
    "isin": "INF0GCD01529",
    "isinRe": "INF0GCD01511"
  },
  {
    "amc": "Navi AMC Limited",
    "code": "150515",
    "name": "Navi Nifty India Manufacturing Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty India Manufacturing Index Fund- Direct Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-08-12",
    "closeData": "2022-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "150516",
    "name": "Navi Nifty India Manufacturing Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Navi Nifty India Manufacturing Index Fund- Regular Plan- Growth",
    "minAmount": "Rs. 500/-",
    "launchDate": "2022-08-12",
    "closeData": "2022-08-23",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150517",
    "name": "Motilal Oswal S&P BSE Enhanced Value ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal SP BSE Enhanced Value ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-05",
    "isin": "INF247L01BE5",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150518",
    "name": "Motilal Oswal S&P BSE Enhanced Value Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Enhanced Value Index Fund-Direct plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-05",
    "isin": "INF247L01BF2",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150519",
    "name": "Motilal Oswal S&P BSE Enhanced Value Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Enhanced Value Index Fund-Regular plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-05",
    "isin": "INF247L01BG0",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150520",
    "name": "Motilal Oswal S&P BSE Quality ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Motilal Oswal S&P BSE Quality ETF",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-12",
    "isin": "INF247L01BH8",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150521",
    "name": "Motilal Oswal S&P BSE Quality Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal SP BSE Quality Index Fund-Direct plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-05",
    "isin": "INF247L01BI6",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150522",
    "name": "Motilal Oswal S&P BSE Quality Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal S&P BSE Quality Index Fund-Regular plan",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-07-29",
    "closeData": "2022-08-05",
    "isin": "INF247L01BJ4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150523",
    "name": "DSP Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Silver ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereof.",
    "launchDate": "2022-08-01",
    "closeData": "2022-08-12",
    "isin": "INF740KA1RE3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150526",
    "name": "ICICI Prudential Nifty Infrastructure ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Infrastructure ETF",
    "minAmount": "1000",
    "launchDate": "2022-08-05",
    "closeData": "",
    "isin": "INF109KC16E5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150531",
    "name": "Kotak Nifty MNC ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty MNC ETF",
    "minAmount": "5000",
    "launchDate": "2022-08-01",
    "closeData": "",
    "isin": "INF174KA1JF2",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150532",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1497",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150533",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Direct Plan - IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1505",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150534",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Direct Plan - IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1513",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150535",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Regular Plan - IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1547",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150536",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Regular Plan - IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1539",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150537",
    "name": "TATA HOUSING OPPORTUNITIES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Tata Housing Opportunities Fund - Regular Plan - Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF277KA1521",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150538",
    "name": "ICICI PRUDENTIAL PSU EQUITY FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential PSU Equity Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-23",
    "closeData": "",
    "isin": "INF109KC19H2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150539",
    "name": "ICICI PRUDENTIAL PSU EQUITY FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential PSU Equity Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-23",
    "closeData": "",
    "isin": "INF109KC12I5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150540",
    "name": "ICICI PRUDENTIAL PSU EQUITY FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential PSU Equity Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-23",
    "closeData": "",
    "isin": "INF109KC10I9",
    "isinRe": "INF109KC11I7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150541",
    "name": "ICICI PRUDENTIAL PSU EQUITY FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential PSU Equity Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-23",
    "closeData": "",
    "isin": "INF109KC13I3",
    "isinRe": "INF109KC14I1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150542",
    "name": "JM Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Duration Fund (Regular) - Growth",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2022-08-10",
    "closeData": "",
    "isin": "INF192K01MM5",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150543",
    "name": "JM Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Duration Fund (Regular) - IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2022-08-10",
    "closeData": "",
    "isin": "INF192K01MN3",
    "isinRe": "INF192K01MO1"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150544",
    "name": "JM Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Duration Fund (Direct) - IDCW",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2022-08-10",
    "closeData": "",
    "isin": "INF192K01MQ6",
    "isinRe": "INF192K01MR4"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150545",
    "name": "JM Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "JM Short Duration Fund (Direct) - Growth",
    "minAmount": "Rs 5000/- per option",
    "launchDate": "2022-08-10",
    "closeData": "",
    "isin": "INF192K01MP8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150546",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150547",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150548",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150549",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150550",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150551",
    "name": "HDFC FMP 1406D August 2022",
    "category": "Income",
    "navName": "HDFC FMP 1406D August 2022 - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "2022-08-24",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150552",
    "name": "Union Retirement Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Union Retirement Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-09-01",
    "closeData": "",
    "isin": "INF582M01IM1",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150553",
    "name": "Union Retirement Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Union Retirement Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-09-01",
    "closeData": "",
    "isin": "INF582M01IN9",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150554",
    "name": "Union Retirement Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Union Retirement Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-09-01",
    "closeData": "",
    "isin": "INF582M01IK5",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150555",
    "name": "Union Retirement Fund",
    "category": "Solution Oriented Scheme - Retirement Fund",
    "navName": "Union Retirement Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs 1000 and in multiples of Rs.1 thereafter.",
    "launchDate": "2022-09-01",
    "closeData": "",
    "isin": "INF582M01IL3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150556",
    "name": "HDFC Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC Silver ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-08-18",
    "closeData": "2022-08-26",
    "isin": "INF179KC1DI2",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150564",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Direct Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01364",
    "isinRe": "INF680P01372"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150565",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01232",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150566",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund -  Regular Fortnightly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01281",
    "isinRe": "INF680P01299"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150567",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Direct Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01380",
    "isinRe": "INF680P01398"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150558",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Regular Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01257",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150559",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Regular Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01265",
    "isinRe": "INF680P01273"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150560",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Regular Monthly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01307",
    "isinRe": "INF680P01315"
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150561",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Direct Daily IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01331",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150562",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund -  Direct Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01323",
    "isinRe": null
  },
  {
    "amc": "Shriram Asset Management Co. Ltd.",
    "code": "150563",
    "name": "Shriram Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Shriram Overnight Fund - Direct Weekly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF680P01349",
    "isinRe": "INF680P01356"
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150572",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Direct Plan IDCW Reinvestment",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LG7",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150573",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Direct Plan IDCW Payout",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LF9",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150568",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Regular Growth",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LB8",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150569",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Regular Plan IDCW Payout",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LC6",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150570",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Regular Plan IDCW Reinvestment",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LD4",
    "isinRe": null
  },
  {
    "amc": "Sundaram Asset Management Company Ltd",
    "code": "150571",
    "name": "Sundaram Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Sundaram Flexicap Fund Direct Growth",
    "minAmount": "100",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF903JA1LE2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150574",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF200KA17M7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150575",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF200KA18M5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150576",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF200KA19M3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150577",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 63 (372 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF200KA10N0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150578",
    "name": "Edelweiss Gold and Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Edelweiss Gold and Silver ETF FOF - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-24",
    "closeData": "",
    "isin": "INF754K01OY3",
    "isinRe": "INF754K01OZ0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150579",
    "name": "Edelweiss Gold and Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Edelweiss Gold and Silver ETF FOF - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-24",
    "closeData": "",
    "isin": "INF754K01OT3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150580",
    "name": "Edelweiss Gold and Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Edelweiss Gold and Silver ETF FOF - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-08-24",
    "closeData": "",
    "isin": "INF754K01OU1",
    "isinRe": "INF754K01OV9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150581",
    "name": "Edelweiss Gold and Silver ETF FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Edelweiss Gold and Silver ETF FOF - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-08-24",
    "closeData": "",
    "isin": "INF754K01OX5",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150582",
    "name": "WhiteOak Capital Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "WhiteOak Capital Mid Cap Fund Direct Plan IDCW",
    "minAmount": "500/- and in multiples of   1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "2022-08-30",
    "isin": "INF03VN01605",
    "isinRe": "INF03VN01613"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150583",
    "name": "WhiteOak Capital Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "WhiteOak Capital Mid Cap Fund Regular Plan Growth",
    "minAmount": "500/- and in multiples of   1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "2022-08-30",
    "isin": "INF03VN01563",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150584",
    "name": "WhiteOak Capital Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "WhiteOak Capital Mid Cap Fund Direct Plan Growth",
    "minAmount": "500/- and in multiples of   1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "2022-08-30",
    "isin": "INF03VN01597",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150585",
    "name": "WhiteOak Capital Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "WhiteOak Capital Mid Cap Fund Regular Plan IDCW",
    "minAmount": "500/- and in multiples of   1/- thereafter",
    "launchDate": "2022-08-16",
    "closeData": "2022-08-30",
    "isin": "INF03VN01571",
    "isinRe": "INF03VN01589"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150586",
    "name": "WhiteOak Capital Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "WhiteOak Capital Tax Saver Fund Direct Plan IDCW",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 500/-",
    "launchDate": "2022-08-16",
    "closeData": "2022-10-07",
    "isin": "INF03VN01639",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150587",
    "name": "WhiteOak Capital Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "WhiteOak Capital Tax Saver Fund Direct Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 500/-",
    "launchDate": "2022-08-16",
    "closeData": "2022-10-07",
    "isin": "INF03VN01621",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150588",
    "name": "WhiteOak Capital Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "WhiteOak Capital Tax Saver Fund Regular Plan IDCW",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 500/-",
    "launchDate": "2022-08-16",
    "closeData": "2022-10-07",
    "isin": "INF03VN01654",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150589",
    "name": "WhiteOak Capital Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "WhiteOak Capital Tax Saver Fund Regular Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 500/-",
    "launchDate": "2022-08-16",
    "closeData": "2022-10-07",
    "isin": "INF03VN01647",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150590",
    "name": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND - IDCW - DIRECT PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-19",
    "closeData": "",
    "isin": "INF194KB1DQ7",
    "isinRe": "INF194KB1DR5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150591",
    "name": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND - GROWTH - DIRECT PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-19",
    "closeData": "",
    "isin": "INF194KB1DP9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150592",
    "name": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND - GROWTH - REGULAR PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-19",
    "closeData": "",
    "isin": "INF194KB1DS3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150593",
    "name": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND - IDCW - REGULAR PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-19",
    "closeData": "",
    "isin": "INF194KB1DT1",
    "isinRe": "INF194KB1DU9"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150594",
    "name": "Mirae Asset Global Electric & Autonomous Vehicles ETFs Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mirae Asset Global Electric & Autonomous Vehicles ETFs Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF769K01IP1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150595",
    "name": "Mirae Asset Global Electric & Autonomous Vehicles ETFs Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mirae Asset Global Electric & Autonomous Vehicles ETFs Fund of Fund- Direct Plan - Growth",
    "minAmount": "Rs.5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF769K01IQ9",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150596",
    "name": "Mirae Asset Global X Artificial Intelligence & Technology ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mirae Asset Global X Artificial Intelligence & Technology ETF Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF769K01IR7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150597",
    "name": "Mirae Asset Global X Artificial Intelligence & Technology ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Mirae Asset Global X Artificial Intelligence & Technology ETF Fund of Fund - Direct Plan- Growth",
    "minAmount": "Rs.5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-08-16",
    "closeData": "",
    "isin": "INF769K01IS5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150601",
    "name": "Kotak FMP Series 296",
    "category": "Income",
    "navName": "Kotak FMP Series 296-Direct Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF174KA1JI6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150598",
    "name": "Kotak FMP Series 296",
    "category": "Income",
    "navName": "Kotak FMP Series 296-Regular Plan-IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF174KA1JH8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150599",
    "name": "Kotak FMP Series 296",
    "category": "Income",
    "navName": "Kotak FMP Series 296-Direct Plan-IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF174KA1JJ4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150600",
    "name": "Kotak FMP Series 296",
    "category": "Income",
    "navName": "Kotak FMP Series 296-Regular Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-08-17",
    "closeData": "",
    "isin": "INF174KA1JG0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150602",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-26",
    "closeData": "2022-08-29",
    "isin": "INF200KA17N5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150603",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-26",
    "closeData": "2022-08-29",
    "isin": "INF200KA18N3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150604",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-26",
    "closeData": "2022-08-29",
    "isin": "INF200KA15N9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150605",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 64 (1169 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-08-26",
    "closeData": "2022-08-29",
    "isin": "INF200KA16N7",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150610",
    "name": "Axis Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis Silver ETF",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereof.",
    "launchDate": "2022-09-02",
    "closeData": "",
    "isin": "INF846K011K1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150611",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-01",
    "closeData": "2022-09-05",
    "isin": "INF200KA19N1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150612",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-01",
    "closeData": "2022-09-05",
    "isin": "INF200KA10O8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150613",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-01",
    "closeData": "2022-09-05",
    "isin": "INF200KA12O4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150614",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 65 (367 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-01",
    "closeData": "2022-09-05",
    "isin": "INF200KA11O6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150615",
    "name": "Axis Silver Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Silver Fund of Fund- Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2022-09-02",
    "closeData": "",
    "isin": "INF846K015J4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150616",
    "name": "Axis Silver Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Silver Fund of Fund- Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2022-09-02",
    "closeData": "",
    "isin": "INF846K016J2",
    "isinRe": "INF846K017J0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150617",
    "name": "Axis Silver Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Silver Fund of Fund -Regular Plan- Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2022-09-02",
    "closeData": "",
    "isin": "INF846K018J8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150618",
    "name": "Axis Silver Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Axis Silver Fund of Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2022-09-02",
    "closeData": "",
    "isin": "INF846K019J6",
    "isinRe": "INF846K010K3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150619",
    "name": "HDFC NIFTY50 VALUE 20 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY 50 VALUE 20 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-09-09",
    "closeData": "2022-09-20",
    "isin": "INF179KC1DK8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150620",
    "name": "HDFC NIFTY100 QUALITY 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY100 QUALITY 30 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-09-09",
    "closeData": "2022-09-20",
    "isin": "INF179KC1DL6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150621",
    "name": "HDFC NIFTY GROWTH SECTORS 15 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY GROWTH SECTORS 15 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-09-09",
    "closeData": "2022-09-20",
    "isin": "INF179KC1DJ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150624",
    "name": "Kotak Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Business Cycle - Direct Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-09-07",
    "closeData": "",
    "isin": "INF174KA1JN6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150625",
    "name": "Kotak Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Business Cycle Fund-Direct Plan-IDCW option",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-09-07",
    "closeData": "",
    "isin": "INF174KA1JO4",
    "isinRe": "INF174KA1JP1"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150626",
    "name": "Kotak Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Business Cycle Fund-Regular Plan--Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-09-07",
    "closeData": "",
    "isin": "INF174KA1JK2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150622",
    "name": "Kotak Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Business Cycle Fund-Regular Plan-IDCW option",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-09-07",
    "closeData": "",
    "isin": "INF174KA1JL0",
    "isinRe": "INF174KA1JM8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150627",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIV-Series 1-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF204KC1634",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150628",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIV-Series 1-Direct Plan-Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF204KC1642",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150629",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIV-Series 1-Regular Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF204KC1618",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150630",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 1",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLIV-Series 1-Regular Plan-Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF204KC1626",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "150631",
    "name": "Samco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Samco Overnight Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF0K1H01040",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "150632",
    "name": "Samco Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "Samco Overnight Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF0K1H01032",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150633",
    "name": "BANDHAN Nifty100 Low Volatility 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY100 LOW VOLATILITY 30 INDEX FUND - GROWTH - REGULAR PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "",
    "isin": "INF194KB1DY1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150634",
    "name": "BANDHAN Nifty100 Low Volatility 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY100 LOW VOLATILITY 30 INDEX FUND - IDCW - DIRECT PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "",
    "isin": "INF194KB1DW5",
    "isinRe": "INF194KB1DX3"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150635",
    "name": "BANDHAN Nifty100 Low Volatility 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY100 LOW VOLATILITY 30 INDEX FUND - IDCW - REGULAR PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "",
    "isin": "INF194KB1DZ8",
    "isinRe": "INF194KB1EA9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150636",
    "name": "BANDHAN Nifty100 Low Volatility 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN NIFTY100 LOW VOLATILITY 30 INDEX FUND - GROWTH - DIRECT PLAN",
    "minAmount": "Rs 1000/- in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "",
    "isin": "INF194KB1DV7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150637",
    "name": "ICICI Prudential Nifty50 Equal Weight Index Fund-",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty50 Equal Weight Index Fund- Growth",
    "minAmount": "5000",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF109KC17I4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150638",
    "name": "ICICI Prudential Nifty50 Equal Weight Index Fund-",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty50 Equal Weight Index Fund- IDCW",
    "minAmount": "5000",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF109KC18I2",
    "isinRe": "INF109KC19I0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150639",
    "name": "ICICI Prudential Nifty50 Equal Weight Index Fund-",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty50 Equal Weight Index Fund- Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF109KC10J7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150640",
    "name": "ICICI Prudential Nifty50 Equal Weight Index Fund-",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty50 Equal Weight Index Fund- Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-09-14",
    "closeData": "",
    "isin": "INF109KC11J5",
    "isinRe": "INF109KC12J3"
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150641",
    "name": "Motilal Oswal Gold and Silver ETFs Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Gold and Silver ETFs Fund of Funds(Regular Plan)",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF247L01BN6",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "150642",
    "name": "Motilal Oswal Gold and Silver ETFs Fund of Funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Motilal Oswal Gold and Silver ETFs Fund of Funds(Direct Plan)",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF247L01BM8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150646",
    "name": "ICICI Prudential Nifty Auto Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Auto Index Fund - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF109KC14J9",
    "isinRe": "INF109KC15J6"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150643",
    "name": "ICICI Prudential Nifty Auto Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Auto Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF109KC16J4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150644",
    "name": "ICICI Prudential Nifty Auto Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Auto Index Fund - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF109KC17J2",
    "isinRe": "INF109KC18J0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150645",
    "name": "ICICI Prudential Nifty Auto Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Auto Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF109KC13J1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150647",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "2022-09-21",
    "isin": "INF200KA13O2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150648",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "2022-09-21",
    "isin": "INF200KA14O0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150649",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "2022-09-21",
    "isin": "INF200KA15O7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150650",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 68 (1302 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-15",
    "closeData": "2022-09-21",
    "isin": "INF200KA16O5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150651",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150652",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150653",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150654",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150655",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150656",
    "name": "HDFC FMP 1359D September 2022",
    "category": "Income",
    "navName": "HDFC FMP 1359D September - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-09-30",
    "closeData": "2022-10-10",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150657",
    "name": "HDFC NIFTY200 MOMENTUM 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY200 MOMENTUM 30 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF179KC1DM4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150658",
    "name": "HDFC NIFTY100 Low Volatility 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY100 Low Volatility 30 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF179KC1DN2",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150659",
    "name": "LIC MF Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "LIC MF Multi Cap Fund-Direct Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "2022-10-20",
    "isin": "INF767K01RK7",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150660",
    "name": "LIC MF Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "LIC MF Multi Cap Fund-Direct IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "2022-10-20",
    "isin": "INF767K01RL5",
    "isinRe": "INF767K01RM3"
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150661",
    "name": "LIC MF Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "LIC MF Multi Cap Fund-Regular Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "2022-10-20",
    "isin": "INF767K01RH3",
    "isinRe": null
  },
  {
    "amc": "LIC Mutual Fund Asset Management Limited",
    "code": "150662",
    "name": "LIC MF Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "LIC MF Multi Cap Fund-Regular IDCW",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "2022-10-20",
    "isin": "INF767K01RI1",
    "isinRe": "INF767K01RJ9"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150663",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Sep 2025 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF209KB10M1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150664",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Sep 2025 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF209KB11M9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150665",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL SEP 2025 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF209KB18L6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150667",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2025 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL Sep 2025 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-22",
    "closeData": "",
    "isin": "INF209KB19L4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150671",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-27",
    "closeData": "",
    "isin": "INF754K01PF9",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150720",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-27",
    "closeData": "",
    "isin": "INF754K01PB8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150721",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-27",
    "closeData": "",
    "isin": "INF754K01PC6",
    "isinRe": "INF754K01PD4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150722",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL April 2037 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-27",
    "closeData": "",
    "isin": "INF754K01PG7",
    "isinRe": "INF754K01PH5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150672",
    "name": "SBI Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Midcap 150 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA17O3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150673",
    "name": "SBI Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Midcap 150 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA10P5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150674",
    "name": "SBI Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Midcap 150 Index Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA18O1",
    "isinRe": "INF200KA19O9"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150675",
    "name": "SBI Nifty Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Midcap 150 Index Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA11P3",
    "isinRe": "INF200KA12P1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150676",
    "name": "SBI Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Smallcap 250 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA13P9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150677",
    "name": "SBI Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Smallcap 250 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA16P2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150678",
    "name": "SBI Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Smallcap 250 Index Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA17P0",
    "isinRe": "INF200KA18P8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150679",
    "name": "SBI Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI Nifty Smallcap 250 Index Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1",
    "launchDate": "2022-09-21",
    "closeData": "2022-09-26",
    "isin": "INF200KA14P7",
    "isinRe": "INF200KA15P4"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150680",
    "name": "SBI CRISIL IBX Gilt Index - June 2036 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - June 2036 Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-22",
    "closeData": "2022-10-03",
    "isin": "INF200KA10Q3",
    "isinRe": "INF200KA11Q1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150681",
    "name": "SBI CRISIL IBX Gilt Index - June 2036 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI  CRISIL IBX Gilt Index - June 2036 Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-22",
    "closeData": "2022-10-03",
    "isin": "INF200KA12Q9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150682",
    "name": "SBI CRISIL IBX Gilt Index - June 2036 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - June 2036 Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-22",
    "closeData": "2022-10-03",
    "isin": "INF200KA13Q7",
    "isinRe": "INF200KA14Q5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150683",
    "name": "SBI CRISIL IBX Gilt Index - June 2036 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - June 2036 Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-22",
    "closeData": "2022-10-03",
    "isin": "INF200KA19P6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150684",
    "name": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND - Growth",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF109KC19J8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150685",
    "name": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF109KC12K1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150686",
    "name": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF109KC10K5",
    "isinRe": "INF109KC11K3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150687",
    "name": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF109KC13K9",
    "isinRe": "INF109KC14K7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150689",
    "name": "Aditya Birla Sun Life Multi - Index Fund Of funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Multi- Index Fund Of Fund-Direct IDCW",
    "minAmount": "100",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF209KB16M8",
    "isinRe": "INF209KB17M6"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150690",
    "name": "Aditya Birla Sun Life Multi - Index Fund Of funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Multi-Index Fund Of Fund-Regular Growth",
    "minAmount": "100",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF209KB12M7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150691",
    "name": "Aditya Birla Sun Life Multi - Index Fund Of funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Multi- Index Fund Of Fund-Regular IDCW",
    "minAmount": "100",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF209KB13M5",
    "isinRe": "INF209KB14M3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150688",
    "name": "Aditya Birla Sun Life Multi - Index Fund Of funds",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Aditya Birla Sun Life Multi - Index Fund of Funds-Direct Growth",
    "minAmount": "100",
    "launchDate": "2022-09-26",
    "closeData": "",
    "isin": "INF209KB15M0",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150692",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - DIRECT PLAN - GROWTH",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1554",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150693",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - DIRECT PLAN - IDCW PAYOUT",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1570",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150694",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1588",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150695",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - REGULAR PLAN - IDCW PAYOUT",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1604",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150696",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - REGULAR PLAN - IDCW REINVESTMENT",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1596",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150697",
    "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - DIRECT PLAN - IDCW REINVESTMENT",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2022-09-23",
    "closeData": "",
    "isin": "INF277KA1562",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150698",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB10N9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150699",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt- April 2026 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB11N7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150700",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB19M2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150701",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-April 2026 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB18M4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150702",
    "name": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB14N1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150703",
    "name": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB12N5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150704",
    "name": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL APR 2028 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB15N8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150705",
    "name": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 50 50 Gilt Plus SDL Apr 2028 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-09-28",
    "closeData": "",
    "isin": "INF209KB13N3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150706",
    "name": "SBI CRISIL IBX Gilt Index - April 2029 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - April 2029 Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA18Q6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150707",
    "name": "SBI CRISIL IBX Gilt Index - April 2029 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - April 2029 Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA15Q2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150708",
    "name": "SBI CRISIL IBX Gilt Index - April 2029 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - April 2029 Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA19Q4",
    "isinRe": "INF200KA10R1"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150709",
    "name": "SBI CRISIL IBX Gilt Index - April 2029 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX Gilt Index - April 2029 Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA16Q0",
    "isinRe": "INF200KA17Q8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150710",
    "name": "SBI CRISIL IBX SDL Index - September 2027 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX SDL Index - September 2027 Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA12R7",
    "isinRe": "INF200KA13R5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150711",
    "name": "SBI CRISIL IBX SDL Index - September 2027 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX SDL Index - September 2027 Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA14R3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150712",
    "name": "SBI CRISIL IBX SDL Index - September 2027 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX SDL Index - September 2027 Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA11R9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150713",
    "name": "SBI CRISIL IBX SDL Index - September 2027 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI CRISIL IBX SDL Index - September 2027 Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-09-26",
    "closeData": "2022-10-03",
    "isin": "INF200KA15R0",
    "isinRe": "INF200KA16R8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150714",
    "name": "UTI Gold ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "UTI Gold ETF Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150715",
    "name": "UTI Gold ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "UTI Gold ETF Fund of Fund - Regular Plan - Growth Opton",
    "minAmount": "Rs.5000/- and in multiples of Re.1/- thereafter",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150716",
    "name": "BANDHAN TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN TRANSPORTATION AND LOGISTICS FUND - GROWTH - DIRECT PLAN",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF194KB1EB7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150717",
    "name": "BANDHAN TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN TRANSPORTATION AND LOGISTICS FUND - GROWTH - REGULAR PLAN",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF194KB1EE1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150718",
    "name": "BANDHAN TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN TRANSPORTATION AND LOGISTICS FUND - IDCW - REGULAR PLAN",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF194KB1EF8",
    "isinRe": "INF194KB1EG6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150719",
    "name": "BANDHAN TRANSPORTATION AND LOGISTICS FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN TRANSPORTATION AND LOGISTICS FUND - IDCW - DIRECT PLAN",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF194KB1EC5",
    "isinRe": "INF194KB1ED3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150723",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 - 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 40 Index Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-09-29",
    "closeData": "",
    "isin": "INF174KA1JQ9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150724",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 - 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 40 Index Fund Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-09-29",
    "closeData": "",
    "isin": "INF174KA1JR7",
    "isinRe": "INF174KA1JS5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150725",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 - 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 40 Index Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-09-29",
    "closeData": "",
    "isin": "INF174KA1JT3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150726",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 - 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60 40 Index Fund Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-09-29",
    "closeData": "",
    "isin": "INF174KA1JU1",
    "isinRe": "INF174KA1JV9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150772",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF754K01PK9",
    "isinRe": "INF754K01PL7"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150727",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF754K01PN3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150770",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF754K01PO1",
    "isinRe": "INF754K01PP8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150771",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL June 2027 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF754K01PJ1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150728",
    "name": "ICICI Prudential Nifty SDL Dec 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Dec 2028 Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC11L1",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150729",
    "name": "ICICI Prudential Nifty SDL Dec 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Dec 2028 Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC14L5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150730",
    "name": "ICICI Prudential Nifty SDL Dec 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Dec 2028 Index Fund - Direct Plan - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC15L2",
    "isinRe": "INF109KC16L0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150731",
    "name": "ICICI Prudential Nifty SDL Dec 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Dec 2028 Index Fund - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC12L9",
    "isinRe": "INF109KC13L7"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150734",
    "name": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC16K2",
    "isinRe": "INF109KC17K0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150735",
    "name": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund - Direct Plan - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC19K6",
    "isinRe": "INF109KC10L3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150732",
    "name": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC15K4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150733",
    "name": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty G-Sec Dec 2030 Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-10-04",
    "closeData": "",
    "isin": "INF109KC18K8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150736",
    "name": "HDFC Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Silver ETF Fund of Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-10-07",
    "closeData": "2022-10-21",
    "isin": "INF179KC1DV5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150737",
    "name": "HDFC Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "HDFC Silver ETF Fund of Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-10-07",
    "closeData": "2022-10-21",
    "isin": "INF179KC1DU7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150738",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - Growth - Direct Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1612",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150739",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - IDCW Reinvestment - Direct Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1620",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150740",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - IDCW Payout - Direct Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1638",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150741",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - Growth - Regular Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1646",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150742",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - IDCW Payout - Regular Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1661",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "150743",
    "name": "Tata Nifty Midcap 150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty Midcap 150 Momentum 50 Index Fund - IDCW Reinvestment - Regular Plan",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2022-10-04",
    "closeData": "2022-10-17",
    "isin": "INF277KA1653",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150744",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-13",
    "closeData": "",
    "isin": "INF194KB1EK8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150745",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND - REGULAR PLAN - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-13",
    "closeData": "",
    "isin": "INF194KB1EL6",
    "isinRe": "INF194KB1EM4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150746",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND - DIRECT PLAN - GROWTH",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-13",
    "closeData": "",
    "isin": "INF194KB1EH4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150747",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND - DIRECT PLAN - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-13",
    "closeData": "",
    "isin": "INF194KB1EI2",
    "isinRe": "INF194KB1EJ0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150748",
    "name": "Axis NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis NASDAQ 100 Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-07",
    "closeData": "",
    "isin": "INF846K016K0",
    "isinRe": "INF846K017K8"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150749",
    "name": "Axis NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis NASDAQ 100 Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-07",
    "closeData": "",
    "isin": "INF846K013K7",
    "isinRe": "INF846K014K5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150750",
    "name": "Axis NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis NASDAQ 100 Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-07",
    "closeData": "",
    "isin": "INF846K015K2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150751",
    "name": "Axis NASDAQ 100 Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis NASDAQ 100 Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-07",
    "closeData": "",
    "isin": "INF846K012K9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150752",
    "name": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF204KC1659",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150753",
    "name": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF204KC1691",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150754",
    "name": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF204KC1683",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150755",
    "name": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty AAA PSU Bond Plus SDL - Sep 2026 Maturity 50 50 Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF204KC1667",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150766",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF174KA1JZ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150767",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF174KA1KA1",
    "isinRe": "INF174KA1KB9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150768",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF174KA1JW7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150769",
    "name": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60 40 Index Fund Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-10-06",
    "closeData": "",
    "isin": "INF174KA1JX5",
    "isinRe": "INF174KA1JY3"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150775",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 Days) - Regular Plan - Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB16N6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150776",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 Days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB17N4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150773",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 days) - Direct Plan - Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB18N2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150774",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TY (76 Days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB19N0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150777",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt -Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-Apr 2029 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB10O7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150778",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt -Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-Apr-2029 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB12O3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150779",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt -Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-Apr 2029 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB11O5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150780",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt -Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt-Apr 2029 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF209KB13O1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150781",
    "name": "Kotak FMP Series 297",
    "category": "Income",
    "navName": "Kotak FMP Series 297-Regular Plan- Payout of IDCW",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF174KA1KD5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150782",
    "name": "Kotak FMP Series 297",
    "category": "Income",
    "navName": "Kotak FMP Series 297-Regular Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF174KA1KC7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150783",
    "name": "Kotak FMP Series 297",
    "category": "Income",
    "navName": "Kotak FMP Series 297-Direct Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF174KA1KE3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150784",
    "name": "Kotak FMP Series 297",
    "category": "Income",
    "navName": "Kotak FMP Series 297-Direct Plan- Payout of IDCW",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-11",
    "closeData": "",
    "isin": "INF174KA1KF0",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150785",
    "name": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01IZ0",
    "isinRe": "INF769K01JB9"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150786",
    "name": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01JA1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150787",
    "name": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01JC7",
    "isinRe": "INF769K01JE3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150788",
    "name": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 50 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01JD5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150789",
    "name": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01IW7",
    "isinRe": "INF769K01IY3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150790",
    "name": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01IU1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150791",
    "name": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01IT3",
    "isinRe": "INF769K01IV9"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "150792",
    "name": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset CRISIL IBX Gilt Index - April 2033 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs.5000/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2022-10-10",
    "closeData": "",
    "isin": "INF769K01IX5",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150797",
    "name": "WhiteOak Capital Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "WhiteOak Capital Large Cap Fund Direct Plan Growth",
    "minAmount": "500/- and in multiple of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF03VN01696",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150798",
    "name": "WhiteOak Capital Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "WhiteOak Capital Large Cap Fund Direct Plan IDCW",
    "minAmount": "500/- and in multiple of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF03VN01704",
    "isinRe": "INF03VN01712"
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150799",
    "name": "WhiteOak Capital Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "WhiteOak Capital Large Cap Fund Regular Plan Growth",
    "minAmount": "500/- and in multiple of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF03VN01662",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "150800",
    "name": "WhiteOak Capital Large Cap Fund",
    "category": "Equity Scheme - Large Cap Fund",
    "navName": "WhiteOak Capital Large Cap Fund Regular Plan IDCW",
    "minAmount": "500/- and in multiple of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF03VN01670",
    "isinRe": "INF03VN01688"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150801",
    "name": "HDFC NIFTY IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY IT ETF - Growth option",
    "minAmount": "500",
    "launchDate": "2022-10-28",
    "closeData": "2022-11-11",
    "isin": "INF179KC1DX1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150802",
    "name": "HDFC NIFTY Private Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY Private Bank ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2022-10-28",
    "closeData": "2022-11-11",
    "isin": "INF179KC1DW3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150803",
    "name": "HDFC Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Business Cycle Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF179KC1EB5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150804",
    "name": "HDFC Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Business Cycle Fund - IDCW Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF179KC1DZ6",
    "isinRe": "INF179KC1EA7"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150805",
    "name": "HDFC Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Business Cycle Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF179KC1DY9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150806",
    "name": "HDFC Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Business Cycle Fund - IDCW Option",
    "minAmount": "100",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF179KC1EC3",
    "isinRe": "INF179KC1ED1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150807",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund - Direct Plan - Growth",
    "minAmount": "Minimum of Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2022-11-01",
    "closeData": "",
    "isin": "INF754K01PV6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150848",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund - Regular Plan - Growth",
    "minAmount": "Minimum of Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2022-11-01",
    "closeData": "",
    "isin": "INF754K01PR4",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150849",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund - Regular Plan - IDCW",
    "minAmount": "Minimum of Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2022-11-01",
    "closeData": "",
    "isin": "INF754K01PS2",
    "isinRe": "INF754K01PT0"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150850",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Sep 2028 Index Fund - Direct Plan - IDCW",
    "minAmount": "Minimum of Rs. 5000/- and in multiples of Re. 1/-",
    "launchDate": "2022-11-01",
    "closeData": "",
    "isin": "INF754K01PW4",
    "isinRe": "INF754K01PX2"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150811",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-25",
    "closeData": "2022-10-31",
    "isin": "INF200KA17R6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150808",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-25",
    "closeData": "2022-10-31",
    "isin": "INF200KA18R4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150809",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-25",
    "closeData": "2022-10-31",
    "isin": "INF200KA19R2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150810",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 69 (367 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-10-25",
    "closeData": "2022-10-31",
    "isin": "INF200KA10S9",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150814",
    "name": "JM Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "JM Midcap Fund (Direct) - IDCW",
    "minAmount": "Rs. 5000 and any amount thereafter",
    "launchDate": "2022-10-31",
    "closeData": "2022-11-14",
    "isin": "INF192K01MW4",
    "isinRe": "INF192K01MX2"
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150815",
    "name": "JM Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "JM Midcap Fund (Direct) - Growth",
    "minAmount": "Rs. 5000 and any amount thereafter",
    "launchDate": "2022-10-31",
    "closeData": "2022-11-14",
    "isin": "INF192K01MV6",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150812",
    "name": "JM Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "JM Midcap Fund (Regular) - Growth",
    "minAmount": "Rs. 5000 and any amount thereafter",
    "launchDate": "2022-10-31",
    "closeData": "2022-11-14",
    "isin": "INF192K01MS2",
    "isinRe": null
  },
  {
    "amc": "JM Financial Asset Management Limited",
    "code": "150813",
    "name": "JM Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "JM Midcap Fund (Regular) - IDCW",
    "minAmount": "Rs. 5000 and any amount thereafter",
    "launchDate": "2022-10-31",
    "closeData": "2022-11-14",
    "isin": "INF192K01MT0",
    "isinRe": "INF192K01MU8"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150817",
    "name": "Canara Robeco Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Canara Robeco Mid Cap Fund- Direct Plan- Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF760K01KI1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150818",
    "name": "Canara Robeco Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Canara Robeco Mid Cap Fund- Direct Plan- IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF760K01KK7",
    "isinRe": "INF760K01KJ9"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150819",
    "name": "Canara Robeco Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Canara Robeco Mid Cap Fund- Regular Plan- IDCW",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF760K01KN1",
    "isinRe": "INF760K01KM3"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "150816",
    "name": "Canara Robeco Mid Cap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "Canara Robeco Mid Cap Fund- Regular Plan- Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-11-11",
    "closeData": "2022-11-25",
    "isin": "INF760K01KL5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150820",
    "name": "Kotak All Weather Debt FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak All Weather Debt FOF-Regular Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF174KA1KG8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150821",
    "name": "Kotak All Weather Debt FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak All Weather Debt FOF-Regular Plan-IDCW",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF174KA1KH6",
    "isinRe": "INF174KA1KI4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150822",
    "name": "Kotak All Weather Debt FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak All Weather Debt FOF-Direct Plan-Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF174KA1KJ2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150823",
    "name": "Kotak All Weather Debt FOF",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak All Weather Debt FOF-Direct Plan-IDCW",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF174KA1KK0",
    "isinRe": "INF174KA1KL8"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150824",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-07",
    "closeData": "",
    "isin": "INF204KC1741",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150825",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-07",
    "closeData": "",
    "isin": "INF204KC1717",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150826",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-07",
    "closeData": "",
    "isin": "INF204KC1758",
    "isinRe": "INF204KC1766"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150827",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec - Jun 2028 Maturity 70 30 Index Fund-Regular Plan IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-07",
    "closeData": "",
    "isin": "INF204KC1725",
    "isinRe": "INF204KC1733"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150837",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Direct Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWJ2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150831",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 days) - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWD5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150832",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Regular Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWG8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150833",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWK0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150834",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWH6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150835",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWI4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150836",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Direct Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWL8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150828",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWF0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150829",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Regular Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWE3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150830",
    "name": "UTI Fixed Term Income Fund - Series XXXV-I (1260Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-I (1260 Days) - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-03",
    "closeData": "",
    "isin": "INF789F1AWC7",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "150838",
    "name": "Samco ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Samco ELSS Tax Saver Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2022-11-15",
    "closeData": "",
    "isin": "INF0K1H01065",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "150839",
    "name": "Samco ELSS Tax Saver Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "Samco ELSS Tax Saver Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 500 and in multiples of Rs. 500 thereafter",
    "launchDate": "2022-11-15",
    "closeData": "",
    "isin": "INF0K1H01057",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150840",
    "name": "DSP FMP Series - 267 - 1246 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 267 - 1246 Days - Direct - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF740KA1RK0",
    "isinRe": "INF740KA1RJ2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150841",
    "name": "DSP FMP Series - 267 - 1246 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 267 - 1246 Days - Regular - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF740KA1RH6",
    "isinRe": "INF740KA1RG8"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150842",
    "name": "DSP FMP Series - 267 - 1246 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 267 - 1246 Days - Direct - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF740KA1RI4",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150843",
    "name": "DSP FMP Series - 267 - 1246 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 267 - 1246 Days - Regular - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-10-28",
    "closeData": "",
    "isin": "INF740KA1RF0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150844",
    "name": "HDFC Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Dec 2026 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-11-01",
    "closeData": "2022-11-09",
    "isin": "INF179KC1EG4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150845",
    "name": "HDFC Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Dec 2026 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-11-01",
    "closeData": "2022-11-09",
    "isin": "INF179KC1EE9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150846",
    "name": "HDFC Nifty G-Sec July 2031 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec July 2031 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-11-01",
    "closeData": "2022-11-09",
    "isin": "INF179KC1EI0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "150847",
    "name": "HDFC Nifty G-Sec July 2031 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec July 2031 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-11-01",
    "closeData": "2022-11-09",
    "isin": "INF179KC1EH2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150851",
    "name": "Axis Nifty SDL September 2026 Debt Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty SDL September 2026 Debt Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2022-11-04",
    "closeData": "2022-11-17",
    "isin": "INF846K011L9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150852",
    "name": "Axis Nifty SDL September 2026 Debt Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty SDL September 2026 Debt Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2022-11-04",
    "closeData": "2022-11-17",
    "isin": "INF846K019K4",
    "isinRe": "INF846K010L1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150853",
    "name": "Axis Nifty SDL September 2026 Debt Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty SDL September 2026 Debt Index Fund - Regular Plan -  IDCW",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2022-11-04",
    "closeData": "2022-11-17",
    "isin": "INF846K012L7",
    "isinRe": "INF846K013L5"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "150854",
    "name": "Axis Nifty SDL September 2026 Debt Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis Nifty SDL September 2026 Debt Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 in multiples of Rs. 1 thereafter",
    "launchDate": "2022-11-04",
    "closeData": "2022-11-17",
    "isin": "INF846K018K6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150855",
    "name": "Union Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Union Multicap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF582M01IS8",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150856",
    "name": "Union Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Union Multicap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF582M01IT6",
    "isinRe": "INF582M01IU4"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150857",
    "name": "Union Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Union Multicap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF582M01IP4",
    "isinRe": "INF582M01IQ2"
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "150858",
    "name": "Union Multicap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Union Multicap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs.1000 and in multiples of Rs.1 thereafter",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF582M01IO7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150859",
    "name": "Kotak FMP Series 298",
    "category": "Income",
    "navName": "Kotak FMP Series 298 - Regular Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-04",
    "closeData": "",
    "isin": "INF174KA1KM6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150860",
    "name": "Kotak FMP Series 298",
    "category": "Income",
    "navName": "Kotak FMP Series 298 - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-04",
    "closeData": "",
    "isin": "INF174KA1KP9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150861",
    "name": "Kotak FMP Series 298",
    "category": "Income",
    "navName": "Kotak FMP Series 298 - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-04",
    "closeData": "",
    "isin": "INF174KA1KN4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150862",
    "name": "Kotak FMP Series 298",
    "category": "Income",
    "navName": "Kotak FMP Series 298 - Direct Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-04",
    "closeData": "",
    "isin": "INF174KA1KO2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150863",
    "name": "Baroda BNP Paribas Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Baroda BNP Paribas Multi Asset Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF251K01RC9",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150864",
    "name": "Baroda BNP Paribas Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Baroda BNP Paribas Multi Asset Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF251K01RD7",
    "isinRe": "INF251K01RE5"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150865",
    "name": "Baroda BNP Paribas Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Baroda BNP Paribas Multi Asset Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF251K01RF2",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "150866",
    "name": "Baroda BNP Paribas Multi Asset Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Baroda BNP Paribas Multi Asset Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF251K01RG0",
    "isinRe": "INF251K01RH8"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150867",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND - DIRECT IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EU7",
    "isinRe": "INF194KB1EV5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150868",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND - DIRECT GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1ET9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150869",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND - REGULAR GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EW3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150870",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - NOV 2026 INDEX FUND - REGULAR IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EX1",
    "isinRe": "INF194KB1EY9"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150871",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT APRIL 2032 INDEX FUND - DIRECT GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EN2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150872",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT APRIL 2032 INDEX FUND - DIRECT IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EO0",
    "isinRe": "INF194KB1EP7"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150873",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT APRIL 2032 INDEX FUND - REGULAR GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1EQ5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150874",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT APRIL 2032 INDEX FUND - REGULAR IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-14",
    "closeData": "",
    "isin": "INF194KB1ER3",
    "isinRe": "INF194KB1ES1"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150875",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days) - Direct Plan - Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-11-10",
    "closeData": "",
    "isin": "INF209KB16O4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150876",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days)- Direct Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-11-10",
    "closeData": "",
    "isin": "INF209KB17O2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150877",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days) - Regular Plan -Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-11-10",
    "closeData": "",
    "isin": "INF209KB14O9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150878",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series TZ (90 days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs. 10",
    "launchDate": "2022-11-10",
    "closeData": "",
    "isin": "INF209KB15O6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150879",
    "name": "ICICI Prudential Nifty Financial Services Ex-Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Financial Services Ex-BaNk ETF",
    "minAmount": "1000",
    "launchDate": "2022-11-16",
    "closeData": "",
    "isin": "INF109KC17L8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150880",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-16",
    "isin": "INF200KA14S1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150881",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-16",
    "isin": "INF200KA11S7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150882",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days) - Regular Plan - Income Distribution cun Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-16",
    "isin": "INF200KA12S5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150883",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 71 (364 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-16",
    "isin": "INF200KA13S3",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150884",
    "name": "DSP FMP Series - 268 - 1281 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 268 - 1281 Days - Regular - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-11-11",
    "closeData": "",
    "isin": "INF740KA1RL8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150885",
    "name": "DSP FMP Series - 268 - 1281 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 268 - 1281 Days - Direct - Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-11-11",
    "closeData": "",
    "isin": "INF740KA1RO2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150886",
    "name": "DSP FMP Series - 268 - 1281 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 268 - 1281 Days - Direct - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-11-11",
    "closeData": "",
    "isin": "INF740KA1RQ7",
    "isinRe": "INF740KA1RP9"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "150887",
    "name": "DSP FMP Series - 268 - 1281 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 268 - 1281 Days - Regular - IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-11-11",
    "closeData": "",
    "isin": "INF740KA1RN4",
    "isinRe": "INF740KA1RM6"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150888",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - SEPTEMBER 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - SEP27 INDEX FUND - DIRECT GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF194KB1EZ6",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150889",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - SEPTEMBER 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT SEP27 INDEX FUND - DIRECT IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF194KB1FA6",
    "isinRe": "INF194KB1FB4"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150890",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - SEPTEMBER 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT SEP27 INDEX FUND - REGULAR GROWTH",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF194KB1FC2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "150891",
    "name": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT - SEPTEMBER 2027 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX 90 10 SDL PLUS GILT SEP27 INDEX FUND - REGULAR IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF194KB1FD0",
    "isinRe": "INF194KB1FE8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150892",
    "name": "Edelweiss Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Smallcap 250 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- therafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QT8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150893",
    "name": "Edelweiss Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Smallcap 250 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- therafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QQ4",
    "isinRe": "INF754K01QR2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150894",
    "name": "Edelweiss Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Smallcap 250 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- therafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QP6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150895",
    "name": "Edelweiss Nifty Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Smallcap 250 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- therafter",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QU6",
    "isinRe": "INF754K01QV4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150896",
    "name": "Edelweiss Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Next 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QM3",
    "isinRe": "INF754K01QN1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150897",
    "name": "Edelweiss Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Next 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QH3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150898",
    "name": "Edelweiss Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Next 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QI1",
    "isinRe": "INF754K01QJ9"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150899",
    "name": "Edelweiss Nifty Next 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Next 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QL5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150900",
    "name": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01PZ7",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150901",
    "name": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QA8",
    "isinRe": "INF754K01QB6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150902",
    "name": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QD2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150903",
    "name": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss Nifty Midcap150 Momentum 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter.",
    "launchDate": "2022-11-10",
    "closeData": "2022-11-24",
    "isin": "INF754K01QE0",
    "isinRe": "INF754K01QF7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150904",
    "name": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF204KC1857",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150905",
    "name": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF204KC1865",
    "isinRe": "INF204KC1873"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150906",
    "name": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF204KC1881",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150907",
    "name": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Sep 2027 Maturity Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF204KC1899",
    "isinRe": "INF204KC1907"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150908",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLIV - Series 2-Direct Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-18",
    "closeData": "",
    "isin": "INF204KC1790",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150909",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLIV - Series 2-Direct Plan-Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-18",
    "closeData": "",
    "isin": "INF204KC1808",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150910",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund XLIV - Series 2-Regular Plan-Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-18",
    "closeData": "",
    "isin": "INF204KC1782",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150911",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 2-Regular Plan-Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-18",
    "closeData": "",
    "isin": "INF204KC1774",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "150914",
    "name": "Mahindra Manulife Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Mahindra Manulife Small Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF174V01BI1",
    "isinRe": "INF174V01BJ9"
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "150915",
    "name": "Mahindra Manulife Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Mahindra Manulife Small Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF174V01BK7",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "150912",
    "name": "Mahindra Manulife Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Mahindra Manulife Small Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF174V01BH3",
    "isinRe": null
  },
  {
    "amc": "Mahindra Manulife Investment Management Pvt Ltd",
    "code": "150913",
    "name": "Mahindra Manulife Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "Mahindra Manulife Small Cap Fund - Direct Plan - IDCW",
    "minAmount": "Rs 1000/- and in multiples of Re 1/- thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF174V01BL5",
    "isinRe": "INF174V01BM3"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150916",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-17",
    "closeData": "2022-11-21",
    "isin": "INF200KA18S2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150917",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days) Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-17",
    "closeData": "2022-11-21",
    "isin": "INF200KA15S8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150918",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days) Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-17",
    "closeData": "2022-11-21",
    "isin": "INF200KA16S6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150919",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 72 (1239 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-17",
    "closeData": "2022-11-21",
    "isin": "INF200KA17S4",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150920",
    "name": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF204KC1AC0",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150921",
    "name": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Jun 2036 Maturity Index fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF204KC1AD8",
    "isinRe": "INF204KC1AE6"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150922",
    "name": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF204KC1AA4",
    "isinRe": "INF204KC1AB2"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150923",
    "name": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Jun 2036 Maturity Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF204KC1998",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150924",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 3 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150925",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 3 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150926",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 3 - Direct Plan - Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150927",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 3 - Regular Plan - Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150931",
    "name": "ICICI Prudential Nifty Pharma Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Pharma Index Fund - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF109KC19L4",
    "isinRe": "INF109KC10M1"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150928",
    "name": "ICICI Prudential Nifty Pharma Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Pharma Index Fund - Direct Plan - IDCW",
    "minAmount": "1000",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF109KC12M7",
    "isinRe": "INF109KC13M5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150929",
    "name": "ICICI Prudential Nifty Pharma Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Pharma Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF109KC18L6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150930",
    "name": "ICICI Prudential Nifty Pharma Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty Pharma Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF109KC11M9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150932",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 4-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF204KC1931",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150933",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 4-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF204KC1949",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150934",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 4-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF204KC1923",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150935",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 4-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": "INF204KC1915",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150936",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+ AAA PSU APR 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU APR 2026 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2022-11-24",
    "closeData": "",
    "isin": "INF209KB18O0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150937",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+ AAA PSU APR 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+AAA PSU APR 2026 Index Fund-Regular IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-11-24",
    "closeData": "",
    "isin": "INF209KB19O8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150938",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+ AAA PSU APR 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL + AAA PSU APR 2026 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2022-11-24",
    "closeData": "",
    "isin": "INF209KB10P4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "150939",
    "name": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+ AAA PSU APR 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX 60 40 SDL+ AAA PSU APR 2026 Index Fund-Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-11-24",
    "closeData": "",
    "isin": "INF209KB11P2",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150943",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 5 - Direct Plan - Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150940",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 5 - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150941",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 5 - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "150942",
    "name": "Nippon India Fixed Horizon Fund - XLIV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund - XLIV - Series 5 - Regular Plan - Payout of IDCW",
    "minAmount": "Rs 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2022-11-25",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150944",
    "name": "Kotak FMP Series 299",
    "category": "Income",
    "navName": "Kotak FMP Series 299 - Regular Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-23",
    "closeData": "",
    "isin": "INF174KA1KQ7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150945",
    "name": "Kotak FMP Series 299",
    "category": "Income",
    "navName": "Kotak FMP Series 299 - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-23",
    "closeData": "",
    "isin": "INF174KA1KR5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150946",
    "name": "Kotak FMP Series 299",
    "category": "Income",
    "navName": "Kotak FMP Series 299 - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-23",
    "closeData": "",
    "isin": "INF174KA1KT1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150947",
    "name": "Kotak FMP Series 299",
    "category": "Income",
    "navName": "Kotak FMP Series 299 - Direct Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-11-23",
    "closeData": "",
    "isin": "INF174KA1KS3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "150948",
    "name": "Kotak Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Silver ETF",
    "minAmount": "5000",
    "launchDate": "2022-11-21",
    "closeData": "",
    "isin": "INF174KA1KU9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150949",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC14M3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150950",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC17M6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150951",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC15M0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150952",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC18M4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150953",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC19M2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150954",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1226 Days Plan F - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-28",
    "closeData": "",
    "isin": "INF109KC16M8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150957",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF109KC11N7",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150958",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan -- Series 88 - 91 Days Plan G - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF109KC10N9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150955",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF109KC12N5",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150956",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 91 Days Plan G - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-29",
    "closeData": "",
    "isin": "INF109KC13N3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150959",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150960",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H - Growth",
    "minAmount": "5000",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150961",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150962",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 125 Days Plan H - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150968",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Regular Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWQ7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150969",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Regular Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWO2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150970",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Direct Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWV7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150971",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWP9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150972",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWM6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150963",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWU9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150964",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWR5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150965",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWS3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150966",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Direct Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWT1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "150967",
    "name": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-II (1223 Days) - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and in multiple of Rs 10",
    "launchDate": "2022-11-30",
    "closeData": "",
    "isin": "INF789F1AWN4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150974",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150975",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150976",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150977",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150978",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150973",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1233 Days Plan I - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150979",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150980",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150981",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "150982",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 182 Days Plan J - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150983",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 73 (1226 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 73 (1226 Days) - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-28",
    "closeData": "2022-12-07",
    "isin": "INF200KA10T7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150984",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 73 (1226 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 73 (1226 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-28",
    "closeData": "2022-12-07",
    "isin": "INF200KA11T5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150985",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 73 (1226 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 73 (1226 Days) - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-28",
    "closeData": "2022-12-07",
    "isin": "INF200KA12T3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "150986",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 73 (1226 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 73 (1226 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-11-28",
    "closeData": "2022-12-07",
    "isin": "INF200KA19S0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150987",
    "name": "BHARAT Bond ETF - April 2033",
    "category": "Other Scheme - Other  ETFs",
    "navName": "BHARAT Bond ETF - April 2033",
    "minAmount": "Rs. 1001",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF754K01QX0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150988",
    "name": "BHARAT Bond ETF FOF - April 2033",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2033 - Regular Plan - IDCW",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF754K01QZ5",
    "isinRe": "INF754K01RA6"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150989",
    "name": "BHARAT Bond ETF FOF - April 2033",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2033 - Direct Plan - Growth",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF754K01RC2",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150990",
    "name": "BHARAT Bond ETF FOF - April 2033",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2033 - Direct Plan - IDCW",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF754K01RD0",
    "isinRe": "INF754K01RE8"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "150991",
    "name": "BHARAT Bond ETF FOF - April 2033",
    "category": "Other Scheme - FoF Domestic",
    "navName": "BHARAT Bond ETF FOF - April 2033 - Regular Plan - Growth",
    "minAmount": "Rs. 1000/-",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF754K01QY8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150992",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Regular Growth",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01AH0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150993",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Regular Quarterly IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01AF4",
    "isinRe": "INF917K01AG2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151001",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Regular Annual IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01WB7",
    "isinRe": "INF917K01WA9"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150994",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Regular Semi Annual IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01AI8",
    "isinRe": "INF917K01AJ6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150995",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund -Regular Plan - Bonus",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01AK4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150996",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Direct Growth",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01HN3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150997",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Direct Semi Annual IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01HR4",
    "isinRe": "INF917K01HQ6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "150998",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Direct Quarterly IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01HP8",
    "isinRe": "INF917K01HO1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151000",
    "name": "HSBC Corporate Bond Fund",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "HSBC Corporate Bond Fund - Direct Annual IDCW",
    "minAmount": "Rs. 10000 and in multiples of Re. 1 thereafter",
    "launchDate": "1997-02-10",
    "closeData": "",
    "isin": "INF917K01VZ8",
    "isinRe": "INF917K01VY1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151012",
    "name": "HSBC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HSBC Gilt Fund - Regular Quarterly IDCW",
    "minAmount": "Rs.10000 and in multipes of Re.1 thereafter",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF917K01BN6",
    "isinRe": "INF917K01BO4"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151013",
    "name": "HSBC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HSBC Gilt Fund - Regular Growth",
    "minAmount": "Rs.10000 and in multipes of Re.1 thereafter",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF917K01BP1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151014",
    "name": "HSBC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HSBC Gilt Fund - Direct Growth",
    "minAmount": "Rs.10000 and in multipes of Re.1 thereafter",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF917K01FI7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151015",
    "name": "HSBC Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "HSBC Gilt Fund - Direct Quarterly IDCW",
    "minAmount": "Rs.10000 and in multipes of Re.1 thereafter",
    "launchDate": "2000-03-28",
    "closeData": "",
    "isin": "INF917K01FK3",
    "isinRe": "INF917K01FJ5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151033",
    "name": "HSBC Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HSBC Midcap Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2004-06-24",
    "closeData": "",
    "isin": "INF917K01239",
    "isinRe": "INF917K01247"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151034",
    "name": "HSBC Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HSBC Midcap Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2004-06-24",
    "closeData": "",
    "isin": "INF917K01254",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151035",
    "name": "HSBC Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HSBC Midcap Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2004-06-24",
    "closeData": "",
    "isin": "INF917K01E78",
    "isinRe": "INF917K01FX6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151036",
    "name": "HSBC Midcap Fund",
    "category": "Equity Scheme - Mid Cap Fund",
    "navName": "HSBC Midcap Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2004-06-24",
    "closeData": "",
    "isin": "INF917K01FZ1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151037",
    "name": "HSBC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Infrastructure Fund - Regular Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re 1 thereafter",
    "launchDate": "2007-08-07",
    "closeData": "2007-09-11",
    "isin": "INF917K01536",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151038",
    "name": "HSBC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Infrastructure Fund - Regular IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re 1 thereafter",
    "launchDate": "2007-08-07",
    "closeData": "2007-09-11",
    "isin": "INF917K01544",
    "isinRe": "INF917K01551"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151039",
    "name": "HSBC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Infrastructure Fund - Direct IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re 1 thereafter",
    "launchDate": "2007-08-07",
    "closeData": "2007-09-11",
    "isin": "INF917K01E52",
    "isinRe": "INF917K01FU2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151040",
    "name": "HSBC Infrastructure Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Infrastructure Fund - Direct Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re 1 thereafter",
    "launchDate": "2007-08-07",
    "closeData": "2007-09-11",
    "isin": "INF917K01FW8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151041",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund- Regular Plan - Bonus",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151042",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Regular IDCW",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01114",
    "isinRe": "INF917K01122"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151043",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Regular Growth",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01130",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151044",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Direct IDCW",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01E29",
    "isinRe": "INF917K01UI6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151045",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Direct Growth",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01UH8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151046",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Regular Annual IDCW",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01UN6",
    "isinRe": "INF917K01UM8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151047",
    "name": "HSBC Credit Risk Fund",
    "category": "Debt Scheme - Credit Risk Fund",
    "navName": "HSBC Credit Risk Fund - Direct Annual IDCW",
    "minAmount": "Rs.10000 and in multiples of Rs 1 thereafter",
    "launchDate": "2009-09-29",
    "closeData": "",
    "isin": "INF917K01UL0",
    "isinRe": "INF917K01UK2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151055",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Direct Daily IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01FD8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151049",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Regular Daily IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01AY5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151050",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Regular Weekly IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01AZ2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151051",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Regular Monthly IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01BB1",
    "isinRe": "INF917K01BA3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151052",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Direct Monthly IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01E86",
    "isinRe": "INF917K01FF3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151053",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Direct Weekly IDCW",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01FH9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151054",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Direct Growth",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01FE6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151048",
    "name": "HSBC Money Market Fund",
    "category": "Debt Scheme - Money Market Fund",
    "navName": "HSBC Money Market Fund - Regular Growth",
    "minAmount": "Rs.10000/- and in multiples of Re 1/- thereafter.",
    "launchDate": "2010-10-27",
    "closeData": "",
    "isin": "INF917K01BC9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151056",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Regular Monthly IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01965",
    "isinRe": "INF917K01973"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151057",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Regular Quarterly IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01981",
    "isinRe": "INF917K01999"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151058",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Regular Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01AA5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151059",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Direct Quarterly IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01GD6",
    "isinRe": "INF917K01GC8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151060",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Direct Growth",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01GE4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151061",
    "name": "HSBC Equity Savings Fund",
    "category": "Hybrid Scheme - Equity Savings",
    "navName": "HSBC Equity Savings Fund - Direct Monthly IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2011-09-27",
    "closeData": "2011-10-11",
    "isin": "INF917K01E37",
    "isinRe": "INF917K01GA2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151067",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Direct Growth",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01IQ4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151068",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Direct Plan -  Monthly IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01IP6",
    "isinRe": "INF917K01IO9"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151069",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Direct Quarterly IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01IS0",
    "isinRe": "INF917K01IR2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151070",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Direct Annual IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01VR5",
    "isinRe": "INF917K01VQ7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151071",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Regular Annual IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01VT1",
    "isinRe": "INF917K01VS3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151062",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Bonus",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01CM6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151063",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund -  Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01CH6",
    "isinRe": "INF917K01CI4"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151064",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Regular Quarterly IDCW",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01CJ2",
    "isinRe": "INF917K01CK0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151065",
    "name": "HSBC Short Duration Fund",
    "category": "Debt Scheme - Short Duration Fund",
    "navName": "HSBC Short Duration Fund - Regular Growth",
    "minAmount": "Rs. 10000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2011-12-07",
    "closeData": "2011-12-21",
    "isin": "INF917K01CL8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151076",
    "name": "HSBC ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC ELSS Fund - Regular Growth",
    "minAmount": "Rs. 500 and in multiples of Rs.500 thereafter",
    "launchDate": "2006-01-05",
    "closeData": "",
    "isin": "INF677K01064",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151077",
    "name": "HSBC ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC ELSS Fund - Regular IDCW Payout",
    "minAmount": "Rs. 500 and in multiples of Rs.500 thereafter",
    "launchDate": "2006-01-05",
    "closeData": "",
    "isin": "INF677K01072",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151078",
    "name": "HSBC ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC ELSS Fund - Direct Growth",
    "minAmount": "Rs. 500 and in multiples of Rs.500 thereafter",
    "launchDate": "2006-01-05",
    "closeData": "",
    "isin": "INF917K01GP0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151079",
    "name": "HSBC ELSS Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "HSBC ELSS Fund - Direct IDCW Payout",
    "minAmount": "Rs. 500 and in multiples of Rs.500 thereafter",
    "launchDate": "2006-01-05",
    "closeData": "",
    "isin": "INF917K01GO3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151084",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Regular Growth",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF677K01916",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151085",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Regular Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF677K01924",
    "isinRe": "INF677K01932"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151086",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Direct Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF917K01GZ9",
    "isinRe": "INF917K01GY2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151087",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Direct Growth",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF917K01HA0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151088",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Direct Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF917K01VN4",
    "isinRe": "INF917K01VM6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151089",
    "name": "HSBC Dynamic Bond Fund",
    "category": "Debt Scheme - Dynamic Bond",
    "navName": "HSBC Dynamic Bond Fund - Regular Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2006-08-17",
    "closeData": "2006-08-24",
    "isin": "INF917K01VP9",
    "isinRe": "INF917K01VO2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151108",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Direct Daily IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF917K01HL7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151109",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Direct Weekly IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF917K01HK9",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151102",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Regular Daily IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF677K01AF4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151103",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Regular Weekly IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF917K01HM5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151104",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Regular Growth",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF677K01AE7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151105",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Regular Monthly IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF677K01AI8",
    "isinRe": "INF677K01AJ6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151106",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Direct Monthly IDCW",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF917K01HJ1",
    "isinRe": "INF917K01HI3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151107",
    "name": "HSBC Banking and PSU Debt Fund",
    "category": "Debt Scheme - Banking and PSU Fund",
    "navName": "HSBC Banking and PSU Debt Fund - Direct Growth",
    "minAmount": "Rs.10000 Daily Dividend option Rs. 100000",
    "launchDate": "2007-09-19",
    "closeData": "2007-09-19",
    "isin": "INF917K01HH5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151110",
    "name": "HSBC Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HSBC Value Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2009-11-16",
    "closeData": "2009-12-15",
    "isin": "INF677K01023",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151111",
    "name": "HSBC Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HSBC Value Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2009-11-16",
    "closeData": "2009-12-15",
    "isin": "INF677K01213",
    "isinRe": "INF677K01015"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151112",
    "name": "HSBC Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HSBC Value Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2009-11-16",
    "closeData": "2009-12-15",
    "isin": "INF917K01HC6",
    "isinRe": "INF917K01HB8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151113",
    "name": "HSBC Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "HSBC Value Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2009-11-16",
    "closeData": "2009-12-15",
    "isin": "INF917K01HD4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151114",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Regular Growth",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF677K01452",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151115",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Regular Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF677K01460",
    "isinRe": "INF677K01478"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151116",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Direct Monthly IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF917K01E60",
    "isinRe": "INF917K01GK1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151117",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Direct Growth",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF917K01GM7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151118",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Direct Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF917K01VV7",
    "isinRe": "INF917K01VU9"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151119",
    "name": "HSBC Low Duration Fund ",
    "category": "Debt Scheme - Low Duration Fund",
    "navName": "HSBC Low Duration Fund - Regular Annual IDCW",
    "minAmount": "10000",
    "launchDate": "2010-11-19",
    "closeData": "2010-11-30",
    "isin": "INF917K01VX3",
    "isinRe": "INF917K01VW5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151120",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01LB0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151121",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01KZ1",
    "isinRe": "INF917K01LA2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151122",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01LE4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151123",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01E45",
    "isinRe": "INF917K01LD6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151124",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Regular Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01TW9",
    "isinRe": "INF917K01TV1"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151125",
    "name": "HSBC Aggressive Hybrid Fund ",
    "category": "Hybrid Scheme - Aggressive Hybrid Fund",
    "navName": "HSBC Aggressive Hybrid Fund - Direct Annual IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01TY5",
    "isinRe": "INF917K01TX7"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151129",
    "name": "HSBC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HSBC Balanced Advantage Fund - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01IN1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151126",
    "name": "HSBC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HSBC Balanced Advantage Fund - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01KW8",
    "isinRe": "INF917K01KX6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151127",
    "name": "HSBC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HSBC Balanced Advantage Fund - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01KY4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151128",
    "name": "HSBC Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "HSBC Balanced Advantage Fund - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2011-01-17",
    "closeData": "2011-01-31",
    "isin": "INF917K01IM3",
    "isinRe": "INF917K01IL5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151130",
    "name": "HSBC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HSBC Small Cap Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF917K01QA1",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151131",
    "name": "HSBC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HSBC Small Cap Fund - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF917K01QB9",
    "isinRe": "INF917K01XQ3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151132",
    "name": "HSBC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HSBC Small Cap Fund - Direct IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF917K01PZ0",
    "isinRe": "INF917K01XP5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151133",
    "name": "HSBC Small Cap Fund",
    "category": "Equity Scheme - Small Cap Fund",
    "navName": "HSBC Small Cap Fund - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-04-22",
    "closeData": "",
    "isin": "INF917K01QC7",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151135",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Direct Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QT1",
    "isinRe": "INF917K01QS3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151136",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Direct Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QV7",
    "isinRe": "INF917K01QU9"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151137",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Regular Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QP9",
    "isinRe": "INF917K01QO2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151138",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QR5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151140",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Regular Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QN4",
    "isinRe": "INF917K01QM6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151134",
    "name": "HSBC Arbitrage Fund",
    "category": "Hybrid Scheme - Arbitrage Fund",
    "navName": "HSBC Arbitrage Fund - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter",
    "launchDate": "2014-06-26",
    "closeData": "",
    "isin": "INF917K01QL8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151142",
    "name": "HSBC Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Business Cycles Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-07-30",
    "closeData": "",
    "isin": "INF917K01RI2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151143",
    "name": "HSBC Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Business Cycles Fund - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-07-30",
    "closeData": "",
    "isin": "INF917K01RH4",
    "isinRe": "INF917K01RG6"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151144",
    "name": "HSBC Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Business Cycles Fund - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-07-30",
    "closeData": "",
    "isin": "INF917K01RF8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151145",
    "name": "HSBC Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HSBC Business Cycles Fund - Direct IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2014-07-30",
    "closeData": "",
    "isin": "INF917K01RK8",
    "isinRe": "INF917K01RJ0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151146",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Direct Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01TK4",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151147",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Regular IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01TJ6",
    "isinRe": "INF917K01TI8"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151148",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Direct IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01TM0",
    "isinRe": "INF917K01TL2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151149",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Regular Growth",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01TH0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151150",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Direct Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01WF8",
    "isinRe": "INF917K01WC5"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151151",
    "name": "HSBC Medium Duration Fund",
    "category": "Debt Scheme - Medium Duration Fund",
    "navName": "HSBC Medium Duration Fund - Regular Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re.1 thereafter",
    "launchDate": "2015-01-22",
    "closeData": "",
    "isin": "INF917K01WE1",
    "isinRe": "INF917K01WD3"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151156",
    "name": "HSBC NIFTY 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY 50 INDEX FUND - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01C96",
    "isinRe": "INF917K01D04"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151157",
    "name": "HSBC NIFTY 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY 50 INDEX FUND - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D12",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151158",
    "name": "HSBC NIFTY 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY 50 INDEX FUND - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D38",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151159",
    "name": "HSBC NIFTY 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY 50 INDEX FUND - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D20",
    "isinRe": "INF917K01D46"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151160",
    "name": "HSBC NIFTY NEXT 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY NEXT 50 INDEX FUND - Direct Growth",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D79",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151161",
    "name": "HSBC NIFTY NEXT 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY NEXT 50 INDEX FUND - Regular IDCW",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D87",
    "isinRe": "INF917K01E03"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151162",
    "name": "HSBC NIFTY NEXT 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY NEXT 50 INDEX FUND - Regular Growth",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D95",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151163",
    "name": "HSBC NIFTY NEXT 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC NIFTY NEXT 50 INDEX FUND - Direct IDCW",
    "minAmount": "5000",
    "launchDate": "2020-03-24",
    "closeData": "",
    "isin": "INF917K01D53",
    "isinRe": "INF917K01D61"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151164",
    "name": "360 ONE ELSS Nifty 50 Tax Saver Index Fund(Formerly known as IIFL ELSS Nifty 50 Tax Saver Index Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "360 ONE ELSS Nifty 50 Tax Saver Index Fund - Regular Plan - Growth",
    "minAmount": "500",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF579M01AL6",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151165",
    "name": "360 ONE ELSS Nifty 50 Tax Saver Index Fund(Formerly known as IIFL ELSS Nifty 50 Tax Saver Index Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "360 ONE ELSS Nifty 50 Tax Saver Index Fund - Direct Plan - Growth",
    "minAmount": "500",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF579M01AN2",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151166",
    "name": "360 ONE ELSS Nifty 50 Tax Saver Index Fund(Formerly known as IIFL ELSS Nifty 50 Tax Saver Index Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "360 ONE ELSS Nifty 50 Tax Saver Index Fund - Direct Plan - IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF579M01AO0",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151167",
    "name": "360 ONE ELSS Nifty 50 Tax Saver Index Fund(Formerly known as IIFL ELSS Nifty 50 Tax Saver Index Fund",
    "category": "Equity Scheme - ELSS",
    "navName": "360 ONE ELSS Nifty 50 Tax Saver Index Fund - Regular Plan - IDCW Payout",
    "minAmount": "500",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF579M01AM4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151172",
    "name": "Kotak FMP Series 300",
    "category": "Income",
    "navName": "Kotak FMP Series 300 - Regular Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF174KA1KV7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151173",
    "name": "Kotak FMP Series 300",
    "category": "Income",
    "navName": "Kotak FMP Series 300 - Regular Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF174KA1KW5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151174",
    "name": "Kotak FMP Series 300",
    "category": "Income",
    "navName": "Kotak FMP Series 300 - Direct Plan - Growth",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF174KA1KX3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151175",
    "name": "Kotak FMP Series 300",
    "category": "Income",
    "navName": "Kotak FMP Series 300 - Direct Plan - IDCW Payout",
    "minAmount": "Rs. 5 000/-",
    "launchDate": "2022-12-01",
    "closeData": "",
    "isin": "INF174KA1KY1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151176",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Direct Plan -Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K017L6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151177",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Regular Plan - Daily IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K016L8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151178",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K015L0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151179",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K014L3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151201",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Regular Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K018L4",
    "isinRe": "INF846K010M9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151202",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Direct Plan - Monthly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K019L2",
    "isinRe": "INF846K011M7"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151203",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K012M5",
    "isinRe": "INF846K014M1"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151204",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K013M3",
    "isinRe": "INF846K015M8"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151205",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Direct Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K017M4",
    "isinRe": "INF846K019M0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151206",
    "name": "Axis Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "Axis Long Duration Fund - Regular Plan - Annual IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-07",
    "closeData": "2022-12-21",
    "isin": "INF846K016M6",
    "isinRe": "INF846K018M2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151180",
    "name": "HDFC Nifty G-Sec Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Jun 2027 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF179KC1EK6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151181",
    "name": "HDFC Nifty G-Sec Jun 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Jun 2027 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF179KC1EJ8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151182",
    "name": "HDFC Nifty G-Sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Sep 2032 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF179KC1EM2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151183",
    "name": "HDFC Nifty G-Sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Sep 2032 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2022-12-02",
    "closeData": "2022-12-08",
    "isin": "INF179KC1EL4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151184",
    "name": "UTI CRISIL SDL Maturity April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI CRISIL SDL Maturity April 2033 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2022-12-12",
    "closeData": "",
    "isin": "INF789F1AWW5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151185",
    "name": "UTI CRISIL SDL Maturity April 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI CRISIL SDL Maturity April 2033 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/- and in multiples of Re.1/- thereafter.",
    "launchDate": "2022-12-12",
    "closeData": "",
    "isin": "INF789F1AWX3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151186",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days) - Direct Plan Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs.10/-",
    "launchDate": "2022-12-07",
    "closeData": "",
    "isin": "INF209KB18P7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151187",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs.10/-",
    "launchDate": "2022-12-07",
    "closeData": "",
    "isin": "INF209KB19P5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151188",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days)- Regular Plan - Growth Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs.10/-",
    "launchDate": "2022-12-07",
    "closeData": "",
    "isin": "INF209KB16P1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151189",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UB (1224 days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs. 1000/- and in multiples of Rs.10/-",
    "launchDate": "2022-12-07",
    "closeData": "",
    "isin": "INF209KB17P9",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151190",
    "name": "quant Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "quant Overnight Fund - Growth Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-12-05",
    "closeData": "2022-12-05",
    "isin": "INF966L01AZ7",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151191",
    "name": "quant Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "quant Overnight Fund - IDCW Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-12-05",
    "closeData": "2022-12-05",
    "isin": "INF966L01BD2",
    "isinRe": "INF966L01BE0"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151193",
    "name": "quant Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "quant Overnight Fund - IDCW Option - Direct Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-12-05",
    "closeData": "2022-12-05",
    "isin": "INF966L01BA8",
    "isinRe": "INF966L01BB6"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151195",
    "name": "quant Overnight Fund",
    "category": "Debt Scheme - Overnight Fund",
    "navName": "quant Overnight Fund - Growth Option - Regular Plan",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-12-05",
    "closeData": "2022-12-05",
    "isin": "INF966L01BC4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151196",
    "name": "ICICI Prudential Nifty 10 yr Benchmark G-sec ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty 10 yr Benchmark G-sec ETF",
    "minAmount": "1000",
    "launchDate": "2022-12-12",
    "closeData": "",
    "isin": "INF109KC18O0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151207",
    "name": "ICICI Prudential Nifty Commodities ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty Commodities ETF",
    "minAmount": "1000",
    "launchDate": "2022-12-14",
    "closeData": "",
    "isin": "INF109KC19O8",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151208",
    "name": "ICICI Prudential Nifty SDL Sep 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2026 Index Fund - Growth",
    "minAmount": "1000",
    "launchDate": "2022-12-15",
    "closeData": "",
    "isin": "INF109KC10P4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151209",
    "name": "ICICI Prudential Nifty SDL Sep 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2026 Index Fund - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-12-15",
    "closeData": "",
    "isin": "INF109KC11P2",
    "isinRe": "INF109KC12P0"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151210",
    "name": "ICICI Prudential Nifty SDL Sep 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2026 Index Fund - Direct Plan - Annual IDCW",
    "minAmount": "1000",
    "launchDate": "2022-12-15",
    "closeData": "",
    "isin": "INF109KC14P6",
    "isinRe": "INF109KC15P3"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151211",
    "name": "ICICI Prudential Nifty SDL Sep 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "ICICI Prudential Nifty SDL Sep 2026 Index Fund - Direct Plan - Growth",
    "minAmount": "1000",
    "launchDate": "2022-12-15",
    "closeData": "",
    "isin": "INF109KC13P8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151212",
    "name": "SBI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "SBI Long Duration Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2022-12-12",
    "closeData": "2022-12-20",
    "isin": "INF200KA13T1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151213",
    "name": "SBI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "SBI Long Duration Fund - Regular Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2022-12-12",
    "closeData": "2022-12-20",
    "isin": "INF200KA14T9",
    "isinRe": "INF200KA15T6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151214",
    "name": "SBI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "SBI Long Duration Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2022-12-12",
    "closeData": "2022-12-20",
    "isin": "INF200KA16T4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151215",
    "name": "SBI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "SBI Long Duration Fund - Direct Plan - Income Distribution cum Capital Withdrawal Option (IDCW)",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2022-12-12",
    "closeData": "2022-12-20",
    "isin": "INF200KA17T2",
    "isinRe": "INF200KA18T0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151216",
    "name": "Kotak Nifty SDL Jul 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 20226 Index Fund Regular Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-13",
    "closeData": "",
    "isin": "INF174KA1KZ8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151217",
    "name": "Kotak Nifty SDL Jul 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 20226 Index Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-13",
    "closeData": "",
    "isin": "INF174KA1LA9",
    "isinRe": "INF174KA1LB7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151218",
    "name": "Kotak Nifty SDL Jul 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2026 Index Fund Direct Plan Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-13",
    "closeData": "",
    "isin": "INF174KA1LC5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151219",
    "name": "Kotak Nifty SDL Jul 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2026 Index Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-13",
    "closeData": "",
    "isin": "INF174KA1LD3",
    "isinRe": "INF174KA1LE1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151220",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1ER1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151221",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1EO8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151222",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1ES9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151223",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1EP5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151224",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - Growth Option",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1EQ3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151225",
    "name": "HDFC FMP 1204D December 2022",
    "category": "Income",
    "navName": "HDFC FMP 1204D December - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-16",
    "closeData": "2022-12-26",
    "isin": "INF179KC1EN0",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151229",
    "name": "quant Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "quant Gilt Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-14",
    "closeData": "",
    "isin": "INF966L01BG5",
    "isinRe": "INF966L01BH3"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151230",
    "name": "quant Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "quant Gilt Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-14",
    "closeData": "",
    "isin": "INF966L01BI1",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151231",
    "name": "quant Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "quant Gilt Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-14",
    "closeData": "",
    "isin": "INF966L01BJ9",
    "isinRe": "INF966L01BK7"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151228",
    "name": "quant Gilt Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "quant Gilt Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2022-12-14",
    "closeData": "",
    "isin": "INF966L01BF7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151232",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1679",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151233",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Direct Plan - IDCW Reinvestment",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1687",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151234",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1695",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151235",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1703",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151236",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Regular Plan - IDCW Reinvestment",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1711",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151237",
    "name": "TATA MULTICAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Tata Multicap Fund - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF277KA1729",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151238",
    "name": "UTI CRISIL SDL Maturity June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI CRISIL SDL Maturity June 2027 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-01-02",
    "closeData": "",
    "isin": "INF789F1AWY1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151239",
    "name": "UTI CRISIL SDL Maturity June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI CRISIL SDL Maturity June 2027 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-01-02",
    "closeData": "",
    "isin": "INF789F1AWZ8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151240",
    "name": "DSP FMP Series - 269 - 160 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 269 - 160 Days - Regular IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-12-20",
    "closeData": "",
    "isin": "INF740KA1RS3",
    "isinRe": "INF740KA1RT1"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151241",
    "name": "DSP FMP Series - 269 - 160 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 269 - 160 Days - Direct IDCW",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-12-20",
    "closeData": "",
    "isin": "INF740KA1RV7",
    "isinRe": "INF740KA1RW5"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151242",
    "name": "DSP FMP Series - 269 - 160 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 269 - 160 Days - Regular Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-12-20",
    "closeData": "",
    "isin": "INF740KA1RR5",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151243",
    "name": "DSP FMP Series - 269 - 160 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 269 - 160 Days - Direct Growth",
    "minAmount": "Rs. 5000/  and any amount thereafter",
    "launchDate": "2022-12-20",
    "closeData": "",
    "isin": "INF740KA1RU9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151244",
    "name": "Kotak FMP Series 302",
    "category": "Income",
    "navName": "Kotak FMP Series 302 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-19",
    "closeData": "",
    "isin": "INF174KA1LF8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151245",
    "name": "Kotak FMP Series 302",
    "category": "Income",
    "navName": "Kotak FMP Series 302 - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-19",
    "closeData": "",
    "isin": "INF174KA1LI2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151246",
    "name": "Kotak FMP Series 302",
    "category": "Income",
    "navName": "Kotak FMP Series 302 - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-19",
    "closeData": "",
    "isin": "INF174KA1LG6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151247",
    "name": "Kotak FMP Series 302",
    "category": "Income",
    "navName": "Kotak FMP Series 302 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-19",
    "closeData": "",
    "isin": "INF174KA1LH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151248",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151249",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151250",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151251",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 93 Days Plan P - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151253",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)- Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-27",
    "closeData": "2023-01-02",
    "isin": "INF200KA12U1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151254",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)- Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-27",
    "closeData": "2023-01-02",
    "isin": "INF200KA19T8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151255",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)- Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-27",
    "closeData": "2023-01-02",
    "isin": "INF200KA10U5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151252",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 74 (1243 Days)- Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-27",
    "closeData": "2023-01-02",
    "isin": "INF200KA11U3",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151256",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151257",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151258",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151259",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151260",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151261",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1199 Days Plan Q - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151262",
    "name": "DSP Nifty Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Nifty Bank ETF",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2022-12-26",
    "closeData": "2022-12-28",
    "isin": "INF740KA1RX3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151263",
    "name": "Kotak FMP Series 303",
    "category": "Income",
    "navName": "Kotak FMP Series 303 - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": "INF174KA1LM4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151264",
    "name": "Kotak FMP Series 303",
    "category": "Income",
    "navName": "Kotak FMP Series 303 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": "INF174KA1LJ0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151265",
    "name": "Kotak FMP Series 303",
    "category": "Income",
    "navName": "Kotak FMP Series 303 - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": "INF174KA1LK8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151266",
    "name": "Kotak FMP Series 303",
    "category": "Income",
    "navName": "Kotak FMP Series 303 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2022-12-23",
    "closeData": "",
    "isin": "INF174KA1LL6",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "151267",
    "name": "WhiteOak Capital Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "WhiteOak Capital Balanced Advantage Fund Direct Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 1/-",
    "launchDate": "2023-01-20",
    "closeData": "2023-02-03",
    "isin": "INF03VN01738",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "151268",
    "name": "WhiteOak Capital Balanced Advantage Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "WhiteOak Capital Balanced Advantage Fund Regular Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 1/-",
    "launchDate": "2023-01-20",
    "closeData": "2023-02-03",
    "isin": "INF03VN01720",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151269",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)- Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-30",
    "closeData": "2023-01-09",
    "isin": "INF200KA15U4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151270",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days) - Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-30",
    "closeData": "2023-01-09",
    "isin": "INF200KA16U2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151271",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)- Regular  Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-30",
    "closeData": "2023-01-09",
    "isin": "INF200KA13U9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151272",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 75 (366 Days)- Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2022-12-30",
    "closeData": "2023-01-09",
    "isin": "INF200KA14U7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151273",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1737",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151274",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Direct Plan - IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1752",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151275",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1760",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151276",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Regular Plan - IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1778",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151277",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Regular Plan - IDCW Payout",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1786",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151278",
    "name": "Tata Nifty G-Sec Dec 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G-Sec Dec 2029 Index Fund - Direct Plan - IDCW Reinvestment",
    "minAmount": "Rs 5000/- and in multiple of Re.1/- thereafter",
    "launchDate": "2023-01-03",
    "closeData": "",
    "isin": "INF277KA1745",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151279",
    "name": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF251K01RI6",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151280",
    "name": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF251K01RJ4",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151281",
    "name": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF251K01RM8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151283",
    "name": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas Nifty SDL December 2026 Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-01-16",
    "closeData": "",
    "isin": "INF251K01RL0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151285",
    "name": "Kotak FMP Series 304",
    "category": "Income",
    "navName": "Kotak FMP Series 304-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": "INF174KA1LN2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151286",
    "name": "Kotak FMP Series 304",
    "category": "Income",
    "navName": "Kotak FMP Series 304-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": "INF174KA1LP7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151287",
    "name": "Kotak FMP Series 304",
    "category": "Income",
    "navName": "Kotak FMP Series 304-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": "INF174KA1LQ5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151288",
    "name": "Kotak FMP Series 304",
    "category": "Income",
    "navName": "Kotak FMP Series 304-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000",
    "launchDate": "2022-12-27",
    "closeData": "",
    "isin": "INF174KA1LO0",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151289",
    "name": "HSBC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HSBC Multi Cap Fund - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-10",
    "closeData": "",
    "isin": "INF336L01QQ8",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151290",
    "name": "HSBC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HSBC Multi Cap Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-10",
    "closeData": "",
    "isin": "INF336L01QN5",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151291",
    "name": "HSBC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HSBC Multi Cap Fund - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-10",
    "closeData": "",
    "isin": "INF336L01QO3",
    "isinRe": "INF336L01QP0"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151292",
    "name": "HSBC Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "HSBC Multi Cap Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-10",
    "closeData": "",
    "isin": "INF336L01QR6",
    "isinRe": "INF336L01QS4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151297",
    "name": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2023-01-05",
    "closeData": "2023-01-16",
    "isin": "INF846K013N1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151298",
    "name": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2023-01-05",
    "closeData": "2023-01-16",
    "isin": "INF846K014N9",
    "isinRe": "INF846K015N6"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151299",
    "name": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2023-01-05",
    "closeData": "2023-01-16",
    "isin": "INF846K011N5",
    "isinRe": "INF846K012N3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151300",
    "name": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Axis CRISIL IBX 50 50 Gilt Plus SDL June 2028 Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 1/- thereafter",
    "launchDate": "2023-01-05",
    "closeData": "2023-01-16",
    "isin": "INF846K010N7",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151305",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G Sec Dec 2026 Index Fund-Direct Plan-IDCW Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1802",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151306",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G Sec Dec 2026 Index Fund-Regular Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1828",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151301",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G Sec Dec 2026 Index Fund-Direct Plan-Growth",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1794",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151302",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G Sec Dec 2026 Index Fund-Direct Plan-IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1810",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151303",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G SEc Dec 2026 Index Fund-Regular Plan-IDCW Reinvestment",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1836",
    "isinRe": null
  },
  {
    "amc": "Tata Asset Management Limited",
    "code": "151304",
    "name": "Tata Nifty G-Sec Dec 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Tata Nifty G Sec Dec 2026 Index Fund-Regular Plan-IDCW Payout",
    "minAmount": "Rs. 5000/- and in multiple of Re.1/- thereafter.",
    "launchDate": "2023-01-04",
    "closeData": "",
    "isin": "INF277KA1844",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151307",
    "name": "Aditya Birla Sun Life Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Aditya Birla Sun Life Multi Asset Allocation Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2023-01-11",
    "closeData": "",
    "isin": "INF209KB15R9",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151308",
    "name": "Aditya Birla Sun Life Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Aditya Birla Sun Life Multi Asset Allocation Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2023-01-11",
    "closeData": "",
    "isin": "INF209KB16R7",
    "isinRe": "INF209KB17R5"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151309",
    "name": "Aditya Birla Sun Life Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Aditya Birla Sun Life Multi Asset Allocation Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2023-01-11",
    "closeData": "",
    "isin": "INF209KB12R6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151310",
    "name": "Aditya Birla Sun Life Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Aditya Birla Sun Life Multi Asset Allocation Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2023-01-11",
    "closeData": "",
    "isin": "INF209KB13R4",
    "isinRe": "INF209KB14R2"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151311",
    "name": "HDFC Long Duration Debt Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "HDFC Long Duration Debt Fund - IDCW Option",
    "minAmount": "100",
    "launchDate": "2023-01-06",
    "closeData": "2023-01-17",
    "isin": "INF179KC1EX9",
    "isinRe": "INF179KC1EY7"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151312",
    "name": "HDFC Long Duration Debt Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "HDFC Long Duration Debt Fund - IDCW Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-01-06",
    "closeData": "2023-01-17",
    "isin": "INF179KC1EU5",
    "isinRe": "INF179KC1EV3"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151313",
    "name": "HDFC Long Duration Debt Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "HDFC Long Duration Debt Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-01-06",
    "closeData": "2023-01-17",
    "isin": "INF179KC1ET7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151314",
    "name": "HDFC Long Duration Debt Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "HDFC Long Duration Debt Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-01-06",
    "closeData": "2023-01-17",
    "isin": "INF179KC1EW1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151315",
    "name": "Kotak FMP Series 305",
    "category": "Income",
    "navName": "Kotak FMP Series 305 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-05",
    "closeData": "",
    "isin": "INF174KA1LR3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151316",
    "name": "Kotak FMP Series 305",
    "category": "Income",
    "navName": "Kotak FMP Series 305 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-05",
    "closeData": "",
    "isin": "INF174KA1LT9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151317",
    "name": "Kotak FMP Series 305",
    "category": "Income",
    "navName": "Kotak FMP Series 305 - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-05",
    "closeData": "",
    "isin": "INF174KA1LU7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151318",
    "name": "Kotak FMP Series 305",
    "category": "Income",
    "navName": "Kotak FMP Series 305 - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-05",
    "closeData": "",
    "isin": "INF174KA1LS1",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151319",
    "name": "TRUSTMF CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "TRUSTMF CORPORATE BOND FUND-REGULAR PLAN-MONTHLY IDCW",
    "minAmount": "1000",
    "launchDate": "2023-01-09",
    "closeData": "",
    "isin": "INF0GCD01552",
    "isinRe": "INF0GCD01545"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151320",
    "name": "TRUSTMF CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "TRUSTMF CORPORATE BOND FUND-DIRECT PLAN-GROWTH",
    "minAmount": "1000",
    "launchDate": "2023-01-09",
    "closeData": "",
    "isin": "INF0GCD01560",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151321",
    "name": "TRUSTMF CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "TRUSTMF CORPORATE BOND FUND-DIRECT PLAN-MONTHLY IDCW",
    "minAmount": "1000",
    "launchDate": "2023-01-09",
    "closeData": "",
    "isin": "INF0GCD01586",
    "isinRe": "INF0GCD01578"
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151322",
    "name": "TRUSTMF CORPORATE BOND FUND",
    "category": "Debt Scheme - Corporate Bond Fund",
    "navName": "TRUSTMF CORPORATE BOND FUND-REGULAR PLAN-GROWTH",
    "minAmount": "1000",
    "launchDate": "2023-01-09",
    "closeData": "",
    "isin": "INF0GCD01537",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151327",
    "name": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund - Regular - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-10",
    "closeData": "2023-01-19",
    "isin": "INF740KA1RY1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151328",
    "name": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund - Direct - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-10",
    "closeData": "2023-01-19",
    "isin": "INF740KA1SC5",
    "isinRe": "INF740KA1SD3"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151329",
    "name": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund - Direct - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-10",
    "closeData": "2023-01-19",
    "isin": "INF740KA1SB7",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151330",
    "name": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP CRISIL SDL Plus G-Sec Apr 2033 50 50 Index Fund - Regular - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-10",
    "closeData": "2023-01-19",
    "isin": "INF740KA1RZ8",
    "isinRe": "INF740KA1SA9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151331",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXA9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151332",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Regular Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXD3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151333",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXE1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151334",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXF8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151335",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Direct Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXG6",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151336",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Direct Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXI2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151338",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXC5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151339",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXJ0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151340",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Regular Plan - Maturity IDCW Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXB7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151341",
    "name": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund - Series XXXV-III (1176 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and thereafter in multiple of Rs10 /-",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": "INF789F1AXH4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151342",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151343",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151344",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151345",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Direct Plan - Quarterly IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151346",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Direct Plan - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151347",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1192 Days Plan R - Half Yearly IDCW",
    "minAmount": "5000",
    "launchDate": "2023-01-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151348",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 76 (1221 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 76 (1221 Days) - Regular Plan - Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-18",
    "closeData": "2023-01-23",
    "isin": "INF200KA18U8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151349",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 76 (1221 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 76 (1221 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-18",
    "closeData": "2023-01-23",
    "isin": "INF200KA17U0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151350",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 76 (1221 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 76 (1221 Days) - Direct Plan - Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-18",
    "closeData": "2023-01-23",
    "isin": "INF200KA10V3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151351",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 76 (1221 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 76 (1221 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-18",
    "closeData": "2023-01-23",
    "isin": "INF200KA19U6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151358",
    "name": "Kotak Nifty 1D Rate Liquid ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Kotak Nifty 1D Rate Liquid ETF",
    "minAmount": "5000",
    "launchDate": "2023-01-18",
    "closeData": "",
    "isin": "INF174KA1LV5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151359",
    "name": "Kotak FMP Series 306",
    "category": "Income",
    "navName": "Kotak FMP Series 306 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-20",
    "closeData": "",
    "isin": "INF174KA1LW3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151360",
    "name": "Kotak FMP Series 306",
    "category": "Income",
    "navName": "Kotak FMP Series 306 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-01-20",
    "closeData": "",
    "isin": "INF174KA1LY9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151361",
    "name": "Kotak FMP Series 306",
    "category": "Income",
    "navName": "Kotak FMP Series 306 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-01-20",
    "closeData": "",
    "isin": "INF174KA1LZ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151362",
    "name": "Kotak FMP Series 306",
    "category": "Income",
    "navName": "Kotak FMP Series 306 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-01-20",
    "closeData": "",
    "isin": "INF174KA1LX1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151363",
    "name": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2026 75 25 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2026 75 25 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 1/ thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF789F1AXK8",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151364",
    "name": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2026 75 25 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2026 75 25 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 1/ thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF789F1AXL6",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151365",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF754K01RK5",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151397",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF754K01RL3",
    "isinRe": "INF754K01RM1"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151398",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF754K01RG3",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151399",
    "name": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Edelweiss CRISIL IBX 50 50 Gilt Plus SDL Short Duration Index fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- and multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF754K01RH1",
    "isinRe": "INF754K01RI9"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151366",
    "name": "Axis Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Business Cycles Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "2023-02-16",
    "isin": "INF846K019N8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151367",
    "name": "Axis Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Business Cycles Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "2023-02-16",
    "isin": "INF846K010O5",
    "isinRe": "INF846K011O3"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151368",
    "name": "Axis Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Business Cycles Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "2023-02-16",
    "isin": "INF846K016N4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151369",
    "name": "Axis Business Cycles Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Axis Business Cycles Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "2023-02-16",
    "isin": "INF846K017N2",
    "isinRe": "INF846K018N0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151370",
    "name": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund - Regular - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-25",
    "closeData": "2023-02-08",
    "isin": "INF740KA1SE1",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151371",
    "name": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund - Direct - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-25",
    "closeData": "2023-02-08",
    "isin": "INF740KA1SI2",
    "isinRe": "INF740KA1SJ0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151372",
    "name": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund - Regular - IDCW",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-25",
    "closeData": "2023-02-08",
    "isin": "INF740KA1SF8",
    "isinRe": "INF740KA1SG6"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151373",
    "name": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "DSP Nifty SDL Plus G-Sec Sep 2027 50 50 Index Fund - Direct - Growth",
    "minAmount": "Rs. 500/-and any amount thereafter",
    "launchDate": "2023-01-25",
    "closeData": "2023-02-08",
    "isin": "INF740KA1SH4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151374",
    "name": "HDFC NIFTY Midcap 150 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY Midcap 150 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "2023-02-09",
    "isin": "INF179KC1FA4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151375",
    "name": "HDFC NIFTY Smallcap 250 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC NIFTY Smallcap 250 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "2023-02-09",
    "isin": "INF179KC1FB2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151376",
    "name": "HDFC S&P BSE 500 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "HDFC S&P BSE 500 ETF - Growth Option",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "2023-02-09",
    "isin": "INF179KC1EZ4",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151377",
    "name": "ITI Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ITI Flexi Cap Fund- Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF00XX01CL3",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151378",
    "name": "ITI Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ITI Flexi Cap Fund- Regular Plan- IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF00XX01CM1",
    "isinRe": "INF00XX01CN9"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151379",
    "name": "ITI Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ITI Flexi Cap Fund- Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF00XX01CO7",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151380",
    "name": "ITI Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "ITI Flexi Cap Fund- Direct Plan- IDCW",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-01-27",
    "closeData": "",
    "isin": "INF00XX01CP4",
    "isinRe": "INF00XX01CQ2"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151382",
    "name": "Kotak Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Banking & Financial Services Fund - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF174KA1MB5",
    "isinRe": "INF174KA1MC3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151383",
    "name": "Kotak Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Banking & Financial Services Fund - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF174KA1ME9",
    "isinRe": "INF174KA1MF6"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151384",
    "name": "Kotak Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Banking & Financial Services Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF174KA1MD1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151381",
    "name": "Kotak Banking and Financial Services Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Kotak Banking & Financial Services Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF174KA1MA7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151389",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2029 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec - Jun 2029 Maturity 70 30 Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF204KC1AH9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151390",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2029 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec-Jun 2029 Maturity 70 30 Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF204KC1AI7",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151391",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2029 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec-Jun 2029 Maturity 70 30 Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF204KC1AJ5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151392",
    "name": "Nippon India Nifty SDL Plus G-Sec - Jun 2029 Maturity 70 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty SDL Plus G-Sec-Jun 2029 Maturity 70 30 Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs. 1000 and in multiples of Re. 1 thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF204KC1AK3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151393",
    "name": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "",
    "isin": "INF209KB18R3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151394",
    "name": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "",
    "isin": "INF209KB11S6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151395",
    "name": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "",
    "isin": "INF209KB19R1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151396",
    "name": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX AAA Mar 2024 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2023-01-30",
    "closeData": "",
    "isin": "INF209KB10S8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151400",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "2023-02-13",
    "isin": "INF200KA16V0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151401",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)- Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "2023-02-13",
    "isin": "INF200KA13V7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151402",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)- Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "2023-02-13",
    "isin": "INF200KA14V5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151403",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 77 (366 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "2023-02-13",
    "isin": "INF200KA15V2",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "151404",
    "name": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund - Regular Plan Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "",
    "isin": "INF663L01X54",
    "isinRe": null
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "151405",
    "name": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund - Regular Plan IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "",
    "isin": "INF663L01X62",
    "isinRe": "INF663L01X70"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "151406",
    "name": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund - Direct Plan IDCW Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "",
    "isin": "INF663L01X39",
    "isinRe": "INF663L01X47"
  },
  {
    "amc": "PGIM India Asset Management Private Limite",
    "code": "151407",
    "name": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "PGIM India CRISIL IBX Gilt Index - Apr 2028 Fund - Direct Plan Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-02",
    "closeData": "",
    "isin": "INF663L01X21",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151408",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND - DIRECT GROWTH",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF194KB1FF5",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151409",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND - REGULAR GROWTH",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF194KB1FI9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151410",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND - REGULAR IDCW",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF194KB1FJ7",
    "isinRe": "INF194KB1FK5"
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151411",
    "name": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND",
    "category": "Other Scheme - Index Funds",
    "navName": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND - DIRECT IDCW",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": "INF194KB1FG3",
    "isinRe": "INF194KB1FH1"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151412",
    "name": "Mirae Asset Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mirae Asset Flexi Cap Fund - Direct Plan - Growth",
    "minAmount": "Rs.5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF769K01JJ2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151413",
    "name": "Mirae Asset Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mirae Asset Flexi Cap Fund - Direct Plan -  IDCW",
    "minAmount": "Rs.5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF769K01JI4",
    "isinRe": "INF769K01JK0"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151414",
    "name": "Mirae Asset Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mirae Asset Flexi Cap Fund - Regular Plan - Growth",
    "minAmount": "Rs.5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF769K01JG8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151415",
    "name": "Mirae Asset Flexi Cap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "Mirae Asset Flexi Cap Fund - Regular Plan - IDCW",
    "minAmount": "Rs.5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF769K01JF0",
    "isinRe": "INF769K01JH6"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151416",
    "name": "Mirae Asset Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "Mirae Asset Gold ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-02-09",
    "closeData": "",
    "isin": "INF769K01JP9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151417",
    "name": "Kotak Nifty SDL Jul 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2033 Index Fund Regular Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF174KA1MG4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151418",
    "name": "Kotak Nifty SDL Jul 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2033 Index Fund Direct Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF174KA1MK6",
    "isinRe": "INF174KA1ML4"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151419",
    "name": "Kotak Nifty SDL Jul 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2033 Index Fund Regular Plan IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF174KA1MH2",
    "isinRe": "INF174KA1MI0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151420",
    "name": "Kotak Nifty SDL Jul 2033 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2033 Index Fund Direct Plan Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-02-03",
    "closeData": "",
    "isin": "INF174KA1MJ8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151421",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-08",
    "closeData": "",
    "isin": "INF769K01JN4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151422",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1 - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-08",
    "closeData": "",
    "isin": "INF769K01JO2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151423",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-08",
    "closeData": "",
    "isin": "INF769K01JL8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151424",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 1 - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-08",
    "closeData": "",
    "isin": "INF769K01JM6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151425",
    "name": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 INDEX FUND - Direct IDCW Payout",
    "minAmount": "500",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151426",
    "name": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 INDEX FUND - Regular IDCW Payout",
    "minAmount": "500",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151427",
    "name": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 INDEX FUND - Direct Growth",
    "minAmount": "500",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151428",
    "name": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life CRISIL IBX SDL Jun 2032 INDEX FUND - Regular Growth",
    "minAmount": "500",
    "launchDate": "2023-02-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151433",
    "name": "Axis Crisil IBX 50 50 Gilt Plus SDL September 2027 Index Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "AXIS CRISIL IBX 50 50 Gilt Plus SDL September 2027 Index Fund- REGULAR PLAN-GROWTH",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2023-02-08",
    "closeData": "2023-02-22",
    "isin": "INF846K015O4",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151434",
    "name": "Axis Crisil IBX 50 50 Gilt Plus SDL September 2027 Index Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "AXIS CRISIL IBX 50 50 Gilt Plus SDL September 2027 Index Fund- REGULAR PLAN-IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2023-02-08",
    "closeData": "2023-02-22",
    "isin": "INF846K016O2",
    "isinRe": "INF846K017O0"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151435",
    "name": "Axis Crisil IBX 50 50 Gilt Plus SDL September 2027 Index Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "AXIS CRISIL IBX 50 50 Gilt Plus SDL September 2027 Index Fund- DIRECT PLAN-GROWTH",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2023-02-08",
    "closeData": "2023-02-22",
    "isin": "INF846K012O1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151436",
    "name": "Axis Crisil IBX 50 50 Gilt Plus SDL September 2027 Index Fund",
    "category": "Debt Scheme - Gilt Fund",
    "navName": "AXIS CRISIL IBX 50 50 Gilt Plus SDL September 2027 Index Fund- DIRECT PLAN- IDCW",
    "minAmount": "Rs. 5000/- & in multiple of Re. 1 thereafter",
    "launchDate": "2023-02-08",
    "closeData": "2023-02-22",
    "isin": "INF846K013O9",
    "isinRe": "INF846K014O7"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151439",
    "name": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund-Regular Plan-Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2023-02-20",
    "closeData": "",
    "isin": "INF204KC1AL1",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151440",
    "name": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund-Direct Plan-Growth Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2023-02-20",
    "closeData": "",
    "isin": "INF204KC1AO5",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151441",
    "name": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund-Regular Plan-IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2023-02-20",
    "closeData": "",
    "isin": "INF204KC1AM9",
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151442",
    "name": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Nippon India Nifty G-Sec Oct 2028 Maturity Index Fund-Direct Plan-IDCW Option",
    "minAmount": "Rs.1000 and in multiples of Re.1 thereafter",
    "launchDate": "2023-02-20",
    "closeData": "",
    "isin": "INF204KC1AP2",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "151443",
    "name": "BANK OF INDIA MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Bank of India Multi Cap Fund Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-10",
    "closeData": "",
    "isin": "INF761K01FR0",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "151444",
    "name": "BANK OF INDIA MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Bank of India Multi Cap Fund Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-10",
    "closeData": "",
    "isin": "INF761K01FQ2",
    "isinRe": "INF761K01FS8"
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "151445",
    "name": "BANK OF INDIA MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Bank of India Multi Cap Fund Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-10",
    "closeData": "",
    "isin": "INF761K01FU4",
    "isinRe": null
  },
  {
    "amc": "Bank of India Investment Managers Private Limited",
    "code": "151446",
    "name": "BANK OF INDIA MULTI CAP FUND",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Bank of India Multi Cap Fund Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-10",
    "closeData": "",
    "isin": "INF761K01FT6",
    "isinRe": "INF761K01FV2"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151447",
    "name": "DSP FMP Series - 270 - 1144 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 270 - 1144 Days - Regular - Growth",
    "minAmount": "Rs. 5000/- and any amount thereafter",
    "launchDate": "2023-02-13",
    "closeData": "",
    "isin": "INF740KA1SK8",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151448",
    "name": "DSP FMP Series - 270 - 1144 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 270 - 1144 Days - Regular - IDCW",
    "minAmount": "Rs. 5000/- and any amount thereafter",
    "launchDate": "2023-02-13",
    "closeData": "",
    "isin": "INF740KA1SL6",
    "isinRe": "INF740KA1SM4"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151449",
    "name": "DSP FMP Series - 270 - 1144 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 270 - 1144 Days - Direct - Growth",
    "minAmount": "Rs. 5000/- and any amount thereafter",
    "launchDate": "2023-02-13",
    "closeData": "",
    "isin": "INF740KA1SN2",
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151450",
    "name": "DSP FMP Series - 270 - 1144 Days",
    "category": "Income",
    "navName": "DSP FMP Series - 270 - 1144 Days - Direct - IDCW",
    "minAmount": "Rs. 5000/- and any amount thereafter",
    "launchDate": "2023-02-13",
    "closeData": "",
    "isin": "INF740KA1SO0",
    "isinRe": "INF740KA1SP7"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151451",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-15",
    "closeData": "2023-02-20",
    "isin": "INF200KA19V4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151452",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-15",
    "closeData": "2023-02-20",
    "isin": "INF200KA10W1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151453",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days) Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-15",
    "closeData": "2023-02-20",
    "isin": "INF200KA18V6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151454",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 78 (1170 Days) Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-02-15",
    "closeData": "2023-02-20",
    "isin": "INF200KA17V8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151455",
    "name": "HDFC Nifty SDL Oct 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty SDL Oct 2026 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-02-16",
    "closeData": "2023-02-22",
    "isin": "INF179KC1FJ5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151456",
    "name": "HDFC Nifty SDL Oct 2026 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty SDL Oct 2026 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-02-16",
    "closeData": "2023-02-22",
    "isin": "INF179KC1FI7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151457",
    "name": "HDFC MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC MNC Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-02-17",
    "closeData": "2023-03-03",
    "isin": "INF179KC1FF3",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151458",
    "name": "HDFC MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC MNC Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-02-17",
    "closeData": "2023-03-03",
    "isin": "INF179KC1FC0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151459",
    "name": "HDFC MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC MNC Fund - IDCW Option",
    "minAmount": "100",
    "launchDate": "2023-02-17",
    "closeData": "2023-03-03",
    "isin": "INF179KC1FG1",
    "isinRe": "INF179KC1FH9"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151460",
    "name": "HDFC MNC Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC MNC Fund - IDCW Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-02-17",
    "closeData": "2023-03-03",
    "isin": "INF179KC1FD8",
    "isinRe": "INF179KC1FE6"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151465",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151466",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151467",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151468",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151469",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151470",
    "name": "HDFC FMP 2638D February 2023",
    "category": "Income",
    "navName": "HDFC FMP 2638D February 2023 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-02-21",
    "closeData": "2023-02-22",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "151471",
    "name": "NAVI ELSS TAX SAVER NIFTY50 INDEX FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "NAVI ELSS TAX SAVER NIFTY 50 INDEX FUND - DIRECT PLAN GROWTH",
    "minAmount": "500/-",
    "launchDate": "2023-02-14",
    "closeData": "",
    "isin": "INF959L01GR6",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "151472",
    "name": "NAVI ELSS TAX SAVER NIFTY50 INDEX FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "NAVI ELSS TAX SAVER NIFTY 50 INDEX FUND - REGULAR PLAN GROWTH",
    "minAmount": "500/-",
    "launchDate": "2023-02-14",
    "closeData": "",
    "isin": "INF959L01GS4",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "151473",
    "name": "NAVI ELSS TAX SAVER NIFTY50 INDEX FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "NAVI ELSS TAX SAVER NIFTY 50 INDEX FUND - DIRECT PLAN IDCW PAYOUT",
    "minAmount": "500/-",
    "launchDate": "2023-02-14",
    "closeData": "",
    "isin": "INF959L01GT2",
    "isinRe": null
  },
  {
    "amc": "Navi AMC Limited",
    "code": "151474",
    "name": "NAVI ELSS TAX SAVER NIFTY50 INDEX FUND",
    "category": "Equity Scheme - ELSS",
    "navName": "NAVI ELSS TAX SAVER NIFTY 50 INDEX FUND - REGULAR PLAN IDCW PAYOUT",
    "minAmount": "500/-",
    "launchDate": "2023-02-14",
    "closeData": "",
    "isin": "INF959L01GU0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151475",
    "name": "SBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "SBI Dividend Yield Fund - Direct Plan - Income Distribution cum Capital Withdrawal (IDCW) Option",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-02-20",
    "closeData": "2023-03-06",
    "isin": "INF200KA15W0",
    "isinRe": "INF200KA16W8"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151476",
    "name": "SBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "SBI Dividend Yield Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-02-20",
    "closeData": "2023-03-06",
    "isin": "INF200KA11W9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151477",
    "name": "SBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "SBI Dividend Yield Fund - Regular Plan - Income Distribution cum Capital Withdrawal (IDCW) Option",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-02-20",
    "closeData": "2023-03-06",
    "isin": "INF200KA12W7",
    "isinRe": "INF200KA13W5"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151478",
    "name": "SBI Dividend Yield Fund",
    "category": "Equity Scheme - Dividend Yield Fund",
    "navName": "SBI Dividend Yield Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-02-20",
    "closeData": "2023-03-06",
    "isin": "INF200KA14W3",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151479",
    "name": "HSBC CRISIL IBX Gilt June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX Gilt June 2027 Index Fund - Direct - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF336L01QT2",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151480",
    "name": "HSBC CRISIL IBX Gilt June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX Gilt June 2027 Index Fund - Regular - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF336L01QW6",
    "isinRe": null
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151481",
    "name": "HSBC CRISIL IBX Gilt June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX Gilt June 2027 Index Fund - Regular - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF336L01QX4",
    "isinRe": "INF336L01QY2"
  },
  {
    "amc": "HSBC Asset Management (India) Private Ltd.",
    "code": "151482",
    "name": "HSBC CRISIL IBX Gilt June 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HSBC CRISIL IBX Gilt June 2027 Index Fund - Direct - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF336L01QU0",
    "isinRe": "INF336L01QV8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151483",
    "name": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days) - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 10/- thereafter",
    "launchDate": "2023-02-21",
    "closeData": "",
    "isin": "INF789F1AXN2",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151484",
    "name": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 10/- thereafter",
    "launchDate": "2023-02-21",
    "closeData": "",
    "isin": "INF789F1AXO0",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151485",
    "name": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days) - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 10/- thereafter",
    "launchDate": "2023-02-21",
    "closeData": "",
    "isin": "INF789F1AXP7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151486",
    "name": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days)",
    "category": "Income",
    "navName": "UTI Fixed Term Income Fund Series XXXVI - I (1574 Days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 10/- thereafter",
    "launchDate": "2023-02-21",
    "closeData": "",
    "isin": "INF789F1AXM4",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151487",
    "name": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2028- 75 25 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2028- 75 25 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 1 thereafter",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF789F1AXR3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151488",
    "name": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2028- 75 25 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI NIFTY SDL Plus AAA PSU Bond Apr 2028- 75 25 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs 1 thereafter",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF789F1AXQ5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151489",
    "name": "HDFC Nifty G-Sec Jun 2036 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Jun 2036 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-02-27",
    "closeData": "2023-03-08",
    "isin": "INF179KC1FS6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151490",
    "name": "HDFC Nifty G-Sec Jun 2036 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Jun 2036 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-02-27",
    "closeData": "2023-03-08",
    "isin": "INF179KC1FT4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151491",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF109KC12R6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151492",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF109KC15R9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151493",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF109KC14R2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151494",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 1303 Days Plan S - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF109KC13R4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151495",
    "name": "HDFC Nifty G-Sec Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Apr 2029 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-02-27",
    "closeData": "2023-03-08",
    "isin": "INF179KC1FQ0",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151496",
    "name": "HDFC Nifty G-Sec Apr 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC Nifty G-Sec Apr 2029 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-02-27",
    "closeData": "2023-03-08",
    "isin": "INF179KC1FR8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151504",
    "name": "Axis Fixed Term Plan - Series 112 (1143 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 112 (1143 Days) - Regular Plan -  Half Yearly IDCW Payout",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-02-23",
    "closeData": "2023-02-28",
    "isin": "INF846K013P6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151506",
    "name": "Axis Fixed Term Plan - Series 112 (1143 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 112 (1143 Days) - Regular  Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-02-23",
    "closeData": "2023-02-28",
    "isin": "INF846K011P0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151501",
    "name": "Axis Fixed Term Plan - Series 112 (1143 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 112 (1143 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-02-23",
    "closeData": "2023-02-28",
    "isin": "INF846K018O8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151503",
    "name": "Axis Fixed Term Plan - Series 112 (1143 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 112 (1143 Days) - Regular Plan - Quarterly IDCW Payout",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-02-23",
    "closeData": "2023-02-28",
    "isin": "INF846K012P8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151507",
    "name": "Kotak FMP Series 307",
    "category": "Income",
    "navName": "Kotak FMP Series 307 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF174KA1MM2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151508",
    "name": "Kotak FMP Series 307",
    "category": "Income",
    "navName": "Kotak FMP Series 307 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF174KA1MP5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151509",
    "name": "Kotak FMP Series 307",
    "category": "Income",
    "navName": "Kotak FMP Series 307 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF174KA1MN0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151510",
    "name": "Kotak FMP Series 307",
    "category": "Income",
    "navName": "Kotak FMP Series 307 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-02-24",
    "closeData": "",
    "isin": "INF174KA1MO8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151517",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - DIRECT PLAN - HALF YEARLY IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FS8",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151518",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - DIRECT PLAN - PERIODIC IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FQ2",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151511",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - REGULAR PLAN - GROWTH OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FL3",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151512",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - REGULAR PLAN - QUARTERLY IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FN9",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151513",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - REGULAR PLAN - HALF YEARLY IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FO7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151514",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - REGULAR PLAN - PERIODIC IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FM1",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151515",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - DIRECT PLAN - GROWTH OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FP4",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151516",
    "name": "IDFC FIXED TERM PLAN - SERIES 199 (2233 DAYS)",
    "category": "Income",
    "navName": "IDFC FIXED TERM PLAN - SERIES 199 - DIRECT PLAN - QUARTERLY IDCW OPTION (2233 DAYS)",
    "minAmount": "Rs.5000/- and multiples of Rs.10/- thereafter",
    "launchDate": "2023-02-28",
    "closeData": "",
    "isin": "INF194KB1FR0",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151519",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days) - Direct Plan - Growth Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF209KB18S1",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151520",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF209KB17S3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151521",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days) - Regular Plan - Growth Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF209KB16S5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151522",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UF (180 days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-02-27",
    "closeData": "",
    "isin": "INF209KB19S9",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151523",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AYB5",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151524",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long duration Fund - Direct Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AYC3",
    "isinRe": "INF789F1AYD1"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151525",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Direct Plan - Annual IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AYG4",
    "isinRe": "INF789F1AYH2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151526",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Direct Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AYI0",
    "isinRe": "INF789F1AYJ8"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151527",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AXS1",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151528",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Regular Plan - Quarterly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AXT9",
    "isinRe": "INF789F1AXU7"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151529",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Regular Plan - Half Yearly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AXV5",
    "isinRe": "INF789F1AXW3"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151530",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Direct Plan - Half Yearly IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AYE9",
    "isinRe": "INF789F1AYF6"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151531",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Regular Plan - Annual IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AXX1",
    "isinRe": "INF789F1AXY9"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151532",
    "name": "UTI Long Duration Fund",
    "category": "Debt Scheme - Long Duration Fund",
    "navName": "UTI Long Duration Fund - Regular Plan - Flexi IDCW Option",
    "minAmount": "Rs 5000/- and in multiple of Rs1/- thereafter.",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF789F1AXZ6",
    "isinRe": "INF789F1AYA7"
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151533",
    "name": "Aditya Birla Sun Life CRISIL Liquid Overnight ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Aditya Birla Sun Life CRISIL Liquid Overnight ETF - IDCW Daily Reinvestment with Weekly Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-03",
    "closeData": "",
    "isin": "INF209KB18T9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151538",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151539",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151540",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151541",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - 365 Days Plan T - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151546",
    "name": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund - REGULAR PLAN - GROWTH",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151547",
    "name": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund - REGULAR PLAN- IDCW",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151548",
    "name": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund - DIRECT PLAN - GROWTH",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151549",
    "name": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Bandhan US TREASURY BOND 0-1 YEAR Fund of Fund - DIRECT PLAN - IDCW",
    "minAmount": "Rs.1000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "151550",
    "name": "Motilal Oswal Nifty G-sec May 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty G-sec May 2029 Index Fund-  Regualr Plan Growth ption",
    "minAmount": "500",
    "launchDate": "2023-03-02",
    "closeData": "",
    "isin": "INF247L01BP1",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "151551",
    "name": "Motilal Oswal Nifty G-sec May 2029 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty G-sec May 2029 Index Fund- Direct Plan Growth Option",
    "minAmount": "500",
    "launchDate": "2023-03-02",
    "closeData": "",
    "isin": "INF247L01BO4",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151552",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF769K01JS3",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151553",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2 - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF769K01JR5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151554",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2 - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF769K01JT1",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151555",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 2 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF769K01JQ7",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151557",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 79 (1130 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 79 (1170 Days) - Regular Plan - Income Distribution cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-03-09",
    "closeData": "2023-03-13",
    "isin": "INF200KA18W4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151558",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 79 (1130 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 79 (1170 Days) - Direct Plan - Income Distribution cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-03-09",
    "closeData": "2023-03-13",
    "isin": "INF200KA10X9",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151559",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 79 (1130 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 79 (1170 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-03-09",
    "closeData": "2023-03-13",
    "isin": "INF200KA19W2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151556",
    "name": "SBI Fixed Maturity Plan (FMP) - Series 79 (1130 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP) - Series 79 (1170 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-03-09",
    "closeData": "2023-03-13",
    "isin": "INF200KA17W6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151560",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF209KB14T8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151561",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF209KB15T5",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151562",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF209KB16T3",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151563",
    "name": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Nifty SDL SEP 2027 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2023-03-06",
    "closeData": "",
    "isin": "INF209KB17T1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151564",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151565",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151566",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151567",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151568",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - Quarterly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151569",
    "name": "HDFC FMP 1269D March 2023",
    "category": "Income",
    "navName": "HDFC FMP 1269D March 2023 - Quarterly IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "2023-03-20",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151570",
    "name": "HDFC NIFTY SDL Plus G-Sec Jun 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY SDL Plus G-Sec Jun 2027 40 60 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-03-13",
    "closeData": "2023-03-21",
    "isin": "INF179KC1GA2",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151571",
    "name": "HDFC NIFTY SDL Plus G-Sec Jun 2027 40 60 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY SDL Plus G-Sec Jun 2027 40 60 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-03-13",
    "closeData": "2023-03-21",
    "isin": "INF179KC1GB0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151572",
    "name": "ICICI Prudential Nifty PSU Bank ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "ICICI Prudential Nifty PSU Bank ETF",
    "minAmount": "1000",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF109KC10S8",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151573",
    "name": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "",
    "isin": "INF251K01RP1",
    "isinRe": "INF251K01RQ9"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151574",
    "name": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "",
    "isin": "INF251K01RR7",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151575",
    "name": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "",
    "isin": "INF251K01RS5",
    "isinRe": "INF251K01RT3"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151576",
    "name": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Baroda BNP Paribas NIFTY SDL December 2028 Index Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-14",
    "closeData": "",
    "isin": "INF251K01RO4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151577",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund-Direct Growth",
    "minAmount": "500",
    "launchDate": "2023-03-08",
    "closeData": "",
    "isin": "INF209KB11U2",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151582",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund-Regular IDCW",
    "minAmount": "500",
    "launchDate": "2023-03-08",
    "closeData": "",
    "isin": "INF209KB10U4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151583",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund-Regular Growth",
    "minAmount": "500",
    "launchDate": "2023-03-08",
    "closeData": "",
    "isin": "INF209KB19T7",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151584",
    "name": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Aditya Birla Sun Life Crisil IBX Gilt Apr 2028 Index Fund-Direct IDCW",
    "minAmount": "500",
    "launchDate": "2023-03-08",
    "closeData": "",
    "isin": "INF209KB12U0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151578",
    "name": "ICICI Prudential Innovation Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Innovation Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF109KC13T0",
    "isinRe": "INF109KC14T8"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151579",
    "name": "ICICI Prudential Innovation Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Innovation Fund - Growth",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF109KC19S9",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151580",
    "name": "ICICI Prudential Innovation Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Innovation Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF109KC12T2",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151581",
    "name": "ICICI Prudential Innovation Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "ICICI Prudential Innovation Fund - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF109KC10T6",
    "isinRe": "INF109KC11T4"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151585",
    "name": "Axis S&P BSE Sensex ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Axis S & P BSE Sensex ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-10",
    "closeData": "2023-03-21",
    "isin": "INF846K010Q0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151586",
    "name": "Axis Fixed Term Plan - Series 113 (1228 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 113 (1228 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 10/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF846K011Q8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151587",
    "name": "Axis Fixed Term Plan - Series 113 (1228 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 113 (1228 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 10/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF846K012Q6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151588",
    "name": "Axis Fixed Term Plan - Series 113 (1228 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 113 (1228 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Re 10/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF846K014Q2",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151589",
    "name": "Axis Fixed Term Plan - Series 113 (1228 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 113 (1228 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 10/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF846K015Q9",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151629",
    "name": "Axis Fixed Term Plan - Series 113 (1228 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 113 (1228 Days) - Regular Plan - Half Yearly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re 10/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF846K016Q7",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151590",
    "name": "Invesco India Nifty G-sec Jul 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Jul 2027 Index Fund - Regular - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1759",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151591",
    "name": "Invesco India Nifty G-sec Jul 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Jul 2027 Index Fund - Regular - IDCW Payout",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1767",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151592",
    "name": "Invesco India Nifty G-sec Jul 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Jul 2027 Index Fund - Direct - IDCW Payout",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1783",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151593",
    "name": "Invesco India Nifty G-sec Jul 2027 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Jul 2027 Index Fund - Direct - Growth",
    "minAmount": "Rs. 1000/- per application and in multiples of Re",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1775",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151594",
    "name": "Invesco India Nifty G-sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Sep 2032 Index Fund - Regular - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1791",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151595",
    "name": "Invesco India Nifty G-sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Sep 2032 Index Fund - Regular - IDCW Payout",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1809",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151596",
    "name": "Invesco India Nifty G-sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Sep 2032 Index Fund - Direct - IDCW Payout",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1825",
    "isinRe": null
  },
  {
    "amc": "Invesco Asset Management (India) Private Limited",
    "code": "151597",
    "name": "Invesco India Nifty G-sec Sep 2032 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Invesco India Nifty G-sec Sep 2032 Index Fund - Direct - Growth",
    "minAmount": "Rs. 1000/- per app and in multiples of Re. 1",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF205KA1817",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151602",
    "name": "Kotak Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Silver ETF Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF174KA1MR1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151603",
    "name": "Kotak Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "Kotak Silver ETF Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF174KA1MQ3",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151604",
    "name": "Mirae Asset Nifty 100 Low Volatility 30 ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty 100 Low Volatility 30 ETF",
    "minAmount": "NFO Rs.5000 and in Rs.1 multiples thereafter",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF769K01JU9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151605",
    "name": "Kotak Nifty SDL Jul 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2028 Index Fund - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-15",
    "closeData": "",
    "isin": "INF174KA1MT7",
    "isinRe": "INF174KA1MU5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151606",
    "name": "Kotak Nifty SDL Jul 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2028 Index Fund - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-15",
    "closeData": "",
    "isin": "INF174KA1MW1",
    "isinRe": "INF174KA1MX9"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151607",
    "name": "Kotak Nifty SDL Jul 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2028 Index Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-15",
    "closeData": "",
    "isin": "INF174KA1MS9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151608",
    "name": "Kotak Nifty SDL Jul 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty SDL Jul 2028 Index Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-15",
    "closeData": "",
    "isin": "INF174KA1MV3",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "151609",
    "name": "NJ ELSS Tax Saver Scheme",
    "category": "Equity Scheme - ELSS",
    "navName": "NJ ELSS Tax Saver Scheme Regular Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/-",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF0J8L01099",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "151610",
    "name": "NJ ELSS Tax Saver Scheme",
    "category": "Equity Scheme - ELSS",
    "navName": "NJ ELSS Tax Saver Scheme Regular IDCW",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/-",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF0J8L01107",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "151611",
    "name": "NJ ELSS Tax Saver Scheme",
    "category": "Equity Scheme - ELSS",
    "navName": "NJ ELSS Tax Saver Scheme Direct Growth",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/-",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF0J8L01115",
    "isinRe": null
  },
  {
    "amc": "NJ Asset Management Private Limited",
    "code": "151612",
    "name": "NJ ELSS Tax Saver Scheme",
    "category": "Equity Scheme - ELSS",
    "navName": "NJ ELSS Tax Saver Scheme Direct IDCW",
    "minAmount": "Rs. 500/- and in multiples of Rs. 500/-",
    "launchDate": "2023-03-13",
    "closeData": "",
    "isin": "INF0J8L01123",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151613",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF200KA13X3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151614",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF200KA14X1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151615",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days)- Regular Plan- Income Distribution Cum Capital Withdrawal Payout Option",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF200KA12X5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151616",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 80 (366 Days) Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-15",
    "closeData": "2023-03-20",
    "isin": "INF200KA11X7",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151617",
    "name": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)",
    "category": "Income",
    "navName": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)-REGULAR PLAN -GROWTH",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF0GCD01636",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151618",
    "name": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)",
    "category": "Income",
    "navName": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)-REGULAR PLAN -IDCW PAYOUT",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF0GCD01644",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151619",
    "name": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)",
    "category": "Income",
    "navName": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)-DIRECT PLAN -IDCW PAYOUT",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF0GCD01669",
    "isinRe": null
  },
  {
    "amc": "Trust Asset Management Private Limited",
    "code": "151620",
    "name": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)",
    "category": "Income",
    "navName": "TRUSTMF FIXED MATURITY PLAN -SERIES II (1196 DAYS)-DIRECT PLAN -GROWTH",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF0GCD01651",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "151622",
    "name": "Union Fixed Maturity Plan - Series 13 (1114 Days)",
    "category": "Income",
    "navName": "Union Fixed Maturity Plan - Series 13 - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-03-21",
    "closeData": "",
    "isin": "INF582M01IW0",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "151623",
    "name": "Union Fixed Maturity Plan - Series 13 (1114 Days)",
    "category": "Income",
    "navName": "Union Fixed Maturity Plan - Series 13 - Regular Plan - IDCW Payout Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-03-21",
    "closeData": "",
    "isin": "INF582M01IZ3",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "151624",
    "name": "Union Fixed Maturity Plan - Series 13 (1114 Days)",
    "category": "Income",
    "navName": "Union Fixed Maturity Plan - Series 13 - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-03-21",
    "closeData": "",
    "isin": "INF582M01IY6",
    "isinRe": null
  },
  {
    "amc": "Union Asset Management Company Private Limited",
    "code": "151621",
    "name": "Union Fixed Maturity Plan - Series 13 (1114 Days)",
    "category": "Income",
    "navName": "Union Fixed Maturity Plan - Series 13 - Direct Plan - IDCW Payout Option",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-03-21",
    "closeData": "",
    "isin": "INF582M01IX8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151625",
    "name": "Axis Fixed Term Plan - Series 114 (83 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 114 (83 Days) - Direct Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-03-17",
    "closeData": "2023-03-23",
    "isin": "INF846K017Q5",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151626",
    "name": "Axis Fixed Term Plan - Series 114 (83 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 114 (83 Days) - Regular Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-03-17",
    "closeData": "2023-03-23",
    "isin": "INF846K010R8",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151627",
    "name": "Axis Fixed Term Plan - Series 114 (83 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 114 (83 Days) - Regular Plan - Growth",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-03-17",
    "closeData": "2023-03-23",
    "isin": "INF846K019Q1",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151628",
    "name": "Axis Fixed Term Plan - Series 114 (83 Days)",
    "category": "Income",
    "navName": "Axis Fixed Term Plan - Series 114 (83 Days) - Direct Plan - Quarterly IDCW",
    "minAmount": "Rs. 5000 and in multiples of Rs. 10/- thereafter",
    "launchDate": "2023-03-17",
    "closeData": "2023-03-23",
    "isin": "INF846K018Q3",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151631",
    "name": "Axis S&P 500 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis S&P 500 ETF Fund of Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-22",
    "closeData": "2023-04-05",
    "isin": "INF846K012R4",
    "isinRe": "INF846K013R2"
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151632",
    "name": "Axis S&P 500 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis S&P 500 ETF Fund of Fund - Regular Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-22",
    "closeData": "2023-04-05",
    "isin": "INF846K014R0",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151633",
    "name": "Axis S&P 500 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis S&P 500 ETF Fund of Fund - Direct Plan - Growth",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-22",
    "closeData": "2023-04-05",
    "isin": "INF846K011R6",
    "isinRe": null
  },
  {
    "amc": "Axis Asset Management Co. Ltd.",
    "code": "151634",
    "name": "Axis S&P 500 ETF Fund of Fund",
    "category": "Other Scheme - FoF Overseas",
    "navName": "Axis S&P 500 ETF Fund of Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 500 and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-22",
    "closeData": "2023-04-05",
    "isin": "INF846K015R7",
    "isinRe": "INF846K016R5"
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151635",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF109KC14S0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151636",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF109KC12S4",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151637",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF109KC11S6",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151638",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan U - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF109KC13S2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151639",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-21",
    "closeData": "2023-03-29",
    "isin": "INF200KA17X4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151640",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-21",
    "closeData": "2023-03-29",
    "isin": "INF200KA18X2",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151641",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days) Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-21",
    "closeData": "2023-03-29",
    "isin": "INF200KA15X8",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151642",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 81 (1157 Days) Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-21",
    "closeData": "2023-03-29",
    "isin": "INF200KA16X6",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151643",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days) Direct Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-23",
    "closeData": "2023-03-23",
    "isin": "INF200KA12Y3",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151644",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days) Regular Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-23",
    "closeData": "2023-03-23",
    "isin": "INF200KA19X0",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151645",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days) Direct Plan- Growth",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-23",
    "closeData": "2023-03-23",
    "isin": "INF200KA11Y5",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151646",
    "name": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days)",
    "category": "Income",
    "navName": "SBI Fixed Maturity Plan (FMP)-Series 82 (91 Days) Regular Plan- Income Distribution Cum Capital Withdrawal Payout",
    "minAmount": "Rs. 5000/- and in multiples of Re. 1/- thereafter",
    "launchDate": "2023-03-23",
    "closeData": "2023-03-23",
    "isin": "INF200KA10Y7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151647",
    "name": "Kotak Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Smallcap 50 Index Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF174KA1MY7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151648",
    "name": "Kotak Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Smallcap 50 Index Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF174KA1MZ4",
    "isinRe": "INF174KA1NA5"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151649",
    "name": "Kotak Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Smallcap 50 Index Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF174KA1NB3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151650",
    "name": "Kotak Nifty Smallcap 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty Smallcap 50 Index Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": "INF174KA1NC1",
    "isinRe": "INF174KA1ND9"
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151663",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 2-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151664",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 2-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151665",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 2-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151666",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 2",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 2-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-16",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151667",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 3-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151668",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 3-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151669",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 3-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151670",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 3",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 3-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151674",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 4-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151671",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 4-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151672",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 4-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151673",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 4",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 4-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-24",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151675",
    "name": "Kotak FMP Series 309",
    "category": "Income",
    "navName": "Kotak FMP Series 309 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NI8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151676",
    "name": "Kotak FMP Series 309",
    "category": "Income",
    "navName": "Kotak FMP Series 309 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NJ6",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151677",
    "name": "Kotak FMP Series 309",
    "category": "Income",
    "navName": "Kotak FMP Series 309 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NK4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151678",
    "name": "Kotak FMP Series 309",
    "category": "Income",
    "navName": "Kotak FMP Series 309 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NL2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151679",
    "name": "Kotak FMP Series 308",
    "category": "Income",
    "navName": "Kotak FMP Series 308 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NE7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151680",
    "name": "Kotak FMP Series 308",
    "category": "Income",
    "navName": "Kotak FMP Series 308 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NF4",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151681",
    "name": "Kotak FMP Series 308",
    "category": "Income",
    "navName": "Kotak FMP Series 308 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NG2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151682",
    "name": "Kotak FMP Series 308",
    "category": "Income",
    "navName": "Kotak FMP Series 308 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-03-17",
    "closeData": "",
    "isin": "INF174KA1NH0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151683",
    "name": "Kotak FMP Series 310",
    "category": "Income",
    "navName": "Kotak FMP Series 310 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF174KA1NM0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151684",
    "name": "Kotak FMP Series 310",
    "category": "Income",
    "navName": "Kotak FMP Series 310 - Direct Plan - IDCW Payout Option",
    "minAmount": "5000",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF174KA1NP3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151685",
    "name": "Kotak FMP Series 310",
    "category": "Income",
    "navName": "Kotak FMP Series 310 - Regular Plan - IDCW Payout option",
    "minAmount": "5000",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF174KA1NN8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151686",
    "name": "Kotak FMP Series 310",
    "category": "Income",
    "navName": "Kotak FMP Series 310 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF174KA1NO6",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151691",
    "name": "Mirae Asset Nifty SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL June 2028 Index Fund - Direct Plan - Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF769K01JZ8",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151692",
    "name": "Mirae Asset Nifty SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL June 2028 Index Fund - Direct Plan - IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF769K01JY1",
    "isinRe": "INF769K01KA9"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151693",
    "name": "Mirae Asset Nifty SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL June 2028 Index Fund - Regular Plan - IDCW",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF769K01JV7",
    "isinRe": "INF769K01JX3"
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151694",
    "name": "Mirae Asset Nifty SDL June 2028 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Mirae Asset Nifty SDL June 2028 Index Fund -Regular Plan - Growth",
    "minAmount": "Investors can invest under the Scheme with a minim",
    "launchDate": "2023-03-20",
    "closeData": "",
    "isin": "INF769K01JW5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151695",
    "name": "Mirae Asset Nifty 8-13 yr G-Sec ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Nifty 8-13 yr Gsec ETF",
    "minAmount": "minimum investment of Rs.5000 during NFO",
    "launchDate": "2023-03-29",
    "closeData": "",
    "isin": "INF769K01KF8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151700",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days) - Direct Plan - Growth Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF209KB13V6",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151701",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days) - Regular Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF209KB12V8",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151702",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days) - Direct Plan - Payout of IDCW Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF209KB14V4",
    "isinRe": null
  },
  {
    "amc": "Aditya Birla Sun Life AMC Limited",
    "code": "151703",
    "name": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days)",
    "category": "Income",
    "navName": "Aditya Birla Sun Life Fixed Term Plan - Series UJ (1110 days) - Regular Plan - Growth Option",
    "minAmount": "Minimum of Rs.1000 and in multiples of Rs. 10",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF209KB11V0",
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151704",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151705",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151706",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "ICICI Prudential Asset Management Company Limited",
    "code": "151707",
    "name": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V",
    "category": "Income",
    "navName": "ICICI Prudential Fixed Maturity Plan - Series 88 - Plan V - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-03-31",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151708",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 5-Direct Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151709",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 5-Direct Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151710",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 5-Regular Plan-Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Nippon Life India Asset Management Limited",
    "code": "151711",
    "name": "Nippon India Fixed Horizon Fund - XLV - Series 5",
    "category": "Income",
    "navName": "Nippon India Fixed Horizon Fund-XLV-Series 5-Regular Plan-Payout of IDCW",
    "minAmount": "Rs. 5000 and in multiples of Re. 1 thereafter.",
    "launchDate": "2023-03-29",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151712",
    "name": "quant Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "quant Dynamic Asset Allocation Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF966L01BM3",
    "isinRe": "INF966L01BN1"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151713",
    "name": "quant Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "quant Dynamic Asset Allocation Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF966L01BL5",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151714",
    "name": "quant Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "quant Dynamic Asset Allocation Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF966L01BO9",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151715",
    "name": "quant Dynamic Asset Allocation Fund",
    "category": "Hybrid Scheme - Dynamic Asset Allocation or Balanced Advantage",
    "navName": "quant Dynamic Asset Allocation Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-03-23",
    "closeData": "",
    "isin": "INF966L01BP6",
    "isinRe": "INF966L01BQ4"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151724",
    "name": "HDFC NIFTY Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Midcap 150 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GC8",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151725",
    "name": "HDFC NIFTY Midcap 150 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Midcap 150 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GD6",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151726",
    "name": "HDFC NIFTY Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Smallcap 250 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GF1",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151727",
    "name": "HDFC NIFTY Smallcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC NIFTY Smallcap 250 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GE4",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151728",
    "name": "HDFC S&P BSE 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC S&P BSE 500 Index Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GG9",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151729",
    "name": "HDFC S&P BSE 500 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "HDFC S&P BSE 500 Index Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-04-06",
    "closeData": "2023-04-18",
    "isin": "INF179KC1GH7",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151730",
    "name": "UTI Silver Exchange Traded Fund",
    "category": "Other Scheme - Other  ETFs",
    "navName": "UTI Silver Exchange Traded Fund",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151731",
    "name": "UTI Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "UTI Silver ETF Fund of Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151732",
    "name": "UTI Silver ETF Fund of Fund",
    "category": "Other Scheme - FoF Domestic",
    "navName": "UTI Silver ETF Fund of Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151733",
    "name": "Baroda BNP Paribas Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Baroda BNP Paribas Floater Fund - Regular Plan - Weekly IDCW option",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF251K01RV9",
    "isinRe": "INF251K01RW7"
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151734",
    "name": "Baroda BNP Paribas Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Baroda BNP Paribas Floater Fund - Regular Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF251K01RU1",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151735",
    "name": "Baroda BNP Paribas Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Baroda BNP Paribas Floater Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF251K01RX5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151736",
    "name": "Baroda BNP Paribas Floater Fund",
    "category": "Debt Scheme - Floater Fund",
    "navName": "Baroda BNP Paribas Floater Fund - Direct Plan - Weekly IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-04-10",
    "closeData": "",
    "isin": "INF251K01RY3",
    "isinRe": "INF251K01RZ0"
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151737",
    "name": "DSP Gold ETF",
    "category": "Other Scheme - Gold ETF",
    "navName": "DSP Gold ETF",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-04-17",
    "closeData": "2023-04-24",
    "isin": "INF740KA1SW3",
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151738",
    "name": "UTI Nifty 500 Value 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 500 Value 50 Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-04-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151739",
    "name": "UTI Nifty 500 Value 50 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty 500 Value 50 Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-04-26",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151741",
    "name": "Kotak FMP Series 311",
    "category": "Income",
    "navName": "Kotak FMP Series 311 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-04-27",
    "closeData": "",
    "isin": "INF174KA1NQ1",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151742",
    "name": "Kotak FMP Series 311",
    "category": "Income",
    "navName": "Kotak FMP Series 311 - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-04-27",
    "closeData": "",
    "isin": "INF174KA1NR9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151743",
    "name": "Kotak FMP Series 311",
    "category": "Income",
    "navName": "Kotak FMP Series 311 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-04-27",
    "closeData": "",
    "isin": "INF174KA1NS7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151744",
    "name": "Kotak FMP Series 311",
    "category": "Income",
    "navName": "Kotak FMP Series 311 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-04-27",
    "closeData": "",
    "isin": "INF174KA1NT5",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "151745",
    "name": "WhiteOak Capital Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "WhiteOak Capital Multi Asset Allocation Fund Direct Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 1/-",
    "launchDate": "2023-05-03",
    "closeData": "2023-05-12",
    "isin": "INF03VN01761",
    "isinRe": null
  },
  {
    "amc": "WhiteOak Capital Asset Management Limited",
    "code": "151746",
    "name": "WhiteOak Capital Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "WhiteOak Capital Multi Asset Allocation Fund Regular Plan Growth",
    "minAmount": "Minimum of Rs. 500/- and in multiples of Re. 1/-",
    "launchDate": "2023-05-03",
    "closeData": "2023-05-12",
    "isin": "INF03VN01779",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151747",
    "name": "Baroda BNP Paribas Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Baroda BNP Paribas Value Fund - Regular Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2023-05-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151754",
    "name": "Baroda BNP Paribas Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Baroda BNP Paribas Value Fund - Regular Plan - IDCW option",
    "minAmount": "5000",
    "launchDate": "2023-05-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151755",
    "name": "Baroda BNP Paribas Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Baroda BNP Paribas Value Fund - Direct Plan - Growth option",
    "minAmount": "5000",
    "launchDate": "2023-05-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151756",
    "name": "Baroda BNP Paribas Value Fund",
    "category": "Equity Scheme - Value Fund",
    "navName": "Baroda BNP Paribas Value Fund - Direct Plan - IDCW option",
    "minAmount": "5000",
    "launchDate": "2023-05-17",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151748",
    "name": "HDFC Defence Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Defence Fund - IDCW Option",
    "minAmount": "100",
    "launchDate": "2023-05-19",
    "closeData": "2023-05-30",
    "isin": "INF179KC1GM7",
    "isinRe": "INF179KC1GN5"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151749",
    "name": "HDFC Defence Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Defence Fund - IDCW Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-05-19",
    "closeData": "2023-05-30",
    "isin": "INF179KC1GJ3",
    "isinRe": "INF179KC1GK1"
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151750",
    "name": "HDFC Defence Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Defence Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-05-19",
    "closeData": "2023-05-30",
    "isin": "INF179KC1GI5",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151751",
    "name": "HDFC Defence Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Defence Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-05-19",
    "closeData": "2023-05-30",
    "isin": "INF179KC1GL9",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "151752",
    "name": "Samco Active Momentum Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Samco Active Momentum Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1/- thereafter",
    "launchDate": "2023-06-15",
    "closeData": "2023-06-29",
    "isin": "INF0K1H01073",
    "isinRe": null
  },
  {
    "amc": "Samco Asset Management Private Limited",
    "code": "151753",
    "name": "Samco Active Momentum Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "Samco Active Momentum Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and in multiples of Rs. 1/- thereafter",
    "launchDate": "2023-06-15",
    "closeData": "2023-06-29",
    "isin": "INF0K1H01081",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151757",
    "name": "quant Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Business Cycle Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-05-12",
    "closeData": "",
    "isin": "INF966L01BR2",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151758",
    "name": "quant Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Business Cycle Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-05-12",
    "closeData": "",
    "isin": "INF966L01BU6",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151759",
    "name": "quant Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Business Cycle Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-05-12",
    "closeData": "",
    "isin": "INF966L01BS0",
    "isinRe": "INF966L01BT8"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151760",
    "name": "quant Business Cycle Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant Business Cycle Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-05-12",
    "closeData": "",
    "isin": "INF966L01BV4",
    "isinRe": "INF966L01BW2"
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151761",
    "name": "UTI Nifty50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty50 Equal Weight Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151762",
    "name": "UTI Nifty50 Equal Weight Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI Nifty50 Equal Weight Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151763",
    "name": "UTI S&P BSE Housing Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Housing Index Fund - Regular Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "UTI Asset Mgmt. Co. Ltd.",
    "code": "151764",
    "name": "UTI S&P BSE Housing Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "UTI S&P BSE Housing Index Fund - Direct Plan - Growth Option",
    "minAmount": "Rs 5000 and in multiple of Rs 1/- thereafter",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151765",
    "name": "SBI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI S&P BSE Sensex Index Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-05-18",
    "closeData": "2023-05-24",
    "isin": "INF200KA13Y1",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151766",
    "name": "SBI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI S&P BSE Sensex Index Fund - Regular Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-05-18",
    "closeData": "2023-05-24",
    "isin": "INF200KA14Y9",
    "isinRe": "INF200KA15Y6"
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151769",
    "name": "SBI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI S&P BSE Sensex Index Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-05-18",
    "closeData": "2023-05-24",
    "isin": "INF200KA16Y4",
    "isinRe": null
  },
  {
    "amc": "SBI Funds Management Limited",
    "code": "151770",
    "name": "SBI S&P BSE Sensex Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "SBI S&P BSE Sensex Index Fund - Direct Plan - IDCW",
    "minAmount": "Rs. 5000/- & in multiples of Re.1",
    "launchDate": "2023-05-18",
    "closeData": "2023-05-24",
    "isin": "INF200KA17Y2",
    "isinRe": "INF200KA18Y0"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151771",
    "name": "Kotak FMP Series 312",
    "category": "Income",
    "navName": "Kotak FMP Series 312 - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": "INF174KA1NW9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151772",
    "name": "Kotak FMP Series 312",
    "category": "Income",
    "navName": "Kotak FMP Series 312 - Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": "INF174KA1NX7",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151773",
    "name": "Kotak FMP Series 312",
    "category": "Income",
    "navName": "Kotak FMP Series 312 - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": "INF174KA1NU3",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151774",
    "name": "Kotak FMP Series 312",
    "category": "Income",
    "navName": "Kotak FMP Series 312 - Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-05-22",
    "closeData": "",
    "isin": "INF174KA1NV1",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151775",
    "name": "ITI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ITI Focused Equity Fund - Regular Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-05-29",
    "closeData": "",
    "isin": "INF00XX01CW0",
    "isinRe": "INF00XX01CR0"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151776",
    "name": "ITI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ITI Focused Equity Fund - Direct Plan - IDCW",
    "minAmount": "5000",
    "launchDate": "2023-05-29",
    "closeData": "",
    "isin": "INF00XX01CT6",
    "isinRe": "INF00XX01CU4"
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151777",
    "name": "ITI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ITI Focused Equity Fund - Direct Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-05-29",
    "closeData": "",
    "isin": "INF00XX01CS8",
    "isinRe": null
  },
  {
    "amc": "ITI Asset Management Limited",
    "code": "151778",
    "name": "ITI Focused Equity Fund",
    "category": "Equity Scheme - Focused Fund",
    "navName": "ITI Focused Equity Fund - Regular Plan - Growth",
    "minAmount": "5000",
    "launchDate": "2023-05-29",
    "closeData": "",
    "isin": "INF00XX01CV2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151779",
    "name": "Mirae Asset Silver ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "Mirae Asset Silver ETF",
    "minAmount": "Rs. 5000 and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-05-29",
    "closeData": "2023-06-06",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151782",
    "name": "Kotak Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 200 Momentum 30 Index Fund - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-05-25",
    "closeData": "",
    "isin": "INF174KA1OC9",
    "isinRe": "INF174KA1OD7"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151783",
    "name": "Kotak Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 200 Momentum 30 Index Fund - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-05-25",
    "closeData": "",
    "isin": "INF174KA1NY5",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151780",
    "name": "Kotak Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 200 Momentum 30 Index Fund - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-05-25",
    "closeData": "",
    "isin": "INF174KA1NZ2",
    "isinRe": "INF174KA1OA3"
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151781",
    "name": "Kotak Nifty 200 Momentum 30 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Kotak Nifty 200 Momentum 30 Index Fund - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-05-25",
    "closeData": "",
    "isin": "INF174KA1OB1",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151788",
    "name": "quant BFSI Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant BFSI Fund - Growth Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-06-01",
    "closeData": "",
    "isin": "INF966L01CA6",
    "isinRe": null
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151789",
    "name": "quant BFSI Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant BFSI Fund - IDCW Option - Regular Plan",
    "minAmount": "5000",
    "launchDate": "2023-06-01",
    "closeData": "",
    "isin": "INF966L01CB4",
    "isinRe": "INF966L01CC2"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151790",
    "name": "quant BFSI Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant BFSI Fund - IDCW Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-06-01",
    "closeData": "",
    "isin": "INF966L01BY8",
    "isinRe": "INF966L01BZ5"
  },
  {
    "amc": "quant Money Managers Limited",
    "code": "151791",
    "name": "quant BFSI Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "quant BFSI Fund - Growth Option - Direct Plan",
    "minAmount": "5000",
    "launchDate": "2023-06-01",
    "closeData": "",
    "isin": "INF966L01BX0",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151792",
    "name": "Edelweiss Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Edelweiss Multi Asset Allocation Fund - Direct Plan - Growth",
    "minAmount": "Rs. 5000/- & multiples of Re. 1/- thereafter",
    "launchDate": "2023-06-05",
    "closeData": "2023-06-19",
    "isin": "INF754K01RS8",
    "isinRe": null
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151793",
    "name": "Edelweiss Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Edelweiss Multi Asset Allocation Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000/- & multiples of Re. 1/- thereafter",
    "launchDate": "2023-06-05",
    "closeData": "2023-06-19",
    "isin": "INF754K01RT6",
    "isinRe": "INF754K01RU4"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151794",
    "name": "Edelweiss Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Edelweiss Multi Asset Allocation Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000/- & multiples of Re. 1/- thereafter",
    "launchDate": "2023-06-05",
    "closeData": "2023-06-19",
    "isin": "INF754K01RP4",
    "isinRe": "INF754K01RQ2"
  },
  {
    "amc": "Edelweiss Asset Management Limited",
    "code": "151795",
    "name": "Edelweiss Multi Asset Allocation Fund",
    "category": "Hybrid Scheme - Multi Asset Allocation",
    "navName": "Edelweiss Multi Asset Allocation Fund - Regular Plan - Growth",
    "minAmount": "Rs. 5000/- & multiples of Re. 1/- thereafter",
    "launchDate": "2023-06-05",
    "closeData": "2023-06-19",
    "isin": "INF754K01RO7",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151796",
    "name": "360 ONE Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "360 ONE FLEXICAP FUND-DIRECT PLAN- GROWTH",
    "minAmount": "1000",
    "launchDate": "2023-06-12",
    "closeData": "",
    "isin": "INF579M01AS1",
    "isinRe": null
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151797",
    "name": "360 ONE Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "360 ONE FLEXICAP FUND-REGULAR PLAN- IDCW",
    "minAmount": "1000",
    "launchDate": "2023-06-12",
    "closeData": "",
    "isin": "INF579M01AQ5",
    "isinRe": "INF579M01AR3"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151798",
    "name": "360 ONE Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "360 ONE FLEXICAP FUND-DIRECT PLAN- IDCW",
    "minAmount": "1000",
    "launchDate": "2023-06-12",
    "closeData": "",
    "isin": "INF579M01AT9",
    "isinRe": "INF579M01AU7"
  },
  {
    "amc": "360 ONE Asset Management Limited (Formerly known as IIFL Asset Management Limited)",
    "code": "151799",
    "name": "360 ONE Flexicap Fund",
    "category": "Equity Scheme - Flexi Cap Fund",
    "navName": "360 ONE FLEXICAP FUND-REGULAR PLAN- GROWTH",
    "minAmount": "1000",
    "launchDate": "2023-06-12",
    "closeData": "",
    "isin": "INF579M01AP7",
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151802",
    "name": "HDFC Non-Cyclical Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Non-Cyclical Consumer Fund - IDCW Option",
    "minAmount": "100",
    "launchDate": "2023-06-23",
    "closeData": "2023-07-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151803",
    "name": "HDFC Non-Cyclical Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Non-Cyclical Consumer Fund - Growth Option",
    "minAmount": "100",
    "launchDate": "2023-06-23",
    "closeData": "2023-07-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151804",
    "name": "HDFC Non-Cyclical Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Non-Cyclical Consumer Fund - Growth Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-06-23",
    "closeData": "2023-07-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "HDFC Asset Management Company Limited",
    "code": "151805",
    "name": "HDFC Non-Cyclical Consumer Fund",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "HDFC Non-Cyclical Consumer Fund - IDCW Option - Direct Plan",
    "minAmount": "100",
    "launchDate": "2023-06-23",
    "closeData": "2023-07-07",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151806",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4 - Direct Plan - Growth",
    "minAmount": "Rs.5000 during NFO and then multiples of Re. 1/-",
    "launchDate": "2023-06-08",
    "closeData": "",
    "isin": "INF769K01KP7",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151807",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4 - Direct Plan - IDCW",
    "minAmount": "Rs.5000 during NFO and then multiples of Re. 1/-",
    "launchDate": "2023-06-08",
    "closeData": "",
    "isin": "INF769K01KQ5",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151808",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4 - Regular Plan - Growth",
    "minAmount": "Rs.5000 during NFO and then multiples of Re. 1/-",
    "launchDate": "2023-06-08",
    "closeData": "",
    "isin": "INF769K01KN2",
    "isinRe": null
  },
  {
    "amc": "Mirae Asset Investment Managers (India) Pvt. Ltd",
    "code": "151809",
    "name": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4",
    "category": "Income",
    "navName": "Mirae Asset Fixed Maturity Plan - Series V -Plan 4 - Regular Plan - IDCW",
    "minAmount": "Rs.5000 during NFO and then multiples of Re. 1/-",
    "launchDate": "2023-06-08",
    "closeData": "",
    "isin": "INF769K01KO0",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "151814",
    "name": "Motilal Oswal Nifty Microcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Microcap 250 Index Fund- Direct- Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-06-15",
    "closeData": "2023-06-29",
    "isin": "INF247L01BQ9",
    "isinRe": null
  },
  {
    "amc": "Motilal Oswal Asset Management Company Limited",
    "code": "151815",
    "name": "Motilal Oswal Nifty Microcap 250 Index Fund",
    "category": "Other Scheme - Index Funds",
    "navName": "Motilal Oswal Nifty Microcap 250 Index Fund- Regular- Growth Option",
    "minAmount": "Rs. 500/- and in multiples of Re. 1/- thereafter.",
    "launchDate": "2023-06-15",
    "closeData": "2023-06-29",
    "isin": "INF247L01BR7",
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151816",
    "name": "BANDHAN FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN FINANCIAL SERVICES FUND - DIRECT PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and any amount thereafter thereafter",
    "launchDate": "2023-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151817",
    "name": "BANDHAN FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN FINANCIAL SERVICES FUND - DIRECT PLAN - IDCW",
    "minAmount": "Rs. 5000/- and any amount thereafter thereafter",
    "launchDate": "2023-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151818",
    "name": "BANDHAN FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
    "minAmount": "Rs. 5000/- and any amount thereafter thereafter",
    "launchDate": "2023-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Bandhan AMC Limited",
    "code": "151819",
    "name": "BANDHAN FINANCIAL SERVICES FUND",
    "category": "Equity Scheme - Sectoral/ Thematic",
    "navName": "BANDHAN FINANCIAL SERVICES FUND - REGULAR PLAN - IDCW",
    "minAmount": "Rs. 5000/- and any amount thereafter thereafter",
    "launchDate": "2023-07-10",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "DSP Asset Managers Private Limited",
    "code": "151820",
    "name": "DSP Nifty IT ETF",
    "category": "Other Scheme - Other  ETFs",
    "navName": "DSP Nifty IT ETF",
    "minAmount": "Rs. 5000/-",
    "launchDate": "2023-06-21",
    "closeData": "2023-07-03",
    "isin": "INF740KA1SX1",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "151821",
    "name": "Canara Robeco Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Canara Robeco Multi Cap Fund - Regular Plan - Growth Option",
    "minAmount": "Rs. 5000 and multiples of Re. 1 thereafter",
    "launchDate": "2023-07-07",
    "closeData": "2023-07-21",
    "isin": "INF760K01KR2",
    "isinRe": null
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "151822",
    "name": "Canara Robeco Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Canara Robeco Multi Cap Fund - Direct Plan - IDCW Option",
    "minAmount": "Rs. 5000 and multiples of Re. 1 thereafter",
    "launchDate": "2023-07-07",
    "closeData": "2023-07-21",
    "isin": "INF760K01KQ4",
    "isinRe": "INF760K01KP6"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "151823",
    "name": "Canara Robeco Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Canara Robeco Multi Cap Fund - Regular Plan - IDCW Option",
    "minAmount": "Rs. 5000 and multiples of Re. 1 thereafter",
    "launchDate": "2023-07-07",
    "closeData": "2023-07-21",
    "isin": "INF760K01KT8",
    "isinRe": "INF760K01KS0"
  },
  {
    "amc": "Canara Robeco Asset Management Company Limited",
    "code": "151824",
    "name": "Canara Robeco Multi Cap Fund",
    "category": "Equity Scheme - Multi Cap Fund",
    "navName": "Canara Robeco Multi Cap Fund - Direct Plan - Growth Option",
    "minAmount": "Rs. 5000 and multiples of Re. 1 thereafter",
    "launchDate": "2023-07-07",
    "closeData": "2023-07-21",
    "isin": "INF760K01KO9",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151825",
    "name": "Kotak FMP Series 313",
    "category": "Income",
    "navName": "Kotak FMP Series 313 - Regular Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-06-22",
    "closeData": "",
    "isin": "INF174KA1OF2",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151826",
    "name": "Kotak FMP Series 313",
    "category": "Income",
    "navName": "Kotak FMP Series 313 - Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-06-22",
    "closeData": "",
    "isin": "INF174KA1OG0",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151827",
    "name": "Kotak FMP Series 313",
    "category": "Income",
    "navName": "Kotak FMP Series 313 - Direct Plan - IDCW Payout",
    "minAmount": "5000",
    "launchDate": "2023-06-22",
    "closeData": "",
    "isin": "INF174KA1OH8",
    "isinRe": null
  },
  {
    "amc": "Kotak Mahindra Asset Management Company Limited.",
    "code": "151828",
    "name": "Kotak FMP Series 313",
    "category": "Income",
    "navName": "Kotak FMP Series 313 - Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-06-22",
    "closeData": "",
    "isin": "INF174KA1OE5",
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151829",
    "name": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days)",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days) Regular Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151830",
    "name": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days)",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days) Regular Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151831",
    "name": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days)",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days) Direct Plan - IDCW Option",
    "minAmount": "5000",
    "launchDate": "2023-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  },
  {
    "amc": "Baroda BNP Paribas Asset Management India Private Limited",
    "code": "151832",
    "name": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days)",
    "category": "Income",
    "navName": "Baroda BNP Paribas Fixed Maturity Plan Series 2 (189 days) Direct Plan - Growth Option",
    "minAmount": "5000",
    "launchDate": "2023-06-19",
    "closeData": "",
    "isin": null,
    "isinRe": null
  }
]