import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MFComparisonPlot } from "./components/d3/MFComparisonPlot";
import { PortfolioChart } from "./components/ui/PortfolioChart";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    id: "app",
    children: [
      {
        path: "",
        element: <PortfolioChart />,
      },
      {
        path: "portfolio",
        element: <PortfolioChart />,
      },
      {
        path: "compare/trailing",
        element: <MFComparisonPlot comparison={{ type: "trailing" }} />,
      },
      {
        path: "compare/leading",
        element: <MFComparisonPlot comparison={{ type: "leading" }} />,
      },
      {
        path: "compare/rolling",
        element: (
          <MFComparisonPlot comparison={{ type: "rolling", days: [360 * 2] }} />
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
