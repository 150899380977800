import { useEffect } from "react";

const update = () => {
  const matches = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    document.documentElement.classList.add("dark", "changing-theme");
  } else {
    document.documentElement.classList.remove("dark", "changing-theme");
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#f8fafc");
  }
  window.setTimeout(() => {
    document.documentElement.classList.remove("changing-theme");
  });
};

export const isDark = () => {
  return !!window.matchMedia("(prefers-color-scheme: dark)").matches;
};

export const useDarkWatch = () => {
  useEffect(() => {
    let mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    update();
    mediaQuery.addEventListener("change", update);
    return () => {
      mediaQuery.removeEventListener("change", update);
    };
  }, []);
};
