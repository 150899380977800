import clsx from "clsx";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import allTrades from "src/data/all_trades";
import { MFData } from "src/instruments/mf";
import {
  TTrades,
  normalizeHoldingsWithNav,
  tradesToFifoAdjustedOps,
} from "src/portfolio/holdings";
import { MFChart } from "../d3/MFChart";
import { useDimensions } from "src/lib/utils/useDimensions";
import { toINRStr } from "src/lib/utils/toINRStr";
import { xirrForTrades } from "src/utils/xirr";

interface TPortfolioMFCard {
  isin?: string;
  domain?: [number, number];
  portfolioDateExtent?: [number, number] | [undefined, undefined];
  className?: string;
  mfData: MFData;
  weight?: number;
  total?: number;
  endDate?: number;
  onClick?: (isin: string) => void;
}

const msInDay = 3600 * 24 * 1000;
const daysBetween = (x: number, y: number, every = 1) =>
  Math.floor(Math.abs(x - y) / (every * msInDay));

export const PortfolioMFCard = ({
  isin,
  mfData,
  weight,
  total,
  domain,
  portfolioDateExtent,
  endDate,
  className,
  onClick,
}: TPortfolioMFCard) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [navs, setNavs] = useState<number[]>();
  const [startDate, setStartDate] = useState<number>();

  const { width, height } = useDimensions(wrapperRef);

  const sourceTrades = useMemo(
    () =>
      allTrades.allTrades
        .filter((x) => x.isin === isin)
        .map((x) => [
          x.isin,
          x.trade_type as TTrades[1],
          x.quantity,
          x.price,
          +new Date(x.trade_date),
        ]) as TTrades[],
    [isin],
  );

  /* const [folio, setFolio] = useState<Folio[]>();
   * const [latest, setLatest] = useState<AccType[number]>(); */

  useEffect(() => {
    if (!isin) return;
    const assets = { [mfData.isin]: mfData };
    const hops = tradesToFifoAdjustedOps(sourceTrades);
    if (!hops.ops[0]) return;
    const ld = Math.min(...Object.values(assets).map((a) => a.last_nav_date));

    const { navs } = normalizeHoldingsWithNav(hops, assets, ld || +new Date());
    setNavs(navs);
    setStartDate(hops.ops[0][0]);
  }, [isin, sourceTrades, endDate, mfData]);

  const dots = useMemo(() => {
    if (!startDate || !navs) return;

    return {
      dates: sourceTrades.map((x) => new Date(x[4])),
      values: sourceTrades.map((x) => {
        return daysBetween(startDate, x[4]);
      }),
      types: sourceTrades.map((x) => x[1] as TTrades[1]),
      meta: sourceTrades,
    };
  }, [navs, sourceTrades, startDate]);

  const onClickCb = useCallback(
    () => onClick && isin && onClick(isin),
    [isin, onClick],
  );

  if (!navs || !dots || !startDate) {
    return null;
  }
  const invested = Math.round(total ?? 0);
  const value = Math.round((total ?? 0) * (1 + navs[navs.length - 1]));
  const current = {
    weight: ((weight ?? 0) * 100).toFixed(0),
    invested,
    value,
    unrealized: value - invested,
  };

  const xirr = xirrForTrades(
    allTrades.allTrades.filter((x) => x.isin === isin),
    -value,
    new Date(),
  );

  return (
    <div
      onClick={onClickCb}
      className={clsx(
        "w-64 h-36 p-2 flex flex-col dark:bg-gray-800",
        "bg-orange-100",
        "dark:bg-gray-600",
        className,
      )}
    >
      <div>
        <div className="flex justify-between tracking-tighter font-mono font-bold">
          <span>
            {toINRStr(current.value)}
            <span className="text-xs pl-1">
              <span className="text-gray-400">(</span>
              <span
                className={clsx(
                  "font-bold bg-opacity-50 bg-gray-300 dark:bg-gray-800",
                  current.unrealized > 0 ? "text-green-700" : "text-red-400",
                )}
              >
                {toINRStr(current.unrealized)}
              </span>
              <span className="text-gray-400">)</span>
            </span>
          </span>
          <span className="text-slate-500">{current.weight}%</span>
        </div>
        <div className="space-x-2 text-gray-500 dark:text-gray-200 text-xs">
          <span className="space-x-1">
            <span>XIRR:</span>
            <span className="font-bold">{(xirr * 100).toFixed(2)}%</span>
          </span>
          <span className="space-x-1">
            <span>Return:</span>
            <span className="font-bold">
              {(
                (current.unrealized / (current.value - current.unrealized)) *
                100
              ).toFixed(2)}
              %
            </span>
          </span>
        </div>{" "}
      </div>
      <div ref={wrapperRef} className="w-full h-full">
        <MFChart
          width={width}
          height={height}
          dots={dots}
          forCard
          dateExtent={portfolioDateExtent}
          domain={domain}
          data={[
            {
              values: navs,
              type: "main",
              startDate,
              endDate: endDate ?? +new Date(),
            },
          ]}
        />
      </div>
      <p
        title={mfData.name}
        className="w-full font-bold truncate py-2 dark:text-gray-200"
      >
        {mfData.name}
      </p>
    </div>
  );
};
